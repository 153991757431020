<template>
  <div class="card add-service">
    <div class="sub-title">
      <h6>Gallery</h6>
    </div>
    <div class="file-upload">
      <img src="@/assets/img/icons/upload-icon.svg" alt="image" />
      <h6>Drag & drop files or <span>Browse</span></h6>
      <p>Supported formates: JPEG, PNG</p>
      <input type="file" accept="video/image" />
    </div>
    <div class="file-preview">
      <label for="">Select Default Image</label>
      <!-- <ul class="gallery-selected-img">
                <li>
                    <div class="img-preview">
                        <img src="@/assets/img/services/service-01.jpg" alt="Service Image">
                        <a href="javascript:;"><i class="feather-trash-2"></i></a>
                    </div>
                    <label class="custom_check">
                        <input type="radio" name="gallery" checked>
                        <span class="checkmark"></span>
                    </label>
                </li>
                <li>
                    <div class="img-preview">
                        <img src="@/assets/img/services/service-02.jpg" alt="Service">
                        <a href="javascript:;"><i class="feather-trash-2"></i></a>
                    </div>
                    <label class="custom_check">
                        <input type="radio" name="gallery">
                        <span class="checkmark"></span>
                    </label>
                </li>
                <li>
                    <div class="img-preview">
                        <img src="@/assets/img/services/service-03.jpg" alt="Service Image">
                        <a href="javascript:;"><i class="feather-trash-2"></i></a>
                    </div>
                    <label class="custom_check">
                        <input type="radio" name="gallery">
                        <span class="checkmark"></span>
                    </label>
                </li>
                <li>
                    <div class="img-preview">
                        <img src="@/assets/img/services/service-04.jpg" class="img-fluid thumbnail" alt="image">
                        <a href="javascript:;"><i class="feather-trash-2"></i></a>
                    </div>
                    <label class="custom_check">
                        <input type="radio" name="gallery">
                        <span class="checkmark"></span>
                    </label>
                </li>
            </ul> -->
      <ul class="gallery-selected-img">
        <li v-for="(image, index) in images" :key="index">
          <div class="img-preview">
            <img :src="image.src" :alt="image.alt" />
            <a
              href="javascript:void(0);"
              class="remove-gallery"
              @click="removeImage(index)"
            >
              <i class="feather feather-trash-2"></i>
            </a>
          </div>
          <label class="custom_check">
            <input type="radio" name="gallery" :checked="index === selectedImageIndex" />
            <span class="checkmark"></span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          src: require("@/assets/admin_img/services/service-01.jpg"),
          alt: "Service Image",
        },
        { src: require("@/assets/admin_img/services/service-02.jpg"), alt: "Service" },
        {
          src: require("@/assets/admin_img/services/service-03.jpg"),
          alt: "Service Image",
        },
        { src: require("@/assets/admin_img/services/service-04.jpg"), alt: "Image" },
      ],
      selectedImageIndex: 0, // Set the initial selected image index
    };
  },
  methods: {
    removeImage(index) {
      // Remove the image at the specified index from the `images` array
      this.images.splice(index, 1);

      // Update the selectedImageIndex if needed
      if (index === this.selectedImageIndex) {
        this.selectedImageIndex = 0; // Set to the first image after deletion
      }
    },
  },
};
</script>
