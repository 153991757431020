<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Testimonials</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select
                          :options="Selecttest"
                          id="selecttest"
                          placeholder="A - Z"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li></li>
              <li>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-testimonial"
                >
                  <i class="fa fa-plus me-2"></i>Add Testimonial
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'User_Name'">
                      <td class="table-namesplit">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="require(`@/assets/admin_img/customer/${record.Image}`)"
                            class="me-2"
                            alt="img"
                          />
                        </a>
                        <a href="javascript:void(0);" class="table-name">
                          <span>{{ record.User_Name }}</span>
                          <p>{{ record.User_Email }}</p>
                        </a>
                      </td>
                    </template>
                    <template v-else-if="column.key === 'Rating'">
                      <div class="rating">
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                        <i class="fas fa-star filled me-1"></i>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <h6 :class="record.Class">{{ record.Status }}</h6>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="table-actions d-flex">
                        <button
                          class="btn delete-table me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-testimonial"
                        >
                          <i class="feather feather-edit"></i>
                        </button>
                        <button
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <testimonials-model></testimonials-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User Name",
    dataIndex: "User_Name",
    key: "User_Name",
    sorter: {
      compare: (a, b) => {
        a = a.User_Name.toLowerCase();
        b = b.User_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Rating",
    dataIndex: "Rating",
    key: "Rating",
    sorter: {
      compare: (a, b) => {
        a = a.Rating.toLowerCase();
        b = b.Rating.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Content",
    dataIndex: "Content",
    sorter: {
      compare: (a, b) => {
        a = a.Content.toLowerCase();
        b = b.Content.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Create At",
    dataIndex: "Create_at",
    sorter: {
      compare: (a, b) => {
        a = a.Create_at.toLowerCase();
        b = b.Create_at.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];

const data = [
  {
    id: "1",
    Image: "user-01.jpg",
    User_Name: "John Smith",
    User_Email: "johnsmith@gmail.com",
    Rating: "",
    Content: "Lorem ipsum dolor sit",
    Create_at: "28 Sep 2023 16:43PM",
    Class: "badge-active",
    Status: "Active",
    Action: "",
  },
  {
    id: "2",
    Image: "user-03.jpg",
    User_Name: "John Smith",
    User_Email: "johnsmith@gmail.com",
    Rating: "",
    Content: "Lorem ipsum dolor sit",
    Create_at: "20 Sep 2023 16:43PM",
    Class: "badge-inactive",
    Status: "Inactive",
    Action: "",
  },
  {
    id: "3",
    Image: "user-09.jpg",
    User_Name: "Robert",
    User_Email: "robert@gmail.com",
    Rating: "",
    Content: "Lorem ipsum dolor sit",
    Create_at: "20 Sep 2023 16:43PM",
    Class: "badge-active",
    Status: "Active",
    Action: "",
  },
  {
    id: "4",
    Image: "user-06.jpg",
    User_Name: "Sharonda",
    User_Email: "sharonda@gmail.com",
    Rating: "",
    Content: "Lorem ipsum dolor sit",
    Create_at: "10 Sep 2023 16:43PM",
    Class: "badge-inactive",
    Status: "inactive",
    Action: "",
  },
  {
    id: "5",
    Image: "user-04.jpg",
    User_Name: "Robert",
    User_Email: "robert@gmail.comm",
    Rating: "",
    Content: "Lorem ipsum dolor sit",
    Create_at: "20 Sep 2023 16:43PM",
    Class: "badge-active",
    Status: "Active",
    Action: "",
  },
  {
    id: "6",
    Image: "user-04.jpg",
    User_Name: "Nicholas",
    User_Email: "nicholas@gmail.com",
    Rating: "",
    Content: "Lorem ipsum dolor sit",
    Create_at: "15 Sep 2023 16:43PM",
    Class: "badge-inactive",
    Status: "inactive",
    Action: "",
  },
  {
    id: "7",
    Image: "user-03.jpg",
    User_Name: "John Smith",
    User_Email: "johnsmith@gmail.com",
    Rating: "",
    Content: "Lorem ipsum dolor sit",
    Create_at: "20 Sep 2023 16:43PM",
    Class: "badge-inactive",
    Status: "Inactive",
    Action: "",
  },
  {
    id: "8",
    Image: "user-09.jpg",
    User_Name: "Robert",
    User_Email: "robert@gmail.com",
    Rating: "",
    Content: "Lorem ipsum dolor sit",
    Create_at: "20 Sep 2023 16:43PM",
    Class: "badge-active",
    Status: "Active",
    Action: "",
  },
];
export default {
  data() {
    return {
      Selecttest: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
