<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <div class="row">
          <div class="col-lg-7 col-sm-12 m-auto">
            <div class="content-page-header">
              <h5>Edit Reviews Type</h5>
            </div>
            <b-form @submit.prevent="submitForm">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Reviews Type <span>(English)</span></label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter Reviews Type"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" variant="primary"> Update</b-button>
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/review-type");
    },
  },
};
</script>
