<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="row" style="padding-bottom: 10px;">
            <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
              <div class="card">
                <div class="card-body">
                  <div class="home-user">
                    <div class="home-userhead">
                      <div class="home-usercount">
                        <span
                          ><img src="@/assets/admin_img/icons/eye.svg" alt="img"
                        /></span>
                        <h6>Total Pengeluaran Bulain Ini </h6>
                      </div>
                    </div>
                    <div class="home-usercontent">
                      <div class="home-usercontents">
                        <div class="home-usercontentcount">
                          <span class="counters" data-count="{{ total1 }}">{{ total1 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
              <div class="card">
                <div class="card-body">
                  <div class="home-user home-provider">
                    <div class="home-userhead">
                      <div class="home-usercount">
                        <span
                          ><img src="@/assets/admin_img/icons/monitor.svg" alt="img"
                        /></span>
                        <h6>Jumlah Pengeluaran Bulan Ini</h6>
                      </div>
                    </div>
                    <div class="home-usercontent">
                      <div class="home-usercontents">
                        <div class="home-usercontentcount">
                          <span class="counters" data-count="{{ total2 }}">{{ total2 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
              <div class="card">
                <div class="card-body">
                  <div class="home-user home-service">
                    <div class="home-userhead">
                      <div class="home-usercount">
                        <span
                          ><img src="@/assets/admin_img/icons/global.svg" alt="img"
                        /></span>
                        <h6>Total Pengeluaran Keseluruhan</h6>
                      </div>
                    </div>
                    <div class="home-usercontent">
                      <div class="home-usercontents">
                        <div class="home-usercontentcount">
                          <span class="counters" data-count="{{ total3 }}">{{ total3 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-page-header content-page-headersplit mb-0">
            <h5>Pengeluaran</h5>
            <div class="list-btn">
              <ul>
                <li>
                  <div class="input-group">
                    <input type="date" v-model="params.dateStart" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="height: 43px !important;">
                    <span class="input-group-text">s/d</span>
                    <input type="date" v-model="params.dateEnd" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="height: 43px !important;">
                    <span class="input-group-text"></span>
                    <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="height: 43px !important;">
                    <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                  </div>
                </li>
                <li>
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#add-expenditure"
                  >
                    <i class="fa fa-plus me-2"></i>Add Expenditure
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-resposnive table-div">
                <div class="table datatable">
                  <a-table :columns="columns" :data-source="data" :pagination="pagination">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'id'">
                        <div class="table-actions d-flex">
                          <button
                            class="btn delete-table me-2"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-expenditure"
                            @click="openEditModal(record)"
                          >
                            <i class="feather feather-edit"></i>
                          </button>
                          <button
                            class="btn delete-table"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                            @click="openDeleteModal(record)"
                          >
                            <i class="feather feather-trash-2"></i>
                          </button>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <expenditure-model :editData="editData" :deleteItemId="itemIdToDelete" @dataUpdated="getData"></expenditure-model>
  </template>
  
  <script>
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: {
        compare: (a, b) => {
          a = a.date.toLowerCase();
          b = b.date.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      key: "nominal",
      sorter: {
        compare: (a, b) => {
          a = a.nominal.toLowerCase();
          b = b.nominal.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            dateStart: '',
            dateEnd: ''
        },
        editData: {},
        itemIdToDelete: {},
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        total1: 0,
        total2: 0,
        total3: 0
      };
    },
    name: "expendituresetting",
    computed: {

    },
    methods: {
        search() {
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("expenditure/getExpenditure", this.params)
                .then((resp) => {
                    this.data = resp.data.data.map((item, index) => ({
                      ...item,
                      index: index + 1 
                    }));
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    this.total1 = resp.total1 !== null ? resp.total1 : 0;
                    this.total2 = resp.total2 !== null ? resp.total2 : 0;
                    this.total3 = resp.total3 !== null ? resp.total3 : 0;
                    this.params.dateStart = this.formatDate(resp.dateStart);
                    this.params.dateEnd = this.formatDate(resp.dateEnd);
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
        formatDate(item) {
            // Assuming dateString is in a different format, you need to format it to YYYY-MM-DD
            const date = new Date(item);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    },
    mounted() {
        this.getData();
    },
  };
  </script>
      <style>
      .ant-select-selection-item {
        height: 30px;
        width: 90px;
        margin-top: -6px;
        font-size: 15px;
      }
      </style>