<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>

      <div class="page-wrapper">
        <div class="content">
          <div class="container">			
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <div class="col-md-6 aos" data-aos="fade-up">
                    <h4>Daftar Merek</h4>
                    <p>Pilih Merek Kendaraan Yang Akan di Buat!</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-3" v-for="(item, index) in menuItems" :key="index" style="height: 300px;">
                <div 
                    data-bs-toggle="modal"
                    data-bs-target="#add-vehicle"
                    @click="openModal(item)"
                      class="feature-box aos" data-aos="fade-up"
                  >
                  <!-- <div class="feature-icon">
                    <span>
                      <img src="@/assets/img/mari/icons/mobil1.svg" alt="img">
                    </span>
                  </div> -->
                  <h5>{{ item.title }}</h5>
                  <div class="" >
                    <img v-if="item.icon === undefined || item.icon === ''" src="@/assets/img/services/service-06.jpg" class="img-fluid" alt="">
                    <img v-else :src="item.icon" alt="" style="width: 100%; height: 100%;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vehicle-model :company="company" :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :brandData="dataBrand" :vendorData="dataVendor" @dataUpdated="getBack" :idBrand="brandId"></vehicle-model>
  </template>
  
  <script>
  export default {
    data() {
      return {
        menuItems: [],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        paramVendor: {
            row: 100,
            keyword: "",
            sortby: "full_name",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataProduct: [],
        dataBrand: [],
        dataVendor: [],
        brandId: null,
        backMenu: null,
        company: null
      };
    },
    methods: {
        openModal(item) {
            const data = this.dataBrand.find(data => data.name === item.title);
            console.log(data.id);
            this.brandId = data.id;
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
            .dispatch("brand/getBrand", this.params)
            .then((resp) => {
                this.menuItems = resp.data.data.map((item, index) => ({
                    title: item.name,
                    icon: item.file, // Assuming "icon_file" contains the file path for icons
                    route: "/"
                }));
                this.dataBrand = resp.data.data;
                console.log(this.menuItems);
                loading.hide();
            })
            .catch((e) => {
                this.$toast.error(e);
                loading.hide();
            });
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params)
                .then((resp) => {
                    this.dataProduct = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVendor() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vendor/getVendor", this.paramVendor)
                .then((resp) => {
                    this.dataVendor = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getBack() {
          if (this.backMenu == "list") {
            this.$router.replace({ path: "/mari/vehicle" });
          } else {
            this.$router.replace({ path: "/mari/monitoring" });
          }
        },
        getCompany() {
          const user = JSON.parse(localStorage.getItem('user'));
          this.company = user.company;
          console.log(this.company)
        },
    },
    mounted() {
        this.backMenu = this.$route.query.condition;
        // console.log(this.backMenu);
        this.getData();
        this.getProduct();
        this.getVendor();
        this.getCompany();
    },
  };
  </script>