<template>
  <div class="main-wrapper connectapp">
    <custombookcalendarnav />

    <div class="page-wrapper">
      <connectappscontent />

      <customnotifydeleteacc />
    </div>
  </div>
</template>
