<template>
  <!-- Working Section -->
  <section class="Working-four-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading working-four-heading aos" data-aos="fade-up">
            <p>Working Steps</p>
            <h2>How we are working</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="working-four-main">
            <h6>Step 1</h6>
            <div class="working-four-img">
              <img src="@/assets/img/icons/working-1.svg" alt="" />
            </div>
            <h4>Choose a Service Category</h4>
            <p>Select the type of catering service you are looking for</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="working-four-main">
            <h6>Step 2</h6>
            <div class="working-four-img">
              <img src="@/assets/img/icons/working-2.svg" alt="" />
            </div>
            <h4>Choose a Service Category</h4>
            <p>Select the type of catering service you are looking for</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="working-four-main">
            <h6>Step 3</h6>
            <div class="working-four-img">
              <img src="@/assets/img/icons/working-3.svg" alt="" />
            </div>
            <h4>Check Catererings Details</h4>
            <p>Select the type of catering service you are looking for</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="working-four-main">
            <h6>Step 4</h6>
            <div class="working-four-img">
              <img src="@/assets/img/icons/working-4.svg" alt="" />
            </div>
            <h4>Book the best catererings</h4>
            <p>Select the type of catering service you are looking for</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--/ Working Section -->

  <!-- Around the world  Services -->
  <section class="around-world-section">
    <div class="container">
      <div class="section-heading section-heading-four">
        <div class="row align-items-center">
          <div class="col-md-6 aos" data-aos="fade-up">
            <h2>Top Caterers Around the World</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
          <div class="col-md-6 text-md-end aos" data-aos="fade-up">
            <router-link to="/categories" class="btn btn-primary btn-view"
              >VIEW ALL CATERERS<i class="feather-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel world-four-slider common-four-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexfourwork" :key="item.id">
                <div class="service-widget service-widget-space aos" data-aos="fade-up">
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="fav-item fav-item-four">
                      <a
                        href="javascript:void(0)"
                        class="fav-icon"
                        @click="toggleSelected"
                      >
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                    <div class="item-info item-info-four">
                      <div class="rating">
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                      </div>
                    </div>
                  </div>
                  <div class="service-content service-four-content">
                    <h3 class="title">
                      <router-link to="/service-details">{{ item.title }}</router-link>
                    </h3>
                    <p>
                      we are also engage in offering catering services and decoration
                      services where vision...
                    </p>
                    <ul>
                      <li>Buffet Caterings</li>
                      <li>Engagement</li>
                    </ul>
                    <p>
                      <span class="mini-mealmenu">{{ item.rate }}</span
                      ><span class="mealmenu">{{ item.offer }}</span>
                    </p>
                  </div>
                  <div class="service-content-bottom">
                    <div class="service-cater-img service-world-img">
                      <a href="javascript:;"
                        ><img :src="require(`@/assets/img/profiles/${item.avatar}`)"
                      /></a>
                      <p class="service-cater-bottom">
                        <i class="feather-map-pin"></i>{{ item.country }}
                      </p>
                    </div>
                    <span><i class="feather-calendar"></i></span>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfourwork from "@/assets/json/indexfourwork.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexfourwork: indexfourwork,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    toggleSelected(event) {
      const element = event.target;
      element.classList.toggle("selected");
    },
  },
};
</script>
