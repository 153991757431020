<template>
  <div class="main-wrapper">
    <custombookcalendarnav />

    <div class="page-wrapper">
      <breadcrumb :title="title" :text="text" :text1="text1" />

      <invoicecontent />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Invoice",
      text: "Home",
      text1: "Invoice",
    };
  },
};
</script>
