<template>
    <!-- Show Product -->
    <div class="modal fade" id="show-product" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Paket Sewa</h5>
            <button
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Jenis Sewa</label>
                            <select v-if="formEdit !== undefined" class="form-control" v-model="formEdit.rental_type" disabled>
                                <option value="1" :value="1" :selected="formEdit.rental_type === 1">Sewa Lepas Kunci</option>
                                <option value="2" :value="2" :selected="formEdit.rental_type === 2">Sewa Dengan Driver</option>
                            </select>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Nama Sewa</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name" disabled/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Kode Sewa</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.code" disabled/>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Satuan Durasi</label>
                            <select v-if="formEdit !== undefined" class="form-control" v-model="formEdit.type" disabled>
                                <option value="1">Per Jam</option>
                                <option value="2">Per Hari</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="col-6" style="margin-right: 10px;">
                                <label class="form-label">Durasi</label>
                                <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.duration" disabled/>
                            </div>
                            <div class="col-6">
                                <div class="col-lg-12">
                                    <label class="form-label">Status</label>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-check form-check-inline">
                                        <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.status === 1" type="radio" id="inlineRadio1" name="status" value="true" v-model="formEdit.status">
                                        <label class="form-check-label" for="inlineRadio1">AKTIF</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.status === 2" type="radio" id="inlineRadio2" name="status" value="false" v-model="formEdit.status">
                                        <label class="form-check-label" for="inlineRadio2">TIDAK AKTIF</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end">
                    <button
                        type="button"
                        class="btn btn-secondary me-2"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Show Product -->

    <!-- Add Product -->
    <div class="modal fade custom-modal" id="add-product" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Paket Sewa</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0 mt-4">
            <form @submit.prevent="createSubmit">
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Jenis Sewa <span style="color:red">*</span></label>
                            <select class="form-control" v-model="formCreate.rental_type">
                                <option value="1">Sewa Lepas Kunci</option>
                                <option value="2">Sewa Dengan Driver</option>
                            </select>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Nama Sewa <span style="color:red">*</span></label>
                            <input type="text" class="form-control" v-model="formCreate.name"/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Kode Sewa <span style="color:red">*</span></label>
                            <input type="text" class="form-control" v-model="formCreate.code"/>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Satuan Durasi <span style="color:red">*</span></label>
                            <select class="form-control" v-model="formCreate.type">
                                <option value="1">Per Jam</option>
                                <option value="2">Per Hari</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="col-6" style="margin-right: 10px;">
                                <label class="form-label">Durasi <span style="color:red">*</span></label>
                                <input type="number" class="form-control" v-model="formCreate.duration"/>
                            </div>
                            <div class="col-6">
                                <div class="col-lg-12">
                                    <label class="form-label">Status <span style="color:red">*</span></label>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="inlineRadio1" name="status" value="true" v-model="formCreate.status">
                                        <label class="form-check-label" for="inlineRadio1">AKTIF</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="inlineRadio2" name="status" value="false" v-model="formCreate.status">
                                        <label class="form-check-label" for="inlineRadio2">TIDAK AKTIF</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end" style="padding-bottom: 10px;">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Product -->
  
    <!-- Edit Product -->
    <div class="modal fade custom-modal" id="edit-product" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Paket Sewa</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Jenis Sewa</label>
                            <select v-if="formEdit !== undefined" class="form-control" v-model="formEdit.rental_type">
                                <option value="1" :value="1" :selected="formEdit.rental_type === 1">Sewa Lepas Kunci</option>
                                <option value="2" :value="2" :selected="formEdit.rental_type === 2">Sewa Dengan Driver</option>
                            </select>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Nama Sewa</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name"/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Kode Sewa</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.code"/>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Satuan Durasi</label>
                            <select v-if="formEdit !== undefined" class="form-control" v-model="formEdit.type">
                                <option value="1">Per Jam</option>
                                <option value="2">Per Hari</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="col-6" style="margin-right: 10px;">
                                <label class="form-label">Durasi</label>
                                <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.duration"/>
                            </div>
                            <div class="col-6">
                                <div class="col-lg-12">
                                    <label class="form-label">Status</label>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-check form-check-inline">
                                        <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.status === 1" type="radio" id="inlineRadio1" name="status" value="true" v-model="formEdit.status">
                                        <label class="form-check-label" for="inlineRadio1">AKTIF</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.status === 2" type="radio" id="inlineRadio2" name="status" value="false" v-model="formEdit.status">
                                        <label class="form-check-label" for="inlineRadio2">TIDAK AKTIF</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end" style="padding-bottom: 10px;">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save Changes</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Product -->
  
    <div class="modal fade" id="delete-product" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Paket Sewa ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {}
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object
    },
    emits: ['edit-product'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
    },
    methods: {
      editSubmit() {
        this.formEdit.status = this.formEdit.status === 'true' ? 1 : 0;
        this.formEdit.is_bbm = this.formEdit.is_bbm === 'true' ? 1 : 0;
        this.formEdit.is_tol = this.formEdit.is_tol === 'true' ? 1 : 0;
        this.formEdit.is_driver = this.formEdit.is_driver === 'true' ? 1 : 0;
        this.formEdit.is_destination = this.formEdit.is_destination === 'true' ? 1 : 0;
        this.formEdit.is_parking = this.formEdit.is_parking === 'true' ? 1 : 0;
        // console.log(this.formEdit);
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("product/updateProduct", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {}
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createSubmit() {
        this.formCreate.status = this.formCreate.status === 'true' ? 1 : 0;
        this.formCreate.is_bbm = this.formCreate.is_bbm === 'true' ? 1 : 0;
        this.formCreate.is_tol = this.formCreate.is_tol === 'true' ? 1 : 0;
        this.formCreate.is_driver = this.formCreate.is_driver === 'true' ? 1 : 0;
        this.formCreate.is_destination = this.formCreate.is_destination === 'true' ? 1 : 0;
        this.formCreate.is_parking = this.formCreate.is_parking === 'true' ? 1 : 0;
        console.log(this.formCreate);
        var loading = this.$loading.show()
        this.$store
          .dispatch("product/addProduct", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {}
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteData() {
        // Perform deletion using this.deleteItemId
        console.log('Deleting item with ID:', this.deleteItemId);
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("product/deleteProduct", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      }
    }
  };
  </script>
  