<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content w-100 ps-0 pt-0">
        <sidelinkheading />

        <div class="row">
          <div class="content-table">
            <div class="content-page-header content-page-headersplit">
              <h5>Database Backup</h5>
              <div class="page-head-btn">
                <a
                  class="btn btn-primary"
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#add-tax"
                  ><i class="fa fa-plus me-2"></i>Generate Database Backup</a
                >
              </div>
            </div>
            <div class="col-12">
              <div class="table-resposnive table-bottom">
                <div class="table datatable">
                  <a-table :columns="columns" :data-source="data">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'File_Name'">
                        <td>{{ record.File_Name }}</td>
                      </template>
                      <template v-else-if="column.key === 'id'">
                        <div class="action-language">
                          <ul>
                            <li>
                              <a href="javascript:void(0);" class="delete-table"
                                ><i class="fa fa-download"></i
                              ></a>
                            </li>
                            <li>
                              <a
                                class="delete-table"
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#delete"
                                ><i class="far fa-trash-alt"></i
                              ></a>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <database-backup-model></database-backup-model>
</template>

<script>
const columns = [
  {
    title: "File_Name",
    dataIndex: "File_Name",
    key: "File_Name",
    sorter: {
      compare: (a, b) => {
        a = a.File_Name.toLowerCase();
        b = b.File_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Code.toLowerCase();
        b = b.Code.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
  },
];
const data = [
  {
    File_Name: "Sed ut perspiciatis omni_db.sql",
    Date: "27 Sep 2023 10:11AM",
    Action: "",
  },
  {
    File_Name: "Sed ut perspiciatis omni_db.sql",
    Date: "27 Sep 2023 10:11AM",
    Action: "",
  },
  {
    File_Name: "Sed ut perspiciatis omni_db.sql",
    Date: "27 Sep 2023 10:11AM",
    Action: "",
  },
  {
    File_Name: "Sed ut perspiciatis omni_db.sql",
    Date: "27 Sep 2023 10:11AM",
    Action: "",
  },
  {
    File_Name: "Sed ut perspiciatis omni_db.sql",
    Date: "27 Sep 2023 10:11AM",
    Action: "",
  },
];
export default {
  data() {
    return {
      columns,
      data,
    };
  },
};
</script>
