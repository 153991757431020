<template>
  <!-- Interesting & Useful Blogs section -->
  <section class="useful-blog-section">
    <div class="container">
      <div class="section-heading section-heading-four">
        <div class="row">
          <div class="col-md-6 aos" data-aos="fade-up">
            <h2>Interesting & Useful Blogs</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
          <div class="col-md-6 text-md-end aos" data-aos="fade-up">
            <router-link to="/blog-details" class="btn btn-primary btn-view"
              >VIEW ALL BLOGS<i class="feather-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel useful-four-slider common-four-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexfourblog" :key="item.id">
                <div class="service-widget service-widget-space aos" data-aos="fade-up">
                  <div class="service-img">
                    <router-link to="/blog-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                  </div>
                  <div class="service-content service-four-blogs">
                    <h3 class="title">
                      <router-link to="/blog-details"
                        >Lorem Ipsum is simply dummy text of the
                      </router-link>
                    </h3>
                    <p>
                      we are also engage in offering catering services and decoration
                      services where vision...
                    </p>
                    <div class="usefull-bottom">
                      <h6>{{ item.name }}</h6>
                      <div class="useful-img">
                        <router-link to="/customer-profile"
                          ><img
                            :src="require(`@/assets/img/profiles/${item.avatar}`)"
                            alt=""
                        /></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Interesting & Useful Blogs section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfourblog from "@/assets/json/indexfourblog.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexfourblog: indexfourblog,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
