<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Menu Management</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-selects">
                  <div class="form-group mb-0">
                    <div class="group-image">
                      <img src="@/assets/admin_img/flags/us1.png" alt="img" />

                      <vue-select
                        class="select"
                        :options="Choose"
                        placeholder="Language"
                      />
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <router-link class="btn btn-primary" to="/admin/create-menu"
                  ><i class="fa fa-plus me-2"></i>Create Menu</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Status'">
                      <h6 class="badge-active">{{ record.Status }}</h6>
                    </template>
                    <template v-else-if="column.key === 'Language_Edit'">
                      <div class="filter-select-set">
                        <div class="form-group mb-0">
                          <div class="group-image">
                            <img
                              :src="
                                require(`@/assets/admin_img/flags/${record.Flag_Img}`)
                              "
                              alt="img"
                            />
                            <vue-select :options="Select" placeholder="Language" />
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <router-link
                          class="delete-table me-2"
                          to="/admin/edit-management"
                        >
                          <img src="@/assets/admin_img/icons/edit.svg" alt="svg" />
                        </router-link>
                        <a
                          class="delete-table"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <img src="@/assets/admin_img/icons/delete.svg" alt="svg" />
                        </a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <delete-model :delete_title="delete_title" :delete_text="delete_text" />
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Title",
    dataIndex: "Title",
    sorter: {
      compare: (a, b) => {
        a = a.Title.toLowerCase();
        b = b.Title.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created At",
    dataIndex: "Created_At",
    sorter: {
      compare: (a, b) => {
        a = a.Created_At.toLowerCase();
        b = b.Created_At.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  {
    title: "Language Edit",
    dataIndex: "Language_Edit",
    key: "Language_Edit",
    sorter: {
      compare: (a, b) => {
        a = a.Language_Edit.toLowerCase();
        b = b.Language_Edit.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];

const data = [
  {
    id: "1",
    Title: "Primary Menu",
    Created_At: "30 Sep 2023",
    Status: "Published",
    Language_Edit: "",
    Flag_Img: "us1.png",
    Action: "",
  },
  {
    id: "2",
    Title: "FooterDefault",
    Created_At: "30 Sep 2023",
    Status: "Published",
    Language_Edit: "",
    Flag_Img: "us1.png",
    Action: "",
  },
  {
    id: "3",
    Title: "Main Menu",
    Created_At: "14 Sep 2023",
    Status: "Published",
    Language_Edit: "",
    Flag_Img: "us1.png",
    Action: "",
  },
  {
    id: "4",
    Title: "AboutDefault",
    Created_At: "10 Sep 2023",
    Status: "Published",
    Language_Edit: "",
    Flag_Img: "us1.png",
    Action: "",
  },
  {
    id: "5",
    Title: "More Information",
    Created_At: "23 Sep 2023",
    Status: "Published",
    Language_Edit: "",
    Flag_Img: "us1.png",
    Action: "",
  },
];
export default {
  data() {
    return {
      Choose: ["Language", "English", "Spanish"],
      Select: ["Language", "English", "Spanish"],
      columns,
      data,
      delete_title: "Delete Menu management",
      delete_text: "Are you sure want to Delete?",
    };
  },
};
</script>
