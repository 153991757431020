<template>
    <!-- pricing Section -->
		<section class="price-sections">			
			<div class="container">
				<div class="row">
					<div class="col-md-12 text-center aos" data-aos="fade-up">
						<div class="section-heading sec-header">
							<h2>Pricing Plans</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
						</div>
					</div>
				</div>
				<div class="price-toggle mb-0">
					<div class="status-toggle toggle-pink d-inline-flex align-items-center">
						Bill Monthly
						<input type="checkbox" id="status_1" class="check">
						<label for="status_1" class="checktoggle">checkbox</label>
						Bill Annualy
					</div>
				</div>
				<div class="row aos" data-aos="fade-up">
					<div class="col-lg-4 col-sm-12">
						<div class="pricing-plans price-new">
							<div class="pricing-planshead">
								<h6>$50<span>/month</span></h6>
								<h4>Starter</h4>
								<h5>Lorem ipsum dolor sit amet, consectetur</h5>
							</div>
							<div class="pricing-planscontent">
								<ul>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Sed perspiciatis unde omnis natus error</span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Lorem dolor consecteturadipiscing elit</span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Duis irure dolor reprehenderit voluptate</span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Nemo enim ipsam voluptatem quia </span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Sed perspiciatis iste natus error </span>
									</li>
								</ul>
								<div class="pricing-btn">
									<router-link to="/search" class="btn btn-view">Choose Plan</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-12">
						<div class="pricing-plans price-new active">
							<div class="pricing-planshead">
								<div class="price-block">
									<span class="popular">MOST POPULAR</span>
								</div>
								<h6>$100<span>/month</span></h6>
								<h4>Company</h4>
								<h5>Lorem ipsum dolor sit amet, consectetur</h5>
							</div>
							<div class="pricing-planscontent">
								<ul>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Sed perspiciatis unde omnis natus error</span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Lorem dolor consecteturadipiscing elit</span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Duis irure dolor reprehenderit voluptate</span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Nemo enim ipsam voluptatem quia </span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Sed perspiciatis iste natus error </span>
									</li>
								</ul>
								<div class="pricing-btn">
									<router-link to="/search" class="btn btn-view">Choose Plan</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-12">
						<div class="pricing-plans price-new">
							<div class="pricing-planshead">
								<h6>$150<span>/month</span></h6>
								<h4>Professional</h4>
								<h5>Lorem ipsum dolor sit amet, consectetur</h5>
							</div>
							<div class="pricing-planscontent">
								<ul>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Sed perspiciatis unde omnis natus error</span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Lorem dolor consecteturadipiscing elit</span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Duis irure dolor reprehenderit voluptate</span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Nemo enim ipsam voluptatem quia </span>
									</li>
									<li>
										<i class="fa fa-check-circle me-2"></i>
										<span>Sed perspiciatis iste natus error </span>
									</li>
								</ul>
								<div class="pricing-btn">
									<router-link to="/search" class="btn btn-view">Choose Plan</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /pricing Section -->
		
		<!-- App Section -->
		<section class="app-section-two">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-md-6 col-12">
						<div class="appimg aos" data-aos="fade-up">
							<img src="@/assets/img/mobile.png" class="img-fluid" alt="img">
						</div>
					</div>
					<div class="col-md-6 col-12">	
						<div class="download-sec">
							<div class="section-heading-two">
								<p>Download App</p>
								<h2>We're available on mobile Just download it!</h2>
							</div>
							<div class="heading aos" data-aos="fade-up">
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut magna aliqua. Ut enim ad minimi ut aliquip ex ea commodo consequat.</p>
								<h6>Scan the QR code to get the app now</h6>
								<div class="scan-img">
									<img src="@/assets/img/scan-img.png" class="img-fluid" alt="img">
								</div>
							</div>
							<div class="downlaod-btn aos" data-aos="fade-up">
								<a href="javascript:;" target="_blank"><img src="@/assets/img/appstore.svg" alt="img"></a>
								<a href="javascript:;" target="_blank"><img src="@/assets/img/googleplay.svg" alt="img"></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /App Section -->
</template>