<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>
      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-12 col-md-6 aos" data-aos="fade-up">
                  <h4>List Absen Driver</h4>
                  <p>List data absen driver disini!</p>
                </div>
                <div class="col-12 col-md-6 text-md-end aos mt-3 mt-md-0" data-aos="fade-up">
                  <div class="input-group justify-content-end">
                    <!-- Use full width for input and buttons on smaller screens -->
                    <router-link to="/mari/driver/home" class="btn btn-primary ms-2 mt-2 mt-md-0">Home</router-link>
                    <button
                      class="btn btn-primary ms-2 mt-2 mt-md-0"
                      type="button"
                      :data-bs-toggle="'modal'"
                      :data-bs-target="dataExists ? '#update-absen-driver' : '#absen-driver'"
                      id="button-addon2"
                      style="min-width: 100px;"
                    >
                      Absen
                    </button>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row mt-4">
              <div class="col-12">
                <div class="table-responsive table-div">
                  <a-table :columns="columns" :data-source="data" :pagination="pagination">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'file'">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="record.file"
                            class="me-2"
                            alt="img"
                            style="max-width: 100px; max-height: 100px;"
                          />
                        </a>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <report-driver-model @dataUpdated="getData"></report-driver-model>
    </div>
  </template>  
  
  <script>
  import Dialog from '@/views/mari/components/dialog.vue'
  import Datalist from '@/views/mari/components/datalist.vue'
  import VuePdfEmbed from 'vue-pdf-embed'
  // import 'vue-pdf-embed/dist/style/index.css'

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Nama Driver",
      dataIndex: "driverName",
      key: "driverName",
      sorter: {
        compare: (a, b) => {
          a = a.driverName.toLowerCase();
          b = b.driverName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    
    // {
    //   title: "Status Driver",
    //   dataIndex: "statusDriver",
    //   key: "statusDriver",
    //   sorter: {
    //     compare: (a, b) => {
    //       a = a.statusDriver.toLowerCase();
    //       b = b.statusDriver.toLowerCase();
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    // },
    {
      title: "Jam Absen",
      dataIndex: "createdAtTime",
      key: "createdAtTime",
      sorter: {
        compare: (a, b) => {
          a = a.createdAtTime.toLowerCase();
          b = b.createdAtTime.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Action",
    //   key: "id",
    //   sorter: true,
    //   class: "text-end",
    // },
  ];
  export default {
    components: {
      VuePdfEmbed,
      Dialog,
    },
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "created_at",
            sorttype: "asc",
            page: 1,
            absenDate: ""
        },
        paramAbsen: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            date: ""
        },
        editData: {},
        itemIdToDelete: {},
        dataRole: [],
        dataMenu: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        invoice: null,
        dataExists: null
      };
    },
    name: "brandsetting",
    computed: {

    },
    methods: {
        search() {
          this.pagination.current = 1;
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            if (this.params.absenDate == '') {
              const now = new Date();
              const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Jakarta' }; // Adjust timeZone if needed
              const formatter = new Intl.DateTimeFormat('en-CA', options);
              const today = formatter.format(now).replace(/\//g, '-');
              this.params.absenDate = today;
            }
            this.$store
                .dispatch("driver/getAbsenDriver", this.params)
                .then((resp) => {
                  const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
                    this.data = resp.data.data.map((item, index) => ({
                      ...item,
                      index: startIndex + index + 1 ,
                      driverName: item.user.full_name,
                      statusDriver: item.user.status_mudi,
                      createdAtTime: this.formatTime(item.created_at)
                    }));
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    console.log(this.data);
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        formatTime(datetimeString) {
          const date = new Date(datetimeString);
          return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        },
        async getAbsen() {
        const userData = localStorage.getItem('user');
        const user = JSON.parse(userData);
        this.userLogin = user;
        this.paramAbsen.keyword = user.id;
        const now = new Date();
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Jakarta' }; // Adjust timeZone if needed
        const formatter = new Intl.DateTimeFormat('en-CA', options);
        const today = formatter.format(now).replace(/\//g, '-');
        this.paramAbsen.date = today;
        var loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("driver/getAbsenDriverCurrent", this.paramAbsen);
          this.dataAbsen = resp.data.data;
          const now = new Date();
          const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Jakarta' }; // Adjust timeZone if needed
          const formatter = new Intl.DateTimeFormat('en-CA', options);
          const today = formatter.format(now).replace(/\//g, '-');
          this.dataExists = !!this.dataAbsen.find(entry => entry.absen_masuk === today);
          this.dataAbsen = this.dataAbsen.find(entry => entry.absen_masuk === today);
        } catch (e) {
          this.$toast.error(e);
        } finally {
          loading.hide();
        }
      },
    },
    mounted() {
        this.getAbsen();
        this.getData();
    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>
