<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Wallet</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data" >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'User_Name'">
                      <td class="table-profileimage">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="require(`@/assets/admin_img/customer/${record.Image}`)"
                            class="me-2"
                            alt="img"
                          />
                        </a>
                        <a href="javascript:void(0);" class="table-name">
                          <span>{{ record.User_Name }}</span>
                          <p>{{ record.Email }}</p>
                        </a>
                      </td>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <router-link to="/admin/wallet-history" class="btn btn-history"
                        >History</router-link
                      >
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User Name",
    dataIndex: "User_Name",
    key: "User_Name",
    sorter: {
      compare: (a, b) => {
        a = a.User_Name.toLowerCase();
        b = b.User_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    sorter: {
      compare: (a, b) => {
        a = a.Phone.toLowerCase();
        b = b.Phone.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Balance",
    dataIndex: "Balance",
    sorter: {
      compare: (a, b) => {
        a = a.Balance.toLowerCase();
        b = b.Balance.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
  },
];
const data = [
  {
    id: "1",
    Image: "user-01.jpg",
    User_Name: "John Smith",
    Email: "johnsmith@gmail.com",
    Phone: "+1 843-443-3282",
    Balance: "$1000",
  },
  {
    id: "2",
    Image: "user-07.jpg",
    User_Name: "Johnny",
    Email: "johnny@gmail.com",
    Phone: "+1 917-409-0861",
    Balance: "$800",
  },
  {
    id: "3",
    Image: "user-09.jpg",
    User_Name: "Robert",
    Email: "robert@gmail.com",
    Phone: "+1 956-623-2880",
    Balance: "$700",
  },
  {
    id: "4",
    Image: "user-06.jpg",
    User_Name: "Sharonda",
    Email: "sharonda@gmail.com",
    Phone: "+1 559-741-9672",
    Balance: "$600",
  },
  {
    id: "5",
    Image: "user-04.jpg",
    User_Name: "Nicholas",
    Email: "nicholas@gmail.com",
    Phone: "+1 559-741-9672",
    Balance: "$500",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
