<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>Kendaraan</h4>
                  <p>Kamu bisa lihat daftar kendaraan disni!</p>
                </div>
                <div class="col-md-6 text-md-end aos" data-aos="fade-up">
                  <router-link to="/mari/create-vehicle?condition=list" class="btn btn-primary btn-sm" type="button"><i class="fa fa-plus me-2"></i>Tambah Kendaraan
                  </router-link>
                </div>
              </div>
              
              <div class="row mt-4">
                <div class="input-group" style="justify-content: end;">
                  <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="max-width: 25% !important;" @keyup.enter="search">
                  <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <div class="table-resposnive table-div">
                    <div class="table datatable">
                      <a-table :columns="columns" :data-source="data" :pagination="pagination" style="overflow-x: auto;">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'Status'">
                            <h6 v-if="record.status === 'AKTIF'" class="badge-active">{{ record.status }}</h6>
                            <h6 v-else class="badge-active">{{ record.status }}</h6>
                          </template>
                          <template v-if="column.key === 'id'">
                            <div class="table-actions d-flex">
                                <button
                                class="btn delete-table me-2"
                                type="button"
                                @click="goToDetailPage(record)"
                              >
                                <i class="feather feather-eye"></i>
                              </button>
                              <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-vehicle"
                                @click="openEditModal(record)"
                              >
                                <i class="feather feather-edit"></i>
                              </button>
                              <button
                                class="btn delete-table me-2"
                                type="button"
                                @click="openInvoice(record)"
                              >
                                <i class="fa-solid fa-file-invoice"></i>
                              </button>
                              <!-- <button
                                class="btn delete-table"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#delete"
                                @click="openDeleteModal(record)"
                              >
                                <i class="feather feather-trash-2"></i>
                              </button> -->
                            </div>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vehicle-model :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :brandData="dataBrand" :vendorData="dataVendor" @dataUpdated="getData"></vehicle-model>
    <Dialog ref="dialog">
      <div style="width: 400px; height: 600px;">
        <header>
          <button class="btn btn-secondary" @click="onDownload">
            <i class="fa-solid fa-download"></i>
            Download
          </button>
        </header>
        <VuePdfEmbed v-if="invoice" ref="pdfEmbed" :source="invoice" />
      </div>
    </Dialog>
  </template>
  
  <script>
  import Dialog from '@/views/mari/components/dialog.vue'
  import VuePdfEmbed from 'vue-pdf-embed'
  // import 'vue-pdf-embed/dist/style/index.css'

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Jenis Kendaraan",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Nome Plat",
      dataIndex: "nomor_plat",
      key: "nomor_plat",
      sorter: {
        compare: (a, b) => {
          a = a.nomor_plat.toLowerCase();
          b = b.nomor_plat.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Transmisi",
      dataIndex: "transmisi",
      key: "transmisi",
      sorter: {
        compare: (a, b) => {
          a = a.transmisi.toLowerCase();
          b = b.transmisi.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
      key: "capacity",
      sorter: {
        compare: (a, b) => {
          a = a.capacity.toLowerCase();
          b = b.capacity.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Production Year",
      dataIndex: "production_year",
      key: "production_year",
      sorter: {
        compare: (a, b) => {
          a = a.production_year.toLowerCase();
          b = b.production_year.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Brand",
      dataIndex: "brandName",
      key: "brandName",
      sorter: {
        compare: (a, b) => {
          a = a.brandName.toLowerCase();
          b = b.brandName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Kepemilikan",
      dataIndex: "vendorName",
      key: "vendorName",
      sorter: {
        compare: (a, b) => {
          a = a.vendorName.toLowerCase();
          b = b.vendorName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    components: {
      VuePdfEmbed,
      Dialog,
    },
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        paramVendor: {
            row: 100,
            keyword: "",
            sortby: "full_name",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataProduct: [],
        dataBrand: [],
        dataVendor: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        }
      };
    },
    name: "vehiclesetting",
    computed: {

    },
    methods: {
        async fetchAllData() {
          await Promise.all([
            this.getVendor(),
            this.getBrand(),
            this.getProduct(),
            this.getData()
          ]);
        },
        search() {
          this.params.page = 1;
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        async openInvoice(record) {
            const loading = this.$loading.show()
            const previewBlob = await this.$store.dispatch('vehicle/createRecapPdf', record.id)
            const newBlob = new Blob([previewBlob], { type: 'application/pdf' });

            function blobToBase64(blob) {
                return new Promise((resolve, _) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(blob);
                });
            }

            this.invoice = await blobToBase64(newBlob);
            this.$refs.dialog.open()
            loading.hide()
        },
        async onDownload() {
          await this.$refs.pdfEmbed.download('vehicle.pdf')
          this.$refs.dialog.close()
        },
        async getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params)
                .then((resp) => {
                  const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
                    this.data = resp.data.data.map((item, index) => {
                        const user = JSON.parse(localStorage.getItem('user'))
                        let company = '---'
                        if (user && user.company) {
                          company = user.company.name
                        } 
                        const brand = this.dataBrand.find(brand => brand.id == item.brand_id);
                        const brandName = brand ? brand.name : '';
                        const vendor = this.dataVendor.find(vendor => vendor.id == item.vendor_id);
                        const vendorName = vendor ? vendor.full_name : company;
                        return {
                          ...item,
                          index: startIndex + index + 1 ,
                          brandName,
                          vendorName
                        };
                    });
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    loading.hide();
                    console.log(this.data);
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
        goToDetailPage(data) {
          this.$router.push(`/mari/detail-vehicle/${data.id}`);
        },
        async getProduct() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("product/getProduct", this.params);
            this.dataProduct = resp.data.data.map((item, index) => {
                let rentalTypeName;
                if (item.rental_type == 1) {
                    rentalTypeName = "Sewa Lepas Kunci";
                } else {
                    rentalTypeName = "Sewa Dengan Driver";
                }
                return {
                    ...item,
                    rentalTypeName,
                };
            });
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching product data:", e);
          } finally {
            loading.hide();
          }
        },
        async getBrand() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("brand/getBrandAll");
            this.dataBrand = resp.data;
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching brand data:", e);
          } finally {
            loading.hide();
          }
        },
        async getVendor() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("vendor/getVendorAll");
            this.dataVendor = resp.data;
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching vendor data:", e);
          } finally {
            loading.hide();
          }
        },
    },
    async mounted() {
      try {
        await this.fetchAllData();
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    },
  };
  </script>
  <style>
  .ant-select-selection-item {
    height: 30px;
    width: 90px;
    margin-top: -6px;
    font-size: 15px;
  }
  </style>
