<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Permission</h5>
        </div>

        <div class="role-wrap">
          <div class="row">
            <div class="col-md-6">
              <h6>Role Name: <span>Testings</span></h6>
            </div>
            <div class="col-md-6">
              <label class="checkboxs justify-content-md-end">
                <input type="checkbox" />
                <span><i></i></span>
                <b class="check-content">Allow All Modules</b>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive role-table">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data" >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Modules'">
                      <td class="mod-name">{{ record.Modules }}</td>
                    </template>
                    <template v-else-if="column.key === 'Create'">
                      <label class="checkboxs mb-0">
                        <input type="checkbox" />
                        <span><i></i></span>
                      </label>
                    </template>
                    <template v-else-if="column.key === 'Edit'">
                      <label class="checkboxs mb-0">
                        <input type="checkbox" />
                        <span><i></i></span>
                      </label>
                    </template>
                    <template v-else-if="column.key === 'Delete'">
                      <label class="checkboxs mb-0">
                        <input type="checkbox" />
                        <span><i></i></span>
                      </label>
                    </template>
                    <template v-else-if="column.key === 'View'">
                      <label class="checkboxs mb-0">
                        <input type="checkbox" />
                        <span><i></i></span>
                      </label>
                    </template>
                    <template v-else-if="column.key === 'Allow_all'">
                      <label class="checkboxs mb-0">
                        <input type="checkbox" />
                        <span><i></i></span>
                      </label>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>

            <div class="btn-path text-center mt-4">
              <a href="javascript:void(0);" class="btn btn-cancel me-3">Back</a>
              <button type="submit" class="btn btn-primary">Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <roles-model></roles-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Modules",
    dataIndex: "Modules",
    key: "Modules",
    sorter: {
      compare: (a, b) => {
        a = a.Modules.toLowerCase();
        b = b.Modules.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Sub Modules",
    dataIndex: "Sub_Modules",
    sorter: {
      compare: (a, b) => {
        a = a.Sub_Modules.toLowerCase();
        b = b.Sub_Modules.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Create",
    dataIndex: "Create",
    key: "Create",
    sorter: {
      compare: (a, b) => {
        a = a.Create.toLowerCase();
        b = b.Create.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Edit",
    dataIndex: "Edit",
    key: "Edit",
    sorter: {
      compare: (a, b) => {
        a = a.Edit.toLowerCase();
        b = b.Edit.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Delete",
    dataIndex: "Delete",
    key: "Delete",
    sorter: {
      compare: (a, b) => {
        a = a.Delete.toLowerCase();
        b = b.Delete.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "View",
    dataIndex: "View",
    key: "View",
    sorter: {
      compare: (a, b) => {
        a = a.View.toLowerCase();
        b = b.View.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Allow all",
    dataIndex: "Allow_all",
    key: "Allow_all",
    sorter: {
      compare: (a, b) => {
        a = a.Allow_all.toLowerCase();
        b = b.Allow_all.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    id: "1",
    Modules: "Dashboard",
    Sub_Modules: "Dashboard",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
  {
    id: "2",
    Modules: "Appointments",
    Sub_Modules: "Appointments",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
  {
    id: "3",
    Modules: "Specialization",
    Sub_Modules: "Specialization",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
  {
    id: "4",
    Modules: "Sub Categories",
    Sub_Modules: "Sub Categories",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
  {
    id: "5",
    Modules: "Clients",
    Sub_Modules: "Clients",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
  {
    id: "6",
    Modules: "Payment Request",
    Sub_Modules: "Payment Request",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
  {
    id: "7",
    Modules: "Reviews",
    Sub_Modules: "Reviews",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
  {
    id: "8",
    Modules: "Subscription",
    Sub_Modules: "Subscription",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
  {
    id: "9",
    Modules: "Settings",
    Sub_Modules: "Settings",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
  {
    id: "10",
    Modules: "Email Templates",
    Sub_Modules: "Email Templates",
    Create: "",
    Edit: "",
    Delete: "",
    View: "",
    Allow_all: "",
  },
];
export default {
  data() {
    return {
      columns,
      data,
    };
  },
};
</script>
