const areaChart = {
    series: [{
        name: 'Series 1',
        data: [11, 32, 45, 32, 34, 52, 41],
      }, {
        name: 'Series 2',
        data: [31, 40, 28, 51, 42, 109, 100],
      }],
      chart: {
        id: "vuechart-example",
      },
      chart: {
        height: 350,
        type: 'area',
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        },
        colors:[' #4169E1', '#4169E1'],
        dataLabels: {
          enabled: false
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
              enabled: true,
              delay: 150
          },
          dynamicAnimation: {
              enabled: true,
              speed: 350
          }
        },
        toolbar: {
          show: false
        } ,
        legend: {
            show: false,
       },
    stroke: {
      width: 2
    },
    fill: {
      opacity: 0.1
    },
    markers: {
      size: 0
    },
    xaxis: {
        type: 'month',
        categories: ["jan", "feb", "march", "april", "may", "june", "july"]
      },
    },
};

const barChart ={
    colors: ['#4169E1'],
    series: [
        {
        name: "Received",
        type: "column",
        data: [70, 150, 80, 180, 150, 175, 201, 60, 200, 120, 190, 160, 50]
        }
    ],
    chart: {
    id: "vuechart-example",
    },
      chart: {
        type: 'bar',
        fontFamily: 'Poppins, sans-serif',
        height: 350,
        toolbar: {
            show: false
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '60%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
        },
        yaxis: {
            title: {
                text: '$ (thousands)'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands"
                }
            }
        }

    },
    
};

const areaChart1 = {
    series: [{
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100]
      }],
      chart: {
        id: "vuechart-example",
      },
      chart: {
        height: 350,
        type: 'area',
        fill: {
            colors: [' #4169E1'],
            type: 'gradient',
            gradient: {
              type: "horizontal",
              shadeIntensity: 0.1,
              gradientToColors: undefined, 
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 0.5,
              stops: [0, 50, 100],
              colorStops: []
            }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'Month',
            categories: ["Jan", "Feb", "March", "April", "May", "Jun", "July"]
        },
        tooltip: {
           
        },
    },
};

const pieChart = {
    series: [10, 45 , 45],
        chart: {
        width: 200,
        type: 'pie',
      },
    type: 'pie',
    data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [{
            label: 'Sales',
            data: [24, 10, 32, 24, 26, 20],
            backgroundColor: '#664dc9'
        }]
    },
    options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
            display: false,
            labels: {
                display: false
            }
        },
        scales: {
            yAxes: {
                ticks: {
                    beginAtZero: true,
                    fontSize: 10,
                    max: 80
                }
            },
            xAxes: {
                barPercentage: 0.6,
                ticks: {
                    beginAtZero: true,
                    fontSize: 11
                }
            }
        }
    }
};

export{
    areaChart,
    barChart,
    areaChart1,
    pieChart
}