<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />
      <sidebar />
      <div class="page-wrapper">
        <providerdevicemanagementContent />
      </div>
    </div>
  </div>
</template>
