<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Pending Services</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A -> Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <router-link class="btn btn-primary" to="/admin/add-service"
                  ><i class="fa fa-plus me-2"></i>Add Services
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <servicestab></servicestab>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Service'">
                      <div class="table-imgname">
                        <img
                          :src="require(`@/assets/admin_img/services/${record.Image}`)"
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Service }}</span>
                      </div>
                    </template>

                    <template v-else-if="column.key === 'Status'">
                      <h6 class="badge-pending">{{ record.Status }}</h6>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="action-language">
                        <router-link class="table-edit" to="edit-service">
                          <i class="fa-regular fa-pen-to-square"></i><span>Edit</span>
                        </router-link>
                        <a
                          class="table-delete"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="fa-solid fa-trash-can"></i><span>Delete</span>
                        </a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <delete-model :delete_title="delete_title" :delete_text="delete_text" />
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category",
    dataIndex: "Category",
    key: "Category",
    sorter: {
      compare: (a, b) => {
        a = a.Category.toLowerCase();
        b = b.Category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Sub Category",
    dataIndex: "Sub_Category",
    sorter: {
      compare: (a, b) => {
        a = a.Sub_Category.toLowerCase();
        b = b.Sub_Category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Duration",
    dataIndex: "Duration",
    sorter: {
      compare: (a, b) => {
        a = a.Duration.toLowerCase();
        b = b.Duration.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created By",
    dataIndex: "Created_By",
    sorter: {
      compare: (a, b) => {
        a = a.Created_By.toLowerCase();
        b = b.Created_By.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Image: "service-03.jpg",
    Service: "Computer Repair",
    Category: "Computer",
    Sub_Category: "Other",
    Amount: "$80",
    Duration: "10:00",
    Status: "Pending",
    Created_By: "Provider",
    Action: "",
  },
  {
    id: "2",
    Image: "service-02.jpg",
    Service: "Car Repair Services",
    Category: "Automobile",
    Sub_Category: "Other",
    Amount: "$50",
    Duration: "12:00",
    Status: "Pending",
    Created_By: "Admin",
    Action: "",
  },
  {
    id: "3",
    Image: "service-04.jpg",
    Service: "Steam Car Wash",
    Category: "Car Wash",
    Sub_Category: "Other",
    Amount: "$14",
    Duration: "14:00",
    Status: "Pending",
    Created_By: "Provider",
    Action: "",
  },

  {
    id: "4",
    Image: "service-09.jpg",
    Service: "House Cleaning",
    Category: "Cleaning",
    Sub_Category: "Other",
    Amount: "$100",
    Duration: "16:00",
    Status: "Pending",
    Created_By: "Admin",
    Action: "",
  },
];
export default {
  data() {
    return {
      delete_title: "Delete Services",
      delete_text: "Are you sure want to Delete?",
      columns,
      data,
      Select: ["A -> Z", "Z -> A"],
    };
  },
};
</script>
