<template>
  <div class="main-wrapper">
    <mari-layout></mari-layout>

    <div class="page-wrapper">
      <div class="content">
        <div class="container">
          <div class="section-heading">
            <div class="row">
              <div class="col-md-6 col-12 aos" data-aos="fade-up">
                <h4>Order</h4>
                <p>Kamu bisa lihat daftar order kendaraan disni!</p>
              </div>
              <div class="col-md-6 col-12 text-md-end text-center aos" data-aos="fade-up">
                <router-link to="/mari/driver/home" class="btn btn-primary mb-2">Home</router-link>
              </div>
            </div>

            <div class="content-page-header content-page-headersplit mb-0">
              <div class="list-btn">
                <ul>
                  <li>
                    <div class="input-group flex-wrap">
                      <input type="date" v-model="params.dateStart" class="form-control mb-2 mb-md-0" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                      <span class="input-group-text mb-2 mb-md-0">s/d</span>
                      <input type="date" v-model="params.dateEnd" class="form-control mb-2 mb-md-0" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                      <span class="input-group-text mb-2 mb-md-0"></span>
                      <input type="text" v-model="params.keyword" class="form-control mb-2 mb-md-0" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" @keyup.enter="search">
                      <button class="btn btn-primary mb-2 mb-md-0" @click="search" type="button" id="button-addon2">Search</button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12 d-flex">
                <div class="col-lg-4 col-md-6 col-sm-6 col-12" v-for="(item, index) in data" :key="index">
                  <div class="service-slider aos" data-aos="fade-right">
                    <div class="service-widget">
                      <div class="service-content service-content-three">
                        <h3 class="title">
                          <a href="javascript:void(0);">{{ item.no_order }}</a>
                        </h3>
                        <ul>
                          <li>{{ item.status }}</li>
                        </ul>
                        <ul>
                          <li v-for="vehicle in item.vehicleNames" :key="index" style="font-weight: bold;">{{ vehicle }}</li>
                        </ul>
                        <div class="main-saloons-profile">
                          <div class="saloon-profile-left">
                            <div class="saloon-content">
                              <div class="saloon-content-top">
                                <i class="feather-clock"></i>
                                <span>{{ item.startDate }} - {{ item.finishDate}}</span>
                              </div>										
                            </div>
                          </div>
                        </div>
                        <div class="saloon-bottom d-flex">
                          <a href="javascript:void(0);" class="dropdown-item mb-1" data-bs-toggle="modal" data-bs-target="#show-order-driver" @click="openEditModal(item)">
                            <i class="feather feather-eye"></i> Detail Order
                          </a>
                          <a href="javascript:void(0);" class="dropdown-item mb-1" data-bs-toggle="modal" data-bs-target="#list-inspection" @click="openInspectionModal(item)">
                            <i class="feather feather-truck"></i> Inspeksi Kendaraan
                          </a>
                        </div>
                      </div>
                    </div>				
                  </div>
                </div>
                <!-- <div class="table-responsive table-div">
                  <div class="table datatable">
                    <a-table :columns="columns" :data-source="data" :pagination="pagination" style="overflow-x: auto;">
                      <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'Status'">
                          <h6 v-if="record.status === 'AKTIF'" class="badge-active">{{ record.status }}</h6>
                          <h6 v-else class="badge-active">{{ record.status }}</h6>
                        </template>
                        <template v-if="column.key === 'id'">
                          <td class="text-center">
                            <a class="delete-table" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="true">
                              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </a>
                            <ul class="dropdown-menu">
                              <li>
                                <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#show-order-driver" @click="openEditModal(record)">
                                  <i class="feather feather-eye"></i> Detail Order
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#list-inspection" @click="openInspectionModal(record)">
                                  <i class="feather feather-truck"></i> Inspeksi Kendaraan
                                </a>
                              </li>
                            </ul>
                          </td>
                        </template>
                      </template>
                    </a-table>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="blog-pagination rev-page">
                  <nav>
                    <ul class="pagination justify-content-center">
                      <!-- Previous Page Button -->
                      <li class="page-item" :class="{ disabled: pagination.current === 1 }">
                        <a class="page-link page-prev" href="javascript:void(0);" @click="changePage(pagination.current - 1)" tabindex="-1">
                          <i class="fa-solid fa-arrow-left me-1"></i> PREV
                        </a>
                      </li>

                      <!-- Page Numbers -->
                      <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: page === pagination.current }">
                        <a class="page-link" href="javascript:void(0);" @click="changePage(page)">
                          {{ page }}
                        </a>
                      </li>

                      <!-- Next Page Button -->
                      <li class="page-item" :class="{ disabled: pagination.current === totalPages }">
                        <a class="page-link page-next" href="javascript:void(0);" @click="changePage(pagination.current + 1)">
                          NEXT <i class="fa-solid fa-arrow-right ms-1"></i>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <order-model :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :customerData="dataCustomer" :vehicleData="dataVehicle" :brandData="dataBrand" :driverData="dataDriver" :staffData="dataStaff" :vehicleId="vehicleId ? vehicleId : null" @dataUpdated="getData"></order-model>
            <inspection-model :editData="editData" :productData="dataProduct" :customerData="dataCustomer" :vehicleData="dataVehicle" :brandData="dataBrand" :driverData="dataDriver" :staffData="dataStaff" :orderData="dataOrder" :userId="userLogin" :userLogin="userLogin" @dataUpdated="getData"></inspection-model>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    sorter: true, 
    fixed: 'left' 
  },
  {
    title: "No Order",
    dataIndex: "no_order",
    key: "no_order",
    sorter: {
      compare: (a, b) => {
        a = a.no_order.toLowerCase();
        b = b.no_order.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Pelanggan",
  //   dataIndex: "customerName",
  //   key: "customerName",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.customerName.toLowerCase();
  //       b = b.customerName.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Jenis Sewa",
  //   dataIndex: "productName",
  //   key: "productName",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.productName.toLowerCase();
  //       b = b.productName.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Tanggal Berangkat",
  //   dataIndex: "startDate",
  //   key: "startDate",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.startDate.toLowerCase();
  //       b = b.startDate.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Jam Berangkat",
  //   dataIndex: "starting_time",
  //   key: "starting_time",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.starting_time.toLowerCase();
  //       b = b.starting_time.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Tanggal Kembali",
  //   dataIndex: "finishDate",
  //   key: "finishDate",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.finishDate.toLowerCase();
  //       b = b.finishDate.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  // {
  //   title: "Kendaraan",
  //   dataIndex: "vehicleName",
  //   key: "vehicleName",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.vehicleName.toLowerCase();
  //       b = b.vehicleName.toLowerCase();
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
    fixed: 'right',
    width: 100,
  },
];
export default {
  data() {
    return {
      columns,
      data: [],
      Select: ["A -> Z", "Z -> A"],
      params: {
          row: 10,
          keyword: "",
          sortby: "id",
          sorttype: "desc",
          page: 1,
          dateStart: '',
          dateEnd: '',
          jadwal: false,
          driverId: null
      },
      paramDriver: {
          row: 10,
          keyword: "Driver",
          sortby: "id",
          sorttype: "asc",
          page: 1
      },
      paramStaff: {
          row: 10,
          keyword: "Staff Kendaraan",
          sortby: "id",
          sorttype: "asc",
          page: 1
      },
      editData: {},
      itemIdToDelete: {},
      dataProduct: [],
      dataVehicle: [],
      dataCustomer: [],
      dataVendor: [],
      dataDriver: [],
      dataStaff: [],
      dataBrand: [],
      pagination: {
        current: 1, // Initial current page number
        pageSize: 10, // Number of items per page
        total: 0, // Total number of items
        showSizeChanger: true, // Show size changer
        pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
        showTotal: (total) => `Total ${total} items`, // Function to display total items
        onChange: this.handlePageChange, // Function to handle page change
        onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
      },
      totalB: 0,
      totalP: 0,
      total: 0,
      dataOrder: {},
      previewPdf: null,
      dataUserCompany: null,
      orderId: null
    };
  },
  name: "ordersetting",
  computed: {
    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.pageSize);
    },
  },
  methods: {
      async fetchAllData() {
        await Promise.all([
          this.getLoginCompany(),
          this.getVendor(),
          this.getBrand(),
          this.getProduct(),
          this.getVehicle(),
          this.getCustomer(),
          this.getDriver(),
          this.getStaff(),
          this.getData()
        ]);
      },
      search() {
        this.params.page = 1;
        this.getData();
      },
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.handlePageChange(page);
        }
      },
      handlePageChange(page) {
        this.pagination.current = page;
        this.params.page = page;
        this.getData();
      },
      handlePageSizeChange(current, size) {
        this.pagination.pageSize = size;
        this.pagination.current = 1;
        this.params.row = size;
        this.params.page = 1;
        this.getData();
      },
      async openPreviewInvoice(data) {
        const previewBlob = await this.$store.dispatch('invoice/getPreview', data.id)
        const newBlob = new Blob([previewBlob], { type: 'application/pdf' });

        // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
            // For other browsers: create a link pointing to the ObjectURL containing the blob.
            const objUrl = window.URL.createObjectURL(newBlob);

            this.previewPdf = objUrl


            // For Firefox it is necessary to delay revoking the ObjectURL.
            setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
        }
        console.log('preview', previewBlob)
      },
      openEditModal(data) {
        // Set the edit data before opening the modal
        this.editData = { data };
      },
      openReportModal(data) {
        // Set the edit data before opening the modal
        this.orderId = { data };
      },
      openDeleteModal(data) {
        // Set the edit data before opening the modal
        this.itemIdToDelete = { data };
      },
      openInspectionModal(data) {
        this.dataOrder = { data };
      },
      async getData() {
        const loading = this.$loading.show();
        try {
          const userData = localStorage.getItem('user');
          const user = JSON.parse(userData);
          this.userLogin = user;
          this.params.driverId = this.userLogin.id;
          const resp = await this.$store.dispatch("order/getOrderDriver", this.params);
          const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
          this.data = resp.dataOrder.data.map((item, index) => {
            const sDate = new Date(item.starting_date);
            const fDate = new Date(item.finish_date);
            const time = new Date(item.starting_time);
            // const startTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const vehicle = this.dataVehicle.find(vehicle => vehicle.id === item.vehicle_id);
            const product = this.dataProduct.find(product => product.id === item.product_id);
            const productName = product ? product.name : '';
            const customer = this.dataCustomer.find(customer => customer.id === item.customer_id);
            const customerName = customer ? customer.name : '';
            // Get the vehicle names from order_vehicle and order_vehicle_detail
            const vehicleNames = item.order_vehicle.flatMap(orderVehicle =>
              orderVehicle.order_vehicle_detail.map(detail => {
                const vehicle = detail.vehicle;
                return vehicle ? vehicle.nomor_plat : '';
              })
            ).filter(Boolean);
            return {
                ...item,
                index: startIndex + index + 1,
                startDate: sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear(),
                finishDate: fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear(),
                // startTime,
                vehicleNames: vehicleNames,
                productName,
                customerName
            };
          });
          this.pagination.current = resp.data.current_page;
          this.pagination.total = resp.data.total;
          this.pagination.pageSize = this.params.row;
          this.totalB = resp.totalB !== null ? resp.totalB : 0;
          this.totalP = resp.totalP !== null ? resp.totalP : 0;
          this.total = resp.total !== null ? resp.total : 0;
          this.params.dateStart = this.formatDate(resp.dateStart);
          this.params.dateEnd = this.formatDate(resp.dateEnd);
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching order data:", e);
        } finally {
          loading.hide();
        }
      },
      async getProduct() {
        const loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("product/getProduct", this.params);
          this.dataProduct = resp.data.data.map((item, index) => {
              let rentalTypeName;
              if (item.rental_type == 1) {
                  rentalTypeName = "Sewa Lepas Kunci";
              } else {
                  rentalTypeName = "Sewa Dengan Driver";
              }
              return {
                  ...item,
                  rentalTypeName,
              };
          });
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching product data:", e);
        } finally {
          loading.hide();
        }
      },
      async getVehicle() {
        const loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("vehicle/getVehicleAll");
          this.dataVehicle = resp.data.map((item,index) => {
            const user = JSON.parse(localStorage.getItem('user'))
            let vendorName = '---'
            if (user && user.company) {
              vendorName = user.company.name
            }
            if (item.vendor_id) {
              const data = this.dataVendor.find(vendor => vendor.id === item.vendor_id);
              vendorName = data.full_name;
            }
            const vehicleName = item.name + ' - ' + item.nomor_plat + ' - ' + vendorName;
            return {
              ...item,
              vehicleName
            };
          });
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching vehicle data:", e);
        } finally {
          loading.hide();
        }
      },
      async getCustomer() {
        const loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("customer/getCustomerAll");
          this.dataCustomer = resp.data;
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching customer data:", e);
        } finally {
          loading.hide();
        }
      },
      async getDriver() {
        const loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("user/getUser", this.paramDriver);
          this.dataDriver = resp.data.data;
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching driver data:", e);
        } finally {
          loading.hide();
        }
      },
      async getStaff() {
        const loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("user/getUser", this.paramStaff);
          this.dataStaff = resp.data.data;
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching staff data:", e);
        } finally {
          loading.hide();
        }
      },
      async getVendor() {
        const loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("vendor/getVendorAll");
          this.dataVendor = resp.data;
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching vendor data:", e);
        } finally {
          loading.hide();
        }
      },
      async getBrand() {
        const loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("brand/getBrandAll");
          this.dataBrand = resp.data;
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching brand data:", e);
        } finally {
          loading.hide();
        }
      },
      async getLoginCompany() {
        const loading = this.$loading.show();
        try {
          const userData = localStorage.getItem('user');
          const user = JSON.parse(userData);
          this.userLogin = user;
          if (!user.company_id || !user.company) {
            return
          }
          const resp = await this.$store.dispatch("company/showCompany", {id : user.company_id});
          this.dataUserCompany = resp.data.data;
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching login company data:", e);
        } finally {
          loading.hide();
        }
      },
      formatDate(item) {
          // Assuming dateString is in a different format, you need to format it to YYYY-MM-DD
          const date = new Date(item);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
      },
      sendWhatsAppMessage(order) {
        const companyName = this.dataUserCompany.name;
        const { no_order, dp, total_price, customerName, jml_org, pick_up_point, interpoint, remark, payment_method } = order;
        const customerSelected = this.dataCustomer.find(customer => customer.id === order.customer_id);
        const phone_number = customerSelected ? customerSelected.phone_number : null;
        // const email = customerSelected ? customerSelected.email : null;
        const productSelected = this.dataProduct.find(product => product.id === order.product_id);
        const productName = productSelected ?  productSelected.rentalTypeName + ' - ' + productSelected.name : '';
        const vehicleSelected = this.dataVehicle.find(vehicle => vehicle.id === order.vehicle_id);
        const vehicleName = vehicleSelected ? vehicleSelected.name : '';
        const sDate = new Date(order.starting_date);
        const startDate = sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear();
        const fDate = new Date(order.finish_date);
        const finishDate = fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear();
        const countryCode = '62'; // Example country code for Indonesia
        const formattedPhoneNumber = `+${countryCode}${phone_number.slice(1)}`; // Format phone number
        const message = `${companyName}\nNo Order ${no_order}\nNama Pemesan : ${customerName}\nNomor telpon : ${formattedPhoneNumber}\nJumlah Pax : ${jml_org}\nPilihan Produk Sewa Mobil : ${productName}\nJenis Kendaraan di pesan : ${vehicleName}\nTanggal dan Waktu Penjemputan : ${startDate}\nTanggal akhir Penghantaran : ${finishDate}\nAlamat Penjemputan/Pengambilan : ${pick_up_point}\nRute Tujuan Awal dan Akhir Pemakaian Kendaraan : ${interpoint}\nHARGA DEAL : ${total_price}\nDEPOSITE PAYMENT : ${dp}\n\nMetode Pembayaran : ${payment_method}\nRequest / Catatan : ${remark}`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
      },
  },
  async  mounted() {
    try {
      await this.fetchAllData();
    } catch (error) {
      console.error("Error during data fetching:", error);
    }
  }
};
</script>
  <style>
  .ant-select-selection-item {
    height: 30px;
    width: 90px;
    margin-top: -6px;
    font-size: 15px;
  }
  </style>

<style scoped>
@media (max-width: 768px) {
  .text-md-end {
    text-align: center !important;
  }
  .input-group {
    flex-direction: column;
  }
  .input-group .form-control,
  .input-group .input-group-text,
  .input-group .btn {
    width: 100%;
    margin-bottom: 10px;
  }
  .input-group-text {
    justify-content: center;
  }
}
</style>
