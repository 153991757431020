<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- Login Activity -->
          <div class="page-header">
            <div class="row align-items-center">
              <div class="col-md-4">
                <div class="provider-subtitle">
                  <h6>Device Management</h6>
                </div>
              </div>
              <div
                class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap"
              >
                <div class="review-sort d-flex align-items-center me-2">
                  <p>Sort</p>
                  <vue-select :options="abcd" placeholder="A -> Z" />
                </div>
                <div class="grid-listview">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="@/assets/img/icons/filter-icon.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="provide-table manage-table">
            <div class="table-responsive">
              <table class="table custom-table datatable mb-0" id="data-table">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Status'">
                      <a href="javascript:;"
                        ><span :class="record.Class">{{ record.Status }}</span></a
                      >
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div id="tablelength"></div>
            </div>
            <div class="col-md-7">
              <div class="table-ingopage">
                <div id="tableinfo"></div>
                <div id="tablepagination"></div>
              </div>
            </div>
          </div>
          <!-- /Login Activity -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "Device",
    dataIndex: "Device",
    sorter: {
      compare: (a, b) => {
        a = a.Device.toLowerCase();
        b = b.Device.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "IP Address",
    dataIndex: "IP_Address",
    sorter: {
      compare: (a, b) => {
        a = a.IP_Address.toLowerCase();
        b = b.IP_Address.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Location",
    dataIndex: "Location",
    sorter: {
      compare: (a, b) => {
        a = a.Location.toLowerCase();
        b = b.Location.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    Device: "Chrome - Windows",
    Date: "22 Sep 2023, 11:40:30",
    IP_Address: "Chennai / India",
    Location: "232.222.12.72",
    Class: "badge-danger",
    Status: "Delete",
  },
  {
    Device: "Safari Macos",
    Date: "21 Sep 2023, 13:10:50",
    IP_Address: "Newyork / USA",
    Location: "224.111.12.75",
    Class: "badge-danger",
    Status: "Delete",
  },
  {
    Device: "Firefox Windows",
    Date: "20 Sep 2023, 14:16:10",
    IP_Address: "Newyork / USA",
    Location: "232.222.12.72",
    Class: "badge-danger",
    Status: "Delete",
  },
  {
    Device: "Safari Macos",
    Date: "19 Sep 2023, 10:20:20",
    IP_Address: "Newyork / USA",
    Location: "333.555.10.54",
    Class: "badge-danger",
    Status: "Delete",
  },
];
export default {
  data() {
    return {
      abcd: ["A -> Z", "Most helful"],
      columns,
      data,
    };
  },
};
</script>
