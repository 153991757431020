<template>
  <!-- Service Section -->
  <section class="car-blogs-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading car-wash-heading aos" data-aos="fade-up">
            <div class="car-wash-img-five">
              <img
                src="@/assets/img/icons/car-wash-header-1.svg"
                alt=""
                class="car-wash-header-one"
              />
              <h2>Our Blogs</h2>
              <img
                src="@/assets/img/icons/car-wash-header-2.svg"
                alt=""
                class="car-wash-header-two"
              />
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel car-blog-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexfiveservice" :key="item.id">
                <div class="service-widget aos" data-aos="fade-up">
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="fav-item">
                      <router-link to="/categories"
                        ><span class="item-cat item-car">{{
                          item.date
                        }}</span></router-link
                      >
                    </div>
                  </div>
                  <div class="service-content">
                    <h3 class="title">
                      <router-link to="/service-details">{{ item.Title }}</router-link>
                    </h3>
                    <p>
                      Donec vel pellentesque nisl, molestie tempus ligula. Cras massa
                      turpis, pretium nec placerat ornare, sodales ac urna. Sed commodo
                      semper fermentum.
                    </p>
                    <div class="serv-info">
                      <router-link to="/service-details" class="btn btn-book"
                        >Read More</router-link
                      >
                      <img
                        :src="require(`@/assets/img/profiles/${item.avatar}`)"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Service Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfiveservice from "@/assets/json/indexfiveservice.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexfiveservice: indexfiveservice,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
