<template>
  <!-- Add Holiday -->
  <div class="modal fade custom-modal" id="add-holiday">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Add Holiday</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form action="javascript:;">
            <div class="wallet-add">
              <div class="form-group">
                <label class="col-form-label">Holiday Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Holiday Name"
                />
              </div>
              <div class="form-group">
                <label class="col-form-label">From</label>
                <div class="form-icon">
                  <datepicker
                    v-model="startdate"
                    :input-format="dateFormat"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="Select Date"
                  />
                  <span class="cus-icon">
                    <i class="feather-calendar"></i>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">To</label>
                <div class="form-icon">
                  <datepicker
                    v-model="startdate1"
                    :input-format="dateFormat"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="Select Date"
                  />
                  <span class="cus-icon">
                    <i class="feather-calendar"></i>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Description</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Enter Description"
                ></textarea>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <b-button type="submit" variant="primary">Submit</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Holiday -->

  <!-- Add Leave -->
  <div class="modal fade custom-modal" id="add-leave">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Add Leave</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form action="javascript:;">
            <div class="wallet-add">
              <div class="form-group">
                <label class="col-form-label">From</label>
                <div class="form-icon">
                  <datepicker
                    v-model="startdate"
                    :input-format="dateFormat"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="Select Date"
                  />
                  <span class="cus-icon">
                    <i class="feather-calendar"></i>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">To</label>
                <div class="form-icon">
                  <datepicker
                    v-model="startdate1"
                    :input-format="dateFormat"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="Select Date"
                  />
                  <span class="cus-icon">
                    <i class="feather-calendar"></i>
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Reason</label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Enter Reason"
                ></textarea>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Leave -->
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDate1 = ref(new Date());
export default {
  data() {
    return {
      startdate: currentDate,
      startdate1: currentDate1,
      dateFormat: "dd-MM-yyyy",
    };
  },
};
</script>
