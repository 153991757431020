<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content w-100 ps-0 pt-0">
        <sidelinkheading />

        <!-- SEO Settings -->
        <div class="row">
          <div class="content-table">
            <div class="content-page-header">
              <h5>SEO Settings</h5>
            </div>
            <ul class="nav seo-list">
              <li>
                <a
                  href="javascript:;"
                  class="active"
                  data-bs-toggle="tab"
                  data-bs-target="#site"
                  >Site Meta</a
                >
              </li>
              <li>
                <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#ogmeta"
                  >Og Meta</a
                >
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="site">
                <b-form @submit.prevent="submitForm">
                  <div class="form-group">
                    <label>Meta Title</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter Meta Title"
                    />
                  </div>
                  <div class="form-group">
                    <label>Site Description</label>
                    <textarea
                      class="form-control"
                      placeholder="Enter Site Description"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label>Keywords</label>
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="specialist"
                      id="specialist"
                      :tags="tags"
                    />
                  </div>
                  <div class="col-lg-12">
                    <div class="btn-path">
                      <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                      <b-button type="submit" variant="primary">Save Changes</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
              <div class="tab-pane fade" id="ogmeta">
                <b-form @submit.prevent="submitForm">
                  <div class="form-group">
                    <label>Meta Titles</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter Meta Title"
                    />
                  </div>
                  <div class="form-group">
                    <label>Site Description</label>
                    <textarea
                      class="form-control"
                      placeholder="Enter Site Description"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label>Keywords</label>
                    <vue3-tags-input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="specialist"
                      id="specialist"
                      :tags="tags"
                    />
                  </div>
                  <div class="col-lg-12">
                    <div class="btn-path">
                      <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                      <b-button type="submit" variant="primary">Save Changes</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
        <!-- /SEO Settings -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue3TagsInput from "vue3-tags-input";

export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      tags: ["Test"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/seo-settings");
    },
  },
};
</script>
