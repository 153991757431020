<template>
    
    <div class="main-wrapper">

        <div class="provider-body">

            <dashboardnavbar />

            <div class="page-wrapper">
                <sidebar />

                <addsubcontent />
            </div>
        </div>
        
        <cursorpointer />
    </div>
</template>