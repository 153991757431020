<template>
    <div class="main-wrapper">
        <indexnineheadertop />
        <indexnineheader />

        <div class="page-wrapper">

            <indexninebanner />

            <indexnineservice />

            <indexninemechanics />

            <indexninecustomer />

            <indexnineadditional />

            <indexnineblog />

            <indexninepartner />

            <indexninefooter />

            <scroll />
        </div>
    </div>
</template>