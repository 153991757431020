<template>
	<div class="main-wrapper">
		<mari-layout></mari-layout>
		<div class="page-wrapper">
			<div class="content">		
				<div class="container">			
					<div class="section-heading">
						<div class="row">
							<div class="col-md-6 aos" data-aos="fade-up">
								<h4>Bantuan</h4>
                <p>Dapatkan Bantuan disini!</p>
							</div>
						</div>
					</div>
					<div class="row">
            <div class="contact-details">
              <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4 d-flex">
                  <div class="contact-info flex-fill">
                    <span><i class="feather-phone"></i></span>
                    <div class="contact-data">
                      <h4>Phone Number</h4>
                      <p>(888) 888-8888</p>
                      <p>(123) 456-7890</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                  <div class="contact-info flex-fill">
                    <span><i class="feather-mail"></i></span>
                    <div class="contact-data">
                      <h4>Email Address</h4>
                      <p><a href="mailto:truelysell@example.com">admin@marirental.com</a></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                  <div class="contact-info flex-fill">
                    <span><i class="feather-map-pin"></i></span>
                    <div class="contact-data">
                      <h4>Address</h4>
                      <p>Cilengkrang,
                    Kota Bandung, Indonesia</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Contact Details -->

            <!-- Get In Touch -->
            <div class="row">
              <div class="col-md-6">
                <div class="contact-img">
                  <img src="@/assets/img/mari/call.svg" class="img-fluid" alt="img" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="contact-queries">
                  <h2>Get In Touch</h2>
                  <form @submit.prevent="submitForm">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label">Name</label>
                          <input class="form-control" type="text" placeholder="Enter Name*" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label">Email</label>
                          <input
                            class="form-control"
                            type="email"
                            placeholder="Enter Email Address*"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="col-form-label">Phone Number</label>
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter Phone Number"
                          />
                        </div>
                        <div class="form-group">
                          <label class="col-form-label">Message</label>
                          <textarea
                            class="form-control"
                            rows="4"
                            placeholder="Type Message"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <b-button variant="primary" type="submit"
                          >Send Message<i class="feather-arrow-right-circle ms-2"></i
                        ></b-button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
  
  <script>
  export default {
    data() {
      return {
        
      };
    },
    name: "subscribesetting",
    computed: {

    },
    methods: {

    },
    mounted() {

    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>