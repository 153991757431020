import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getReportDriver(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/report-driver', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addReportDriver(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/report-driver/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateReportDriver(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('/report-driver/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        showReportDriver(_, id) {
            return new Promise((resolve, reject) => {
              axios.get(`/report-driver/show/${id}`).then(resp => {
                resolve(resp.data);
              }).catch(e => {
                reject(e);
              });
            });
        },

    },
    getters: {

    }

}