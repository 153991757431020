<template>
  <!-- /Around the world  Services -->
  <section class="review-client-section">
    <div class="container">
      <div class="section-heading section-heading-four">
        <div class="row align-items-center">
          <div class="col-md-6 aos" data-aos="fade-up">
            <h2>Reviews from our Clients</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
          <div class="col-md-6 text-md-end aos" data-aos="fade-up">
            <a href="javascript:void(0)" class="btn btn-primary btn-view"
              >VIEW ALL REVIEWS<i class="feather-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel client-four-slider common-four-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexfourservices" :key="item.id">
                <div class="client-review-main">
                  <div class="client-review-top">
                    <div class="client-review-name">
                      <h6>{{ item.name }}</h6>
                      <div class="rating">
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua reader
                      will be distracted Loremut labore et dolore.
                    </p>
                  </div>
                  <div class="client-review-img">
                    <img :src="require(`@/assets/img/profiles/${item.avatar}`)" />
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>

      <indexfourbranch />
    </div>
  </section>
  <!-- /Reviews from our Clients -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfourservices from "@/assets/json/indexfourservices.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexfourservices: indexfourservices,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 2.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style>
.client-review-main {
  margin-right: 24px;
}
</style>
