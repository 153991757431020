import { createApp } from 'vue'
import { router } from '@/router';
import App from '@/App.vue'
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueSelect from 'vue3-select2-component'
import VCalendar from 'v-calendar';
import SummernoteEditor from 'vue3-summernote-editor';
import DatePicker from 'vue3-datepicker'
import VueTelInput from 'vue3-tel-input'
import VueTimepicker from 'vue3-timepicker'
import VueApexCharts from "vue3-apexcharts";
import VueEasyLightbox from "vue-easy-lightbox";
import Vue3Autocounter from 'vue3-autocounter';
import VueSweetalert2 from 'vue-sweetalert2' 
import 'sweetalert2/dist/sweetalert2.min.css';
import AOS from 'aos'
import axios from 'axios';
import store from './store'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import ganttastic from '@infectoone/vue-ganttastic'


// Components
import header from '@/views/frontend/layouts/header.vue'
import HeaderMenu from '@/components/headerMenu.vue'
import CustomerHeadMenu from '@/components/customerheadermenu.vue'
import Nav from '@/components/navbar.vue'
import CustomNav from '@/components/customernav.vue'
import CustomBookCalendarNav from '@/components/customerbookcalendernav.vue'
import OtpHeader from '@/components/otpheader.vue'
import SuccessHeader from '@/components/successnav.vue'
import DashNav from '@/components/dashboardnav.vue'
import SideBar from '@/views/frontend/layouts/sidebar.vue'
import Cursor from '@/views/frontend/layouts/cursor.vue'
import HeaderTop from '@/views/frontend/layouts/headertop.vue'
import IndexTwoHeader from '@/components/indextwoheader.vue'
import IndexThreeHeader from '@/components/indexthreeheader.vue'
import IndexFourHeader from '@/components/indexfourheader.vue'
import IndexFiveHeader from '@/components/indexfiveheader.vue'
import IndexSixHeader from '@/components/indexsixheader.vue'
import IndexSevenHeader from '@/components/indexsevenheader.vue'
import IndexEightHeader from '@/components/indexeightheader.vue'
import IndexNineHeaderTop from '@/components/indexnineheadertop.vue'
import IndexNineHeader from '@/components/indexnineheader.vue'
import Customer_Sidebar from '@/views/frontend/layouts/customer-sidebar.vue'

/************Page Components********************/
import About from '@/views/frontend/pages/Pages/AboutUs/about.vue'
import AboutProvider from '@/views/frontend/pages/Pages/AboutUs/aboutProviders.vue'
import CouponAdd from '@/views/frontend/pages/Providers/Coupons/Addcoupon/couponadd.vue'
import AddSubContent from '@/views/frontend/pages/Providers/Subscription/Addsubscription/subcontent.vue'
import BlogContent from '@/views/frontend/pages/Blog/blogdetails/blogcontent.vue'
import BlogSidebar from '@/views/frontend/pages/Blog/blogdetails/blogsidebar.vue'
import BlogGridContent from '@/views/frontend/pages/Blog/bloggrid/bloggridcontent.vue'
import BlogListContent from '@/views/frontend/pages/Blog/bloglist/bloglistcontent.vue'
import BookingDoneContent from '@/views/frontend/pages/Pages/Booking/Booking-done/bookingdonecontent'
import BookPayContent from '@/views/frontend/pages/Pages/Booking/Booking-payment/bookingpaymentcontent.vue'
import BookingContent from '@/views/frontend/pages/Pages/Booking/booking/bookingcontent.vue'
import CategoriesContent from '@/views/frontend/pages/Pages/Categories/categoriescontent.vue'
import ChangePassWordContent from '@/views/frontend/pages/Pages/ChangePassword/changepasswordcontent.vue'
import ChooseSignUpContent from '@/views/frontend/pages/Pages/Authentication/ChooseSignup/choosesignupcontent.vue'
import ComingSoonContent from '@/views/frontend/pages/Pages/Comingsoon/comingsooncontent.vue'
import ConnectedAppsContent from '@/views/frontend/pages/Customers/Settings/Connectedapps/connectappscontent.vue'
import ContactContent from '@/views/frontend/pages/Pages/ContactUs/contactcontent.vue'
import ContactMap from '@/views/frontend/pages/Pages/ContactUs/contactmap.vue'
import CustomerCalendarContent from '@/views/frontend/pages/Customers/Booking/CustomerBookingCalender/Customercalendarcontent.vue'
import CustomerBookDetails from '@/views/frontend/pages/Customers/Booking/CustomerBookingCalender/customerbookingdetails.vue'
import CustomerReschedule from '@/views/frontend/pages/Customers/Booking/CustomerBookingCalender/reschedule.vue'
import CancelAppointment from '@/views/frontend/pages/Customers/Booking/CustomerBookingCalender/cancelappointment.vue'
import CustomerBookingContent from '@/views/frontend/pages/Customers/Booking/Customerbooking/customerbookingcontent.vue'
import CustomerAddReview from '@/views/frontend/pages/Customers/Booking/Customerbooking/addreview.vue'
import CustomerChatContent from '@/views/frontend/pages/Customers/Chat/customerchatcontent.vue'
import CustomerBoardContent from '@/views/frontend/pages/Customers/Dashboard/customerdashboardcontent.vue'
import CustomerFavContent from '@/views/frontend/pages/Customers/Favourites/customerfavouritecontent.vue'
import CustomerNotifyContent from '@/views/frontend/pages/Customers/Settings/Customernotification/customernotificationcontent.vue'
import CustomerNotifyDeleteAcc from '@/views/frontend/pages/Customers/Settings/Customernotification/deleteaccount.vue'
import CustomerProfileContent from '@/views/frontend/pages/Customers/Settings/Customerprofile/customerprofilecontent.vue'
import CustomProfileDelete from '@/views/frontend/pages/Customers/Settings/Customerprofile/deleteaccount.vue'
import CustomerReviewContent from '@/views/frontend/pages/Customers/Reviews/customerreviewscontent.vue'
import CustomerWalletContent from '@/views/frontend/pages/Customers/Wallet/customerwalletcontent.vue'
import AddWallet from '@/views/frontend/pages/Customers/Wallet/addwallet.vue'
import DevicemanageContent from '@/views/frontend/pages/Devicemanagement/devicemanagementcontent.vue'
import EmailOtpContent from '@/views/frontend/pages/Pages/Authentication/Emailotp/emailotpcontent.vue'
import Error404 from '@/views/frontend/pages/Pages/Errorpages/Error404/error404content.vue'
import Error500 from '@/views/frontend/pages/Pages/Errorpages/Error500/error500content.vue'
import FaqContent from '@/views/frontend/pages/Pages/Faq/faqcontent.vue'
import FooterDown from '@/views/frontend/layouts/footer.vue'
import FreeTrialContent from '@/views/frontend/pages/Pages/Authentication/Freetrial/freetrialcontent.vue'
import HowItsWorkContent from '@/views/frontend/pages/Pages/Howitswork/howitsworkcontent.vue'
import InvoiceContent from '@/views/frontend/pages/Providers/Invoice/invoicecontent.vue'
import PasswordRecoveryContent from '@/views/frontend/pages/Pages/Authentication/Passwordupdate/passwordrecoverycontent.vue'
import PaymentSettingContent from '@/views/frontend/pages/Providers/settings/Paymentsettings/paymentsettingcontent.vue'
import PhoneOtpContent from '@/views/frontend/pages/Pages/Authentication/Phoneotp/phoneotpcontent.vue';
import PricingContent from '@/views/frontend/pages/Pages/Pricingplan/pricingcontent.vue'
import PrivacyPolicyContent from '@/views/frontend/pages/Pages/Privacypolicy/privacypolicycontent.vue'
import ProviderAddonsContent from '@/views/frontend/pages/Providers/Subscription/Provideraddons/provideraddonscontent.vue'
import ProviderDeleteAccount from '@/views/frontend/pages/Providers/Subscription/Provideraddons/deleteaccount.vue'
import Dashboardbooking from '@/views/frontend/pages/Providers/Dashboard/dashboardbooking.vue'
import Myserviceactive from '@/views/frontend/pages/Providers/Myservices/myserviceactive.vue'
import Myserviceinactive from '@/views/frontend/pages/Providers/Myservices/myserviceinactive.vue'
import Proivderbookinglist from '@/views/frontend/pages/Providers/Booking/Booking/proivderbookinglist.vue'
import Pagination from '@/views/frontend/pages/Customers/Booking/Customerbooking/pagination.vue'
import Dashboardwidget from '@/views/frontend/pages/Customers/Dashboard/dashboardwidget.vue'
import Recentbooking from '@/views/frontend/pages/Customers/Dashboard/recentbooking.vue'
import Recenttransaction from '@/views/frontend/pages/Customers/Dashboard/recenttransaction.vue'
import Favouritesort from '@/views/frontend/pages/Customers/Favourites/favouritesort.vue'
import Favouritelist from '@/views/frontend/pages/Customers/Favourites/favouritelist.vue'
import BookingService from '@/views/frontend/pages/Services/servicedetails2/booking-service/booking-service.vue'

import AvailabilityContent from '@/views/frontend/pages/Providers/Availability/availabilitycontent.vue'
import AvailabilityDeleteAcc from '@/views/frontend/pages/Providers/Availability/deleteaccount.vue'
import BookingDetailsContent from '@/views/frontend/pages/Providers/Booking/Bookingdetails/bookingdetailscontent.vue'
import BookingDetailsAddLeave from '@/views/frontend/pages/Providers/Booking/Bookingdetails/bookingdetailsaddleave.vue'
import BookingDetailsDeleteAcc from '@/views/frontend/pages/Providers/Booking/Bookingdetails/deleteaccount.vue'
import ProviderBookingcontent from '@/views/frontend/pages/Providers/Booking/Booking/bookingcontent.vue'
import ProviderBookDelateAcc from '@/views/frontend/pages/Providers/Booking/Booking/deleteaccount.vue'
import ProviderChatContent from '@/views/frontend/pages/Providers/Chat/chatcontent.vue'
import ProviderChatDeleteAcc from '@/views/frontend/pages/Providers/Chat/deleteaccount.vue'
import ProviderConnectAppContent from '@/views/frontend/pages/Providers/settings/Connectedapps/connectedappcontent.vue'
import ProviderCouponContent from '@/views/frontend/pages/Providers/Coupons/Coupons/couponscontent.vue'
import ProviderDashboardContent from '@/views/frontend/pages/Providers/Dashboard/dashboardcontent.vue'
import ProviderDashboardDeleteAcc from '@/views/frontend/pages/Providers/Dashboard/deleteaccount.vue'
import ProviderDetails from '@/views/frontend/pages/Services/Provider/ProviderDetails/detailscontent.vue'
import ProviderDeviceManagementContent from '@/views/frontend/pages/Providers/Devicemanagement/devicemanagementcontent.vue'
import ProviderEarningContent from '@/views/frontend/pages/Providers/Earnings/earningscontent.vue'
import ProviderEarningDeleteAcc from '@/views/frontend/pages/Providers/Earnings/deleteaccount.vue'
import ProviderEditServiceContent from '@/views/frontend/pages/Providers/Myservices/Editservices/editservicecontent.vue'
import ProviderHolidayContent from '@/views/frontend/pages/Providers/Holiday&Leaves/holidaycontent.vue'
import ProviderAddHoliday from '@/views/frontend/pages/Providers/Holiday&Leaves/addholiday.vue'
import ProviderAlertMessage from '@/views/frontend/pages/Providers/Holiday&Leaves/alertmessage.vue'
import ProviderNotificationContent from '@/views/frontend/pages/Providers/settings/Notification/notificationcontent.vue'
import ProviderOfferContent from '@/views/frontend/pages/Providers/Offers/offerscontent.vue'
import ProviderAddCoupon from '@/views/frontend/pages/Providers/Offers/addcoupon.vue'
import ProviderPayoutContent from '@/views/frontend/pages/Providers/Payout/payoutcontent.vue'
import ProviderAddWallet from '@/views/frontend/pages/Providers/Payout/addwallet.vue'
import ProviderPlanContent from '@/views/frontend/pages/Providers/settings/Plan&billings/plancontent.vue'
import ProviderProfileSettingsContent from '@/views/frontend/pages/Providers/settings/Accountsettings/profilesettingscontent.vue'
import ProviderReviewContent from '@/views/frontend/pages/Providers/Reviews/reviewcontent.vue'
import ProviderReviewDeleteAcc from '@/views/frontend/pages/Providers/Reviews/deleteaccount.vue'
import ProviderSecurityContent from '@/views/frontend/pages/Providers/settings/Security/securitycontent.vue'
import ProviderSecurityChangeEmail from '@/views/frontend/pages/Providers/settings/Security/changeemail.vue'
import ProviderServiceContent from '@/views/frontend/pages/Providers/Myservices/Serviceslist/serviceslistcontent.vue'
import ProviderServiceActive from '@/views/frontend/pages/Providers/Myservices/Serviceslist/activeservice.vue'
import ProviderServiceInactive from '@/views/frontend/pages/Providers/Myservices/Serviceslist/inactiveservice.vue'
import ProviderServiceDeleteService from '@/views/frontend/pages/Providers/Myservices/Serviceslist/deleteservice.vue'
import ProviderServicesContent from '@/views/frontend/pages/Providers/Myservices/servicecontent.vue'
import ProviderActiveService from '@/views/frontend/pages/Providers/Myservices/activeservice.vue'
import ProviderDeleteService from '@/views/frontend/pages/Providers/Myservices/deleteservice.vue'
import ProviderSocialProfileContent from '@/views/frontend/pages/Providers/settings/Socialprofiles/socialprofilecontent.vue'
import ProviderSubscriptionContent from '@/views/frontend/pages/Providers/Subscription/subscriptioncontent.vue'
import ProviderSubscriptionDeleteAcc from '@/views/frontend/pages/Providers/Subscription/deleteaccount.vue'
import ProviderListContent from '@/views/frontend/pages/Services/Provider/Providerlist/providerlistcontent.vue'
import ResetpasswordContent from '@/views/frontend/pages/Pages/Authentication/Resetpassword/resetpasswordcontent.vue'
import SearchListContent from '@/views/frontend/pages/Services/Searchlist/searchlistcontent.vue'
import SearchContent from '@/views/frontend/pages/Services/Search/searchcontent.vue'
import SecuritySettingsContent from '@/views/frontend/pages/Customers/Settings/Securitysettings/securitysettingscontent.vue'
import SecuritySettingsChangeMail from '@/views/frontend/pages/Customers/Settings/Securitysettings/changeemail.vue'
import ServiceDetailsContent from '@/views/frontend/pages/Services/Servicedetails/servicedetailscontent.vue'
import ServiceGridContent from '@/views/frontend/pages/Services/Servicegrid/servicegridcontent.vue'
import ServiceListContent from '@/views/frontend/pages/Services/Servicelist/servicelistcontent.vue'
import UserSignupContent from '@/views/frontend/pages/Pages/Authentication/ChooseSignup/Usersignup/usersignupcontent.vue'
import IndexProfessional from '@/views/frontend/pages/Index/indexprofessional.vue'
import IndexFeatureService from '@/views/frontend/pages/Index/indexfeatureservice.vue'
import IndexOffering from '@/views/frontend/pages/Index/indexoffering.vue'
import IndexPlaning from '@/views/frontend/pages/Index/indexplaning.vue'
import IndexBlog from '@/views/frontend/pages/Index/indexblog.vue'
import IndexTwoHero from '@/views/frontend/pages/Indextwo/indextwohero.vue'
import IndexTwoFeaturedService from '@/views/frontend/pages/Indextwo/indextwofeaturedservice.vue'
import IndexTwoPopular from '@/views/frontend/pages/Indextwo/indextwopopular.vue'
import indexpricing from '@/views/frontend/pages/Indextwo/indextwopricing.vue'
import IndexTwoClient from '@/views/frontend/pages/Indextwo/indextwoclient.vue'
import IndexTwoPartners from '@/views/frontend/pages/Indextwo/indextwopartners.vue'
import IndexTwoFooter from '@/views/frontend/pages/Indextwo/footer'
import IndexThreeBanner from '@/views/frontend/pages/Indexthree/indexthreebanner.vue'
import IndexThreeAppointment from '@/views/frontend/pages/Indexthree/indexthreeappointment.vue'
import IndexThreeService from '@/views/frontend/pages/Indexthree/indexthreeservice.vue'
import IndexThreeWork from '@/views/frontend/pages/Indexthree/indexthreework.vue'
import IndexThreeLatest from '@/views/frontend/pages/Indexthree/indexthreelatest.vue'
import IndexThreeFooter from '@/views/frontend/pages/Indexthree/footer.vue'
import RelatedService from '@/views/frontend/pages/Services/Servicedetails/relatedservice.vue'
import Scroll from '@/views/frontend/pages/scroll.vue'
import IndexFourBanner from '@/views/frontend/pages/indexfour/indexfourbanner.vue'
import IndexFourCatering from '@/views/frontend/pages/indexfour/indexfourcatering.vue'
import IndexFourCategories from '@/views/frontend/pages/indexfour/indexfourcategories.vue'
import IndexFourWork from '@/views/frontend/pages/indexfour/indexfourwork.vue'
import IndexFourServices from '@/views/frontend/pages/indexfour/indexfourservices.vue'
import IndexFourBranch from '@/views/frontend/pages/indexfour/indexfourbranch.vue'
import IndexFourBlog from '@/views/frontend/pages/indexfour/indexfourblog.vue'
import IndexFourFooter from '@/views/frontend/pages/indexfour/footer.vue'
import IndexFiveBanner from '@/views/frontend/pages/indexfive/indexfivebanner.vue'
import IndexFiveFeatured from '@/views/frontend/pages/indexfive/indexfivefeatured.vue'
import IndexFiveCategories from '@/views/frontend/pages/indexfive/indexfivecategories.vue'
import IndexFiveTestimonial from '@/views/frontend/pages/indexfive/indexfivepricing.vue'
import IndexFivePartner from '@/views/frontend/pages/indexfive/indexfivepartner.vue'
import IndexFiveService from '@/views/frontend/pages/indexfive/indexfiveservice.vue'
import IndexFiveApp from '@/views/frontend/pages/indexfive/indexfiveapp.vue'
import IndexFiveFooter from '@/views/frontend/pages/indexfive/footer.vue'
import IndexSixBanner from '@/views/frontend/pages/indexsix/indexsixbanner.vue'
import IndexSixCompany from '@/views/frontend/pages/indexsix/indexsixcompany.vue'
import IndexSixWork from '@/views/frontend/pages/indexsix/indexsixwork.vue'
import IndexSixTeam from '@/views/frontend/pages/indexsix/indexsixteam.vue'
import IndexSixPricing from '@/views/frontend/pages/indexsix/indexsixpricing.vue'
import IndexSixFooter from '@/views/frontend/pages/indexsix/footer.vue'
import IndexSevenBanner from '@/views/frontend/pages/indexseven/indexsevenbanner.vue'
import IndexSevenPopular from '@/views/frontend/pages/indexseven/indexsevenpopular.vue'
import IndexSevenService from '@/views/frontend/pages/indexseven/indexsevenservice.vue'
import IndexSevenProvider from '@/views/frontend/pages/indexseven/indexsevenprovider.vue'
import IndexSevenTestimonial from '@/views/frontend/pages/indexseven/indexseventestimonial.vue'
import IndexSevenPricing from '@/views/frontend/pages/indexseven/indexsevenpricing.vue'
import IndexSevenPartner from '@/views/frontend/pages/indexseven/indexsevenpartner.vue'
import IndexSevenFooter from '@/views/frontend/pages/indexseven/footer.vue'
import IndexEightBanner from '@/views/frontend/pages/indexeight/indexeightbanner.vue'
import IndexEightPopular from '@/views/frontend/pages/indexeight/indexeightpopular.vue'
import IndexEightMeet from '@/views/frontend/pages/indexeight/indexeightmeet.vue'
import IndexEightCustomer from '@/views/frontend/pages/indexeight/indexeightcustomer.vue'
import IndexEightBlog from '@/views/frontend/pages/indexeight/indexeightblog.vue'
import IndexEightFooter from '@/views/frontend/pages/indexeight/footer.vue'
import IndexNineBanner from '@/views/frontend/pages/indexnine/indexninebanner.vue'
import IndexNineService from '@/views/frontend/pages/indexnine/indexnineservice.vue'
import IndexNineMechanics from '@/views/frontend/pages/indexnine/indexninemechanics.vue'
import IndexNineCustomer from '@/views/frontend/pages/indexnine/indexninecustomer.vue'
import IndexNineAdditional from '@/views/frontend/pages/indexnine/indexnineadditional.vue'
import IndexNineBlog from '@/views/frontend/pages/indexnine/indexnineblog.vue'
import IndexNinePartner from '@/views/frontend/pages/indexnine/indexninepartner.vue'
import IndexNineFooter from '@/views/frontend/pages/indexnine/footer.vue'
import ServiceInformation from '@/views/frontend/pages/Services/Createservice/service-information.vue'
import ServiceAvailability from '@/views/frontend/pages/Services/Createservice/availability.vue'
import ServiceLocation from '@/views/frontend/pages/Services/Createservice/service-location.vue'
import ServiceGallery from '@/views/frontend/pages/Services/Createservice/service-gallery.vue'
import ServiceSeo from '@/views/frontend/pages/Services/Createservice/service-seo.vue'
import Bookingsort from '@/views/frontend/pages/Customers/Booking/Customerbooking/bookingsort.vue'
import Bookinglist from '@/views/frontend/pages/Customers/Booking/Customerbooking/bookinglist.vue'
import TimePicker from '@/views/frontend/pages/Services/Createservice/timepicker.vue'
import ServiceLightbox from '@/views/frontend/pages/Services/Servicedetails/service-lightbox.vue'
import Servicessticksidebar from '@/views/frontend/pages/Services/Servicedetails/servicessticksidebar.vue'
import Servicedetails from '@/views/frontend/pages/Services/Servicedetails/service.vue'
import Relatedservice2 from '@/views/frontend/pages/Services/servicedetails2/relatedservice2.vue'
import Servicedetails2stick from '@/views/frontend/pages/Services/servicedetails2/servicedetails2stick.vue'
import Serviceoverview from '@/views/frontend/pages/Services/servicedetails2/serviceoverview.vue'


import BreadCrumb from '@/components/Breadcrumb/Component.vue'
import BlogBreadcrumb from '@/components/Breadcrumb/Blogcomponent.vue'
import ProviderHeader from '@/components/Breadcrumb/provider-header.vue'

// *************** Model Components ***************
import Model from '@/components/model/model.vue'
import C_Booking_Model from '@/components/model/c-booking-model.vue'
import Provider_Coupons_Model from '@/components/model/provider-coupons-model.vue'
import Provider_Booking_Model from '@/components/model/provider-booking-model.vue'
import Provider_settings_Model from '@/components/model/provider-settings-model.vue'



//admin component
import Adminabusereport from '@/components/admin/pageheader.vue'
import AdminServiceInformation from '@/views/admin/pages/Services/Addservice/service-information.vue'
import AdminServiceAvailability from '@/views/admin/pages/Services/Addservice/availability.vue'
import AdminServiceLocation from '@/views/admin/pages/Services/Addservice/location.vue'
import AdminServiceGallery from '@/views/admin/pages/Services/Addservice/gallery.vue'
import AdminServiceTimepicker from '@/views/admin/pages/Services/Addservice/timepicker.vue'
import AdminServiceSeo from '@/views/admin/pages/Services/Addservice/seo.vue'

//admin 
import Adminheader from '@/views/admin/layouts/header.vue'
import Adminsidebar from '@/views/admin/layouts/sidebar.vue'
import SideLinkHeading from '@/views/admin/layouts/sidelinkheading.vue'
import ServicesTab from '@/views/admin/layouts/servicestab'
import Active_Blog from '@/views/admin/pages/Content/Blog/active-blog.vue'
import Inactive_Bloglist from '@/views/admin/pages/Content/Blog/inactive-bloglist.vue'
import Pending_Bloglist from '@/views/admin/pages/Content/Blog/pending-bloglist.vue'
import Chat_Profile from '@/views/admin/pages/Others/chat-profile.vue'
import Chatcontent from '@/views/admin/pages/Others/chatcontent.vue'
import Chatuser_list from '@/views/admin/pages/Others/chatuser-list.vue'

/*********************** Admin Model  *******************/
import Announcement_Model from '@/components/model/admin-model/announcement-model'
import Abusemodel from '@/components/model/admin-model/abusemodel.vue'
import Sms_Template_Model from '@/components/model/admin-model/sms-template-model.vue'
import Email_Template_Model from '@/components/model/admin-model/email-template-model.vue'
import Email_Newsletter_Model from '@/components/model/admin-model/email-newsletter-model.vue'
import Featured_Service_Model from '@/components/model/admin-model/featured-service-model.vue'
import Service_Offers_Model from '@/components/model/admin-model/service-offers-model.vue'
import Marketing_Coupons_Model from '@/components/model/admin-model/marketing-coupons-model.vue'
import Roles_Model from '@/components/model/admin-model/roles-model.vue'
import Customers_Model from '@/components/model/admin-model/customers-model.vue'
import Provider_Model from '@/components/model/admin-model/provider-model.vue'
import Faq_Model from '@/components/model/admin-model/faq-model.vue'
import Testimonials_Model from '@/components/model/admin-model/testimonials-model.vue'
import Conutries_Model from '@/components/model/admin-model/conutries-model.vue'
import States_Model from '@/components/model/admin-model/states-model.vue'
import Cities_Model from '@/components/model/admin-model/cities-model.vue'
import Users_Model from '@/components/model/admin-model/users-model.vue'
import Pages_Model from '@/components/model/admin-model/pages-model.vue'
import Payout_Request_Model from '@/components/model/admin-model/payout-request-model.vue'
import Sub_Categories_Model from '@/components/model/admin-model/sub-categories-model.vue'
import Categories_Model from '@/components/model/admin-model/categories-model.vue'
import Review_Type_Model from '@/components/model/admin-model/review-type-model.vue'
import Report_Delete_Model from '@/components/model/admin-model/report-delete-model.vue'
import Delete_Model from '@/components/model/admin-model/delete-model.vue'
import Delete_Model_One from '@/components/model/admin-model/delete-model-one.vue'
import Taxrate_model from '@/components/model/admin-model/taxratemodel.vue'
import Security_Model from '@/components/model/admin-model/security-model.vue'
import Blog_Categories_Model from '@/components/model/admin-model/blog-categories-model.vue'
import Database_Backup_Model from '@/components/model/admin-model/database-backup-model.vue'
import System_Model from '@/components/model/admin-model/system-model.vue'
import Banip_Model from '@/components/model/admin-model/banip-model.vue'
import Storage_Model from '@/components/model/admin-model/storage-model.vue'
import Currencies_Model from '@/components/model/admin-model/currencies-model.vue'
import Payment_Model from '@/components/model/admin-model/payment-model.vue'
import Gateways_Model from '@/components/model/admin-model/gateways-model.vue'
import Sms_Model from '@/components/model/admin-model/sms-model.vue'
import Email_Model from '@/components/model/admin-model/email-model.vue'
import Social_Model from '@/components/model/admin-model/social-model.vue'
import Contact_Message_Model from '@/components/model/admin-model/contact-message-model.vue'
import Offers_Model from '@/components/model/admin-model/offers-model.vue'

// Start Rental
// view page
// import RoleSetting from '@/views/admin/pages/AdminSettings/role-setting.vue'
// import MenuSetting from '@/views/admin/pages/AdminSettings/menu-setting.vue'
// import AccesSetting from '@/views/admin/pages/AdminSettings/acces-setting.vue'
// import UserSetting from '@/views/admin/pages/AdminSettings/user-setting.vue'
// import CompanySetting from '@/views/admin/pages/AdminSettings/company-setting.vue'
// import BranchSetting from '@/views/admin/pages/AdminSettings/branch-setting.vue'
// import PapperSetting from '@/views/admin/pages/AdminSettings/papper-setting.vue'
// import FinesSetting from '@/views/admin/pages/AdminSettings/fines-setting.vue'
// import ExpenditureSetting from '@/views/admin/pages/AdminSettings/expenditure-setting.vue'
// import MaintenanceSetting from '@/views/admin/pages/AdminSettings/maintenance-setting.vue'
// import Customer from '@/views/admin/pages/AdminSettings/customer.vue'
// import Product from '@/views/admin/pages/AdminSettings/product.vue'
// import Vehicle from '@/views/admin/pages/AdminSettings/vehicle.vue'
// import VehicleDetail from '@/views/admin/pages/AdminSettings/vehicle-detail.vue'
// import Order from '@/views/admin/pages/AdminSettings/order.vue'
// import CheckVehicle from '@/views/admin/pages/AdminSettings/check-vehicle.vue'
// import ReturnList from '@/views/admin/pages/AdminSettings/return-list.vue'
// import JadwalOrder from '@/views/admin/pages/AdminSettings/jadwal-order.vue'
// import JadwalMaintenance from '@/views/admin/pages/AdminSettings/jadwal-maintenance.vue'
// import Finance from '@/views/admin/pages/AdminSettings/finance.vue'
// import Subscribe from '@/views/admin/pages/AdminSettings/subscribe.vue'
// import Help from '@/views/admin/pages/AdminSettings/help.vue'
// import Monitoring from '@/views/admin/pages/AdminSettings/monitoring.vue'
// import Monitoring2 from '@/views/admin/pages/AdminSettings/monitoring2.vue'
// import Pengaturan from '@/views/admin/pages/AdminSettings/pengaturan.vue'
// import FinanceMenu from '@/views/admin/pages/AdminSettings/finance-menu.vue'
// import Brand from '@/views/admin/pages/AdminSettings/brand.vue'
// import Vendor from '@/views/admin/pages/AdminSettings/vendor.vue'
// import CreateVehicle from '@/views/admin/pages/AdminSettings/create-vehicle.vue'

// model
// import Role_Model from '@/components/model/admin-model/role-model.vue'
// import Menu_Model from '@/components/model/admin-model/menu-model.vue'
// import Acces_Model from '@/components/model/admin-model/acces-model.vue'
// import User_Model from '@/components/model/admin-model/user-model.vue'
// import Company_Model from '@/components/model/admin-model/company-model.vue'
// import Branch_Model from '@/components/model/admin-model/branch-model.vue'
// import Papper_Model from '@/components/model/admin-model/papper-model.vue'
// import Fines_Model from '@/components/model/admin-model/fines-model.vue'
// import Expenditure_Model from '@/components/model/admin-model/expenditure-model.vue'
// import Maintenance_Model from '@/components/model/admin-model/maintenance-model.vue'
// import Customer_Model from '@/components/model/admin-model/customer-model.vue'
// import Product_Model from '@/components/model/admin-model/product-model.vue'
// import Vehicle_Model from '@/components/model/admin-model/vehicle-model.vue'
// import Order_Model from '@/components/model/admin-model/order-model.vue'
// import Return_Model from '@/components/model/admin-model/return-model.vue'
// import Brand_Model from '@/components/model/admin-model/brand-model.vue'
// import Vendor_Model from '@/components/model/admin-model/vendor-model.vue'
// End Rental

// rental here
import MariHeader from '@/views/mari/layouts/header.vue'
import MariHeaderMenu from '@/views/mari/layouts/header-menu.vue'
import MariHeaderLogin from '@/views/mari/layouts/header-login.vue'
import MariFooter from '@/views/mari/layouts/footer.vue'

// modal
import Role_Model from '@/views/mari/pages/modal/role-model.vue'
import Menu_Model from '@/views/mari/pages/modal/menu-model.vue'
import Acces_Model from '@/views/mari/pages/modal/acces-model.vue'
import User_Model from '@/views/mari/pages/modal/user-model.vue'
import Company_Model from '@/views/mari/pages/modal/company-model.vue'
import Branch_Model from '@/views/mari/pages/modal/branch-model.vue'
import Papper_Model from '@/views/mari/pages/modal/papper-model.vue'
import Fines_Model from '@/views/mari/pages/modal/fines-model.vue'
import Expenditure_Model from '@/views/mari/pages/modal/expenditure-model.vue'
import Maintenance_Model from '@/views/mari/pages/modal/maintenance-model.vue'
import Customer_Model from '@/views/mari/pages/modal/customer-model.vue'
import Product_Model from '@/views/mari/pages/modal/product-model.vue'
import Vehicle_Model from '@/views/mari/pages/modal/vehicle-model.vue'
import Order_Model from '@/views/mari/pages/modal/order-model.vue'
import Return_Model from '@/views/mari/pages/modal/return-model.vue'
import Brand_Model from '@/views/mari/pages/modal/brand-model.vue'
import Vendor_Model from '@/views/mari/pages/modal/vendor-model.vue'
import Inspection_Model from '@/views/mari/pages/modal/inspection-model.vue'
import Report_driver_Model from '@/views/mari/pages/modal/driver-model.vue'
import Pelunasan_Model from '@/views/mari/pages/modal/pelunasan-model.vue'
import Invoice_Model from '@/views/mari/pages/modal/invoice-model.vue'
import Recap_Debit_Credit_Model from '@/views/mari/pages/modal/recap-debit-credit-model.vue'
import Incentive_Driver_Model from '@/views/mari/pages/modal/incentive-driver-model.vue'
import Image_View_Model from '@/views/mari/pages/modal/image-view-model.vue'

// componen guide
import MariGuideProfile from '@/views/mari/pages/guide/profile.vue'
import MariGuidePaket from '@/views/mari/pages/guide/jenis-paket.vue'
import MariGuideVehicle from '@/views/mari/pages/guide/add-vehicle.vue'
import MariGuideDriver from '@/views/mari/pages/guide/add-driver.vue'
import MariGuideCustomer from '@/views/mari/pages/guide/add-customer.vue'
// rental here

// plugins
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/assets/plugins/summernote/summernote-lite.min.css';
import '@/assets/plugins/summernote/summernote-lite.min.js';
import 'vue3-timepicker/dist/VueTimepicker.css';
import '@/assets/css/feather.css';
import '@/assets/css/feather.min.js';

// Firebase
import firebase from "firebase/compat/app";
import 'firebase/compat/storage';
import "firebase/compat/analytics";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAsUmHxqP_vQKDTXlzrJdAYu2QFgjMXgrM",
  authDomain: "gps-tracker-3c2a1.firebaseapp.com",
  databaseURL: "https://gps-tracker-3c2a1.firebaseio.com",
  projectId: "gps-tracker-3c2a1",
  storageBucket: "gps-tracker-3c2a1.appspot.com",
  messagingSenderId: "893074004983",
  appId: "1:893074004983:web:f27abdb4faaa62d395acf2"
};

firebase.initializeApp(firebaseConfig);

import swal from 'sweetalert2';
window.Swal = swal;

if(window.location.href.includes("/admin")) {
  require ('@/assets/css/admin.css');
} else {
  require('vue3-tel-input/dist/vue3-tel-input.css');
  require('@/assets/css/style.css');
}


axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// add token to localstorage
if (localStorage.getItem('token')) {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + localStorage.getItem('token');
}

// check error handling
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.data) {
    
    // error token expired
    if (error.response.data.status == 401) {
      console.log(error.response.data.message);
      swal.fire({icon: 'error', text: error.response.data.message});
      // store.dispatch('auth/logout');
      router.replace('/mari/login');
    }

    // error limit company
    if (error.response.status == 402) {
      router.replace('/mari/limit-company');
    }

    // error email not verified
    if (error.response.status == 403) {
      router.replace('/mari/email-validation');
    }

    // error validasi form
    if (error.response.status == 422) {
      let message = '';
      Object.keys(error.response.data).forEach(element => {
          message += error.response.data[element][0] + '<br />'
      })
      swal.fire({icon: 'error', html: message});
    }

    // error message
    if (error.response.status == 400) {
      swal.fire({icon: 'error', html: error.response.data.message});
    }

    return Promise.reject(error);
  }

  // error network
  if (!error.status) {
    swal.fire({icon: 'error', html: error.message});
  }
  return Promise.reject(error);
});

// guard for auth
router.beforeEach((to, from, next) => {
  if (
        to.path == '/mari/register' || 
        to.path == '/mari/login' || 
        to.path == '/mari/forgot-password' || 
        to.path == '/mari/email-verify' || 
        to.path == '/mari/reset-password' || 
        to.path == '/mari/email-validation'
      ) {
      next();
  } else {
      // var hasToken = localStorage.getItem('token');
      // if (hasToken) {
      //     next();
      // } else {
      //     next('/mari/login');
      // }
      var hasToken = localStorage.getItem('token');
      var hasUser = localStorage.getItem('user');

      if (hasToken && hasUser) {
        const parsedUser = JSON.parse(hasUser);
        const userRole = parsedUser.role.name;
  
        if (to.path === '/' || to.path === '/mari') {
          // Redirect based on role if accessing base URL
          if (["SUPER ADMIN", "OWNER", "Operator"].includes(userRole)) {
            next();
          } else if (userRole === 'Staff Kendaraan') {
            next('/mari/staff/home');
          } else if (userRole === 'Driver') {
            next('/mari/driver/home');
          } else {
            next('/mari/login');
          }
        } else if (to.meta.requiresAuth) {
          // Check if the route requires authentication and the user's role matches
          if (to.meta.roles && !to.meta.roles.includes(userRole)) {
            next('/mari/login'); // Redirect to login or a forbidden page
          } else if (to.meta.role && to.meta.role !== userRole) {
            next('/mari/login'); // Redirect to login or a forbidden page
          } else {
            next();
          }
        } else {
          next();
        }
      } else {
        next('/mari/login');
      }
  }
})

const app = createApp(App)

// rental here
app.component('mari-layout', MariHeader)
app.component('mari-header-menu',MariHeaderMenu)
app.component('mari-header-login',MariHeaderLogin)
app.component('mari-footer', MariFooter)
// rental here

//admin component
app.component('abusereport', Adminabusereport)
app.component('admin-service-information', AdminServiceInformation)
app.component('admin-service-availability', AdminServiceAvailability)
app.component('admin-service-location', AdminServiceLocation)
app.component('admin-service-gallery', AdminServiceGallery)
app.component('admin-service-timepicker', AdminServiceTimepicker)
app.component('admin-service-seo', AdminServiceSeo)
app.component('active-blog',Active_Blog)
app.component('inactive-bloglist',Inactive_Bloglist)
app.component('pending-bloglist',Pending_Bloglist)
app.component('chat-profile',Chat_Profile)
app.component('chatuser-list',Chatuser_list)
app.component('chatcontent',Chatcontent)


// *************** layout Components ***************
app.component('layout', Adminheader)
app.component('adminsidebar', Adminsidebar)
app.component('sidelinkheading',SideLinkHeading)
app.component('servicestab', ServicesTab)

app.component('layouts',header)
app.component('headerMenu',HeaderMenu)
app.component('customerheadmenu',CustomerHeadMenu)
app.component('otpheader',OtpHeader)
app.component('navbar',Nav)
app.component('customernav',CustomNav)
app.component('dashboardnavbar',DashNav)
app.component('successheader',SuccessHeader)
app.component('headertop',HeaderTop)
app.component('indextwoheader',IndexTwoHeader)
app.component('indexthreeheader',IndexThreeHeader)
app.component('indexfourheader',IndexFourHeader)
app.component('indexfiveheader',IndexFiveHeader)
app.component('indexsixheader',IndexSixHeader)
app.component('indexsevenheader',IndexSevenHeader)
app.component('indexeightheader',IndexEightHeader)
app.component('indexnineheadertop',IndexNineHeaderTop)
app.component('indexnineheader',IndexNineHeader)
app.component('customer-sidebar',Customer_Sidebar)


// *************** pages Components ***************

app.component('aboutprovider',AboutProvider)
app.component('truelyfooter',FooterDown)
app.component('sidebar',SideBar)
app.component('couponadd',CouponAdd)
app.component('addsubcontent',AddSubContent)
app.component('cursorpointer',Cursor)
app.component('about-us',About)
app.component('blogcontent',BlogContent)
app.component('blogsidebar',BlogSidebar)
app.component('bloggridcontent',BlogGridContent)
app.component('bloglistcontent',BlogListContent)
app.component('bookingdonecontent',BookingDoneContent)
app.component('bookingpaycontent',BookPayContent)
app.component('bookingcontent',BookingContent)
app.component('categoriescontent',CategoriesContent)
app.component('changepasswordcontent',ChangePassWordContent)
app.component('choosesignupcontent',ChooseSignUpContent)
app.component('comingsooncontent',ComingSoonContent)
app.component('connectappscontent',ConnectedAppsContent)
app.component('contactcontent',ContactContent)
app.component('contactmap',ContactMap)
app.component('custombookcalendarnav',CustomBookCalendarNav)
app.component('customercalendarcontent',CustomerCalendarContent)
app.component('customerbookdetails',CustomerBookDetails)
app.component('customerreschedule',CustomerReschedule)
app.component('cancelappointment',CancelAppointment)
app.component('customerbookingcontent',CustomerBookingContent)
app.component('customeraddreview',CustomerAddReview)
app.component('customerchatcontent',CustomerChatContent)
app.component('customerboardcontent',CustomerBoardContent)
app.component('customerfavcontent',CustomerFavContent)
app.component('customernotifycontent',CustomerNotifyContent)
app.component('customnotifydeleteacc',CustomerNotifyDeleteAcc)
app.component('customerprofilecontent',CustomerProfileContent)
app.component('customprofiledelete',CustomProfileDelete)
app.component('customerreviewcontent',CustomerReviewContent)
app.component('customerwalletcontent',CustomerWalletContent)
app.component('addwallet',AddWallet)
app.component('devicemanagementcontent',DevicemanageContent)
app.component('emailotpcontent',EmailOtpContent)
app.component('error404',Error404)
app.component('error500',Error500)
app.component('faqcontent',FaqContent)
app.component('freetrialcontent',FreeTrialContent)
app.component('howitsworkcontent',HowItsWorkContent)
app.component('invoicecontent',InvoiceContent)
app.component('passwordrecoverycontent',PasswordRecoveryContent)
app.component('paymentsettingcontent',PaymentSettingContent)
app.component('phoneotpcontent',PhoneOtpContent)
app.component('pricingcontent',PricingContent)
app.component('privacypolicycontent',PrivacyPolicyContent)
app.component('provideraddonscontent',ProviderAddonsContent)
app.component('providerdeleteaccount',ProviderDeleteAccount)
app.component('availabilitycontent',AvailabilityContent)
app.component('availabilitydelectacc',AvailabilityDeleteAcc)
app.component('bookingdetailscontent',BookingDetailsContent)
app.component('bookingdetailsaddleave',BookingDetailsAddLeave)
app.component('bookingdetailsdeleteacc',BookingDetailsDeleteAcc)
app.component('providerbookingcontent',ProviderBookingcontent)
app.component('providerbookdeleteacc',ProviderBookDelateAcc)
app.component('providerchatcontent',ProviderChatContent)
app.component('providerchatdeleteacc',ProviderChatDeleteAcc)
app.component('providerconnectappcontent',ProviderConnectAppContent)
app.component('providercouponcontent',ProviderCouponContent)
app.component('providerdashboardcontent',ProviderDashboardContent)
app.component('providerdashboarddeleteacc',ProviderDashboardDeleteAcc)
app.component('providerdetails',ProviderDetails)
app.component('providerdevicemanagementContent',ProviderDeviceManagementContent)
app.component('providerearningcontent',ProviderEarningContent)
app.component('providerearningdeleteacc',ProviderEarningDeleteAcc)
app.component('providereditservicecontent',ProviderEditServiceContent)
app.component('providerholidaycontent',ProviderHolidayContent)
app.component('provideraddholiday',ProviderAddHoliday)
app.component('provideralertmessage',ProviderAlertMessage)
app.component('providernotificationcontent',ProviderNotificationContent)
app.component('provideroffercontent',ProviderOfferContent)
app.component('provideraddcoupon',ProviderAddCoupon)
app.component('providerpayoutcontent',ProviderPayoutContent)
app.component('provideraddwallet',ProviderAddWallet)
app.component('providerPlancontent',ProviderPlanContent)
app.component('providerprofilesettingscontent',ProviderProfileSettingsContent)
app.component('providerreviewcontent',ProviderReviewContent)
app.component('providerreviewdeleteacc',ProviderReviewDeleteAcc)
app.component('providersecuritycontent',ProviderSecurityContent)
app.component('providersecuritychangeemail',ProviderSecurityChangeEmail)
app.component('providerservicecontent',ProviderServiceContent)
app.component('providersserviceactive',ProviderServiceActive)
app.component('providerserviceinactive',ProviderServiceInactive)
app.component('providerservicedeleteservice',ProviderServiceDeleteService)
app.component('providerservicescontent',ProviderServicesContent)
app.component('provideractiveservice',ProviderActiveService)
app.component('providerdeleteservice',ProviderDeleteService)
app.component('providersocialprofilecontent',ProviderSocialProfileContent)
app.component('providersubscriptioncontent',ProviderSubscriptionContent)
app.component('providersubscriptiondeleteacc',ProviderSubscriptionDeleteAcc)
app.component('providerlistcontent',ProviderListContent)
app.component('resetpasswordcontent',ResetpasswordContent)
app.component('searchlistcontent',SearchListContent)
app.component('searchcontent',SearchContent)
app.component('securitysettingscontent',SecuritySettingsContent)
app.component('securitysettingschangemail',SecuritySettingsChangeMail)
app.component('servicedetailscontent',ServiceDetailsContent)
app.component('servicegridcontent',ServiceGridContent)
app.component('servicelistcontent',ServiceListContent)
app.component('usersignupcontent',UserSignupContent)
app.component('indexprofessional',IndexProfessional)
app.component('indexfeatureservice',IndexFeatureService)
app.component('indexoffering',IndexOffering)
app.component('indexplaning',IndexPlaning)
app.component('indexblog',IndexBlog)
app.component('indextwohero',IndexTwoHero)
app.component('indextwofeaturedservice',IndexTwoFeaturedService)
app.component('indextwopopular',IndexTwoPopular)
app.component('indextwopricing',indexpricing)
app.component('indextwoclient',IndexTwoClient)
app.component('indextwopartners',IndexTwoPartners)
app.component('indextwofooter',IndexTwoFooter)
app.component('indexthreebanner',IndexThreeBanner)
app.component('indexthreeappointment',IndexThreeAppointment)
app.component('indexthreeservice',IndexThreeService)
app.component('indexthreework',IndexThreeWork)
app.component('indexthreelatest',IndexThreeLatest)
app.component('indexthreefooter',IndexThreeFooter)
app.component('relatedservice',RelatedService)
app.component('scroll',Scroll)
app.component('indexfourbanner',IndexFourBanner)
app.component('indexfourcatering',IndexFourCatering)
app.component('indexfourcategories',IndexFourCategories)
app.component('indexfourwork',IndexFourWork)
app.component('indexfourservices',IndexFourServices)
app.component('indexfourbranch',IndexFourBranch)
app.component('indexfourblog',IndexFourBlog)
app.component('indexfourfooter',IndexFourFooter)
app.component('indexfivebanner',IndexFiveBanner)
app.component('indexfivefeatured',IndexFiveFeatured)
app.component('indexfivecategories',IndexFiveCategories)
app.component('indexfivetestimonial',IndexFiveTestimonial)
app.component('indexfivepartner',IndexFivePartner)
app.component('indexfiveservice',IndexFiveService)
app.component('indexfiveapp',IndexFiveApp)
app.component('indexfivefooter',IndexFiveFooter)
app.component('indexsixbanner',IndexSixBanner)
app.component('indexsixcompany',IndexSixCompany)
app.component('indexsixwork',IndexSixWork)
app.component('indexsixteam',IndexSixTeam)
app.component('indexsixpricing',IndexSixPricing)
app.component('indexsixfooter',IndexSixFooter)
app.component('indexsevenbanner',IndexSevenBanner)
app.component('indexsevenpopular',IndexSevenPopular)
app.component('indexsevenservice',IndexSevenService)
app.component('indexsevenprovider',IndexSevenProvider)
app.component('indexseventestimonial',IndexSevenTestimonial)
app.component('indexsevenpricing',IndexSevenPricing)
app.component('indexsevenpartner',IndexSevenPartner)
app.component('indexsevenfooter',IndexSevenFooter)
app.component('indexeightbanner',IndexEightBanner)
app.component('indexeightpopular',IndexEightPopular)
app.component('indexeightmeet',IndexEightMeet)
app.component('indexeightcustomer',IndexEightCustomer)
app.component('indexeightblog',IndexEightBlog)
app.component('indexeightfooter',IndexEightFooter)
app.component('indexninebanner',IndexNineBanner)
app.component('indexnineservice',IndexNineService)
app.component('indexninemechanics',IndexNineMechanics)
app.component('indexninecustomer',IndexNineCustomer)
app.component('indexnineadditional',IndexNineAdditional)
app.component('indexnineblog',IndexNineBlog)
app.component('indexninepartner',IndexNinePartner)
app.component('indexninefooter',IndexNineFooter)
app.component('service-information',ServiceInformation)
app.component('service-availability', ServiceAvailability)
app.component('service-location', ServiceLocation)
app.component('service-gallery',ServiceGallery)
app.component('service-seo', ServiceSeo)
app.component('bookingsort',Bookingsort)
app.component('bookinglist',Bookinglist)
app.component('frontend-timepicker', TimePicker)
app.component('service-lightbox',ServiceLightbox)
app.component('servicessticksidebar',Servicessticksidebar)
app.component('servicedetails',Servicedetails)
app.component('relatedservice2',Relatedservice2)
app.component('servicedetails2stick',Servicedetails2stick)
app.component('serviceoverview',Serviceoverview)
app.component('dashboardbooking',Dashboardbooking)
app.component('myserviceactive',Myserviceactive)
app.component('myserviceinactive',Myserviceinactive)
app.component('providerbookinglist',Proivderbookinglist)
app.component('pagination',Pagination)
app.component('recenttransaction',Recenttransaction)
app.component('dashboardwidget',Dashboardwidget)
app.component('recentbooking',Recentbooking)
app.component('favouritelist',Favouritelist)
app.component('favouritesort',Favouritesort)
app.component('booking-service', BookingService)

app.component('breadcrumb',BreadCrumb)
app.component('blogbreadcrumb',BlogBreadcrumb)
app.component('provider-header', ProviderHeader)

/****************** Admin Model  ****************/
app.component('announcement-model',Announcement_Model)
app.component('abuse-model',Abusemodel)
app.component('sms-template-model',Sms_Template_Model)
app.component('email-template-model',Email_Template_Model)
app.component('email-newsletter-model',Email_Newsletter_Model)
app.component('featured-service-model',Featured_Service_Model)
app.component('service-offers-model',Service_Offers_Model)
app.component('roles-model',Roles_Model)
app.component('customers-model',Customers_Model)
app.component('provider-model',Provider_Model)
app.component('faq-model',Faq_Model)
app.component('testimonials-model',Testimonials_Model)
app.component('conutries-model',Conutries_Model)
app.component('states-model',States_Model)
app.component('cities-model',Cities_Model)
app.component('users-model',Users_Model)
app.component('pages-model',Pages_Model)
app.component('payout-request-model',Payout_Request_Model)
app.component('sub-categories-model',Sub_Categories_Model)
app.component('categories-model',Categories_Model)
app.component('review-type-model',Review_Type_Model)
app.component('report-delete-model',Report_Delete_Model)
app.component('delete-model',Delete_Model)
app.component('delete-model-one',Delete_Model_One)
app.component('taxratemodel',Taxrate_model)
app.component('security-model',Security_Model)
app.component('blog-categories-model',Blog_Categories_Model)
app.component('marketing-coupons-model',Marketing_Coupons_Model)
app.component('database-backup-model',Database_Backup_Model)
app.component('system-model',System_Model)
app.component('banip-model',Banip_Model)
app.component('storage-model',Storage_Model)
app.component('currencies-model',Currencies_Model)
app.component('payment-model',Payment_Model)
app.component('gateways-model',Gateways_Model)
app.component('sms-model',Sms_Model)
app.component('email-model',Email_Model)
app.component('social-model',Social_Model)
app.component('contact-message-model',Contact_Message_Model)
app.component('offers-model',Offers_Model)

// Start Rental
// view
// app.component('role-settings',RoleSetting)
// app.component('menu-settings',MenuSetting)
// app.component('acces-settings',AccesSetting)
// app.component('user-settings',UserSetting)
// app.component('company-settings',CompanySetting)
// app.component('branch-settings',BranchSetting)
// app.component('papper-settings',PapperSetting)
// app.component('fines-settings',FinesSetting)
// app.component('expenditure-settings',ExpenditureSetting)
// app.component('maintenance-settings',MaintenanceSetting)
// app.component('customer',Customer)
// app.component('product',Product)
// app.component('vehicle',Vehicle)
// app.component('vehicle-detail',VehicleDetail)
// app.component('order',Order)
// app.component('check-vehicle',CheckVehicle)
// app.component('return-list',ReturnList)
// app.component('jadwal-order',JadwalOrder)
// app.component('jadwal-maintenance',JadwalMaintenance)
// app.component('finance',Finance)
// app.component('subscribe',Subscribe)
// app.component('help',Help)
// app.component('monitoring',Monitoring)
// app.component('monitoring2',Monitoring2)
// app.component('pengaturan',Pengaturan)
// app.component('finance-menu',FinanceMenu)
// app.component('brand',Brand)
// app.component('vendor',Vendor)
// app.component('create-vehicle',CreateVehicle)

// model
app.component('role-model',Role_Model)
app.component('menu-model',Menu_Model)
app.component('acces-model',Acces_Model)
app.component('user-model',User_Model)
app.component('company-model',Company_Model)
app.component('branch-model',Branch_Model)
app.component('papper-model',Papper_Model)
app.component('fines-model',Fines_Model)
app.component('expenditure-model',Expenditure_Model)
app.component('maintenance-model',Maintenance_Model)
app.component('customer-model',Customer_Model)
app.component('product-model',Product_Model)
app.component('vehicle-model',Vehicle_Model)
app.component('order-model',Order_Model)
app.component('return-model',Return_Model)
app.component('brand-model',Brand_Model)
app.component('vendor-model',Vendor_Model)
app.component('inspection-model',Inspection_Model)
app.component('report-driver-model',Report_driver_Model)
app.component('pelunasan-model',Pelunasan_Model)
app.component('invoice-model',Invoice_Model)
app.component('recap-debit-credit-model',Recap_Debit_Credit_Model)
app.component('incentive-driver-model',Incentive_Driver_Model)
app.component('image-view-model',Image_View_Model)

// guide component
app.component('guide-profile',MariGuideProfile)
app.component('guide-paket',MariGuidePaket)
app.component('guide-vehicle',MariGuideVehicle)
app.component('guide-driver',MariGuideDriver)
app.component('guide-customer',MariGuideCustomer)
// End Rental


// *************** Model Components ***************
app.component('model', Model)
app.component('c-booking-model',C_Booking_Model)
app.component('provider-coupons-model',Provider_Coupons_Model)
app.component('provider-booking-model',Provider_Booking_Model)
app.component('provider-settings-model',Provider_settings_Model)

app.component('SummernoteEditor', SummernoteEditor);
app.component('vue-select', VueSelect)
app.component('datepicker', DatePicker)
app.component('timepicker',VueTimepicker)
app.component('vue3-autocounter', Vue3Autocounter)

.use(store)
.use(VueTelInput)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(Antd)
.use(LoadingPlugin, {
  color: 'blue',
  loader: 'dots',
  height: 64,
  width: 64,
})
.use(AOS.init())
.use(ganttastic)
app.use(VueSweetalert2)
app.use(VCalendar, {})
app.use(VueApexCharts);
app.use(VueEasyLightbox);
app.use(router).mount('#app');
