<template>
  <!-- Partners Section -->
  <section class="our-partners-seven">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-seven aos" data-aos="fade-up">
            <h2>Our Partners</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
          </div>
        </div>
        <div class="owl-carousel partners-slider-seven aos" data-aos="fade-up">
          <Carousel
            :autoplay="3000"
            :wrap-around="true"
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <Slide v-for="item in indexsevenpartner" :key="item.id">
              <div class="partner-img">
                <img :src="require(`@/assets/img/partner/${item.image}`)" alt="img" />
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>
  <!-- Partners Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexsevenpartner from "@/assets/json/indexsevenpartner.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexsevenpartner: indexsevenpartner,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
