<template>
    <div class="card add-service">
        <div class="row mt-4">
            <div class="col-12">
                <form>
                    <div class="col-12">
                        <div class="col-6">
                            <div class="mb-3">
                                <div class="profile-upload-content">
            
                                    <!-- company logo here -->
                                    <img v-if="(data !== undefined) && (data.logo !== '')" :src="data.logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                    <!-- company logo here -->
            
                                    <!-- upload file form logo here -->
                                    <div class="profile-upload-btn">
                                        <div class="profile-upload-file">
                                            <input
                                                type="file"
                                                class="form-control"
                                                ref="uploadFieldAttachment"
                                                id="imgInp"
                                                @change="selectFileAttachment"
                                            />
                                        </div>
                                    </div>
                                        <!-- upload file form logo here -->
                                    <div class="profile-upload-para">
                                        <p style="font-size: 12px;">
                                        *ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                                        File yang diizinkan .png dan .jpg.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nama</label>
                            <input type="text" class="form-control" v-model="data.name"/>
                        </div>
                        <div>
                            <label class="form-label">Nomer Plat</label>
                            <input type="text" class="form-control" v-model="data.nomor_plat"/>
                        </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-group">
                            <div class="d-flex">
                                <div class="col-6">
                                    <div class="col-lg-12">
                                        <div class="split-check-title">
                                            <label class="form-label">Transmisi</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio1" name="transmisi" value="Manual" v-model="data.transmisi">
                                            <label class="form-check-label" for="inlineRadio1">Manual</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio2" name="transmisi" value="Matic" v-model="data.transmisi">
                                            <label class="form-check-label" for="inlineRadio2">Matic</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Tahun Produksi</label>
                                    <input type="text" class="form-control" v-model="data.production_year"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Kapasitas (Tempat duduk)</label>
                            <input type="number" class="form-control" v-model="data.capacity"/>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Brand</label>
                            <select class="form-select" v-model="data.brand_id">
                                <option v-for="brand in brandData" :key="brand.id" :value="brand.id" >{{ brand.name }}</option>
                            </select>
                        </div>
                        </div>
                    </div>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nomer STNK</label>
                            <input type="text" class="form-control" v-model="data.nomor_stnk"/>
                        </div>
                        <div>
                            <label class="form-label">Warna</label>
                            <input type="text" class="form-control" v-model="data.color"/>
                        </div>
                        </div>
                    </div>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Jenis BBM yang digunakan</label>
                            <input type="text" class="form-control" v-model="data.bbm_type"/>
                        </div>
                        <div>
                            <label class="form-label">Catatan</label>
                            <input type="text" class="form-control" v-model="data.remark"/>
                        </div>
                        </div>
                    </div>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                            <div class="col-6" style="margin-right: 10px;">
                                <label class="form-label">Jenis Sewa</label>
                                <select v-model="data.product_id" class="form-select">
                                    <option v-for="option in productData" :value="option.id">{{ option.rentalTypeName }} - {{ option.name }}</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <label class="form-label">Harga</label>
                                <input v-model.number="data.price" class="form-control" type="number" placeholder="Item Price">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="field-bottom-btns">
                    <!-- <div class="field-btns">
                        <button class="btn btn-primary prev_btn" type="button"
                        @click="previousStep" :disabled="currentStep === 1"><i
                                class="fa-solid fa-arrow-left"></i>Prev</button>
                    </div> -->
                    <div class="field-btns">
                        <button class="btn btn-primary next_btn" type="button"
                        @click="save" :disabled="currentStep === totalSteps">Selanjutnya <i
                                class="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        // mari
        data: {},
        logo: ''
      };
    },
    props: {
        currentStep : Object,
        totalSteps : Object,
        idCompany : Object,
        productData: {
            type: Array,
            default: () => []
        },
        brandData: {
            type: Array,
            default: () => []
        },
        vendorData: {
            type: Array,
            default: () => []
        },
    },
    methods: {
    validateFile(file, allowedFileTypes, maxFileSize) {
        if (!allowedFileTypes.includes(file.type)) {
            this.$swal.fire({
                icon: 'warning',
                text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
            });
            return false;
        }

        if (file.size > maxFileSize) {
            this.$swal.fire({
                icon: 'warning',
                text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
            });
            return false;
        }

        return true;
    },
    // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      save() {
        this.data.image = this.logo;
        this.data.remark = this.data.remark !== undefined ? this.data.remark : "None";
        this.data.vehicle_prices = [{
            'product_id': this.data.product_id,
            'price': this.data.price
        }];
        var loading = this.$loading.show()
        this.$store
        .dispatch("vehicle/addVehicle", this.data )
        .then((data) => {
            if (data.status == 201) {
                this.updateGuide();
                loading.hide()
                this.data = {};
                this.$emit('nextVehicle');
            }
        },
        (error) => {
            console.log(error)
            loading.hide();
        })
        .catch((e) => {
            loading.hide()
        })
      },
      updateGuide() {
        this.$store
        .dispatch("guide/updateGuide", { id: this.idCompany, data: { guide_step : "is_vehicle"} })
        .then((data) => {
            if (data.status == 200) {
                // loading.hide()
            }
        },
        (error) => {
            console.log(error)
            // loading.hide();
        })
        .catch((e) => {
            // loading.hide()
        })
      },
      previousStep() {
        this.$emit('previous');
      }
    },
  };
  </script>
  