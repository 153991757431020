<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>Booking List</h6>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div class="review-sort d-flex align-items-center me-2">
            <p>Sort</p>
            <vue-select :options="abcd" placeholder="A -> Z" />
          </div>
          <div class="grid-listview">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <img src="@/assets/img/icons/filter-icon.svg" alt="" />
                </a>
              </li>
              <li>
                <router-link to="/provider-book-details">
                  <i class="feather-calendar"></i>
                </router-link>
              </li>
              <li>
                <router-link to="/provider-services">
                  <i class="feather-grid"></i>
                </router-link>
              </li>
              <li>
                <router-link to="/provider-booking" class="active">
                  <i class="feather-list"></i>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Header -->
    <providerbookinglist></providerbookinglist>
    <!-- Pagination -->
    <pagination></pagination>
    <!-- /Pagination -->

    <!-- Add Review -->
    <provider-booking-model></provider-booking-model>
    <!-- /Add Review -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      abcd: ["A -> Z", "Most Helpful"],
    };
  },
  mounted() {
    document.querySelectorAll(".fav-icon").forEach(function (element) {
      element.addEventListener("click", function () {
        element.classList.toggle("selected");
      });
    });
  },
};
</script>
