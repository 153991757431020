<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content w-100 ps-0 pt-0">
        <sidelinkheading />

        <div class="row">
          <div class="content-table">
            <div class="content-page-header content-page-headersplit">
              <h5>Currency</h5>
              <div class="page-head-btn">
                <router-link class="btn btn-linegrey" to="/admin/currency-settings"
                  ><i class="feather feather-settings"></i
                ></router-link>
                <a
                  class="btn btn-primary"
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#add-currency"
                  ><i class="fa fa-plus me-2"></i>Add Currency</a
                >
              </div>
            </div>
            <div class="col-12">
              <div class="table-resposnive table-bottom">
                <div class="table datatable">
                  <a-table :columns="columns" :data-source="data">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'Actions'">
                        <h6 :class="record.Class">{{ record.Actions }}</h6>
                      </template>
                      <template v-else-if="column.key === 'id'">
                        <div class="action-language">
                          <ul>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="delete-table"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-currency"
                                ><i class="far fa-edit"></i
                              ></a>
                            </li>
                            <li>
                              <a
                                class="delete-table"
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#delete"
                                ><i class="far fa-trash-alt"></i
                              ></a>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <currencies-model></currencies-model>
</template>

<script>
const columns = [
  {
    title: "Currency_Name",
    dataIndex: "Currency_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Currency_Name.toLowerCase();
        b = b.Currency_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Code",
    dataIndex: "Code",
    sorter: {
      compare: (a, b) => {
        a = a.Code.toLowerCase();
        b = b.Code.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Symbol",
    dataIndex: "Symbol",
    sorter: {
      compare: (a, b) => {
        a = a.Symbol.toLowerCase();
        b = b.Symbol.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Exchange Rate",
    dataIndex: "Exchange_Rate",
    sorter: {
      compare: (a, b) => {
        a = a.Exchange_Rate.toLowerCase();
        b = b.Exchange_Rate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    dataIndex: "Actions",
    key: "Actions",
    sorter: {
      compare: (a, b) => {
        a = a.Actions.toLowerCase();
        b = b.Actions.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
  },
];
const data = [
  {
    id: "1",
    Currency_Name: "US Dollar",
    Code: "USD",
    Symbol: "$",
    Exchange_Rate: "Default",
    Class: "success-labels",
    Actions: "Action",
    Action: "",
  },
  {
    id: "2",
    Currency_Name: "Turkiye Lira",
    Code: "TRY",
    Symbol: "₺",
    Exchange_Rate: "Default",
    Class: "success-labels",
    Actions: "Action",
    Action: "",
  },
  {
    id: "3",
    Currency_Name: "EU Euro",
    Code: "EUR",
    Symbol: "€",
    Exchange_Rate: "Default",
    Class: "success-labels",
    Actions: "Action",
    Action: "",
  },
  {
    id: "4",
    Currency_Name: "India Rupee",
    Code: "INR",
    Symbol: "₹",
    Exchange_Rate: "Default",
    Class: "success-labels",
    Actions: "Action",
    Action: "",
  },
  {
    id: "5",
    Currency_Name: "England Pound",
    Code: "GBP",
    Symbol: "£",
    Exchange_Rate: "Default",
    Class: "success-labels",
    Actions: "Action",
    Action: "",
  },
];
export default {
  data() {
    return {
      columns,
      data,
    };
  },
};
</script>
