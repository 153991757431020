import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getMenu(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/menu', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addMenu(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/menu/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateMenu(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('menu/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteMenu(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('menu/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }



    },
    getters: {

    }

}