<template>
    <!-- Providers Section -->
			<section class="providers-section abt-provider">			
				<div class="container">
					<div class="section-heading">
						<div class="row">
							<div class="col-md-6">					
								<h2>Top Providers</h2>
								<p>Meet Our Experts</p>	
							</div>
							<div class="col-md-6 text-md-end">
								<router-link to="/providers" class="btn btn-primary btn-view">View All<i class="feather-arrow-right-circle"></i></router-link>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-3 col-sm-6">
							<div class="providerset">
								<div class="providerset-img">
									<router-link to="/provider-details">
										<img src="@/assets/img/provider/provider-11.jpg" alt="img">
									</router-link>
								</div>
								<div class="providerset-content">
									<div class="providerset-price">
										<div class="providerset-name">
											<h4><router-link to="/provider-details">John Smith</router-link><i class="fa fa-check-circle" aria-hidden="true"></i></h4>
											<span>Electrician</span>
										</div>
										<div class="providerset-prices">
											<h6>$20.00<span>/hr</span></h6>
										</div>
									</div>
									<div class="provider-rating">
										<div class="rating">
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star-half-stroke filled"></i><span>(320)</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-sm-6">
							<div class="providerset">
								<div class="providerset-img">
									<router-link to="/provider-details">
										<img src="@/assets/img/provider/provider-12.jpg" alt="img">
									</router-link>
								</div>
								<div class="providerset-content">
									<div class="providerset-price">
										<div class="providerset-name">
											<h4><router-link to="/provider-details">Michael</router-link><i class="fa fa-check-circle" aria-hidden="true"></i></h4>
											<span>Carpenter</span>
										</div>
										<div class="providerset-prices">
											<h6>$50.00<span>/hr</span></h6>
										</div>
									</div>
									<div class="provider-rating">
										<div class="rating">
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star-half-stroke filled"></i><span>(228)</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-sm-6">
							<div class="providerset">
								<div class="providerset-img">
									<router-link to="/provider-details">
										<img src="@/assets/img/provider/provider-13.jpg" alt="img">
									</router-link>
								</div>
								<div class="providerset-content">
									<div class="providerset-price">
										<div class="providerset-name">
											<h4><router-link to="/provider-details">Antoinette</router-link><i class="fa fa-check-circle" aria-hidden="true"></i></h4>
											<span>Cleaner</span>
										</div>
										<div class="providerset-prices">
											<h6>$25.00<span>/hr</span></h6>
										</div>
									</div>
									<div class="provider-rating">
										<div class="rating">
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star-half-stroke filled"></i><span>(130)</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-sm-6">
							<div class="providerset">
								<div class="providerset-img">
									<router-link to="/provider-details">
										<img src="@/assets/img/provider/provider-14.jpg" alt="img">
									</router-link>
								</div>
								<div class="providerset-content">
									<div class="providerset-price">
										<div class="providerset-name">
											<h4><router-link to="/provider-details">Thompson</router-link><i class="fa fa-check-circle" aria-hidden="true"></i></h4>
											<span>Mechanic</span>
										</div>
										<div class="providerset-prices">
											<h6>$25.00<span>/hr</span></h6>
										</div>
									</div>
									<div class="provider-rating">
										<div class="rating">
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star filled me-1"></i>
											<i class="fa-solid fa-star-half-stroke filled"></i><span>(95)</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Providers Section -->
			
			<!-- Client Section -->
			<section class="client-section">			
				<div class="container">
					<div class="row">
						<div class="col-md-12 text-center">
							<div class="section-heading">
								<h2>What our client says</h2>
								<p>Lorem ipsum dolor sit amet, consectetur elit</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="owl-carousel testimonial-slider">
							<Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
								<Slide v-for="item in indexplaning" :key="item.id">
									<div class="client-widget">
										<div class="client-img">
											<a href="javascript:;">
												<img class="img-fluid" alt="Image" :src="require(`@/assets/img/profiles/${item.image}`)">
											</a>
										</div>
										<div class="client-content">
											<div class="rating">
												<i class="fa-solid fa-star filled me-1"></i>
												<i class="fa-solid fa-star filled me-1"></i>
												<i class="fa-solid fa-star filled me-1"></i>
												<i class="fa-solid fa-star filled me-1"></i>
												<i class="fa-solid fa-star filled me-1"></i>
											</div>
											<p>{{ item.content }}</p>
											<h5>{{item.name}}</h5>
											<h6>{{item.position}}</h6>
										</div>
									</div>
								</Slide>
									<template #addons>
										<Navigation />
									</template>
							</Carousel>	
							
							
						</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Client Section -->
			
			<!-- Service Section -->
			<div class="service-offer">
				<div class="container">
					<div class="col-md-12">
						<div class="offer-paths about-offer">
							<div class="offer-path-content">
								<h3>Looking for the Best Service Finder & Bookings</h3>
								<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore architecto beatae vitae dicta sunt explicabo.</p>
								<router-link to="/service-grid" class="btn btn-primary btn-views">Get Started<i class="feather-arrow-right-circle"></i></router-link>
							</div>
							<div class="offer-pathimg">
								<img src="@/assets/img/service-img.png" alt="img">
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /Service Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexplaning from '@/assets/json/indexplanning.json'
import "vue3-carousel/dist/carousel.css";
export default {
	data() {
		return {
			indexplaning: indexplaning,
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
				loop: true,
				margin: 24,
			},

			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 700px and up
				767: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 991px and up
				991: {
					itemsToShow: 2,
					snapAlign: "center"
				},
				// 1024 and up
				1024: {
					itemsToShow: 2,
					snapAlign: "start"

				}
			}
		}
	},
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
}
</script>

<style>
.client-widget{
	margin-right: 24px;
}
</style>