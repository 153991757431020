<script setup>
  import { ref } from 'vue'

  const emit = defineEmits(['onConfirmed'])

  const isOpen = ref(false)

  function openModal() {
    isOpen.value = true
  }

  function closeModal() {
    isOpen.value = false
  }

  function onYesClick() {
    closeModal()
    emit('onConfirmed')
  }

  defineExpose({
    openModal
  })

// show display: block aria-model=true
</script>

<template>
  <div :style="{'display': isOpen ? 'block' : 'none'}" :class="{'show': isOpen}" class="modal fade" aria-modal="true" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Konfirmasi</h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          >
          </button>
        </div>
        <div class="modal-body pt-4">
          <p>Apakah anda yakin merubah kendaraan?</p> 
          <div class="d-flex justify-content-end">
            <button @click="onYesClick" class="btn btn-primary">Iya</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isOpen" :class="{'show': isOpen}" class="modal-backdrop fade"></div>
</template>
