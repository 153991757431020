<template>
    <div class="tab-pane fade" id="slot_thursday">
        <div class="hours-info">
            <h4 class="nameof-day">Thursday</h4>
            <div class="row hours-cont mb-3">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">From</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">To</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value1" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">Slots</label>
                        <input type="text" class="form-control" placeholder="Enter Slot">
                    </div>
                </div>
            </div>
            <div class="row hours-cont mb-3" v-for="(certify, index) in certify" :key="index">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">From</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value2" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">To</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value3" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex">
                        <div class="form-group w-100">
                            <label class="col-form-label">Slots</label>
                            <input type="text" class="form-control" placeholder="Enter Slot">
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">&nbsp;</label>
                            <a href="javascript:;" class="btn btn-danger-outline trash" @click="deletecertify(index)">
                                <i class="far fa-trash-alt mt-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:;" class="add-text add-hours" @click="addcertify">
            <i class="feather-plus-circle"></i> Add More
        </a>
    </div>
    <div class="tab-pane fade" id="slot_friday">
        <div class="hours-info">
            <h4 class="nameof-day">Friday</h4>
            <div class="row hours-cont mb-3">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">From</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value4" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">To</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value5" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">Slots</label>
                        <input type="text" class="form-control" placeholder="Enter Slot">
                    </div>
                </div>
            </div>
            <div class="row hours-cont mb-3" v-for="(certify1, index1) in certify1" :key="index1">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">From</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value6" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">To</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value7" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex">
                        <div class="form-group w-100">
                            <label class="col-form-label">Slots</label>
                            <input type="text" class="form-control" placeholder="Enter Slot">
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">&nbsp;</label>
                            <a href="javascript:;" class="btn btn-danger-outline trash" @click="deletecertifyOne(index1)">
                                <i class="far fa-trash-alt mt-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:;" class="add-text add-hours" @click="addcertifyOne">
            <i class="feather-plus-circle"></i> Add More
        </a>
    </div>
    <div class="tab-pane fade" id="slot_saturday">
        <div class="hours-info">
            <h4 class="nameof-day">Saturday</h4>
            <div class="row hours-cont mb-3">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">From</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value8" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">To</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value9" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">Slots</label>
                        <input type="text" class="form-control" placeholder="Enter Slot">
                    </div>
                </div>
            </div>
            <div class="row hours-cont mb-3" v-for="(certify2, index2) in certify2" :key="index2">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">From</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value10" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">To</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value11" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex">
                        <div class="form-group w-100">
                            <label class="col-form-label">Slots</label>
                            <input type="text" class="form-control" placeholder="Enter Slot">
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">&nbsp;</label>
                            <a href="javascript:;" class="btn btn-danger-outline trash" @click="deletecertifyTwo(index2)">
                                <i class="far fa-trash-alt mt-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:;" class="add-text add-hours" @click="addcertifyTwo">
            <i class="feather-plus-circle"></i> Add More
        </a>
    </div>
    <div class="tab-pane fade" id="slot_sunday">
        <div class="hours-info">
            <h4 class="nameof-day">Sunday</h4>
            <div class="row hours-cont mb-3">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">From</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value12" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">To</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value13" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group ">
                        <label class="col-form-label">Slots</label>
                        <input type="text" class="form-control" placeholder="Enter Slot">
                    </div>
                </div>
            </div>
            <div class="row hours-cont mb-3" v-for="(certify3, index3) in certify3" :key="index3">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">From</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value14" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="col-form-label">To</label>
                        <div class="form-icon">
                            <a-time-picker v-model:value="value15" class="form-control timepicker" placeholder="Select Time"
                                use12-hours format="h:mm a" />
                            <span class="cus-icon"><i class="feather-clock"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex">
                        <div class="form-group w-100">
                            <label class="col-form-label">Slots</label>
                            <input type="text" class="form-control" placeholder="Enter Slot">
                        </div>
                        <div class="form-group">
                            <label class="col-form-label">&nbsp;</label>
                            <a href="javascript:;" class="btn btn-danger-outline trash" @click="deletecertifyThree(index3)">
                                <i class="far fa-trash-alt mt-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:;" class="add-text add-hours" @click="addcertifyThree">
            <i class="feather-plus-circle"></i> Add More
        </a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            certify: [],
            certify1: [],
            certify2: [],
            certify3: [],
            value: null,
            value1: null,
            value2: null,
            value3: null,
            value4: null,
            value5: null,
            value6: null,
            value7: null,
            value8: null,
            value9: null,
            value10: null,
            value11: null,
            value12: null,
            value13: null,
            value14: null,
            value15: null,
        }
    },
    methods: {
        addcertify() {
            this.certify.push({});
        },
        deletecertify(index) {
            this.certify.splice(index, 1);
        },
        addcertifyOne() {
            this.certify1.push({});
        },
        deletecertifyOne(index1) {
            this.certify1.splice(index1, 1);
        },
        addcertifyTwo() {
            this.certify2.push({});
        },
        deletecertifyTwo(index2) {
            this.certify2.splice(index2, 1);
        },
        addcertifyThree() {
            this.certify3.push({});
        },
        deletecertifyThree(index3) {
            this.certify3.splice(index3, 1);
        },
    }
}
</script>