<template>
    <!-- Show Order -->
    <div class="modal fade" id="show-order" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Order</h5>
            <button
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetMap"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form>
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="mb-3">
                      <label class="form-label">Jenis Sewa <span style="color:red">*</span></label>
                      <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.product_id" @change="getCustomerVerif" disabled>
                          <option v-for="product in productData" :key="product.id" :value="product.id">{{ product.rentalTypeName }} - {{ product.name }}</option>
                      </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Awal {{ isMember ? 'Penjemputan' : 'Pengambilan/Pengantaran' }}  <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formEdit.starting_date" disabled/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="time" class="form-control"  v-model="formEdit.starting_time" disabled/>
                          </div>
                      </div>
                  </div>

                  <div class="mb-3">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Akhir  {{ isMember ? 'Pengantaran' : 'Pengambilan/Pengantaran' }} <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formEdit.finish_date" disabled/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="time" class="form-control"  v-model="formEdit.finish_time" disabled/>
                          </div>
                      </div>
                  </div>

                </div>
              </div>

              <div class="row" v-if="isMember">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Layanan Ambil <span style="color:red">*</span></label>
                    <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.pick_type" disabled>
                      <option value="diantar">Diantar</option>
                      <option value="diambil">Diambil Sendiri</option>
                    </select>
                  </div>
                  <div class="mb-3" v-if="(formEdit !== undefined) && (formEdit.pick_type == 'diantar')">
                      <label class="form-label">Alamat Pengantaran <span style="color:red">*</span></label>
                      <div class="d-flex">
                        <input type="text" class="form-control me-3"  v-model="formEdit.pick_up_point"/>
                        <!-- <mari-input-datalist v-if="formEdit !== undefined" :value="mapLocationQuery.value" :list="mapLocationQuery.list" :listKey="'display_name'" @input="onMapLocationQueryChange" @data-click="onMapLocationQueryDataClick" class="me-3" disabled/> -->
                        <!-- <button @click="mapLocationState.isShown = !mapLocationState.isShown" type="button" class="btn btn-primary">Map</button> -->
                      </div>
                  </div>
                  <!-- <div v-if="mapLocationState.marker || mapLocationState.isShown" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" :start-marker="mapLocationState.marker" @location-change="v => mapLocationState.marker = v" disabled/>
                  </div> -->
                  <!-- <LocationPickerMap v-if="isShowMapCreate" :geolocation="true" :q="formEdit.pick_up_point" @onMapMove="onMapMoveCreate" />  -->
                </div>
                <div class="col-md-6">
                  <div class="mb-3" v-if="(formEdit !== undefined) && (formEdit.pick_type == 'diantar')">
                      <label class="form-label">Ongkos Pengantaran <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_ongkir" disabled/>
                  </div>
                </div>
              </div>

              <div class="row" v-if="!isMember">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Alamat Penjemputan <span style="color:red">*</span></label>
                      <div class="d-flex">
                        <input v-if="formEdit !== undefined" type="text" class="form-control me-3"  v-model="formEdit.pick_up_point"/>
                      </div>
                  </div>
                  <!-- <div v-if="mapLocationState.marker || mapLocationState.isShown" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" :start-marker="mapLocationState.marker" @location-change="v => mapLocationState.marker = v" disabled/>
                  </div> -->
                </div>
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Rute Tujuan Awal dan Akhir Pemakaian Kendaraan <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formEdit.interpoint" disabled/>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row mt-4">
                <div class="col-6">
                  <div class="row">
                    <div>
                      <div class="mb-3">
                        <label class="form-label">Pelanggan <span style="color:red">*</span></label>
                        <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.customer_id" @change="updateCategory" disabled>
                          <option v-for="customer in filteredCustomer" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div>
                      <div class="mb-3">
                        <label class="form-label">Kategori <span style="color:red">*</span></label>
                        <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.category" disabled/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Pilih Kendaraan</th>
                      <th>{{ isMember ? 'Pilih Staff' : 'Pilih Driver' }}</th>
                      <th>Tanggal Awal</th>
                      <th>Tanggal Akhir</th>
                      <th v-if="isCategory">Nama Tamu</th>
                      <th v-if="isCategory">No Tlp Tamu</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="formEdit && formEdit.order_vehicle" v-for="(orderVehicle, index) in formEdit.order_vehicle" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <select class="form-select" v-model="orderVehicle.vehicle_id" required disabled>
                          <option disabled value="">Pilih satu</option>
                          <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id">
                            {{ vehicle.name }} - {{ vehicle.nomor_plat }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <select class="form-select" v-model="orderVehicle.driver_id" required disabled>
                          <option disabled value="">Pilih satu</option>
                          <option v-for="driver in listUser" :key="driver.id" :value="driver.id">
                            {{ driver.full_name }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input type="datetime-local" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].tgl_awal"
                              :min="minStartDateTime" :max="maxFinishDateTime" required disabled>
                      </td>
                      <td>
                        <input type="datetime-local" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].tgl_akhir"
                              :min="minStartDateTime" :max="maxFinishDateTime" required disabled>
                      </td>
                      <td v-if="isCategory">
                        <input type="text" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].nama_tamu" disabled>
                      </td>
                      <td v-if="isCategory">
                        <input type="text" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].phone_tamu" disabled>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr />

              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Harga Sewa <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formattedEditValues.total_price" @input="onInput('formEdit', 'total_price', $event)" disabled/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Total Harga Sewa <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formattedEditValues.harus_bayar" disabled/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Catatan</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formEdit.remark" disabled/>
                </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Discount (%) <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.discount" disabled/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Metode Pembayaran <span style="color:red">*</span></label>
                    <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.payment_method" disabled>
                        <option value="Transfer">Transfer</option>
                        <option value="Cash">Cash</option>
                        <option value="Debit/Credit">Debit/Credit</option>
                    </select>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">DP <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formattedEditValues.dp"  @input="onInput('formEdit', 'dp', $event)" disabled/>
                  </div>
                </div>
              </div>

              <div v-if="(formEdit && (formEdit.order_vehilce && (formEdit.order_vehicle.length > 0)))" class="row">
                <h4>List Kendaraan di Split</h4>
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Vehicle Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(vehicle, index) in formEdit.order_vehicle" :key="vehicle.vehicle_id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ getVehicleName(vehicle.vehicle_id) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-primary me-2"
                  @click="sendWhatsAppMessage(formEdit)"
                >
                  Kirim Konfirmasi Order
                </button>
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Show Order -->

    <!-- Show Order Driver -->
    <div class="modal fade" id="show-order-driver" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Order</h5>
            <button
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetMap"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form>
              <div class="row mt-4">
                <div class="col-md-6 col-12">
                  <div class="mb-3">
                    <label class="form-label">Jenis Sewa <span style="color:red">*</span></label>
                    <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.product_id" @change="getCustomerVerif" disabled>
                      <option v-for="product in productData" :key="product.id" :value="product.id">{{ product.rentalTypeName }} - {{ product.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="mb-3 form-group">
                    <div class="d-flex flex-column flex-md-row">
                      <div class="me-md-2">
                        <label class="form-label">Tanggal & Waktu Awal {{ isMember ? 'Penjemputan' : 'Pengambilan/Pengantaran' }} <span style="color:red">*</span></label>
                        <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.starting_date" disabled/>
                      </div>
                      <span class="mt-2 mt-md-4">&nbsp;-&nbsp;</span> <!-- Time separator -->
                      <div class="ms-md-2">
                        <label class="form-label">Jam <span style="color:red">*</span></label>
                        <input v-if="formEdit !== undefined" type="time" class="form-control" v-model="formEdit.starting_time" disabled/>
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <div class="d-flex flex-column flex-md-row">
                      <div class="me-md-2">
                        <label class="form-label">Tanggal & Waktu Akhir {{ isMember ? 'Pengantaran' : 'Pengambilan/Pengantaran' }} <span style="color:red">*</span></label>
                        <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.finish_date" disabled/>
                      </div>
                      <span class="mt-2 mt-md-4">&nbsp;-&nbsp;</span> <!-- Time separator -->
                      <div class="ms-md-2">
                        <label class="form-label">Jam <span style="color:red">*</span></label>
                        <input v-if="formEdit !== undefined" type="time" class="form-control" v-model="formEdit.finish_time" disabled/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="isMember">
                <div class="col-md-6 col-12">
                  <div class="mb-3">
                    <label class="form-label">Layanan Ambil <span style="color:red">*</span></label>
                    <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.pick_type" disabled>
                      <option value="diantar">Diantar</option>
                      <option value="diambil">Diambil Sendiri</option>
                    </select>
                  </div>
                  <div class="mb-3" v-if="formEdit !== undefined && formEdit.pick_type === 'diantar'">
                    <label class="form-label">Alamat Pengantaran <span style="color:red">*</span></label>
                    <div class="d-flex">

                        <input type="text" class="form-control me-3"  v-model="formEdit.pick_up_point"/>
                      <!-- <mari-input-datalist v-if="formEdit !== undefined" :value="mapLocationQuery.value" :list="mapLocationQuery.list" :listKey="'display_name'" @input="onMapLocationQueryChange" @data-click="onMapLocationQueryDataClick" class="me-3" disabled/>
                      <button @click="mapLocationState.isShown = !mapLocationState.isShown" type="button" class="btn btn-primary">Map</button> -->
                    </div>
                  </div>
                  <!-- <div v-if="mapLocationState.marker || mapLocationState.isShown" class="map-container" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" :start-marker="mapLocationState.marker" @location-change="v => mapLocationState.marker = v" disabled/>
                  </div> -->
                </div>
                <div class="col-md-6 col-12">
                  <div class="mb-3" v-if="formEdit !== undefined && formEdit.pick_type === 'diantar'">
                    <label class="form-label">Ongkos Pengantaran <span style="color:red">*</span></label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_ongkir" disabled/>
                  </div>
                </div>
              </div>

              <div class="row" v-if="!isMember">
                <div class="col-md-6 col-12">
                  <div class="mb-3">
                    <label class="form-label">Alamat Penjemputan <span style="color:red">*</span></label>
                    <div class="d-flex">
                      <!-- <mari-input-datalist v-if="formEdit !== undefined" :value="mapLocationQuery.value" :list="mapLocationQuery.list" :listKey="'display_name'" @input="onMapLocationQueryChange" @data-click="onMapLocationQueryDataClick" class="me-3" disabled/>
                      <button @click="mapLocationState.isShown = !mapLocationState.isShown" type="button" class="btn btn-primary">Map</button> -->
                    </div>
                  </div>
                  <!-- <div v-if="mapLocationState.marker || mapLocationState.isShown" class="map-container" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" :start-marker="mapLocationState.marker" @location-change="v => mapLocationState.marker = v" disabled/>
                  </div> -->
                </div>
                <div class="col-md-6 col-12">
                  <div class="mb-3">
                    <label class="form-label">Rute Tujuan Awal dan Akhir Pemakaian Kendaraan <span style="color:red">*</span></label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.interpoint" disabled/>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row mt-4">
                <div class="col-md-6 col-12">
                  <div class="mb-3">
                    <label class="form-label">Pelanggan <span style="color:red">*</span></label>
                    <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.customer_id" @change="updateCategory" disabled>
                      <option v-for="customer in filteredCustomer" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="mb-3">
                    <label class="form-label">Kategori <span style="color:red">*</span></label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.category" disabled/>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Pilih Kendaraan</th>
                        <th>{{ isMember ? 'Pilih Staff' : 'Pilih Driver' }}</th>
                        <th>Tanggal Awal</th>
                        <th>Tanggal Akhir</th>
                        <th v-if="isCategory">Nama Tamu</th>
                        <th v-if="isCategory">No Tlp Tamu</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="formEdit && formEdit.order_vehicle" v-for="(orderVehicle, index) in formEdit.order_vehicle" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>
                          <select class="form-select" v-model="orderVehicle.vehicle_id" required disabled>
                            <option disabled value="">Pilih satu</option>
                            <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id">
                              {{ vehicle.name }} - {{ vehicle.nomor_plat }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <select class="form-select" v-model="orderVehicle.driver_id" required disabled>
                            <option disabled value="">Pilih satu</option>
                            <option v-for="driver in listUser" :key="driver.id" :value="driver.id">
                              {{ driver.full_name }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <input type="datetime-local" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].tgl_awal"
                                :min="minStartDateTime" :max="maxFinishDateTime" required disabled>
                        </td>
                        <td>
                          <input type="datetime-local" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].tgl_akhir"
                                :min="minStartDateTime" :max="maxFinishDateTime" required disabled>
                        </td>
                        <td v-if="isCategory">
                          <input type="text" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].nama_tamu" disabled>
                        </td>
                        <td v-if="isCategory">
                          <input type="text" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].phone_tamu" disabled>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="resetMap">Tutup</button>
          </div>
        </div>
      </div>
    </div>

    <!-- /Show Order Driver -->

    <!-- Add Order -->
    <div class="modal fade custom-modal" id="add-order" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Order</h5>
            <button
              id="close-create"
              type="button"
              ref="addOrderRefBtn"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetMap"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="row mt-4">
                <div class="col-md-6">
                  <!-- <div class="mb-3">
                      <label class="form-label">Pilih Kendaraan <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.vehicle_id">
                          <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id">{{ vehicle.name }} - {{ vehicle.nomor_plat }}</option>
                      </select>
                  </div> -->
                  <div class="mb-3">
                      <label class="form-label">Jenis Sewa <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.product_id" @change="getCustomerVerif">
                          <option v-for="product in productData" :key="product.id" :value="product.id">{{ product.rentalTypeName }} - {{ product.name }}</option>
                      </select>
                  </div>
                  
                </div>
                <div class="col-md-6">

                  <!-- <div class="mb-3">
                      <label class="form-label">Jenis Sewa <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.product_id" @change="getCustomerVerif">
                          <option v-for="product in productData" :key="product.id" :value="product.id">{{ product.rentalTypeName }} - {{ product.name }}</option>
                      </select>
                  </div> -->
                  <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Awal {{ isMember ? 'Penjemputan' : 'Pengambilan/Pengantaran' }}  <span style="color:red">*</span></label>
                              <input type="date" class="form-control"  v-model="formCreate.starting_date"/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input type="time" class="form-control"  v-model="formCreate.starting_time"/>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Akhir  {{ isMember ? 'Pengantaran' : 'Pengambilan/Pengantaran' }} <span style="color:red">*</span></label>
                              <input type="date" class="form-control"  v-model="formCreate.finish_date" :min="formCreate.starting_date ?? null" />
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input type="time" class="form-control"  v-model="formCreate.finish_time"/>
                          </div>
                      </div>
                  </div>

                </div>
              </div>

              <div class="row" v-if="isMember">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Layanan Ambil <span style="color:red">*</span></label>
                    <select class="form-select" v-model="formCreate.pick_type">
                      <option value="diantar">Diantar</option>
                      <option value="diambil">Diambil Sendiri</option>
                    </select>
                  </div>
                  <div class="mb-3" v-if="formCreate.pick_type == 'diantar'">
                      <label class="form-label">Alamat Pengantaran <span style="color:red">*</span></label>
                      <div class="d-flex">
                        <input type="text" class="form-control me-3"  v-model="formCreate.pick_up_point"/>
                        <!-- <mari-input-datalist :value="mapLocationQuery.value" :list="mapLocationQuery.list" :listKey="'display_name'" @input="onMapLocationQueryChange" @data-click="onMapLocationQueryDataClick" class="me-3" />
                        <button @click="mapLocationState.isShown = !mapLocationState.isShown" type="button" class="btn btn-primary">Map</button> -->
                      </div>
                  </div>
                  <!-- <div v-if="mapLocationState.isShown" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" @location-change="v => mapLocationState.marker = v" />
                  </div> -->
                </div>
                <div class="col-md-6">
                  <div class="mb-3" v-if="formCreate.pick_type == 'diantar'">
                      <label class="form-label">Ongkos Pengantaran <span style="color:red">*</span></label>
                      <input type="number" class="form-control" v-model="formCreate.fee_ongkir"/>
                  </div>
                  <div class="mb-3" v-if="formCreate.pick_type == 'diantar'">
                      <label class="form-label">Bayar Ongkos Dengan<span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCustomer.ongkir_type">
                          <option value="Cash">Cash</option>
                          <option value="Transfer">Transfer</option>
                      </select>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Rute Tujuan Pemakaian Kendaraan <span style="color:red">*</span></label>
                      <input type="text" class="form-control"  v-model="formCreate.interpoint"/>
                  </div>
                </div>
              </div>

              <div class="row" v-if="!isMember">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Alamat Penjemputan <span style="color:red">*</span></label>
                      <div class="d-flex">
                        <input type="text" class="form-control me-3"  v-model="formCreate.pick_up_point"/>

                        <!-- <mari-input-datalist :value="mapLocationQuery.value" :list="mapLocationQuery.list" :listKey="'display_name'" @input="onMapLocationQueryChange" @data-click="onMapLocationQueryDataClick" class="me-3" />
                        <button @click="mapLocationState.isShown = !mapLocationState.isShown" type="button" class="btn btn-primary">Map</button> -->
                      </div>
                  </div>
                  <!-- <div v-if="mapLocationState.isShown" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" @location-change="v => mapLocationState.marker = v" />
                  </div> -->
                  <!-- <LocationPickerMap v-if="isShowMapCreate" :geolocation="true" :q="formCreate.pick_up_point" @onMapMove="onMapMoveCreate" />                   -->
                </div>
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Rute Tujuan Awal dan Akhir Pemakaian Kendaraan <span style="color:red">*</span></label>
                      <input type="text" class="form-control"  v-model="formCreate.interpoint"/>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row mt-4">
                <div class="col-6">
                  <div class="row">
                    <div v-if="!isDriver">
                      <div class="mb-3">
                        <label class="form-label">Kategori <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCreate.filter_category">
                            <option value="REGULER">REGULER</option>
                            <option value="COOPORATE">COOPORATE</option>
                            <option value="RENT TO RENT">RENT TO RENT</option>
                            <option value="INTERNAL">INTERNAL</option>
                        </select>
                      </div>
                      
                    </div>
                    <div class="col-12 mb-4">
                      <a v-show="!isMember" @click="isDriver = !isDriver" class="btn btn-primary col-4"><i class="fa fa-plus me-2"></i>{{ isDriver ? 'Batalkan' : 'Pelanggan Baru' }}</a>
                    </div>
                  </div>
                  <div v-if="isDriver" class="row">
                    <h6>Data Pelanggan Baru</h6>
                    <div class="mb-3 form-group">
                      <div class="mb-3">
                        <label class="form-label">Nama Pelanggan <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formCustomer.name"/>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">No Telepon <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formCustomer.phone_number"/>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Jenis Kelamin <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCustomer.gender">
                            <option value="Laki - Laki">Laki - Laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Jenis Pelanggan <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCustomer.type">
                            <option value="REGULER">REGULER</option>
                            <option value="COOPORATE">COOPORATE</option>
                            <option value="RENT TO RENT">RENT TO RENT</option>
                            <option value="INTERNAL">INTERNAL</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6" v-if="!isDriver">
                  <div class="row">
                    <div>
                      <div class="mb-3">
                        <label class="form-label">Pelanggan <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCreate.customer_id" @change="updateCategory">
                          <option v-for="customer in filteredCustomer" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-6">
                  <div v-if="!isMember" class="mb-3">
                    <label class="form-label">Driver <span style="color:red">*</span></label>
                    <select class="form-select" v-model="formCreate.driver_id">
                        <option v-for="driver in listUser" :key="driver.id" :value="driver.id">{{ driver.full_name }}</option>
                    </select>
                  </div>
                </div> -->
              </div>

              <hr />

              <div class="row">
                <div class="row mb-3">
                  <button type="button" @click="addVehicle" class="btn btn-primary col-auto">Tambah Kendaraan</button>
                </div>
                
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Pilih Kendaraan</th>
                      <th>{{ isMember ? 'Pilih Staff' : 'Pilih Driver' }}</th>
                      <th>Tanggal Awal</th>
                      <th>Tanggal Akhir</th>
                      <th v-if="isCategory">Nama Tamu</th>
                      <th v-if="isCategory">No Tlp Tamu</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(orderVehicle, index) in formCreate.order_vehicle" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <select class="form-select" v-model="orderVehicle.vehicle_id" required>
                          <option disabled value="">Pilih satu</option>
                          <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id">
                            {{ vehicle.name }} - {{ vehicle.nomor_plat }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <select class="form-select" v-model="orderVehicle.driver_id" required>
                          <option disabled value="">Pilih satu</option>
                          <option v-for="driver in listUser" :key="driver.id" :value="driver.id">
                            {{ driver.full_name }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input type="datetime-local" class="form-control" v-model="orderVehicle.tgl_awal"
                              :min="minStartDateTime" :max="maxFinishDateTime" required >
                      </td>
                      <td>
                        <input type="datetime-local" class="form-control" v-model="orderVehicle.tgl_akhir"
                              :min="minStartDateTime" :max="maxFinishDateTime" required >
                      </td>
                      <td v-if="isCategory">
                        <input type="text" class="form-control" v-model="orderVehicle.nama_tamu">
                      </td>
                      <td v-if="isCategory">
                        <input type="text" class="form-control" v-model="orderVehicle.phone_tamu">
                      </td>
                      <td>
                        <button type="button" @click="removeVehicle(index)" class="btn btn-danger">Remove</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr />

              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Harga Sewa <span style="color:red">*</span></label>
                      <input type="text" class="form-control" v-model="formattedCreateValues.total_price" @input="onInput('formCreate', 'total_price', $event)"/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Total Harga Sewa <span style="color:red">*</span></label>
                      <input type="text" class="form-control" v-model="formattedCreateValues.harus_bayar" disabled/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Catatan</label>
                      <input type="text" class="form-control"  v-model="formCreate.remark"/>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Discount (%)</label>
                      <input type="number" class="form-control"  v-model="formCreate.discount"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Metode Pembayaran <span style="color:red">*</span></label>
                    <select class="form-select" v-model="formCreate.payment_method">
                        <option value="Transfer">Transfer</option>
                        <option value="Cash">Cash</option>
                        <option value="Debit/Credit">Debit/Credit</option>
                    </select>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">DP <span style="color:red">*</span></label>
                      <input type="text" class="form-control"  v-model="formattedCreateValues.dp" @input="onInput('formCreate', 'dp', $event)"/>
                  </div>
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Order -->

    <!-- Add Order detail -->
    <div class="modal fade custom-modal" id="add-order2" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Order</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              ref="closeButtonCreateOrderModalRef"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <!-- <i class="feather feather-x"></i> -->
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">

              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="mb-3">
                      <label class="form-label">Pilih Kendaraan <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.vehicle_id" disabled>
                          <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id" :selected="dataId === vehicle.id" :disabled="dataId === vehicle.id">{{ vehicle.name }} - {{ vehicle.nomor_plat }}</option>
                      </select>
                  </div>
                  <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Awal {{ isMember ? 'Penjemputan' : 'Pengambilan/Pengantaran' }}  <span style="color:red">*</span></label>
                              <input type="date" class="form-control"  v-model="formCreate.starting_date"/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input type="time" class="form-control"  v-model="formCreate.starting_time"/>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-6">

                  <div class="mb-3">
                      <label class="form-label">Jenis Sewa <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.product_id" @change="getCustomerVerif">
                          <option v-for="product in productData" :key="product.id" :value="product.id">{{ product.rentalTypeName }} - {{ product.name }}</option>
                      </select>
                  </div>
                  <div class="mb-3">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Akhir  {{ isMember ? 'Pengantaran' : 'Pengambilan/Pengantaran' }} <span style="color:red">*</span></label>
                              <input type="date" class="form-control"  v-model="formCreate.finish_date"/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input type="time" class="form-control"  v-model="formCreate.finish_time"/>
                          </div>
                      </div>
                  </div>

                </div>
              </div>

              <div class="row" v-if="isMember">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Layanan Ambil <span style="color:red">*</span></label>
                    <select class="form-select" v-model="formCreate.pick_type">
                      <option value="diantar">Diantar</option>
                      <option value="diambil">Diambil Sendiri</option>
                    </select>
                  </div>
                  <div class="mb-3" v-if="formCreate.pick_type == 'diantar'">
                      <label class="form-label">Alamat Pengantaran <span style="color:red">*</span></label>
                      <div class="d-flex">
                        <input type="text" class="form-control me-3"  v-model="formCreate.pick_up_point"/>
                        <!-- <mari-input-datalist :value="mapLocationQuery.value" :list="mapLocationQuery.list" :listKey="'display_name'" @input="onMapLocationQueryChange" @data-click="onMapLocationQueryDataClick" class="me-3" />
                        <button @click="mapLocationState.isShown = !mapLocationState.isShown" type="button" class="btn btn-primary">Map</button> -->
                      </div>
                  </div>
                  <!-- <div v-if="mapLocationState.isShown" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" @location-change="v => mapLocationState.marker = v" />
                  </div> -->
                </div>
                <div class="col-md-6">
                  <div class="mb-3" v-if="formCreate.pick_type == 'diantar'">
                      <label class="form-label">Ongkos Pengantaran <span style="color:red">*</span></label>
                      <input type="number" class="form-control" v-model="formCreate.fee_ongkir"/>
                  </div>
                </div>
              </div>

              <div class="row" v-if="!isMember">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Alamat Penjemputan <span style="color:red">*</span></label>
                      <div class="d-flex">
                        <!-- <mari-input-datalist :value="mapLocationQuery.value" :list="mapLocationQuery.list" :listKey="'display_name'" @input="onMapLocationQueryChange" @data-click="onMapLocationQueryDataClick" class="me-3" />
                        <button @click="mapLocationState.isShown = !mapLocationState.isShown" type="button" class="btn btn-primary">Map</button> -->
                      </div>
                  </div>
                  <!-- <LocationPickerMap v-if="isShowMapCreate" :geolocation="true" :q="formCreate.pick_up_point" @onMapMove="onMapMoveCreate" />                   -->
                  <!-- <div v-if="mapLocationState.isShown" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" @location-change="v => mapLocationState.marker = v" />
                  </div> -->
                </div>
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Rute Tujuan Awal dan Akhir Pemakaian Kendaraan <span style="color:red">*</span></label>
                      <input type="text" class="form-control"  v-model="formCreate.interpoint"/>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row mt-4">
                <div class="col-6">
                  <div v-if="!isMember" class="mb-3">
                    <label class="form-label">Driver <span style="color:red">*</span></label>
                    <select class="form-select" v-model="formCreate.driver_id">
                        <option v-for="driver in listUser" :key="driver.id" :value="driver.id">{{ driver.full_name }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="row">
                    <div v-if="!isDriver">
                      <div class="mb-3">
                        <label class="form-label">Pelanggan <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCreate.customer_id" @change="updateCategory">
                          <option v-for="customer in filteredCustomer" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 mb-4">
                      <a v-show="!isMember" @click="isDriver = !isDriver" class="btn btn-primary col-4"><i class="fa fa-plus me-2"></i>{{ isDriver ? 'Batalkan' : 'Pelanggan Baru' }}</a>
                    </div>
                  </div>
                  <div v-if="isDriver" class="row">
                    <h6>Data Pelanggan Baru</h6>
                    <div class="mb-3 form-group">
                      <div class="mb-3">
                        <label class="form-label">Nama Pelanggan <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formCustomer.name"/>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">No Telepon <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formCustomer.phone_number"/>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Jenis Kelamin <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCustomer.gender">
                            <option value="Laki - Laki">Laki - Laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Jenis Pelanggan <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCustomer.type">
                            <option value="REGULER">REGULER</option>
                            <option value="COOPORATE">COOPORATE</option>
                            <option value="RENT TO RENT">RENT TO RENT</option>
                            <option value="INTERNAL">INTERNAL</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Total Sewa <span style="color:red">*</span></label>
                      <input type="text" class="form-control" v-model="formattedCreateValues.total_price" @input="onInput('formCreate', 'total_price', $event)"/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Catatan</label>
                      <input type="text" class="form-control"  v-model="formCreate.remark"/>
                </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label class="form-label">Metode Pembayaran <span style="color:red">*</span></label>
                    <select class="form-select" v-model="formCreate.payment_method">
                        <option value="Transfer">Transfer</option>
                        <option value="Cash">Cash</option>
                        <option value="Debit/Credit">Debit/Credit</option>
                    </select>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">DP <span style="color:red">*</span></label>
                      <input type="text" class="form-control" v-model="formattedCreateValues.dp" @input="onInput('formCreate', 'dp', $event)"/>
                  </div>
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Order -->
  
    <!-- Edit Order -->
    <div class="modal fade custom-modal" id="edit-order" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Order</h5>
            <button
              id="close-edit"
              ref="closeEditOrderRef"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetMap()"
            >
              <!-- <i class="feather feather-x"></i> -->
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="row mt-4">
                <div class="col-md-6">
                  <!-- <div class="mb-3">
                      <label class="form-label">Pilih Kendaraan <span style="color:red">*</span></label>
                      <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.vehicle_id">
                          <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id" :selected="dataId === vehicle.id" :disabled="dataId === vehicle.id">{{ vehicle.name }} - {{ vehicle.nomor_plat }}</option>
                      </select>
                  </div> -->
                  <div class="mb-3">
                      <label class="form-label">Jenis Sewa <span style="color:red">*</span></label>
                      <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.product_id" @change="getCustomerVerif">
                          <option v-for="product in productData" :key="product.id" :value="product.id">{{ product.rentalTypeName }} - {{ product.name }}</option>
                      </select>
                  </div>
                  <!-- <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Awal {{ isMember ? 'Penjemputan' : 'Pengambilan/Pengantaran' }}  <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="date" class="form-control"  v-model="formEdit.starting_date"/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span>
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="time" class="form-control"  v-model="formEdit.starting_time"/>
                          </div>
                      </div>
                  </div> -->
                </div>
                <div class="col-md-6">

                  <!-- <div class="mb-3">
                      <label class="form-label">Jenis Sewa <span style="color:red">*</span></label>
                      <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.product_id" @change="getCustomerVerif">
                          <option v-for="product in productData" :key="product.id" :value="product.id">{{ product.rentalTypeName }} - {{ product.name }}</option>
                      </select>
                  </div> -->
                  <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Awal {{ isMember ? 'Penjemputan' : 'Pengambilan/Pengantaran' }}  <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="date" class="form-control"  v-model="formEdit.starting_date"/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="time" class="form-control"  v-model="formEdit.starting_time"/>
                          </div>
                      </div>
                  </div>

                  <div class="mb-3">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Akhir  {{ isMember ? 'Pengantaran' : 'Pengambilan/Pengantaran' }} <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="date" class="form-control"  v-model="formEdit.finish_date"/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="time" class="form-control"  v-model="formEdit.finish_time"/>
                          </div>
                      </div>
                  </div>

                </div>
              </div>

              <div class="row" v-if="isMember">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Layanan Ambil <span style="color:red">*</span></label>
                    <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.pick_type">
                      <option value="diantar">Diantar</option>
                      <option value="diambil">Diambil Sendiri</option>
                    </select>
                  </div>
                  <div class="mb-3" v-if="(formEdit !== undefined) && (formEdit.pick_type == 'diantar')">
                      <label class="form-label">Alamat Pengantaran <span style="color:red">*</span></label>
                      <div class="d-flex">
                        test
                        <input type="text" class="form-control me-3"  v-model="formEdit.pick_up_point"/>
                        <!-- <mari-input-datalist v-if="formEdit !== undefined" :value="mapLocationQuery.value" :list="mapLocationQuery.list" :listKey="'display_name'" @input="onMapLocationQueryChange" @data-click="onMapLocationQueryDataClick" class="me-3" />
                        <button @click="mapLocationState.isShown = !mapLocationState.isShown" type="button" class="btn btn-primary">Map</button> -->
                      </div>
                  </div>
                  <!-- <div v-if="mapLocationState.marker && mapLocationState.isShown" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" :start-marker="mapLocationState.marker" @location-change="v => mapLocationState.marker = v" />
                  </div> -->
                  <!-- <LocationPickerMap v-if="isShowMapCreate" :geolocation="true" :q="formEdit.pick_up_point" @onMapMove="onMapMoveCreate" />  -->
                </div>
                <div class="col-md-6">
                  <div class="mb-3" v-if="(formEdit !== undefined) && (formEdit.pick_type == 'diantar')">
                      <label class="form-label">Ongkos Pengantaran <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_ongkir"/>
                  </div>
                </div>
              </div>

              <div class="row" v-if="!isMember">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Alamat Penjemputan <span style="color:red">*</span></label>
                      <div class="d-flex">
                        <input v-if="formEdit !== undefined" type="text" class="form-control me-3"  v-model="formEdit.pick_up_point"/>
                        <!-- <mari-input-datalist v-if="formEdit !== undefined" :value="mapLocationQuery.value" :list="mapLocationQuery.list" :listKey="'display_name'" @input="onMapLocationQueryChange" @data-click="onMapLocationQueryDataClick" class="me-3" />
                        <button @click="mapLocationState.isShown = !mapLocationState.isShown" type="button" class="btn btn-primary">Map</button> -->
                      </div>
                  </div>
                  <!-- <div v-if="mapLocationState.marker || mapLocationState.isShown" style="height:400px;">
                    <mari-location-picker-map :center="mapLocationState.center" :start-marker="mapLocationState.marker" @location-change="v => mapLocationState.marker = v" />
                  </div> -->
                </div>
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Rute Tujuan Awal dan Akhir Pemakaian Kendaraan <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formEdit.interpoint"/>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row mt-4">
                <!-- <div class="col-6">
                  <div v-if="!isMember" class="mb-3">
                    <label class="form-label">Driver <span style="color:red">*</span></label>
                    <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.driver_id">
                        <option v-for="driver in listUser" :key="driver.id" :value="driver.id">{{ driver.full_name }}</option>
                    </select>
                  </div>
                </div> -->

                <div class="col-6">
                  <div class="row">
                    <div>
                      <div class="mb-3">
                        <label class="form-label">Pelanggan <span style="color:red">*</span></label>
                        <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.customer_id" @change="updateCategory">
                          <option v-for="customer in filteredCustomer" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div>
                      <div class="mb-3">
                        <label class="form-label">Kategori <span style="color:red">*</span></label>
                        <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.category" disabled/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="row mb-3">
                  <button type="button" @click="addVehicleToEdit" class="btn btn-primary col-auto">Tambah Kendaraan</button>
                </div>
                
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Pilih Kendaraan</th>
                      <th>{{ isMember ? 'Pilih Staff' : 'Pilih Driver' }}</th>
                      <th>Tanggal Awal</th>
                      <th>Tanggal Akhir</th>
                      <th v-if="isCategory">Nama Tamu</th>
                      <th v-if="isCategory">No Tlp Tamu</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="formEdit && formEdit.order_vehicle" v-for="(orderVehicle, index) in formEdit.order_vehicle" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <select class="form-select" v-model="orderVehicle.vehicle_id" required>
                          <option disabled value="">Pilih satu</option>
                          <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id">
                            {{ vehicle.name }} - {{ vehicle.nomor_plat }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <select class="form-select" v-model="orderVehicle.driver_id" required>
                          <option disabled value="">Pilih satu</option>
                          <option v-for="driver in listUser" :key="driver.id" :value="driver.id">
                            {{ driver.full_name }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input type="datetime-local" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].tgl_awal"
                              :min="minStartDateTime" :max="maxFinishDateTime" required>
                      </td>
                      <td>
                        <input type="datetime-local" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].tgl_akhir"
                              :min="minStartDateTime" :max="maxFinishDateTime" required>
                      </td>
                      <td v-if="isCategory">
                        <input type="text" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].nama_tamu">
                      </td>
                      <td v-if="isCategory">
                        <input type="text" class="form-control" v-model="orderVehicle.order_vehicle_detail[0].phone_tamu">
                      </td>
                      <td>
                        <button type="button" @click="removeVehicleFromEdit(index)" class="btn btn-danger">Remove</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr />

              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Harga Sewa <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formattedEditValues.total_price" @input="onInput('formEdit', 'total_price', $event)"/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Total Harga Sewa <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formattedEditValues.harus_bayar" disabled/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Catatan</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formEdit.remark"/>
                </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Discount (%) <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.discount"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Metode Pembayaran <span style="color:red">*</span></label>
                    <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.payment_method">
                        <option value="Transfer">Transfer</option>
                        <option value="Cash">Cash</option>
                        <option value="Debit/Credit">Debit/Credit</option>
                    </select>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">DP <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formattedEditValues.dp"  @input="onInput('formEdit', 'dp', $event)"/>
                  </div>
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Order -->
  
    <!-- /Delete Order-->
    <div class="modal fade" id="delete-order" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Order ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Order-->

    <!-- /Cancel Order-->
    <div class="modal fade" id="cancel-order" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Cancel Order ?</h5>
  
            <button
              id="close-cancel"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to cancel this order?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="cancelData">Update</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Cancel Order-->

    <!-- Add Order Rent To Rent -->
    <div class="modal fade custom-modal" id="add-order-rtr" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Order</h5>
            <button
              id="close-create"
              type="button"
              ref="closeSplit"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmitRTR">

              <div class="row mt-4">
                <div class="col-6">

                  <div v-if="!isNewVehicle" class="mb-3">
                    <label class="form-label">Pilih Kendaraan <span style="color:red">*</span></label>
                    <select class="form-select" v-model="formCreate.vehicle_id">
                        <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id">{{ vehicle.name }} - {{ vehicle.nomor_plat }}</option>
                    </select>
                  </div>

                  <div class="col-12">
                    <a @click="isNewVehicle = !isNewVehicle" class="btn btn-primary col-4">{{ !isNewVehicle ? 'Kendaraan Baru' : 'Batalkan' }}</a>
                  </div>

                  <div v-if="isNewVehicle" class="row mt-4">
                    <h5>Kendaraan Baru</h5>
                    <div class="mb-3 form-group">
                      <div class="mb-3">
                        <label class="form-label">Nama Pemilik <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formVendor.full_name"/>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">No Telepon <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formVendor.phone_number"/>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Alamat <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formVendor.address"/>
                      </div>
                      <div class="mb-3">
                      <label class="form-label">Merek Kendaraan <span style="color:red">*</span></label>
                          <select class="form-select" v-model="formVehicle.brand_id">
                              <option v-for="brand in brandData" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
                          </select>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Name Kendaraan <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formVehicle.name"/>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Plat Nomer <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formVehicle.nomor_plat"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Jenis Sewa <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.product_id" @change="getCustomerVerif">
                          <option v-for="product in productData" :key="product.id" :value="product.id">{{ product.rentalTypeName }} - {{ product.name }}</option>
                      </select>
                  </div>
                </div>
              </div>

              
              <div class="row mt-4">
                <div class="col-6">
                  <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Penjemputan <span style="color:red">*</span></label>
                              <input type="date" class="form-control"  v-model="formCreate.starting_date"/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input type="time" class="form-control"  v-model="formCreate.starting_time"/>
                          </div>
                      </div>
                  </div>

                  <div class="mb-3">
                      <label class="form-label">Alamat Penjemputan/Pengambilan <span style="color:red">*</span></label>
                      <input type="text" class="form-control"  v-model="formCreate.pick_up_point"/>
                  </div>

                  <div v-if="!isMember" class="mb-3">
                      <label class="form-label">Driver <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.driver_id">
                          <option v-for="driver in listUser" :key="driver.id" :value="driver.id">{{ driver.full_name }}</option>
                      </select>
                  </div>
                </div>
                <div class="col-6">

                  <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal Akhir Pengantaran <span style="color:red">*</span></label>
                              <input type="date" class="form-control"  v-model="formCreate.finish_date"/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input type="time" class="form-control"  v-model="formCreate.finish_time"/>
                          </div>
                      </div>
                  </div>
                  
                  <div class="mb-3">
                      <label class="form-label">Rute Tujuan Awal dan Akhir Pemakaian Kendaraan <span style="color:red">*</span></label>
                      <input type="text" class="form-control"  v-model="formCreate.interpoint"/>
                  </div>

                  <div class="row">
                    <div v-if="!isDriver">
                      <div class="mb-3">
                        <label class="form-label">Customer</label>
                        <select class="form-select" v-model="formCreate.customer_id" @change="updateCategory">
                          <option v-for="customer in filteredCustomer" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 mb-4">
                      <a v-show="!isMember" @click="isDriver = !isDriver" class="btn btn-primary col-4">Pelanggan Baru</a>
                    </div>
                  </div>
                  <div v-if="isDriver" class="row mt-4">
                    <h5>Pelanggan Baru</h5>
                    <div class="mb-3 form-group">
                      <div class="mb-3">
                        <label class="form-label">Nama Pelanggan <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formCustomer.name"/>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">No Telepon <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formCustomer.phone_number"/>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Jenis Kelamin <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCustomer.gender">
                            <option value="Laki - Laki">Laki - Laki</option>
                            <option value="Perempuan">Perempuan</option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Jenis Pelanggan <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCustomer.type">
                            <option value="REGULER">REGULER</option>
                            <option value="COOPORATE">COOPORATE</option>
                            <option value="RENT TO RENT">RENT TO RENT</option>
                            <option value="INTERNAL">INTERNAL</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Pax / Jumlah Orang <span style="color:red">*</span></label>
                      <input type="number" class="form-control" v-model="formCreate.jml_org"/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Total Sewa <span style="color:red">*</span></label>
                      <input type="number" class="form-control" v-model="formCreate.total_price"/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Catatan</label>
                      <input type="text" class="form-control"  v-model="formCreate.remark"/>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label class="form-label">Metode Pembayaran <span style="color:red">*</span></label>
                    <select class="form-select" v-model="formCreate.payment_method">
                        <option value="Transfer">Transfer</option>
                        <option value="Cash">Cash</option>
                        <option value="Debit/Credit">Debit/Credit</option>
                    </select>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">DP</label>
                      <input type="number" class="form-control"  v-model="formCreate.dp"/>
                  </div>
                  <!-- <div class="mb-3">
                    <label class="form-label">Status Pembayaran</label>
                    <select class="form-select" v-model="formCreate.status_payment">
                        <option value="Sudah Lunas">Sudah Lunas</option>
                        <option value="Belum Lunas">Belum Lunas</option>
                    </select>
                  </div> -->
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Order Rent To Rent -->

    <!-- /split kendaraan-->
    <div class="modal fade custom-modal" id="split-kendaraan" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Split Kendaraan</h5>
            <button
              id="close-create"
              type="button"
              ref="closeButtonSplit"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="saveSplit">

              <div class="row mt-4">
                <h6>No Order {{ formEdit && formEdit.no_order }}/ Range Order : {{ formEdit && formEdit.starting_date}} <span class="fw-normal">s/d</span> {{ formEdit && formEdit.finish_date }}</h6>
                <div v-if="formEdit" v-for="(vehicle, indexTop) in formEdit.order_vehicle" :key="vehicle.id">
                  <h7>Detail Kendaraan ke-{{indexTop+1}}</h7>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Kendaraan</th>
                        <th>{{ isMember ? 'Staff' : 'Driver' }}</th>
                        <th>Kendaraan Pengganti</th>
                        <th>{{ isMember ? 'Staff Pengganti' : 'Driver Pengganti' }}</th>
                        <th>Tanggal Awal</th>
                        <th>Tanggal Akhir</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="vehicle" v-for="(vehicleDetail, index) in vehicle.order_vehicle_detail" :key="vehicleDetail.id">
                        <td>
                          <select class="form-select" v-model="vehicleDetail.vehicle_id" disabled>
                            <option v-for="vehicleList in vehicleData" :key="vehicleList.id" :value="vehicleList.id">{{ vehicleList.name }} - {{ vehicleList.nomor_plat }}</option>
                          </select>
                        </td>
                        <td>
                          <select class="form-select" v-model="vehicleDetail.driver_id" disabled>
                              <option v-for="driver in listUser" :key="driver.id" :value="driver.id">{{ driver.full_name }}</option>
                          </select>
                        </td>
                        <td v-if="index < vehicle.order_vehicle_detail.length - 1">
                          <select class="form-select" v-model="vehicle.order_vehicle_detail[index + 1].vehicle_id" disabled>
                            <option v-for="vehicleList in vehicleData" :key="vehicleList.id" :value="vehicleList.id">{{ vehicleList.name }} - {{ vehicleList.nomor_plat }}</option>
                          </select>
                        </td>
                        <td v-if="index < vehicle.order_vehicle_detail.length - 1">
                          <select class="form-select" v-model="vehicle.order_vehicle_detail[index + 1].driver_id" disabled>
                            <option v-for="driver in listUser" :key="driver.id" :value="driver.id">{{ driver.full_name }}</option>
                          </select>
                        </td>
                        <td v-if="index === vehicle.order_vehicle_detail.length - 1">
                          <select class="form-select" v-model="vehicleDetail.new_vehicle_id">
                            <option v-for="vehicleList in filteredVehicleData" :key="vehicleList.id" :value="vehicleList.id">{{ vehicleList.name }} - {{ vehicleList.nomor_plat }}</option>
                          </select>
                        </td>
                        <td v-if="index === vehicle.order_vehicle_detail.length - 1">
                          <select class="form-select" v-model="vehicleDetail.new_driver_id">
                            <option v-for="driver in listUser" :key="driver.id" :value="driver.id">{{ driver.full_name }}</option>
                          </select>
                        </td>
                        <td v-if="index < vehicle.order_vehicle_detail.length - 1">
                          <input type="datetime-local" class="form-control" :value="formatDateTime(vehicleDetail.tgl_awal)" disabled>
                        </td>
                        <td v-if="index < vehicle.order_vehicle_detail.length - 1">
                          <input type="datetime-local" class="form-control" :value="formatDateTime(vehicleDetail.tgl_akhir)" disabled>
                        </td>
                        <td v-if="index === vehicle.order_vehicle_detail.length - 1">
                          <input type="datetime-local" class="form-control" v-model="vehicleDetail.new_tgl_awal" :min="formatDateTime(vehicleDetail.tgl_awal)" :max="formatDateTime(vehicleDetail.tgl_akhir)">
                        </td>
                        <td v-if="index === vehicle.order_vehicle_detail.length - 1">
                          <input type="datetime-local" class="form-control" :value="formatDateTime(vehicleDetail.tgl_akhir)" disabled>
                        </td>
                        <td v-if="index === vehicle.order_vehicle_detail.length - 1 && index != 0">
                          <button type="button" @click="removeVehicleSplit(indexTop, index)" class="btn btn-danger">Remove</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /split kendaraan-->

    <!-- /extend tgl order-->
    <div class="modal fade custom-modal" id="extend-order" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Tanggal/Harga order</h5>
            <button
              id="close-extend"
              ref="closeExtendOrderRef"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetMap()"
            >
              <!-- <i class="feather feather-x"></i> -->
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="extendSubmit">
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Awal {{ isMember ? 'Penjemputan' : 'Pengambilan/Pengantaran' }}  <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="date" class="form-control"  :value="formatDate(formEdit.starting_date)" disabled/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="time" class="form-control"  v-model="formEdit.starting_time" disabled/>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-6">

                  <div class="mb-3">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal & Waktu Akhir  {{ isMember ? 'Pengantaran' : 'Pengambilan/Pengantaran' }} <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="date" class="form-control"  v-model="formEdit.finish_date" :min="formatDate(formEdit.starting_date)"/>
                          </div>
                          <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> <!-- Time separator -->
                          <div style="margin-left: 10px;">
                              <label class="form-label">Jam <span style="color:red">*</span></label>
                              <input v-if="formEdit !== undefined" type="time" class="form-control"  v-model="formEdit.finish_time"/>
                          </div>
                      </div>
                  </div>

                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Harga Sewa <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formattedEditValues.total_price" @input="onInput('formEdit', 'total_price', $event)"/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Total Harga Sewa <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formattedEditValues.harus_bayar" disabled/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">Catatan</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formEdit.remark"/>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                      <label class="form-label">Discount (%) <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.discount"/>
                  </div>
                  <div class="mb-3">
                      <label class="form-label">DP <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control"  v-model="formattedEditValues.dp"  @input="onInput('formEdit', 'dp', $event)" disabled/>
                  </div>
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /extend tgl order-->

    <!-- /update status order-->
    <div class="modal fade custom-modal" id="status-order" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ubah Status Order</h5>
            <button
              id="close-status"
              ref="closeStatusOrderRef"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetMap()"
            >
              <!-- <i class="feather feather-x"></i> -->
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="statusSubmit">
              <div class="row mt-4">
                <div class="col-md-12">
                  <label class="form-label">Status Order</label>
                  <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.status">
                    <option value="Draf" :selected="formEdit.status === 'Draf'">Draf</option>
                      <option value="Booking" :selected="formEdit.status === 'Booking'">Booking</option>
                      <option value="Proses" :selected="formEdit.status === 'Proses'">Proses</option>
                      <option value="Finish" :selected="formEdit.status === 'Finish'">Finish</option>
                      <option value="Cancel" :selected="formEdit.status === 'Cancel'">Cancel</option>
                  </select>
                </div>
              </div>
              <div class="text-end mt-4" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /update status order-->

      <div class="modal fade" id="invoice-order-pdf" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="d-flex">
            <button class="btn btn-primary" @click="downloadPdf">Download</button>
          </div>
          <div id="invoice-order-pdf-content" v-if="invoicePdfData">
                <table class="container">
                    <tbody>
                        <td class="left">
                            <h1 class="title">Invoice</h1>
                        </td>
                        <td class="right" align="right">
                            <img class="logo" :src="invoicePdfData.order.company && invoicePdfData.order.company.logo ? invoicePdfData.order.company.logo : require('@/assets/img/mari/logo_marirental3.svg')" />
                        </td>
                    </tbody>
                </table>
                <div class="hr"></div>
                <table class="container mt">
                    <tbody>
                        <td class="left">
                            <p class="title">Kepada:</p>
                            <p>{{ invoicePdfData.order.customer.name }}</p>
                        </td>
                        <td class="right" align="right">
                            <p class="title">Tanggal:</p>
                            <p>{{ formatDate(invoicePdfData.order.created_at) }}</p>
                        </td>
                    </tbody>
                </table>
                <table class="container mt">
                    <tbody>
                        <td class="left">
                        </td>
                        <td class="right" align="right">
                            <p class="title">No Invoice:</p>
                            <p>{{ invoicePdfData.order.no_order }}</p>
                        </td>
                    </tbody>
                </table>
                <table class="container mt table-border">
                    <thead align="left">
                        <tr>
                             <th class="title">Unit</th>
                             <th class="title">No. Polisi</th>
                             <th class="title">Tgl. Pemakian</th>
                             <th class="title">Ket</th>
                             <th class="title">Jumlah</th>
                        </tr>
                     </thead>
                     <tbody>
                         <tr>
                             <td>{{ invoicePdfData.order.vehicle ? invoidePdfData.order.vehicle.name : '-' }}</td>
                             <td>{{ invoicePdfData.order.vehicle ? invoicePdfData.order.nomor_plat : '-' }}</td>
                             <td>{{ formatDate(invoicePdfData.order.starting_date) }} - {{ formatDate(invoicePdfData.order.finish_date) }} </td>
                             <td>{{ invoicePdfData.order.product.name }}</td>
                             <td>{{ formatCurrency2(invoicePdfData.order.total_price) }}</td>
                         </tr>
                         <tr>
                            <td colspan="3"></td>
                            <td style="text-align: right" ><strong>DP</strong></td>
                            <td style="text-align: right"><strong>{{ formatCurrency2(invoicePdfData.order.dp) }}</strong></td>
                         </tr>
                         <tr>
                            <td colspan="3"></td>
                            <td style="text-align: right" ><strong>Sisa</strong></td>
                            <td style="text-align: right"><strong>{{ formatCurrency2(invoicePdfData.order.total_price - invoicePdfData.order.dp) }}</strong></td>
                         </tr>
                         <tr>
                            <td colspan="3"></td>
                            <td style="text-align: right" ><strong>Diskon</strong></td>
                            <td style="text-align: right"><strong>{{ invoicePdfData.order.discount }}</strong></td>
                         </tr>
                         <tr>
                            <td colspan="3"></td>
                            <td style="text-align: right" ><strong>Total</strong></td>
                            <td style="text-align: right"><strong>{{ formatCurrency2(invoicePdfData.order.total_price) }}</strong></td>
                         </tr>
                     </tbody>
                </table>
                <table class="container mt">
                    <tbody>
                        <td class="left">
                            <p class="title">Pembayaran:</p>
                            <p>Nama: {{ invoicePdfData.order.company ?  invoicePdfData.order.company.name : 'Mari Rental' }}</p>
                            <p>No Rek: {{ invoicePdfData.order.company && invoicePdfData.order.company.bank_account && invoicePdfData.order.company.no_rekening ? `${invoicePdfData.order.company.bank_account.toUpperCase()} ${invoicePdfData.order.company.no_rekening}` : 'MariBank 123123123123' }}</p>
                        </td>

                    </tbody>
                </table>
                <table class="container mt-big">
                    <tbody>
                        <td class="left">
                            <p class="title">Terimakasih atas pembelian anda</p>
                        </td>
                        <td class="right" align="right">
                        </td>
                    </tbody>
                </table>

                
          </div>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { getCurrentPosition } from "@/utils/currentLocation";
  import { uploadFile } from "@/utils/fileUpload";
  import MariLocationPickerMap from '@/views/mari/components/location-picker-map.vue'
  import MariInputDatalist from '@/views/mari/components/input-datalist.vue'
  import debounce from "debounce";
  import { locationFromQuery } from "@/utils/locationFromQuery";
  import moment from 'moment'
  import { formatCurrency as formatCurrency2 } from '@/utils/currency'

  export default {
    components: {
      MariLocationPickerMap,
      MariInputDatalist
    },
    data() {
      return {
        mapLocationState: {
          center: [-6.905977, 107.613144], // bandung
          marker: null,
          isShown: false
        },
        mapLocationQuery: {
          value: '',
          list: [],
          debounce: null,
        },

        formCreate: {
          order_vehicle: [],
          pick_up_point: ''
        },
        formEdit : {
          order_vehicle: [],
          pick_up_point: '' 
        },
        itemId: 0,
        filteredProductData: [],
        productCost: 0,
        vehicleSelect: {},
        customerSelect: {},
        productSelected: {},
        logo: '',
        currentStep: 1,
			  totalSteps: 4,
        filteredCustomer: {},
        originalCustomer: [],
        isDriver: false,
        isMember: true,
        isNewVehicle: false,
        formCustomer: {},
        formVehicle: {},
        formVendor: {},
        dataCompany: null,
        // isAvilable: false
        isCategory: false,
        isShowMapCreate: false,
        isShowMapEdit: false,
        pickUpPointCreate: '',
        pickUpPointEdit: '',
        latLngEdit: null,
        formSplit: {},

        dataListMapCreate: [],
        dataListMapCreateTimeoutId: [],
        formattedCreateValues: {},
        formattedEditValues: {},
        listUser: [],
        formEditDetail: {},
        invoicePdfData: null
      };
    },
    props: {
      startDate: Object,
      startTime: Object,

      editData: Object,
      deleteItemId: Object,
      productData: {
        type: Array,
        default: () => []
      },
      customerData: {
        type: Array,
        default: () => []
      },
      vehicleData: {
        type: Array,
        default: () => []
      },
      driverData: {
        type: Array,
        default: () => []
      },
      driverAbsen: {
        type: Array,
        default: () => []
      },
      staffData: {
        type: Array,
        default: () => []
      },
      brandData: {
        type: Array,
        default: () => []
      },
      dataId: Object,
      invoicePdfDataProp: Object
    },
    emits: ['edit-order'],
    watch: {
      'formCreate.filter_category'(v) {
        if (this.filteredCustomer instanceof Array) {
          this.formCreate.customer_id = null
          this.filteredCustomer = this.originalCustomer.filter((cust) => cust.type == v)
        }
      },
      // async 'formCreate.pick_up_point'() {
      //   if (this.dataListMapCreateTimeoutId) {
      //     clearTimeout(this.dataListMapCreateTimeoutId)
      //   }

      //   const result = await new Promise((resolve, reject) => {
      //     this.dataListMapCreateTimeoutId = setTimeout(async () => {
      //       const resp = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${this.formCreate.pick_up_point}`)
      //       const data = await resp.json()

      //       resolve(data)
      //     }, 200)
      //   })

      //   this.dataListMapCreate = result
      // },
      startDate: {
        immediate: true,
        handler(newStartDate) {
          if (newStartDate !== undefined) {
            this.formCreate.starting_date = newStartDate;
          }
        }
      },
      startTime: {
        immediate: true,
        handler(newStartTime) {
          if (newStartTime) {
            this.formCreate.starting_time = newStartTime;
          }
        }
      },

      dataId: {
        immediate: true,
        handler(newVehicleId) {
          if (newVehicleId !== undefined) {
            this.formCreate.vehicle_id = parseInt(newVehicleId);
            this.formCreate.order_vehicle = [{
              driver_id: '',
              vehicle_id: newVehicleId
            }]
          }
        }
      },
      invoicePdfDataProp: {
        immediate: true,
        handler: function(newValue) {
          if (newValue) {
            this.invoicePdfData = newValue
            console.log('newValue', this.invoicePdfData)
          }
        }
      },
      editData: {
        immediate: true,
        handler: async function(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;

            // console.log('form Edit', this.formEdit)
            // this.edit_populateLocation(this.formEdit)
            if (this.formEdit) {
              if (this.formEdit.starting_date) {
                this.formEdit.starting_date = this.formatDate(this.formEdit.starting_date);
              }
              if (this.formEdit.finish_date) {
                this.formEdit.finish_date = this.formatDate(this.formEdit.finish_date);
              }
              this.formattedEditValues.total_price = this.formatCurrency(this.formEdit.total_price);
              this.formattedEditValues.dp = this.formatCurrency(this.formEdit.dp);
              try {
                this.updateCategory();
                this.getCustomerVerif();
              } catch (error) {
                console.error('Error during async operations:', error);
              }

              if (Array.isArray(this.formEdit.order_vehicle) && this.formEdit.order_vehicle.length > 0) {
                this.formEdit.order_vehicle = this.formEdit.order_vehicle.map((ov) => {
                  if (Array.isArray(ov.order_vehicle_detail) && ov.order_vehicle_detail.length > 0) {
                    // ov.order_vehicle_detail[0].tgl_awal = moment(ov.order_vehicle_detail[0].tgl_awal).format('YYYY-MM-DDTHH:mm')
                    // ov.order_vehicle_detail[0].tgl_akhir = moment(ov.order_vehicle_detail[0].tgl_akhir).format('YYYY-MM-DDTHH:mm')
                    ov.order_vehicle_detail[0].tgl_awal = this.formatDateTime(ov.order_vehicle_detail[0].tgl_awal)
                    ov.order_vehicle_detail[0].tgl_akhir = this.formatDateTime(ov.order_vehicle_detail[0].tgl_akhir)
                    
                    return ov
                  }

                  return ov
                })
              }
              const userData = localStorage.getItem('user');
              const user = JSON.parse(userData);
              this.userLogin = user;

              // this.formEditDetail = this.formEdit.order_vehicle.flatMap((item1) => {
              //   return item1.order_vehicle_detail
              //     .map((item2) => {
              //       return item2.driver_id === this.userLogin.id ? item2 : null;
              //     })
              //     .filter(item => item !== null); // Filter out null items
              // });
            }
          }
        }
      },
      'formCreate.total_price': 'calculateTotalHargaSewa',
      'formCreate.discount': 'calculateTotalHargaSewa',
      'formEdit.total_price': 'calculateTotalHargaSewa',
      'formEdit.discount': 'calculateTotalHargaSewa',
      'formCreate.starting_date' : 'updateDate',
      'formCreate.starting_time' : 'updateDate',
      'formCreate.finish_date' : 'updateDate',
      'formCreate.finish_time' : 'updateDate',
      'formEdit.starting_date' : 'updateDate',
      'formEdit.starting_time' : 'updateDate',
      'formEdit.finish_date' : 'updateDate',
      'formEdit.finish_time' : 'updateDate'
    },
    computed: {
      // create_pick_up_point() {
      //   if (!this.mapLocationState.marker) {
      //     return null
      //   }
      //   return `${this.mapLocationQuery.value};${this.mapLocationState.marker.lat};${this.mapLocationState.marker.lng}`
      // },
      // edit_pick_up_point() {
      //   if (!this.formEdit) {
      //     return null
      //   }
      //   if (this.mapLocationState.marker && this.mapLocationState.marker.lat && this.mapLocationState.marker.lng) {
      //     return `${this.mapLocationQuery.value};${this.mapLocationState.marker.lat};${this.mapLocationState.marker.lng}`
      //   }
      //   return this.mapLocationQuery.value
      // },
      filteredVehicleData() {
        const id = this.formSplit.vehicle_id_old;
        return this.vehicleData.filter(vehicle => vehicle.id !== id);
      },
      minStartDateTime() {
        if(this.formEdit !== undefined) {
          this.formEdit.starting_time = '00:01';
          return this.formEdit.starting_date && this.formEdit.starting_time
          ? `${this.formEdit.starting_date}T${this.formEdit.starting_time}`
          : null;
        } else {
          this.formCreate.starting_time = '00:01';
          return this.formCreate.starting_date && this.formCreate.starting_time
            ? `${this.formCreate.starting_date}T${this.formCreate.starting_time}`
            : null;
        }
      },
      maxFinishDateTime() {
        if(this.formEdit !== undefined) {
          this.formEdit.finish_time = '23:59';
          return this.formEdit.finish_date && this.formEdit.finish_time
          ? `${this.formEdit.finish_date}T${this.formEdit.finish_time}`
          : null;
        } else {
          this.formCreate.finish_time = '23:59';
          return this.formCreate.finish_date && this.formCreate.finish_time
            ? `${this.formCreate.finish_date}T${this.formCreate.finish_time}`
            : null;
        }
      },
    },
    methods: {
      formatCurrency2: formatCurrency2, 
      downloadPdf() {
        const el = document.getElementById('invoice-order-pdf-content')
        html2pdf(el, {
          margin: 16
        })
      },
      edit_populateLocation(formEdit) {
        if (!formEdit) {
          return
        }
        if (!formEdit.pick_up_point) {
          return
        }
        const parts = formEdit.pick_up_point.split(';')
        if (parts.length === 1) {
          this.mapLocationQuery.value = parts[0]
          return
        }
        if (parts.length !== 3) {
          return
        }
        this.mapLocationQuery.value = parts[0]
        const lat = parseFloat(parts[1])
        const lng = parseFloat(parts[2])
        this.mapLocationState.marker = [lat, lng]
        this.mapLocationState.center = [lat, lng]
      },
      resetMap() {
        this.mapLocationState.isShown = false
        this.mapLocationQuery.value = ''
        this.mapLocationState.marker = null
      },
      onMapLocationQueryChange: function (value) {
        this.mapLocationQuery.value = value
        if (this.mapLocationQuery.debounce) {
          this.mapLocationQuery.debounce.clear()
        }
        this.mapLocationQuery.debounce = debounce(async () => {
          this.mapLocationQuery.list = await locationFromQuery(value)
        }, 200)

        this.mapLocationQuery.debounce()
      },
      onMapLocationQueryDataClick(data) {
        const lat = parseFloat(data.lat)
        const lng = parseFloat(data.lon)
        this.mapLocationState.center = [lat, lng]
        this.mapLocationState.isShown = true
      },

      onMapMoveCreate(latLng) {
        this.pickUpPointCreate = `${latLng.lat};${latLng.lng};${this.formCreate.pick_up_point}`
      },
      onMapMoveEdit(latLng) {
        this.pickUpPointEdit = `${latLng.lat};${latLng.lng};${this.formEdit.pick_up_point}`
      },
        
      closeCreateModal() {
        this.$refs.closeButtonCreateOrderModalRef.click()
        this.$refs.closeButtonSplit.click()
        this.$refs.addOrderRefBtn.click()
        this.$refs.closeExtendOrderRef.click()
        this.$refs.closeStatusOrderRef.click()
      },
      validateFile(file, allowedFileTypes, maxFileSize) {
          if (!allowedFileTypes.includes(file.type)) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
              });
              return false;
          }

          if (file.size > maxFileSize) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
              });
              return false;
          }

          return true;
      },
      // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      editSubmit() {
          this.formEdit.customer_photo = (this.customerSelect !== undefined && this.customerSelect.photo !== '') ? this.customerSelect.photo : this.formEdit.customer_photo;
          this.formEdit.remark = this.formEdit.remark !== undefined ? this.formEdit.remark : "None";
          this.formEdit.starting_time = this.formEdit.starting_time !== undefined ? this.formEdit.starting_time : "00:00";
          this.formEdit.pick_up_point = this.formEdit.pick_up_point ? this.formEdit.pick_up_point : this.edit_pick_up_point;

          let hasValidationError = false;

          this.formEdit.order_vehicle = this.formEdit.order_vehicle.map((ov, index) => {
            const detail = ov.order_vehicle_detail;
            
            if (Array.isArray(detail) && detail.length === 1) {
              const tglAwal = new Date(detail[0].tgl_awal).getTime();
              const tglAkhir = new Date(detail[0].tgl_akhir).getTime();

              // Validation: tgl_awal should not be greater than tgl_akhir
              if (tglAwal > tglAkhir) {
                Swal.fire({
                  icon: 'error',
                  text: `Tanggal awal tidak boleh lebih besar dari tanggal akhir untuk kendaraan ${index + 1}!`,
                });
                hasValidationError = true;  // Set the error flag
              }

              return {
                ...ov
              };
            }

            return {
              ...ov
            };
          });

          // Stop further execution if validation failed
          if (hasValidationError) {
            return;
          }

          
          this.formEdit.status = "Booking";
          

          const id = this.formEdit.id;

          this.$swal.fire({
          title: 'Apa Anda yakin?',
          text: 'Jika update data order maka order akan berstatus Booking dan data split akan ter-reset?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel!'
          }).then((result) => {
              if (result.isConfirmed) {
                var loading = this.$loading.show()
                this.$store
                  .dispatch("order/updateOrder", { id: id, data: this.formEdit })
                  .then((data) => {
                    if (data.status == 200) {
                      loading.hide()
                      this.formEdit = {};
                      this.vehicleSelect = {};
                      this.currentStep = 1;
                      this.$refs.closeEditOrderRef.click();
                      this.resetMap()
                      this.$swal.fire({
                      text: 'Berhasil update order',
                      icon: 'success',
                      confirmButtonText: 'Ok'
                      }).then((result) => {
                          if (result.isConfirmed) {
                          this.$emit('dataUpdated');
                          // window.location.reload();
                        }
                      });
                    } else if (data.status == 201) {
                      loading.hide()
                      Swal.fire({
                        icon: 'error',
                        text: 'Order sudah dalam status proses !!',
                      });
                    }
                  },
                  (error) => {
                    console.log(error)
                    loading.hide();
                  })
                  .catch((e) => {
                    loading.hide()
                  })
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  // User canceled, no action needed
                  this.$swal.fire({
                      icon: 'info',
                      text: 'Update cancelled',
                  });
              }
          });
      },
      createSubmit() {
        if (this.formCustomer.name) {
          this.saveNewCustomer();
        } else {
          this.saveOrder();
        }
        this.resetMap()
      },
      createSubmitRTR() {
        if (this.formVendor.full_name && this.formVehicle.name) {
          this.saveNewVendor();
        }
      },
      saveOrder () {
        if (this.formCreate.order_vehicle.length > 0) {
          const ov = this.formCreate.order_vehicle[0]
          this.formCreate.order_vehicle[0] = {
            ...ov,
            tgl_awal: moment(`${this.formCreate.starting_date} ${this.formCreate.starting_time}`).format('YYYY-MM-DDTHH:mm'),
            tgl_akhir: moment(`${this.formCreate.finish_date} ${this.formCreate.finish_time}`).format('YYYY-MM-DDTHH:mm'),
          }
          let hasValidationError = false;

          this.formCreate.order_vehicle = this.formCreate.order_vehicle.map((vehicle, index) => {
            const tglAwal = new Date(vehicle.tgl_awal).getTime();
            const tglAkhir = new Date(vehicle.tgl_akhir).getTime();

            // Validation: tgl_awal should not be greater than tgl_akhir
            if (tglAwal > tglAkhir) {
              Swal.fire({
                icon: 'error',
                text: `Tanggal awal tidak boleh lebih besar dari tanggal akhir untuk kendaraan ${index + 1}!`,
              });
              hasValidationError = true;  // Set the error flag
              return vehicle; // Return the vehicle as is, since we won't continue processing
            }

            return {
              ...vehicle
            };
          });

          // If there was a validation error, stop further execution
          if (hasValidationError) {
            return;
          }

          
          const selectedProduct = this.productData.find(product => product.id == this.formCreate.product_id);
          if (selectedProduct.rentalTypeName === 'Sewa Dengan Driver') {
            for (let orderVehicle of this.formCreate.order_vehicle) {
              if (!orderVehicle.driver_id) {
                Swal.fire({
                  icon: 'error',
                  text: 'Driver Belum dipilih !!',
                });
                return;
              }
            }
          }
          this.formCreate.status = "Booking";
        } else {
          this.formCreate.status = "Draf";
        }

        this.formCreate.customer_photo = (this.customerSelect !== undefined && this.customerSelect.photo !== '') ? this.customerSelect.photo : "-";
        this.formCreate.remark = this.formCreate.remark !== undefined ? this.formCreate.remark : "None";
        this.formCreate.status_payment = "Belum Lunas";
        // this.formCreate.pick_up_point = this.create_pick_up_point;
        this.mapLocationState.isShown = false;
        var loading = this.$loading.show()
        this.$store
          .dispatch("order/addOrder", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              // console.log('order_vehicle', this.formCreate.order_vehicle)
              this.formCreate = {};
              this.vehicleSelect = {};
              this.currentStep = 1;
              this.formCreate = {
                order_vehicle: [
                  { vehicle_id: '', driver_id: '' } // Initialize with one empty object
                ],
                pick_up_point: ''
              }
              window.location.reload();
              this.$emit('dataUpdated');
              // this.closeCreateModal()
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      saveNewCustomer() {
        this.formCustomer.address = "-";
        this.formCustomer.status = "AKTIF";
        this.formCustomer.multiple_order = 0;
        this.formCustomer.is_member = 0;
        this.formCustomer.is_payment_termin = 0;
        this.formCustomer.multiple_order = 0;
        var loading = this.$loading.show()
        this.$store
        .dispatch("customer/addCustomer", this.formCustomer )
        .then((data) => {
            if (data.status == 201) {
                this.formCreate.customer_id = data.data.id;
                this.formCreate.category = data.data.type;
                this.formCustomer = {}
                this.saveOrder();
                loading.hide()
                this.data = {};
                this.$emit('nextCustomer');
            }
        },
        (error) => {
            console.log(error)
            loading.hide();
        })
        .catch((e) => {
            loading.hide()
        })
      },
      saveNewVendor() {
        this.formVendor.type = 2;
        var loading = this.$loading.show()
        this.$store
        .dispatch("vendor/addVendor", this.formVendor )
        .then((data) => {
            if (data.status == 201) {
                this.formVehicle.vendor_id = data.data.id;
                this.formVendor = {}
                this.saveNewVehicle();
                loading.hide()
                this.data = {};
                this.$emit('nextCustomer');
            }
        },
        (error) => {
            console.log(error)
            loading.hide();
        })
        .catch((e) => {
            loading.hide()
        })
      },
      saveNewVehicle() {
        this.formVehicle.transmisi = "Manual";
        this.formVehicle.capacity = 1;
        this.formVehicle.production_year = "-";
        this.formVehicle.nomor_stnk = "-";
        this.formVehicle.bbm_type = "-";
        this.formVehicle.color = "-";
        this.formVehicle.image = "-";
        var loading = this.$loading.show()
        this.$store
        .dispatch("vehicle/addVehicle", this.formVehicle )
        .then((data) => {
            if (data.status == 201) {
                this.formCreate.vehicle_id = data.data.id;
                this.formVehicle = {}
                this.createSubmit();
                loading.hide()
                this.data = {};
                this.$emit('nextCustomer');
            }
        },
        (error) => {
            console.log(error)
            loading.hide();
        })
        .catch((e) => {
            loading.hide()
        })
      },
      saveSplit() {
        const id = this.formEdit.id;
        this.formEdit.order_vehicle = this.formEdit.order_vehicle.map((ov) => {
          if (Array.isArray(ov.order_vehicle_detail) && ov.order_vehicle_detail.length > 0) {
            const updatedDetails = ov.order_vehicle_detail.map((detail) => {
              return {
                ...detail,
                tgl_awal: this.formatDateTime(detail.tgl_awal),
                tgl_akhir: this.formatDateTime(detail.tgl_akhir),
              };
            });
            return {
              ...ov,
              order_vehicle_detail: updatedDetails,
            };
          }
          return ov;
        });
          var loading = this.$loading.show()
          this.$store
            .dispatch("order/splitVehicle", { id: id, data: this.formEdit })
            .then((data) => {
              if (data.status == 200) {
                loading.hide()
                this.formEdit = {};
                this.formSplit= {};
                this.$refs.closeSplit.click();
                this.closeCreateModal()
                this.$swal.fire({
                text: 'Berhasil update order',
                icon: 'success',
                confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                    this.$emit('dataUpdated');
                    // window.location.reload();
                  }
                });
              }
            },
            (error) => {
              console.log(error)
              loading.hide();
            })
            .catch((e) => {
              loading.hide()
            })
      },
      extendSubmit() {
        const newFinishDate = this.combineDateTime(this.formEdit.finish_date, this.formEdit.finish_time);
        this.formEdit.new_finish_date = newFinishDate;
        const id = this.formEdit.id;
          var loading = this.$loading.show()
          this.$store
            .dispatch("order/extendOrder", { id: id, data: this.formEdit })
            .then((data) => {
              if (data.status == 200) {
                loading.hide()
                this.formEdit = {};
                this.closeCreateModal()
                this.$swal.fire({
                text: 'Berhasil update order',
                icon: 'success',
                confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                    this.$emit('dataUpdated');
                    // window.location.reload();
                  }
                });
              }
            },
            (error) => {
              console.log(error)
              loading.hide();
            })
            .catch((e) => {
              loading.hide()
            })
      },
      async statusSubmit() {
        const id = this.formEdit.id;
        const loading = this.$loading.show();

        try {
          const response = await this.$store.dispatch("order/statusOrder", { id: id, data: this.formEdit });

          loading.hide();

          if (response.data.message === 'Order sudah di kunci dan tidak dapat di edit') {
            this.formEdit = {};
            this.closeCreateModal();
            await this.$swal.fire({
              text: response.data.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            });
            this.$emit('dataUpdated');
          } else if (response.data.message === 'Success' && response.data.info === 'Status order sudah di update dan sudah terkunci') {
            this.formEdit = {};
            this.closeCreateModal();
            await this.$swal.fire({
              text: response.data.info,
              icon: 'success',
              confirmButtonText: 'Ok'
            });
            this.$emit('dataUpdated');
          } else {
            this.formEdit = {};
            this.closeCreateModal();
            await this.$swal.fire({
              text: 'Berhasil update order',
              icon: 'success',
              confirmButtonText: 'Ok'
            });
            this.$emit('dataUpdated');
          }
        } catch (error) {
          console.error(error);
          this.$toast.error('Failed to update order status');
        }
      },

      deleteData() {
        // Perform deletion using this.deleteItemId
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("order/deleteOrder", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              // window.location.reload();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      cancelData() {
        // Perform deletion using this.deleteItemId
        const id = this.deleteItemId.data.id;
        const data = {status: "Cancel"};
        var loading = this.$loading.show()
        this.$store
        .dispatch("order/cancelOrder", { id: id, data: data })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-cancel').click();
              // window.location.reload();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      updateCategory() {
        if (this.formEdit !== undefined) {
            // const selectedCustomer = this.customerData.find(customer => customer.id === this.formEdit.customer_id);
            const selectedCustomer = this.formEdit.customer;
            this.customerSelect = selectedCustomer;
            this.formEdit.category = selectedCustomer.type;
            if (this.formEdit.category === "COOPORATE" || this.formEdit.category === "RENT TO RENT") {
              this.isCategory = true;
            } else {
              this.isCategory = false;
            }
          } else {
              const selectedCustomer = this.customerData.find(customer => customer.id === this.formCreate.customer_id);
              this.customerSelect = selectedCustomer;
              this.formCreate.filter_category = selectedCustomer.type;
              if (this.formCreate.filter_category === "COOPORATE" || this.formCreate.filter_category === "RENT TO RENT") {
                this.isCategory = true;
              } else {
                this.isCategory = false;
              }
          }
      },
      getCustomerVerif() {
        if(this.formEdit !== undefined) {
          const selectedProduct = this.productData.find(product => product.id == this.formEdit.product_id);
          if (selectedProduct && selectedProduct.rental_type == 1) {
            this.filteredCustomer = this.customerData.filter(item => item.is_member == 1);
            this.isMember = true;
            this.isDriver = false;
            this.listUser = this.staffData;
          } else {
            this.filteredCustomer = this.customerData;
            this.isMember = false;
            this.listUser = this.driverData;
            // this.isDriver = true;
          }
        } else {
          const selectedProduct = this.productData.find(product => product.id == this.formCreate.product_id);
          if (selectedProduct && selectedProduct.rental_type == 1) {
            this.filteredCustomer = this.customerData.filter(item => item.is_member == 1);
            this.isMember = true;
            this.isDriver = false;
            this.listUser = this.staffData;
          } else {
            this.filteredCustomer = this.customerData;
            this.isMember = false;
            this.listUser = this.driverData;
            // this.isDriver = true;
          }
        }
        this.originalCustomer = structuredClone(this.filteredCustomer)
      },
      setInitialProduct() {
        const sewaLepasKunciProduct = this.productData.find(product => product.rentalTypeName === 'Sewa Lepas Kunci');
        if (sewaLepasKunciProduct) {
          this.formCreate.product_id = sewaLepasKunciProduct.id;
          this.formCreate.pick_type = "diantar";
        } else if (this.productData.length > 0) {
          this.formCreate.product_id = this.productData[0].id;
          this.formCreate.pick_type = null;
        }
        this.getCustomerVerif();
      },
      async openModal() {
        this.setInitialProduct();
      },
      async getLoginCompany() {
        try {
          const userData = localStorage.getItem('user');
          const user = JSON.parse(userData);
          this.userLogin = user;
          const loading = this.$loading.show();
          const resp = await this.$store.dispatch("company/showCompany", { id: user.company_id });
          this.dataCompany = resp.data.data;
          loading.hide();
        } catch (e) {
          this.$toast.error(e);
          if (loading) loading.hide();
        }
      },
      async sendWhatsAppMessage(order) {
        await this.getLoginCompany();
        this.$nextTick(() => {
          const vehicleNames = order.order_vehicle.map(orderVehicle => {
            const vehicle = orderVehicle.vehicle;
            return `${vehicle.name} - ${vehicle.class} - ${vehicle.color}`;
          });
          const companyName = this.dataCompany.name;
          const { no_order, dp, total_price, customerName, jml_org, pick_up_point, interpoint, remark, payment_method } = order;
          const customerSelected = this.customerData.find(customer => customer.id === order.customer_id);
          const phone_number = customerSelected ? customerSelected.phone_number : null;
          // const email = customerSelected ? customerSelected.email : null;
          const productSelected = this.productData.find(product => product.id === order.product_id);
          const productName = productSelected ?  productSelected.rentalTypeName + ' - ' + productSelected.name : '';
          const vehicleSelected = this.vehicleData.find(vehicle => vehicle.id === order.vehicle_id);
          const vehicleName = vehicleNames.join(', ');
          const sDate = new Date(order.starting_date);
          const startDate = sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear();
          const fDate = new Date(order.finish_date);
          const finishDate = fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear();
          const countryCode = '62'; // Example country code for Indonesia
          const formattedPhoneNumber = `+${countryCode}${phone_number.slice(1)}`; // Format phone number
          const message = `${companyName}\nNo Order ${no_order}\nNama Pemesan : ${customerName}\nNomor telpon : ${formattedPhoneNumber}\nJumlah Pax : ${jml_org}\nPilihan Produk Sewa Mobil : ${productName}\nJenis Kendaraan di pesan : ${vehicleName}\nTanggal dan Waktu Penjemputan : ${startDate}\nTanggal akhir Penghantaran : ${finishDate}\nAlamat Penjemputan/Pengambilan : ${pick_up_point}\nRute Tujuan Awal dan Akhir Pemakaian Kendaraan : ${interpoint}\nHARGA DEAL : ${total_price}\nDEPOSITE PAYMENT : ${dp}\n\nMetode Pembayaran : ${payment_method}\nRequest / Catatan : ${remark}`;
          const encodedMessage = encodeURIComponent(message);
          const whatsappUrl = `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`;
          window.open(whatsappUrl, '_blank');
        });
      },
      getVehicleName(vehicle_id) {
        const vehicle = this.vehicleData.find(v => v.id === vehicle_id);
        return vehicle ? vehicle.name : 'Unknown Vehicle';
      },
      formatCurrency(value) {
        if (!value && value !== 0) return '';
        // Convert value to a string
        let stringValue = value.toString();
        // Split the string into integer and decimal parts
        let parts = stringValue.split('.');
        // Format the integer part with thousand separators
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        // Join the parts and return the formatted value
        return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
      },
      unformatCurrency(value) {
        return value.replace(/\./g, '').replace(',', '.');
      },
      onInput(form, field, event) {
        const rawValue = event.target.value;
        const unformattedValue = this.unformatCurrency(rawValue);
        const numberValue = parseFloat(unformattedValue);
        const formattedValue = this.formatCurrency(numberValue);

        if (form === 'formCreate') {
          this.formCreate[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedCreateValues[field] = formattedValue;
        } else if (form === 'formEdit') {
          this.formEdit[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedEditValues[field] = formattedValue;
        }
      },
      addVehicle() {
        const newVehicle = {
          vehicle_id: '',
          driver_id: '',
          tgl_awal: '',
          tgl_akhir: ''
        };
        if (this.formCreate.order_vehicle.length === 0) {
          newVehicle.tgl_awal = this.minStartDateTime;
          newVehicle.tgl_akhir = this.maxFinishDateTime;
        }
        this.formCreate.order_vehicle.push(newVehicle);
      },
      removeVehicle(index) {
        this.formCreate.order_vehicle.splice(index, 1);
      },
      addVehicleToEdit() {
        this.formEdit.order_vehicle.push({
          vehicle_id: '',
          driver_id: '',
          order_vehicle_detail: [
            { tgl_awal: '', tgl_akhir: '', nama_tamu: '', phone_tamu: '' } // Initialize with empty dates
          ]
        });
      },
      removeVehicleFromEdit(index) {
        this.formEdit.order_vehicle.splice(index, 1);
      },
      removeVehicleSplit(vehicleIndex, detailIndex) {
        if (this.formEdit && this.formEdit.order_vehicle && this.formEdit.order_vehicle.length > vehicleIndex) {
          const vehicle = this.formEdit.order_vehicle[vehicleIndex];
          if (vehicle.order_vehicle_detail && vehicle.order_vehicle_detail.length > detailIndex) {
            vehicle.order_vehicle_detail.splice(detailIndex, 1);
          }
        }
      },
      calculateTotalHargaSewa() {
        if (this.formEdit) {
          const total_price = parseFloat(this.formEdit.total_price) || 0;
          const discountPercentage = parseFloat(this.formEdit.discount) || 0;
          const discountAmount = (total_price * discountPercentage) / 100;
          const harus_bayar = total_price - discountAmount;
          this.formEdit.harus_bayar = harus_bayar;
          this.formattedEditValues.harus_bayar = this.formatCurrency(harus_bayar);
        } else {
          const total_price = parseFloat(this.formCreate.total_price) || 0;
          const discountPercentage = parseFloat(this.formCreate.discount) || 0;
          const discountAmount = (total_price * discountPercentage) / 100;
          const harus_bayar = total_price - discountAmount;
          this.formCreate.harus_bayar = harus_bayar;
          this.formattedCreateValues.harus_bayar = this.formatCurrency(harus_bayar);
        }
      },
      formatDateTime(dateTime) {
        if (!dateTime) return '';
        return moment(dateTime).utcOffset(0).format('YYYY-MM-DDTHH:mm');
      },
      getMinDate() {
        // Get the current date and time
        const now = new Date();
        // Adjust to the local time zone
        const localNow = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
        // Format the date to be compatible with datetime-local input
        return localNow.toISOString().slice(0, 16);
      },
      formatDate(dateTime) {
        return moment(dateTime).format('YYYY-MM-DD');
      },
      combineDateTime(date, time) {
        return `${date} ${time}:00`;
      },
      updateDate() {
        if (this.formCreate.order_vehicle && this.formCreate.order_vehicle.length > 0) {
          this.formCreate.order_vehicle[0].tgl_awal = this.minStartDateTime;
          this.formCreate.order_vehicle[0].tgl_akhir = this.maxFinishDateTime;
        } else if (this.formEdit && this.formEdit.order_vehicle && this.formEdit.order_vehicle.length > 0) {
          this.formEdit.order_vehicle.forEach((item) => {
            item.tgl_awal = this.minStartDateTime;
            item.tgl_akhir = this.maxFinishDateTime;
            if (item.order_vehicle_detail && item.order_vehicle_detail.length > 0) {
              item.order_vehicle_detail[0].tgl_awal = this.minStartDateTime;
              item.order_vehicle_detail[0].tgl_akhir = this.maxFinishDateTime;
            }
          });
        }
      },
    },
    async mounted() {
      // const position = await getCurrentPosition() // handle error
      // this.mapLocationState.center = [position.coords.latitude, position.coords.longitude]
    }
  };
  </script>
  
<style>
/* Set up flexbox layout */
.d-flex {
  display: flex;
}

/* Make child divs grow and take up equal width within the flex container */
.d-flex > div {
  flex-grow: 1;
  margin-bottom: 10px; /* Add margin bottom for spacing between rows */
}

/* Media query for small screens */
@media (max-width: 576px) {
  .d-flex {
    flex-wrap: wrap; /* Allow flex items to wrap onto new lines */
  }

  .d-flex > div {
    flex-basis: 100%; /* Make each div take full width on small screens */
    margin-right: 0; /* Remove margin-right for full width */
  }
}

.service-book-img img {
    width: 100% !important;
    border-radius: 10px;
}
</style>
