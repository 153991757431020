<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content w-100 ps-0 pt-0">
        <sidelinkheading />

        <div class="row">
          <div class="content-table">
            <div class="content-page-header">
              <h5>Storage</h5>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-lg-6">
                  <div class="nav-menus mb-3">
                    <h4>Local Storage</h4>
                    <div class="active-switch">
                      <label class="switch">
                        <input type="checkbox" checked="" />
                        <span class="sliders round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="nav-menus mb-3">
                    <h4>AWS Storage</h4>
                    <div class="settings-view">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#aws-config"
                        ><i class="feather feather-settings"></i
                      ></a>
                      <div class="active-switch">
                        <label class="switch">
                          <input type="checkbox" checked="" />
                          <span class="sliders round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <storage-model></storage-model>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
