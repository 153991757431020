<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>Keuangan</h4>
                  <p>Kamu bisa lihat data keuangan disni!</p>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/eye.svg" alt="img"
                            /></span>
                            <h6>Total Keuangan Bulan Ini</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ total1 }}">{{ formatCurrency(total1) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user home-provider">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/monitor.svg" alt="img"
                            /></span>
                            <h6>Jumlah Data Keuangan Bulan Ini</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ total2 }}">{{ total2 }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user home-service">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/global.svg" alt="img"
                            /></span>
                            <h6>Total Keuangan Keseluruhan</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ total3 }}">{{ formatCurrency(total3) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-page-header content-page-headersplit mb-0">
                <div class="list-btn">
                  <ul>
                    <li>
                      <div class="input-group">
                        <input type="date" v-model="params.dateStart" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text">s/d</span>
                        <input type="date" v-model="params.dateEnd" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text"></span>
                        <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" @keyup.enter="search">
                        <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="table-resposnive table-div">
                    <div class="table datatable">
                      <a-table :columns="columns" :data-source="data" :pagination="pagination" style="overflow-x: auto;">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'Status'">
                            <h6 v-if="record.status === 'AKTIF'" class="badge-active">{{ record.status }}</h6>
                            <h6 v-else class="badge-active">{{ record.status }}</h6>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "No Order",
      dataIndex: "no_order",
      key: "no_order",
      sorter: {
        compare: (a, b) => {
          a = a.no_order.toLowerCase();
          b = b.no_order.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "customerName",
      sorter: {
        compare: (a, b) => {
          a = a.customerName.toLowerCase();
          b = b.customerName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      sorter: {
        compare: (a, b) => {
          a = a.productName.toLowerCase();
          b = b.productName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Starting Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        compare: (a, b) => {
          a = a.startDate.toLowerCase();
          b = b.startDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Starting Time",
      dataIndex: "startTime",
      key: "startTime",
      sorter: {
        compare: (a, b) => {
          a = a.startTime.toLowerCase();
          b = b.startTime.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Finish Date",
      dataIndex: "finishDate",
      key: "finishDate",
      sorter: {
        compare: (a, b) => {
          a = a.finishDate.toLowerCase();
          b = b.finishDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vehicleName",
      key: "vehicleName",
      sorter: {
        compare: (a, b) => {
          a = a.vehicleName.toLowerCase();
          b = b.vehicleName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Total Sewa",
      dataIndex: "totalPrice",
      key: "totalPrice",
      sorter: {
        compare: (a, b) => {
          a = a.totalPrice.toLowerCase();
          b = b.totalPrice.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: totalPrice => `${totalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00`,
    },
    {
      title: "Total DP",
      dataIndex: "totalDp",
      key: "totalDp",
      sorter: {
        compare: (a, b) => {
          a = a.totalDp.toLowerCase();
          b = b.totalDp.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ];
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            dateStart: '',
            dateEnd: '',
            jadwal: false
        },
        paramUser: {
            row: 10,
            keyword: "8",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataProduct: [],
        dataVehicle: [],
        dataCustomer: [],
        dataUser: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        total1: 0,
        total2: 0,
        total3: 0
      };
    },
    name: "financesetting",
    computed: {

    },
    methods: {
        search() {
          this.params.page = 1;
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("order/getOrder", this.params)
                .then((resp) => {
                  const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
                    this.data = resp.data.data.map((item, index) => {
                        const sDate = new Date(item.starting_date);
                        const fDate = new Date(item.finish_date);
                        const time = new Date(item.starting_time);
                        const startTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        const vehicle = item.vehicle; //this.dataVehicle.find(vehicle => vehicle.id === item.vehicle_id);
                        const vehicleName = vehicle ? vehicle.name : '';
                        const product = item.product; //this.dataProduct.find(product => product.id === item.product_id);
                        let rentalTypeName;
                        if (product.rental_type == 1) {
                            rentalTypeName = "Sewa Lepas Kunci";
                        } else {
                            rentalTypeName = "Sewa Dengan Driver";
                        }
                        const productName = product ? product.name : '';
                        const customer = item.customer; //this.dataCustomer.find(customer => customer.id === item.customer_id);
                        const customerName = customer ? customer.name : '';
                        const totalPrice = this.formatCurrency(item.total_price);
                        const totalDp = this.formatCurrency(item.dp);
                        return {
                            ...item,
                            index: startIndex + index + 1,
                            startDate: sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear(),
                            finishDate: fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear(),
                            startTime,
                            vehicleName,
                            productName,
                            customerName,
                            totalPrice,
                            totalDp
                        };
                    });
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    this.total1 = resp.total1 !== null ? resp.total1 : 0;
                    this.total2 = resp.total2 !== null ? resp.total2 : 0;
                    this.total3 = resp.total3 !== null ? resp.total3 : 0;
                    this.params.dateStart = this.formatDate(resp.dateStart);
                    this.params.dateEnd = this.formatDate(resp.dateEnd);
                    console.log(resp);
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params)
                .then((resp) => {
                    this.dataProduct = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVehicle() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params)
                .then((resp) => {
                    this.dataVehicle = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getCustomer() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("customer/getCustomer", this.params)
                .then((resp) => {
                    this.dataCustomer = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getUser() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("user/getUser", this.paramUser)
                .then((resp) => {
                    this.dataUser = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        formatDate(item) {
            // Assuming dateString is in a different format, you need to format it to YYYY-MM-DD
            const date = new Date(item);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        formatCurrency(value) {
          if (!value && value !== 0) return '';
          // Convert value to a string
          let stringValue = value.toString();
          // Split the string into integer and decimal parts
          let parts = stringValue.split('.');
          // Format the integer part with thousand separators
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          // Join the parts and return the formatted value
          return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
        },
    },
    mounted() {
        this.getProduct();
        this.getVehicle();
        this.getCustomer();
        this.getUser();
        this.getData();
    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>