<template>
	<div class="main-wrapper">
		<mari-layout></mari-layout>
		<div class="page-wrapper background" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
			<div class="content">		
				<div class="container">			
					<div class="section-heading">
						<div class="row">
							<div class="col-md-6 aos" data-aos="fade-up">
								<h4>Pengaturan</h4>
                <p>Kamu bisa mengatur kebutuhan perusahaan disni!</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-lg-3" v-for="(item, index) in filteredMenuItems" :key="index">
							<router-link :to="item.route" class="feature-box aos" data-aos="fade-up">
								<div class="feature-icon">
								<span>
									<!-- Menggunakan interpolasi string untuk membuat nama file gambar dinamis -->
									<img :src="require(`@/assets/img/mari/menu/${item.icon}.svg`)" alt="img">
								</span>
								</div>
								<h5>{{ item.title }}</h5>
								<div class="feature-overlay">
								<img src="@/assets/img/mari/menu/slide.svg" alt="img">
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
    <mari-footer></mari-footer>
	</div>
</template>
  
  <script>
  export default {
    data() {
      return {
      menuItems: [
          { title: 'Perusahaan', icon: 'Perusahaan', route: '/mari/company' },
          { title: 'Jenis Sewa', icon: 'Jenis_Sewa', route: '/mari/paket' },
          { title: 'User', icon: 'User', route: '/mari/user-setting' },
          { title: 'Vendor', icon: 'Vendor', route: '/mari/vendor' },
		  { title: 'Order', icon: 'order', route: '/mari/order' },
		  { title: 'Pelanggan', icon: 'pelanggan', route: '/mari/pelanggan' },
		  { title: 'Riwayat Order Driver', icon: 'finance', route: '/mari/driver-grafik' },
          { title: 'Brand', icon: 'Brand', route: '/mari/brand' },
          { title: 'Role', icon: 'Menu', route: '/mari/role' },
          { title: 'Menu', icon: 'Menu', route: '/mari/menu' },
          { title: 'Acces Menu', icon: 'Akses_Menu', route: '/mari/acces' }
        ],
      company_id: null,
      backgroundImage: require(`@/assets/img/mari/background.svg`)
      };
    },
	computed: {
		filteredMenuItems() {
		// If user company ID is 0, allow access to brand, role, menu, and access menu
		if (this.company_id === 0) {
			return this.menuItems;
		} else {
			// Filter out menu items that user should not have access to
			return this.menuItems.filter(
			(item) =>
				item.route !== '/mari/brand' &&
				item.route !== '/mari/role' &&
				item.route !== '/mari/menu' &&
				item.route !== '/mari/acces'&&
				item.route !== '/mari/paket'
			);
		}
		},
	},
    mounted() {
        const userData = localStorage.getItem('user');
		const user = JSON.parse(userData);
		this.company_id = user.company_id;
		console.log(this.company_id);
    },
    methods: {
     
    },
  };
  </script>

<style scoped>
	.feature-icon span {
	  background: #f7f7ff00;
	}

  .background {
    /* Full height */
    height: 100%; 

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
