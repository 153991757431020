<template>
  <div class="row align-items-center">
    <div class="col-md-4">
      <div class="widget-title">
        <h4>Booking List</h4>
      </div>
    </div>

    <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
      <div class="review-sort d-flex align-items-center me-2">
        <p>Sort</p>
        <vue-select :options="SortBooking" id="sort-booking" placeholder="Recent" />
      </div>
      <div class="grid-listview">
        <ul>
          <li>
            <router-link to="customer-booking-calendar">
              <i class="feather-calendar"></i>
            </router-link>
          </li>
          <li>
            <a href="javascript:void(0);">
              <i class="feather-filter"></i>
            </a>
          </li>
          <li>
            <a href="javascript:;">
              <i class="feather-grid"></i>
            </a>
          </li>
          <li>
            <router-link to="customer-booking" class="active">
              <i class="feather-list"></i>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      SortBooking: ["Recent", "Older", "A to Z", "Z to A"],
    };
  },
};
</script>
