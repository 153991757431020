<template>
  <div class="main-wrapper">
    <div class="login-pages">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="login-images">
              <img src="@/assets/admin_img/login-banner.png" alt="img" />
            </div>
          </div>
          <div class="col-lg-6">
            <Form  @submit="handleRegister" :validation-schema="schema" class="login-content">
              <div class="login-contenthead">
                <h5>Selamat Datang Kembali</h5>
                <h6>Silahkan Login dengan akun Anda</h6>
              </div>
              <div class="login-input">
                <div class="form-group">
                  <label>E-mail</label>
                  <Field name="email" type="text" placeholder="Email *"  class="form-control pass-input" />
                  <ErrorMessage name="email" class="error-feedback" />
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <label>Password</label>
                    <router-link class="forgetpassword-link" to="/admin/forgot-password"
                      >Forgot password?</router-link
                    >
                  </div>
                  <div class="pass-group">
                    <Field v-if="showPassword" name="password" type="text" placeholder="Password *" class="form-control pass-input" />
                    <Field  v-else name="password" type="password" placeholder="Password *" class="form-control pass-input" />
                    <ErrorMessage name="password" class="error-feedback" />
                    <span
                      class="toggle-password"
                      @click="toggleShow"
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></span>
                  </div>
                </div>
                <div class="filter-checkbox m-0">
                  <ul class="d-flex justify-content-between">
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Remember Me</b>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="login-button mt-4">
                <button class="btn btn-login" :disabled="loading"><span v-show="loading" class="spinner-border spinner-border-sm"></span>  Login</button>
              </div>
              <div class="signinform text-center">
                <h4>
                  Belum punya akun ?
                  <router-link to="/admin/signup" class="hover-a">Daftar sekarang</router-link>
                </h4>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from "vee-validate";

    export default {
        name: 'Login',
        components: {
          Form,
          Field,
          ErrorMessage,
      },
      data() {
        const schema = yup.object().shape({
          email: yup
            .string()
            .required("Email harus diisi!")
            .email("Harus berupa format email!")
            .max(50, "Tidak boleh lebih dari 50 karakter!"),
          password: yup
            .string()
            .required("Password harus diisi!")
            .min(6, "Harus lebih dari 6 karakter!"),
        });

        return {
          successful: false,
          loading: false,
          message: "",
          schema,
        };
      },
      computed: {
        loggedIn() {
          return this.$store.state.auth.status.loggedIn;
        },
        buttonLabel() {
          return this.showPassword ? "Hide" : "Show";
        },
      },
      mounted() {
        // if (this.loggedIn) {
        //   this.$router.push("/profile");
        // }
      },
      methods: {
        handleRegister(user) {
          let loading = this.$loading.show();
          this.$store.dispatch("auth/login", user).then(
            (data) => {
              loading.hide();
                if (data.status == 200) {
                    this.$swal.fire({icon: 'success', html: data.data.message});
                    this.$router.replace({ path: "dashboard" });
                }
            },
            (error) => {
              console.log(error)
              loading.hide();
            }
          );
        },
      },
      toggleShow() {
        this.showPassword = !this.showPassword;
      },
    }
</script>
