<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Payout Requests</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-payout"
                >
                  <i class="fa fa-plus me-2"></i>Add Payout
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <a href="javascript:void(0);" class="table-profileimage">
                        <img
                          :src="require(`@/assets/admin_img/customer/${record.Image}`)"
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Name }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <h6 class="badge-pending">{{ record.Status }}</h6>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-select table-selectpayouts">
                        <div class="form-group mb-0">
                          <vue-select :options="Status" placeholder="Select Status" />
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <payout-request-model></payout-request-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payout Method",
    dataIndex: "Payout_Method",
    sorter: {
      compare: (a, b) => {
        a = a.Payout_Method.toLowerCase();
        b = b.Payout_Method.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created At",
    dataIndex: "Created_At",
    sorter: {
      compare: (a, b) => {
        a = a.Created_At.toLowerCase();
        b = b.Created_At.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
  },
];

const data = [
  {
    id: "1",
    Image: "user-01.jpg",
    Name: "John Smith",
    Payout_Method: "stripe",
    Amount: "$80",
    Status: "Pending",
    Created_At: "07 Oct 2022 11:22:51",
    Action: "",
  },
  {
    id: "2",
    Image: "user-04.jpg",
    Name: "Johnny",
    Payout_Method: "paypal",
    Amount: "$50",
    Status: "Pending",
    Created_At: "15 Oct 2022 11:22:51",
    Action: "",
  },
  {
    id: "3",
    Image: "user-06.jpg",
    Name: "Robert",
    Payout_Method: "stripe",
    Amount: "$50",
    Status: "Pending",
    Created_At: "21 Oct 2022 11:22:51",
    Action: "",
  },
  {
    id: "4",
    Image: "user-09.jpg",
    Name: "Sharonda",
    Payout_Method: "paypal",
    Amount: "$50",
    Status: "Pending",
    Created_At: "07 Oct 2022 11:22:51",
    Action: "",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      Status: ["Select Status", "Pending", "Inprogress", "Completed", "Cancelled"],
      columns,
      data,
    };
  },
};
</script>
