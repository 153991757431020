<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Add Users</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="profile-upload">
                    <div class="profile-upload-img">
                      <img
                        src="@/assets/admin_img/customer/user-01.jpg"
                        alt="img"
                        id="blah"
                      />
                    </div>
                    <div class="profile-upload-content">
                      <div class="profile-upload-btn">
                        <div class="profile-upload-file">
                          <input type="file" id="imgInp" />
                          <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                        </div>
                        <a href="javascript:void(0);" class="btn btn-remove">Remove</a>
                      </div>
                      <div class="profile-upload-para">
                        <p>
                          *image size should be at least 320px big,and less then 500kb.
                          Allowed files .png and .jpg.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Name "
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>User Name</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter User Name"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Email</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Phone</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter Phone Number"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Current Password</label>
                    <div class="pass-group">
                      <div class="pass-group">
                        <b-form-input
                          v-if="showPassword"
                          type="text"
                          class="form-control pass-input"
                          v-model="password"
                          placeholder="password"
                        />
                        <b-form-input
                          v-else
                          type="password"
                          class="form-control pass-input"
                          placeholder="password"
                          v-model="password"
                        />
                        <span
                          class="toggle-password"
                          @click="toggleShow"
                          :class="{
                            'feather-eye': showPassword,
                            'feather-eye-off': !showPassword,
                          }"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>New Password</label>
                      <div class="pass-group">
                        <b-form-input
                          v-if="showPassword2"
                          type="text"
                          class="form-control pass-input"
                          v-model="password2"
                          placeholder=".............."
                        />
                        <b-form-input
                          v-else
                          type="password"
                          class="form-control pass-input"
                          placeholder=".............."
                          v-model="password2"
                        />
                        <span
                          class="toggle-password"
                          @click="toggleShow1"
                          :class="{
                            'feather-eye': showPassword2,
                            'feather-eye-off': !showPassword2,
                          }"
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Role</label>
                      <vue-select :options="Admin" placeholder="Admin" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-groupheads d-flex d-flex justify-content-between">
                      <h2>Status</h2>
                      <div class="active-switch">
                        <label class="switch">
                          <input type="checkbox" checked="" />
                          <span class="sliders round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="btn-path">
                      <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                      <b-button type="submit" variant="primary"> Save Changes</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Admin: ["Admin", " Super Admin"],
      showPassword: false,
      password: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    buttonLabel1() {
      return this.showPassword2 ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword2 = !this.showPassword2;
    },
    submitForm() {
      this.$router.push("add-user");
    },
  },
};
</script>
