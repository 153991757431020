import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        createOperationalOrder(_, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/x/create-operational-order`, data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        getPaymentDriver(_, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/x/get-payment-driver-from-expenditure/${id}`).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getExpenditure(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/expenditure', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addExpenditure(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/expenditure/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateExpenditure(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('expenditure/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteExpenditure(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('expenditure/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }



    },
    getters: {

    }

}