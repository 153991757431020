<template>
    <div class="card add-service">
        <div class="sub-title">
            <h6>SEO</h6>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label class="col-form-label">Meta Title</label>
                    <input type="text" class="form-control">
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label class="col-form-label">Meta Keywords</label>
                    <input type="text" class="form-control">
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group mb-0">
                    <label class="col-form-label">Meta Description</label>
                    <textarea class="form-control"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

