<template>
    <!-- Show Driver Report -->
    <div class="modal fade" id="show-driver-report" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Report</h5>
            <button
              id="close-show"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form>
                <div class="row mt-4">
                    <div class="col-12 text-center">
                        <h5 class="display-4 font-weight-bold mb-3">REPORT DRIVER</h5>
                        <p class="lead mb-4">
                            Perusahaan tidak akan menanggung segala kerusakan yang timbul pada kendaraan yang dipakai oleh mudi seperti (kempes ban atau bocor ban, kebersihan, baret, atau kehilangan kelengkapan kendaraan) dan akan menjadi tanggung jawab mudi itu sendiri.
                        </p>
                    </div>
                    <div class="col-12 mt-2 mb-3">
                        <div class="mb-3 p-3 border rounded shadow-sm bg-light">
                            <div class="mb-2">
                                <strong>No POL : </strong>
                                <span class="text-white p-2" style="background-color: #59C2FB; border-radius: 5px; font-weight: bold;">
                                    {{ selectedVehicle?.nomor_plat || 'N/A' }}
                                </span>
                            </div>
                            <div class="mb-2" v-if="userData?.role.name !== 'Driver'">
                                <strong>Order Vendor : </strong>
                                <span class="text-muted"> {{ selectedVehicle?.vendor?.full_name || userData?.company?.name }}</span>
                            </div>
                            <div class="mb-2">
                                <strong>Nama Driver : </strong>
                                <span class="text-muted"> {{ formEdit?.nama_driver || 'N/A' }}</span>
                            </div>
                            <div class="mb-2">
                                <strong>Nama Tamu : </strong>
                                <span class="text-muted"> {{ formEdit?.nama_tamu || 'N/A' }}</span>
                            </div>
                            <div class="mb-2">
                                <strong>Hari / Tanggal / Jam Keluar : </strong>
                                <span class="text-muted">{{ formattedDateTime || 'N/A' }}</span>
                            </div>
                            <div class="mb-2">
                                <strong>Rute Perjalanan : </strong>
                                <span class="text-muted"> {{ formEdit?.rute_perjalanan || 'N/A' }}</span>
                            </div>
                            <div class="mb-2">
                                <strong>Jenis Layanan : </strong>
                                <span class="text-muted"> {{ selectedOrder?.product?.name || 'N/A' }}</span>
                            </div>
                            <div class="mb-2" v-if="userData?.role.name !== 'Driver'">
                                <strong>Status Mudi : </strong>
                                <span class="text-muted"> {{ formEdit?.order_vehicle_detail?.driver?.status_mudi || 'N/A' }}</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row mt-4">
                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <label class="form-label">ASAL NEGARA</label>
                            <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.asal_negara" disabled>
                                <option value="Indonesia (Domestic)">Indonesia (Domestic)</option>
                                <option value="Mancanegara (non english speaking)">Mancanegara (non english speaking)</option>
                                <option value="Mancanegara (english speaking)">Mancanegara (english speaking)</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">METODE PEMBERIAN OP</label>
                            <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.metode_pemberian_op" disabled>
                                <option value="Petty Cash">Petty Cash</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Biaya Operasional Dari Kantor</label>
                            <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.nominal_op" disabled />
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian BBM</label>
                            <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.nominal_bbm" disabled />
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian TOL</label>
                            <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.nominal_tol" disabled />
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian PARKIR</label>
                            <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.nominal_parkir" disabled />
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian MAKAN</label>
                            <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.nominal_makan" disabled />
                            <p>50.000/day</p>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian INAP</label>
                            <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.nominal_inap" disabled />
                            <p>100.000/malam</p>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Total Biaya Pemakaian Operasional Kantor</label>
                            <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.nominal_pemakaian_op" disabled />
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Sisa Biaya Operasional Kantor</label>
                            <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.nominal_sisa_op" disabled />
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Biaya Cuci Kendaraan Mandiri</label>
                            <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.nominal_pendapatan_gaji" disabled />
                            <p>
                            Biaya Cuci 1x akan Otomatis di potong ke gaji dengan besaran sbb :
                            Trip 1 hari (mobil kecil 20rb mobil besar 30rb)
                            Trip lebih 1 hari (mobil kecil 25rb mobil besar 35rb)
                            </p>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <label class="form-label">FOTO BON BBM</label>
                            <div>
                                <img v-if="formEdit && formEdit.foto_bbm" 
                                @click="openImageView(formEdit.foto_bbm)" 
                                data-bs-toggle="modal"
                                data-bs-target="#image-view"
                                :src="formEdit.foto_bbm" alt="Preview Bon BBM" class="img-thumbnail mb-3" width="100" height="100" />
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">FOTO KONDISI AWAL BBM DAN ODO KM</label>
                            <div>
                                <img v-if="formEdit && formEdit.foto_kondisi_awal" 
                                @click="openImageView(formEdit.foto_kondisi_awal)" 
                                data-bs-toggle="modal"
                                data-bs-target="#image-view"
                                :src="formEdit.foto_kondisi_awal" alt="Preview Kondisi Awal BBM & KM" class="img-thumbnail mb-3" width="100" height="100" />
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">FOTO KONDISI AKHIR BBM DAN ODO KM</label>
                            <div>
                                <img v-if="formEdit && formEdit.foto_kondisi_akhir" 
                                @click="openImageView(formEdit.foto_kondisi_akhir)" 
                                data-bs-toggle="modal"
                                data-bs-target="#image-view"
                                :src="formEdit.foto_kondisi_akhir" alt="Preview Kondisi Akhir BBM & KM" class="img-thumbnail mb-3" width="100" height="100" />
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">FOTO BON TOL & PARKIR</label>
                            <div>
                                <img v-if="formEdit && formEdit.foto_bon_tol_parkir" 
                                @click="openImageView(formEdit.foto_bon_tol_parkir)" 
                                data-bs-toggle="modal"
                                data-bs-target="#image-view"
                                :src="formEdit.foto_bon_tol_parkir" alt="Preview Bon Tol & Parkir" class="img-thumbnail mb-3" width="100" height="100" />
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">FOTO TIME SHEET atau CAR LOG</label>
                            <div>
                                <img v-if="formEdit && formEdit.foto_car_log" 
                                @click="openImageView(formEdit.foto_car_log)" 
                                data-bs-toggle="modal"
                                data-bs-target="#image-view"
                                :src="formEdit.foto_car_log" alt="Preview Time Sheet or Car Log" class="img-thumbnail mb-3" width="100" height="100" />
                            </div>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Catatan</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.remark" disabled/>
                        </div>
                    </div>
                </div>

                <div class="text-end mt-3">
                    <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">
                    Cancel
                    </button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Show Driver Report -->

    <!-- Add Driver Report -->
    <div class="modal fade" id="add-driver-report" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Tambah Report</h5>
                <button
                id="close-create"
                type="button"
                class="btn-close close-modal"
                ref="closeButtonCreateOrderModalRef"
                data-bs-dismiss="modal"
                aria-label="Close"
                >
                <i class="feather feather-x"></i>
                </button>
            </div>
            <div class="modal-body pt-0">
                <form @submit.prevent="updateSubmit">
                <div class="row mt-4">
                    <div class="col-12">
                    <h5 style="text-align: center;">REPORT DRIVER</h5>
                    <p style="text-align: center;">Perusahaan tidak akan menanggung Segala kerusakan yg timbul pada kendaraan yg di pakai oleh mudi seperti (kempes ban atau bocor ban, kebersihan, baret atau kehilangan kelengkapan kendaraan dan akan menjadi tanggung jawab mudi itu sendiri)</p>
                    </div>
                    <div class="col-12 mt-2 mb-3">
                        <div class="mb-1">
                            <strong>No POL:</strong>
                            <span class="text-muted p-2" style="display: block; background-color: #59C2FB; text-align: center; border-radius: 10px; font-weight: bold;">
                                {{ selectedVehicle?.nomor_plat || 'N/A' }}
                            </span>
                        </div>
                        <div class="mb-1">
                            <strong>Nama Driver : </strong>
                            <span class="text-muted"> {{ formCreate.nama_driver || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Nama Tamu : </strong>
                            <span class="text-muted"> {{ formCreate.nama_tamu || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Hari / Tanggal / Jam Keluar : </strong> 
                            <span class="text-muted">{{ formattedDateTime || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Rute Perjalanan : </strong>
                            <span class="text-muted"> {{ formCreate.rute_perjalanan || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Jenis Layanan : </strong>
                            <span class="text-muted"> {{ selectedOrder && selectedOrder.product ? selectedOrder.product.name : 'N/A' }}</span>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <label class="form-label">ASAL NEGARA</label>
                            <select class="form-select" v-model="formCreate.asal_negara">
                                <option value="Indonesia (Domestic)">Indonesia (Domestic)</option>
                                <option value="Mancanegara (non english speaking)">Mancanegara (non english speaking)</option>
                                <option value="Mancanegara (english speaking)">Mancanegara (english speaking)</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">METODE PEMBERIAN OP</label>
                            <select class="form-select" v-model="formCreate.metode_pemberian_op">
                                <option value="Petty Cash">Petty Cash</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Biaya Operasional Dari Kantor</label>
                            <input type="number" class="form-control" v-model="formCreate.nominal_op"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian BBM</label>
                            <input type="number" class="form-control" v-model="formCreate.nominal_bbm"/>    
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian TOL</label>
                            <input type="number" class="form-control" v-model="formCreate.nominal_tol"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian PARKIR</label>
                            <input type="number" class="form-control" v-model="formCreate.nominal_parkir"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian MAKAN</label>
                            <input type="number" class="form-control" v-model="formCreate.nominal_makan"/>
                            <p>50.000/day</p>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Biaya Pemakaian INAP</label>
                            <input type="number" class="form-control" v-model="formCreate.nominal_inap"/>
                            <p>100.000/malam</p>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Total Biaya Pemakaian Operasional Kantor</label>
                            <input type="number" class="form-control" v-model="formCreate.nominal_pemakaian_op" disabled/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Sisa Biaya Operasional Kantor</label>
                            <input type="number" class="form-control" v-model="formCreate.nominal_sisa_op" disabled/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Biaya Cuci Kendaraan Mandiri</label>
                            <input type="number" class="form-control" v-model="formCreate.nominal_pendapatan_gaji"/>
                            <p>
                            Biaya Cuci 1x akan Otomatis di potong ke gaji dengan besaran sbb :
                            Trip 1 hari (mobil kecil 20rb mobil besar 30rb)
                            Trip lebih 1 hari (mobil kecil 25rb mobil besar 35rb)
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="mb-3">
                            <label class="form-label">FOTO BON BBM</label>
                            <div class="profile-upload-content">
                            <img v-if="formCreate.foto_bbm" :src="formCreate.foto_bbm" alt="Preview Bon BBM" class="img-thumbnail mb-3" width="100" height="100" />
                            <div class="profile-upload-btn">
                                <div class="profile-upload-file">
                                <input type="file" @change="event => selectFileAttachment(event, 'foto_bbm')" />
                                </div>
                            </div>
                            </div>
                            <p>wajib tertera tgl, nopol dan nominal
                            DILARANG KERAS UNTUK MEMALSUKAN BON BBM BAGI YANG KETAHUAN AKAN DI JERAT PASAL 263 KUHP ayat (1&2) MAKSIMAL PENJARA 6 TAHUN</p>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">FOTO KONDISI AWAL BBM DAN ODO KM</label>
                            <div class="profile-upload-content">
                            <img v-if="formCreate.foto_kondisi_awal" :src="formCreate.foto_kondisi_awal" alt="Preview Kondisi Awal BBM & KM" class="img-thumbnail mb-3" width="100" height="100" />
                            <div class="profile-upload-btn">
                                <div class="profile-upload-file">
                                <input type="file" @change="event => selectFileAttachment(event, 'foto_kondisi_awal')" />
                                </div>
                            </div>
                            </div>
                            <p>Pastikan sebelum berangkat dari pool kondisi bbm dan odo km kendaraan wajib di foto</p>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">FOTO KONDISI AKHIR BBM DAN ODO KM</label>
                            <div class="profile-upload-content">
                            <img v-if="formCreate.foto_kondisi_akhir" :src="formCreate.foto_kondisi_akhir" alt="Preview Kondisi Akhir BBM & KM" class="img-thumbnail mb-3" width="100" height="100" />
                            <div class="profile-upload-btn">
                                <div class="profile-upload-file">
                                <input type="file" @change="event => selectFileAttachment(event, 'foto_kondisi_akhir')" />
                                </div>
                            </div>
                            </div>
                            <p>Pastikan sesudah kembali ke pool kondisi bbm dan ODO KM wajib di foto</p>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">FOTO BON TOL & PARKIR</label>
                            <div class="profile-upload-content">
                            <img v-if="formCreate.foto_bon_tol_parkir" :src="formCreate.foto_bon_tol_parkir" alt="Preview Bon Tol & Parkir" class="img-thumbnail mb-3" width="100" height="100" />
                            <div class="profile-upload-btn">
                                <div class="profile-upload-file">
                                <input type="file" @change="event => selectFileAttachment(event, 'foto_bon_tol_parkir')" />
                                </div>
                            </div>
                            </div>
                            <p>tidak ada bon fisik atau bukti lainya tidak akan bisa di claim</p>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">FOTO TIME SHEET atau CAR LOG</label>
                            <div class="profile-upload-content">
                            <img v-if="formCreate.foto_car_log" :src="formCreate.foto_car_log" alt="Preview Bon Tol & Parkir" class="img-thumbnail mb-3" width="100" height="100" />
                            <div class="profile-upload-btn">
                                <div class="profile-upload-file">
                                <input type="file" @change="event => selectFileAttachment(event, 'foto_car_log')" />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Catatan</label>
                            <input type="text" class="form-control" v-model="formCreate.remark"/>
                        </div>
                    </div>
                </div>
                <div class="text-end">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
                </form>
            </div>
            </div>
        </div>
    </div>

                    <!-- <div class="col-6">
                        <div class="mb-3">
                            <label class="form-label">NAMA MUDI</label>
                            <input type="text" class="form-control" v-model="formCreate.nama_driver"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">NAMA TAMU/NAMA VENDOR</label>
                            <input type="text" class="form-control" v-model="formCreate.nama_tamu"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">ASAL NEGARA</label>
                            <select class="form-select" v-model="formCreate.asal_negara">
                                <option value="Indonesia (Domestic)">Indonesia (Domestic)</option>
                                <option value="Mancanegara (non english speaking)">Mancanegara (non english speaking)</option>
                                <option value="Mancanegara (english speaking)">Mancanegara (english speaking)</option>
                            </select>
                        </div>
                        <div class="mb-3 form-group">
                            <div class="d-flex">
                                <div style="margin-right: 10px;">
                                    <label class="form-label">TANGGAL DAN WAKTU MULAI TRIP</label>
                                    <input type="date" class="form-control"  v-model="formCreate.starting_date"/>
                                </div>
                                <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> 
                                <div style="margin-left: 10px;">
                                    <label class="form-label">Jam</label>
                                    <input type="time" class="form-control"  v-model="formCreate.starting_time"/>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 form-group">
                            <div class="d-flex">
                                <div style="margin-right: 10px;">
                                    <label class="form-label">TANGGAL DAN WAKTU SELESAI TRIP</label>
                                    <input type="date" class="form-control"  v-model="formCreate.finish_date"/>
                                </div>
                                <span style="margin-top: 38px;">&nbsp;-&nbsp;</span> 
                                <div style="margin-left: 10px;">
                                    <label class="form-label">Jam</label>
                                    <input type="time" class="form-control"  v-model="formCreate.finish_time"/>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">RUTE PERJALANAN</label>
                            <input type="text" class="form-control" v-model="formCreate.rute_perjalanan"/>
                        </div>
                        <div class="mb-3">
                            <div class="col-lg-12">
                                <div class="split-check-title">
                                    <label class="form-label">Status Mudi</label>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="inlineRadio1" name="status_mudi" value="Super Prioritas" v-model="formCreate.status_mudi">
                                    <label class="form-check-label" for="inlineRadio1">Super Prioritas</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="inlineRadio2" name="status_mudi" value="Prioritas" v-model="formCreate.status_mudi">
                                    <label class="form-check-label" for="inlineRadio2">Prioritas</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="inlineRadio3" name="status_mudi" value="Free Lancer" v-model="formCreate.status_mudi">
                                    <label class="form-check-label" for="inlineRadio3">Free Lancer</label>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="col-lg-12">
                                <div class="split-check-title">
                                    <label class="form-label">Jenis layanan</label>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="mobil" name="mobil" value="" v-model="formCreate.mobil">
                                    <label class="form-check-label" for="mobil">Mobil</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="supir" name="supir" value="" v-model="formCreate.supir">
                                    <label class="form-check-label" for="supir">Supir</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="bbm" name="bbm" value="" v-model="formCreate.bbm">
                                    <label class="form-check-label" for="bbm">Bbm</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="tol" name="tol" value="" v-model="formCreate.tol">
                                    <label class="form-check-label" for="tol">Tol</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="parkir" name="parkir" value="" v-model="formCreate.parkir">
                                    <label class="form-check-label" for="parkir">Parkir</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="makan_supir" name="makan_supir" value="" v-model="formCreate.makan_supir">
                                    <label class="form-check-label" for="makan_supir">Makan Supir</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inap_supir" name="inap_supir" value="" v-model="formCreate.inap_supir">
                                    <label class="form-check-label" for="inap_supir">Inap Supir</label>
                                </div>
                                <p>Silahkan checklist sesuai jenis layanan yg di gunakan</p>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">JENIS KENDARAAN YG DIGUNAKAN</label>
                            <select class="form-select" v-model="formCreate.jenis_kendaraan">
                                <option value="Mobil Kecil (AVANZA, XENIA, INNOVA, XPANDER, H1, WULING)">Mobil Kecil (AVANZA, XENIA, INNOVA, XPANDER, H1, WULING)</option>
                                <option value="Mobil Besar (HIACE, PREMIO, ELF)">Mobil Besar (HIACE, PREMIO, ELF)</option>
                                <option value="Mobil Premium (ALPHARD, FORTUNER)">Mobil Premium (ALPHARD, FORTUNER)</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Nopol Kendaraan</label>
                            <input type="text" class="form-control" v-model="formCreate.nomor_plat"/>
                        </div>
                    </div> -->
    <!-- /Add Driver Report -->

    <!-- /absen driver-->
    <div class="modal fade custom-modal" id="absen-driver" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Absen Hari Ini</h5>
            <button
              id="close-absen"
              type="button"
              ref="closeButtonAbsen"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="saveAbsen">
              <div class="row mt-4">
                <!-- <div class="col-6">
                  <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                              <label class="form-label">Tanggal <span style="color:red">*</span></label>
                              <input type="date" class="form-control"  v-model="formAbsen.absen_masuk"/>
                          </div>
                      </div>
                  </div>

                </div> -->
                <div class="col-6">

                  <!-- <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                            <label class="form-label">Status Driver <span style="color:red">*</span></label>
                            <select class="form-select" v-model="formAbsen.status">
                                <option value="Ready">Ready</option>
                                <option value="Istirahat">Istirahat</option>
                            </select>
                          </div>
                      </div>
                  </div> -->
                  
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /absen driver-->

    <!-- /absen driver update status-->
    <div class="modal fade custom-modal" id="update-absen-driver" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Absen Driver</h5>
            <button
              id="close-absen-update"
              type="button"
              ref="closeButtonAbsenUpdate"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="updateAbsen">
              <div class="row mt-4">
                <div class="col-6">

                  <!-- <div class="mb-3 form-group">
                      <div class="d-flex">
                          <div style="margin-right: 10px;">
                            <label class="form-label">Status Driver <span style="color:red">*</span></label>
                            <select v-if="formAbsenUpdate" class="form-select" v-model="formAbsenUpdate.status">
                                <option value="Ready">Ready</option>
                                <option value="Istirahat">Istirahat</option>
                            </select>
                          </div>
                      </div>
                  </div> -->
                  <p>Anda Sudah Absen</p>
                  
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <!-- <button type="submit" class="btn btn-primary">Save</button> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /absen driver update status-->

    <!-- Show Customer -->
    <div class="modal fade" id="show-driver-grafik" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Riwayat Order</h5>
            <button
              id="close-show-customer"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
                <!-- Display calculations -->
                <div class="row">
                <div class="table-wrapper" style="max-height: 700px; overflow-y: auto;">
                  <table class="table" style="margin-bottom: 20px;">
                      <thead>
                      <tr>
                        <th>#</th>
                          <th>No Order</th>
                          <th>Tanggal Pembuatan Order</th>
                          <th>Nama Tamu</th>
                          <th>Tanggal Berangkat Order</th>
                          <th>Tanggal Kembali Order</th>
                          <th>Perjalanan</th>
                          <th>Kendaraan</th>
                      </tr>
                      </thead>
                      <tbody>
                          <tr v-if="formGrafik !== undefined" v-for="(item, index) in formGrafik.dataOrder.data" :key="index">
                              <td class="mb-3">
                                  {{ index + 1 }}
                              </td>
                              <td class="mb-3">
                                  {{ item.no_order }}
                              </td>
                              <td class="mb-3">
                                  {{ formatDate(item.created_at) }}
                              </td>
                              <td class="mb-3">
                                  {{ item.customer ? item.customer.name : "Data Tidak Ada" }}
                              </td>
                              <td class="mb-3">
                                  {{ formatDate(item.starting_date) }}
                              </td>
                              <td class="mb-3">
                                  {{ formatDate(item.finish_date) }}
                              </td>
                              <td class="mb-3">
                                {{ getCountOrder(item.id) }}
                              </td>
                              <td class="mb-3">
                                <div v-for="vehicle in listVehicle">
                                    <p v-if="vehicle.order_id == item.id">
                                        -{{ vehicle.vehiclePlat }}
                                    </p>
                                </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </div>
              </div>
              <div class="text-end mt-4">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Show Customer -->

    <div>
        <button 
            ref="hiddenImageModalOpener" 
            type="button" 
            data-bs-toggle="modal" 
            data-bs-target="#image-view" 
            style="display: none;">
          </button>
    </div>
    <image-view-model :imageSrc="modalImageSrc"/>
  </template>
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  import moment from 'moment'
  export default {
    data() {
      return {
        files: {
            foto_bbm: null,
            foto_kondisi_awal: null,
            foto_kondisi_akhir: null,
            foto_bon_tol_parkir: null,
            foto_car_log: null
        },
        formCreate: {
            mobil: false,
            supir: false,
            bbm: false,
            tol: false,
            parkir: false,
            makan_supir: false,
            inap_supir: false,
            foto_bbm: null,
            foto_kondisi_awal: null,
            foto_kondisi_akhir: null,
            foto_bon_tol_parkir: null,
            foto_car_log: null
        },
        formEdit: {},
        formAbsen: {},
        formAbsenUpdate: {},
        formGrafik: {
            dataOrder: [],
            data: []
        },
        selectedVehicle: null,
        boundHandleBackButton: null,
        selectedOrder: null,
        modalImageSrc: '',
        userData: JSON.parse(localStorage.getItem('user')),
        listVehicle: null
      };
    },
    props: {
        dataId: Object,
        reportData: Object,
        dataAbsen: Object,
        dataGrafik: Object,
        openImage: {
            type: String,
            required: true,
        },
    },
    emits: ['edit-report'],
    watch: {
        dataId: {
            immediate: true,
            handler(newOrderId) {
                if (newOrderId && newOrderId.newData) {
                    this.formCreate = newOrderId.newData.report[0];
                    this.selectedVehicle = newOrderId.newData.vehicle;
                    this.selectedOrder = newOrderId.orderData;
                    // if (this.formCreate.starting_date) {
                    //     this.formCreate.starting_date = this.formatDate(this.formCreate.starting_date);
                    // }
                    // if (this.formCreate.finish_date) {
                    //     this.formCreate.finish_date = this.formatDate(this.formCreate.finish_date);
                    // }
                    // console.log(newOrderId);
                    // if (newOrderId && newOrderId.newData && newOrderId.newData.id !== undefined) {
                    //     this.formCreate.order_vehicle_detail_id = newOrderId.newData.id;
                    // } else {
                    //     this.formCreate.order_vehicle_detail_id = null; // Or any default value you prefer
                    // }
                }
            }
        },
        reportData: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.formEdit = newValue.newData;
                    this.selectedOrder = newValue.orderData;
                    this.selectedVehicle = newValue.vehicleData;
                    if (this.formEdit) {
                        this.formEdit.mobil = this.formEdit.mobil == 1 ? true : false;
                        this.formEdit.supir = this.formEdit.supir == 1 ? true : false;
                        this.formEdit.bbm = this.formEdit.bbm == 1 ? true : false;
                        this.formEdit.tol = this.formEdit.tol == 1 ? true : false;
                        this.formEdit.parkir = this.formEdit.parkir == 1 ? true : false;
                        this.formEdit.makan_supir = this.formEdit.makan_supir == 1 ? true : false;
                        this.formEdit.inap_supir = this.formEdit.inap_supir == 1 ? true : false;
                    }
                }
            }
        },
        dataAbsen: {
            immediate: true,
            handler(newDataAbsen) {
                if (newDataAbsen !== undefined) {
                    this.formAbsenUpdate = newDataAbsen;
                }
            }
        },
        dataGrafik: {
            immediate: true,
            handler(newDataGrafik) {
                if (newDataGrafik && newDataGrafik.selectedData) {
                    this.formGrafik = newDataGrafik.selectedData;
                    this.listVehicle = this.formGrafik.data.map((item, index) => {
                        const vehiclePlat = item.vehicle? item.vehicle.nomor_plat : "-";
                        const order_id = item.order_vehicle.order_id;
                        return {
                            order_id,
                            vehiclePlat
                        }
                    });
                }
            }
        },
        openImage(newSrc) {
            if (newSrc) {
                this.openImageView(newSrc);
                this.$refs.hiddenImageModalOpener.click();
            }
        },
        'formCreate.nominal_bbm': 'updateComputedValues',
        'formCreate.nominal_tol': 'updateComputedValues',
        'formCreate.nominal_parkir': 'updateComputedValues',
        'formCreate.nominal_makan': 'updateComputedValues',
        'formCreate.nominal_inap': 'updateComputedValues',
        'formCreate.nominal_op': 'updateComputedValues'
    },
    computed : {
        formattedDateTime() {
            if (this.selectedOrder) {
                const { starting_date, starting_time, finish_date, finish_time } = this.selectedOrder;
                const startDate = new Date(starting_date).toLocaleDateString('en-GB');
                const finishDate = new Date(finish_date).toLocaleDateString('en-GB');
                return `${startDate} pukul: ${starting_time} S/D ${finishDate} pukul: ${finish_time}`;
            }
            return '';
        },
    },
    methods: {
        validateFile(file, allowedFileTypes, maxFileSize) {
            if (!allowedFileTypes.includes(file.type)) {
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
                });
                return false;
            }

            if (file.size > maxFileSize) {
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
                });
                return false;
            }

            return true;
        },
        selectFileAttachment(event, column) {
            const file = event.target.files[0];
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const maxFileSize = 10 * 1024 * 1024; // 10 MB
            const compressionThreshold = 2 * 1024 * 1024; // 2 MB

            if (!this.validateFile(file, allowedFileTypes, maxFileSize)) {
                return;
            }

            if (file.size > compressionThreshold) {
                // Compress the image if it's larger than 2 MB
                this.compressImage(file, compressionThreshold).then((compressedFile) => {
                    this.uploadAndHandleFile(compressedFile, column);
                }).catch((error) => {
                    this.$swal.fire({
                        icon: 'error',
                        text: `Gagal mengompresi gambar: ${error.message}`,
                    });
                });
            } else {
                // Directly upload files that don't need compression
                this.uploadAndHandleFile(file, column);
            }
        },

        compressImage(file, targetSize) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        let width = img.width;
                        let height = img.height;

                        // Start with high-quality compression and decrease until target size is met
                        let quality = 0.9; // Start at 90% quality

                        const compressAndCheck = () => {
                            canvas.width = width;
                            canvas.height = height;
                            ctx.drawImage(img, 0, 0, width, height);

                            canvas.toBlob((blob) => {
                                if (blob.size <= targetSize || quality < 0.1) {
                                    // If the size is within the target size or the quality is too low, resolve
                                    resolve(new File([blob], file.name, { type: file.type }));
                                } else {
                                    // Reduce quality and try again
                                    quality -= 0.1;
                                    compressAndCheck();
                                }
                            }, file.type, quality);
                        };

                        compressAndCheck();
                    };
                };
                reader.onerror = (error) => reject(error);
            });
        },

        uploadAndHandleFile(file, column) {
            this.files[column] = file;

            var loading = this.$loading.show();

            uploadFile(file)
                .then((resp) => {
                    this.formCreate[column] = resp;  // Ensure that `resp` contains a 'url' property
                    loading.hide();
                    this.$swal.fire({
                        icon: 'success',
                        text: 'File berhasil diupload !!',
                    });
                })
                .catch((e) => {
                    loading.hide();
                    this.$swal.fire({
                        icon: 'warning',
                        text: 'Terjadi kesalahan: ' + e.message,
                    });
                });
        },
        updateSubmit() {
            var loading = this.$loading.show()
            this.$store
            .dispatch("reportDriver/updateReportDriver", { id: this.formCreate.id, data: this.formCreate })
            .then((data) => {
                if (data.status == 201) {
                loading.hide()
                this.formCreate = {
                    mobil: false,
                    supir: false,
                    bbm: false,
                    tol: false,
                    parkir: false,
                    makan_supir: false,
                    inap_supir: false,
                    foto_bbm: null,
                    foto_kondisi_awal: null,
                    foto_kondisi_akhir: null,
                    foto_bon_tol_parkir: null,
                    foto_car_log: null
                };
                this.files = {
                    foto_bbm: null,
                    foto_kondisi_awal: null,
                    foto_kondisi_akhir: null,
                    foto_bon_tol_parkir: null,
                    foto_car_log: null
                    },
                this.$refs.closeButtonCreateOrderModalRef.click()
                document.getElementById('close-create').click();
                this.$emit('dataUpdated');
                }
            },
            (error) => {
                console.log(error)
                loading.hide();
            })
            .catch((e) => {
                loading.hide()
            })
        },
        saveAbsen() {
            var loading = this.$loading.show()
            const userData = localStorage.getItem('user');
            const user = JSON.parse(userData);
            const now = new Date();
            const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Jakarta' }; // Adjust timeZone if needed
            const formatter = new Intl.DateTimeFormat('en-CA', options);
            const today = formatter.format(now).replace(/\//g, '-');
            this.userLogin = user;
            this.formAbsen.driver_id = user.id;
            this.formAbsen.absen_masuk = today;
            this.formAbsen.status = "Ready";
            this.$store
            .dispatch("driver/addAbsenDriver", this.formAbsen )
            .then((data) => {
                if (data.status == 201) {
                    loading.hide()
                    this.formAbsen = {}
                    this.$refs.closeButtonAbsenUpdate.click()
                    document.getElementById('close-absen').click();
                    this.$emit('dataUpdated');
                }
            },
            (error) => {
                console.log(error)
                loading.hide();
            })
            .catch((e) => {
                loading.hide()
            })
        },
        updateAbsen() {
            var loading = this.$loading.show()
            const id = this.formAbsenUpdate.driver_id;
            this.$store
            .dispatch("driver/updateAbsenDriver", { id: id, data: this.formAbsenUpdate })
            .then((data) => {
                if (data.status == 200) {
                    loading.hide()
                    this.formAbsen = {}
                    this.$refs.closeButtonAbsenUpdate.click()
                    document.getElementById('close-absen-update').click();
                    this.$emit('dataUpdated');
                }
            },
            (error) => {
                console.log(error)
                loading.hide();
            })
            .catch((e) => {
                loading.hide()
            })
        },
        formatDate(dateTime) {
            return moment(dateTime).format('YYYY-MM-DD');
        },
        updateComputedValues() {
            this.formCreate.nominal_pemakaian_op =
                this.sanitizeNumber(this.formCreate.nominal_bbm) +
                this.sanitizeNumber(this.formCreate.nominal_tol) +
                this.sanitizeNumber(this.formCreate.nominal_parkir) +
                this.sanitizeNumber(this.formCreate.nominal_makan) +
                this.sanitizeNumber(this.formCreate.nominal_inap);

            this.formCreate.nominal_sisa_op =
                this.sanitizeNumber(this.formCreate.nominal_op) - this.formCreate.nominal_pemakaian_op;
        },
        sanitizeNumber(value) {
            return isNaN(value) ? 0 : Number(value);
        },
        getCountOrder(orderId) {
            const order = this.formGrafik.dataOrder.data.find(detail => detail.id === orderId);
    
            if (!order) {
                return 0; // If no order is found with the specified orderId, return 0
            }

            const startDate = moment(order.starting_date);
            const finishDate = moment(order.finish_date);
            
            const totalDays = finishDate.diff(startDate, 'days') + 1; // +1 to include both start and finish dates
            
            return totalDays;
        },
        closeModal() {
            if (this.$refs.closeButtonAbsenUpdate) {
                this.$refs.closeButtonAbsenUpdate.click();
            }
            if (this.$refs.closeButtonCreateOrderModalRef) {
                this.$refs.closeButtonCreateOrderModalRef.click();
            }
        },
        handleBackButton(event) {
            event.preventDefault();
            this.closeModal();
        },
        openImageView(imageSrc) {
            this.modalImageSrc = imageSrc;
        },
    },
    mounted() {
        this.boundHandleBackButton = this.handleBackButton.bind(this);
        window.addEventListener('popstate', this.boundHandleBackButton);
        window.history.pushState({ modalOpen: true }, null, window.location.href);
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.boundHandleBackButton);
    }
  };
  </script>
  