<template>
  <!-- Hero Section -->
  <section class="hero-section">
    <div class="container">
      <div class="home-banner">
        <div class="row align-items-center w-100">
          <div class="col-lg-7 col-md-10 mx-auto">
            <div class="section-search aos" data-aos="fade-up">
              <h1>Professional Services For Your Home & Commercial</h1>
              <p>Search From 100 Awesome Verified Ads!</p>
              <div class="search-box">
                <form @submit.prevent="submitForm">
                  <div class="search-input line">
                    <div class="search-group-icon">
                      <i class="feather-map-pin"></i>
                    </div>
                    <div class="form-group mb-0">
                      <label>Your Location</label>
                      <input type="text" class="form-control" placeholder="America" />
                    </div>
                  </div>
                  <div class="search-input">
                    <div class="search-group-icon search-icon">
                      <i class="feather-search"></i>
                    </div>
                    <div class="form-group mb-0">
                      <label>What are you looking for?</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Car Repair Services"
                      />
                    </div>
                  </div>
                  <div class="search-btn">
                    <b-button variant="primary" type="submit"
                      ><i class="feather-search me-2"></i>Search</b-button
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="banner-imgs">
              <div class="banner-1 shape-1">
                <img class="img-fluid" alt="banner" src="@/assets/img/banner1.png" />
              </div>
              <div class="banner-2 shape-3">
                <img class="img-fluid" alt="banner" src="@/assets/img/banner2.png" />
              </div>
              <div class="banner-3 shape-3">
                <img class="img-fluid" alt="banner" src="@/assets/img/banner3.png" />
              </div>
              <div class="banner-4 shape-2">
                <img class="img-responsive" alt="banner" src="@/assets/img/banner4.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Hero Section -->

  <!-- Feature Section -->
  <section class="feature-section">
    <div class="container">
      <div class="section-heading">
        <div class="row">
          <div class="col-md-6 aos" data-aos="fade-up">
            <h2>Featured Categories</h2>
            <p>What do you need to find?</p>
          </div>
          <div class="col-md-6 text-md-end aos" data-aos="fade-up">
            <router-link to="/categories" class="btn btn-primary btn-view"
              >View All<i class="feather-arrow-right-circle"></i
            ></router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-3">
          <router-link to="/service-details" class="feature-box aos" data-aos="fade-up">
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-01.svg" alt="img" />
              </span>
            </div>
            <h5>Construction</h5>
            <div class="feature-overlay">
              <img src="@/assets/img/services/service-02.jpg" alt="img" />
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-lg-3">
          <router-link to="/service-details" class="feature-box aos" data-aos="fade-up">
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-02.svg" alt="img" />
              </span>
            </div>
            <h5>Car Wash</h5>
            <div class="feature-overlay">
              <img src="@/assets/img/feature.jpg" alt="img" />
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-lg-3">
          <router-link to="/service-details" class="feature-box aos" data-aos="fade-up">
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-03.svg" alt="img" />
              </span>
            </div>
            <h5>Electrical</h5>
            <div class="feature-overlay">
              <img src="@/assets/img/services/service-01.jpg" alt="img" />
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-lg-3">
          <router-link to="/service-details" class="feature-box aos" data-aos="fade-up">
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-04.svg" alt="img" />
              </span>
            </div>
            <h5>Cleaning</h5>
            <div class="feature-overlay">
              <img src="@/assets/img/services/service-09.jpg" alt="img" />
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-lg-3">
          <router-link to="/service-details" class="feature-box aos" data-aos="fade-up">
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-05.svg" alt="img" />
              </span>
            </div>
            <h5>Interior</h5>
            <div class="feature-overlay">
              <img src="@/assets/img/services/service-07.jpg" alt="img" />
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-lg-3">
          <router-link to="/service-details" class="feature-box aos" data-aos="fade-up">
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-06.svg" alt="img" />
              </span>
            </div>
            <h5>Carpentry</h5>
            <div class="feature-overlay">
              <img src="@/assets/img/services/service-03.jpg" alt="img" />
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-lg-3">
          <router-link to="/service-details" class="feature-box aos" data-aos="fade-up">
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-07.svg" alt="img" />
              </span>
            </div>
            <h5>Computer</h5>
            <div class="feature-overlay">
              <img src="@/assets/img/services/service-06.jpg" alt="img" />
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-lg-3">
          <router-link to="/service-details" class="feature-box aos" data-aos="fade-up">
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-08.svg" alt="img" />
              </span>
            </div>
            <h5>Plumbing</h5>
            <div class="feature-overlay">
              <img src="@/assets/img/services/service-11.jpg" alt="img" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
  },
};
</script>
