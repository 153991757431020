<template>
    <div class="main-wrapper">
        <layout></layout>
        <adminsidebar></adminsidebar>

        <div class="page-wrapper">
            <div class="content">
                <div class="row">
                    <div class="col-lg-7 col-sm-12 m-auto">
                        <div class="content-page-header">
                            <h5>Create Menu</h5>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Name">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Languages</label>
                                    <div class="form-group">
                                        <div class="group-image">
                                            <img src="@/assets/admin_img/flags/us1.png" alt="img">
                                            <vue-select 
                                                :options="State"
                                                placeholder="Select State"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label>Status</label>
                                    <div class="form-group">
                                        <vue-select 
                                            :options="Select"
                                            placeholder="Published"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="btn-path">
                                   
                                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                                    <a href="javascript:void(0);" class="btn btn-primary">Save</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    data() {
        return {
            State: ["Select State", "US", "London"],
            Select: ["Published", "Unpublished"]
        }
    },
    name: 'admincreate-menu',
}
</script>