<template>
    <!-- Add Leave -->
		<div class="modal fade custom-modal" id="add-reschedule">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content doctor-profile">
					<div class="modal-header border-bottom-0 justify-content-between">
						<h5 class="modal-title">Reschedule Appointment</h5>
						<button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x"></i></button>
					</div>
					<div class="modal-body pt-0">
						<form action="javascript:;">
							<div class="wallet-add">
								<div class="form-group">
									<label class="col-form-label">Appointment Date</label>
									<div class="form-icon">
										<input type="text" class="form-control datetimepicker" placeholder="Select appointment date" />
										<span class="cus-icon"><i class="feather-calendar"></i></span>
									</div>
								</div>
								<div class="form-group">
									<label class="col-form-label">Appointment Time</label>
									<div class="form-icon">
										<input type="text" class="form-control timepicker" placeholder="Select appointment date" />										<span class="cus-icon"><i class="feather-clock"></i></span>
									</div>
								</div>
								<div class="modal-submit text-end">
									<a href="javascript:;" class="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</a>
									<b-button type="submit" variant="primary">Save</b-button>
								</div>								
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- /Add Leave -->
		
		<!-- Alert Message -->
		<div class="modal fade custom-modal" id="alertmsg">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content doctor-profile">
					<div class="modal-header border-bottom-0 justify-content-between">
						<h5 class="modal-title">Cancel Appointment</h5>
						<button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x"></i></button>
					</div>
					<div class="modal-body pt-0">
						<form action="javascript:;">
							<div class="msg-alert">
								<p>Are you sure you want to cnacel <span>John Doe</span> appointment on <span>Oct 28, 2023</span> at time <span>10AM - 12PM</span> </p>
								<p>You wan't be able to revert this action later?</p>
							</div>
							<div class="modal-submit text-end">
								<a href="javascript:;" class="btn btn-secondary me-2" data-bs-dismiss="modal">Dismiss</a>
								<b-button type="submit" variant="primary">Yes, cancel</b-button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- /Alert Message -->
</template>