<template>
    <div class="mt-0">
        <!-- Session Expired -->
        <div class="main-wrapper error-page">
            <div class="bg-img">
                <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg1">
                <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg2">
                <img src="@/assets/img/bg/feature-bg-03.png" alt="img" class="bgimg3">
            </div>
            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="error-wrap text-center">
                                <div class="error-logo">
                                    <router-link to="/"><img class="img-fluid" src="@/assets/admin_img/logo_marirental3.svg" alt="img"></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="error-wrap">
                                <h2>Limit Pembuatan Order / Masa Berlangganan Anda Telah Habis</h2>
                                <p>Silahkan hubungi Call Center kami untuk lebih lanjut</p>
                                <router-link to="/mari/login" class="btn btn-primary"><i
                                    class="feather-arrow-left-circle me-2"></i>Kembali Ke Login</router-link>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="error-wrap">
                                <div class="error-img">
                                    <img class="img-fluid" src="@/assets/img/mari/mari_rental_1.png" alt="img">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Session Expired -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>