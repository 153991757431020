<template>
    <!-- See How It Works -->
    <section class="hidden-charge-section">
        <div class="container">
            <div class="section-heading section-heading-six">
                <div class="row justify-content-center text-center">
                    <div class="col-md-6 aos" data-aos="fade-up">
                        <div class="reason-six justify-content-center">
                            <img src="@/assets/img/icons/vacuum-cleaner.svg" alt="">
                            <p>NO EXTRA HIDDEN CHARGES!</p>
                        </div>
                        <h2>Choose Your <span>Plan</span></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="hidden-charge-main">
                        <div class="hidden-charge-img">
                            <img src="@/assets/img/icons/charges-3.svg" alt="">
                        </div>
                        <h4>Standard</h4>
                        <ul>
                            <li>Experienced & Trained Cleaner</li>
                            <li>Maintenance Cleaning</li>
                            <li>Insured Liability ans Cleaning</li>
                            <li>Planed Holiday Cover</li>
                            <li>Experienced & Trained Cleaner</li>
                        </ul>
                        <div class="hidden-charges-bottom-mark">
                            <h1>$15<span>/hr</span></h1>
                            <h6>PER MONTH</h6>
                        </div>
                        <a href="javascript:;" class="btn btn-primary btn-charges w-100">Book Now</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="hidden-charge-main hidden-charge-main-two">
                        <div class="hidden-charge-img">
                            <img src="@/assets/img/icons/charges-2.svg" alt="">
                        </div>
                        <h4>Premium</h4>
                        <ul>
                            <li>Experienced & Trained Cleaner</li>
                            <li>Maintenance Cleaning</li>
                            <li>Insured Liability ans Cleaning</li>
                            <li>Planed Holiday Cover</li>
                            <li>Experienced & Trained Cleaner</li>
                            <li>Maintenance Cleaning</li>
                            <li>Insured Liability ans Cleaning</li>
                        </ul>
                        <div class="hidden-charges-bottom-mark">
                            <h1>$100<span>/hr</span></h1>
                            <h6>PER MONTH</h6>
                        </div>
                        <a href="javascript:;" class="btn btn-primary btn-charges btn-plan w-100">Book Now</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="hidden-charge-main">
                        <div class="hidden-charge-img">
                            <img src="@/assets/img/icons/charges-1.svg" alt="">
                        </div>
                        <h4>Enterprise</h4>
                        <ul>
                            <li>Experienced & Trained Cleaner</li>
                            <li>Maintenance Cleaning</li>
                            <li>Insured Liability ans Cleaning</li>
                            <li>Planed Holiday Cover</li>
                            <li>Experienced & Trained Cleaner</li>
                        </ul>
                        <div class="hidden-charges-bottom-mark">
                            <h1>$56<span>/hr</span></h1>
                            <h6>PER MONTH</h6>
                        </div>
                        <a href="javascript:;" class="btn btn-primary btn-charges w-100">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- App Section -->
    <section class="app-six-section">
        <div class="container">
            <div class="app-sec app-sec-six">
                <div class="app-sec-main">
                    <div class="app-imgs-six-bg">
                        <img src="@/assets/img/new-app-six-bg.png" alt="">
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-12">
                            <div class="appimg-six aos" data-aos="fade-up">
                                <img src="@/assets/img/new-app-img.png" class="img-fluid" alt="img">
                                <div class="app-img-sec">
                                    <img src="@/assets/img/new-app-sec-ng.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="new-app-six-middle">
                                <div class="heading aos" data-aos="fade-up">
                                    <h2>Download <span>Our New App</span></h2>
                                    <p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla
                                        ut metus varius laoreet.Aliquam lorem ante.</p>
                                </div>
                                <div class="new-app-six-bottom aos" data-aos="fade-up">
                                    <div class="new-app-six-content">
                                        <a href="javascript:;"><img src="@/assets/img/icons/googleplay-six.svg"
                                                class="img-fluid new-six-img" alt="img"></a>
                                        <a href="javascript:;"><img src="@/assets/img/icons/appstore-six.svg" class="img-fluid"
                                                alt="img"></a>
                                    </div>
                                    <a href="javascript:;" target="_blank">
                                        <img src="@/assets/img/icons/scan-six.svg" class="img-fluid" alt="img">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /App Section -->
</template>

