<template>
    <section class="customers-eight-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="customers-eights-all">
                        <div class="section-heading section-heading-eight customers-eight-heading aos" data-aos="fade-up">
                            <img src="@/assets/img/icons/dog.svg" alt="">
                            <h2>Over 1000+ Customers
                                with 5-Star Reviews</h2>
                            <p>Mauris ut cursus nunc. </p>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the industry.</p>
                        <ul class="total-client-avatar total-client-avatar-seven">
                            <li>
                                <a href="javascript:;">
                                    <img src="@/assets/img/profiles/avatar-06.jpg" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;">
                                    <img src="@/assets/img/profiles/avatar-07.jpg" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;">
                                    <img src="@/assets/img/profiles/avatar-08.jpg" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;">
                                    <img src="@/assets/img/profiles/avatar-09.jpg" alt="">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;">
                                    <img src="@/assets/img/profiles/avatar-10.jpg" alt="">
                                </a>
                            </li>
                        </ul>
                        <p class="customers-icons"><i class="fa-solid fa-star filled me-1"></i><span>4.9</span>/1000 Reviews</p>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="owl-carousel testimonals-eight-slider">
                        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                            <Slide v-for="item in indexeightcustomer" :key="item.id">
                                <div class="testimonials-main-ryt customers-eight-main">
                                    <div class="testimonials-content-seven">
                                        <div class="testimonials-seven-img">
                                            <img :src="require(`@/assets/img/profiles/${item.image}`)" alt="">
                                        </div>
                                        <img src="@/assets/img/icons/test-quote.svg" alt="" class="img-fluid">
                                    </div>
                                    <div class="testimonials-img-content">
                                        <h6>James Williams</h6>
                                        <div class="rating">
                                            <i class="fa-solid fa-star filled me-1"></i>
                                            <i class="fa-solid fa-star filled me-1"></i>
                                            <i class="fa-solid fa-star filled me-1"></i>
                                            <i class="fa-solid fa-star filled me-1"></i>
                                            <i class="fa-solid fa-star filled me-1"></i>
                                        </div>
                                    </div>
                                    <p>There are many variations of passages of Lorem Ipsum available,
                                        but the majority have suffered alteration in some form, by injected humour, or randomised
                                        words which don</p>
                                </div>
                            </Slide>
                                <template #addons>
                                    <Navigation />
                                </template>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexeightcustomer from '@/assets/json/indexeightcustomer.json'
import "vue3-carousel/dist/carousel.css";
export default {
	data() {
		return {
			indexeightcustomer: indexeightcustomer,
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
				loop: true,
				margin: 24,
			},

			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 700px and up
				767: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 991px and up
				991: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 1024 and up
				1024: {
					itemsToShow: 1,
					snapAlign: "start"

				}
			}
		}
	},
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
}
</script>

