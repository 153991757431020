<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <sidelinkheading />
      <div class="row">
        <div class="content-table">
          <div class="col-lg-12">
            <div class="content-page-header calendar-set-grp">
              <h5>Calendar</h5>
              <div class="filter-sorting">
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="filter-sets"
                      ><img
                        src="@/assets/admin_img/icons/filter1.svg"
                        class="me-2"
                        alt="img"
                      />Filter</a
                    >
                  </li>
                  <li>
                    <span
                      ><img
                        src="@/assets/admin_img/icons/sort.svg"
                        class="me-2"
                        alt="img"
                    /></span>
                    <div class="review-sort">
                      <vue-select :options="CalendarSelect" placeholder="A -> Z" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="card bg-white">
              <div class="card-body">
                <div id="calendar"></div>
                <FullCalendar
                  :options="calendarOptions"
                  :events="events"
                  id="calendar-book"
                ></FullCalendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      events: [],
      CalendarSelect: ["A -> Z", "Z -> A"],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        events: [
          {
            title: "6:48a Test Event 1",
            start: "2023-09-19",
          },
          {
            title: "11:55a Test Event 2",
            start: "2023-09-21",
          },
          {
            title: "05:20p Event Name 4",
            start: "2023-09-21",
          },
          {
            title: "04:42p Test Event 3",
            start: "2023-09-23",
          },
        ],
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
      },
    };
  },
};
</script>
