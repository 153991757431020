<template>
  <div
    class="modal fade"
    id="add-tax"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="submitForm">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Create Tax</h5>
            <button
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Tax Name</label>
                  <input type="text" class="form-control" placeholder="Enter Tax Name" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Tax Percentage</label>
                  <input type="text" class="form-control" placeholder="0.00%" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Create Tax</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="edit-tax"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="submitForm">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabeltwo">Edit Tax Rate</h5>
            <button
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Tax Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Name"
                    value="Casual Customs"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Tax Percentage</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Tax Rate"
                    value="2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Delete -->
  <div class="modal fade" id="delete-item">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Tax Rates ?</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to Delete?</p>
          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete -->
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/tax-rates");
    },
  },
};
</script>
