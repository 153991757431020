<template>
  <!-- Booking Details -->
  <div class="toggle-sidebar">
    <div class="sidebar-layout">
      <div class="sidebar-header">
        <h5>Booking Details</h5>
        <a href="javascript:;" class="sidebar-close"><i class="feather-x"></i></a>
      </div>
      <div class="sidebar-body">
        <div class="book-confirm bk-wrap">
          <div class="d-flex justify-content-between">
            <h6>Services<span class="badge-success">Confirmed</span></h6>
            <a href="javascript:;" class="edit-book"><i class="feather-edit"></i></a>
          </div>
          <ul>
            <li>
              <span class="bk-date"><i class="feather-calendar"></i> Date & Time </span> :
              Oct 28, 2023 - 10AM to 12 AM
            </li>
            <li>
              <span class="bk-date"><i class="feather-map-pin"></i> Location </span> : New
              York
            </li>
            <li>
              <span class="bk-date"><i class="feather-user"></i> User Name </span> : John
              Smith
            </li>
          </ul>
          <div class="bk-action">
            <a
              href="javascript:;"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#add-reschedule"
              ><i class="feather-user"></i> Reschedule</a
            >
            <a
              href="javascript:;"
              class="btn btn-secondary"
              data-bs-toggle="modal"
              data-bs-target="#alertmsg"
              ><i class="feather-x-circle"></i> Cancel</a
            >
          </div>
        </div>
        <div class="book-customer bk-wrap">
          <h5>Customer Details</h5>
          <div class="d-flex flex-wrap">
            <div class="book-email">
              <img src="@/assets/img/profiles/avatar-02.jpg" alt="" />
              <div>
                <p>John Doe</p>
                <p>john@gmail.com</p>
              </div>
            </div>
            <div class="book-email-info">
              <div>
                <p>+1 888 888 8888</p>
                <p>Montana, USA</p>
              </div>
              <a href="javascript:void(0)" class="btn btn-primary btn-mail"
                ><img src="@/assets/img/icons/message.svg" alt="" /> Chat</a
              >
            </div>
          </div>
        </div>
        <div class="bk-wrap">
          <h5>Appointment Message</h5>
          <p>Thanks for your interest in our services</p>
        </div>
        <div class="bk-wrap bk-service">
          <div>
            <h5>House Cleaning Services</h5>
            <p>quick and quality service</p>
          </div>
          <p class="bk-price">$100.00</p>
        </div>
        <div class="bk-wrap bk-history">
          <h4>Booking History</h4>
          <ul>
            <li>
              <span>
                <i class="feather-calendar"></i>
              </span>
              <div class="book-crete">
                <h6>Booking created</h6>
                <p>Oct 28 2023 1:28 PM</p>
              </div>
            </li>
            <li>
              <span>
                <i class="feather-user"></i>
              </span>
              <div class="book-crete">
                <h6>Assigned to John Smith</h6>
                <p>Oct 28 2023 1:28 PM</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- /Booking Details -->

  <!-- Delete Account -->
  <div class="modal fade custom-modal" id="del-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sureyou want to delete This Account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <b-button type="submit" variant="danger">Delete Account</b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/login");
    },
  },
};
</script>
