<template>
    <!-- Works Section -->
        <section class="works-section">
            <div class="container">
                <div class="services-header aos" data-aos="fade-up">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="section-content">
                                <h2>How It Works</h2>
                                <div class="our-img-all">
                                <img src="@/assets/img/icons/scissor.svg" alt="">
                                </div>
                                <p>Our Barbershop & Tattoo Salon provides classic services combined with innovative techniques.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="works-main aos" data-aos="fade-right">
                            <div class="works-tops">
                                <div class="works-top-img">
                                    <img src="@/assets/img/services/service-30.jpg" alt="">
                                    <span>1</span>
                                </div>
                            </div>
                            <div class="works-bottom">
                                <h2>Discover</h2>
                                <p>Barber is a person whose occupation is mainly to cut dress groom style and shave men.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="works-main aos" data-aos="fade-up">
                            <div class="works-tops">
                                <div class="works-top-img works-load-profile">
                                    <img src="@/assets/img/services/service-25.jpg" alt="">
                                    <span>2</span>
                                </div>
                            </div>
                            <div class="works-bottom">
                                <h2>Basics</h2>
                                <p>Barber is a person whose occupation is mainly to cut dress groom style and shave men.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 col-12">
                        <div class="works-main aos" data-aos="fade-left">
                            <div class="works-tops">
                                <div class="works-top-img">
                                    <img src="@/assets/img/services/service-30.jpg" alt="">
                                    <span>3</span>
                                </div>
                            </div>
                            <div class="works-bottom">
                                <h2>Enjoy</h2>
                                <p>Barber is a person whose occupation is mainly to cut dress groom style and shave men.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- /Works Section -->

        <!-- client section -->
        <section class="client-sections review-four">
            <div class="container">
                <div class="services-header aos" data-aos="fade-up">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="section-content section-client">
                                <h2>What Our Client Says</h2>
                                <div class="our-img-all">
                                <img src="@/assets/img/icons/scissor-white.svg" alt="">
                                </div>
                                <p>Our Barbershop & Tattoo Salon provides classic services combined with innovative techniques.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" slider say-about slider-for aos" data-aos="fade-up">
                    <Carousel>
                        <Slide v-for="item in indexthreework" :key="item.id">
                        <div class="review-love-group">
                            <div class="quote-love-img">
                                <img class="img-fluid" src="@/assets/img/icons/quote.svg" alt="">
                            </div>
                            <p class="review-passage">{{ item.hint }}</p>
                            <div class="say-name-blk text-center">
                                <h5>Ramen Daren</h5>
                                <p>Newyork, USA</p>
                            </div>
                        </div>
                        </Slide>
                    </Carousel>
                
                </div>
                
                <div class="slider client-img slider-nav client-pro aos" data-aos="fade-up">
                    <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints"  >
                    <Slide v-for="item in indexthreework" :key="item.id" class="testimonial-thumb">
                        <img :src="require(`@/assets/img/profiles/${item.img}`)" alt="">
                    </Slide>
                </Carousel>
                </div>
            
            </div>
        </section>
        <!-- /client section -->
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexthreework from '@/assets/json/indexthreework.json'
import "vue3-carousel/dist/carousel.css";
export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            indexthreework: indexthreework, 
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
                loop: true,
                margin: 24,
            },

            breakpoints: {
                575: {
                    itemsToShow: 2,
                    snapAlign: "center"
                },
                // 700px and up
                767: {
                    itemsToShow: 2,
                    snapAlign: "center"
                },
                // 991px and up
                991: {
                    itemsToShow: 2,
                    snapAlign: "center"
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: "start"
                }
            },
            settingswork: {
                itemsToShow: 1,
                snapAlign: "center",
                loop: true,
                margin: 24,
                asNavFor: '.client-img.slider-nav'
            },
        }
    },
}
</script>
