<template>
    <header class="header">
      <div class="container">
        <nav class="navbar navbar-expand-lg header-nav">
          <div class="navbar-header">
            <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
              <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <router-link to="#" class="navbar-brand logo">
              <img src="@/assets/img/mari/logo_marirental3.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <router-link to="#" class="navbar-brand logo-small">
              <img src="@/assets/img/mari/logo_marirental3.svg" class="img-fluid" alt="Logo" />
            </router-link>
          </div>
          <div class="main-menu-wrapper">
            <div class="menu-header">
              <a
                id="menu_close"
                class="menu-close"
                href="javascript:void(0);"
                @click="closeMenu"
              >
                <i class="fas fa-times"></i
              ></a>
            </div>
            
            <div v-if="isDriver == false">
              <mari-header-menu />
            </div>
          </div>
          <ul class="nav header-navbar-rht noti-pop-detail">  
            <!-- Chat -->
            <li class="nav-item logged-item msg-nav">
              <router-link to="#" class="nav-link">
                <img src="@/assets/img/icons/message-icon.svg" alt="" />
              </router-link>
            </li>
            <!-- /Chat -->
  
            <!-- Notifications -->
            <li
              class="nav-item has-arrow dropdown-heads dropdown logged-item noti-nav noti-wrapper"
            >
              <a
                href="javascript:;"
                class="dropdown-toggle nav-link notify-link"
                data-bs-toggle="dropdown"
              >
                <span class="noti-message"></span>
                <img src="@/assets/img/icons/bell-icon.svg" alt="Bell" />
              </a>
              <div class="dropdown-menu notify-blk notifications">
                <div class="topnav-dropdown-header">
                  <div>
                    <!-- <p class="notification-title">Notifications <span> 3 </span></p> -->
                  </div>
                  <!-- <a href="javascript:void(0)" class="clear-noti"
                    ><i class="fa-regular fa-circle-check"></i> Mark all as read
                  </a> -->
                </div>
                <div class="noti-content">
                  <ul class="notification-list">
                    <li v-if="isTrialExpired" class="notification-message">
                      <div>
                        <div class="media noti-img d-flex">
                          <div class="media-body flex-grow-1">
                            <p class="noti-details">
                              <strong>Your trial period has expired. Please upgrade to continue.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-if="isPaymentDue" class="notification-message">
                      <div>
                        <div class="media noti-img d-flex">
                          <div class="media-body flex-grow-1">
                            <p class="noti-details">
                              <strong>Your payment due date has passed. Please make a payment to continue.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-if="isEntryLimitReached" class="notification-message">
                      <div>
                        <div class="media noti-img d-flex">
                          <div class="media-body flex-grow-1">
                            <p class="noti-details">
                              <strong>You have reached your entry limit.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-if="timeUntilExpiration" class="notification-message">
                      <div>
                        <div class="media noti-img d-flex">
                          <div class="media-body flex-grow-1">
                            <p class="noti-details">
                              <strong>Time until expiration: {{ timeUntilExpiration }}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-if="entryLimitCount" class="notification-message">
                      <div>
                        <div class="media noti-img d-flex">
                          <div class="media-body flex-grow-1">
                            <p class="noti-details">
                              <strong>Orders Limit: {{ companyInfo.entry_limit }}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="notification-message">
                      <div>
                        <div class="media noti-img d-flex">
                          <div class="media-body flex-grow-1">
                            <p class="noti-details">
                              <strong>List Order Draf</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                    <template v-if="orderDraf && orderDraf.length > 0">
                      <template v-for="order in orderDraf" :key="order.noOrder">
                        <li class="notification-message">
                          <div>
                            <div class="media noti-img d-flex">
                              <div class="media-body flex-grow-1">
                                <p class="noti-details">
                                  <strong>{{ order.noOrder }}</strong>
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </template>
                    </template>
                  </ul>
                </div>
                <div class="topnav-dropdown-footer">
                  <!-- <div
                    >View All Notifications
                    <img src="@/assets/img/icons/arrow-right-01.svg" alt="Arrow"
                  /></div>> -->
                </div>
              </div>
            </li>
            <!-- /Notifications -->
  
            <!-- User Menu -->
            <li class="nav-item dropdown has-arrow account-item">
              <a
                href="javascript:;"
                class="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
              >
                <div class="user-infos">
                  <span class="user-img">
                    <img
                      src="@/assets/img/profiles/avatar-02.jpg"
                      class="rounded-circle"
                      alt=""
                    />
                  </span>
                  <div class="user-info">
                    <h6 v-if="userLogin !== null">{{ userLogin.full_name }}</h6>
                    <p v-if="companyInfo && (companyInfo.company_id != 0)">{{  companyInfo.class }} Member</p>
                    <p v-else> Super Admin</p>
                  </div>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-end emp">
                <!-- <router-link class="dropdown-item" to="/customer-profile">
                  <i class="feather-user me-2"></i> Profile
                </router-link> -->
            
                <a class="dropdown-item" @click="logout">
                  <i class="feather-log-out me-2"></i> Logout
                </a>
              </div>
            </li>
            <!-- /User Menu -->
          </ul>
        </nav>
      </div>
      <!-- <div v-if="companyInfo && (companyInfo.company_id != 0)" style="text-align: center;">
        <p v-if="isTrialExpired"><strong>Your trial period has expired. Please upgrade to continue.</strong></p>
        <p v-else-if="isPaymentDue"><strong>Your payment due date has passed. Please make a payment to continue.</strong></p>
        <p v-else-if="isEntryLimitReached"><strong>You have reached your entry limit.</strong></p>
        <p v-else>
          <span v-if="timeUntilExpiration"><strong>Time until expiration: {{ timeUntilExpiration }}</strong></span>
          <span v-if="entryLimitCount"><strong>Entries used: {{ companyInfo.entries_count }}/{{ companyInfo.entry_limit }}</strong></span>
        </p>
      </div> -->
    </header>
  </template>
  
  <script>
  export default {
    data() {
      return {
        userLogin: null,
        trialStartDate: null,
        trialDuration: null,
        entryLimit: null,
        entriesCount: null,
        companyClass: null,
        dueDate: null,
        companyInfo: null,
        orderDraf : null,
      };
    },
    computed: {
      isGuide() {
        // console.log(this.$store.state.guide.isGuide, "isguide")
        return this.$store.state.guide.isGuide;
      },
      isDriver() {
        // console.log(this.$store.state.user.isDriver, "isdriver")
        return this.$store.state.user.isDriver;
      },
      isTrialExpired() {
        if (this.companyClass === 'trial' && this.trialStartDate && this.trialDuration) {
          const trialEndDate = new Date(this.trialStartDate);
          trialEndDate.setDate(trialEndDate.getDate() + this.trialDuration);
          return new Date() > trialEndDate;
        }
        return false;
      },
      isPaymentDue() {
        if (this.dueDate) {
          return new Date() > new Date(this.dueDate);
        }
        return false;
      },
      isEntryLimitReached() {
        return this.entriesCount >= this.entryLimit;
      },
      timeUntilExpiration() {
        if (this.companyClass === 'trial' && this.trialStartDate && this.trialDuration) {
          const trialEndDate = new Date(this.trialStartDate);
          trialEndDate.setDate(trialEndDate.getDate() + this.trialDuration);
          const now = new Date();
          if (trialEndDate > now) {
            const diff = trialEndDate - now;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            return `${days} days and ${hours} hours`;
          }
        } else if (this.companyClass !== 'trial' && this.dueDate) {
          const dueDate = new Date(this.dueDate);
          const now = new Date();
          if (dueDate > now) {
            const diff = dueDate - now;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            return `${days} days and ${hours} hours`;
          }
        }
        return null;
      },
      entryLimitCount() {
        return this.entriesCount >= 0 && this.entryLimit > 0;
      },
    },
    methods: {
      toggleSidebar() {
        const body = document.body;
        body.classList.toggle("menu-opened");
      },
      closeMenu() {
        const body = document.body;
        body.classList.remove("menu-opened");
      },
      logout() {
        let loading = this.$loading.show();
        this.$store.dispatch("auth/logout").then(() => {
            loading.hide();
            this.$router.replace({ path: "mari/login" });
        });
      },
      async fetchCompanyInfo() {
          try {
              const response = await this.$store.dispatch("company/getCompanyInfo");
              this.trialStartDate = response.data.trial_start_date;
              this.trialDuration = response.data.trial_duration;
              this.entryLimit = response.data.entry_limit;
              this.entriesCount = response.data.entries_count;
              this.companyClass = response.data.class;
              this.dueDate = response.data.due_date;
              this.companyInfo = response.data;
          } catch (error) {
              console.error('Failed to fetch company info:', error);
          }
      },
      async getData() {
        const loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("order/getDraf");
          this.orderDraf = resp.data.map((item) => {
              return {
                  noOrder: item.no_order
              };
          });
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching order data:", e);
        } finally {
          loading.hide();
        }
      },
    },
    async mounted() {
      const expiresAt = localStorage.getItem('expiresAt');

      // Check if token is expired
      if (expiresAt && new Date().getTime() > expiresAt * 1000) {
        // Token is expired, handle accordingly
        console.error('Token is expired. Redirecting to login.');
        localStorage.removeItem('token');
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('user');
        this.$router.push('/login'); // Redirect to login page
        return;
      }
      const userData = localStorage.getItem('user');
      const user = JSON.parse(userData);
      this.userLogin = user;
      await this.fetchCompanyInfo();
      await this.getData();
    },
  };
  </script>
  