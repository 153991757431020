<template>
    <div class="modal fade custom-modal" id="incentive-driver" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Insentif Driver</h5>
            <button
              id="close-absen"
              type="button"
              ref="closeButtonIncentive"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div v-if="data" class="modal-body pt-0">
            <p class="mb-0 mt-3 text-uppercase text-muted fw-bold"><small>Rute perjalanan</small></p>
            <p class="mb-1">{{ data.rute_perjalanan }}</p>
            <p class="m-0 text-uppercase text-muted fw-bold"><small>Status mudi</small></p>
            <p class="mb-1">{{ data.status_mudi }}</p>
            <p class="m-0 text-uppercase text-muted fw-bold"><small>Product</small></p>
            <div v-if="products && products.length > 0">
                <span v-for="product of products" :key="product" class="badge bg-primary me-1">{{ product }}</span>
            </div>
            <form class="mt-2" @submit.prevent="createSubmit">
                <div class="d-flex">
                    <div class="mb-3">
                        <label class="form-label">Nominal <span style="color:red">*</span></label>
                        <input class="form-control" type="text" v-model="formattedCreateValues.amount" @input="onInput('formCreate', 'amount', $event)">
                    </div>

                </div>
                  <div class="mb-3">
                    <label class="form-label">Metode Pembayaran <span style="color:red">*</span></label>
                    <select class="form-select" v-model="formCreate.payment_method">
                        <option value="Transfer">Transfer</option>
                        <option value="Cash">Cash</option>
                        <option value="Debit/Credit">Debit/Credit</option>
                    </select>
                  </div>
                <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: null,
            products: [],
            formCreate: {},
            formattedCreateValues: {}
        }
    },
    props: {
        editData: Object
    },
    watch: {
        editData: {
            immediate: true,
            async handler(newValue) {
                this.products = []
                if (newValue) {
                  const loading = this.$loading.show() 
                  try {
                    const result = await this.$store.dispatch('driver/getIncentiveDriver', newValue.id)
                    if (result.data) {
                      this.formCreate.amount = result.data.amount
                      this.formattedCreateValues.amount = this.formatCurrency(result.data.amount)
                      this.formCreate.payment_method = result.data.payment_method
                    }
                  } catch (e) {
                    this.$toast.error(e)
                  } finally {
                    loading.hide()
                  }

                  if (newValue.mobil == 1) {
                      this.products.push('MOBIL')
                  }
                  if (newValue.supir == 1) {
                      this.products.push('SUPIR')
                  }
                  if (newValue.bbm == 1) {
                      this.products.push('BBM')
                  }
                  if (newValue.tol == 1) {
                      this.products.push('TOL')
                  }
                  if (newValue.parkir == 1) {
                      this.products.push('PARKIR')
                  }
                  if (newValue.makan_supir == 1) {
                      this.products.push('MAKAN SUPIR')
                  }
                  if (newValue.inap_supir == 1) {
                      this.products.push('INAP SUPIR')
                  }
                  this.data = newValue 
                }
                
            } 
        }
    },
    methods: {
      async createSubmit() {
        if (!this.data) {
          return
        }

        const data = {
          ...this.formCreate,
          report_driver_id: this.data.id
        }

        const loading = this.$loading.show()
        try {
          await this.$store.dispatch('driver/createIncentiveDriver', data)
        } catch (e) {
          this.$toast.error(e)
        } finally {
          loading.hide()
          this.$refs.closeButtonIncentive.click()
          this.$router.go()
        }
      },
      formatCurrency(value) {
          if (!value && value !== 0) return '';
          // Convert value to a string
          let stringValue = value.toString();
          // Split the string into integer and decimal parts
          let parts = stringValue.split('.');
          // Format the integer part with thousand separators
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          // Join the parts and return the formatted value
          return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
        },
      unformatCurrency(value) {
        return value.replace(/\./g, '').replace(',', '.');
      },
      onInput(form, field, event) {
        const rawValue = event.target.value;
        const unformattedValue = this.unformatCurrency(rawValue);
        const numberValue = parseFloat(unformattedValue);
        const formattedValue = this.formatCurrency(numberValue);

        if (form === 'formCreate') {
          this.formCreate[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedCreateValues[field] = formattedValue;
        } else if (form === 'formEdit') {
          this.formEdit[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedEditValues[field] = formattedValue;
        }
      }
    }
}
</script>
