<template>
    <!-- Add Acces -->
    <div class="modal fade" id="add-brand" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Brand Kendaraan</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="mb-3">
                <label class="form-label">Nama Brand</label>
                <input type="text" class="form-control" v-model="formCreate.name"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Logo</label>
                    <div class="profile-upload-content">
                        <!-- company logo here -->
                        <img v-if="logo" :src="logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                        <!-- company logo here -->

                        <!-- upload file form logo here -->
                        <div class="profile-upload-btn">
                            <div class="profile-upload-file">
                            <input
                                type="file"
                                class="form-control"
                                ref="uploadFieldAttachment"
                                id="imgInp"
                                @change="selectFileAttachment"
                            />
                            <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                            </div>
                        </div>
                        <!-- upload file form logo here -->
                        <div class="profile-upload-para">
                            <p>
                            *ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                        File yang diizinkan .png dan .jpg.
                            </p>
                        </div>
                    </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Acces -->
  
    <!-- Edit Acces -->
    <div class="modal fade" id="edit-brand" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Brand Kendaraan</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="mb-3">
                <label class="form-label">Nama Brand</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Menu</label>
                    <div class="profile-upload-content">
                        <!-- company logo here -->
                        <img v-if="(formEdit !== undefined) && (formEdit.file !== '')" :src="formEdit.file" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                        <!-- company logo here -->
                        <!-- upload file form logo here -->
                        <div class="profile-upload-btn">
                            <div class="profile-upload-file">
                            <input
                                type="file"
                                class="form-control"
                                ref="uploadFieldAttachment"
                                id="imgInp"
                                @change="selectFileAttachment"
                            />
                            <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                            </div>
                        </div>
                        <!-- upload file form logo here -->
                        <div class="profile-upload-para">
                            <p>
                            *ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                        File yang diizinkan .png dan .jpg.
                            </p>
                        </div>
                    </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Acces -->
  
    <div class="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Brand Kendaraan ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {},
        dataRole: [],
        dataMenu: [],
        logo: '',
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object,
      menuData: {
        type: Array,
        default: () => []
      },
      roleData: {
        type: Array,
        default: () => []
      },
    },
    emits: ['edit-brand'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
    },
    methods: {
      validateFile(file, allowedFileTypes, maxFileSize) {
          if (!allowedFileTypes.includes(file.type)) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
              });
              return false;
          }

          if (file.size > maxFileSize) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
              });
              return false;
          }

          return true;
      },
    // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      editSubmit() {
        this.formEdit.file = this.logo;
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("brand/updateBrand", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {}
              this.logo = '';
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createSubmit() {
        this.formCreate.file = this.logo;
        var loading = this.$loading.show()
        this.$store
          .dispatch("brand/addBrand", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {}
              this.logo = '';
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteData() {
        // Perform deletion using this.deleteItemId
        console.log('Deleting item with ID:', this.deleteItemId);
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("brand/deleteBrand", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
    },
  };
  </script>
  