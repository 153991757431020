<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Add Membership</h5>
        </div>
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-groupheads">
                <h2>Profile Picture</h2>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="form-group">
                <label>Plan Name</label>
                <b-form-input
                  type="text"
                  class="form-control"
                  placeholder="Enter Plan Name "
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label>Monthly Price</label>
                <b-form-input
                  type="text"
                  class="form-control"
                  placeholder="Enter Plan Price"
                />
                <h6 class="info-set">
                  <i
                    class="feather feather-info me-2"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="content"
                  ></i
                  >Set 0 for free
                </h6>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label>Yearly Price</label>
                <b-form-input
                  type="text"
                  class="form-control"
                  placeholder="Enter Plan Price"
                />
                <h6 class="info-set">
                  <i
                    class="feather feather-info me-2"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="content"
                  ></i
                  >Set 0 for free
                </h6>
              </div>
            </div>
          </div>
          <div class="content-page-header mt-3">
            <h5>Addon Settings</h5>
          </div>
          <div class="location-set">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="location-setcontent">
                  <h5>Services</h5>
                  <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                </div>
              </div>
              <div class="col-lg-5 col-12">
                <div class="form-group mb-0">
                  <b-form-input type="text" class="form-control" placeholder="0-100" />
                  <div class="filter-checkbox mt-2">
                    <ul>
                      <li>
                        <label class="checkboxs">
                          <input type="checkbox" />
                          <span><i></i></span>
                          <b class="check-content">Unlimited</b>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-1 col-12">
                <div class="active-switch text-end">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="location-set">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="location-setcontent">
                  <h5>Appointments</h5>
                  <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                </div>
              </div>
              <div class="col-lg-5 col-12">
                <div class="form-group mb-0">
                  <b-form-input type="text" class="form-control" placeholder="0-100" />
                  <div class="filter-checkbox mt-2">
                    <ul>
                      <li>
                        <label class="checkboxs">
                          <input type="checkbox" />
                          <span><i></i></span>
                          <b class="check-content">Unlimited</b>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-1 col-12">
                <div class="active-switch text-end">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="location-set">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="location-setcontent">
                  <h5>Staffs</h5>
                  <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                </div>
              </div>
              <div class="col-lg-5 col-12">
                <div class="form-group mb-0">
                  <b-form-input type="text" class="form-control" placeholder="0-100" />
                  <div class="filter-checkbox mt-2">
                    <ul>
                      <li>
                        <label class="checkboxs">
                          <input type="checkbox" />
                          <span><i></i></span>
                          <b class="check-content">Unlimited</b>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-1 col-12">
                <div class="active-switch text-end">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="location-set">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="location-setcontent">
                  <h5>Gallery</h5>
                  <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                </div>
              </div>
              <div class="col-lg-5 col-12">
                <div class="form-group mb-0">
                  <b-form-input type="text" class="form-control" placeholder="0-100" />
                  <div class="filter-checkbox mt-2">
                    <ul>
                      <li>
                        <label class="checkboxs">
                          <input type="checkbox" />
                          <span><i></i></span>
                          <b class="check-content">Unlimited</b>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-1 col-12">
                <div class="active-switch text-end">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="location-set">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="location-setcontent">
                  <h5>Additional Service</h5>
                  <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                </div>
              </div>
              <div class="col-lg-5 col-12">
                <div class="form-group mb-0">
                  <b-form-input type="text" class="form-control" placeholder="0-100" />
                  <div class="filter-checkbox mt-2">
                    <ul>
                      <li>
                        <label class="checkboxs">
                          <input type="checkbox" />
                          <span><i></i></span>
                          <b class="check-content">Unlimited</b>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-1 col-12">
                <div class="active-switch text-end">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-groupheads d-flex justify-content-between">
            <h2>Status</h2>
            <div class="active-switch text-end">
              <label class="switch">
                <input type="checkbox" checked="" />
                <span class="sliders round"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="btn-path">
              <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
              <b-button type="submit" to="/admin/membership" variant="primary"
                >Save Changes</b-button
              >
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>

  <!--change email-modal -->
  <div
    class="modal fade"
    id="add-faq"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Create FAQ’s</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" placeholder="Enter FAQ Title" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Category</label>
                <vue-select :options="Category" placeholder="Select Category" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Current Password</label>
                <textarea
                  class="form-control"
                  placeholder="Enter Your Details"
                ></textarea>
              </div>
            </div>
            <div class="col-12">
              <div class="form-groupheads d-flex d-flex justify-content-between">
                <h2>Status</h2>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <b-button type="button" variant="primary">Submit</b-button>
          <b-button type="button" variant="secondary" data-bs-dismiss="modal"
            >Close</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Category: ["Select Category", "Category 1", "Category 2"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/membership");
    },
  },
};
</script>
