<template>
  <div class="col-lg-4 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="sidebar-widget widget">
        <div class="service-amount">
          <span>$45</span>
        </div>
        <div class="service-book">
          <a
            href="javascript:;"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#service-select"
          >
            Book Service
          </a>
        </div>
      </div>
      <div class="card provider-widget clearfix">
        <div class="card-body">
          <h5 class="card-title">Service Provider</h5>
          <div class="about-author d-flex align-items-center">
            <div class="about-provider-img">
              <a href="javascript:void(0);">
                <img
                  class="img-fluid rounded-circle"
                  alt="image"
                  src="@/assets/img/provider/provider-01.jpg"
                />
              </a>
            </div>
            <div class="provider-details">
              <a href="javascript:void(0);" class="ser-provider-name">Demo Provider</a>
              <p class="last-seen"><i class="fas fa-circle online"></i> Online</p>
              <p class="text-muted mb-1">Member Since Sep 2023</p>
            </div>
          </div>
          <hr />
          <div class="provider-info">
            <p class="mb-1">
              <i class="far fa-envelope"></i>
              <a href="javascript:;"><span>demoprovider@example.com</span></a>
            </p>
            <p class="mb-0"><i class="fas fa-phone-alt"></i> 91-9988776655</p>
          </div>
        </div>
      </div>
      <div class="booking-report-provider">
        <a href="javascript:;">
          <h6>
            <span> <i class="fas fa-bug me-1"></i> </span> Report this provider
          </h6>
        </a>
      </div>
      <div class="card available-widget widget-book-detail">
        <div class="card-body">
          <h5 class="card-title">Service Availability</h5>
          <ul>
            <li><span>Monday</span>9:30 AM - 7:00 PM</li>
            <li><span>Tuesday</span>9:30 AM - 7:00 PM</li>
            <li><span>Wednesday</span>9:30 AM - 7:00 PM</li>
            <li><span>Thursday</span>9:30 AM - 7:00 PM</li>
            <li><span>Friday</span>9:30 AM - 7:00 PM</li>
            <li><span>Saturday</span>9:30 AM - 7:00 PM</li>
            <li><span>Sunday</span>9:30 AM - 7:00 PM</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
