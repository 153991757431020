<template>
    <div class="bg-img">
        <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg1" />
        <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg2" />
        <img src="@/assets/img/bg/feature-bg-03.png" alt="img" class="bgimg3" />
    </div>

    <div class="content">
        <div class="container">
            <div class="row">
                <!-- Service Profile -->
                <div class="col-md-8">
                    <div class="serv-profile">
                        <h2>Car Repair Services</h2>
                        <ul>
                            <li>
                                <span class="badge">Car Wash</span>
                            </li>
                            <li class="service-map"><i class="feather-map-pin"></i> Alabama, USA</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="serv-action">
                        <ul>
                            <li>
                                <a href="javascript:;"><i class="feather-heart"></i></a>
                            </li>
                            <li>
                                <a href="javascript:;" data-bs-toggle="tooltip" title="Share"><i class="feather-share-2"></i></a>
                            </li>
                            <li>
                                <a href="javascript:;"><i class="feather-printer"></i></a>
                            </li>
                            <li>
                                <a href="javascript:;"><i class="feather-download"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="service-gal">
                        <div class="row gx-2">
                            <div class="col-md-9">
                                <div class="service-images big-gallery" v-for="(image, index) in galleryImages" :key="index"
                                    @click="() => show(index)">
                                    <img :src="require(`@/assets/img/services/${image.src}`)" class="img-fluid" alt="img">
                                    <a href="javascript:;" data-fancybox="gallery" class="btn btn-show"><i
                                            class="feather-image me-2"></i>Show all photos</a>
                                </div>
                            </div>
                            <service-lightbox></service-lightbox>
                        </div>
                    </div>
                </div>
                <!-- /Service Profile -->
            </div>

            <div class="row">
                <!-- Service Details -->
                <servicedetails></servicedetails>
                <!-- /Service Details -->

                <div class="col-lg-4 theiaStickySidebar">
                    <servicessticksidebar></servicessticksidebar>

                    <!-- /Service Availability -->
                </div>
            </div>
        </div>
    </div>

    <vue-easy-lightbox :visible="visible" :index="index"
        :imgs="galleryImages.map((image) => ({ src: require(`@/assets/img/services/${image.src}`) }))"
        @hide="visible = false" @on-prev="handlePrev" @on-next="handleNext"></vue-easy-lightbox>
</template>
  
<script>
import ServiceContentGallery from "@/assets/json/servicecontentgallery.json";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
    data() {
        return {
            ServiceContentGallery: ServiceContentGallery,
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
                loop: true,
                margin: 24,
            },
            breakpoints: {
                575: {
                    itemsToShow: 1,
                    snapAlign: "center",
                },
                // 700px and up
                767: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                // 991px and up
                991: {
                    itemsToShow: 3,
                    snapAlign: "center",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
            },
            visible: false,
            index: 0,
            galleryImages: [
                {
                    id: 1,
                    src: "service-ban-01.jpg"
                },
            ],

        };
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    methods: {
        show(index) {
            this.index = index;
            this.visible = true;
        },
        handlePrev(oldIndex, newIndex) {
            console.log("when prev btn click or user swipe right ----");
            console.log("oldIndex of imgs:", oldIndex);
            console.log("newIndex of imgs:", newIndex);
        },
        handleNext(oldIndex, newIndex) {
            console.log("when next btn click or user swipe left ----");
            console.log("oldIndex of imgs:", oldIndex);
            console.log("newIndex of imgs:", newIndex);
        },
    },
};
</script>
  
<style>
.gallery-widget {
    margin-right: 24px;
}
</style>
  