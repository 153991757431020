<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <div class="row">
          <div class="col-lg-12">
            <div class="content-page-header content-page-headersplit">
              <h5>Widgets</h5>
              <div class="list-btn">
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="btn btn-primary"
                      >Manage with Live Preview</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8 col-sm-12">
                <div class="form-groupheads">
                  <h2>Available Widgets</h2>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="Text" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control bg-white"
                        value="Categories"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control bg-white"
                        value="Custom Menu"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control bg-white"
                        value="Recent Post"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="Pages" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="Calendar" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="Search" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="Image" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control bg-white"
                        value="Recent Comments"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="Archives" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control bg-white"
                        value="Custom HTML"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="Video" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="Audio" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="Meta" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control bg-white"
                        value="Tag Cloud"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input type="text" class="form-control bg-white" value="RSS" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-12">
                <div class="menu-set">
                  <div class="menu-setheader">
                    <a
                      data-bs-toggle="collapse"
                      href="#customs"
                      role="button"
                      aria-expanded="false"
                      aria-controls="custom"
                      >Footer Widgets<i
                        class="fa fa-angle-down float-end"
                        aria-hidden="true"
                      ></i
                    ></a>
                  </div>
                  <div class="menu-setcontent collapse show" id="customs">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <vue-select :options="Search" placeholder="Search" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <vue-select :options="Post" placeholder="Recent Post" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <vue-select :options="Categories" placeholder="Categories" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <vue-select :options="Services" placeholder="Services" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="menu-set">
                  <div class="menu-setheader">
                    <a
                      data-bs-toggle="collapse"
                      href="#widgets"
                      role="button"
                      aria-expanded="false"
                      aria-controls="custom"
                      >Sidebar Widgets<i
                        class="fa fa-angle-down float-end"
                        aria-hidden="true"
                      ></i
                    ></a>
                  </div>
                  <div class="menu-setcontent collapse show" id="widgets">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <vue-select :options="Video" placeholder="Video" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <vue-select :options="Pages" placeholder="Pages" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <vue-select :options="Text" placeholder="Text" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <vue-select :options="Calendar" placeholder="Calendar" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Search: ["Search", "Search view"],
      Post: ["Recent Post", "Post"],
      Categories: ["Categories", "Categories 1"],
      Services: ["Services", "Services 1"],
      Video: ["Video", "Image"],
      Pages: ["Pages", "Post"],
      Text: ["Text", "Text 1"],
      Calendar: ["Calendar", "Calendar 1"],
    };
  },
};
</script>
