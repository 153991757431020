<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Add Announcements</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Subject</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter Subject"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>State Name</label>
                    <textarea class="form-control" placeholder="Enter Message"></textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Send To</label>
                    <ul class="custom-radiosbtn">
                      <li>
                        <label class="radiossets"
                          >Select all
                          <input type="radio" checked="checked" name="radio" />
                          <span class="checkmark-radio"></span>
                        </label>
                      </li>
                      <li>
                        <label class="radiossets"
                          >User
                          <input type="radio" name="radio" />
                          <span class="checkmark-radio"></span>
                        </label>
                      </li>
                      <li>
                        <label class="radiossets"
                          >Provider
                          <input type="radio" name="radio" />
                          <span class="checkmark-radio"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" variant="primary">Save Changes</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/announcements");
    },
  },
};
</script>
