<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
        <div class="page-wrapper page-settings">
            <div class="content">
                <!-- <div class="content container-fluid"> -->
                <!-- Page Header -->
                <div class="page-header">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="provider-subtitle">
                            <h6>Jadwal Order</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Page Header -->

                <!-- calendar -->
                <div class="row">
                    <div class="col-md-12">
                    <!-- Information Section -->
                    <div class="information d-flex justify-content-center align-items-center">
                    <div class="legend-item">
                        <div class="legend-color orange" style="background-color: orange; border: 1px solid black; width: 40px; height: 20px;"></div>
                        <span class="legend-text">Booking</span>
                    </div>
                    <div class="legend-item">
                        <div class="legend-color blue" style="background-color: blue; border: 1px solid black; width: 40px; height: 20px;"></div>
                        <span class="legend-text">Proses</span>
                    </div>
                    <div class="legend-item">
                        <div class="legend-color green" style="background-color: green; border: 1px solid black; width: 40px; height: 20px;"></div>
                        <span class="legend-text">Finish</span>
                    </div>
                    <!-- Add more legend items for additional status types if needed -->
                    </div>
                    <!-- /Information Section -->
                    </div>
                    <div class="col-md-12">
                    <FullCalendar
                        :options="calendarOptions"
                        :events="events"
                        id="calendar-book"
                    ></FullCalendar>
                    </div>
                </div>
                <!-- /calendar -->
                <!-- </div> -->
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import FullCalendar from "@fullcalendar/vue3";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import timeGridPlugin from "@fullcalendar/timegrid";
  import interactionPlugin from "@fullcalendar/interaction";
  export default {
    components: {
      FullCalendar,
    },
    data() {
      return {
        events: [],
        calendarOptions: {
            plugins: [
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin, // needed for dateClick
            ],
            events: [],
            initialView: "dayGridMonth",
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
            weekends: true,
      },
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            dateStart: '',
            dateEnd: '',
            jadwal: true
        },
      };
    },
    methods: {
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("order/getOrder", this.params)
                .then((resp) => {
                    const eventsArray = resp.data.data.map((item) => {
                        const sDate = new Date(item.starting_date);
                        const fDate = new Date(item.finish_date);
                        let color = ''; // Default color

                        // Set color based on status
                        switch (item.status.toLowerCase()) {
                            case 'booking':
                                color = 'orange';
                                break;
                            case 'proses':
                                color = 'blue';
                                break;
                            case 'finish':
                                color = 'green';
                                break;
                            default:
                                color = ''; // Default color if status doesn't match
                                break;
                        }
                        return {
                            title: item.no_order,
                            start: `${sDate.getFullYear()}-${(sDate.getMonth() + 1).toString().padStart(2, '0')}-${sDate.getDate().toString().padStart(2, '0')}`,
                            end: `${fDate.getFullYear()}-${(fDate.getMonth() + 1).toString().padStart(2, '0')}-${fDate.getDate().toString().padStart(2, '0')}`,
                            color: color,
                        };
                    });
                    this.events = eventsArray;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
    },
    mounted() {
        this.getData();
    },
    
    watch: {
        // Watch for changes in the events data property and update the calendarOptions accordingly
        events: {
            handler(newEvents) {
            this.calendarOptions.events = newEvents;
            },
            deep: true, // Ensure deep watching to detect changes in nested properties of events
        },
    },
  };
  </script>
  