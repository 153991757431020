<template>
  <!-- Work Section -->
  <section class="work-section pt-0">
    <div class="container">
      <div class="row aos" data-aos="fade-up">
        <div class="col-md-12">
          <div class="offer-paths">
            <div class="offer-pathimg">
              <img src="@/assets/img/offer.png" alt="img" />
            </div>
            <div class="offer-path-content">
              <h3>We Are Offering 14 Days Free Trial</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore minim veniam, quis nostrud
                exercitation ullamco magna aliqua.
              </p>
              <router-link to="/free-trial" class="btn btn-primary btn-views"
                >Try 14 Days Free Trial<i class="feather-arrow-right-circle"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading aos" data-aos="fade-up">
            <h2>How It Works</h2>
            <p>Aliquam lorem ante, dapibus in, viverra quis</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="work-box aos" data-aos="fade-up">
            <div class="work-icon">
              <span>
                <img src="@/assets/img/icons/work-icon.svg" alt="img" />
              </span>
            </div>
            <h5>Choose What To Do</h5>
            <p>
              Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore magna
              aliqua.
            </p>
            <h4>01</h4>
          </div>
        </div>
        <div class="col-md-4">
          <div class="work-box aos" data-aos="fade-up">
            <div class="work-icon">
              <span>
                <img src="@/assets/img/icons/find-icon.svg" alt="img" />
              </span>
            </div>
            <h5>Find What You Want</h5>
            <p>
              Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore magna
              aliqua.
            </p>
            <h4>02</h4>
          </div>
        </div>
        <div class="col-md-4">
          <div class="work-box aos" data-aos="fade-up">
            <div class="work-icon">
              <span>
                <img src="@/assets/img/icons/place-icon.svg" alt="img" />
              </span>
            </div>
            <h5>Amazing Places</h5>
            <p>
              Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore magna
              aliqua.
            </p>
            <h4>03</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Work Section -->

  <!-- Service Section -->
  <section class="service-section">
    <div class="container">
      <div class="section-heading">
        <div class="row">
          <div class="col-md-6 aos" data-aos="fade-up">
            <h2>Most Popular Services</h2>
            <p>Explore the greates our services. You won’t be disappointed</p>
          </div>
          <div class="col-md-6 text-md-end aos" data-aos="fade-up">
            <div class="owl-nav mynav1"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel popular-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexpopularservice" :key="item.id">
                <div class="service-widget">
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="fav-item">
                      <router-link to="/categories"
                        ><span class="item-cat">{{ item.badge }}</span></router-link
                      >
                      <a
                        href="javascript:void(0)"
                        class="fav-icon"
                        @click="toggleSelected"
                      >
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                    <div class="item-info">
                      <router-link to="/providers"
                        ><span class="item-img"
                          ><img
                            :src="require(`@/assets/img/profiles/${item.avatar}`)"
                            class="avatar"
                            alt="" /></span
                      ></router-link>
                    </div>
                  </div>
                  <div class="service-content">
                    <h3 class="title">
                      <router-link to="/service-details">{{ item.title }}</router-link>
                    </h3>
                    <p>
                      <i class="feather-map-pin"></i>{{ item.country
                      }}<span class="rate"
                        ><i class="fa-solid fa-star filled me-1"></i>4.9</span
                      >
                    </p>
                    <div class="serv-info">
                      <h6>
                        {{ item.offer }}<span class="old-price">{{ item.price }}</span>
                      </h6>
                      <router-link to="/service-details" class="btn btn-book"
                        >Book Now</router-link
                      >
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
        <div class="btn-sec aos" data-aos="fade-up">
          <router-link to="/search" class="btn btn-primary btn-view"
            >View All<i class="feather-arrow-right-circle"></i
          ></router-link>
        </div>
      </div>
    </div>
  </section>
  <!-- /Service Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexpopularservice from "@/assets/json/indexpopularservice.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexpopularservice: indexpopularservice,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mounted() {
    document.querySelectorAll(".fav-icon").forEach(function (element) {
      element.addEventListener("click", function () {
        element.classList.toggle("selected");
      });
    });
  },
  methods: {
    toggleSelected(event) {
      const element = event.target;
      element.classList.toggle("selected");
    },
  },
};
</script>
