import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getVendorDetail(_,id){
            return new Promise((resolve, reject) => {
                axios.get(`/vendor/show/${id}`).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        getInvoicePdfData(_, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/x/get-vendor-invoice-pdf-data/${id}`).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getInvoiceVendor(_,id){
            return new Promise((resolve, reject) => {
                axios.get(`/invoice-vendor/${id}`, { responseType: 'blob' }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        getInvoiceVendorJson(_, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/x/get-total-pay-vendor/${id}`).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        payInvoiceVendor(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/x/pay-invoice-vendor', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getVendor(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/vendor', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getVendorAll() {
            return new Promise((resolve, reject) => {
                axios.get('/vendor/getAll').then(resp => {
                resolve(resp.data);
                }).catch(e => {
                reject(e);
                });
            });
        },

        addVendor(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/vendor/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateVendor(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('vendor/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteVendor(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('vendor/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }



    },
    getters: {

    }

}
