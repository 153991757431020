<template>
  <div class="main-wrapper">
    <mari-layout></mari-layout>
    <div class="page-wrapper background" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
      <div class="content">
		
        <div class="container">			
          <div class="section-heading">
            <div class="row">
              <div class="col-md-6 aos" data-aos="fade-up">
                <div class="user-infos">
                  <span class="user-img">
                    <img
                      src="@/assets/img/profiles/avatar-02.jpg"
                      class="rounded-circle"
                      alt=""
                    />
                  </span>
                  <div class="user-info">
                    <p>Selamat Datang!</p>
                    <h5 v-if="userLogin !== null">{{ userLogin.full_name }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-3" v-for="(item, index) in menuItems" :key="index">
              <router-link :to="item.route" class="feature-box aos" data-aos="fade-up">
                <div class="feature-icon">
                  <span>
                    <!-- Menggunakan interpolasi string untuk membuat nama file gambar dinamis -->
                    <img :src="require(`@/assets/img/mari/menu/${item.icon}.svg`)" alt="img">
                  </span>
                </div>
                <h5>{{ item.title }}</h5>
                <div class="feature-overlay">
                  <img src="@/assets/img/mari/menu/slide.svg" alt="img">
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mari-footer></mari-footer>
  </div>
</template>

<script scope>

export default {
  data() {
    return {
      menuItems: [
        { title: 'Monitoring', icon: 'monitoring', route: '/mari/monitoring' }, 
        { title: 'Kendaraan', icon: 'kendaraan', route: '/mari/vehicle' },
        { title: 'Finance', icon: 'finance', route: '/mari/finance-menu' },
        { title: 'Profil', icon: 'pengaturan', route: '/mari/pengaturan' },
        { title: 'Absen Driver', icon: 'pengaturan', route: '/mari/absen-driver' },
        // { title: 'Order', icon: 'order', route: '/mari/order' },
        // { title: 'Pelanggan', icon: 'pelanggan', route: '/mari/pelanggan' },
        // { title: 'Subscribe', icon: 'berlangganan', route: '/mari/subscribe' },
        // { title: 'Bantuan', icon: 'bantuan', route: '/mari/help' },
        
      ],
      userLogin: null,
      guideStep: 1,
      backgroundImage: require(`@/assets/img/mari/background.svg`)
    };
  },
  methods: {
    reloadRoute() {
      this.$router.push({ path: this.$route.path });
    },
    checkUserGuide() {
      console.log("kesini")
      let loading = this.$loading.show();
      this.$store.dispatch("guide/getGuide", { id: this.userLogin.company_id }).then(
        (data) => {
            loading.hide();
            if (data.status == 200) {
              const dataGuide = data.data;
              if (dataGuide.is_profile == 1) {
                this.guideStep++;
              }
              if (dataGuide.is_product == 1) {
                this.guideStep++;
              }
              if (dataGuide.is_vehicle == 1) {
                this.guideStep++;
              }
              if (dataGuide.is_user == 1) {
                this.guideStep++;
              }
              if (dataGuide.is_customer == 1) {
                this.guideStep++;
              }
              this.$nextTick(() => {
                if (this.guideStep < 6) {
                  console.log(true, "kadie")
                  this.$store.commit('guide/setGuide', true);
                  this.$router.replace({ path: "/mari/guide" });
                } else {
                  this.$store.commit('guide/setGuide', false);
                  this.$router.replace({ path: "/mari" });
                }
              });
            }
        },
        (error) => {
          console.log(error);
          loading.hide();
        }
      );
    },
  },
  mounted() {
    const userData = localStorage.getItem('user');
		const user = JSON.parse(userData);
		this.userLogin = user;
    // console.log(this.userLogin);
    // this.checkUserGuide();
  },
};
</script>

<style scoped>
  .feature-icon span {
    background: #f7f7ff00;
  }

  .background {
    /* Full height */
    height: 100%; 

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
