import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
      printInvoicePdf(_, id) {
        return new Promise((resolve, reject) => {
            axios.get(`/x/create-invoice-pdf/${id}`, { responseType: 'blob' }).then((resp) => {
              resolve(resp.data)
            }).catch((err) => {
              reject(err)
            })
          })
      },
      getInvoicePdfData(_, id) {
        return new Promise((resolve, reject) => {
            axios.get(`/x/get-finance-invoice-pdf-data/${id}`).then((resp) => {
              resolve(resp.data)
            }).catch((err) => {
              reject(err)
            })
          })
      },
      getInvoices(_, params) {
        return new Promise((resolve, reject) => {
            axios.get('/invoices', { params }).then((resp) => {
              resolve(resp.data)
            }).catch((err) => {
              reject(err)
            })
          })
      },
      createInvoice(_, data) {
        return new Promise((resolve, reject) => {
          axios.post('/x/create-invoice', data).then((resp) => {
            resolve(resp.data)
          }).catch((err) => {
            reject(err)
          })
        })
      },
      addInvoiceLine(_, data) {
        return new Promise((resolve, reject) => {
          axios.post('/x/add-invoice-line', data).then((resp) => {
            resolve(resp.data)
          }).catch((err) => {
            reject(err)
          })
        })
      },
      updateInvoiceLine(_, data) {
        return new Promise((resolve, reject) => {
          axios.post('/x/update-invoice-line', data).then((resp) => {
            resolve(resp.data)
          }).catch((err) => {
            reject(err)
          })
        })
      },
      removeInvoiceLine(_, data) {
        return new Promise((resolve, reject) => {
          axios.post('/x/remove-invoice-line', data).then((resp) => {
            resolve(resp.data)
          }).catch((err) => {
            reject(err)
          })
        })
      },
      
      deleteInvoices(_, id){
        return new Promise((resolve,reject) => {
            axios.delete('invoices/delete/'+ id).then(resp => {
                resolve(resp);
            }).catch(e => {
                reject(e)
            })
        })
      },
    },
    getters: {

    }

}
