<template>
    <div class="card add-service">
        <div class="row mt-4">
            <div class="col-12">
                <form>
                    <div class="row">
                        <div class="mb-3 form-group">
                            <div class="d-flex">
                                <div class="col-6" style="margin-right: 10px;">
                                    <label class="form-label">Nama Pelanggan</label>
                                    <input type="text" class="form-control" v-model="data.name"/>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Nik</label>
                                    <input type="text" class="form-control" v-model="data.nik"/>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 form-group">
                            <div class="d-flex">
                                <div class="col-6" style="margin-right: 10px;">
                                    <label class="form-label">Nomer Telepon (WA)</label>
                                    <input type="text" class="form-control" v-model="data.phone_number"/>
                                </div>
                                <div class="col-6">
                                    <div class="col-lg-12">
                                        <div class="split-check-title">
                                            <label class="form-label">Jenis Kelamin</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio1" name="gender" value="Laki - Laki" v-model="data.gender">
                                            <label class="form-check-label" for="inlineRadio1">Laki - Laki</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio2" name="gender" value="Perempuan" v-model="data.gender">
                                            <label class="form-check-label" for="inlineRadio2">Perempuan</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 form-group">
                            <div class="d-flex">
                                <div class="col-6" style="margin-right: 10px;">
                                    <div class="col-lg-12">
                                        <div class="split-check-title">
                                            <label class="form-label">Status</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio1" name="status" value="AKTIF" v-model="data.status">
                                            <label class="form-check-label" for="inlineRadio1">AKTIF</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio2" name="status" value="TIDAK AKTIF" v-model="data.status">
                                            <label class="form-check-label" for="inlineRadio2">TIDAK AKTIF</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="col-lg-12">
                                        <div class="split-check-title">
                                            <label class="form-label">Jenis Pelanggan</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio1" name="type" value="REGULER" v-model="data.type">
                                            <label class="form-check-label" for="inlineRadio1">REGULER</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio2" name="type" value="COOPORATE" v-model="data.type">
                                            <label class="form-check-label" for="inlineRadio2">COOPORATE</label>
                                        </div><div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio3" name="type" value="RENT TO RENT" v-model="data.type">
                                            <label class="form-check-label" for="inlineRadio3">RENT TO RENT</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio4" name="type" value="INTERNAL" v-model="data.type">
                                            <label class="form-check-label" for="inlineRadio4">INTERNAL</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Alamat</label>
                            <input type="text" class="form-control" v-model="data.address"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="field-bottom-btns">
                    <!-- <div class="field-btns">
                        <button class="btn btn-primary prev_btn" type="button"
                        @click="previousStep" :disabled="currentStep === 1"><i
                                class="fa-solid fa-arrow-left"></i>Prev</button>
                    </div> -->
                    <div class="field-btns">
                        <button class="btn btn-primary next_btn" type="button"
                        @click="save" :disabled="currentStep === totalSteps">Selanjutnya <i
                                class="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // mari
        data: {},
        logo: ''
      };
    },
    props: {
        currentStep : Object,
        totalSteps : Object,
        idCompany : Object
    },
    methods: {
      save() {
        this.data.is_member = 0;
        this.data.is_payment_termin = 0;
        this.data.multiple_order = 0;
        var loading = this.$loading.show()
        this.$store
        .dispatch("customer/addCustomer", this.data )
        .then((data) => {
            if (data.status == 201) {
                this.updateGuide();
                loading.hide()
                this.data = {};
                this.$emit('nextCustomer');
            }
        },
        (error) => {
            console.log(error)
            loading.hide();
        })
        .catch((e) => {
            loading.hide()
        })
      },
      updateGuide() {
        this.$store
        .dispatch("guide/updateGuide", { id: this.idCompany, data: { guide_step : "is_customer"} })
        .then((data) => {
            if (data.status == 200) {
                // loading.hide()
            }
        },
        (error) => {
            console.log(error)
            // loading.hide();
        })
        .catch((e) => {
            // loading.hide()
        })
      },
      previousStep() {
        this.$emit('previous');
      }
    },
  };
  </script>
  