<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Featured Services</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-features"
                  ><i class="fa fa-plus me-2"></i>Add Feature Service</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Service'">
                      <a href="javascript:void(0);" class="table-imgname">
                        <img
                          :src="
                            require(`@/assets/admin_img/services/${record.Service_img}`)
                          "
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Service }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'Services_Status'">
                      <h6 :class="record.Class">{{ record.Services_Status }}</h6>
                    </template>
                    <template v-else-if="column.key === 'Provider_Name'">
                      <div class="table-namesplit">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="
                              require(`@/assets/admin_img/customer/${record.Provider_Img}`)
                            "
                            class="me-2"
                            alt="img"
                          />
                        </a>
                        <a href="javascript:void(0);" class="table-name">
                          <span>{{ record.Provider_Name }}</span>
                          <p>{{ record.Provider_Email }}</p>
                        </a>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <h6 :class="record.Class">{{ record.Status }}</h6>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <a class="delete-table me-2" href="javascript:;">
                          <img src="@/assets/admin_img/icons/eye.svg" alt="svg" />
                        </a>
                        <a
                          class="delete-table me-2"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-features"
                        >
                          <img src="@/assets/admin_img/icons/edit.svg" alt="svg" />
                        </a>
                        <a
                          class="delete-table"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <img src="@/assets/admin_img/icons/delete.svg" alt="svg" />
                        </a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Featured Services -->
  <div
    class="modal fade"
    id="add-features"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Featured Services</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <b-form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Service</label>
                  <vue-select :options="Service" placeholder="Select Service" />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-groupheads d-flex justify-content-between">
                <h2>Status</h2>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <b-button type="submit" variant="primary" data-bs-dismiss="modal"
              >Save</b-button
            >
            <b-button type="button" variant="secondary" data-bs-dismiss="modal"
              >Close</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
  <!-- /Add Featured Services -->

  <!-- Edit Featured Services -->
  <div
    class="modal fade"
    id="edit-features"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Featured Services</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <b-form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Select</label>
                  <vue-select :options="Service" placeholder="Select Service" />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-groupheads d-flex d-flex justify-content-between">
                <h2>Status</h2>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <b-button type="submit" variant="primary" data-bs-dismiss="modal"
              >Save</b-button
            >
            <b-button type="button" variant="secondary" data-bs-dismiss="modal"
              >Close</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
  <!-- /Add Featured Services -->


  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Featured Services</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to Delete?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Services Status",
    dataIndex: "Services_Status",
    key: "Services_Status",
    sorter: {
      compare: (a, b) => {
        a = a.Services_Status.toLowerCase();
        b = b.Services_Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category",
    dataIndex: "Category",
    sorter: {
      compare: (a, b) => {
        a = a.Category.toLowerCase();
        b = b.Category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Provider Name",
    dataIndex: "Provider_Name",
    key: "Provider_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Provider_Name.toLowerCase();
        b = b.Provider_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    Services_Status: "Active",
    Category: "Computer",
    Amount: "$80",
    Provider_Img: "user-01.jpg",
    Provider_Name: "John Smith",
    Status: "Active",
    Class: "badge-active",
    Action: "",
  },
  {
    id: "2",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    Services_Status: "Active",
    Category: "Computer",
    Amount: "$80",
    Provider_Img: "user-04.jpg",
    Provider_Name: "Johnny",
    Status: "Active",
    Class: "badge-active",
    Action: "",
  },
  {
    id: "3",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    Services_Status: "Active",
    Category: "Computer",
    Amount: "$80",
    Provider_Img: "user-06.jpg",
    Provider_Name: "Robert",
    Status: "Active",
    Class: "badge-active",
    Action: "",
  },
  {
    id: "4",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    Services_Status: "Active",
    Category: "Computer",
    Amount: "$80",
    Provider_Img: "user-09.jpg",
    Provider_Name: "Sharonda",
    Status: "Active",
    Class: "badge-active",
    Action: "",
  },
  {
    id: "5",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    Services_Status: "Active",
    Category: "Computer",
    Amount: "$80",
    Provider_Img: "user-01.jpg",
    Provider_Name: "John Smith",
    Status: "Active",
    Class: "badge-active",
    Action: "",
  },
  {
    id: "6",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    Services_Status: "Active",
    Category: "Computer",
    Amount: "$80",
    Provider_Img: "user-04.jpg",
    Provider_Name: "Johnny",
    Provider_Email: " johnny@gmail.com",
    Status: "Active",
    Class: "badge-active",
    Action: "",
  },
  {
    id: "7",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    Services_Status: "Active",
    Category: "Computer",
    Amount: "$80",
    Provider_Img: "user-06.jpg",
    Provider_Name: "Robert",
    Status: "Active",
    Class: "badge-active",
    Action: "",
  },
  {
    id: "8",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    Services_Status: "Active",
    Category: "Computer",
    Amount: "$80",
    Provider_Img: "user-09.jpg",
    Provider_Name: "Sharonda",
    Status: "Active",
    Class: "badge-active",
    Action: "",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      Service: ["Select Service", "service 2"],
      Select: ["Select Service", "service 2"],
      columns,
      data,
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/services");
    },
  },
};
</script>
