<script setup>
import { ref } from 'vue';
import { onUnmounted, watch } from 'vue';

const isOpen = ref(false)

function open() {
    isOpen.value = true
}

function close() {
    isOpen.value = false
}

const unwatch = watch(isOpen, (value) => {
    if (value) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = ''
    }
})

onUnmounted(() => {
    unwatch()
})

defineExpose({
    open,
    close
})
</script>

<template>
    <div v-if="isOpen" class="dialog">
        <div class="dialog-content shadow-sm rounded p-4">
            <button @click="close" class="exit-btn btn rounded-circle shadow-sm">
                <i class="feather feather-x"></i>
            </button>
            <slot />
        </div>
    </div>
</template>

<style scoped>
.dialog {
    z-index: 1000000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(2px);
}

.dialog-content {
    background-color: white;
    position: relative;
}

.exit-btn {
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: white;
}
</style>