<template>
  <div class="col-lg-4 d-flex flex-column">
    <h6 class="user-title">Recent Transaction</h6>
    <div class="table-responsive transaction-table flex-fill">
      <table class="table mb-0">
        <tbody>
          <tr v-for="record in Dashboardtransaction" :key="record.id">
            <td>
              <div class="table-book d-flex align-items-center">
                <span class="book-img">
                  <img :src="require(`@/assets/img/icons/${record.Image}`)" alt="" />
                </span>
                <div>
                  <h6>{{ record.Field1 }}</h6>
                  <p>
                    <i class="feather-calendar"></i>{{ record.Date }}
                    <span><i class="feather-clock"></i> {{ record.Time }}</span>
                  </p>
                </div>
              </div>
            </td>
            <td class="text-end">
              <h5 class="trans-amt">{{ record.Field2 }}</h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Dashboardtransaction from "@/assets/json/dashboardtransaction.json";
export default {
  data() {
    return {
      Dashboardtransaction: Dashboardtransaction,
    };
  },
};
</script>
