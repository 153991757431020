<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-6 mx-auto">
          <div class="login-wrap">
            <div class="login-header">
              <h3>User Signup</h3>
            </div>

            <!-- Login Form -->
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <label class="col-form-label">Name</label>
                <input type="text" class="form-control" placeholder="Enter your name" />
              </div>
              <div class="form-group">
                <label class="col-form-label">E-mail</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="example@email.com"
                />
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label class="col-form-label">Phone Number</label>
                  <div class="form-group">
                    <vue-tel-input
                      mode="international"
                      class="form-control form-control-lg group_formcontrol"
                      id="phone"
                      name="phone"
                      value="(256) 789-6253"
                    ></vue-tel-input>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label d-block"
                  >Password<span class="brief-bio float-end"
                    >Must be 8 Characters at Least</span
                  ></label
                >
                <div class="pass-group">
                  <input
                    v-if="showPassword"
                    type="text"
                    class="form-control pass-input"
                    v-model="password"
                    placeholder="**********"
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder="**********"
                    v-model="password"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label class="custom_check">
                    <input type="checkbox" name="rememberme" class="rememberme" />
                    <span class="checkmark"></span>Remember Me
                  </label>
                </div>
                <div class="col-6 text-end">
                  <label class="custom_check">
                    <input type="checkbox" name="loginotp" class="loginotp" />
                    <span class="checkmark"></span>Login with OTP
                  </label>
                </div>
              </div>
              <b-button variant="primary w-100 login-btn" type="submit">Signup</b-button>
              <div class="login-or">
                <span class="or-line"></span>
                <span class="span-or">Or, log in with your email</span>
              </div>
              <div class="social-login">
                <a href="javascript:;" class="btn btn-google w-100"
                  ><img src="@/assets/img/icons/google.svg" class="me-2" alt="img" />Log
                  in with Google</a
                >
                <a href="javascript:;" class="btn btn-google w-100"
                  ><img src="@/assets/img/icons/fb.svg" class="me-2" alt="img" />Log in
                  with Facebook</a
                >
              </div>
              <p class="no-acc">
                Already have an account <router-link to="login">Sign in</router-link>
              </p>
            </form>
            <!-- /Login Form -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    submitForm() {
      this.$router.push("/login");
    },
  },
};
</script>
