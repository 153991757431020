<template>
    <!-- Show Companies -->
    <div class="modal fade" id="show-company" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Perusahaan</h5>
            <button
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form>
              <div class="col-12">
                <div class="col-12">
                  <img v-if="(formEdit !== undefined) && (formEdit.logo !== '')" :src="formEdit.logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                  <img v-else :src="require(`@/assets/admin_img/customer/user-01.jpg`)" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nama Perusahaan</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name" disabled/>
                        </div>
                        <div>
                            <label class="form-label">Alamat</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.address" disabled/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nomer Telepon (WA)</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number" disabled/>
                        </div>
                        <div>
                            <label class="form-label">Nama Pemilik</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.owner_name" disabled/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Tanggal Bergabung</label>
                            <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.join_date" disabled/>
                        </div>
                        <div>
                          <label class="form-label">Saldo Aplikasi</label>
                          <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.payment_value" disabled/>
                        </div>
                        <!-- <div>
                            <label class="form-label">Tanggal Pembayaran</label>
                            <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.payment_date" />
                        </div> -->
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Invite Code</label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.invite_code" disabled/>
                        </div>
                        <div>
                          <label class="form-label">Reference Code</label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.reference_code" disabled/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Class</label>
                          <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.class" disabled>
                              <option value="trial">Trial</option>
                              <option value="basic">Basic</option>
                              <option value="premium">Premium</option>
                          </select>
                        </div>
                        <div>
                          <label class="form-label">Tanggal Mulai Trial</label>
                          <input v-if="formEdit !== undefined" type="date" class="form-control" v-model="formEdit.trial_start_date" disabled/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Durasi Trial</label>
                          <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.trial_duration" disabled/>
                        </div>
                        <div>
                          <label class="form-label">Limit Order</label>
                          <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.entry_limit" disabled/>
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-3">
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="col-6">
                                <div class="col-lg-12">
                                    <div class="split-check-title">
                                        <label class="form-label">Jeni Pembayaran</label>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-check form-check-inline">
                                        <input v-if="formEdit !== undefined" class="form-check-input" type="radio" :checked="formEdit.payment_type === 1" id="inlineRadio1" name="payment_type" value=1 v-model="formEdit.payment_type">
                                        <label class="form-check-label" for="inlineRadio1">Cash</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="formEdit !== undefined" class="form-check-input" type="radio" :checked="formEdit.payment_type === 2" id="inlineRadio2" name="payment_type" value=2 v-model="formEdit.payment_type">
                                        <label class="form-check-label" for="inlineRadio2">Termin</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                              <label class="form-label">Saldo Aplikasi</label>
                              <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.payment_value" />
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="mb-3 form-group">
                  <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Tanggal Habis Berlangganan</label>
                          <input v-if="formEdit !== undefined" type="date" class="form-control" v-model="formEdit.due_date" disabled/>
                        </div>
                        <div>
                          <label class="form-label">Tentang Perusahaan</label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.about" disabled/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div style="margin-right: 10px;">
                          <label class="form-label">Nama Bank<span style="color: red;">*</span></label>
                          <select v-if="formEdit !== undefined" class="form-control" v-model="formEdit.bank_account" disabled>
                            <option value="">Pilih Bank</option>
                            <option value="bca">BCA (Bank Central Asia)</option>
                            <option value="bri">BRI (Bank Rakyat Indonesia)</option>
                            <option value="bni">BNI (Bank Negara Indonesia)</option>
                            <option value="mandiri">Bank Mandiri</option>
                            <option value="cimb">CIMB Niaga</option>
                            <option value="btn">Bank BTN</option>
                            <option value="permata">Bank Permata</option>
                            <option value="danamon">Bank Danamon</option>
                            <option value="panin">Bank Panin</option>
                            <option value="ocbc">OCBC NISP</option>
                          </select>
                      </div>
                      <div>
                          <label class="form-label">Nama Pemilik Bank<span style="color: red;">*</span></label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.nama_pemilik_bank" disabled/>
                      </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div style="margin-right: 10px;">
                          <label class="form-label">No Rekening<span style="color: red;">*</span></label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.no_rekening" disabled/>
                      </div>
                      <div>
                      </div>
                    </div>
                </div>
                <div class="col-6">
                        <div class="mt-3">
                            <div class="profile-upload-content">
                              <p>Tanda Tangan dan Stample</p>
                              <img v-if="formEdit && formEdit.stample" :src="formEdit.stample" alt="logo" class="img-thumbnail mb-3 me-3" width="100" height="100">
                              <p v-else>Belum ada Tanda Tangan dan Stample</p>
                        </div>
                    </div>
                  </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Show Companies -->

    <!-- Add Companies -->
    <div class="modal fade" id="add-company" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Perusahaan</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetState"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="col-12">
                <div class="col-12">
                  <div class="col-6">
                        <div class="mt-3">
                            <div class="profile-upload-content">
        
                                <!-- company logo here -->
                                <img v-if="logo" :src="logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                <!-- company logo here -->
        
                                <!-- upload file form logo here -->
                                <div class="profile-upload-btn">
                                    <div class="profile-upload-file">
                                        <input
                                            type="file"
                                            class="form-control"
                                            ref="uploadFieldAttachment"
                                            id="imgInp"
                                            @change="selectFileAttachment"
                                        />
                                    </div>
                                </div>
                                    <!-- upload file form logo here -->
                                <div class="profile-upload-para">
                                    <p style="font-size: 12px;">
                                    <span style="color: red;">*</span>ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                                    File yang diizinkan .png dan .jpg.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nama Perusahaan</label>
                            <input type="text" class="form-control" v-model="formCreate.name" />
                        </div>
                        <div>
                            <label class="form-label">Alamat</label>
                            <input type="text" class="form-control" v-model="formCreate.address" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nomer Telepon (WA)</label>
                            <input type="text" class="form-control" v-model="formCreate.phone_number" />
                        </div>
                        <div>
                            <label class="form-label">Nama Pemilik</label>
                            <input type="text" class="form-control" v-model="formCreate.owner_name" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Tanggal Bergabung</label>
                            <input type="datetime-local" class="form-control" v-model="formCreate.join_date" />
                        </div>
                        <div>
                          <label class="form-label">Invite Code</label>
                          <input type="text" class="form-control" v-model="formCreate.invite_code" />
                        </div>
                        <!-- <div>
                            <label class="form-label">Tanggal Pembayaran</label>
                            <input type="datetime-local" class="form-control" v-model="formCreate.payment_date" />
                        </div> -->
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Reference Code</label>
                          <input type="text" class="form-control" v-model="formCreate.reference_code" />
                        </div>
                        <div>
                          <label class="form-label">Saldo Aplikasi</label>
                          <input type="number" class="form-control" v-model="formCreate.payment_value" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Class</label>
                          <select class="form-select" v-model="formCreate.class">
                              <option value="trial">Trial</option>
                              <option value="basic">Basic</option>
                              <option value="premium">Premium</option>
                          </select>
                        </div>
                        <div>
                          <label class="form-label">Tanggal Mulai Trial</label>
                          <input type="date" class="form-control" v-model="formCreate.trial_start_date" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Durasi Trial</label>
                          <input type="number" class="form-control" v-model="formCreate.trial_duration" />
                        </div>
                        <div>
                          <label class="form-label">Limit Order</label>
                          <input type="number" class="form-control" v-model="formCreate.entry_limit" />
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-3">
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="col-6">
                                <div class="col-lg-12">
                                    <div class="split-check-title">
                                        <label class="form-label">Jeni Pembayaran</label>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :checked="formCreate.payment_type === 1" id="inlineRadio1" name="payment_type" value=1 v-model="formCreate.payment_type">
                                        <label class="form-check-label" for="inlineRadio1">Cash</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" :checked="formCreate.payment_type === 2" id="inlineRadio2" name="payment_type" value=2 v-model="formCreate.payment_type">
                                        <label class="form-check-label" for="inlineRadio2">Termin</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="mb-3 form-group">
                  <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Tanggal Habis Berlangganan</label>
                          <input type="date" class="form-control" v-model="formCreate.due_date" />
                        </div>
                        <div>
                          <label class="form-label">Tentang Perusahaan</label>
                          <input type="text" class="form-control" v-model="formCreate.about" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div style="margin-right: 10px;">
                          <label class="form-label">Nama Bank<span style="color: red;">*</span></label>
                          <select class="form-control" v-model="formCreate.bank_account">
                            <option value="">Pilih Bank</option>
                            <option value="bca">BCA (Bank Central Asia)</option>
                            <option value="bri">BRI (Bank Rakyat Indonesia)</option>
                            <option value="bni">BNI (Bank Negara Indonesia)</option>
                            <option value="mandiri">Bank Mandiri</option>
                            <option value="cimb">CIMB Niaga</option>
                            <option value="btn">Bank BTN</option>
                            <option value="permata">Bank Permata</option>
                            <option value="danamon">Bank Danamon</option>
                            <option value="panin">Bank Panin</option>
                            <option value="ocbc">OCBC NISP</option>
                          </select>
                      </div>
                      <div>
                          <label class="form-label">Nama Pemilik Bank<span style="color: red;">*</span></label>
                          <input type="text" class="form-control" v-model="formCreate.nama_pemilik_bank"/>
                      </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div style="margin-right: 10px;">
                          <label class="form-label">No Rekening<span style="color: red;">*</span></label>
                          <input type="text" class="form-control" v-model="formCreate.no_rekening"/>
                      </div>
                      <div>
                      </div>
                    </div>
                </div>
                <div class="col-6">
                        <div class="mt-3">
                            <div class="profile-upload-content">
                              <p>Tanda Tangan dan Stample</p>
        
                                <div class="d-flex">
                                  <img :src="stample ? stample : require('@/assets/img/stample-ttd.png')" alt="logo" class="img-thumbnail mb-3 me-3" width="100" height="100">
                                  <p v-if="!stample">Ini adalah contoh gambar Tanda Tangan dan Stample</p>
                                </div>
        
                                <!-- upload file form logo here -->
                                <div class="profile-upload-btn">
                                    <div class="profile-upload-file">
                                        <input
                                            type="file"
                                            class="form-control"
                                            ref="uploadFieldAttachment"
                                            id="imgInp"
                                            @change="uploadStample"
                                        />
                                    </div>
                                </div>
                                    <!-- upload file form logo here -->
                                <div class="profile-upload-para">
                                    <p style="font-size: 12px;">
                                    <span style="color: red;">*</span>Pastikan gambar berlatar putih atau transparan, dan kurang dari 500kb.
                                    File yang diizinkan .png
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  @click="resetState"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Companies -->
  
    <!-- Edit Companies -->
    <div class="modal fade" id="edit-company" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Perusahaan</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetState"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="col-12">
                <div class="col-12">
                  <div class="col-6">
                        <div class="mb-3">
                            <div class="profile-upload-content">
        
                                <!-- company logo here -->
                                <img v-if="(formEdit !== undefined) && (formEdit.logo !== '')" :src="formEdit.logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                <!-- company logo here -->
        
                                <!-- upload file form logo here -->
                                <div class="profile-upload-btn">
                                    <div class="profile-upload-file">
                                        <input
                                            type="file"
                                            class="form-control"
                                            ref="uploadFieldAttachment"
                                            id="imgInp"
                                            @change="selectFileAttachment"
                                        />
                                    </div>
                                </div>
                                    <!-- upload file form logo here -->
                                <div class="profile-upload-para">
                                    <p style="font-size: 12px;">
                                    <span style="color: red;">*</span>ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                                    File yang diizinkan .png dan .jpg.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nama Perusahaan</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name" />
                        </div>
                        <div>
                            <label class="form-label">Alamat</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.address" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nomer Telepon (WA)</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number" />
                        </div>
                        <div>
                            <label class="form-label">Nama Pemilik</label>
                            <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.owner_name" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Tanggal Bergabung</label>
                            <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.join_date" />
                        </div>
                        <div>
                          <label class="form-label">Saldo Aplikasi</label>
                          <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.payment_value" />
                        </div>
                        <!-- <div>
                            <label class="form-label">Tanggal Pembayaran</label>
                            <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.payment_date" />
                        </div> -->
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Invite Code</label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.invite_code" />
                        </div>
                        <div>
                          <label class="form-label">Reference Code</label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.reference_code" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Class</label>
                          <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.class">
                              <option value="trial">Trial</option>
                              <option value="basic">Basic</option>
                              <option value="premium">Premium</option>
                          </select>
                        </div>
                        <div>
                          <label class="form-label">Tanggal Mulai Trial</label>
                          <input v-if="formEdit !== undefined" type="date" class="form-control" v-model="formEdit.trial_start_date" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Durasi Trial</label>
                          <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.trial_duration" />
                        </div>
                        <div>
                          <label class="form-label">Limit Order</label>
                          <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.entry_limit" />
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-3">
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="col-6">
                                <div class="col-lg-12">
                                    <div class="split-check-title">
                                        <label class="form-label">Jeni Pembayaran</label>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-check form-check-inline">
                                        <input v-if="formEdit !== undefined" class="form-check-input" type="radio" :checked="formEdit.payment_type === 1" id="inlineRadio1" name="payment_type" value=1 v-model="formEdit.payment_type">
                                        <label class="form-check-label" for="inlineRadio1">Cash</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="formEdit !== undefined" class="form-check-input" type="radio" :checked="formEdit.payment_type === 2" id="inlineRadio2" name="payment_type" value=2 v-model="formEdit.payment_type">
                                        <label class="form-check-label" for="inlineRadio2">Termin</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                              <label class="form-label">Saldo Aplikasi</label>
                              <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.payment_value" />
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="mb-3 form-group">
                  <div class="d-flex">
                        <div style="margin-right: 10px;">
                          <label class="form-label">Tanggal Habis Berlangganan</label>
                          <input v-if="formEdit !== undefined" type="date" class="form-control" v-model="formEdit.due_date" />
                        </div>
                        <div>
                          <label class="form-label">Tentang Perusahaan</label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.about" />
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div style="margin-right: 10px;">
                          <label class="form-label">Nama Bank<span style="color: red;">*</span></label>
                          <select v-if="formEdit !== undefined" class="form-control" v-model="formEdit.bank_account">
                            <option value="">Pilih Bank</option>
                            <option value="bca">BCA (Bank Central Asia)</option>
                            <option value="bri">BRI (Bank Rakyat Indonesia)</option>
                            <option value="bni">BNI (Bank Negara Indonesia)</option>
                            <option value="mandiri">Bank Mandiri</option>
                            <option value="cimb">CIMB Niaga</option>
                            <option value="btn">Bank BTN</option>
                            <option value="permata">Bank Permata</option>
                            <option value="danamon">Bank Danamon</option>
                            <option value="panin">Bank Panin</option>
                            <option value="ocbc">OCBC NISP</option>
                          </select>
                      </div>
                      <div>
                          <label class="form-label">Nama Pemilik Bank<span style="color: red;">*</span></label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.nama_pemilik_bank"/>
                      </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div style="margin-right: 10px;">
                          <label class="form-label">No Rekening<span style="color: red;">*</span></label>
                          <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.no_rekening"/>
                      </div>
                      <div>
                      </div>
                    </div>
                </div>
                <div class="col-6">
                        <div class="mt-3">
                            <div class="profile-upload-content">
                              <p>Tanda Tangan dan Stample</p>
        
                                <div class="d-flex">
                                  <img v-if="stample" :src="stample" alt="logo" class="img-thumbnail mb-3 me-3" width="100" height="100">
                                  <img v-else-if="formEdit && formEdit.stample" :src="formEdit.stample" alt="logo" class="img-thumbnail mb-3 me-3" width="100" height="100">
                                  <img v-else :src="require('@/assets/img/stample-ttd.png')" alt="logo" class="img-thumbnail mb-3 me-3" width="100" height="100">
                                  <p v-if="!stample && (!formEdit || !formEdit.stample)">Ini adalah contoh gambar Tanda Tangan dan Stample</p>
                                </div>
        
                                <!-- upload file form logo here -->
                                <div class="profile-upload-btn">
                                    <div class="profile-upload-file">
                                        <input
                                            type="file"
                                            class="form-control"
                                            ref="uploadFieldAttachment"
                                            id="imgInp"
                                            @change="uploadStample"
                                        />
                                    </div>
                                </div>
                                    <!-- upload file form logo here -->
                                <div class="profile-upload-para">
                                    <p style="font-size: 12px;">
                                    <span style="color: red;">*</span>Pastikan gambar berlatar putih atau transparan, dan kurang dari 500kb.
                                    File yang diizinkan .png
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  @click="resetState"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Companies -->

    <!-- Edit Current Companies -->
    <!-- <div class="modal fade" id="edit-current" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Perusahaan</h5>
            <button
              id="close-edit-current"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="mb-3">
                <div class="profile-upload-content">

                    company logo here
                    <img v-if="logo" :src="logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                    company logo here

                    upload file form logo here
                    <div class="profile-upload-btn">
                        <div class="profile-upload-file">
                          <input
                              type="file"
                              class="form-control"
                              ref="uploadFieldAttachment"
                              id="imgInp"
                              @change="selectFileAttachment"
                          />
                          <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                        </div>
                    </div>
                     upload file form logo here
                    <div class="profile-upload-para">
                        <p>
                        *image size should be at least 320px big,and less then 500kb.
                        Allowed files .png and .jpg.
                        </p>
                    </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Company Name</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Address</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.address"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Phone Number</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Owner Name</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.owner_name"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Invite Code</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.invite_code"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Reference Code</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.reference_code"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Join Date</label>
                <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.join_date"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Payment Date</label>
                <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.payment_date"/>
              </div>
              <div class="mb-3">
                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="split-check-title">
                                <h6>Payment Type</h6>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="split-check-box">
                                <ul class="custom-radiosbtn">
                                    <li>
                                        <label class="radiossets">Chass
                                            <input v-if="formEdit !== undefined" type="radio" :checked="formEdit.payment_type === 1" name="payment_type" value=1 v-model="formEdit.payment_type">
                                            <span class="checkmark-radio"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="radiossets">Termine
                                            <input v-if="formEdit !== undefined" type="radio" :checked="formEdit.payment_type === 2" name="payment_type" value=2 v-model="formEdit.payment_type">
                                            <span class="checkmark-radio"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Payment Value</label>
                <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.payment_value"/>
              </div>
              <div class="mb-3">
                <label class="form-label">About</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.about"/>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Edit Current Companies -->
  
    <div class="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Hapus Perusahaan ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {},
        logo: '',
        stample: null,
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object,
    },
    emits: ['edit-company'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
            console.log('formedt', this.formEdit)
          }
        }
      },
    },
    methods: {
      validateFile(file, allowedFileTypes, maxFileSize) {
        if (!allowedFileTypes.includes(file.type)) {
            this.$swal.fire({
                icon: 'warning',
                text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
            });
            return false;
        }

        if (file.size > maxFileSize) {
            this.$swal.fire({
                icon: 'warning',
                text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
            });
            return false;
        }

        return true;
      },
      // method upload file / foto
      uploadStample(event) {
        const file = event.target.files[0];
        const allowedFileTypes = ['image/png'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(file)
            .then((resp) => {
                this.stample = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      editSubmit() {
        this.formEdit.logo = this.formEdit.logo !== '' ? this.formEdit.logo : this.logo;
        if (this.stample) {
          this.formEdit.stample = this.stample
        }
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("company/updateCompany", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {};
              this.logo = '';
              this.stample = null
              document.getElementById('close-edit').click();
              // document.getElementById('close-edit-current').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createSubmit() {
        this.formCreate.logo = this.formCreate.logo !== '' ? this.formCreate.logo : this.logo;
        if (this.stample) {
          this.formCreate.stample = this.stample
        }
        var loading = this.$loading.show()
        this.$store
          .dispatch("company/addCompany", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {};
              this.logo = '';
              this.stample = null
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteData() {
        // Perform deletion using this.deleteItemId
        console.log('Deleting item with ID:', this.deleteItemId);
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("company/deleteCompany", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      resetState() {
        this.stample = null
      }
    },
  };
  </script>
  