<template>
  <div class="bg-img">
    <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg1" />
    <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg2" />
    <img src="@/assets/img/bg/feature-bg-03.png" alt="img" class="bgimg3" />
  </div>

  <div class="content">
    <div class="container">
      <div class="row">
        <!-- Filter -->
        <div class="col-lg-3 col-sm-12 theiaStickySidebar">
          <div class="stickysidebar">
            <div class="filter-div">
              <div class="filter-head">
                <h5>Filter by</h5>
                <a href="javascript:;" class="reset-link">Reset Filters</a>
              </div>
              <div class="filter-content">
                <h2>Keyword</h2>
                <input
                  type="text"
                  class="form-control"
                  placeholder="What are you looking for?"
                />
              </div>
              <div class="filter-content">
                <h2>
                  Categories <span><i class="fas fa-chevron-down"></i></span>
                </h2>
                <div class="filter-checkbox" id="fill-more">
                  <ul>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">All Categories</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Construction</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Car Wash</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Electrical</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Cleaning</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Interior</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Computer</b>
                      </label>
                    </li>
                  </ul>
                </div>
                <a href="javascript:void(0);" id="more" class="more-view"
                  >View More <i class="feather-arrow-down-circle ms-1"></i
                ></a>
              </div>
              <div class="filter-content">
                <h2>Sub Category</h2>
                <vue-select :options="Category" placeholder="All Sub Category" />
              </div>
              <div class="filter-content">
                <h2>Location</h2>
                <div class="group-img">
                  <input type="text" class="form-control" placeholder="Select Location" />
                  <i class="feather-map-pin"></i>
                </div>
              </div>
              <div class="filter-content">
                <h2 class="mb-5">Price Range</h2>
                <div class="filter-range">
                  <Slider v-model="value3" :merge="merge" :format="format" />
                </div>
                <div class="filter-range-amount">
                  <h5>Price: <span>$5 - $210</span></h5>
                </div>
              </div>
              <div class="filter-content">
                <h2>
                  By Rating <span><i class="fas fa-chevron-down"></i></span>
                </h2>
                <ul class="rating-set">
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(35)</span>
                    </a>
                  </li>
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(40)</span>
                    </a>
                  </li>
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(40)</span>
                    </a>
                  </li>
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(20)</span>
                    </a>
                  </li>
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(5)</span>
                    </a>
                  </li>
                </ul>
              </div>
              <b-button variant="primary">Search</b-button>
            </div>
          </div>
        </div>
        <!-- /Filter -->

        <!-- Service -->
        <div class="col-lg-9 col-sm-12">
          <div class="row sorting-div">
            <div class="col-lg-4 col-sm-12">
              <div class="count-search">
                <h6>Found 6 Services</h6>
              </div>
            </div>
            <div class="col-lg-8 col-sm-12 d-flex justify-content-end">
              <div class="sortbyset">
                <div class="sorting-select">
                  <vue-select :options="Select" placeholder="Price Low to High" />
                </div>
              </div>
              <div class="grid-listview">
                <ul>
                  <li>
                    <router-link to="/service-grid">
                      <i class="feather-grid"></i>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/service-list" class="active">
                      <i class="feather-list"></i>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <!-- Service List -->
              <div class="service-list">
                <div class="service-cont">
                  <div class="service-cont-img">
                    <router-link to="service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        src="@/assets/img/services/service-04.jpg"
                      />
                    </router-link>
                    <div class="fav-item">
                      <a href="javascript:void(0)" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="service-cont-info">
                    <span class="item-cat">Car Wash</span>
                    <h3 class="title">
                      <router-link to="/service-details">Car Repair Services</router-link>
                    </h3>
                    <p><i class="feather-map-pin"></i>Maryland City, MD, USA</p>
                    <div class="service-pro-img">
                      <img src="@/assets/img/profiles/avatar-01.jpg" alt="" />
                      <span><i class="fa-solid fa-star filled me-1"></i>4.9</span>
                    </div>
                  </div>
                </div>
                <div class="service-action">
                  <h6>$25.00<span class="old-price">$35.00</span></h6>
                  <router-link to="/booking" class="btn btn-secondary"
                    >Book Now</router-link
                  >
                </div>
              </div>
              <!-- /Service List -->

              <!-- Service List -->
              <div class="service-list">
                <div class="service-cont">
                  <div class="service-cont-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        src="@/assets/img/services/service-02.jpg"
                      />
                    </router-link>
                    <div class="fav-item">
                      <a href="javascript:void(0)" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="service-cont-info">
                    <span class="item-cat">Construction</span>
                    <h3 class="title">
                      <router-link to="/service-details"
                        >Toughened Glass Fitting Services</router-link
                      >
                    </h3>
                    <p><i class="feather-map-pin"></i>New Jersey, USA</p>
                    <div class="service-pro-img">
                      <img src="@/assets/img/profiles/avatar-02.jpg" alt="" />
                      <span><i class="fa-solid fa-star filled me-1"></i>4.9</span>
                    </div>
                  </div>
                </div>
                <div class="service-action">
                  <h6>$30.00<span class="old-price">$35.00</span></h6>
                  <router-link to="/booking" class="btn btn-secondary"
                    >Book Now</router-link
                  >
                </div>
              </div>
              <!-- /Service List -->

              <!-- Service List -->
              <div class="service-list">
                <div class="service-cont">
                  <div class="service-cont-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        src="@/assets/img/services/service-06.jpg"
                      />
                    </router-link>
                    <div class="fav-item">
                      <a href="javascript:void(0)" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="service-cont-info">
                    <span class="item-cat">Computer</span>
                    <h3 class="title">
                      <router-link to="/service-details"
                        >Computer & Server AMC Service</router-link
                      >
                    </h3>
                    <p><i class="feather-map-pin"></i>California, USA</p>
                    <div class="service-pro-img">
                      <img src="@/assets/img/profiles/avatar-05.jpg" alt="" />
                      <span><i class="fa-solid fa-star filled me-1"></i>4.9</span>
                    </div>
                  </div>
                </div>
                <div class="service-action">
                  <h6>$30.00<span class="old-price">$35.00</span></h6>
                  <router-link to="/booking" class="btn btn-secondary"
                    >Book Now</router-link
                  >
                </div>
              </div>
              <!-- /Service List -->

              <!-- Service List -->
              <div class="service-list">
                <div class="service-cont">
                  <div class="service-cont-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        src="@/assets/img/services/service-07.jpg"
                      />
                    </router-link>
                    <div class="fav-item">
                      <a href="javascript:void(0)" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="service-cont-info">
                    <span class="item-cat">Interior</span>
                    <h3 class="title">
                      <router-link to="/service-details">Interior Designing</router-link>
                    </h3>
                    <p><i class="feather-map-pin"></i>Maryland, USA</p>
                    <div class="service-pro-img">
                      <img src="@/assets/img/profiles/avatar-06.jpg" alt="" />
                      <span><i class="fa-solid fa-star filled me-1"></i>4.9</span>
                    </div>
                  </div>
                </div>
                <div class="service-action">
                  <h6>$15.00<span class="old-price">$25.00</span></h6>
                  <router-link to="/booking" class="btn btn-secondary"
                    >Book Now</router-link
                  >
                </div>
              </div>
              <!-- /Service List -->

              <!-- Service List -->
              <div class="service-list">
                <div class="service-cont">
                  <div class="service-cont-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        src="@/assets/img/services/service-09.jpg"
                      />
                    </router-link>
                    <div class="fav-item">
                      <a href="javascript:void(0)" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="service-cont-info">
                    <span class="item-cat">Cleaning</span>
                    <h3 class="title">
                      <router-link to="/service-details"
                        >House Cleaning Services</router-link
                      >
                    </h3>
                    <p><i class="feather-map-pin"></i>Georgia</p>
                    <div class="service-pro-img">
                      <img src="@/assets/img/profiles/avatar-09.jpg" alt="" />
                      <span><i class="fa-solid fa-star filled me-1"></i>4.9</span>
                    </div>
                  </div>
                </div>
                <div class="service-action">
                  <h6>$55.00<span class="old-price">$60.00</span></h6>
                  <router-link to="/booking" class="btn btn-secondary"
                    >Book Now</router-link
                  >
                </div>
              </div>
              <!-- /Service List -->

              <!-- Service List -->
              <div class="service-list">
                <div class="service-cont">
                  <div class="service-cont-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        src="@/assets/img/services/service-10.jpg"
                      />
                    </router-link>
                    <div class="fav-item">
                      <a href="javascript:void(0)" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="service-cont-info">
                    <span class="item-cat">Construction</span>
                    <h3 class="title">
                      <router-link to="/service-details"
                        >Building Construction Services</router-link
                      >
                    </h3>
                    <p><i class="feather-map-pin"></i>Montana, USA</p>
                    <div class="service-pro-img">
                      <img src="@/assets/img/profiles/avatar-09.jpg" alt="" />
                      <span><i class="fa-solid fa-star filled me-1"></i>4.9</span>
                    </div>
                  </div>
                </div>
                <div class="service-action">
                  <h6>$45.00<span class="old-price">$50.00</span></h6>
                  <router-link to="/booking" class="btn btn-secondary"
                    >Book Now</router-link
                  >
                </div>
              </div>
              <!-- /Service List -->
            </div>
          </div>

          <!-- Pagination -->
          <div class="row">
            <div class="col-sm-12">
              <div class="blog-pagination rev-page">
                <nav>
                  <ul class="pagination justify-content-center mt-0">
                    <li class="page-item disabled">
                      <a class="page-link page-prev" href="javascript:;" tabindex="-1"
                        ><i class="fa-solid fa-arrow-left me-1"></i> PREV</a
                      >
                    </li>
                    <li class="page-item active">
                      <a class="page-link" href="javascript:;">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:;">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:;">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link page-next" href="javascript:;"
                        >NEXT <i class="fa-solid fa-arrow-right ms-1"></i
                      ></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!-- /Pagination -->
        </div>
        <!-- /Service -->
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider";

export default {
  data() {
    return {
      Category: ["All Sub Category", "Computer", "Construction"],
      Select: ["Price Low to High", "Price High to Low"],
      value3: [20, 80],
      merge: 10,
      format: {
        prefix: "$",
        suffix: "0",
      },
    };
  },
  mounted() {
    document.querySelectorAll(".fav-icon").forEach(function (element) {
      element.addEventListener("click", function () {
        element.classList.toggle("selected");
      });
    });

    if (document.getElementById("more") != null) {
      const button = document.getElementById("more");
      const container = document.getElementById("fill-more");

      let isLess = true;

      function showMoreLess() {
        if (isLess) {
          isLess = false;
          container.style.height = "auto";
          button.innerHTML = "Show less <i class='feather-arrow-up-circle ms-1'>";
        } else {
          isLess = true;
          container.style.height = "180px";
          button.innerHTML = "Show more <i class='feather-arrow-down-circle ms-1'></i>";
        }
      }

      button.addEventListener("click", showMoreLess);
    }
  },
  components: {
    Slider,
  },
};
</script>

<style>
.slider-connect {
  background: var(--slider-connect-bg, #35a7f4) !important;
  cursor: pointer;
}
.slider-tooltip {
  background: var(--slider-tooltip-bg, #0b2540) !important;
  border: 1px solid var(--slider-tooltip-bg, #0b2540) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.roundskin .slider-tooltip {
  background: var(--slider-tooltip-bg, #006cfa) !important;
  border: 1px solid var(--slider-tooltip-bg, #006cfa) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.slider-handle {
  background-color: #35a7f4 !important;
  border: 2px solid #fff !important;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
