<template>
    <div class="row">
        <div class="col-md-12">
            <div class="available-section card-section ">
                <div class="available-heading">
                    <h4>Availablity</h4>
                </div>

                <!-- Timeslot -->
                <div class="timeslot-sec availablt-time-slots">
                    <!-- Schedule Nav -->
                    <label class="col-form-label">Configure Time Slots</label>
                    <div class="schedule-nav">
                        <ul class="nav">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#all_days">All Days</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#slot_monday">Monday</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#slot_tuesday">Tuesday</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#slot_wednesday">Wednesday</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#slot_thursday">Thursday</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#slot_friday">Friday</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#slot_saturday">Saturday</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#slot_sunday">Sunday</a>
                            </li>
                        </ul>
                    </div>
                    <!-- /Schedule Nav -->

                    <div class="tab-content pt-0">
                        <div class="tab-pane active" id="all_days">
                            <div class="hours-info">
                                <h4 class="nameof-day">All Days</h4>
                                <div class="row hours-cont">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>From</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>To</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value1"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Slots</label>
                                            <input type="text" class="form-control" placeholder="Enter Slot">
                                        </div>
                                    </div>
                                </div>
                                <div class="row hours-cont" v-for="(certify, index) in certify" :key="index">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>From</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value2"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>To</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value3"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="d-flex">
                                            <div class="form-group w-100">
                                                <label>Slots</label>
                                                <input type="text" class="form-control" placeholder="Enter Slot">
                                            </div>
                                            <div class="form-group">
                                                <label>&nbsp;</label><a href="javascript:;" class="btn btn-danger-outline trash"
                                                    @click="deletecertify(index)">
                                                    <i class="far fa-trash-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:;" class="link-sets add-text add-hours" @click="addcertify"><i
                                    class="fe fe-plus-circle"></i> Add
                                More</a>
                        </div>
                        <div class="tab-pane fade" id="slot_monday">
                            <div class="hours-info">
                                <h4 class="nameof-day">Monday</h4>
                                <div class="row hours-cont">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>From</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value4"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>To</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value5"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Slots</label>
                                            <input type="text" class="form-control" placeholder="Enter Slot">
                                        </div>
                                    </div>
                                </div>
                                <div class="row hours-cont" v-for="(certify1, index1) in certify1" :key="index1">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>From</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value6"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>To</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value7"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="d-flex">
                                            <div class="form-group w-100">
                                                <label>Slots</label>
                                                <input type="text" class="form-control" placeholder="Enter Slot">
                                            </div>
                                            <div class="form-group">
                                                <label>&nbsp;</label><a href="javascript:;" class="btn btn-danger-outline trash"
                                                    @click="deletecertifyOne(index1)">
                                                    <i class="far fa-trash-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:;" class="link-sets add-text add-hours" @click="addcertifyOne"><i
                                    class="fe fe-plus-circle"></i> Add
                                More</a>
                        </div>
                        <div class="tab-pane fade" id="slot_tuesday">
                            <div class="hours-info">
                                <h4 class="nameof-day">Tuesday</h4>
                                <div class="row hours-cont">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>From</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value8"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>To</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value9"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Slots</label>
                                            <input type="text" class="form-control" placeholder="Enter Slot">
                                        </div>
                                    </div>
                                </div>
                                <div class="row hours-cont" v-for="(certify2, index2) in certify2" :key="index2">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>From</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value10"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>To</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value11"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="d-flex">
                                            <div class="form-group w-100">
                                                <label>Slots</label>
                                                <input type="text" class="form-control" placeholder="Enter Slot">
                                            </div>
                                            <div class="form-group">
                                                <label>&nbsp;</label><a href="javascript:;" class="btn btn-danger-outline trash"
                                                    @click="deletecertifyTwo(index2)">
                                                    <i class="far fa-trash-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:;" class="link-sets add-text add-hours" @click="addcertifyTwo"><i
                                    class="fe fe-plus-circle"></i> Add
                                More</a>
                        </div>
                        <div class="tab-pane fade" id="slot_wednesday">
                            <div class="hours-info">
                                <h4 class="nameof-day">Wednesday</h4>
                                <div class="row hours-cont">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>From</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value12"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>To</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value13"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Slots</label>
                                            <input type="text" class="form-control" placeholder="Enter Slot">
                                        </div>
                                    </div>
                                </div>
                                <div class="row hours-cont" v-for="(certify3, index3) in certify3" :key="index3">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>From</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value15"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>To</label>
                                            <div class="form-availability-field">
                                                <a-time-picker
                                                    v-model:value="value15"
                                                    class="form-control timepicker"
                                                    placeholder="Select Time"
                                                    use12-hours
                                                    format="h:mm a"
                                                />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="d-flex">
                                            <div class="form-group w-100">
                                                <label>Slots</label>
                                                <input type="text" class="form-control" placeholder="Enter Slot">
                                            </div>
                                            <div class="form-group">
                                                <label>&nbsp;</label><a href="javascript:;" class="btn btn-danger-outline trash"
                                                    @click="deletecertifyThree(index3)">
                                                    <i class="far fa-trash-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:;" class="link-sets add-text add-hours" @click="addcertifyThree"><i
                                    class="fe fe-plus-circle"></i> Add
                                More</a>
                        </div>
                        <admin-service-timepicker></admin-service-timepicker>
                    </div>

                </div>
                <!-- Timeslot -->

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            certify: [],
            certify1: [],
            certify2: [],
            certify3: [],
            value: null,
            value1: null,
            value2: null,
            value3: null,
            value4: null,
            value5: null,
            value6: null,
            value7: null,
            value8: null,
            value9: null,
            value10: null,
            value11: null,
            value12: null,
            value13: null,
            value14: null,
            value15: null,
        }
    },
    methods: {
        addcertify() {
            this.certify.push({});
        },
        deletecertify(index) {
            this.certify.splice(index, 1);
        },
        addcertifyOne() {
            this.certify1.push({});
        },
        deletecertifyOne(index1) {
            this.certify1.splice(index1, 1);
        },
        addcertifyTwo() {
            this.certify2.push({});
        },
        deletecertifyTwo(index2) {
            this.certify2.splice(index2, 1);
        },
        addcertifyThree() {
            this.certify3.push({});
        },
        deletecertifyThree(index3) {
            this.certify3.splice(index3, 1);
        },
    },
}
</script>