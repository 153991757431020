<template>
    <div class="main-wrapper">
        <layout></layout>
        <adminsidebar></adminsidebar>

        <div class="page-wrapper page-settings">

            <sidelinkheading />
            
            <div class="content w-100">
                <div class="content-page-header">
                    <h5>Appearance Settings</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-groupheads">
                                    <h2>Theme</h2>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="theme-image" @click="activate(1)" :class="{ active : active_el == 1 }">
                                    <div class="theme-image-set" >
                                        <img src="@/assets/admin_img/img1.jpg" alt="img">
                                        <h2>Theme 1</h2>
                                    </div>
                                    <div class="theme-image-content">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="theme-image" @click="activate(2)" :class="{ active : active_el == 2 }">
                                    <div class="theme-image-set" >
                                        <img src="@/assets/admin_img/img2.jpg" alt="img">
                                        <h2>Theme 2</h2>
                                    </div>
                                    <div class="theme-image-content">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="theme-image" @click="activate(3)" :class="{ active : active_el == 3 }">
                                    <div class="theme-image-set">
                                        <img src="@/assets/admin_img/img3.jpg" alt="img">
                                        <h2>Theme 3</h2>
                                    </div>
                                    <div class="theme-image-content">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group-set">
                                    <div class="row">
                                        <div class="col-lg-3 col-sm-6">
                                            <h4>Font Family</h4>
                                            <h5>Choose your font style</h5>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                           <vue-select 
                                                :options="Select"
                                                placeholder="Roboto"
                                           />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group-set">
                                    <div class="row">
                                        <div class="col-lg-3 col-sm-6">
                                            <h4>Accent color</h4>
                                            <h5>Choose your theme color</h5>
                                        </div>
                                        <div class="col-lg-9 col-sm-12">
                                           <div class="themecolor">
                                                <ul>
                                                    <li>
                                                        <span class="themecolorset defaultcolor" 
                                                            @click="activate(4)" :class="{ active : active_el == 4 }">
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span class="themecolorset theme-greens" 
                                                            @click="activate(5)" :class="{ active : active_el == 5 }">
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span class="themecolorset theme-viloets"
                                                            @click="activate(6)" :class="{ active : active_el == 6 }">
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span class="themecolorset theme-orange" 
                                                            @click="activate(7)" :class="{ active : active_el == 7 }">
                                                        </span>
                                                    </li>
                                                </ul>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-grouphead">
                                    <h2>Navbar</h2>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group-set">
                                    <div class="row align-items-center">
                                        <div class="col-lg-3 col-sm-6">
                                            <h4 class="mb-0">Navbar 1</h4>
                                        </div>
                                        <div class="col-lg-9 col-sm-12">
                                            <div class="nav-bar-image">
                                                <img src="@/assets/admin_img/nav1.jpg" alt="img">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group-set">
                                    <div class="row align-items-center">
                                        <div class="col-lg-3 col-sm-6">
                                            <h4 class="mb-0">Navbar 2</h4>
                                        </div>
                                        <div class="col-lg-9 col-sm-12">
                                            <div class="nav-bar-image">
                                                <img src="@/assets/admin_img/nav2.jpg" alt="img">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    data() {
        return {
            Select: ["Roboto", "Poppins"],
            active_el:0,
        }
    },
    methods:{
        activate:function(el){
            this.active_el = el;
        },
        
    }
}
</script>