<template>
  <div class="content">
    <div class="container">
      <div class="customer-chat">
        <div class="row" :class="{ 'chat-window': true, 'chat-slide': isChatSlided }">
          <!-- Chat User List -->
          <div class="col-lg-3 chat-cont-left d-flex">
            <div class="card mb-sm-3 mb-md-0 contacts_card flex-fill">
              <div class="chat-header">
                <div>
                  <h6>Chats</h6>
                  <p>Start New Conversation</p>
                </div>
              </div>
              <div class="chat-search chat-search-head">
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Search"
                    class="form-control search-chat chat-search"
                  />
                  <div class="search-icon-right">
                    <span class="search_btn"><i class="feather-search"></i></span>
                  </div>
                </div>
              </div>
              <div class="card-body contacts_body chat-users-list chat-scroll">
                <div class="chat-header inner-chat-header pt-0">
                  <div>
                    <h6>Recent</h6>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  class="media d-flex active"
                  @click="toggleChat"
                >
                  <div class="media-img-wrap flex-shrink-0">
                    <div class="avatar avatar-online">
                      <img
                        src="@/assets/img/profiles/chat-profile-01.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                  </div>
                  <div class="media-body flex-grow-1">
                    <div>
                      <div class="user-name">Hendrita</div>
                      <div class="user-last-chat">Lorem ipsum dolor</div>
                    </div>
                    <div>
                      <div class="last-chat-time">10:12 AM</div>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="media d-flex read-chat"
                  @click="toggleChat"
                >
                  <div class="media-img-wrap flex-shrink-0">
                    <div class="avatar avatar-online">
                      <img
                        src="@/assets/img/profiles/avatar-03.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                  </div>
                  <div class="media-body flex-grow-1">
                    <div>
                      <div class="user-name">John Smith</div>
                      <div class="user-last-chat">
                        <i class="feather-image"></i> Image
                      </div>
                    </div>
                    <div>
                      <div class="last-chat-time">10:19 AM</div>
                      <div class="badge badge-primary badge-pill">2</div>
                    </div>
                  </div>
                </a>
                <a href="javascript:void(0);" class="media d-flex" @click="toggleChat">
                  <div class="media-img-wrap flex-shrink-0">
                    <div class="avatar">
                      <img
                        src="@/assets/img/profiles/avatar-04.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                  </div>
                  <div class="media-body flex-grow-1">
                    <div>
                      <div class="user-name">Audrey</div>
                      <div class="user-last-chat">
                        <i class="feather-video"></i> Video
                      </div>
                    </div>
                    <div>
                      <div class="last-chat-time">7:30 PM</div>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="media read-chat d-flex"
                  @click="toggleChat"
                >
                  <div class="media-img-wrap flex-shrink-0">
                    <div class="avatar avatar-away">
                      <img
                        src="@/assets/img/profiles/avatar-06.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                  </div>
                  <div class="media-body flex-grow-1">
                    <div>
                      <div class="user-name">David</div>
                      <div class="user-last-chat">
                        <i class="feather-file-text"></i> Document
                      </div>
                    </div>
                    <div>
                      <div class="last-chat-time">6:59 PM</div>
                    </div>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="media read-chat d-flex"
                  @click="toggleChat"
                >
                  <div class="media-img-wrap flex-shrink-0">
                    <div class="avatar avatar-online">
                      <img
                        src="@/assets/img/profiles/avatar-05.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                  </div>
                  <div class="media-body flex-grow-1">
                    <div>
                      <div class="user-name">Ashley</div>
                      <div class="user-last-chat">typing...</div>
                    </div>
                    <div>
                      <div class="last-chat-time">11:21 AM</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!-- Chat User List -->

          <!-- Chat Content -->
          <div
            class="col-lg-9 chat-cont-right chat-window-long"
            :class="{
              'chat-window-long': !isFullWidth,
              'window-full-width': isFullWidth,
              'full-width': isFullWidth,
            }"
          >
            <!-- Chat History -->
            <div
              class="card mb-0"
              :class="{ 'chat-window': true, 'chat-slide': isChatSlided }"
            >
              <div class="card-header msg_head">
                <div class="d-flex bd-highlight">
                  <a
                    id="back_user_list"
                    href="javascript:void(0)"
                    class="back-user-list"
                    @click="handleBackButtonClick"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </a>
                  <div class="img_cont">
                    <img
                      class="rounded-circle user_img"
                      src="@/assets/img/profiles/chat-profile-01.jpg"
                      alt="User"
                    />
                  </div>
                  <div class="user_info">
                    <span>Hendrita</span>
                    <p class="mb-0 active">Online</p>
                  </div>
                </div>
                <div class="chat-options">
                  <ul>
                    <li>
                      <a href="javascript:;"><i class="feather-volume-2"></i></a>
                    </li>
                    <li>
                      <a href="javascript:;"><i class="feather-search"></i></a>
                    </li>
                    <li>
                      <a href="javascript:;"><i class="feather-video"></i></a>
                    </li>
                    <li>
                      <a
                        href="javascript:;"
                        class="chat-profile-open"
                        @click="toggleWindow"
                        ><i class="feather-user" id="task_chat"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:;" class="with-bg"
                        ><i class="feather-more-horizontal"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="card-body msg_card_body chat-scroll pt-0">
                <ul class="list-unstyled">
                  <li class="chat-date mt-0"><span>Yesterday</span></li>
                  <li class="media received d-flex">
                    <div class="avatar flex-shrink-0">
                      <img
                        src="@/assets/img/profiles/chat-profile-01.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            incididunt ut labore et dolore magna aliqua.
                          </p>
                        </div>
                        <span class="drop-item message-more-option">
                          <a
                            href="javascript:;"
                            class="more-vertical-bar"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="feather-more-vertical"></i>
                          </a>
                          <small class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-copy me-2"></i>Copy</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-trash-2 me-2"></i>Delete</a
                            >
                          </small>
                        </span>
                        <ul class="chat-msg-info name-date">
                          <li>Hendrita</li>
                          <li>
                            <span class="chat-time"
                              >09:30 am<i class="fa-solid fa-check-double read"></i
                            ></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="media received d-flex">
                    <div class="avatar flex-shrink-0">
                      <img
                        src="@/assets/img/profiles/chat-profile-01.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div class="msg-img">
                          <div class="chat-msg-attachments">
                            <div class="chat-attachment">
                              <img src="@/assets/img/chat-img-01.jpg" alt="Attachment" />
                              <a href="" class="chat-attach-download">
                                <i class="fas fa-download"></i>
                              </a>
                            </div>
                            <div class="chat-attachment">
                              <img src="@/assets/img/chat-img-02.jpg" alt="Attachment" />
                              <a href="" class="chat-attach-download">
                                <i class="fas fa-download"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <span class="drop-item message-more-option">
                          <a
                            href="javascript:;"
                            class="more-vertical-bar"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="feather-more-vertical"></i>
                          </a>
                          <small class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-copy me-2"></i>Copy</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-trash-2 me-2"></i>Delete</a
                            >
                          </small>
                        </span>
                        <ul class="chat-msg-info name-date">
                          <li>Hendrita</li>
                          <li>
                            <span class="chat-time"
                              >09:30 am<i class="fa-solid fa-check-double un-read"></i
                            ></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="media sent d-flex">
                    <div class="avatar flex-shrink-0">
                      <img
                        src="@/assets/img/profiles/chat-profile-02.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div>
                          <p>
                            Sed ut perspiciatis unde omnis iste natus error accusantium
                            doloremque laudantium
                          </p>
                        </div>
                        <span class="drop-item message-more-option">
                          <a
                            href="javascript:;"
                            class="more-vertical-bar"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="feather-more-vertical"></i>
                          </a>
                          <small class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-copy me-2"></i>Copy</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-trash-2 me-2"></i>Delete</a
                            >
                          </small>
                        </span>
                        <ul class="chat-msg-info name-date">
                          <li>
                            <span class="chat-time"
                              >10:45 am<i class="fa-solid fa-check"></i
                            ></span>
                          </li>
                          <li>John Steven</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="chat-date"><span>Today</span></li>
                  <li class="media received d-flex">
                    <div class="avatar flex-shrink-0">
                      <img
                        src="@/assets/img/profiles/chat-profile-01.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            incididunt ut labore et dolore magna aliqua.
                          </p>
                        </div>
                        <span class="drop-item message-more-option">
                          <a
                            href="javascript:;"
                            class="more-vertical-bar"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="feather-more-vertical"></i>
                          </a>
                          <small class="dropdown-menu">
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-copy me-2"></i>Copy</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-trash-2 me-2"></i>Delete</a
                            >
                          </small>
                        </span>
                        <ul class="chat-msg-info name-date">
                          <li>Hendrita</li>
                          <li>
                            <span class="chat-time"
                              >09:30 am<i class="fa-solid fa-check-double read"></i
                            ></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="card-footer">
                <div class="input-group">
                  <div class="btn-file btn">
                    <i class="feather-plus"></i>
                    <input type="file" />
                  </div>
                  <input
                    class="form-control type_msg mh-auto empty_check"
                    placeholder="Write your message..."
                  />
                  <div class="send-action">
                    <a href="javascript:void(0);"
                      ><i class="fa-solid fa-face-smile"></i
                    ></a>
                    <a href="javascript:void(0);"><i class="feather-mic"></i></a>
                    <button class="btn btn-primary btn_send">
                      <i class="fa fa-paper-plane" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Chat Profile -->
            <div class="chat-cont-profile chat-profile-right" id="task_window">
              <div class="card mb-sm-3 mb-md-0 flex-fill">
                <div class="card-header">
                  <div class="profile-wrap">
                    <div class="chat-profile">
                      <a
                        id="back_user_lists"
                        href="javascript:void(0)"
                        class="back-user-list"
                        @click="handleBackButtonOneClick"
                      >
                        <i class="fas fa-chevron-left"></i>
                      </a>
                      <div class="chat-profile-img">
                        <img src="@/assets/img/profiles/chat-profile-01.jpg" alt="User" />
                      </div>
                      <div class="chat-profile-info">
                        <h6>Hendrita</h6>
                        <p>Newyork</p>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href="javascript:void(0);"><i class="feather-mail"></i></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);"><i class="feather-phone"></i></a>
                      </li>
                      <li>
                        <a href="javascript:void(0);"><i class="feather-map-pin"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-body">
                  <div class="chat-pro-list">
                    <ul>
                      <li>
                        <span class="role-title"
                          ><i class="fa-solid fa-briefcase"></i>Company</span
                        ><span class="role-info">Company Name</span>
                      </li>
                      <li>
                        <span class="role-title"
                          ><i class="fa-solid fa-user"></i>Role</span
                        ><span class="role-info">Co-founder</span>
                      </li>
                      <li>
                        <span class="role-title"
                          ><i class="fa-solid fa-phone"></i>Phone</span
                        ><span class="role-info">+1 123 456 7890</span>
                      </li>
                      <li>
                        <span class="role-title"
                          ><i class="fa-solid fa-envelope"></i>Email</span
                        ><span class="role-info">info@johnsteven.com</span>
                      </li>
                    </ul>
                  </div>
                  <div class="chat-media">
                    <div class="chat-media-title">
                      <h6>Shared Media</h6>
                      <span>(3475 items)</span>
                    </div>
                    <div class="media-list">
                      <ul class="nav">
                        <li>
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="tab"
                            data-bs-target="#chat-photo"
                            class="active"
                            >Photos</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="tab"
                            data-bs-target="#chat-file"
                            >File</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="tab"
                            data-bs-target="#chat-vdo"
                            >Video</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="tab"
                            data-bs-target="#chat-link"
                            >Link</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="tab-content pt-0">
                      <div class="tab-pane active" id="chat-photo">
                        <div class="photo-list">
                          <ul>
                            <li>
                              <img src="@/assets/img/services/service-03.jpg" alt="img" />
                            </li>
                            <li>
                              <img src="@/assets/img/services/service-04.jpg" alt="img" />
                            </li>
                            <li>
                              <img src="@/assets/img/services/service-05.jpg" alt="img" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="chat-vdo">
                        <div class="photo-list">
                          <ul>
                            <li>
                              <img src="@/assets/img/services/service-01.jpg" alt="img" />
                            </li>
                            <li>
                              <img src="@/assets/img/services/service-04.jpg" alt="img" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="chat-file">
                        <div class="photo-file">
                          <ul>
                            <li><i class="feather-file-text me-2"></i> admin_v1.0.zip</li>
                            <li><i class="feather-file-text me-2"></i> test.pdf</li>
                          </ul>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="chat-link">
                        <div class="photo-link">
                          <ul>
                            <li>
                              <a href="javascript:;"
                                ><i class="feather-link-2 me-2"></i> Sed ut
                                perspiciatis</a
                              >
                            </li>
                            <li>
                              <a href="javascript:;"
                                ><i class="feather-link-2 me-2"></i> https:example.com</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="chat-notification">
                      <ul>
                        <li>
                          <p><i class="fa-solid fa-bell"></i> Notifications</p>
                          <div
                            class="status-toggle blue-tog d-flex justify-content-sm-end align-items-center"
                          >
                            <input
                              type="checkbox"
                              id="status_1"
                              class="check"
                              checked=""
                            />
                            <label for="status_1" class="checktoggle">checkbox</label>
                          </div>
                        </li>
                        <li>
                          <p><i class="fa-solid fa-star"></i> Add To Favourites</p>
                          <div
                            class="status-toggle blue-tog d-flex justify-content-sm-end align-items-center"
                          >
                            <input
                              type="checkbox"
                              id="status_2"
                              class="check"
                              checked=""
                            />
                            <label for="status_2" class="checktoggle">checkbox</label>
                          </div>
                        </li>
                        <li>
                          <p><i class="fa-solid fa-volume-xmark"></i> Mute</p>
                          <div
                            class="status-toggle blue-tog d-flex justify-content-sm-end align-items-center"
                          >
                            <input type="checkbox" id="status_3" class="check" />
                            <label for="status_3" class="checktoggle">checkbox</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="clear-list">
                      <ul>
                        <li>
                          <a href="javascript:;"
                            ><i class="feather-trash-2"></i>Clear Chat</a
                          >
                        </li>
                        <li>
                          <a href="javascript:;"
                            ><i class="feather-external-link"></i>Export Chat</a
                          >
                        </li>
                        <li>
                          <a href="javascript:;"
                            ><i class="feather-alert-circle"></i>Report Contact</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Chat Content -->
          </div>
          <!-- /Chat Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFullWidth: false,
      isChatSlided: false,
    };
  },
  methods: {
    toggleWindow() {
      this.isFullWidth = !this.isFullWidth;
    },
    toggleChat() {
      if (window.innerWidth <= 1015) {
        this.isChatSlided = !this.isChatSlided;
      }
    },
    hasClass(element, className) {
      return element.classList.contains(className);
    },
    handleBackButtonClick() {
      if (window.innerWidth <= 1015) {
        this.isChatSlideActive = false;
      }
    },
    handleBackButtonOneClick() {
      if (window.innerWidth <= 767) {
        this.isChatSlideActive = false;
      }
    },
  },
  mounted() {
    if (window.innerWidth > 1015) {
      this.isChatSlided = false;
    }

    const backButton = document.getElementById("back_user_list");

    backButton.addEventListener("click", (event) => {
      if (window.innerWidth <= 1015) {
        this.isChatSlided = false; // Remove 'chat-slide' class here
      }
      event.preventDefault();
    });

    if (window.innerWidth > 767) {
      this.isChatSlided = false;
    }

    const backButtonOne = document.getElementById("back_user_lists");

    backButtonOne.addEventListener("click", (event) => {
      if (window.innerWidth <= 767) {
        this.isChatSlided = false; // Remove 'chat-slide' class here
      }
      event.preventDefault();
    });
  },
};
</script>
