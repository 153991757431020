<template>
  <div class="addition-service card-section">
    <div class="heading-service">
      <h4>Gallery</h4>
    </div>
    <div class="form-uploads">
      <div class="form-uploads-path">
        <img src="@/assets/admin_img/icons/upload.svg" alt="img" />
        <div class="file-browse">
          <h6>Drag & drop image or</h6>
          <div class="file-browse-path">
            <input type="file" />
            <a href="javascript:void(0);"> Browse</a>
          </div>
        </div>
        <h5>Supported formates: JPEG, PNG</h5>
      </div>
    </div>
    <div class="file-preview">
      <label class="form-label">Select Default Image</label>
      <!-- <ul class="gallery-selected-img">
                <li>
                    <div class="img-preview">
                        <img src="@/assets/admin_img/services/service-01.jpg" alt="Service Image">
                        <a href="javascript:void(0);" class="remove-gallery"><i class="feather feather-trash-2"></i></a>
                    </div>
                    <label class="custom_check">
                        <input type="radio" name="gallery" checked>
                        <span class="checkmark"></span>
                    </label>
                </li>
                <li>
                    <div class="img-preview">
                        <img src="@/assets/admin_img/services/service-02.jpg" alt="Service">
                        <a href="javascript:void(0);" class="remove-gallery"><i class="feather feather-trash-2"></i></a>
                    </div>
                    <label class="custom_check">
                        <input type="radio" name="gallery">
                        <span class="checkmark"></span>
                    </label>
                </li>
                <li>
                    <div class="img-preview">
                        <img src="@/assets/admin_img/services/service-03.jpg" alt="Service Image">
                        <a href="javascript:void(0);" class="remove-gallery"><i class="feather feather-trash-2"></i></a>
                    </div>
                    <label class="custom_check">
                        <input type="radio" name="gallery">
                        <span class="checkmark"></span>
                    </label>
                </li>
                <li>
                    <div class="img-preview">
                        <img src="@/assets/admin_img/services/service-04.jpg" class="img-fluid thumbnail" alt="image">
                        <a href="javascript:void(0);" class="remove-gallery"><i class="feather feather-trash-2"></i></a>
                    </div>
                    <label class="custom_check">
                        <input type="radio" name="gallery">
                        <span class="checkmark"></span>
                    </label>
                </li>
            </ul> -->
      <ul class="gallery-selected-img">
        <li v-for="(image, index) in images" :key="index">
          <div class="img-preview">
            <img :src="image.src" :alt="image.alt" />
            <a
              href="javascript:void(0);"
              class="remove-gallery"
              @click="removeImage(index)"
            >
              <i class="feather feather-trash-2"></i>
            </a>
          </div>
          <label class="custom_check">
            <input type="radio" name="gallery" :checked="index === selectedImageIndex" />
            <span class="checkmark"></span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images: [
        {
          src: require("@/assets/admin_img/services/service-01.jpg"),
          alt: "Service Image",
        },
        { src: require("@/assets/admin_img/services/service-02.jpg"), alt: "Service" },
        {
          src: require("@/assets/admin_img/services/service-03.jpg"),
          alt: "Service Image",
        },
        { src: require("@/assets/admin_img/services/service-04.jpg"), alt: "Image" },
      ],
      selectedImageIndex: 0, // Set the initial selected image index
    };
  },
  methods: {
    removeImage(index) {
      // Remove the image at the specified index from the `images` array
      this.images.splice(index, 1);

      // Update the selectedImageIndex if needed
      if (index === this.selectedImageIndex) {
        this.selectedImageIndex = 0; // Set to the first image after deletion
      }
    },
  },
};
</script>
