<template>
  <!-- Edit Coupon -->
  <div class="modal fade" id="edit-coupon">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Coupon</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input type="text" class="form-control" placeholder="Name" />
            </div>
            <div class="mb-3">
              <label class="form-label">Coupon</label>
              <input type="text" class="form-control" placeholder="Coupon" />
            </div>
            <div class="mb-3">
              <label class="form-label">Type</label>
              <vue-select :options="CouponsType" id="couponstype" placeholder="English" />
            </div>
            <div class="mb-3">
              <label class="form-label">Discount</label>
              <input type="text" class="form-control" value="5%" />
            </div>
            <div class="mb-3">
              <label class="form-label">Limit</label>
              <input type="number" value="1" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Used</label>
              <input type="number" value="1" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Valid Date</label>
              <input type="date" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Services Name</label>
              <vue3-tags-input
                class="input-tags form-control"
                type="text"
                data-role="tagsinput"
                name="ServiceCoupons"
                id="servicecoupons"
                :tags="tags"
              />
            </div>
            <div class="form-grouphead d-flex justify-content-between mb-4">
              <h2>Status</h2>
              <div class="active-switch d-flex align-items-center">
                <h6>Enable</h6>
                <label class="switch">
                  <input type="checkbox" checked="" />
                  <span class="sliders round"></span>
                </label>
              </div>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Coupon -->

  <!-- Delete Coupon -->
  <div class="modal fade" id="delete-coupon">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body pt-0">
          <div class="text-center">
            <i class="feather feather-trash-2 text-danger fs-1"></i>
            <div class="mt-4">
              <h4>Delete Coupon?</h4>
              <p class="text-muted mb-0">Are you sure want to delete this?</p>
            </div>
          </div>
          <div class="d-flex gap-2 justify-content-center mt-4">
            <button type="button" class="btn w-sm btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn w-sm btn-danger">Yes, Delete It!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Coupon -->
</template>
<script>
import Vue3TagsInput from "vue3-tags-input";
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      CouponsType: ["English", "French"],
      tags: ["House Cleaning", "Painting"],
    };
  },
};
</script>
