<template>
  <!-- Add Review -->
  <div class="modal fade custom-modal" id="add-review">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Write A Review</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form @submit.prevent="submitFormOne">
            <div class="write-review">
              <div class="review-add">
                <div class="rev-img">
                  <img src="@/assets/img/services/service-19.jpg" alt="image" />
                </div>
                <div class="rev-info">
                  <h6>Computer Services</h6>
                  <p>Newyork, USA</p>
                </div>
              </div>
              <div class="form-group form-info">
                <label class="col-form-label">Rate The Service</label>
                <div class="rating rating-select mb-0">
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                </div>
              </div>
              <div class="form-group form-info">
                <label class="col-form-label">Write your Review</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Please write your review"
                ></textarea>
              </div>
              <div class="modal-submit text-end">
                <button type="submit" class="btn btn-primary">Send</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Review -->

  <!-- Delete Account -->
  <div class="modal fade custom-modal" id="del-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sureyou want to delete This Account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <b-button type="submit" variant="danger">Delete Account</b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/login");
    },
    submitFormOne() {
      this.$router.push("/provider-dashboard");
    },
  },
};
</script>
