<template>
  <!-- serve you the best cleaning service! -->
  <section class="our-expert-six-section">
    <div class="container">
      <div class="section-heading section-heading-six">
        <div class="row align-items-end">
          <div class="col-md-6 aos" data-aos="fade-up">
            <div class="reason-six">
              <img src="@/assets/img/icons/window-cleaner-detergent.svg" alt="" />
              <p>SERVE YOU THE BEST CLEANING SERVICE</p>
            </div>
            <h2>Our Expert <span>Team</span></h2>
          </div>
          <div class="col-md-6 aos" data-aos="fade-up">
            <router-link to="/categories" class="afford-btn">Explore All</router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="our-expert-six">
            <div class="our-expert-img">
              <img src="@/assets/img/gallery/gallery-04.jpg" alt="" class="img-fluid" />
            </div>
            <div class="our-expert-six-content">
              <h6>William James</h6>
              <p>Head In Industrial Cleaning</p>
              <div class="rating">
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fas fa-star"></i>
              </div>
              <h5>$30.00<span>/hr</span></h5>
              <a href="javascript:;" class="btn btn-primary btn-view-six">View Details</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="our-expert-six">
            <div class="our-expert-img">
              <img src="@/assets/img/gallery/gallery-05.jpg" alt="" class="img-fluid" />
            </div>
            <div class="our-expert-six-content">
              <h6>William James</h6>
              <p>Head In Industrial Cleaning</p>
              <div class="rating">
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fas fa-star"></i>
              </div>
              <h5>$30.00<span>/hr</span></h5>
              <a href="javascript:;" class="btn btn-primary btn-view-six">View Details</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="our-expert-six">
            <div class="our-expert-img">
              <img src="@/assets/img/gallery/gallery-06.jpg" alt="" class="img-fluid" />
            </div>
            <div class="our-expert-six-content">
              <h6>William James</h6>
              <p>Head In Industrial Cleaning</p>
              <div class="rating">
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fas fa-star"></i>
              </div>
              <h5>$30.00<span>/hr</span></h5>
              <a href="javascript:;" class="btn btn-primary btn-view-six">View Details</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="our-expert-six">
            <div class="our-expert-img">
              <img src="@/assets/img/gallery/gallery-07.jpg" alt="" class="img-fluid" />
            </div>
            <div class="our-expert-six-content">
              <h6>William James</h6>
              <p>Head In Industrial Cleaning</p>
              <div class="rating">
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fas fa-star"></i>
              </div>
              <h5>$30.00<span>/hr</span></h5>
              <a href="javascript:;" class="btn btn-primary btn-view-six">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /serve you the best cleaning service! -->

  <!-- Customer Reviews -->
  <section class="customer-reviews-six-section">
    <div class="container">
      <div class="section-heading section-heading-six">
        <div class="row align-items-end">
          <div class="col-md-6 aos" data-aos="fade-up">
            <div class="reason-six">
              <img src="@/assets/img/icons/cleaning-mop.svg" alt="" />
              <p>AFFORDABLE & RELIABLE</p>
            </div>
            <h2>Customer <span>Reviews</span></h2>
          </div>
          <div class="col-md-6 aos" data-aos="fade-up">
            <router-link to="categories" class="afford-btn">Explore All</router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="customer-review-main-six">
            <div class="customer-review-top">
              <img src="@/assets/img/profiles/avatar-20.jpg" alt="" />
              <h5>Maria Williams</h5>
              <p>
                There are many variations of passages of Lorem Ipsum available, but the
                majority have suffered.
              </p>
            </div>
            <div class="customer-review-bottom">
              <div class="rating">
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fas fa-star"></i>
              </div>
            </div>
            <div class="customer-review-quote">
              <img src="@/assets/img/icons/reviews-quote.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="customer-review-main-six">
            <div class="customer-review-top">
              <img src="@/assets/img/profiles/avatar-18.jpg" alt="" />
              <h5>Bella Williams</h5>
              <p>
                There are many variations of passages of Lorem Ipsum available, but the
                majority have suffered.
              </p>
            </div>
            <div class="customer-review-bottom">
              <div class="rating">
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fas fa-star me-1"></i>
                <i class="fas fa-star me-1"></i>
                <i class="fas fa-star me-1"></i>
              </div>
            </div>
            <div class="customer-review-quote">
              <img src="@/assets/img/icons/reviews-quote.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="customer-review-main-six">
            <div class="customer-review-top">
              <img src="@/assets/img/profiles/avatar-11.jpg" alt="" />
              <h5>Cristina Williams</h5>
              <p>
                There are many variations of passages of Lorem Ipsum available, but the
                majority have suffered.
              </p>
            </div>
            <div class="customer-review-bottom">
              <div class="rating">
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fa-solid fa-star filled me-1"></i>
                <i class="fas fa-star me-1"></i>
                <i class="fas fa-star me-1"></i>
              </div>
            </div>
            <div class="customer-review-quote">
              <img src="@/assets/img/icons/reviews-quote.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Customer Reviews -->
</template>
