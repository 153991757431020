<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Booking List</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select
                          :options="SelectIn"
                          id="selectin"
                          placeholder="A -> Z"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="tab-sets">
              <div class="tab-contents-sets">
                <ul>
                  <li>
                    <router-link to="/admin/booking">All Booking</router-link>
                  </li>
                  <li>
                    <router-link to="/admin/pending-booking">Pending </router-link>
                  </li>
                  <li>
                    <router-link to="/admin/inprogress-booking" class="active"
                      >Inprogress
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/admin/completed-booking">Completed </router-link>
                  </li>
                  <li>
                    <router-link to="/admin/cancelled-booking">Cancelled</router-link>
                  </li>
                </ul>
              </div>
              <div class="tab-contents-count">
                <h6>Showing 8-10 of 84 results</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Provider'">
                      <a href="javascript:void(0);" class="table-profileimage">
                        <img
                          :src="
                            require(`@/assets/admin_img/customer/${record.Provider_img}`)
                          "
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Provider }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'User'">
                      <a href="javascript:void(0);" class="table-profileimage">
                        <img
                          :src="require(`@/assets/admin_img/customer/${record.User_img}`)"
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.User }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'Service'">
                      <a href="javascript:void(0);" class="table-imgname">
                        <img
                          :src="
                            require(`@/assets/admin_img/services/${record.Service_img}`)
                          "
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Service }}</span>
                      </a>
                    </template>

                    <template v-else-if="column.key === 'Status'">
                      <td>
                        <h6 :class="record.class">{{ record.Status }}</h6>
                      </td>
                    </template>

                    <template v-else-if="column.key === 'id'">
                      <div class="table-select">
                        <div class="form-group mb-0">
                          <vue-select :options="StatusIn" placeholder="Select Status" />
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Booking Time",
    dataIndex: "Booking_Time",
    sorter: {
      compare: (a, b) => {
        a = a.Booking_Time.toLowerCase();
        b = b.Booking_Time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Provider",
    dataIndex: "Provider",
    key: "Provider",
    sorter: {
      compare: (a, b) => {
        a = a.Provider.toLowerCase();
        b = b.Provider.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User",
    dataIndex: "User",
    key: "User",
    sorter: {
      compare: (a, b) => {
        a = a.User.toLowerCase();
        b = b.User.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: {
      compare: (a, b) => {
        a = a.status.toLowerCase();
        b = b.status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Date: "28 Sep 2023",
    Booking_Time: "10:00:00 - 11:00:00",
    Provider_img: "user-01.jpg",
    Provider: "John Smith",
    User_img: "user-03.jpg",
    User: "Sharon",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    class: "badge-inactive",
    Amount: "$80",
    Status: "Inprogress",
    status: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "2",
    Date: "10 Sep 2023",
    Booking_Time: "18:00:00 - 19:00:00",
    Provider_img: "user-04.jpg",
    Provider: "Johnny",
    User_img: "user-05.jpg",
    User: "Pricilla",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    class: "badge-inactive",
    Amount: "$50",
    Status: "Inprogress",
    status: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "3",
    Date: "25 Sep 2023",
    Booking_Time: "12:00:00 - 13:00:00",
    Provider_img: "user-06.jpg",
    Provider: "Robert",
    User_img: "user-02.jpg",
    User: "Amanda",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    class: "badge-inactive",
    Amount: "$50",
    Status: "Inprogress",
    status: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "4",
    Date: "08 Sep 2023",
    Booking_Time: "07 Oct 2023 11:22:51",
    Provider_img: "user-09.jpg",
    Provider: "Sharonda",
    User_img: "user-01.jpg",
    User: "James",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    class: "badge-inactive",
    Amount: "$50",
    Status: "Inprogress",
    status: "07 Oct 2023 11:22:51",
    Action: "",
  },
];
export default {
  data() {
    return {
      SelectIn: ["A -> Z", "Z -> A"],
      StatusIn: ["Select Status", "Pending", "Inprogress", "Completed", "cancelled"],
      columns,
      data,
    };
  },
};
</script>
