<template>
  <div class="main-wrapper">
    <layouts></layouts>

    <div class="page-wrapper">
      <div class="bg-img">
        <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg1" />
        <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg2" />
      </div>

      <breadcrumb :title="title" :text="text" :text1="text1" />

      <div class="content">
        <div class="container">
          <div class="row">
            <!-- Terms & Conditions -->
            <div class="col-md-12">
              <div class="terms-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
                  illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                  voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam
                  eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                  voluptatem.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <ul>
                  <li>Nunc pulvinar efficitur interdum.</li>
                  <li>Donec feugiat feugiat pulvinar.</li>
                  <li>
                    Suspendisse eu risus feugiat, pellentesque arcu eu, molestie lorem.
                  </li>
                  <li>Duis non leo commodo, euismod ipsum a, feugiat libero.</li>
                </ul>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
                  illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                  explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                  voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam
                  eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                  voluptatem.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <div class="terms-btn">
                  <a href="javascript:;" class="btn btn-secondary">Not right now...</a>
                  <a href="javascript:;" class="btn btn-primary">I agree with terms</a>
                </div>
              </div>
            </div>
            <!-- /Terms & Conditions -->
          </div>
        </div>
      </div>
    </div>
    <truelyfooter />
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Terms & Condition",
      text: "Home",
      text1: "Terms & Condition",
    };
  },
};
</script>
