<template>
  <!-- Header -->
  <header class="header">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/" class="navbar-brand logo-small">
            <img src="@/assets/img/logo-small.png" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);" @click="closeMenu">
              <i class="fas fa-times"></i
            ></a>
          </div>
          <headerMenu />
        </div>
        <ul class="nav header-navbar-rht">
          <!-- Language -->
          <li class="nav-item dropdown lang-nav">
            <div class="flag-dropdown">
              <a
                class="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
                href="javascript:;"
                role="button"
              >
                <i class="feather-globe me-1"></i> <span>EN</span>
              </a>
              <div class="dropdown-menu">
                <a href="javascript:void(0);" class="dropdown-item"> English </a>
                <a href="javascript:void(0);" class="dropdown-item"> French </a>
                <a href="javascript:void(0);" class="dropdown-item"> Spanish </a>
                <a href="javascript:void(0);" class="dropdown-item"> German </a>
              </div>
            </div>
          </li>
          <!-- /Language -->

          <!-- Language -->
          <li class="nav-item flag-nav dropdown">
            <div class="flag-dropdown">
              <a
                class="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
                href="javascript:;"
                role="button"
              >
                <img
                  src="@/assets/img/flags/us.png"
                  class="rounded-circle me-1"
                  height="16"
                  alt=""
                />
                <span>USD</span>
              </a>
              <div class="dropdown-menu">
                <a href="javascript:void(0);" class="dropdown-item">
                  <img src="@/assets/img/flags/us.png" alt="" height="16" /> USD
                </a>
                <a href="javascript:void(0);" class="dropdown-item">
                  <img src="@/assets/img/flags/fr.png" alt="" height="16" /> Euro
                </a>
              </div>
            </div>
          </li>
          <!-- /Language -->

          <!-- Chat -->
          <li class="nav-item logged-item msg-nav">
            <router-link to="/customer-chat" class="nav-link">
              <img src="@/assets/img/icons/message-icon.svg" alt="" />
            </router-link>
          </li>
          <!-- /Chat -->

          <!-- Notifications -->
          <li class="nav-item dropdown logged-item noti-nav noti-wrapper">
            <a
              href="javascript:;"
              class="dropdown-toggle nav-link notify-link"
              data-bs-toggle="dropdown"
            >
              <span class="noti-message">1</span>
              <img src="@/assets/img/icons/bell-icon.svg" alt="Bell" />
            </a>
            <div class="dropdown-menu notify-blk notifications">
              <div class="topnav-dropdown-header">
                <div>
                  <p class="notification-title">Notifications <span> 3 </span></p>
                </div>
                <a href="javascript:void(0)" class="clear-noti"
                  ><i class="fa-regular fa-circle-check"></i> Mark all as read
                </a>
              </div>
              <div class="noti-content">
                <ul class="notification-list">
                  <li class="notification-message">
                    <router-link to="notification">
                      <div class="media noti-img d-flex">
                        <span class="avatar avatar-sm flex-shrink-0">
                          <img
                            class="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="@/assets/img/notifications/avatar-01.jpg"
                          />
                        </span>
                        <div class="media-body flex-grow-1">
                          <p class="noti-details">
                            Lex Murphy left 6 comments on Isla Nublar SOC2 compliance
                            report
                          </p>
                          <p class="noti-time">
                            <span class="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </li>
                  <li class="notification-message">
                    <div class="media noti-img d-flex">
                      <router-link to="notification">
                        <span class="avatar avatar-sm flex-shrink-0">
                          <img
                            class="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="@/assets/img/notifications/avatar-02.jpg"
                          />
                        </span>
                      </router-link>
                      <div class="media-body flex-grow-1">
                        <router-link to="notification"
                          ><p class="noti-details">
                            Ray Arnold requested access to UNIX directory tree hierarchy
                          </p></router-link
                        >
                        <p class="noti-time">
                          <span class="notification-time">1 min</span>
                        </p>
                        <div class="notify-btns">
                          <button class="btn btn-secondary">Decline</button>
                          <button class="btn btn-primary">Accept</button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="notification-message">
                    <router-link to="notification">
                      <div class="media noti-img d-flex">
                        <span class="avatar avatar-sm flex-shrink-0">
                          <img
                            class="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="@/assets/img/notifications/avatar-03.jpg"
                          />
                        </span>
                        <div class="media-body flex-grow-1">
                          <p class="noti-details">
                            Dennis Nedry commented on Isla Nublar SOC2 compliance report
                          </p>
                          <p class="noti-time">
                            <span class="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </li>
                  <li class="notification-message">
                    <router-link to="notification">
                      <div class="media noti-img d-flex">
                        <span class="avatar avatar-sm flex-shrink-0">
                          <img
                            class="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="@/assets/img/notifications/avatar-04.jpg"
                          />
                        </span>
                        <div class="media-body flex-grow-1">
                          <p class="noti-details">
                            John Hammond created Isla Nublar SOC2 compliance report
                          </p>
                          <p class="noti-time">
                            <span class="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="topnav-dropdown-footer">
                <router-link to="notification"
                  >View All Notifications
                  <img src="@/assets/img/icons/arrow-right-01.svg" alt="Arrow"
                /></router-link>
              </div>
            </div>
          </li>
          <!-- /Notifications -->

          <!-- User Menu -->
          <li class="nav-item dropdown has-arrow account-item">
            <a
              href="javascript:;"
              class="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <div class="user-infos">
                <span class="user-img">
                  <img
                    src="@/assets/img/profiles/avatar-02.jpg"
                    class="rounded-circle"
                    alt=""
                  />
                </span>
                <div class="user-info">
                  <h6>John Smith</h6>
                  <p>Demo User</p>
                </div>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end emp">
              <router-link class="dropdown-item" to="/customer-profile">
                <i class="feather-user me-2"></i> Profile
              </router-link>
              <router-link class="dropdown-item" to="/">
                <i class="feather-log-out me-2"></i> Logout
              </router-link>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
      </nav>
    </div>
  </header>
  <!-- /Header -->
</template>


<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    toggleSidebar() {
      const body = document.body;
      body.classList.toggle("menu-opened");
    },
    closeMenu() {
      const body = document.body;
      body.classList.remove("menu-opened");
    },
  },
}
</script>