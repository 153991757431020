<template>
  <ul class="main-nav">
    <li class="has-submenu" :class="Home ? 'active' : 'notactive'">
        <router-link to="/mari">Home</router-link>
    </li>

    <li class="has-submenu" :class="Monitoring ? 'active' : 'notactive'">
        <router-link to="/mari/monitoring">Monitoring</router-link>
    </li>

    <li class="has-submenu" :class="Finance ? 'active' : 'notactive'">
        <router-link to="/mari/finance-menu">Finance</router-link>
    </li>

    <li class="has-submenu" :class="Vehicle ? 'active' : 'notactive'">
        <router-link to="/mari/vehicle">Kendaraan</router-link>
    </li>

    <li class="has-submenu" :class="Profile ? 'active' : 'notactive'">
        <router-link to="/mari/pengaturan">Profile</router-link>
    </li>

    <!-- <li class="has-submenu" :class="Order ? 'active' : 'notactive'">
        <router-link to="/mari/order">Order</router-link>
    </li>

    <li class="has-submenu" :class="Customer ? 'active' : 'notactive'">
        <router-link to="/mari/pelanggan">Pelanggan</router-link>
    </li> -->
  </ul>
</template>

<script>
export default {
  computed: {
    Home() {
      return (
        this.$route.name == "/mari"
      );
    },
    Monitoring() {
      return (
        this.$route.name == "/mari/monitoring"
      );
    },
    Vehicle() {
      return (
        this.$route.name == "/mari/vehicle"
      );
    },
    // Order() {
    //   return (
    //     this.$route.name == "/mari/order"
    //   );
    // },
    // Customer() {
    //   return (
    //     this.$route.name == "/mari/customer"
    //   );
    // },
    Finance() {
      return (
        this.$route.name == "/mari/finance-menu"
      );
    },
    Profile() {
      return (
        this.$route.name == "/mari/pengaturan"
      );
    },
    currentPath() {
      return this.$route.name;
    },
  },
};
</script>
