<template>
  <!-- Service Section -->
  <section class="popular-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="section-heading-two">
            <h2>Most Popular Services</h2>
            <p>Explore the greates our services. You won’t be disappointed</p>
          </div>
        </div>
        <div class="col-md-6 text-md-end aos" data-aos="fade-up">
          <div class="owl-nav mynav1"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel popular-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indextwopopular" :key="item.id">
                <div class="service-widget service-widget-space service-two">
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="fav-item">
                      <router-link to="/categories"
                        ><span class="item-cat">{{ item.badge }}</span></router-link
                      >
                      <a href="javascript:void(0)" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                    <div class="item-info">
                      <router-link to="/providers"
                        ><span class="item-img"
                          ><img
                            :src="require(`@/assets/img/profiles/${item.avatar}`)"
                            class="avatar"
                            alt="" /></span
                      ></router-link>
                    </div>
                  </div>
                  <div class="service-content">
                    <h3 class="title">
                      <router-link to="/service-details">{{ item.title }}</router-link>
                    </h3>
                    <p>
                      <i class="feather-map-pin"></i>{{ item.country
                      }}<span class="rate"
                        ><i class="feather-phone"></i>+1 123 456 7890</span
                      >
                    </p>
                    <div class="serv-info">
                      <div class="rating">
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <span>(10)</span>
                      </div>
                      <h6>
                        {{ item.offer }}<span class="old-price">{{ item.price }}</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Service Section -->

  <!-- /Providers Section -->
  <section class="providers-section-two">
    <div class="container">
      <div class="row">
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="section-heading-two white-text">
            <h2>Top Providers</h2>
            <p>Sed ut perspiciatis unde omnis iste natus error</p>
          </div>
        </div>
        <div class="col-md-6 text-md-end aos" data-aos="fade-up">
          <router-link to="/providers" class="btn btn-pink btn-viewall"
            >View All<i class="feather-arrow-right-circle"></i
          ></router-link>
        </div>
      </div>
      <div class="row aos" data-aos="fade-up">
        <div class="col-lg-3 col-sm-6">
          <div class="providerset provider-box">
            <div class="providerset-img">
              <router-link to="/provider-details">
                <img src="@/assets/img/provider/provider-11.jpg" alt="img" />
              </router-link>
            </div>
            <div class="providerset-content">
              <div class="providerset-price">
                <div class="providerset-name">
                  <h4>
                    <router-link to="/provider-details">John Smith</router-link
                    ><i class="fa fa-check-circle" aria-hidden="true"></i>
                  </h4>
                  <span>Electrician</span>
                </div>
                <div class="providerset-prices">
                  <h6>$20.00<span>/hr</span></h6>
                </div>
              </div>
              <div class="provider-rating">
                <div class="rate">
                  <i class="fa-solid fa-star filled me-1"></i><span>4.8</span>
                </div>
                <router-link to="/provider-details" class="btn btn-pink"
                  >View Details</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="providerset provider-box">
            <div class="providerset-img">
              <router-link to="/provider-details">
                <img src="@/assets/img/provider/provider-12.jpg" alt="img" />
              </router-link>
            </div>
            <div class="providerset-content">
              <div class="providerset-price">
                <div class="providerset-name">
                  <h4>
                    <router-link to="/provider-details">Michael</router-link
                    ><i class="fa fa-check-circle" aria-hidden="true"></i>
                  </h4>
                  <span>Carpenter</span>
                </div>
                <div class="providerset-prices">
                  <h6>$50.00<span>/hr</span></h6>
                </div>
              </div>
              <div class="provider-rating">
                <div class="rate">
                  <i class="fa-solid fa-star filled me-1"></i><span>4.8</span>
                </div>
                <router-link to="/provider-details" class="btn btn-pink"
                  >View Details</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="providerset provider-box">
            <div class="providerset-img">
              <router-link to="/provider-details">
                <img src="@/assets/img/provider/provider-13.jpg" alt="img" />
              </router-link>
            </div>
            <div class="providerset-content">
              <div class="providerset-price">
                <div class="providerset-name">
                  <h4>
                    <router-link to="/provider-details">Antoinette</router-link
                    ><i class="fa fa-check-circle" aria-hidden="true"></i>
                  </h4>
                  <span>Cleaner</span>
                </div>
                <div class="providerset-prices">
                  <h6>$25.00<span>/hr</span></h6>
                </div>
              </div>
              <div class="provider-rating">
                <div class="rate">
                  <i class="fa-solid fa-star filled me-1"></i><span>4.8</span>
                </div>
                <router-link to="/provider-details" class="btn btn-pink"
                  >View Details</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="providerset provider-box">
            <div class="providerset-img">
              <router-link to="/provider-details">
                <img src="@/assets/img/provider/provider-14.jpg" alt="img" />
              </router-link>
            </div>
            <div class="providerset-content">
              <div class="providerset-price">
                <div class="providerset-name">
                  <h4>
                    <router-link to="/provider-details">Thompson</router-link
                    ><i class="fa fa-check-circle" aria-hidden="true"></i>
                  </h4>
                  <span>Mechanic</span>
                </div>
                <div class="providerset-prices">
                  <h6>$25.00<span>/hr</span></h6>
                </div>
              </div>
              <div class="provider-rating">
                <div class="rate">
                  <i class="fa-solid fa-star filled me-1"></i><span>4.8</span>
                </div>
                <router-link to="/provider-details" class="btn btn-pink"
                  >View Details</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Providers Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indextwopopular from "@/assets/json/indextwopopular.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indextwopopular: indextwopopular,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
