<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />
        <div class="content container-fluid">
          <div class="row">
            <!-- Security Settings -->
            <div class="col-md-12">
              <div class="widget-title">
                <h4>Profile Verification</h4>
              </div>
              <div class="linked-item">
                <div class="row">
                  <div class="col-md-8">
                    <div class="linked-acc" id="phone-veri">
                      <span class="link-icon">
                        <img src="@/assets/img/icons/phone-call.svg" alt="Icons" />
                      </span>
                      <div class="linked-info">
                        <h6>
                          <a href="javascript:;"> Phone Number Verification </a>
                        </h6>
                        <p>Not yet verified</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-md-end">
                    <div class="verfification-modal-block">
                      <a
                        href="javascript:void(0);"
                        class="link-close"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        data-bs-title="Not Verified"
                        ><i class="fa-solid fa-circle-xmark"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        class="btn btn-primary btn-connect"
                        data-bs-toggle="modal"
                        data-bs-target="#change-phone"
                        >Change</a
                      >
                      <a href="javascript:void(0);" class="btn-acc">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="linked-item">
                <div class="row">
                  <div class="col-md-8">
                    <div class="linked-acc">
                      <span class="link-icon">
                        <img src="@/assets/img/icons/mail-envelope.svg" alt="Icons" />
                      </span>
                      <div class="linked-info">
                        <h6>
                          <a href="javascript:;"> Email Verification </a>
                        </h6>
                        <p>Verified</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-md-end">
                    <div class="verfification-modal-block">
                      <a
                        href="javascript:void(0);"
                        class="link-close"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        data-bs-title="Verified"
                        ><i class="fa-solid fa-circle-check"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        class="btn btn-primary btn-connect"
                        data-bs-toggle="modal"
                        data-bs-target="#change-mail"
                        >Change</a
                      >
                      <a href="javascript:void(0);" class="btn-acc">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="linked-item">
                <div class="row">
                  <div class="col-md-8">
                    <div class="linked-acc">
                      <span class="link-icon">
                        <img src="@/assets/img/icons/document-pdf.svg" alt="Icons" />
                      </span>
                      <div class="linked-info">
                        <h6>
                          <a href="javascript:;"> Document Verification </a>
                        </h6>
                        <p>Not yet verified</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-md-end">
                    <div class="verfification-modal-block">
                      <a
                        href="javascript:void(0);"
                        class="link-close"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        data-bs-title="Not Verified"
                        ><i class="fa-solid fa-circle-xmark"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        class="btn btn-primary btn-connect"
                        data-bs-toggle="modal"
                        data-bs-target="#change-document"
                        >Change</a
                      >
                      <a href="javascript:void(0);" class="btn-acc">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Security Settings -->
          </div>
        </div>
      </div>
    </div>

    <model></model>
  </div>
</template>
