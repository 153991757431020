<template>
  <div class="provider-body">
    <div class="main-wrapper">
      <dashboardnavbar />

      <div class="page-wrapper">
        <sidebar />

        <providersocialprofilecontent />
      </div>
    </div>
  </div>
</template>
