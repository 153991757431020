<template>
  <div
    id="company-slider"
    class="owl-carousel owl-theme testimonial-slider aos"
    data-aos="fade-up"
  >
    <Carousel
      :autoplay="3000"
      :loop="true"
      :wrap-around="true"
      :settings="settings"
      :breakpoints="breakpoints"
    >
      <Slide v-for="item in indexfourbranch" :key="item.id">
        <div class="company-logos">
          <img
            :src="require(`@/assets/img/icons/${item.image}`)"
            class="img-fluid"
            alt=""
          />
        </div>
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfourbranch from "@/assets/json/indexfourbranch.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexfourbranch: indexfourbranch,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 6,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style>
.testimonial-slider .company-logos {
  margin-right: 24px;
}
</style>
