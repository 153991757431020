import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getProduct(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/product', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addProduct(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/product/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateProduct(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('product/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteProduct(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('product/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }



    },
    getters: {

    }

}