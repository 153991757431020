<template>
  <!-- Header -->
  <header class="header">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/mari/logo_marirental3.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/" class="navbar-brand logo-small">
            <img src="@/assets/img/mari/logo_marirental3.svg" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/icons/message-icon.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);">
              <i class="fas fa-times"></i
            ></a>
          </div>
          <headerMenu />
        </div>
        <!-- <ul class="nav header-navbar-rht">
          <li class="nav-item">
            <router-link class="btn btn-primary" to="/register">Daftar</router-link>
          </li>
        </ul> -->
      </nav>
    </div>
  </header>
  <!-- /Header -->
</template>
