<template>
    <!-- Header -->
    <header class="header header-eight">
        <div class="container">
            <nav class="navbar navbar-expand-lg header-nav">
                <div class="navbar-header">
                    <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar"> 
                        <span class="bar-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </a>
                    <router-link to="/" class="navbar-brand logo">
                        <img src="@/assets/img/logo-02.png" class="img-fluid" alt="Logo">
                    </router-link>
                    <router-link to="/" class="navbar-brand logo-small">
                        <img src="@/assets/img/logo-icon.png" class="img-fluid" alt="Logo">
                    </router-link>
                </div>
                <div class="main-menu-wrapper">
                    <div class="menu-header">
                        <router-link to="/" class="menu-logo">
                            <img src="@/assets/img/logo-02.png" class="img-fluid" alt="Logo">
                        </router-link>
                        <a id="menu_close" class="menu-close" href="javascript:void(0);" @click="closeMenu"> <i class="fas fa-times"></i></a>
                    </div>
                    
                    <headerMenu />

                </div>
                <ul class="nav header-navbar-rht">
                    <li class="nav-item">
                        <router-link class="nav-link header-login" to="/login"><i class="feather-log-in me-2"></i>Login</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link header-login" to="/choose-signup"><i
                                class="feather-user-plus me-2"></i>Register</router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
    <!-- /Header -->
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        toggleSidebar() {
            const body = document.body;
            body.classList.toggle("menu-opened");
        },
        closeMenu() {
            const body = document.body;
            body.classList.remove("menu-opened");
        },
  },
}
</script>