<template>
  <div class="login-body">
    <div class="main-wrapper">
      <mari-header-login />

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-6 mx-auto">
                <div class="login-wrap">
                  <div class="login-header">
                    <h4>Daftar Akun</h4>
                    <p>Daftar sekarang dan gunakan gratis selama 14 hari</p>
                  </div>

                  <!-- Login Form -->
                  <Form class="login-content" @submit="handleRegister" :validation-schema="schema">

                    <div class="form-group">
                      <label>Nama Lengkap</label>
                      <Field name="full_name" type="text" placeholder="Nama Lengkap" class="form-control" />
                      <ErrorMessage name="full_name" class="error-feedback" />
                    </div>

                    <div class="form-group">
                      <label>E-mail</label>
                      <Field name="email" type="text" placeholder="Email" class="form-control" />
                      <ErrorMessage name="email" class="error-feedback" />
                    </div>

                    <div class="form-group">
                      <label>No. HP / Whatsapp</label>
                      <Field name="phone_number" type="text" placeholder="No. Handphone" class="form-control" />
                      <ErrorMessage name="phone_number" class="error-feedback" />
                    </div>

                    <div class="form-group">
                      <label>Nama Bisnis</label>
                      <Field name="name" type="text" placeholder="Nama Bisnis" class="form-control" />
                      <ErrorMessage name="name" class="error-feedback" />
                    </div>

                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>Password</label>
                      </div>
                      <div class="pass-group">
                        <Field  v-if="showPassword" name="password" type="text" placeholder="**********"  class="form-control pass-input" />
                        <Field  v-else name="password" type="password" placeholder="**********"  class="form-control pass-input" />
                        <span
                          class="toggle-password"
                          @click="toggleShow"
                          :class="{
                            'fas fa-eye': showPassword,
                            'fas fa-eye-slash': !showPassword,
                          }"
                        ></span>
                        <ErrorMessage name="password" class="error-feedback" />
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>Konfirmasi Password</label>
                      </div>
                      <div class="pass-group">
                        <Field  v-if="showPassword" name="password_confirmation" type="text" placeholder="**********"  class="form-control pass-input" />
                        <Field  v-else name="password_confirmation" type="password" placeholder="**********"  class="form-control pass-input" />
                        <span
                          class="toggle-password"
                          @click="toggleShow"
                          :class="{
                            'fas fa-eye': showPassword,
                            'fas fa-eye-slash': !showPassword,
                          }"
                        ></span>
                        <ErrorMessage name="password" class="error-feedback" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <label class="custom_check">
                          <input type="checkbox" name="rememberme" class="rememberme" />
                          <span class="checkmark"></span>Remember Me
                        </label>
                      </div>
                    </div>
                    <button class="btn btn-primary" style="width: 100%;" :disabled="loading">
                      <span v-show="loading" class="spinner-border spinner-border-sm"></span> 
                        Submit
                    </button>
                    <p class="no-acc">
                      Sudah Punya Akun ? <router-link to="/mari/login">Login</router-link>
                    </p>
                  </Form>
                  <!-- /Login Form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>  
  </div>  
</template>


<script>
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: 'mari-register',
  components: {
      Form,
      Field,
      ErrorMessage,
  },
  data() {
      const schema = yup.object().shape({
      full_name: yup
        .string()
        .required("Nama lengkap harus diisi!")
        .min(3, "Harus lebih dari 3 karakter!"),
      name: yup
        .string()
        .required("Nama bisnis harus diisi!")
        .min(3, "Harus lebih dari 3 karakter!"),
      email: yup
        .string()
        .required("Email harus diisi!")
        .email("Harus berupa format email!")
        .max(50, "Tidak boleh lebih dari 50 karakter!"),
      password: yup
        .string()
        .required("Password harus diisi!")
        .min(6, "Harus lebih dari 6 karakter!"),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Password tidak sama'),
      phone_number: yup
        .string()
        .required("No HP harus diisi!")
        .min(6, "Harus lebih dari 6 karakter!"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
      showPassword: false,
      password: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    handleRegister(user) {
      let loading = this.$loading.show();
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          loading.hide();
          
          if (data.status == 201) {
            this.$swal.fire({
              icon: 'success',
              text: data.data.message,
              showCancelButton: false,
              confirmButtonText: 'Menuju halaman login',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then(() => {
              this.$router.push({path: '/mari/login'});
            })
          }
        }
      ).catch((error) => {
        console.error(error);
        loading.hide();
        
        // this.$swal.fire({
        //   icon: 'error',
        //   text: 'An error occurred. Please try again.',
        //   showCancelButton: false,
        //   confirmButtonText: 'OK',
        //   allowOutsideClick: false,
        //   allowEscapeKey: false,
        // });
      });
    },
  },
};
</script>
