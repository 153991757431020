<template>
    <div class="main-wrapper">
        <div class="top-bar">
			<h6>50% OFF on Christmas</h6>
			<ul>
				<li>2</li>
				<li>15</li>
				<li>33</li>
				<li>32</li>
			</ul>
			<a href="javascript:;" class="top-close"><i class="feather-x"></i></a>
		</div>
    </div>
</template>

<script>
export default {
	data() {
		return {
			
		}
	},
	mounted() {
		const closeButton = document.querySelector('.top-close');
		const topBar = closeButton.closest('.top-bar');

		closeButton.addEventListener('click', function() {
			topBar.style.display = 'none';
		});
	},	
}
</script>