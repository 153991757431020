<template>
  <div class="col-lg-8 d-flex flex-column">
    <h6 class="user-title">Recent Booking !</h6>
    <div class="table-responsive recent-booking flex-fill">
      <table class="table mb-0">
        <tbody>
          <tr v-for="record in Recentbooking" :key="record.id">
            <td>
              <h2 class="table-avatar">
                <a href="javascript:;" class="avatar avatar-m me-2"
                  ><img
                    class="avatar-img rounded"
                    :src="require(`@/assets/img/services/${record.Img}`)"
                    alt="User Image"
                /></a>
                <a href="javascript:;"
                  >{{ record.Field1
                  }}<span><i class="feather-calendar"></i> {{ record.Date }}</span></a
                >
              </h2>
            </td>
            <td>
              <h2 class="table-avatar table-user">
                <a href="javascript:;" class="avatar avatar-m me-2"
                  ><img
                    class="avatar-img"
                    :src="require(`@/assets/img/profiles/${record.Img1}`)"
                    alt="User Image"
                /></a>
                <a href="javascript:;">
                  {{ record.Field2 }}
                  <span>{{ record.Email }}</span>
                </a>
              </h2>
            </td>
            <td class="text-end">
              <a href="javascript:;" class="btn btn-light-danger">{{ record.Field3 }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Recentbooking from "@/assets/json/dashboardrecent.json";
export default {
  data() {
    return {
      Recentbooking: Recentbooking,
    };
  },
};
</script>
