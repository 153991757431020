<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <sidelinkheading />

      <div class="content w-100">
        <div class="content-page-header content-page-headersplit">
          <h5>Bank Transfer Account</h5>
          <div class="list-btn">
            <ul>
              <li>
                <a
                  class="btn btn-primary"
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#add-account"
                  ><i class="fa fa-plus me-2"></i>Add Account
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="bank-box"
              @click="activate(1)"
              :class="{ active: active_el == 1 }"
            >
              <div class="bank-header">
                <div class="bank-name">
                  <h6>First Bank</h6>
                  <p>**** **** 1832</p>
                </div>
                <div class="bank-info">
                  <p>Holder Name</p>
                  <h6>John Smith</h6>
                </div>
              </div>
              <div class="transfer-listsbottom">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-account"
                  ><i class="feather feather-edit me-2"></i>Edit</a
                >
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#delete"
                  ><i class="feather feather-trash-2 me-2"></i>Delete</a
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="bank-box"
              @click="activate(2)"
              :class="{ active: active_el == 2 }"
            >
              <div class="bank-header">
                <div class="bank-name">
                  <h6>Minster Bank</h6>
                  <p>**** **** 1832</p>
                </div>
                <div class="bank-info">
                  <p>Holder Name</p>
                  <h6>John Smith</h6>
                </div>
              </div>
              <div class="transfer-listsbottom">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-account"
                  ><i class="feather feather-edit me-2"></i>Edit</a
                >
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#delete"
                  ><i class="feather feather-trash-2 me-2"></i>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <payment-model></payment-model>
</template>

<script>
export default {
  name: "adminpayment-settings",
  data() {
    return {
      active_el: 0,
    };
  },
  methods: {
    activate: function (el) {
      this.active_el = el;
    },
  },
};
</script>
