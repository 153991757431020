<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-12">
          <div class="provider-subtitle">
            <h6>Availability</h6>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <!-- Availability Days -->
    <div class="availability-sec">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <div class="row">
            <div class="col-sm-6">
              <h6>All Days</h6>
            </div>
            <div class="col-sm-6">
              <div
                class="status-toggle sml-status d-flex justify-content-sm-end align-items-center"
              >
                <input type="checkbox" id="status_2" class="check" checked />
                <label for="status_2" class="checktoggle">checkbox</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="day-info">
                <div class="row day-cont">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">From</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">To</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value1"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="day-info">
                <day-cont
                  v-for="(day, index) in days"
                  :key="index"
                  @remove="remove(index)"
                ></day-cont>
              </div>
              <div class="form-group">
                <a href="javascript:;" class="add-text add-day" @click="add"
                  ><i class="feather-plus-circle"></i> Add Hours</a
                >
              </div>
            </div>
            <div class="col-sm-6">
              <h6>Monday</h6>
            </div>
            <div class="col-sm-6">
              <div
                class="status-toggle sml-status d-flex justify-content-sm-end align-items-center"
              >
                <input type="checkbox" id="status_3" class="check" checked />
                <label for="status_3" class="checktoggle">checkbox</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="day-info">
                <div class="row day-cont">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">From</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value2"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">To</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value3"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="day-info">
                <day-cont
                  v-for="(day, index) in days1"
                  :key="index"
                  @remove="remove1(index)"
                ></day-cont>
              </div>
              <div class="form-group">
                <a href="javascript:;" class="add-text add-day" @click="add1"
                  ><i class="feather-plus-circle"></i> Add Hours</a
                >
              </div>
            </div>
            <div class="col-sm-6">
              <h6>Tuesday</h6>
            </div>
            <div class="col-sm-6">
              <div
                class="status-toggle sml-status d-flex justify-content-sm-end align-items-center"
              >
                <input type="checkbox" id="status_21" class="check" checked />
                <label for="status_21" class="checktoggle">checkbox</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="day-info">
                <div class="row day-cont">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">From</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value4"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">To</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value5"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="day-info">
                <day-cont
                  v-for="(day, index) in days2"
                  :key="index"
                  @remove="remove2(index)"
                ></day-cont>
              </div>
              <div class="form-group">
                <a href="javascript:;" class="add-text add-day" @click="add2"
                  ><i class="feather-plus-circle"></i> Add Hours</a
                >
              </div>
            </div>
            <div class="col-sm-6">
              <h6>Wednesday</h6>
            </div>
            <div class="col-sm-6">
              <div
                class="status-toggle sml-status d-flex justify-content-sm-end align-items-center"
              >
                <input type="checkbox" id="status_4" class="check" checked />
                <label for="status_4" class="checktoggle">checkbox</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="day-info">
                <div class="row day-cont">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">From</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value6"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">To</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value7"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="day-info">
                <day-cont
                  v-for="(day, index) in days3"
                  :key="index"
                  @remove="remove3(index)"
                ></day-cont>
              </div>
              <div class="form-group">
                <a href="javascript:;" class="add-text add-day" @click="add3"
                  ><i class="feather-plus-circle"></i> Add Hours</a
                >
              </div>
            </div>
            <div class="col-sm-6">
              <h6>Thursday</h6>
            </div>
            <div class="col-sm-6">
              <div
                class="status-toggle sml-status d-flex justify-content-sm-end align-items-center"
              >
                <input type="checkbox" id="status_5" class="check" checked />
                <label for="status_5" class="checktoggle">checkbox</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="day-info">
                <div class="row day-cont">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">From</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value8"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">To</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value9"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="day-info">
                <day-cont
                  v-for="(day, index) in days4"
                  :key="index"
                  @remove="remove4(index)"
                ></day-cont>
              </div>
              <div class="form-group">
                <a href="javascript:;" class="add-text add-day" @click="add4"
                  ><i class="feather-plus-circle"></i> Add Hours</a
                >
              </div>
            </div>
            <div class="col-sm-6">
              <h6>Friday</h6>
            </div>
            <div class="col-sm-6">
              <div
                class="status-toggle sml-status d-flex justify-content-sm-end align-items-center"
              >
                <input type="checkbox" id="status_6" class="check" checked />
                <label for="status_6" class="checktoggle">checkbox</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="day-info">
                <div class="row day-cont">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">From</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value10"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">To</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value11"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="day-info">
                <day-cont
                  v-for="(day, index) in days5"
                  :key="index"
                  @remove="remove5(index)"
                ></day-cont>
              </div>
              <div class="form-group">
                <a href="javascript:;" class="add-text add-day" @click="add5"
                  ><i class="feather-plus-circle"></i> Add Hours</a
                >
              </div>
            </div>
            <div class="col-sm-6">
              <h6>Saturday</h6>
            </div>
            <div class="col-sm-6">
              <div
                class="status-toggle sml-status d-flex justify-content-sm-end align-items-center"
              >
                <input type="checkbox" id="status_7" class="check" checked />
                <label for="status_7" class="checktoggle">checkbox</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="day-info">
                <div class="row day-cont">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">From</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value12"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">To</label>
                      <div class="form-icon">
                        <a-time-picker
                          v-model:value="value13"
                          use12-hours
                          format="h:mm:ss A"
                          class="form-control timepicker"
                        />
                        <span class="cus-icon"><i class="feather-clock"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="day-info">
                <day-cont
                  v-for="(day, index) in days6"
                  :key="index"
                  @remove="remove6(index)"
                ></day-cont>
              </div>
              <div class="form-group">
                <a href="javascript:;" class="add-text add-day" @click="add6"
                  ><i class="feather-plus-circle"></i> Add Hours</a
                >
              </div>
            </div>
            <div class="col-sm-6">
              <h6>Sunday</h6>
              <h6 class="mb-sm-0">Today Closed</h6>
            </div>
            <div class="col-sm-6">
              <div
                class="status-toggle sml-status d-flex justify-content-sm-end align-items-center"
              >
                <input type="checkbox" id="status_8" class="check" />
                <label for="status_8" class="checktoggle">checkbox</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Availability Days -->
  </div>
</template>

<script>
import DayCont from "./DayCont.vue";

export default {
  components: {
    DayCont,
  },
  data() {
    return {
      days: [],
      days1: [],
      days2: [],
      days3: [],
      days4: [],
      days5: [],
      days6: [],
      value: null,
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      value5: null,
      value6: null,
      value7: null,
      value8: null,
      value9: null,
      value10: null,
      value11: null,
      value12: null,
      value13: null,
    };
  },
  methods: {
    add() {
      this.days.push({});
    },
    remove(index) {
      this.days.splice(index, 1);
    },
    add1() {
      this.days1.push({});
    },
    remove1(index) {
      this.days1.splice(index, 1);
    },
    add2() {
      this.days2.push({});
    },
    remove2(index) {
      this.days2.splice(index, 1);
    },
    add3() {
      this.days3.push({});
    },
    remove3(index) {
      this.days3.splice(index, 1);
    },
    add4() {
      this.days4.push({});
    },
    remove4(index) {
      this.days4.splice(index, 1);
    },
    add5() {
      this.days5.push({});
    },
    remove5(index) {
      this.days5.splice(index, 1);
    },
    add6() {
      this.days6.push({});
    },
    remove6(index) {
      this.days6.splice(index, 1);
    },
  },
};
</script>

<style>
.v3ti .v3ti-tag {
  background-color: #f4f7ff;
  color: #74788d;
}

.v3ti .v3ti-tag .v3ti-remove-tag {
  color: black;
}

.v3ti.input-tags.form-control:focus {
  outline: none;
}
</style>
