<template>
  <!-- Customer Reviews -->
  <section class="customer-review-section">
    <div class="customer-side-main-all">
      <div class="customer-side-left-img">
        <img src="@/assets/img/customer-review-after.png" alt="image" />
      </div>
      <div class="customer-side-right-img">
        <img src="@/assets/img/customer-review-before.png" alt="image" />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-nine aos" data-aos="fade-up">
            <p>OUR LATEST ARTICLES</p>
            <h2>Customer Reviews</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel customer-review-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexninecustomer" :key="item.id">
                <div class="customer-reviews-all">
                  <div class="customer-reviews-main">
                    <img :src="require(`@/assets/img/blog/${item.image}`)" alt="" />
                    <div class="customer-quote">
                      <img src="@/assets/img/icons/customer-quote.svg" alt="" />
                    </div>
                  </div>
                  <h4>Vincent Cooper</h4>
                  <span>Alaska, USA</span>
                  <div class="rating">
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star-half-stroke filled"></i>
                  </div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since.Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.
                  </p>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Customer Reviews -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexninecustomer from "@/assets/json/indexninecustomer.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexninecustomer: indexninecustomer,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
