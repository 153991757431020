<template>
  <!-- Delete Category -->

  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ delete_title }}</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">{{ delete_text }}</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- /Delete Category -->
</template>
<script>
export default {
  component: {},
  props: {
    delete_title: {
      type: String,
      default: "",
    },
    delete_text: {
      type: String,
      default: "",
    },
  },
  methods: {
    methods: {
      submitForm() {
        this.$router.push("/admin/sales-transactions");
      },
    },
  },
};
</script>
