<template>
    <!-- Add Expenditure -->
    <div class="modal fade" id="add-expenditure" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Expenditure</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="mb-3">
                <label class="form-label">Kategori Pengeluaran</label>
                <select class="form-select" v-model="formCreate.category">
                    <option value="Belanja kantor">Belanja kantor</option>
                    <option value="Belanja pegawai">Belanja pegawai</option>
                    <option value="Biaya service">Biaya service</option>
                    <option value="Cicilan kendaraan">Cicilan kendaraan</option>
                    <option value="Operasional">Operasional</option>
                    <option value="Operasional order">Operasional order</option>
                    <option value="Sewa kendaraan">Sewa kendaraan</option>
                    <option value="Gaji driver">Gaji driver</option>
                    <option value="Bayar Invoice Vendor">Bayar Invoice Vendor</option>
                    <option value="Lain-lain">Lain-lain</option>
                </select>
              </div>
              <div v-if="formCreate.category == 'Operasional order' && orders.length > 0">
                <div class="mb-3">
                  <label class="form-label">Order</label>
                  <select class="form-select" @change="handleOrderChange">
                    <option v-for="order in orders" :key="order.id" :value="order.id">{{ order.no_order }} - {{  order.customer?.name }}</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label class="form-label">Detail</label>
                  <select class="form-select" v-model="formCreate.order_vehicle_detail_id">
                    <template v-for="orderVehicle in orderVehicles" :key="orderVehicle.id">
                      <option v-if="orderVehicle.order_vehicle_detail.length > 0"  :value="orderVehicle.order_vehicle_detail[0].id">{{ orderVehicle.order_vehicle_detail[0].vehicle.name }} - {{ orderVehicle.order_vehicle_detail[0].vehicle.nomor_plat }}</option>
                    </template>
                  </select>
                </div>
              </div>
              <div v-if="formCreate.category == 'Bayar Invoice Vendor' && vendorsData.length > 0">
                <div class="mb-3">
                  <label class="form-label">Vendor</label>
                  <select class="form-select" @change="handleVendorChange" v-model="selectedVendorId">
                    <option v-for="vendor in vendorsData" :key="vendor.id" :value="vendor.id">{{ vendor.full_name }}</option>
                  </select>
                </div>
                <div class="mb-3">
                  <p class="mb-0">Total bayar: </p>
                  <p>{{ invoiceVendor ? formatCurrency(invoiceVendor.total_pay) : '-' }}</p>
                  <p class="mb-0">Sisa tagihan: </p>
                  <p>{{ invoiceVendor ? formatCurrency(invoiceVendor.sisa_tagihan) : '-' }}</p>
                </div>
              </div>
              <div v-if="formCreate.category == 'Gaji driver'" class="mb-3">
                <label class="form-label">Driver</label>
                <select class="form-select" v-model="formCreate.driver_id">
                    <option v-for="driver of driverData" :key="driver.id" :value="driver.id">{{ driver.full_name }}</option>
                </select>
              </div>
              <div v-if="formCreate.category != 'Operasional order'" class="mb-3">
                <label class="form-label">Keterangan</label>
                <input type="text" class="form-control" v-model="formCreate.name"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Date</label>
                <input type="datetime-local" class="form-control" v-model="formCreate.date"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Nominal</label>
                <input type="text" class="form-control" v-model="formattedCreateValues.nominal" @input="onInput('formCreate', 'nominal', $event)" />
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Expenditure -->
  
    <!-- Edit Expenditure -->
    <div class="modal fade" id="edit-expenditure" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Expenditure</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="mb-3">
                <label class="form-label">Kategori Pengeluaran</label>
                <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.category">
                    <option value="Belanja kantor" :selected="formEdit.category === 'Belanja kantor'">Belanja kantor</option>
                    <option value="Belanja pegawai" :selected="formEdit.category === 'Belanja pegawai'">Belanja pegawai</option>
                    <option value="Biaya service" :selected="formEdit.category === 'Biaya service'">Biaya service</option>
                    <option value="Cicilan kendaraan" :selected="formEdit.category === 'Cicilan kendaraan'">Cicilan kendaraan</option>
                    <option value="Operasional" :selected="formEdit.category === 'Operasional'">Operasional</option>
                    <option value="Pengeluaran operasional order" :selected="formEdit.category === 'Pengeluaran operasional order'">Operasional order</option>
                    <option value="Sewa kendaraan" :selected="formEdit.category === 'Sewa kendaraan'">Sewa kendaraan</option>
                    <option value="Gaji driver" :selected="formEdit.category === 'Gaji driver'">Gaji driver</option>
                    <option value="Lain-lain" :selected="formEdit.category === 'Lain-lain'">Lain-lain</option>
                </select>
              </div>
              <div v-if="formEdit && formEdit.category == 'Gaji driver'" class="mb-3">
                <label class="form-label">Driver</label>
                <select class="form-select" v-model="formEdit.driver_id">
                    <option v-for="driver of driverData" :key="driver.id" :value="driver.id" :selected="driver.id == formEdit.driver_id">{{ driver.full_name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Keterangan</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name" />
              </div>
              <div class="mb-3">
                <label class="form-label">Date</label>
                <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.date" />
              </div>
              <div class="mb-3">
                <label class="form-label">Nominal</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formattedEditValues.nominal" @input="onInput('formEdit', 'nominal', $event)" />
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Expenditure -->
  
    <div class="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Expenditure ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
import order from '@/store/module/order';

  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {},
        formattedEditValues: {},
        formattedCreateValues: {},
        orders: [],
        orderVehicles: [],
        selectedVendorId: null,
        vendorsData: [],
        invoiceVendor: null
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object,
      driverData: Object
    },
    emits: ['edit-expenditure'],
    watch: {
      async 'formCreate.category'(value) {
        if (value == 'Operasional order') {
          await this.getOrders()
          if (this.orders.length > 0) {
            this.orderVehicles = this.orders[0].order_vehicle
          }
        } else if (value == 'Bayar Invoice Vendor') {
          await this.getVendors()
        }
      },
      editData: {
        immediate: true,
        async handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
            if (newValue.data && newValue.data.nominal) {
              this.formattedEditValues.nominal = this.formatCurrency(newValue.data.nominal)
            }
            if (newValue.data && newValue.data.category === 'Gaji driver') {
              const loading = this.$loading.show() 
              try {
                const result = await this.$store.dispatch('expenditure/getPaymentDriver', newValue.data.id)
                this.formEdit.driver_id = result.data.driver_id
              } catch (e) {
                this.$toast.error(e)
              } finally {
                loading.hide()
              }
            }
          }
        }
      },
    },
    methods: {
      async getOrders() {
        const loading = this.$loading.show()
        try {
          const resp = await this.$store.dispatch("order/getOrder", {
            row: 99999,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            dateStart: '',
            dateEnd: '',
          });

          this.orders = resp.data.data
        } catch (e) {
          console.log(e)
          this.$toast.error(e)
        } finally {
          loading.hide()
        }
      },
      async getVendors() {
        const loading = this.$loading.show()
        try {
          const resp = await this.$store.dispatch("vendor/getVendorAll");
          this.vendorsData = resp.data
        } catch (e) {
          console.log(e)
          this.$toast.error(e)
        } finally {
          loading.hide()
        }
      },
      handleOrderChange(event) {
        const order = this.orders.find((order) => order.id == event.target.value)
        this.orderVehicles = order.order_vehicle
      },
      async handleVendorChange(event) {
        const loading = this.$loading.show()
        try {
          const resp = await this.$store.dispatch("vendor/getInvoiceVendorJson", event.target.value);
          this.invoiceVendor = resp.data
        } catch (e) {
          console.log(e)
          this.$toast.error(e)
        } finally {
          loading.hide()
        }
      },
      editSubmit() {
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("expenditure/updateExpenditure", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {}
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      async createSubmit() {
        if (this.formCreate.category == 'Operasional order') {
          const loading = this.$loading.show()
          try {
            await this.$store.dispatch('expenditure/createOperationalOrder', this.formCreate)
          } catch (e) {
            console.log(e)
            this.$toast.error(e)
          } finally {
            this.formCreate = {}
            document.getElementById('close-create').click();
            this.$emit('dataUpdated');
            loading.hide()
          }
        } else if (this.formCreate.category == 'Bayar Invoice Vendor') {
          const data = {
            ...this.formCreate,
            invoice_vendor: this.invoiceVendor,
            vendor_id: this.selectedVendorId
          }

          const loading = this.$loading.show()
          try {
            await this.$store.dispatch('vendor/payInvoiceVendor', data)
          } catch (e) {
            console.log(e)
            this.$toast.error(e)
          } finally {
            this.formCreate = {}
            document.getElementById('close-create').click();
            this.$emit('dataUpdated');
            loading.hide()
          }
        } else {
          var loading = this.$loading.show()
          this.$store
            .dispatch("expenditure/addExpenditure", this.formCreate )
            .then((data) => {
              if (data.status == 201) {
                loading.hide()
                this.formCreate = {}
                document.getElementById('close-create').click();
                this.$emit('dataUpdated');
              }
            },
            (error) => {
              console.log(error)
              loading.hide();
            })
            .catch((e) => {
              loading.hide()
            })
        }
      },
      deleteData() {
        // Perform deletion using this.deleteItemId
        console.log('Deleting item with ID:', this.deleteItemId);
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("expenditure/deleteExpenditure", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      formatCurrency(value) {
        if (!value && value !== 0) return '';
        
        // Convert value to a string and handle negative numbers
        let stringValue = Math.abs(value).toString();
        
        // Split the string into integer and decimal parts
        let parts = stringValue.split('.');
        
        // Format the integer part with thousand separators
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        
        // Join the parts and add a minus sign if the value was negative
        const formattedValue = parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');

        return value < 0 ? '-' + formattedValue : formattedValue;
      },
      unformatCurrency(value) {
        return value.replace(/\./g, '').replace(',', '.');
      },
      onInput(form, field, event) {
        const rawValue = event.target.value;
        const unformattedValue = this.unformatCurrency(rawValue);
        const numberValue = parseFloat(unformattedValue);
        const formattedValue = this.formatCurrency(numberValue);

        if (form === 'formCreate') {
          this.formCreate[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedCreateValues[field] = formattedValue;
        } else if (form === 'formEdit') {
          this.formEdit[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedEditValues[field] = formattedValue;
        }
      }
    }
  };
  </script>
  
