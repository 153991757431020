<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Plugins Manager</h5>
          <div class="list-btn">
            <ul>
              <li>
                <a class="btn btn-primary" href="javascript:;"
                  ><i class="fa fa-plus me-2"></i>Add Plugin</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="tab-sets mb-4">
              <div class="tab-contents-sets">
                <ul>
                  <li>
                    <router-link to="/admin/plugins-manager"
                      >Installed Plugins</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/admin/available-plugins" class="active"
                      >Available Plugins</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-sm-6 col-12"
            v-for="record in Available_Plugins"
            :key="record.id"
          >
            <div class="service-widget service-fav">
              <div class="service-img">
                <a href="javascript:;">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    :src="require(`@/assets/admin_img/services/${record.Image}`)"
                  />
                </a>
              </div>
              <div class="service-content">
                <div class="serv-info">
                  <div class="serv-users">
                    <h6>
                      {{ record.Method }}<span>{{ record.Version }}</span>
                    </h6>
                  </div>
                </div>
                <div class="serv-update">
                  <h6>{{ record.Amount }}</h6>
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="preview-plugin"
                        ><i class="feather feather-eye"></i> Preview</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="delete-plugin"
                        ><i class="feather feather-shopping-cart me-1"></i>Purchase</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Available_Plugins from "@/assets/json/admin/available-plugins.json";
export default {
  data() {
    return {
      Available_Plugins: Available_Plugins,
    };
  },
};
</script>
