<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>Pelanggan</h4>
                  <p>Kamu bisa lihat data pelanggan disni!</p>
                </div>
                <div class="col-md-6 text-md-end aos" data-aos="fade-up">
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#add-customer"
                    >
                      <i class="fa fa-plus me-2"></i>Tambah Pelanggan
                    </button>
                </div>
              </div>

              <div class="row" style="padding-bottom: 10px;">
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/user.svg" alt="img"
                            /></span>
                            <h6>Total Pelanggan</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ total }}">{{ total }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user home-provider">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/user-circle.svg" alt="img"
                            /></span>
                            <h6>Pelanggan Paling Loyal Bulan Ini</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ loyal }}">{{ loyal }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user home-service">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/user-edit.svg" alt="img"
                            /></span>
                            <h6>Pelanggan Baru Bulan Ini</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ new }}">{{ new }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-page-header content-page-headersplit mb-0">
                <div class="list-btn">
                  <ul>
                    <li>
                      <div class="input-group" style="justify-content: end;">
                        <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="max-width: 25% !important;" @keyup.enter="search">
                        <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="table-resposnive table-div">
                    <div class="table datatable">
                      <a-table :columns="columns" :data-source="data" :pagination="pagination" style="overflow-x: auto;">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'Status'">
                            <h6 v-if="record.status === 'AKTIF'" class="badge-active">{{ record.status }}</h6>
                            <h6 v-else class="badge-active">{{ record.status }}</h6>
                          </template>
                          <template v-if="column.key === 'id'">
                            <div class="table-actions d-flex">
                                <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#show-customer"
                                @click="openEditModal(record)"
                              >
                                <i class="feather feather-eye"></i>
                              </button>
                              <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-customer"
                                @click="openEditModal(record)"
                              >
                                <i class="feather feather-edit"></i>
                              </button>
                              <button
                                class="btn delete-table"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#delete"
                                @click="openDeleteModal(record)"
                              >
                                <i class="feather feather-trash-2"></i>
                              </button>
                            </div>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <customer-model :editData="editData" :deleteItemId="itemIdToDelete" @dataUpdated="getData"></customer-model>
  </template>
  
  <script>
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "NIK",
      dataIndex: "nik",
      key: "nik",
      sorter: {
        compare: (a, b) => {
          a = a.nik.toLowerCase();
          b = b.nik.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      sorter: {
        compare: (a, b) => {
          a = a.phone_number.toLowerCase();
          b = b.phone_number.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: {
        compare: (a, b) => {
          a = a.type.toLowerCase();
          b = b.type.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "name",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        total: 0,
        loyal: '',
        new: 0
      };
    },
    name: "customersetting",
    computed: {

    },
    methods: {
        search() {
          this.params.page = 1;
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("customer/getCustomer", this.params)
                .then((resp) => {
                    const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
                    this.data = resp.data.data.map((item, index) => ({
                      ...item,
                      index: startIndex + index + 1 
                    }));
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    this.total = resp.total !== null ? resp.total : 0;
                    const loyalUser = this.data.find(item => item.id === resp.loyal.customer_id);
                    this.loyal = loyalUser ? loyalUser.name : "Belum Ada";
                    this.new = resp.new !== null ? resp.new : 0;
                    console.log(resp);
                    loading.hide();
                })
                .catch((e) => {
                    // this.$toast.error(e);
                    console.log(e);
                    loading.hide();
                });
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
    },
    mounted() {
        this.getData();
    },
  };
  </script>
  <style>
  .ant-select-selection-item {
    height: 30px;
    width: 90px;
    margin-top: -6px;
    font-size: 15px;
  }
  </style>