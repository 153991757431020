<template>
  <div class="row">
    <div class="col-md-6 col-lg-3" v-for="record in Dashboardwidget" :key="record.id">
      <div class="dash-widget">
        <div class="dash-img">
          <span :class="record.class">
            <img :src="require(`@/assets/img/icons/${record.dashIcon}`)" alt="" />
          </span>
          <div :class="record.class1">
            <img :src="require(`@/assets/img/icons/${record.dashValueIcon}`)" alt="" />
            {{ record.dashValueText }}
          </div>
        </div>
        <div class="dash-info">
          <div class="dash-order">
            <h6>{{ record.dashInfoTitle }}</h6>
            <h5>{{ record.dashInfoValue }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dashboardwidget from "@/assets/json/dashboardwidget.json";
export default {
  data() {
    return {
      Dashboardwidget: Dashboardwidget,
    };
  },
};
</script>
