<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header d-flex justify-content-between">
                <h5>Payout Settings</h5>
                <div class="active-switch d-flex align-items-center">
                  <h6 class="fs-14">Enable</h6>
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Payout No of days to process</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Number of days"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Payout No of days to process</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Number of days"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>No of Payouts <span>(Per Month)</span></label>
                    <b-form-input
                      type="text"
                      placeholder="Enter No of Payouts"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Minimum Payout Amount</label>
                    <b-form-input
                      type="text"
                      placeholder="Enter Minimum Payout Amount"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Payout Commission</label>
                    <b-form-input
                      type="text"
                      placeholder="Enter Payout Commission"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" variant="primary"> Save Changes</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/payout-request");
    },
  },
};
</script>
