<template>
    <div class="mt-0">
        <!-- Session Expired -->
        <div class="main-wrapper error-page">
            <div class="bg-img">
                <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg1">
                <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg2">
                <img src="@/assets/img/bg/feature-bg-03.png" alt="img" class="bgimg3">
            </div>
            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="error-wrap text-center">
                                <div class="error-logo">
                                    <router-link to="/"><img class="img-fluid" src="@/assets/admin_img/logo_marirental3.svg" alt="img"></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="error-wrap">
                                <h2>{{message}}</h2>
                                <p v-if="isSucces">Dengan email Anda yang terverifikasi, Anda sekarang memiliki akses penuh ke semua fitur dan manfaat yang kami tawarkan. Siap untuk mulai menjelajahi?
                                </p>
                                <div v-if="isSucces">
                                    <router-link to="/mari/" class="btn btn-primary"><i
                                        class="feather-arrow-left-circle me-2"></i>Kembali Ke Home</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="error-wrap">
                                <div class="error-img">
                                    <img class="img-fluid" src="@/assets/img/mari/email-verify.svg" alt="img">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Session Expired -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            isSucces: false,
            message: "Email Kamu Gagal di Verifikasi"
        }
    },
    methods: {
        submitEmailVerify(token) {
          let loading = this.$loading.show();
          this.$store.dispatch("auth/emailVerify", token).then(
            (data) => {
              loading.hide();
                if (data.status == 200) {
                    this.isSucces =  true;
                    this.message = data.data.message;
                }
            },
            (error) => {
              console.log(error)
              loading.hide();
            }
          );
        },
    },
    mounted() {
        let token = this.$route.query.token
        this.submitEmailVerify(token);
    }
}
</script>