<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>SMS Templates</h5>
          <div class="list-btn">
            <ul>
              <li>
                <router-link class="btn-filters" to="/admin/sms-settings"
                  ><i class="feather feather-settings"></i>
                </router-link>
              </li>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'action'">
                      <div class="table-actions d-flex">
                        <button
                          class="btn delete-table me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-sms-template"
                        >
                          <i class="feather feather-edit"></i>
                        </button>
                        <button
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <sms-template-model></sms-template-model>
</template>

<script>
const columns = [
  {
    title: "Reference ID",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "SMS Title",
    dataIndex: "SMS_Title",
    sorter: {
      compare: (a, b) => {
        a = a.SMS_Title.toLowerCase();
        b = b.SMS_Title.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },

  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    SMS_Title: "SMS Registration Confirmation",
    Date: "28 Sep 2023 16:43:20",
    Action: "",
  },
  {
    id: "2",
    SMS_Title: "SMS User Booked Confirmation",
    Date: "27 Sep 2023 10:43:20",
    Action: "",
  },
  {
    id: "3",
    SMS_Title: "SMS User Booked Confirmation",
    Date: "23 Sep 2023 10:43:20",
    Action: "",
  },
  {
    id: "4",
    SMS_Title: "SMS Booking Status",
    Date: "23 Sep 2023 10:43:20",
    Action: "",
  },
  {
    id: "5",
    SMS_Title: "SMS Admin Refund",
    Date: "28 Sep 2023 16:43:20",
    Action: "",
  },
  {
    id: "6",
    SMS_Title: "Service Approval By SMS",
    Date: "27 Sep 2023 10:43:20",
    Action: "",
  },
  {
    id: "7",
    SMS_Title: "Subscription Expires SMS",
    Date: "23 Sep 2023 10:43:20",
    Action: "",
  },
  {
    id: "8",
    SMS_Title: "SMS Provider Booked Confirmation",
    Date: "23 Sep 2023 10:43:20",
    Action: "",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
