<template>
  <!-- Add City -->
  <div class="modal fade" id="add-city">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add City</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Country</label>

              <vue-select :options="Country" id="country" placeholder="Select Country" />
            </div>
            <div class="mb-3">
              <label class="form-label">State</label>

              <vue-select :options="State" id="state" placeholder="Select State" />
            </div>
            <div class="mb-4">
              <label class="form-label">City</label>
              <input type="text" class="form-control" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add City -->

  <!-- Edit City -->
  <div class="modal fade" id="edit-city">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit City</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Country</label>
              <vue-select :options="Country1" id="country1" placeholder="United State" />
            </div>
            <div class="mb-3">
              <label class="form-label">State</label>
              <vue-select :options="State1" id="state1" placeholder="Newyork" />
            </div>
            <div class="mb-4">
              <label class="form-label">City</label>
              <input type="text" class="form-control" value="Gandzasar" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit City -->

  <!-- Delete City -->
  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Cities ?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to delete this?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete City -->
</template>
<script>
export default {
  data() {
    return {
      Country: ["Select Country", "United State", "India"],
      Country1: ["Select Country", "United State", "India"],
      State: ["Select State", "Swains Island", "Andorra la Vella"],
      State1: ["Select State", "Newyork", "Washington DC"],
    };
  },
};
</script>
