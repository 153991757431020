<template>
  <!--change email-modal -->
  <div
    class="modal fade"
    id="add-currency"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Currency</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Currency Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Currency Name"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Currency Symbol</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Currency Symbol"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Currency Code</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Currency Code"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Currency Rate</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Currency Rate"
                />
              </div>
            </div>
            <div class="form-groupheads d-flex d-flex justify-content-between">
              <h2>Status</h2>
              <div class="active-switch">
                <label class="switch">
                  <input type="checkbox" checked="" />
                  <span class="sliders round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <b-button type="button" variant="secondary" data-bs-dismiss="modal"
            >Close</b-button
          >
          <b-button type="button" variant="primary" data-bs-dismiss="modal"
            >Save Changes</b-button
          >
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="edit-currency"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Currency</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Currency Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Currency Name"
                  value="England Pound	"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Currency Symbol</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Currency Symbol"
                  value="£"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Currency Code</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Currency Code"
                  value="GBP"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Currency Rate</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Currency Rate"
                />
              </div>
            </div>
            <div class="form-groupheads d-flex d-flex justify-content-between">
              <h2>Status</h2>
              <div class="active-switch">
                <label class="switch">
                  <input type="checkbox" checked="" />
                  <span class="sliders round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <b-button type="button" variant="secondary" data-bs-dismiss="modal"
            >Close</b-button
          >
          <b-button type="button" variant="primary" data-bs-dismiss="modal"
            >Save Changes</b-button
          >
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Currency</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to Delete?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/currencies");
    },
  },
};
</script>
