<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="row" style="padding-bottom: 10px;">
            <h5>Berlangganan</h5>
            <div class="col-12 d-flex widget-path widget-service">
              <div class="card">
                <div class="card-body" style="background-color: #358ef1">
                  <div class="home-user">
                    <div class="home-userhead">
                      <div class="home-usercount">
                        <h6>LAYANAN ANDA AKAN BERAKHIR PADA --/--/----</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-page-header content-page-headersplit mb-0">
            <div class="list-btn">
              <ul>
                <li>
                  
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center" style="padding-bottom: 20px;">
                <div class="col-4"></div>
                <div class="col-4">
                <h5>Konfirmasi Berlangganan</h5>
                    <form>
                        <div class="mb-3">
                            <label class="form-label">Paket</label>
                            <input type="text" class="form-control" disabled/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Bukti Pembayaran</label>
                            <input type="number" class="form-control" disabled/>
                        </div>
                        <div class="text-end">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
                <div class="col-4"></div>
            </div>
            <div class="col-12 text-center">
                <div class="card">
                    <div class="card-body" style="background-color: #358ef1">
                        <h5>Ingin Custom ?</h5>
                        <p>Full akses ke semua fitur di aplikasi dan perubahan/penambahan fitur yang disepakati.</p>
                        <p>Minimal berlangganan 1 Tahun</p>
                        <p>Hubungi kami untuk harga berlangganan</p>
                        <button type="submit" class="btn btn-primary">Hubungi Kami</button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        
      };
    },
    name: "subscribesetting",
    computed: {

    },
    methods: {

    },
    mounted() {

    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>