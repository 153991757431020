<template>
    <!-- About Our Company -->
    <section class="about-our-company">
        <div class="container">
            <div class="section-heading section-heading-six">
                <div class="row">
                    <div class="col-md-6 col-12 aos" data-aos="fade-up">
                        <div class="reason-six">
                            <img src="@/assets/img/icons/window-cleaner-detergent.svg" alt="">
                            <p>BEST CLEANING SERVICE</p>
                        </div>
                        <h2><span>About </span>Our Company</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="our-company-six">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                            has been the industry's standard dummy text ever
                            since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                            a type specimen book.</p>
                    </div>
                    <div class="aboutus-companyimg">
                        <a href="javascript:;">
                            <img src="@/assets/img/our-company-vd.jpg" alt="img">
                        </a>
                        <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#video">
                            <div class="playicon">
                                <span>
                                    <i class="feather-play"></i>
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="our-company-ryt">
                        <div class="our-company-img">
                            <img src="@/assets/img/our-company-six.png" alt="image" class="img-fluid">
                        </div>
                        <div class="our-company-bg">
                            <img src="@/assets/img/our-company-six-bg.png" alt="image" class="img-fluid">
                        </div>
                        <div class="our-company-first-content">
                            <div class="company-top-content">
                                <p>Call us Today!</p>
                                <h3>+012 345678</h3>
                            </div>
                            <a href="javascript:;">
                                <i class="feather-arrow-right"></i>
                            </a>
                        </div>
                        <div class="our-company-two-content">
                            <div class="company-two-top-content">
                                <h4>12+</h4>
                                <img src="@/assets/img/icons/trophy.svg" alt="">
                            </div>
                            <p>Years Experience</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /About Our Company -->

    <!-- Get Satisfied with the services Explore All -->
    <section class="satisfied-service-section">
        <div class="container">
            <div class="section-heading section-heading-six">
                <div class="row align-items-end">
                    <div class="col-md-6 aos" data-aos="fade-up">
                        <div class="reason-six">
                            <img src="@/assets/img/icons/cleaning-mop.svg" alt="">
                            <p>AFFORDABLE & RELIABLE</p>
                        </div>
                        <h2>Get Satisfied with the <span>services</span></h2>
                    </div>
                    <div class="col-md-6 aos" data-aos="fade-up">
                        <router-link to="/categories" class="afford-btn">Explore All</router-link>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="get-service-main">
                        <span>
                            <img src="@/assets/img/icons/satisfied-1.svg" alt="">
                        </span>
                        <router-link to="/service-details">
                            <h5>Upholstery Cleaning</h5>
                        </router-link>
                        <div class="get-service-btn">
                            <a href="javascript:;">
                                View More <i class="ms-2 feather-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="get-service-main">
                        <span>
                            <img src="@/assets/img/icons/satisfied-2.svg" alt="">
                        </span>
                        <router-link to="/service-details">
                            <h5>Upholstery Cleaning</h5>
                        </router-link>
                        <div class="get-service-btn">
                            <a href="javascript:;">
                                View More <i class="ms-2 feather-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="get-service-main">
                        <span>
                            <img src="@/assets/img/icons/satisfied-3.svg" alt="">
                        </span>
                        <router-link to="/service-details">
                            <h5>Upholstery Cleaning</h5>
                        </router-link>
                        <div class="get-service-btn">
                            <a href="javascript:;">
                                View More <i class="ms-2 feather-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="get-service-main">
                        <span>
                            <img src="@/assets/img/icons/satisfied-4.svg" alt="">
                        </span>
                        <router-link to="/service-details">
                            <h5>Upholstery Cleaning</h5>
                        </router-link>
                        <div class="get-service-btn">
                            <a href="javascript:;">
                                View More <i class="ms-2 feather-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="get-service-main">
                        <span>
                            <img src="@/assets/img/icons/satisfied-5.svg" alt="">
                        </span>
                        <router-link to="/service-details">
                            <h5>Upholstery Cleaning</h5>
                        </router-link>
                        <div class="get-service-btn">
                            <a href="javascript:;">
                                View More <i class="ms-2 feather-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="get-service-main">
                        <span>
                            <img src="@/assets/img/icons/satisfied-5.svg" alt="">
                        </span>
                        <router-link to="/service-details">
                            <h5>Upholstery Cleaning</h5>
                        </router-link>
                        <div class="get-service-btn">
                            <a href="javascript:;">
                                View More <i class="ms-2 feather-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="get-service-main">
                        <span>
                            <img src="@/assets/img/icons/satisfied-7.svg" alt="">
                        </span>
                        <router-link to="/service-details">
                            <h5>Upholstery Cleaning</h5>
                        </router-link>
                        <div class="get-service-btn">
                            <a href="javascript:;">
                                View More <i class="ms-2 feather-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="get-service-main">
                        <span>
                            <img src="@/assets/img/icons/satisfied-8.svg" alt="">
                        </span>
                        <router-link to="/service-details">
                            <h5>Upholstery Cleaning</h5>
                        </router-link>
                        <div class="get-service-btn">
                            <a href="javascript:;">
                                View More <i class="ms-2 feather-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Get Satisfied with the services Explore All -->
</template>