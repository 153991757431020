<template>
  <div class="row">
    <div class="col-lg-4 col-sm-6 col-12" v-for="record in Pending_Blog" :key="record.id">
      <div class="blog grid-blog">
        <div class="blog-image">
          <a href="javascript:void(0);">
            <img
              class="img-fluid serv-img"
              alt="Service Image"
              :src="require(`@/assets/admin_img/services/${record.Image}`)"
            />
          </a>
          <div class="fav-item">
            <div class="item-info">
              <router-link to="/admin/categories"
                ><span>{{ record.Name }}</span></router-link
              >
            </div>
            <span class="serv-rating"
              ><i class="fa-solid fa-star"></i>{{ record.Star }}</span
            >
          </div>
        </div>
        <div class="blog-content">
          <div class="blog-widget-image">
            <a href="javascript:void(0);" class="table-profileimage">
              <img
                :src="require(`@/assets/admin_img/customer/${record.UserImg}`)"
                class="me-2"
                alt="img"
              />
              <span>{{ record.User }}</span>
            </a>
            <h6>
              <img src="@/assets/admin_img/icons/calendar.svg" alt="img" />{{
                record.Date
              }}
            </h6>
          </div>
          <h3 class="title">
            <a href="javascript:;">{{ record.Service }}</a>
          </h3>
          <p>{{ record.Content }}</p>
          <div class="blog-info">
            <div class="action-search">
              <router-link to="/admin/edit-blog" class="serv-edit"
                ><i class="feather feather-edit"></i> Edit</router-link
              >
              <a href="javascript:void(0);"
                ><i class="feather feather-trash-2"></i> Delete</a
              >
            </div>
            <span><i class="feather feather-alert-circle"></i> Inactive</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pending_Blog from "@/assets/json/pending-blog.json";
export default {
  data() {
    return {
      Pending_Blog: Pending_Blog,
    };
  },
};
</script>
