<template>
  <!-- Add Account -->
  <div class="modal fade" id="add-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Account</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Bank Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Account Holder Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Account Number</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Routing Number</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">IBAN</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-groupheads d-flex d-flex justify-content-between mb-4">
              <h2>Status</h2>
              <div class="active-switch">
                <label class="switch">
                  <input type="checkbox" checked="" />
                  <span class="sliders round"></span>
                </label>
              </div>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Account -->

  <!-- Edit Account -->
  <div class="modal fade" id="edit-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Account</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Bank Name</label>
              <input type="text" class="form-control" value="Minster Bank" />
            </div>
            <div class="mb-3">
              <label class="form-label">Account Holder Name</label>
              <input type="text" class="form-control" value="John Doe" />
            </div>
            <div class="mb-3">
              <label class="form-label">Account Number</label>
              <input type="text" class="form-control" value="420509843380" />
            </div>
            <div class="mb-3">
              <label class="form-label">Routing Number</label>
              <input type="text" class="form-control" value="125200044" />
            </div>
            <div class="mb-3">
              <label class="form-label">IBAN</label>
              <input
                type="text"
                class="form-control"
                value="US52907093244885792186160135"
              />
            </div>
            <div class="form-groupheads d-flex d-flex justify-content-between mb-4">
              <h2>Status</h2>
              <div class="active-switch">
                <label class="switch">
                  <input type="checkbox" checked="" />
                  <span class="sliders round"></span>
                </label>
              </div>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit IP Address -->


  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Bank Account?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to Delete?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
