<template>
  <div
    class="modal fade"
    id="add-abuse"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Abuse Details</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <b-form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-12">
                <ul class="abuse-list">
                  <li>
                    <h6>Provider Name</h6>
                    <div class="table-profileimage">
                      <img
                        src="@/assets/admin_img/customer/user-01.jpg"
                        class="me-2"
                        alt="img"
                      />
                      <span>John Smith</span>
                    </div>
                  </li>
                  <li>
                    <h6>Username</h6>
                    <div class="table-profileimage">
                      <img
                        src="@/assets/admin_img/customer/user-02.jpg"
                        class="me-2"
                        alt="img"
                      />
                      <span>William</span>
                    </div>
                  </li>
                  <li>
                    <h6>Descriptions</h6>
                    <p>Lorem ipsum dolor sit</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <b-button type="button" variant="secondary" data-bs-dismiss="modal"
              >Close</b-button
            >
            <b-button type="submit" variant="primary">Save Changes</b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>


  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Abuse Reports</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to Delete?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/abuse-reports");
    },
    submitFormOne() {
      this.$router.push("/admin/abuse-reports");
    },
  },
};
</script>
