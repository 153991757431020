<script setup>
  import { ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useToast } from 'vue-toast-notification';
  import moment from 'moment'
  import { useLoading } from 'vue-loading-overlay'
  import { formatCurrency } from '@/utils/currency';

  const props = defineProps({
    vehicles: Array
  })

  const containerRef = ref(null)

  const vehiclesToChoose = ref([])

  const emit = defineEmits(['onVehicleUpdated', 'onVehicleUpdateClick'])

  const store = useStore()
  const $loading = useLoading()
  const $toast = useToast()

  const isOpen = ref(false)
  const yOffset = ref(0)

  const mouseX = ref(0)
  const watchMouseX = ref(0)
  const mouseY = ref(0)
  const watchMouseY = ref(0)

  const item = ref(null)

  const customer = ref(null)
  const firstDriver = ref(null)
  const invoices = ref(null)

  async function toggle(data) {
    if (!isOpen.value) {
      item.value = data

      console.log('data', data)

      if (data.order) {
        vehiclesToChoose.value = props.vehicles

        // get the first driver
        if (Array.isArray(data.order.order_vehicle) && data.order.order_vehicle.length > 0) {
          const ov = data.order.order_vehicle[0]
          if (Array.isArray(ov.order_vehicle_detail) && ov.order_vehicle_detail.length > 0) {
            firstDriver.value = ov.order_vehicle_detail[0].driver
          }
        }

        const loading = $loading.show()
        try {
          const resp = await store.dispatch('invoice/getInvoiceDetailByOrderId', data.order.id)
          invoices.value = resp.data
        } catch (e) {
          console.log('error', e)
          $toast.error(e)
        } finally {
          loading.hide()
        }
      }

    } else {
      item.value = null
      customer.value = null
      vehiclesToChoose.value = []
    }

    item.value = data

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    let containerWidth = 400 
    let containerHeight = 400

    const currentMouseX = watchMouseX.value
    const currentMouseY = watchMouseY.value

    const isXOverflow = (containerWidth + currentMouseX) > windowWidth
    if (isXOverflow) {
      mouseX.value = currentMouseX - containerWidth
    } else {
      mouseX.value = currentMouseX 
    }

    const isYOverflow = (containerHeight + currentMouseY) > windowHeight
    if (isYOverflow) {
      const newY = currentMouseY - containerHeight
      if (newY < 0) {
        mouseY.value = Math.abs(newY)
      } else {
        mouseY.value = newY 
      }
    } else {
      mouseY.value = currentMouseY
    }

    isOpen.value = !isOpen.value
    yOffset.value = window.pageYOffset
  }

  function closePopup() {
    item.value = null
    customer.value = null
    isOpen.value = false
  }

  function onMouseMove(event) {
    watchMouseX.value = event.pageX
    watchMouseY.value = event.pageY
  }

  function onUpdateVehicleClick(vehicle) {
    closePopup()
    emit('onVehicleUpdateClick', vehicle)
   // store.dispatch('order/updateOrder', {
   //   id: order.value.id,
   //   data: {
   //     ...order.value,
   //     vehicle_id: vehicle.id
   //   }
   // }).then((data) => {
   //   emit('onVehicleUpdated', {
   //     order: data.data.data
   //   })
   // })
  }

  function formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function calculateTotalPrice(price, discount) {
    const total_price = price || 0;
    const discountPercentage = discount || 0;
    const discountAmount = (total_price * discountPercentage) / 100;
    return total_price - discountAmount;
  }

  function _formatCurrency(value) {
    if (!value && value !== 0) return '';
    // Convert value to a string
    let stringValue = value.toString();
    // Split the string into integer and decimal parts
    let parts = stringValue.split('.');
    // Format the integer part with thousand separators
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    // Join the parts and return the formatted value
    return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
  }

  function parseDatetime(paramDate, paramTime) {
    const date = moment(paramDate)
    const time = moment(paramTime, 'HH:mm')

    date.set({
      hour: time.get('hour'),
      minute: time.get('minute')
    })

    return date.format('DD MMM YYYY HH:mm')
  }

  function onEditClick() {
    closePopup()
  }

  onMounted(() => {
    document.addEventListener('mousemove', onMouseMove)
  })

  defineExpose({
    toggle,
    closePopup
  })

  function sendWhatsAppMessage(item) {
    const order = item.order;
    const userData = localStorage.getItem('user');
    const userLogin = JSON.parse(userData);
    const vehicleNames = order.order_vehicle.map(orderVehicle => {
      const vehicle = orderVehicle.vehicle;
      return `${vehicle.name} - ${vehicle.class} - ${vehicle.color}`;
    });
    const companyName = userLogin.company ? userLogin.company.name : '-';
    const { no_order, dp, total_price, jml_org, pick_up_point, interpoint, remark, payment_method } = order;
    const customerSelected = order.customer ? order.customer : '-'; // this.dataCustomer.find(customer => customer.id === order.customer_id);
    const customerName = customerSelected ? customerSelected.name : '-';
    const phone_number = customerSelected ? customerSelected.phone_number : null;
    // const email = customerSelected ? customerSelected.email : null;
    const productSelected = order.product; // this.dataProduct.find(product => product.id === order.product_id);
    const productName = productSelected ? productSelected.name : '-';
    const vehicleSelected = order.vechicle; //this.dataVehicle.find(vehicle => vehicle.id === order.vehicle_id);
    const vehicleName = vehicleNames.join(', ');
    const sDate = new Date(order.starting_date);
    const startDate = sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear();
    const fDate = new Date(order.finish_date);
    const finishDate = fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear();
    const countryCode = '62'; // Example country code for Indonesia
    const formattedPhoneNumber = `+${countryCode}${phone_number.slice(1)}`; // Format phone number
    const message = `${companyName}\nNo Order ${no_order}\nNama Pemesan : ${customerName}\nNomor telpon : ${formattedPhoneNumber}\nJumlah Pax : ${jml_org}\nPilihan Produk Sewa Mobil : ${productName}\nJenis Kendaraan di pesan : ${vehicleName}\nTanggal dan Waktu Penjemputan : ${startDate}\nTanggal akhir Penghantaran : ${finishDate}\nAlamat Penjemputan/Pengambilan : ${pick_up_point}\nRute Tujuan Awal dan Akhir Pemakaian Kendaraan : ${interpoint}\nHARGA DEAL : ${total_price}\nDEPOSITE PAYMENT : ${dp}\n\nMetode Pembayaran : ${payment_method}\nRequest / Catatan : ${remark}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  }
</script>

<template>
  <div v-if="isOpen" :style="{ 'top': `${yOffset}px` }" style="left: 0px; background-color: rgba(0, 0, 0, 0.25); width: 100vw; height: 100vh; z-index: 100; position: absolute;" @click="closePopup">
  </div>
    <div :class="{'show': isOpen}" :style="{ 'top': `calc(${yOffset - 200}px + 50%)`}" class="p-0 dropdown-menu dropdown-menu-right shadow-sm" style="position: absolute; left: 50%; transform: translateX(-50%); width: 400px;">
    <div class="d-flex justify-content-between align-items-center bg-primary px-3 py-2">
      <h6 v-if="item && item.order" class="mb-0 text-white text-uppercase">Order #{{item.order.no_order}}</h6>
      <i @click="closePopup" class="feather feather-x text-white" style="font-size: 28px; cursor: pointer;"></i>
    </div>
    <div class="d-flex border-top pt-2 px-3">
      <div v-if="item && item.order" style="max-height: 300px; overflow-y: auto">
        <!-- <p class="m-0 fw-bold">{{ parseDatetime(item.order.starting_date, item.order.starting_time) }} <span class="fw-normal">s/d</span> {{ parseDatetime(item.order.finish_date, item.order.finish_time) }}</p> -->
        <p style="font-size: 16px;" class="m-0 fw-bold">{{ moment.utc(item.order_time.start).format('DD MMM YYYY HH:mm') }} <span class="fw-normal">s/d</span> {{ moment.utc(item.order_time.end).format('DD MMM YYYY HH:mm') }}</p>
        <div class="mb-2">
          <p class="m-0 text-uppercase fw-bold"><small>Customer</small></p>
          <p class="m-0">{{ item.customer.name }} &#x2022; {{ item.customer.type }}</p>
          <p v-if="item.detail.nama_tamu && item.detail.phone_tamu" class="m-0">{{ item.detail.nama_tamu }} &#x2022; {{ item.detail.phone_tamu }}</p>
        </div>
        <div class="mb-2">
          <p class="m-0 text-uppercase fw-bold"><small>Order</small> <span :style="{'background-color': item.style.background}" class="badge text-uppercase">{{ item.order.status }}</span></p>
          
          <div class="d-flex justify-space-between m-0">
            <div>
              <p class="m-0"><span class="fw-bold">Harga:</span> {{ formatCurrency(item.order.total_price) }}</p>
            </div>
            <p class="m-0"><span class="fw-bold">Total Harga:</span> {{ formatCurrency(calculateTotalPrice(item.order.total_price, item.order.discount)) }}</p>
          </div>

          <div v-if="item.product.rental_type == 1" class="rental-type rounded">
            <p class="m-0 fw-bold text-uppercase">Sewa Lepas Kunci</p>
            <p class="m-0">{{ item.product.name }}</p>

            <p v-if="firstDriver"><span class="text-uppercase fw-bold">Driver Pertama:</span> {{ firstDriver.full_name }}</p>
          </div>
          <div v-else-if="item.product.rental_type == 2" class="rental-type rounded px-2">
            <p class="m-0 fw-bold text-uppercase">Sewa Dengan Driver</p>
            <p class="m-0">{{ item.product.name }}</p>

            <p v-if="firstDriver"><span class="text-uppercase fw-bold">Driver Pertama:</span> {{ firstDriver.full_name }}</p>
          </div>

        </div>
        <div>
          <template v-if="invoices && Array.isArray(invoices)">
            <div v-for="invoice of invoices" :key="invoice.invoice.id">
              <div>
                <p class="m-0 text-uppercase text-muted fw-bold"><small>Invoice</small> <span class="badge my-badge">{{ invoice.invoice.no_invoice }}</span></p>
                <p style="font-size: 14px;" class="m-0"><span class="text-uppercase fw-bold">Total Harga:</span> {{ formatCurrency(invoice.invoice.total_price) }}</p>
                <p style="font-size: 14px;" class="m-0"><span class="text-uppercase fw-bold">Sudah Bayar:</span> {{ formatCurrency(invoice.sudah_bayar) }}</p>
                <p style="font-size: 14px;" class="m-0"><span class="text-uppercase fw-bold">Harus Bayar:</span> {{ formatCurrency(invoice.harus_bayar) }}</p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="item && item.maintenance" class="d-flex flex-column">
        <span>{{ item.maintenance.implementation }}</span>
        <p class="m-0">Tanggal awal: {{ moment(item.time.start).format('DD MMM YYYY hh:mm') }}</p>
        <p class="m-0">Tanggal akhir: {{ moment(item.time.end).format('DD MMM YYYY hh:mm') }}</p>
      </div>
    </div>
    <div class="d-flex mt-2 justify-content-between border-top pt-2">
      <div>
        <button
          v-if="item && item.order"
          class="btn delete-table me-2"
          type="button"
          title="Split kendaraan"
          data-bs-toggle="modal"
          data-bs-target="#split-kendaraan"
          @click="closePopup"
          >
            <i class="feather feather-repeat"></i>
        </button>
        <button
          v-if="item && item.order"
          class="btn delete-table me-2"
          type="button"
          title="Edit Tanggal/Harga order"
          data-bs-toggle="modal"
          data-bs-target="#extend-order"
          @click="closePopup"
          >
            <i class="feather feather-clock"></i>
        </button>
        <button
          v-if="item && item.order"
          class="btn delete-table me-2"
          type="button"
          title="Ubah status order"
          data-bs-toggle="modal"
          data-bs-target="#status-order"
          @click="closePopup"
          >
            <i class="feather feather-edit"></i>
        </button>
        <button
          v-if="item && item.order"
          class="btn delete-table me-2"
          type="button"
          title="Kirim Pesan Ke Pelanggan"
          @click="sendWhatsAppMessage(item)"
          >
            <i class="feather feather-message-circle"></i>
        </button>
      </div>
      <button
        v-if="item && item.order"
        data-bs-toggle="modal"
        data-bs-target="#edit-order"
        @click="onEditClick"
        class="me-2 btn btn-primary">Edit</button>
    </div>
  </div>

</template>

<style scoped>
.rental-type {
  background-color: rgba(13, 110, 253, 0.2);
}
.my-badge {
  background-color: rgba(13, 110, 253);
}
</style>

