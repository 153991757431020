<template>
  <!-- Edit Offer -->
  <div class="modal fade" id="edit-offer">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Offer</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Service</label>
              <vue-select
                :options="Serviceoffer"
                id="serviceofferone"
                placeholder="Computer Repair"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">User Name</label>
              <vue-select
                :options="Serviceoffer1"
                id="serviceoffer"
                placeholder="John Smith"
              />
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="mb-3">
                  <label class="form-label">Amount</label>
                  <input type="text" class="form-control" value="$80" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-3">
                  <label class="form-label">Offer</label>
                  <input type="text" class="form-control" value="25%  " />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-3">
                  <label class="form-label">Offer Price</label>
                  <input type="text" value="20%" class="form-control" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="mb-4">
                  <label class="form-label">Valid Date</label>
                  <datepicker
                    :input-format="dateFormat"
                    v-model="startdate"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="Add Date"
                  />
                </div>
              </div>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Offer -->

  <!-- Delete Offer -->
  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Offers ?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to delete this?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Offer -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      dateFormat: "dd-MM-yyyy",
      startdate: currentDate,
      Serviceoffer: ["Compuer Repair", "House Cleaning"],
      Serviceoffer1: ["John Smith", "Johnny"],
    };
  },
};
</script>
