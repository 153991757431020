<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Pages</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Pages_Slug'">
                      <td>{{ record.Pages_Slug }}</td>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <div class="active-switch">
                        <label class="switch">
                          <input type="checkbox" checked="" />
                          <span class="sliders round"></span>
                        </label>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <router-link class="delete-table me-2" to="/admin/edit-page">
                          <img src="@/assets/admin_img/icons/edit.svg" alt="svg" />
                        </router-link>
                        <a
                          class="delete-table"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <img src="@/assets/admin_img/icons/delete.svg" alt="svg" />
                        </a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pages-model></pages-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Pages",
    dataIndex: "Pages",
    sorter: {
      compare: (a, b) => {
        a = a.Pages.toLowerCase();
        b = b.Pages.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Pages Slug",
    dataIndex: "Pages_Slug",
    key: "Pages_Slug",
    sorter: {
      compare: (a, b) => {
        a = a.Pages_Slug.toLowerCase();
        b = b.Pages_Slug.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];

const data = [
  {
    id: "1",
    Pages: "About Us",
    Pages_Slug: "about-us",
    Status: "",
    Action: "",
  },
  {
    id: "2",
    Pages: "Home",
    Pages_Slug: "home",
    Status: "",
    Action: "",
  },
  {
    id: "3",
    Pages: "FAQ",
    Pages_Slug: "faq",
    Status: "",
    Action: "",
  },
  {
    id: "4",
    Pages: "Services",
    Pages_Slug: "faq",
    Status: "",
    Action: "",
  },
  {
    id: "5",
    Pages: "Categories",
    Pages_Slug: "categories",
    Status: "",
    Action: "",
  },
  {
    id: "6",
    Pages: "Contact Us",
    Pages_Slug: "contact-us",
    Status: "",
    Action: "",
  },
  {
    id: "7",
    Pages: "Privacy Policy",
    Pages_Slug: "privacy-policy",
    Status: "",
    Action: "",
  },
  {
    id: "8",
    Pages: "Terms Conditions",
    Pages_Slug: "terms-conditions",
    Status: "",
    Action: "",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
