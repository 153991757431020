<template>
  <div class="row align-items-center">
    <div class="col-md-6">
      <div class="service-wrap">
        <h5>Related Services</h5>
      </div>
    </div>
    <div class="col-md-6 text-md-end">
      <div class="owl-nav mynav"></div>
    </div>
  </div>
  <div class="owl-carousel related-slider">
    <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
      <Slide v-for="item in RelatedService" :key="item.id">
        <div class="service-widget service-widget-space mb-0">
          <div class="service-img">
            <router-link to="/service-details">
              <img
                class="img-fluid serv-img"
                alt="Service Image"
                :src="require(`@/assets/img/services/${item.image}`)"
              />
            </router-link>
            <div class="fav-item">
              <router-link to="/categories"
                ><span class="item-cat">{{ item.badge }}</span></router-link
              >
              <a href="javascript:void(0)" class="fav-icon">
                <i class="feather-heart"></i>
              </a>
            </div>
            <div class="item-info">
              <router-link to="/providers"
                ><span class="item-img"
                  ><img
                    :src="require(`@/assets/img/profiles/${item.avatar}`)"
                    class="avatar"
                    alt="" /></span
              ></router-link>
            </div>
          </div>
          <div class="service-content">
            <h3 class="title">
              <router-link to="/service-details">{{ item.title }}</router-link>
            </h3>
            <p>
              <i class="feather-map-pin"></i>{{ item.country
              }}<span class="rate"><i class="fas fa-star filled me-1"></i>4.9</span>
            </p>
            <div class="serv-info">
              <h6>
                {{ item.offer }}<span class="old-price">{{ item.price }}</span>
              </h6>
              <router-link to="/booking" class="btn btn-book">Book Now</router-link>
            </div>
          </div>
        </div>
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import RelatedService from "@/assets/json/relatedservice.json";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  data() {
    return {
      RelatedService: RelatedService,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: "start",
        },
      },
    };
  },
  mounted() {
    document.querySelectorAll(".fav-icon").forEach(function (element) {
      element.addEventListener("click", function () {
        element.classList.toggle("selected");
      });
    });
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
