<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>Order</h4>
                  <p>Kamu bisa lihat daftar order kendaraan disni!</p>
                </div>
                <div class="col-md-6 text-md-end aos" data-aos="fade-up">
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#add-order"
                      @click="openChildModal"
                    >
                      <i class="fa fa-plus me-2"></i>Add Order
                    </button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/eye.svg" alt="img"
                            /></span>
                            <h6>Total Order Booking</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ totalB }}">{{ totalB }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user home-provider">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/monitor.svg" alt="img"
                            /></span>
                            <h6>Total Order Proses</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ totalP }}">{{ totalP }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user home-service">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/global.svg" alt="img"
                            /></span>
                            <h6>Total Order Bulan Ini</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ total }}">{{ total }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-page-header content-page-headersplit mb-0">
                <div class="list-btn">
                  <ul>
                    <li>
                      <div class="input-group">
                        <input type="date" v-model="params.dateStart" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text">s/d</span>
                        <input type="date" v-model="params.dateEnd" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text"></span>
                        <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" @keyup.enter="search">
                        <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                      </div>
                    </li>
                    <li>
                      <div class="input-group mt-2">
                        <div class="col-3">
                            <select class="form-select" v-model="params.status" @change="search">
                                <option value="Draf">Draf</option>
                                <option value="Booking">Booking</option>
                                <option value="Proses">Proses</option>
                                <option value="Finish">Finish</option>
                            </select>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <div class="table-resposnive table-div">
                    <div class="table datatable">
                      <a-table :columns="columns" :data-source="data" :pagination="pagination" style="overflow-x: auto;">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'status'">
                            <h6 v-if="record.status === 'Proses'" class="badge-active">{{ record.status }}</h6>
                            <h6 v-else-if="record.status === 'Booking'" class="badge-inactive">{{ record.status }}</h6>
                            <h6 v-else-if="record.status === 'Cancel'" class="badge-delete">{{ record.status }}</h6>
                            <h6 v-else-if="record.status === 'Finish'" class="badge-pending">{{ record.status }}</h6>
                            <h6 v-else-if="record.status === 'Draf'" class="badge-pending">{{ record.status }}</h6>
                          </template>
                          <template v-if="column.key === 'id'">
                            <td class="text-center">
                              <a
                                class="delete-table"
                                href="javascript:void(0);"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </a>
                              <ul class="dropdown-menu">
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#invoice-order-pdf" @click="openInvoice(record)">
                                    <i class="fa-solid fa-file-invoice"></i> Print Invoice
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" @click="sendWhatsAppMessage(record)">
                                    <i class="fa-solid feather-send"></i> Kirim Pesan Ke Pelanggan
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" @click="sendWhatsAppMessageDriver(record)">
                                    <i class="fa-solid feather-message-circle"></i> Kirim Pesan Ke Driver
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#show-order" @click="openEditModal(record)">
                                    <i class="feather feather-eye"></i> Detail Order
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#list-inspection" @click="openInspectionModal(record)">
                                    <i class="feather feather-truck"></i> Inspeksi Kendaraan
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#split-kendaraan" @click="openEditModal(record)">
                                    <i class="feather feather-repeat"></i> Split Kendaraan
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#edit-order" @click="openEditModal(record)">
                                    <i class="feather feather-edit"></i> Edit Order
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#extend-order" @click="openEditModal(record)">
                                    <i class="feather feather-clock"></i> Edit Tanggal/Harga order
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#cancel-order" @click="openDeleteModal(record)">
                                    <i class="feather feather-alert-octagon"></i> Cancel Order
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#delete-order" @click="openDeleteModal(record)">
                                    <i class="feather feather-trash-2"></i> Delete Order
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#status-order" @click="openEditModal(record)">
                                    <i class="feather feather-edit"></i> Update Status Order
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </div>
              </div>

              <order-model ref="orderModalRef" :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :customerData="dataCustomer" :vehicleData="dataVehicle" :brandData="dataBrand" :driverData="dataDriver" :driverAbsen="absenDriver" :staffData="dataStaff" :vehicleId="vehicleId ? vehicleId : null" :invoicePdfDataProp="invoicePdfData" @dataUpdated="getData"></order-model>
              <inspection-model :editData="editData" :productData="dataProduct" :customerData="dataCustomer" :vehicleData="dataVehicle" :brandData="dataBrand" :driverData="dataDriver" :staffData="dataStaff" :orderData="dataOrder" :userLogin="userLogin" @dataUpdated="getData"></inspection-model>
              <!-- <report-driver-model :reportData="reportData" :dataId="orderId" @dataUpdated="getData"></report-driver-model> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog ref="dialog">
      <div style="width: 400px; height: 600px;">
        <header>
          <button class="btn btn-secondary" @click="onDownload">
            <i class="fa-solid fa-download"></i>
            Download
          </button>
        </header>
        <VuePdfEmbed v-if="previewPdf" ref="pdfEmbed" :source="previewPdf" />
      </div>
    </Dialog>

  </template>
  
  <script>
  import Dialog from '@/views/mari/components/dialog.vue'
  import VuePdfEmbed from 'vue-pdf-embed'
  // import 'vue-pdf-embed/dist/style/index.css'

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true, 
      fixed: 'left' 
    },
    {
      title: "No Order",
      dataIndex: "no_order",
      key: "no_order",
      sorter: {
        compare: (a, b) => {
          a = a.no_order.toLowerCase();
          b = b.no_order.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      fixed: 'left' 
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Tanggal Order",
      dataIndex: "createDate",
      key: "createDate",
      sorter: {
        compare: (a, b) => {
          a = a.createDate.toLowerCase();
          b = b.createDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Pelanggan",
      dataIndex: "customerName",
      key: "customerName",
      sorter: {
        compare: (a, b) => {
          a = a.customerName.toLowerCase();
          b = b.customerName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Jenis Sewa",
      dataIndex: "productName",
      key: "productName",
      maxWidth: 10,
      sorter: {
        compare: (a, b) => {
          a = a.productName.toLowerCase();
          b = b.productName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Tanggal Berangkat",
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        compare: (a, b) => {
          a = a.startDate.toLowerCase();
          b = b.startDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Jam Berangkat",
      dataIndex: "starting_time",
      key: "starting_time",
      sorter: {
        compare: (a, b) => {
          a = a.starting_time.toLowerCase();
          b = b.starting_time.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Tanggal Kembali",
      dataIndex: "finishDate",
      key: "finishDate",
      sorter: {
        compare: (a, b) => {
          a = a.finishDate.toLowerCase();
          b = b.finishDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Jam Kembali",
      dataIndex: "finish_time",
      key: "finish_time",
      sorter: {
        compare: (a, b) => {
          a = a.finish_time.toLowerCase();
          b = b.finish_time.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    
    {
      title: "Total Sewa",
      dataIndex: "totalPrice",
      key: "totalPrice",
      sorter: {
        compare: (a, b) => {
          a = a.totalPrice.toLowerCase();
          b = b.totalPrice.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: totalPrice => `${totalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00`,
    },
    {
      title: "Total DP",
      dataIndex: "totalDp",
      key: "totalDp",
      sorter: {
        compare: (a, b) => {
          a = a.totalDp.toLowerCase();
          b = b.totalDp.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
      fixed: 'right',
      width: 100,
    },
  ];
  export default {
    components: {
      VuePdfEmbed,
      Dialog,
    },
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "desc",
            page: 1,
            dateStart: '',
            dateEnd: '',
            jadwal: false,
            status: ''
        },
        paramDriver1: {
            row: 100,
            keyword: "",
            sortby: "created_at",
            sorttype: "asc",
            page: 1
        },
        paramDriver2: {
            row: 100,
            keyword: "Driver",
            sortby: "full_name",
            sorttype: "asc",
            page: 1
        },
        paramStaff: {
            row: 100,
            keyword: "Staff Kendaraan",
            sortby: "full_name",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataProduct: [],
        dataVehicle: [],
        dataCustomer: [],
        dataVendor: [],
        dataDriver: [],
        dataStaff: [],
        dataBrand: [],
        absenDriver : [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        totalB: 0,
        totalP: 0,
        total: 0,
        dataOrder: {},
        previewPdf: null,
        dataUserCompany: null,
        reportData: {},
        userLogin: {},
        invoicePdfData: null
      };
    },
    name: "ordersetting",
    computed: {

    },
    methods: {
        async openInvoice(record) {
          const loading = this.$loading.show();
          try {
            const data = await this.$store.dispatch("order/getInvoicePdfData", record.id);
            this.invoicePdfData = data
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching order data:", e);
          } finally {
            loading.hide();
          }
        },
        async fetchAllData() {
          await Promise.all([
            this.getLoginCompany(),
            this.getVendor(),
            this.getBrand(),
            this.getProduct(),
            this.getVehicle(),
            this.getCustomer(),
            this.getDriver(),
            this.getDataDriver(),
            this.getStaff(),
            this.getData()
          ]);
        },
        search() {
          this.params.page = 1;
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        async getData() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("order/getOrder", this.params);
            const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
            this.data = resp.data.data.map((item, index) => {
              const sDate = new Date(item.starting_date);
              const fDate = new Date(item.finish_date);
              const pDate = new Date(item.created_at);
              const time = new Date(item.starting_time);
              const vehicle = item.vehicle;
              const vehicleName = vehicle ? vehicle.name : '';
              const product = item.product;
              let rentalTypeName;
              if (product.rental_type == 1) {
                rentalTypeName = "Sewa Lepas Kunci";
              } else {
                rentalTypeName = "Sewa Dengan Driver";
              }
              const productName = product ? product.name : '';
              const customer = item.customer;
              const customerName = customer ? customer.name : '';
              const totalPrice = this.formatCurrency(item.harus_bayar);
              const totalDp = this.formatCurrency(item.dp);
              return {
                ...item,
                index: startIndex + index + 1,
                createDate: pDate.getDate() + "/" + (pDate.getMonth() + 1) + "/" + pDate.getFullYear(),
                startDate: sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear(),
                finishDate: fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear(),
                vehicleName,
                productName,
                customerName,
                totalPrice,
                totalDp
              };
            });
            this.pagination.current = resp.data.current_page;
            this.pagination.total = resp.data.total;
            this.pagination.pageSize = this.params.row;
            this.totalB = resp.totalB !== null ? resp.totalB : 0;
            this.totalP = resp.totalP !== null ? resp.totalP : 0;
            this.total = resp.total !== null ? resp.total : 0;
            this.params.dateStart = this.formatDate(resp.dateStart);
            this.params.dateEnd = this.formatDate(resp.dateEnd);
            this.editData = {};
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching order data:", e);
          } finally {
            loading.hide();
          }
        },
        async onDownload() {
          await this.$refs.pdfEmbed.download('test.pdf')
          this.$refs.dialog.close()
        },
        async openPreviewInvoice(data) {
          const loading = this.$loading.show()
          const previewBlob = await this.$store.dispatch('invoice/getPreview', data.id)
          const newBlob = new Blob([previewBlob], { type: 'application/pdf' });

          function blobToBase64(blob) {
            return new Promise((resolve, _) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            });
          }

          this.previewPdf = await blobToBase64(newBlob);
          this.$refs.dialog.open()
          loading.hide()

          // // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //     window.navigator.msSaveOrOpenBlob(newBlob);
          // } else {
          //     // For other browsers: create a link pointing to the ObjectURL containing the blob.
          //     const objUrl = window.URL.createObjectURL(newBlob);

          //     this.previewPdf = objUrl


          //     // For Firefox it is necessary to delay revoking the ObjectURL.
          //     setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
          // }
          // console.log('preview', previewBlob)
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
        openReportModal(data) {
          // Set the edit data before opening the modal
          const newData = data.report[0];
          this.reportData = { newData };
        },
        openInspectionModal(data) {
          this.dataOrder = { data };
        },
        async getVendor() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("vendor/getVendorAll");
            this.dataVendor = resp.data;
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching vendor data:", e);
          } finally {
            loading.hide();
          }
        },
        async getProduct() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("product/getProduct", this.params);
            this.dataProduct = resp.data.data.map((item, index) => {
                let rentalTypeName;
                if (item.rental_type == 1) {
                    rentalTypeName = "Sewa Lepas Kunci";
                } else {
                    rentalTypeName = "Sewa Dengan Driver";
                }
                return {
                    ...item,
                    rentalTypeName,
                };
            });
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching product data:", e);
          } finally {
            loading.hide();
          }
        },
        async getVehicle() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("vehicle/getVehicleAll");
            const user = (() => {
              try {
                return JSON.parse(localStorage.getItem('user'));
              } catch (e) {
                return null;
              }
            })();
            this.dataVehicle = resp.data.map((item) => {
              let vendorName = '---';
              if (user && user.company) {
                vendorName = user.company.name;
              }
              if (item.vendor_id) {
                const data = this.dataVendor.find(vendor => vendor.id === item.vendor_id);
                vendorName = data ? data.full_name : vendorName;
              }
              const vehicleName = `${item.name || ''} - ${item.nomor_plat || ''} - ${vendorName}`;
              return {
                ...item,
                vehicleName
              };
            });
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching vehicle data:", e);
          } finally {
            loading.hide();
          }
        },
        async getCustomer() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("customer/getCustomerAll");
            this.dataCustomer = resp.data;
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching customer data:", e);
          } finally {
            loading.hide();
          }
        },
        async getDriver() {
          const loading = this.$loading.show();
          try {
            const today = new Date();
            today.setDate(today.getDate() - 1); // Add one day to the current date
            const pastDate = today.toISOString().split('T')[0]; // Convert to ISO string and split to get the date part
            this.paramDriver1.keyword = pastDate;
            const resp = await this.$store.dispatch("driver/getAbsenDriver", this.paramDriver1);
              this.absenDriver = resp.data.data;
              this.absenDriver = resp.data.data.map((item, index) => ({
                id: item.driver_id,
                full_name: item.user.full_name,
                statusDriver: item.user.status_mudi
              }));
              this.absenDriver.unshift({
                id: 0,
                full_name: "Driver Dummy",
                statusDriver: "Sementara"
              });
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching driver data:", e);
          } finally {
            loading.hide();
          }
        },
        async getDataDriver() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("user/getUser", this.paramDriver2);
            this.dataDriver = resp.data.data;
            // this.dataDriver.unshift({
            //     id: 0,
            //     full_name: "Driver Dummy",
            //     statusDriver: "Sementara"
            //   });
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching staff data:", e);
          } finally {
            loading.hide();
          }
        },
        async getStaff() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("user/getUser", this.paramStaff);
            this.dataStaff = resp.data.data;
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching staff data:", e);
          } finally {
            loading.hide();
          }
        },
        async getBrand() {
          const loading = this.$loading.show();
          try {
            const resp = await this.$store.dispatch("brand/getBrandAll");
            this.dataBrand = resp.data;
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching brand data:", e);
          } finally {
            loading.hide();
          }
        },
        formatDate(item) {
            // Assuming dateString is in a different format, you need to format it to YYYY-MM-DD
            const date = new Date(item);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        async getLoginCompany() {
          const loading = this.$loading.show();
          try {
            const userData = localStorage.getItem('user');
            const user = JSON.parse(userData);
            this.userLogin = user;
            if (!user.company_id || !user.company) {
              return
            }
            const resp = await this.$store.dispatch("company/showCompany", {id : user.company_id});
            this.dataUserCompany = resp.data.data;
          } catch (e) {
            this.$toast.error(e);
            console.error("Error fetching login company data:", e);
          } finally {
            loading.hide();
          }
        },
        sendWhatsAppMessage(order) {
          const vehicleNames = order.order_vehicle.map(orderVehicle => {
            const vehicle = orderVehicle.vehicle;
            return `${vehicle.name} - ${vehicle.class} - ${vehicle.color}`;
          });
          const companyName = this.userLogin.company.name;
          const { no_order, dp, total_price, customerName, jml_org, pick_up_point, interpoint, remark, payment_method } = order;
          const customerSelected = order.customer ? order.customer : '-'; // this.dataCustomer.find(customer => customer.id === order.customer_id);
          const phone_number = customerSelected ? customerSelected.phone_number : null;
          // const email = customerSelected ? customerSelected.email : null;
          const productSelected = order.product; // this.dataProduct.find(product => product.id === order.product_id);
          const productName = productSelected ? productSelected.name : '-';
          const vehicleSelected = order.vechicle; //this.dataVehicle.find(vehicle => vehicle.id === order.vehicle_id);
          const vehicleName = vehicleNames.join(', ');
          const sDate = new Date(order.starting_date);
          const startDate = sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear();
          const fDate = new Date(order.finish_date);
          const finishDate = fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear();
          const countryCode = '62'; // Example country code for Indonesia
          const formattedPhoneNumber = `+${countryCode}${phone_number.slice(1)}`; // Format phone number
          const message = `${companyName}\nNo Order ${no_order}\nNama Pemesan : ${customerName}\nNomor telpon : ${formattedPhoneNumber}\nJumlah Pax : ${jml_org}\nPilihan Produk Sewa Mobil : ${productName}\nJenis Kendaraan di pesan : ${vehicleName}\nTanggal dan Waktu Penjemputan : ${startDate}\nTanggal akhir Penghantaran : ${finishDate}\nAlamat Penjemputan/Pengambilan : ${pick_up_point}\nRute Tujuan Awal dan Akhir Pemakaian Kendaraan : ${interpoint}\nHARGA DEAL : ${total_price}\nDEPOSITE PAYMENT : ${dp}\n\nMetode Pembayaran : ${payment_method}\nRequest / Catatan : ${remark}`;
          const encodedMessage = encodeURIComponent(message);
          const whatsappUrl = `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`;
          window.open(whatsappUrl, '_blank');
        },
        openChildModal() {
          this.$refs.orderModalRef.openModal();
        },
        sendWhatsAppMessageDriver (order) {
          const vehicleNames = order.order_vehicle.map(orderVehicle => {
                const vehicle = orderVehicle.vehicle;
                return `${vehicle.name} - ${vehicle.class} - ${vehicle.color}`;
            });
            const companyName = this.userLogin.company ? this.userLogin.company.name : "-";
            const { no_order, dp, total_price, customerName, jml_org, pick_up_point, interpoint, remark, payment_method } = order;
            const customerSelected = order.customer;
            const phone_number = customerSelected ? customerSelected.phone_number : null;
            const productSelected = order.product;
            const productName = productSelected ? productSelected.name : '';
            const vehicleName = vehicleNames.join(', ');
            const sDate = new Date(order.starting_date);
            const startDate = sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear();
            const startTime = sDate.getHours() + ":" + sDate.getMinutes();
            const fDate = new Date(order.finish_date);
            const finishDate = fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear();
            const finishTime = fDate.getHours() + ":" + fDate.getMinutes();
            const countryCode = '62'; // Example country code for Indonesia
            const formattedPhoneNumber = `+${countryCode}${phone_number.slice(1)}`; // Format phone number
            const message = `
        NAMA PUNYA ORDER WORK ORDER

        Jenis Kendaraan: ${vehicleName}
        Nopol kendaraan: 
        Pemilik kendaraan: ${companyName}

        Pemesan Order: ${customerName}
        Nomor Pemesan: ${formattedPhoneNumber}

        Durasi Pemakaian: 
        Rute Perjalanan: ${interpoint}

        Tanggal Mulai Sewa: ${startDate}
        Jam Mulai Sewa: ${startTime}
        Lokasi Penjemputan: ${pick_up_point}

        Tanggal selesai Sewa: ${finishDate}
        Jam selesai Sewa: ${finishTime}
        Lokasi Selesai Pemakaian: 

        Sudah termasuk: 
        Belum Termasuk: 

        Tagihan: Rp. ${total_price}

        MOHON DI PERHATIKAN:

        1. DRIVER WAJIB TIBA DILOKASI 30 MENIT SEBELUM WAKTU PENJEMPUTAN
        2. CEK KONDISI KENDARAAN DARI MULAI KEBERSIHAN DAN KESIAPAN JALAN MOBIL
        3. PASTIKAN MUDI DALAM KEADAAN RAPIH DAN WANGI
        4. PASTIKAN SETIAP MUDI SUDAH MENERIMA BARCODE BBM/OP DAN KONDISI BBM/ETOL SUDAH TERISI
        5. PASTIKAN UNTUK INSPEKSI KENDARAAN AWAL DAN AKHIR UNTUK PENGAJUAN GAJI

        Catatan:
        Untuk trip besok ada Timesheet dan Refreshment yang harus di bawa (Sudah di sediakan admin di OFFICE)
        `;
            const encodedMessage = encodeURIComponent(message);
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`;
            window.open(whatsappUrl, '_blank');
        },
        formatCurrency(value) {
          if (!value && value !== 0) return '';
          // Convert value to a string
          let stringValue = value.toString();
          // Split the string into integer and decimal parts
          let parts = stringValue.split('.');
          // Format the integer part with thousand separators
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          // Join the parts and return the formatted value
          return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
        },
    },
    async  mounted() {
      try {
        await this.fetchAllData();
      } catch (error) {
        console.error("Error during data fetching:", error);
      }
    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>
