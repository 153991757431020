<template>
  <!-- Delete -->
  <div
    class="modal fade"
    id="delete-item"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ delete_title }}</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="del-modal">
              <p>{{ delete_text }}</p>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Yes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Delete -->
</template>

<script>
export default {
  component: {},
  props: {
    delete_title: {
      type: String,
      default: "",
    },
    delete_text: {
      type: String,
      default: "",
    },
  },
  methods: {
    methods: {
      submitForm() {
        this.$router.push("/admin/sales-transactions");
      },
    },
  },
};
</script>
