<template>
  <!-- Add Announcement -->
  <div class="modal fade" id="add-announcement">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Announcement</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Subject</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Message</label>
              <textarea class="form-control" rows="3"></textarea>
            </div>
            <div class="mb-4">
              <label class="form-label">Send To</label>
              <ul class="custom-radiosbtn">
                <li>
                  <label class="radiossets"
                    >Select All
                    <input type="radio" checked="checked" name="announcementSend" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
                <li>
                  <label class="radiossets"
                    >User
                    <input type="radio" name="announcementSend" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
                <li>
                  <label class="radiossets"
                    >Provider
                    <input type="radio" name="announcementSend" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Announcement -->

  <!-- Edit Announcement -->
  <div class="modal fade" id="edit-announcement">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Announcement</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Subject</label>
              <input type="text" class="form-control" value="Offer" />
            </div>
            <div class="mb-3">
              <label class="form-label">Message</label>
              <textarea class="form-control" rows="3">
Special offers for service booking</textarea
              >
            </div>
            <div class="mb-4">
              <label class="form-label">Send To</label>
              <ul class="custom-radiosbtn">
                <li>
                  <label class="radiossets"
                    >Select All
                    <input type="radio" name="announcementSend" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
                <li>
                  <label class="radiossets"
                    >User
                    <input type="radio" checked="checked" name="announcementSend" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
                <li>
                  <label class="radiossets"
                    >Provider
                    <input type="radio" name="announcementSend" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Announcement -->

  <!-- Delete Announcement -->
  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Announcement ?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to delete this?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Announcement -->
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
