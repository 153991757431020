<template>
    <div class="content container-fluid">

        <!-- Page Header -->
        <div class="page-header">
            <div class="row">
                <div class="col-md-4">
                    <div class="provider-subtitle">
                        <h6>My Services</h6>
                    </div>
                </div>
                <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                    <div class="review-sort d-flex align-items-center me-2">
                        <p>Sort</p>
                        <vue-select 
                            :options="abcd"
                            placeholder="A -> Z"
                        />
                    </div>
                    <div class="grid-listview me-2">
                        <ul>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="@/assets/img/icons/filter-icon.svg" alt="">
                                </a>
                            </li>
                            <li>
                                <router-link to="/provider-services">
                                    <i class="feather-grid"></i>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/provider-services-list" class="active">
                                    <i class="feather-list"></i>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <router-link to="/create-service" @click="redirectReloadCreate" class="btn btn-primary add-set"><i class="feather-plus"> </i>Add
                        Service</router-link>
                </div>
            </div>
        </div>
        <!-- /Page Header -->


        <div class="row">
            <div class="col-md-12">
                <div class="tab-list">
                    <ul class="nav">
                        <li>
                            <a href="javascript:;" class="active" data-bs-toggle="tab" data-bs-target="#active-service">Actice
                                Services</a>
                        </li>
                        <li>
                            <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#inactive-service">Inactive Services</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tab-content pt-0">
            <div class="tab-pane active" id="active-service">
                <div class="row">
                    <div class="col-md-12">

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-04.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Car Wash</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Car Repair Services</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>Maryland City, MD, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <router-link to="/provider-edit-service"><i class="feather-edit"></i>Edit</router-link>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#in-active"><i
                                                class="feather-alert-circle"></i>Inactive</a>
                                    </li>
                                </ul>
                                <router-link to="/booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-02.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Construction</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="service-details">Toughened Glass Fitting Services</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>New Jersey, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <router-link to="/provider-edit-service"><i class="feather-edit"></i>Edit</router-link>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#in-active"><i
                                                class="feather-alert-circle"></i>Inactive</a>
                                    </li>
                                </ul>
                                <router-link to="booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-06.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Computer</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="service-details">Computer & Server AMC Service</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>California, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <router-link to="/provider-edit-service"><i class="feather-edit"></i>Edit</router-link>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#in-active"><i
                                                class="feather-alert-circle"></i>Inactive</a>
                                    </li>
                                </ul>
                                <router-link to="/booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-07.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Interior</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Interior Designing</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>Maryland, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <router-link to="/provider-edit-service"><i class="feather-edit"></i>Edit</router-link>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#in-active"><i
                                                class="feather-alert-circle"></i>Inactive</a>
                                    </li>
                                </ul>
                                <router-link to="booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-01.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Electrician</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Electric Panel Repairing Service</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>Texas, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <router-link to="/provider-edit-service"><i class="feather-edit"></i>Edit</router-link>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#in-active"><i
                                                class="feather-alert-circle"></i>Inactive</a>
                                    </li>
                                </ul>
                                <router-link to="/booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-09.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Cleaning</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">House Cleaning Services</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>Georgia</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <router-link to="/provider-edit-service"><i class="feather-edit"></i>Edit</router-link>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#in-active"><i
                                                class="feather-alert-circle"></i>Inactive</a>
                                    </li>
                                </ul>
                                <router-link to="/booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                    </div>

                </div>
            </div>

            <div class="tab-pane fade" id="inactive-service">
                <div class="row">
                    <div class="col-md-12">

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-04.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Car Wash</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Car Repair Services</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>Maryland City, MD, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#del-service"><i
                                                class="feather-trash-2"></i>Delete</a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#active"><i
                                                class="feather-alert-circle"></i>Active</a>
                                    </li>
                                </ul>
                                <router-link to="/booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-02.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Construction</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Toughened Glass Fitting Services</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>New Jersey, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#del-service"><i
                                                class="feather-trash-2"></i>Delete</a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#active"><i
                                                class="feather-alert-circle"></i>Active</a>
                                    </li>
                                </ul>
                                <router-link to="/booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-06.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Computer</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Computer & Server AMC Service</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>California, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#del-service"><i
                                                class="feather-trash-2"></i>Delete</a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#active"><i
                                                class="feather-alert-circle"></i>Active</a>
                                    </li>
                                </ul>
                                <router-link to="booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-07.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Interior</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Interior Designing</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>Maryland, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#del-service"><i
                                                class="feather-trash-2"></i>Delete</a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#active"><i
                                                class="feather-alert-circle"></i>Active</a>
                                    </li>
                                </ul>
                                <router-link to="/booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-01.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Electrician</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">Electric Panel Repairing Service</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>Texas, USA</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#del-service"><i
                                                class="feather-trash-2"></i>Delete</a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#active"><i
                                                class="feather-alert-circle"></i>Active</a>
                                    </li>
                                </ul>
                                <router-link to="/booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                        <!-- Favorite Item -->
                        <div class="service-list">
                            <div class="service-cont">
                                <div class="service-cont-img">
                                    <router-link to="/service-details">
                                        <img class="img-fluid serv-img" alt="Service Image"
                                            src="@/assets/img/services/service-09.jpg">
                                    </router-link>
                                    <div class="fav-item">
                                        <a href="javascript:void(0)" class="fav-icon">
                                            <i class="feather-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="service-cont-info">
                                    <div class="category-rating">
                                        <span class="item-cat">Cleaning</span>
                                        <span class="serv-rating"><i class="fa-solid fa-star"></i>4.9</span>
                                    </div>
                                    <h3 class="title">
                                        <router-link to="/service-details">House Cleaning Services</router-link>
                                    </h3>
                                    <p><i class="feather-map-pin"></i>Georgia</p>
                                </div>
                            </div>
                            <div class="service-action">
                                <ul>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#del-service"><i
                                                class="feather-trash-2"></i>Delete</a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#active"><i
                                                class="feather-alert-circle"></i>Active</a>
                                    </li>
                                </ul>
                                <router-link to="/booking" class="btn btn-secondary">Apply Offer</router-link>
                            </div>
                        </div>
                        <!-- /Favorite Item -->

                    </div>

                </div>
            </div>

        </div>
        <!-- Pagination -->
    <div class="row">
        <div class="col-sm-6">
            <div class="review-entries">
                <span>Show</span>
                <select>
                    <option>07</option>
                    <option>08</option>
                </select>
                <span>entries</span>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="review-pagination">
                <p>1 - 09 of 09</p>
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" href="javascript:;">1</a>
                    </li>
                    <li class="page-item active">
                        <a class="page-link" href="javascript:;">2 <span class="visually-hidden">(current)</span></a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="javascript:;">3</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- /Pagination -->
</div>
</template>

<script>
export default {
    data() {
        return {
            abcd: ["A -> Z","Most helful"]
        }
    },
    methods: {
        redirectReloadCreate() {
            this.$router
            .push({ path: '/create-service' })
            .then(() => { this.$router.go() })
        },
    },
}
</script>