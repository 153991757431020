<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>Provider Dashboard</h6>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div class="review-sort d-flex align-items-center me-2">
            <p>Sort</p>
            <vue-select :options="abcd" placeholder="A -> Z" />
          </div>
          <div class="grid-listview">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <img src="@/assets/img/icons/filter-icon.svg" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <div class="row">
      <div class="col-xl-3 col-sm-6 d-flex">
        <div class="dash-card flex-fill">
          <div class="dash-header">
            <div class="dash-widget-header">
              <div class="d-flex align-items-center">
                <span class="dash-widget-icon">
                  <img src="@/assets/img/icons/pro-icon-04.svg" alt="img" />
                </span>
                <div class="dash-widget-info">
                  <h6>Earnings</h6>
                  <h5>$50000</h5>
                </div>
              </div>
              <select>
                <option>2023</option>
                <option>2022</option>
              </select>
            </div>
            <div class="dash-total">
              <p>Total Sales: <span>85</span></p>
              <div class="badge-success">+2.58</div>
            </div>
          </div>
          <div class="dash-widget-img">
            <img src="@/assets/img/icons/pro-chart-01.svg" class="img-fluid" alt="img" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 col-12 d-flex">
        <div class="dash-card flex-fill">
          <div class="dash-header">
            <div class="dash-widget-header">
              <div class="d-flex align-items-center">
                <span class="dash-widget-icon">
                  <img src="@/assets/img/icons/pro-icon-03.svg" alt="img" />
                </span>
                <div class="dash-widget-info">
                  <h6>Bookings</h6>
                  <h5>102</h5>
                </div>
              </div>
              <select>
                <option>2023</option>
                <option>2022</option>
              </select>
            </div>
            <div class="dash-total">
              <p>Over All: <span>60</span></p>
              <div class="badge-success">+2.58</div>
            </div>
          </div>
          <div class="dash-widget-img">
            <img src="@/assets/img/icons/pro-chart-02.svg" class="img-fluid" alt="img" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 col-12 d-flex">
        <div class="dash-card flex-fill">
          <div class="dash-header">
            <div class="dash-widget-header">
              <div class="d-flex align-items-center">
                <span class="dash-widget-icon">
                  <img src="@/assets/img/icons/pro-icon-02.svg" alt="img" />
                </span>
                <div class="dash-widget-info">
                  <h6>Services</h6>
                  <h5>12</h5>
                </div>
              </div>
              <select>
                <option>2023</option>
                <option>2022</option>
              </select>
            </div>
            <div class="dash-total">
              <p>Providers: <span>120</span></p>
              <div class="badge-success">+2.58</div>
            </div>
          </div>
          <div class="dash-widget-img">
            <img src="@/assets/img/icons/pro-chart-03.svg" class="img-fluid" alt="img" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 col-12 d-flex">
        <div class="dash-card flex-fill">
          <div class="dash-header">
            <div class="dash-widget-header">
              <div class="d-flex align-items-center">
                <span class="dash-widget-icon">
                  <img src="@/assets/img/icons/pro-icon-01.svg" alt="img" />
                </span>
                <div class="dash-widget-info">
                  <h6>Balance</h6>
                  <h5>$200</h5>
                </div>
              </div>
              <select>
                <option>2023</option>
                <option>2022</option>
              </select>
            </div>
            <div class="dash-total">
              <a href="javascript:;" class="btn btn-primary">Withdraw</a>
            </div>
          </div>
          <div class="dash-widget-img">
            <img src="@/assets/img/icons/pro-chart-04.svg" class="img-fluid" alt="img" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 d-flex flex-column">
        <!-- Current Plan -->
        <h6 class="subhead-title">Current Plan</h6>
        <div class="card flex-fill">
          <div class="card-body">
            <div class="plan-info">
              <div class="plan-term">
                <h5>Standard</h5>
                <p>Our most popular plan for small teams.</p>
                <h6><span>Renew Date:</span> Jan 22, 2023</h6>
              </div>
              <div class="plan-price">
                <h4>$291</h4>
                <p>per user/Year</p>
              </div>
            </div>
            <div class="plan-btns">
              <a href="javascript:;" class="btn btn-danger-light me-2"
                >Cancel Subscription</a
              >
              <router-link to="/provider-subscription" class="btn btn-secondary-outline"
                >Upgrade</router-link
              >
            </div>
          </div>
        </div>
        <!-- /Current Plan -->
      </div>
      <div class="col-md-6 d-flex flex-column">
        <!-- Payments -->
        <h6 class="subhead-title">Payment</h6>
        <div class="card flex-fill">
          <div class="card-body pay-next">
            <div class="plan-info">
              <div class="plan-term">
                <h5>Last Payment</h5>
                <h6>January 22, 2023</h6>
              </div>
              <div class="plan-price">
                <a href="javascript:;" class="btn btn-light-success">Active</a>
              </div>
            </div>
            <div class="plan-info">
              <div class="plan-term">
                <h5>Next Payment</h5>
                <h6>January 22, 2023</h6>
              </div>
              <div class="plan-price">
                <a href="javascript:;" class="btn btn-primary">Manage Payment</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Payments -->
      </div>
      <div class="col-md-12">
        <h6 class="subhead-title">Recent Booking</h6>

        <dashboardbooking></dashboardbooking>

        <div class="view-history">
          <router-link to="/provider-booking" class="btn btn-primary"
            >View All History</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      abcd: ["A -> Z", "Most helful"],
    };
  },
  mounted() {
    document.querySelectorAll(".fav-icon").forEach(function (element) {
      element.addEventListener("click", function () {
        element.classList.toggle("selected");
      });
    });
  },
};
</script>
