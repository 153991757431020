<template>
  <header class="header header-five">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/logo-02.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/" class="navbar-brand logo-small">
            <img src="@/assets/img/logo-small.png" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo-02.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a
              id="menu_close"
              class="menu-close"
              href="javascript:void(0);"
              @click="closeMenu"
            >
              <i class="fas fa-times"></i
            ></a>
          </div>

          <headerMenu />
        </div>
        <ul class="nav header-navbar-rht">
          <li class="nav-item">
            <router-link class="nav-link header-button" to="/choose-signup"
              >Register</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link header-button header-button-five" to="/login"
              ><i class="feather-user me-2"></i>Login</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", () => {
        var sticky = document.querySelector(".header");
        var scroll = window.pageYOffset || document.documentElement.scrollTop;
        if (sticky) {
          if (scroll >= 50) {
            sticky.classList.add("fixed");
          } else {
            sticky.classList.remove("fixed");
          }
        }
      });
    });
  },
  methods: {
    toggleSidebar() {
      const body = document.body;
      body.classList.toggle("menu-opened");
    },
    closeMenu() {
      const body = document.body;
      body.classList.remove("menu-opened");
    },
  },
};
</script>

<style>
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
