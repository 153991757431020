<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <div class="row">
          <div class="col-lg-12 m-auto">
            <div class="progressbar-card">
              <ul id="progress-head">
                <li class="active">Add Service - Service Information</li>
                <li>Add Service - Availablity</li>
                <li>Add Service - Location</li>
                <li>Add Service - Gallery</li>
                <li>Add Service - SEO</li>
              </ul>
              <ul id="progressbar" v-show="currentStep === 1">
                <li class="active">
                  <div class="multi-step-icon">
                    <span><i class="far fa-check-circle"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Information</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Availability</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-map"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Location</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-images"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Gallery</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-chart-bar"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>SEO</h6>
                  </div>
                </li>
              </ul>
              <ul id="progressbar" v-show="currentStep === 2">
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-check-circle"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Information</h6>
                  </div>
                </li>
                <li class="active">
                  <div class="multi-step-icon">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Availability</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-map"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Location</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-images"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Gallery</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-chart-bar"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>SEO</h6>
                  </div>
                </li>
              </ul>
              <ul id="progressbar" v-show="currentStep === 3">
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-check-circle"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Information</h6>
                  </div>
                </li>
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Availability</h6>
                  </div>
                </li>
                <li class="active">
                  <div class="multi-step-icon">
                    <span><i class="far fa-map"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Location</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-images"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Gallery</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-chart-bar"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>SEO</h6>
                  </div>
                </li>
              </ul>
              <ul id="progressbar" v-show="currentStep === 4">
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-check-circle"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Information</h6>
                  </div>
                </li>
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Availability</h6>
                  </div>
                </li>
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-map"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Location</h6>
                  </div>
                </li>
                <li class="active">
                  <div class="multi-step-icon">
                    <span><i class="far fa-images"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Gallery</h6>
                  </div>
                </li>
                <li>
                  <div class="multi-step-icon">
                    <span><i class="far fa-chart-bar"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>SEO</h6>
                  </div>
                </li>
              </ul>
              <ul id="progressbar" v-show="currentStep === 5">
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-check-circle"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Information</h6>
                  </div>
                </li>
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-clock"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Availability</h6>
                  </div>
                </li>
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-map"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Location</h6>
                  </div>
                </li>
                <li class="activated">
                  <div class="multi-step-icon">
                    <span><i class="far fa-images"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>Gallery</h6>
                  </div>
                </li>
                <li class="active">
                  <div class="multi-step-icon">
                    <span><i class="far fa-chart-bar"></i></span>
                  </div>
                  <div class="multi-step-info">
                    <h6>SEO</h6>
                  </div>
                </li>
              </ul>
            </div>

            <fieldset id="first-field" v-if="currentStep === 1">
              <admin-service-information />
              <div class="row">
                <div class="col-md-12">
                  <div class="bottom-btn">
                    <div class="field-btns">
                      <button
                        class="btn btn-primary next_btn"
                        type="button"
                        @click="nextStep"
                        :disabled="currentStep === totalSteps"
                      >
                        Next <i class="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset v-if="currentStep === 2">
              <admin-service-availability />
              <div class="row">
                <div class="col-md-12">
                  <div class="bottom-btn">
                    <div class="field-btns">
                      <button class="btn btn-prev prev_btn" type="button">
                        <i
                          class="fas fa-arrow-left"
                          @click="previousStep"
                          :disabled="currentStep === 1"
                        ></i>
                        Prev
                      </button>
                      <button
                        class="btn btn-primary next_btn"
                        type="button"
                        @click="nextStep"
                        :disabled="currentStep === totalSteps"
                      >
                        Next <i class="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset v-if="currentStep === 3">
              <admin-service-location />
              <div class="row">
                <div class="col-md-12">
                  <div class="bottom-btn">
                    <div class="field-btns">
                      <button
                        class="btn btn-prev prev_btn"
                        type="button"
                        @click="previousStep"
                        :disabled="currentStep === 2"
                      >
                        <i class="fas fa-arrow-left"></i> Prev
                      </button>
                      <button
                        class="btn btn-primary next_btn"
                        type="button"
                        @click="nextStep"
                        :disabled="currentStep === totalSteps"
                      >
                        Next <i class="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset v-if="currentStep === 4">
              <admin-service-gallery />
              <div class="bottom-btn">
                <div class="field-btns">
                  <button
                    class="btn btn-prev prev_btn"
                    type="button"
                    @click="previousStep"
                    :disabled="currentStep === 3"
                  >
                    <i class="fas fa-arrow-left"></i> Prev
                  </button>
                  <button
                    class="btn btn-primary next_btn"
                    type="button"
                    @click="nextStep"
                    :disabled="currentStep === totalSteps"
                  >
                    Next <i class="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </fieldset>

            <fieldset v-if="currentStep === 5">
              <admin-service-seo />
              <div class="bottom-btn">
                <div class="field-btns">
                  <button
                    class="btn btn-prev prev_btn"
                    type="button"
                    @click="previousStep"
                    :disabled="currentStep === 4"
                  >
                    <i class="fas fa-arrow-left"></i> Prev
                  </button>
                  <a
                    href="#"
                    class="btn btn-primary next_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#successmodal"
                    >Save</a
                  >
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade add-service-modal"
    id="successmodal"
    tabindex="-1"
    aria-labelledby="successmodal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <span>
            <i class="fa-regular fa-circle-check"></i>
          </span>
          <h3>Success</h3>
          <p>Service has been created succeessfully</p>
          <div class="popup-btn">
            <router-link to="services" class="btn btn-primary"
              >Go to Dashboard <i class="fa-solid fa-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentStep: 1,
      totalSteps: 5,
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    showProgress(step) {
      return this.currentStep === step;
    },
  },
};
</script>
