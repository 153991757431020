<template>
    <!-- Add Maintenance -->
    <div class="modal fade" id="add-maintenance" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Maintenance Schedule</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="closeButtonCreateModalRef"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="mb-3">
                <label class="form-label">Pilih Kendaraan</label>
                <select class="form-select" v-model="formCreate.vehicle_id" disabled>
                    <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id" :selected="vehicle.id === dataId">{{ vehicle.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Tanggal Mulai Perbaikan</label>
                <input type="datetime-local" class="form-control" v-model="formCreate.tgl_awal"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Tanggal Selesai Perbaikan</label>
                <input type="datetime-local" class="form-control" v-model="formCreate.tgl_akhir"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Perbaikan</label>
                <input type="text" class="form-control" v-model="formCreate.implementation"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Catatan</label>
                <input type="text" class="form-control" v-model="formCreate.remark"/>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Maintenance -->
  
    <!-- Edit Maintenance -->
    <div class="modal fade" id="edit-maintenance" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Maintenance Schedule</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="mb-3">
                <label class="form-label">Pilih Kendaraan</label>
                <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.vehicle_id" disabled>
                    <option v-for="vehicle in vehicleData" :key="vehicle.id" :value="vehicle.id" :selected="vehicle.id === formEdit.vehicle_id">{{ vehicle.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Tanggal Mulai Perbaikan</label>
                <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.tgl_awal" />
              </div>
              <div class="mb-3">
                <label class="form-label">Tanggal Selesai Perbaikan</label>
                <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.tgl_akhir" />
              </div>
              <div class="mb-3">
                <label class="form-label">Perbaikan</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.implementation" />
              </div>
              <div class="mb-3">
                <label class="form-label">Catatan</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.remark" />
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Maintenance -->
  
    <div class="modal fade" id="delete-maintenance" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Maintenance ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {}
      };
    },
    props: {
      startDate: Object,

      editData: Object,
      deleteItemId: Object,
      vehicleData: {
        type: Array,
        default: () => []
      },
      dataId: Object,
      editMaintenance: Object,
    },
    emits: ['edit-maintenance'],
    watch: {
      startDate: {
        immediate: true,
        handler(newStartDate) {
          if (newStartDate !== undefined) {
            this.formCreate.date = newStartDate;
          }
        }
      },

      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
      dataId: {
        immediate: true,
        handler(newVehicleId) {
          if (newVehicleId !== undefined) {
            this.formCreate.vehicle_id = parseInt(newVehicleId);
          }
        }
      },
      editMaintenance: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
    },
    methods: {
      editSubmit() {
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("maintenance/updateMaintenance", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {}
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createSubmit() {
        var loading = this.$loading.show()
        this.$store
          .dispatch("maintenance/addMaintenance", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {}
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
              this.$refs.closeButtonCreateModalRef.click()
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteData() {
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("maintenance/deleteMaintenance", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      }
    }
  };
  </script>
  
