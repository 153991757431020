<template>
    <!-- Add Vehicle -->
    <div class="modal fade custom-modal" id="add-vehicle" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Kendaraan</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <!-- <i class="feather feather-x"></i> -->
            </button>
          </div>
          <div class="modal-body pt-0 mt-4">
            <form @submit.prevent="createCheck">
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div v-if="!isNewVendor" class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Kepemilikan <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCreate.vendor_id">
                            <option value="Pribadi" selected>{{  company ? company.name : '---' }}</option>
                            <option v-for="vendor in vendorData" :key="vendor.id" :value="vendor.id">{{ vendor.full_name }}</option>
                        </select>
                    </div>
                    <div class="col-6 mt-4">
                      <a @click="isNewVendor = !isNewVendor" class="btn btn-primary col-6">{{ !isNewVendor ? 'Vendor Baru' : 'Batalkan' }}</a>
                    </div>
                  </div>
                </div>
                <div v-if="isNewVendor">
                  <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div class="col-6" style="margin-right: 10px;">
                        <label class="form-label">Nama Pemilik <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formVendor.full_name"/>
                      </div>
                      <div class="col-6">
                        <label class="form-label">No Telepon <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formVendor.phone_number"/>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div class="col-6" style="margin-right: 10px;">
                        <label class="form-label">Alamat <span style="color:red">*</span></label>
                        <input type="text" class="form-control"  v-model="formVendor.address"/>
                      </div>
                      <div class="col-6">
                        <label class="form-label">Type</label>
                        <select class="form-select" v-model="formCreate.type">
                            <option value=1>Investor</option>
                            <option value=2>Rent To Rent</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Model <span style="color:red">*</span></label>
                      <input type="text" class="form-control" v-model="formCreate.name"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Class Kendaraan <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.class">
                          <option value="SUV">SUV</option>
                          <option value="MVP">MVP</option>
                          <option value="Hatchback">Hatchback</option>
                          <option value="Sedan">Sedan</option>
                          <option value="Bus">Bus</option>
                          <option value="Commercial">Commercial</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="row">
                    <div class="col-md-6">
                      <div>
                        <label class="form-label">Transmisi <span style="color:red">*</span></label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="transmisi" id="transmisi_1" value="Manual" v-model="formCreate.transmisi">
                        <label class="form-check-label" for="inlineRadio1">Manual</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="transmisi" id="transmisi_2" value="Matic" v-model="formCreate.transmisi">
                        <label class="form-check-label" for="inlineRadio2">Matic</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <label class="form-label">Plat Nomor</label>
                        <input type="text" class="form-control" v-model="formCreate.nomor_plat"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Tahun Produksi <span style="color:red">*</span></label>
                      <input type="text" class="form-control" v-model="formCreate.production_year"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Kapasitas Penumpang <span style="color:red">*</span></label>
                      <input type="number" class="form-control" v-model="formCreate.capacity"/>
                    </div>
                    <!-- <div>
                      <label class="form-label">Nomor STNK</label>
                      <input type="text" class="form-control" v-model="formCreate.nomor_stnk"/>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Harga Dasar <span style="color:red">*</span></label>
                      <input type="text" class="form-control" v-model="formCreate.hpp"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Harga Rent to Rent <span style="color:red">*</span></label>
                      <input type="text" class="form-control" v-model="formCreate.hpp_rent_to_rent"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Warna <span style="color:red">*</span></label>
                      <input type="text" class="form-control" v-model="formCreate.color"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Merek <span style="color:red">*</span></label>
                        <select class="form-select" v-model="formCreate.brand_id" disabled>
                            <option v-for="brand in brandData" :key="brand.id" :value="brand.id" :selected="brand.id === idBrand">{{ brand.name }}</option>
                        </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Tipe Bahan Bakar <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.bbm_type">
                          <option value="Pertalite">Pertalite</option>
                          <option value="Pertamax">Pertamax</option>
                          <option value="Pertamax Turbo">Pertamax Turbo</option>
                          <option value="Solar">Solar</option>
                          <option value="Dexlite">Dexlite</option>
                      </select>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Status <span style="color:red">*</span></label>
                      <select class="form-select" v-model="formCreate.status">
                          <option value="AKTIF">Aktif</option>
                          <option value="TIDAK AKTIF">Tidak Aktif</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6">
                      <label class="form-label">Catatan</label>
                      <input type="text" class="form-control" v-model="formCreate.remark"/>
                    </div>
                  </div>
                </div>
              </div>
              
              
              <div class="mb-3">
                <div class="profile-upload-content">

                    <!-- company logo here -->
                    <img v-if="logo" :src="logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                    <!-- company logo here -->

                    <!-- upload file form logo here -->
                    <!-- <div class="profile-upload-btn ">
                        <label class="form-label">Foto Kendaraan <span style="color:red">*</span></label>
                        <div class="profile-upload-file">
                          <input
                              type="file"
                              class="form-control"
                              ref="uploadFieldAttachment"
                              id="imgInp"
                              @change="selectFileAttachment"
                          />
                        </div>
                    </div> -->
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Vehicle -->
  
    <!-- Edit Vehicle -->
    <div class="modal fade custom-modal" id="edit-vehicle" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Kendaraan</h5>
            <button
              id="close-edit"
              type="button"
              ref="closeButtonEditModalVehicle"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="row">
                <div class="mt-4 mb-3">
                  <div class="profile-upload-content">

                      <!-- company logo here -->
                      <img v-if="(formEdit !== undefined) && (formEdit.image !== '')" :src="formEdit.image" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                      <!-- company logo here -->

                      <!-- upload file form logo here -->
                      <div class="profile-upload-btn">
                          <div class="profile-upload-file">
                            <input
                                type="file"
                                class="form-control"
                                ref="uploadFieldAttachment"
                                id="imgInp"
                                @change="selectFileAttachment"
                            />
                            <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                          </div>
                      </div>
                      <!-- upload file form logo here -->
                      <div class="profile-upload-para">
                          <p>
                          *image size should be at least 320px big,and less then 500kb.
                          Allowed files .png and .jpg.
                          </p>
                      </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Model <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Class Kendaraan <span style="color:red">*</span></label>
                      <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.class">
                          <option value="SUV">SUV</option>
                          <option value="MVP">MVP</option>
                          <option value="Hatchback">Hatchback</option>
                          <option value="Sedan">Sedan</option>
                          <option value="Bus">Bus</option>
                          <option value="Commercial">Commercial</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="row">
                    <div class="col-md-6">
                      <div>
                        <label class="form-label">Transmisi <span style="color:red">*</span></label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input v-if="formEdit !== undefined" class="form-check-input" type="radio" name="transmisi" id="transmisi_1" value="Manual" v-model="formEdit.transmisi">
                        <label class="form-check-label" for="inlineRadio1">Manual</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input v-if="formEdit !== undefined" class="form-check-input" type="radio" name="transmisi" id="transmisi_2" value="Matic" v-model="formEdit.transmisi">
                        <label class="form-check-label" for="inlineRadio2">Matic</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <label class="form-label">Plat Nomor</label>
                        <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.nomor_plat"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Tahun Produksi</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.production_year"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Kapasitas Penumpang</label>
                      <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.capacity"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Harga Dasar <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.hpp"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Harga Rent to Rent <span style="color:red">*</span></label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.hpp_rent_to_rent"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Warna <span style="color:red">*</span></label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.color"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Merek <span style="color:red">*</span></label>
                      <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.brand_id">
                          <option v-for="brand in brandData" :key="brand.id" :value="brand.id" :selected="brand.id === formEdit.brand_id">{{ brand.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Tanggal Terakhir Ganti Oli</label>
                      <input v-if="formEdit !== undefined" type="datetime-local" class="form-control"  v-model="formEdit.date_last_oil"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Tanggal Terakhir Service</label>
                      <input v-if="formEdit !== undefined" type="datetime-local" class="form-control" v-model="formEdit.date_last_service"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Tipe Bahan Bakar <span style="color:red">*</span></label>
                      <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.bbm_type">
                          <option value="Pertalite">Pertalite</option>
                          <option value="Pertamax">Pertamax</option>
                          <option value="Pertamax Turbo">Pertamax Turbo</option>
                          <option value="Solar">Solar</option>
                          <option value="Dexlite">Dexlite</option>
                      </select>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Status <span style="color:red">*</span></label>
                      <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.status">
                          <option value="AKTIF">Aktif</option>
                          <option value="TIDAK AKTIF">Tidak Aktif</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Catatan</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.remark"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Kepemilikan <span style="color:red">*</span></label>
                        <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.vendor_id">
                            <option value="Pribadi">{{  company ? company.company.name : '-' }}</option>
                            <option v-for="vendor in vendorData" :key="vendor.id" :value="vendor.id">{{ vendor.full_name }}</option>
                        </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Vehicle -->
  
    <div class="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Kendaraan ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Product Vehicle -->
    <div class="modal fade custom-modal" id="add-product-vehicle" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Jenis Sewa</h5>
            <button
              id="close-create-price"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="addPrice">
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Jenis Sewa</label>
                            <select v-model="formPrice.product_id" class="form-select">
                                <option v-for="option in productData" :value="option.id">{{ option.rentalTypeName }} - {{ option.name }}</option>
                            </select>
                        </div>
                        <div>
                            <label class="form-label">Harga</label>
                            <input v-model.number="formPrice.price" class="form-control" type="number" placeholder="Item Price">
                        </div>
                    </div>
                </div>
                <div class="text-end" style="padding-bottom: 10px;">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Product Vehicle -->

    <!-- Edit Product Vehicle -->
    <div class="modal fade custom-modal" id="edit-product-vehicle" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Jenis Sewa</h5>
            <button
              id="close-edit-price"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editPrice">
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Jenis Sewa</label>
                            <select v-if="formPriceEdit !== undefined" v-model="formPriceEdit.product_id" class="form-select">
                                <option v-for="option in productData" :value="option.id">{{ option.rentalTypeName }} - {{ option.name }}</option>
                            </select>
                        </div>
                        <div>
                            <label class="form-label">Harga</label>
                            <input v-if="formPriceEdit !== undefined" v-model.number="formPriceEdit.price" class="form-control" type="number" placeholder="Item Price">
                        </div>
                    </div>
                </div>
                <div class="text-end" style="padding-bottom: 10px;">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Product Vehicle -->

    <div class="modal fade" id="delete-product-vehicle" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Jenis Sewa ?</h5>
  
            <button
              id="close-delete-price"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deletePrice">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Doc Vehicle -->
    <div class="modal fade custom-modal" id="add-vehicle-doc" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Dokumen Gambar</h5>
            <button
              id="close-vehicle-doc"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <!-- <i class="feather feather-x"></i> -->
            </button>
          </div>
          <div class="modal-body pt-0 mt-4">
            <form @submit.prevent="addDoc">
                <div class="mb-3 form-group">
                  <div class="profile-upload-content">
                    <!-- company logo here -->
                    <img v-if="logo" :src="logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                    <!-- company logo here -->

                    <!-- upload file form logo here -->
                    <div class="profile-upload-btn">
                        <div class="profile-upload-file">
                          <input
                              type="file"
                              class="form-control"
                              ref="uploadFieldAttachment"
                              id="imgInp"
                              @change="selectFileAttachment"
                          />
                          <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                        </div>
                    </div>
                    <!-- upload file form logo here -->
                    <div class="profile-upload-para">
                        <p>
                        *image size should be at least 320px big,and less then 500kb.
                        Allowed files .png and .jpg.
                        </p>
                    </div>
                  </div>
                </div>
                <div class="text-end" style="padding-bottom: 10px;">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Doc Vehicle -->
  </template>
  <script>
  const barChart = {
    colors: ['#4169E1'],
    series: [
      {
        name: "Received",
        type: "column",
        data: []
      }
    ],
    chart: {
      id: "vuechart-example",
      type: 'bar',
      fontFamily: 'Poppins, sans-serif',
      height: 350,
      toolbar: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          endingShape: 'rounded'
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      },
      yaxis: {
        title: {
          text: 'Jumlah Order'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    },
  };
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        formCreate: {
            // vehicle_prices: [{ price: 0}],
        },
        formPrice: {},
        formPriceEdit: {},
        formEdit : {},
        formDoc: {},
        itemId: 0,
        logo: '',
        barChart: barChart,
        isNewVendor: false,
        formVendor: {}
      };
    },
    props: {
      company: Object,
      editData: Object,
      deleteItemId: Object,
      productData: {
        type: Array,
        default: () => []
      },
      dataId: Object,
      editPrice: Object,
      brandData: {
        type: Array,
        default: () => []
      },
      vendorData: {
        type: Array,
        default: () => []
      },
      idBrand: Object,
    },
    emits: ['edit-vehicle'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
            // this.barChart.series[0].data = this.orderCountPerMonth();
          }
        }
      },
      editPrice: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formPriceEdit = newValue.data;
          }
        }
      },
      idBrand: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            console.log(newValue);
            this.formCreate.brand_id = parseInt(newValue);
          }
        }
      },
    },
    methods: {
      validateFile(file, allowedFileTypes, maxFileSize) {
          if (!allowedFileTypes.includes(file.type)) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
              });
              return false;
          }

          if (file.size > maxFileSize) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
              });
              return false;
          }

          return true;
      },
      // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      editSubmit() {
        console.log(this.formEdit)
        this.formEdit.image = this.formEdit.image !== '' ? this.formEdit.image : this.logo;
        this.formEdit.remark = this.formEdit.remark !== undefined ? this.formEdit.remark : "None";
        // console.log(this.formEdit);
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("vehicle/updateVehicle", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {};
              this.logo = '';
              this.$refs.closeButtonEditModalVehicle.click()
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createCheck() {
        if (this.formVendor.full_name) {
          this.saveNewVendor();
        } else {
          this.createSubmit();
        }
      },
      createSubmit() {
        this.formCreate.image = this.logo;
        this.formCreate.remark = this.formCreate.remark !== undefined ? this.formCreate.remark : "None";
        this.formCreate.vendor_id = this.formCreate.vendor_id != "Pribadi" ? this.formCreate.vendor_id : null;
        // console.log(this.formCreate);
        var loading = this.$loading.show()
        this.$store
          .dispatch("vehicle/addVehicle", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {}
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      saveNewVendor() {
        this.formVendor.type = 2;
        var loading = this.$loading.show()
        this.$store
        .dispatch("vendor/addVendor", this.formVendor )
        .then((data) => {
            if (data.status == 201) {
                this.formCreate.vendor_id = data.data.id;
                this.formVendor = {}
                this.createSubmit();
                loading.hide()
                this.$emit('nextCustomer');
            }
        },
        (error) => {
            console.log(error)
            loading.hide();
        })
        .catch((e) => {
            loading.hide()
        })
      },
      deleteData() {
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("vehicle/deleteVehicle", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      // addItemCreate() {
      //   if (this.formCreate.vehicle_prices.length === 0) {
      //       this.formCreate.vehicle_prices.push({ price: 0 });
      //   } else {
      //       this.formCreate.vehicle_prices.push({ price: 0 });
      //   }
      // },
      removeItemCreate(index) {
        this.formCreate.vehicle_prices.splice(index, 1);
      },
      // addItemEdit() {
      //   if (this.formEdit.vehicle_prices.length === 0) {
      //       this.formEdit.vehicle_prices.push({ price: 0 });
      //   } else {
      //       this.formEdit.vehicle_prices.push({ price: 0 });
      //   }
      // },
      removeItemEdit(index) {
        this.formEdit.vehicle_prices.splice(index, 1);
      },
      orderCountPerMonth() {
        const ordersByMonth = {
          1: 0,  // January
          2: 0,  // February
          3: 0,  // March
          4: 0,  // April
          5: 0,  // May
          6: 0,  // June
          7: 0,  // July
          8: 0,  // August
          9: 0,  // September
          10: 0, // October
          11: 0, // November
          12: 0  // December
        };
        if (this.formEdit && this.formEdit.orders) {
          this.formEdit.orders.forEach(order => {
            const month = new Date(order.starting_date).getMonth() + 1; 
            ordersByMonth[month]++;
          });
        }
        return Object.values(ordersByMonth);
      },
      addPrice() {
        // console.log(this.formEdit);
        this.formPrice.vehicle_id = Number(this.dataId);
        var loading = this.$loading.show()
        this.$store
          .dispatch("vehicle/addPrice", this.formPrice )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formPrice = {};
              document.getElementById('close-create-price').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      editPrice() {
        // console.log(this.formEdit);
        const id = this.formPriceEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("vehicle/updatePrice", { id: id, data: this.formPriceEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formPriceEdit = {};
              document.getElementById('close-edit-price').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deletePrice() {
        const id = this.formPriceEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("vehicle/deletePrice", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formPriceEdit = {};
              document.getElementById('close-delete-price').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      addDoc() {
        this.formDoc.file = this.logo;
        this.formDoc.vehicle_id = Number(this.dataId);
        var loading = this.$loading.show()
        this.$store
          .dispatch("vehicle/addDoc", this.formDoc )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.logo = '';
              this.formDoc = {};
              document.getElementById('close-vehicle-doc').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },

    },
  };
  </script>
  
