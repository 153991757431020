<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>Edit Service</h6>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Header -->
    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="service-wizard">
          <ul id="progressbar" v-show="currentStep === 1">
            <li class="active">
              <div class="multi-step-icon span-info">
                <span><i class="fa-regular fa-circle-check"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Information</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-clock"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Availability</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-map"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Location</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="feather-image"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Gallery</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="fa-solid fa-chart-bar"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Seo</h6>
              </div>
            </li>
          </ul>
          <ul id="progressbar" v-show="currentStep === 2">
            <li class="activated">
              <div class="multi-step-icon span-info">
                <span><i class="fa-regular fa-circle-check"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Information</h6>
              </div>
            </li>
            <li class="active">
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-clock"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Availability</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-map"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Location</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="feather-image"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Gallery</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="fa-solid fa-chart-bar"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Seo</h6>
              </div>
            </li>
          </ul>
          <ul id="progressbar" v-show="currentStep === 3">
            <li class="activated">
              <div class="multi-step-icon span-info">
                <span><i class="fa-regular fa-circle-check"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Information</h6>
              </div>
            </li>
            <li class="activated">
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-clock"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Availability</h6>
              </div>
            </li>
            <li class="active">
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-map"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Location</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="feather-image"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Gallery</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="fa-solid fa-chart-bar"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Seo</h6>
              </div>
            </li>
          </ul>
          <ul id="progressbar" v-show="currentStep === 4">
            <li class="activated">
              <div class="multi-step-icon span-info">
                <span><i class="fa-regular fa-circle-check"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Information</h6>
              </div>
            </li>
            <li class="activated">
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-clock"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Availability</h6>
              </div>
            </li>
            <li class="activated">
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-map"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Location</h6>
              </div>
            </li>
            <li class="active">
              <div class="multi-step-icon">
                <span><i class="feather-image"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Gallery</h6>
              </div>
            </li>
            <li>
              <div class="multi-step-icon">
                <span><i class="fa-solid fa-chart-bar"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Seo</h6>
              </div>
            </li>
          </ul>
          <ul id="progressbar" v-show="currentStep === 5">
            <li class="activated">
              <div class="multi-step-icon span-info">
                <span><i class="fa-regular fa-circle-check"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Information</h6>
              </div>
            </li>
            <li class="activated">
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-clock"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Availability</h6>
              </div>
            </li>
            <li class="activated">
              <div class="multi-step-icon">
                <span><i class="fa-regular fa-map"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Location</h6>
              </div>
            </li>
            <li class="activated">
              <div class="multi-step-icon">
                <span><i class="feather-image"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Gallery</h6>
              </div>
            </li>
            <li class="active">
              <div class="multi-step-icon">
                <span><i class="fa-solid fa-chart-bar"></i></span>
              </div>
              <div class="multi-step-info">
                <h6>Seo</h6>
              </div>
            </li>
          </ul>
        </div>

        <!-- <div class="service-inform-fieldset"> -->
        <fieldset id="first-field" v-if="currentStep === 1">
          <service-information></service-information>
          <div class="row">
            <div class="col-md-12">
              <div class="field-bottom-btns">
                <div class="field-btns">
                  <button
                    class="btn btn-primary next_btn"
                    type="button"
                    @click="nextStep"
                    :disabled="currentStep === totalSteps"
                  >
                    Next <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset v-if="currentStep === 2">
          <service-availability></service-availability>
          <div class="row">
            <div class="col-md-12">
              <div class="field-bottom-btns">
                <div class="field-btns">
                  <button
                    class="btn btn-primary prev_btn"
                    type="button"
                    @click="previousStep"
                    :disabled="currentStep === 1"
                  >
                    <i class="fa-solid fa-arrow-left"></i>Prev
                  </button>
                </div>
                <div class="field-btns">
                  <button
                    class="btn btn-primary next_btn"
                    type="button"
                    @click="nextStep"
                    :disabled="currentStep === totalSteps"
                  >
                    Next <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset v-if="currentStep === 3">
          <service-location></service-location>
          <div class="row">
            <div class="col-md-12">
              <div class="field-bottom-btns">
                <div class="field-btns">
                  <button
                    class="btn btn-primary prev_btn"
                    type="button"
                    @click="previousStep"
                    :disabled="currentStep === 2"
                  >
                    <i class="fa-solid fa-arrow-left"></i>Prev
                  </button>
                </div>
                <div class="field-btns">
                  <button
                    class="btn btn-primary next_btn"
                    type="button"
                    @click="nextStep"
                    :disabled="currentStep === totalSteps"
                  >
                    Next <i class="fa-solid fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset v-if="currentStep === 4">
          <service-gallery></service-gallery>
          <div class="field-bottom-btns">
            <div class="field-btns">
              <button
                class="btn btn-primary prev_btn"
                type="button"
                @click="previousStep"
                :disabled="currentStep === 3"
              >
                <i class="fa-solid fa-arrow-left"></i>Prev
              </button>
            </div>
            <div class="field-btns">
              <button
                class="btn btn-primary next_btn"
                type="button"
                @click="nextStep"
                :disabled="currentStep === totalSteps"
              >
                Next <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </fieldset>

        <fieldset v-if="currentStep === 5">
          <service-seo></service-seo>
          <div class="field-bottom-btns">
            <div class="field-btns">
              <button
                class="btn btn-primary prev_btn"
                type="button"
                @click="previousStep"
                :disabled="currentStep === 4"
              >
                <i class="fa-solid fa-arrow-left"></i>Prev
              </button>
            </div>
            <div class="field-btns">
              <router-link
                to="/provider-services"
                class="btn btn-primary done_btn"
                data-bs-toggle="modal"
                data-bs-target="#successmodal"
                >Next<i class="fa-solid fa-arrow-right"></i
              ></router-link>
            </div>
          </div>
        </fieldset>

        <!-- </div> -->
      </div>
    </div>
  </div>
  <model></model>
</template>

<script>
export default {
  data() {
    return {
      currentStep: 1,
      totalSteps: 5,
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    showProgress(step) {
      return this.currentStep === step;
    },
  },
};
</script>
