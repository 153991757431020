<template>
  <!-- Header -->
  <div class="header">
    <div class="header-left">
      <router-link to="/admin/dashboard" class="logo">
        <img src="@/assets/admin_img/logo_marirental3.svg" alt="Logo" width="30" height="30" />
      </router-link>
      <router-link to="/admin/dashboard" class="logo-small">
        <img src="@/assets/admin_img/logo_marirental2.svg" alt="Logo" width="50" height="50" />
      </router-link>
    </div>
    <!-- <a
      class="mobile_btn"
      id="mobile_btn"
      href="javascript:void(0);"
      @click="toggleSidebar"
    >
      <i class="feather feather-align-left"></i>
    </a> -->
    <div class="header-split">
      <div class="page-headers">
        <!-- <div class="search-bar">
          <span><i class="feather feather-search"></i></span>
          <input type="text" placeholder="Search" class="form-control" />
        </div> -->
        <router-link to="/admin/dashboard" class="logo">
          <img src="@/assets/admin_img/logo_marirental3.svg" alt="Logo" width="200" height="50" />
        </router-link>
      </div>
      <ul class="nav user-menu">
        <!-- Notifications -->
        <!-- <li class="nav-item">
          <router-link to="/" class="viewsite" @click="redirectReload"
            ><i class="feather feather-globe me-2"></i>View Site</router-link
          >
        </li> -->
        <!-- <li class="nav-item dropdown has-arrow dropdown-heads flag-nav">
          <a
            class="nav-link"
            data-bs-toggle="dropdown"
            href="javascript:void(0);"
            role="button"
          >
            <img src="@/assets/admin_img/flags/us1.png" alt="" height="20" />
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a href="javascript:void(0);" class="dropdown-item">
              <img
                src="@/assets/admin_img/flags/us.png"
                class="me-2"
                alt=""
                height="16"
              />
              English
            </a>
            <a href="javascript:void(0);" class="dropdown-item">
              <img
                src="@/assets/admin_img/flags/fr.png"
                class="me-2"
                alt=""
                height="16"
              />
              French
            </a>
            <a href="javascript:void(0);" class="dropdown-item">
              <img
                src="@/assets/admin_img/flags/es.png"
                class="me-2"
                alt=""
                height="16"
              />
              Spanish
            </a>
            <a href="javascript:void(0);" class="dropdown-item">
              <img
                src="@/assets/admin_img/flags/de.png"
                class="me-2"
                alt=""
                height="16"
              />
              German
            </a>
          </div>
        </li> -->
        <!-- <li class="nav-item has-arrow dropdown-heads">
          <a href="javascript:void(0);" class="toggle-switch">
            <i class="feather feather-moon"></i>
          </a>
        </li> -->
        <!-- <li class="nav-item dropdown has-arrow dropdown-heads">
          <a href="javascript:void(0);" data-bs-toggle="dropdown">
            <i class="feather feather-bell"></i>
          </a>
          <div class="dropdown-menu notifications">
            <div class="topnav-dropdown-header">
              <span class="notification-title">Notifications</span>
              <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
            </div>
            <div class="noti-content">
              <ul class="notification-list">
                <li class="notification-message">
                  <a href="javascript:;">
                    <div class="media d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle"
                          alt=""
                          src="@/assets/admin_img/provider/provider-01.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          <span class="noti-title"
                            >Thomas Herzberg have been subscribed</span
                          >
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">15 Sep 2023 10:20 PM</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="notification-message">
                  <a href="javascript:;">
                    <div class="media d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle"
                          alt=""
                          src="@/assets/admin_img/provider/provider-02.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          <span class="noti-title"
                            >Matthew Garcia have been subscribed</span
                          >
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">13 Sep 2023 03:56 AM</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="notification-message">
                  <a href="javascript:;">
                    <div class="media d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle"
                          alt=""
                          src="@/assets/admin_img/provider/provider-03.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          <span class="noti-title"
                            >Yolanda Potter have been subscribed</span
                          >
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">12 Sep 2023 09:25 PM</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="notification-message">
                  <a href="javascript:;">
                    <div class="media d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle"
                          alt="User Image"
                          src="@/assets/admin_img/provider/provider-04.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          <span class="noti-title"
                            >Ricardo Flemings have been subscribed</span
                          >
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">11 Sep 2023 06:36 PM</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="notification-message">
                  <a href="javascript:;">
                    <div class="media d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle"
                          alt="User Image"
                          src="@/assets/admin_img/provider/provider-05.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          <span class="noti-title"
                            >Maritza Wasson have been subscribed</span
                          >
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">10 Sep 2023 08:42 AM</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="notification-message">
                  <a href="javascript:;">
                    <div class="media d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle"
                          alt="User Image"
                          src="@/assets/admin_img/provider/provider-06.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          <span class="noti-title">Marya Ruiz have been subscribed</span>
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">9 Sep 2023 11:01 AM</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="notification-message">
                  <a href="javascript:;">
                    <div class="media d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle"
                          alt="User Image"
                          src="@/assets/admin_img/provider/provider-07.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          <span class="noti-title"
                            >Richard Hughes have been subscribed</span
                          >
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">8 Sep 2023 06:23 AM</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="topnav-dropdown-footer">
              <router-link to="/admin/notifications">View all Notifications</router-link>
            </div>
          </div>
        </li> -->
        <li class="nav-item has-arrow dropdown-heads">
          <router-link to="/admin/dashboard" class="logo">
            <i class="feather feather-home"></i>
          </router-link>
        </li>
        <li class="nav-item has-arrow dropdown-heads" @click="initFullScreen">
          <a href="javascript:void(0);" class="win-maximize">
            <i class="feather feather-maximize"></i>
          </a>
        </li>

        <!-- User Menu -->
        <li class="nav-item dropdown">
          <a
            href="javascript:void(0)"
            class="user-link nav-link"
            data-bs-toggle="dropdown"
          >
            <span class="user-img">
              <img
                class="rounded-circle"
                src="@/assets/admin_img/user.jpg"
                width="40"
                alt="Admin"
              />
              <span class="animate-circle"></span>
            </span>
            <span class="user-content">
              <span class="user-name">John Smith</span>
              <span class="user-details">Demo User</span>
            </span>
          </a>
          <div class="dropdown-menu menu-drop-user">
            <div class="profilemenu">
              <div class="user-detials">
                <a href="javascript:;">
                  <span class="profile-image">
                    <img
                      src="@/assets/admin_img/user.jpg"
                      alt="img"
                      class="profilesidebar"
                    />
                  </span>
                  <span class="profile-content">
                    <span>John Smith</span><br />
                    <span>John@gmail.com</span>
                  </span>
                </a>
              </div>
              <div class="subscription-menu">
                <ul>
                  <li>
                    <router-link to="/admin/account-settings">Profile</router-link>
                  </li>
                  <li>
                    <router-link to="/admin/localization">Settings</router-link>
                  </li>
                </ul>
              </div>
              <div class="subscription-logout">
                <a class="nav-link" @click="logout">
                    <span>Log Out</span>
                </a>
              </div>
            </div>
          </div>
        </li>
        <!-- /User Menu -->
      </ul>
    </div>
  </div>
  <!-- /Header -->
</template>
<script>
export default {
  methods: {
    logout() {
        let loading = this.$loading.show();
        this.$store.dispatch("auth/logout").then(() => {
            loading.hide();
            this.$router.replace({ path: "login" });
        });
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleSidebar() {
      const wrapper = document.querySelector(".main-wrapper");
      const sidebarOverlay = document.querySelector(".sidebar-overlay");
      wrapper.classList.toggle("slide-nav");
      document.documentElement.classList.toggle("menu-opened");
    },
    redirectReload() {
      this.$router.push({ path: "/" }).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>
