<template>
  <div class="content-sidelink float-start">
    <div class="content-sidelinkheading">
      <h6>Settings</h6>
    </div>
    <div class="content-sidelinkmenu">
      <ul>
        <li>
          <h5>General Settings</h5>
        </li>
        <li><router-link to="/admin/localization">Localization </router-link></li>
        <li>
          <router-link to="/admin/account-settings">Account Settings </router-link>
        </li>
        <li><router-link to="/admin/security">Security</router-link></li>
        <li>
          <router-link to="/admin/social-profiles">Social Profiles </router-link>
        </li>
        <li><router-link to="/admin/notifications">Notifications</router-link></li>
        <li><router-link to="/admin/connected-apps">Connected Apps</router-link></li>
        <li>
          <router-link to="/admin/appointment-settings">Appointment Settings</router-link>
        </li>
      </ul>
      <ul>
        <li>
          <h5>Website Settings</h5>
        </li>
        <li>
          <router-link to="/admin/site-information">Site Information</router-link>
        </li>
        <li><router-link to="/admin/seo-settings">Seo Settings</router-link></li>
        <li>
          <router-link to="/admin/preference-settings">Preference Settings</router-link>
        </li>
        <li><router-link to="/admin/appearance">Appearance </router-link></li>
        <li>
          <h4>Frontend Settings</h4>
        </li>
        <li class="submenu-sidelink">
          <router-link to="/admin/header-settings">Header Settings</router-link>
        </li>
        <li class="submenu-sidelink">
          <router-link to="/admin/footer-settings">Footer Settings</router-link>
        </li>
        <li>
          <router-link to="/admin/authentication-settings"
            >Authentication Settings</router-link
          >
        </li>
        <li>
          <router-link to="/admin/social-authentication"
            >Social Authentication</router-link
          >
        </li>
        <li><router-link to="/admin/language">Language </router-link></li>
        <li>
          <router-link to="/admin/typography-settings">Typography Settings</router-link>
        </li>
      </ul>
      <ul>
        <li>
          <h5>System Settings</h5>
        </li>
        <li>
          <router-link to="/admin/email-storage-settings">Email Settings </router-link>
        </li>
        <li><router-link to="/admin/sms-settings">SMS Settings</router-link></li>
        <li><router-link to="/admin/gdpr-settings">GDPR Settings </router-link></li>
        <li>
          <router-link to="/admin/calendar-settings">Calendar Settings</router-link>
        </li>
      </ul>
      <ul>
        <li>
          <h5>Financial Settings</h5>
        </li>
        <li>
          <router-link to="/admin/payment-gateways">Payment Gateways</router-link>
        </li>
        <li>
          <router-link to="/admin/payment-settings">Payment Settings</router-link>
        </li>
        <li><router-link to="/admin/tax-rates">Tax Rates</router-link></li>
        <li><router-link to="/admin/currencies">Currencies</router-link></li>
      </ul>
      <ul>
        <li>
          <h5>
            <router-link to="/admin/service-settings">Service Settings</router-link>
          </h5>
        </li>
      </ul>
      <ul>
        <li>
          <h5>
            <router-link to="/admin/provider-settings">Provider Settings</router-link>
          </h5>
        </li>
      </ul>
      <ul>
        <li>
          <h5>Other Settings</h5>
        </li>
        <li>
          <router-link to="/admin/storage-settings">Storage Settings</router-link>
        </li>
        <li><router-link to="/admin/ban-ip-address">Ban IP Address</router-link></li>
        <li><router-link to="/admin/cronjob">Cronjob</router-link></li>
        <li>
          <h4>Backup</h4>
        </li>
        <li class="submenu-sidelink">
          <router-link to="/admin/system-backup">System Backup</router-link>
        </li>
        <li class="submenu-sidelink">
          <router-link to="/admin/database-backup">Database Backup</router-link>
        </li>
        <li>
          <router-link to="/admin/system-information">System Information</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
