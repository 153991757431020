<template>
    <div class="provider-body">
        <div class="main-wrapper">
            <dashboardnavbar />

            <div class="page-wrapper">
                <sidebar />

                <providerservicescontent />

                <provideractiveservice />

                <providerdeleteservice />
            </div>
        </div>
    </div>
</template>