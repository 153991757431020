<template>
  <!-- pricing Section -->
  <section class="price-sections-seven">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-seven aos" data-aos="fade-up">
            <h2>Our Recent Blog</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel our-recent-blog">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexsevenpricing" :key="item.id">
                <div
                  class="service-widget service-widget-space service-seven-blog aos"
                  data-aos="fade-up"
                >
                  <div class="service-img">
                    <router-link to="/blog-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/blog/${item.image}`)"
                      />
                    </router-link>
                  </div>
                  <div class="service-content service-content-seven">
                    <div class="service-bottom-seven">
                      <div class="service-bottom-icons">
                        <i class="feather-calendar"></i>
                        <span>{{ item.date }}</span>
                      </div>
                      <h6>{{ item.badge }}</h6>
                    </div>
                    <h3 class="title">
                      <router-link to="/blog-details"
                        >Lorem ipsum dolor sit amet, adipiscing elit, sed do
                        eiusmod</router-link
                      >
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium laudantium,eaque ipsa
                    </p>
                    <div class="usefull-bottom">
                      <div class="useful-img">
                        <img
                          :src="require(`@/assets/img/profiles/${item.avatar}`)"
                          alt=""
                        />
                        <span>{{ item.name }}</span>
                      </div>
                      <a href="javascript:void(0);">Read More</a>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /pricing Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexsevenpricing from "@/assets/json/indexsevenpricing.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexsevenpricing: indexsevenpricing,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
