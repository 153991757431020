<template>
  <section class="popular-eight-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-eight aos" data-aos="fade-up">
            <img src="@/assets/img/icons/dog.svg" alt="" />
            <h2>Popular Services</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-3 col-md-6 col-sm-6 col-12"
          v-for="item in IndexEightPopular"
          :key="item.id"
        >
          <div class="service-widget aos" data-aos="fade-up">
            <div class="service-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  :src="require(`@/assets/img/gallery/${item.image}`)"
                />
              </router-link>
              <div class="fav-item">
                <div class="features-service-five features-service-eight">
                  <h6>{{ item.category }}</h6>
                </div>
                <a
                  href="javascript:void(0)"
                  class="fav-icon fav-icon-five"
                  @click="toggleSelected"
                >
                  <i class="feather-heart"></i>
                </a>
              </div>
            </div>
            <div class="service-content service-feature-eight">
              <div class="shop-content-logo">
                <img
                  :src="require(`@/assets/img/profiles/${item.providerImage}`)"
                  alt=""
                />
              </div>
              <h3 class="title">
                <router-link to="/service-details">{{ item.title }}</router-link>
              </h3>
              <p><i class="feather-map-pin"></i>{{ item.location }}</p>
              <div class="feature-services-eight">
                <div class="popular-service-eight">
                  <h6>{{ item.pricePerHour }}<span>/hr</span></h6>
                  <p>{{ item.originalPrice }}</p>
                </div>
                <div class="rate">
                  <div class="rate-icon">
                    <i class="fa-solid fa-star filled me-1"></i
                    ><span>{{ item.rating }}</span>
                  </div>
                  <h6>({{ item.numOfReviews }})</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-sec btn-saloons btn-pets aos" data-aos="fade-up">
        <router-link to="/search" class="btn btn-primary btn-view"
          >VIEW ALL SERVICES</router-link
        >
      </div>
    </div>
  </section>

  <section class="works-eight-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-eight aos" data-aos="fade-up">
            <img src="@/assets/img/icons/dog.svg" alt="" />
            <h2>How it Works</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="works-eights-main">
            <div class="works-eights-img">
              <img src="@/assets/img/icons/gui-calendar-planner-eight.svg" alt="" />
              <div class="works-eights-arrow">
                <img src="@/assets/img/icons/arrow-eight-1.svg" alt="" />
              </div>
            </div>
            <p>Connect with your Calendar</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="works-eights-main">
            <div class="works-eights-img">
              <img src="@/assets/img/icons/pointer-eight.svg" alt="" />
              <div class="works-eights-arrow works-eights-arrow-two">
                <img src="@/assets/img/icons/arrow-eight-2.svg" alt="" />
              </div>
            </div>
            <p>Connect with your Calendar</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="works-eights-main">
            <div class="works-eights-img">
              <img src="@/assets/img/icons/dog-face-eight.svg" alt="" />
              <div class="works-eights-arrow">
                <img src="@/assets/img/icons/arrow-eight-1.svg" alt="" />
              </div>
            </div>
            <p>Connect with your Calendar</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="works-eights-main">
            <div class="works-eights-img">
              <img src="@/assets/img/icons/pay-per-eight.svg" alt="" />
            </div>
            <p>Connect with your Calendar</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="about-us-eight-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="about-eight-main">
            <img src="@/assets/img/about-us-eight.png" alt="" />
            <div class="truely-eight-bg">
              <img src="@/assets/img/her-section-eight-bg.png" alt="" class="img-fluid" />
            </div>
            <div class="truely-eight-bg-two">
              <img src="@/assets/img/her-section-eight-bg.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="passion-eight-all">
            <div
              class="section-heading section-heading-eight passion-eight-heading aos"
              data-aos="fade-up"
            >
              <img src="@/assets/img/icons/dog.svg" alt="" />
              <h2>About Truely sell pet care</h2>
              <p>Mauris ut cursus nunc.</p>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the
              1500s, when an unknown printer took a galley of type and scrambled it to
              make a type specimen book.
            </p>
            <ul>
              <li>
                <img src="@/assets/img/icons/pink-dog-feet.svg" alt="" />
                <span>The Pet Expert</span>
              </li>
              <li>
                <img src="@/assets/img/icons/pink-dog-feet.svg" alt="" />
                <span>Love Your Pet More</span>
              </li>
              <li>
                <img src="@/assets/img/icons/pink-dog-feet.svg" alt="" />
                <span>Your Pet, Our Passion</span>
              </li>
              <li>
                <img src="@/assets/img/icons/pink-dog-feet.svg" alt="" />
                <span>The Pet Expert</span>
              </li>
              <li>
                <img src="@/assets/img/icons/pink-dog-feet.svg" alt="" />
                <span>Love Your Pet More</span>
              </li>
              <li>
                <img src="@/assets/img/icons/pink-dog-feet.svg" alt="" />
                <span>Your Pet, Our Passion</span>
              </li>
            </ul>
            <div class="passion-eight-content">
              <div class="passion-content-top">
                <img src="@/assets/img/icons/win.svg" alt="" />
                <div class="passion-content-bottom">
                  <h2>98.7%</h2>
                  <p>of reviews are 5 star</p>
                </div>
                <a href="javascript:;" class="btn btn-primary">Book Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="cat-dog-eight-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
              <div class="clients-eights-all">
                <div class="clients-eight-span">
                  <h3 class="counter">644</h3>
                  <span>+</span>
                </div>
                <p>Active Clients</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="clients-eights-all">
                <div class="clients-eight-span">
                  <h3 class="counter">356</h3>
                  <span>+</span>
                </div>
                <p>Professionals</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="clients-eights-all">
                <div class="clients-eight-span">
                  <h3 class="counter">123</h3>
                  <span>+</span>
                </div>
                <p>Pet Services</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
              <div class="clients-eights-all">
                <div class="clients-eight-span">
                  <h3 class="counter">965</h3>
                  <span>+</span>
                </div>
                <p>Awards</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="professional-eight-img">
            <img src="@/assets/img/professional-eight.png" alt="" class="img-fluid" />
            <div class="professional-eight-bg">
              <img src="@/assets/img/her-section-eight-bg.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IndexEightPopular from "@/assets/json/indexeightpopular.json";
export default {
  data() {
    return {
      IndexEightPopular: IndexEightPopular,
      isFavourite: false,
    };
  },
  methods: {
    toggleSelected(event) {
      const element = event.target;
      element.classList.toggle("selected");
    },
  },
};
</script>

<style>
.popular-eight-section .service-widget {
  margin-right: 0px;
}
</style>
