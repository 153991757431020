<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <sidelinkheading></sidelinkheading>
      <div class="content w-100">
        <div class="content-page-header">
          <h5>Typography Settings</h5>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card typo-setting">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-groupheads">
                      <h2>Website Font</h2>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Font Name</label>

                    <vue-select
                      :options="SettingsFont"
                      id="settingsfont"
                      placeholder="Circular Std"
                    />
                  </div>
                  <div class="btn-typograp">
                    <a href="javascript:void(0);" class="btn btn-primary">Update</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card typo-setting">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-groupheads">
                      <h2>Heading Fonts</h2>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Body</label>

                    <vue-select
                      :options="SettingsBody"
                      id="settingsbody"
                      placeholder="Circular Std"
                    />
                  </div>
                  <div class="form-group">
                    <label>Heading 1</label>

                    <vue-select
                      :options="SettingsHeading1"
                      id="settingsheading1"
                      placeholder="Circular Std"
                    />
                  </div>
                  <div class="form-group">
                    <label>Heading 2</label>

                    <vue-select
                      :options="SettingsHeading2"
                      id="settingsheading2"
                      placeholder="Circular Std"
                    />
                  </div>
                  <div class="form-group">
                    <label>Heading 3</label>

                    <vue-select
                      :options="SettingsHeading3"
                      id="settingsheading3"
                      placeholder="Circular Std"
                    />
                  </div>
                  <div class="form-group">
                    <label>Heading 4</label>

                    <vue-select
                      :options="SettingsHeading4"
                      id="settingsheading4"
                      placeholder="Circular Std"
                    />
                  </div>
                  <div class="form-group">
                    <label>Heading 5</label>

                    <vue-select
                      :options="SettingsHeading5"
                      id="settingsheading5"
                      placeholder="Circular Std"
                    />
                  </div>
                  <div class="form-group">
                    <label>Heading 6</label>

                    <vue-select
                      :options="SettingsHeading6"
                      id="settingsheading6"
                      placeholder="Circular Std"
                    />
                  </div>
                </div>
                <div class="btn-typograp">
                  <a href="javascript:void(0);" class="btn btn-primary">Update</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      SettingsFont: ["Circular Std", "ASC"],
      SettingsBody: ["Circular Std", "ASC"],
      SettingsHeading1: ["Circular Std", "ASC"],
      SettingsHeading2: ["Circular Std", "ASC"],
      SettingsHeading3: ["Circular Std", "ASC"],
      SettingsHeading4: ["Circular Std", "ASC"],
      SettingsHeading5: ["Circular Std", "ASC"],
      SettingsHeading6: ["Circular Std", "ASC"],
    };
  },
};
</script>
