<template>
    <!-- Edit Pelunasan -->
    <div class="modal fade" id="add-pelunasan" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Form Pelunasan</h5>
            <button
              id="close-edit"
              type="button"
              ref="closeButtonPelunasan"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="mb-3">
                <label class="form-label">No Order</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.no_order" disabled/>
              </div>
              <div class="mb-3">
                <label class="form-label">Total Debit Payment</label>
                <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.total_price" disabled/>
              </div>
              <div class="mb-3">
                <label class="form-label">Bukti DP</label>
                <div class="profile-upload-content">
                    <img v-if="(formEdit !== undefined && formEdit.bukti_dp)" :src="formEdit.bukti_dp" alt="Preview Bukti DP" class="img-thumbnail mb-3" width="100" height="100" />
                    <div class="profile-upload-btn">
                        <div class="profile-upload-file">
                            <input type="file" @change="event => selectFileAttachment(event, 'bukti_dp')" />
                        </div>
                    </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Total Bayar DP</label>
                <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.dp" disabled/>
              </div>
              <div class="mb-3">
                <label class="form-label">Sisa Yang dibayarkan</label>
                <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.sisa_pembayaran" />
              </div>
              <div class="mb-3">
                <label class="form-label">Bukti Yang pembayaran</label>
                <div class="profile-upload-content">
                    <img v-if="(formEdit !== undefined && formEdit.bukti_lunas)" :src="formEdit.bukti_lunas" alt="Preview Bukti Sisa Pembayaran" class="img-thumbnail mb-3" width="100" height="100" />
                    <div class="profile-upload-btn">
                        <div class="profile-upload-file">
                            <input type="file" @change="event => selectFileAttachment(event, 'bukti_lunas')" />
                        </div>
                    </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Status Pembayaran</label>
                <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.status_payment">
                    <option value="Sudah Lunas">Sudah Lunas</option>
                    <option value="Belum Lunas">Belum Lunas</option>
                </select>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Pelunasan -->
  
  </template>
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        files: {
            bukti_dp: null,
            bukti_lunas: null,
        },
        formEdit : {}
      };
    },
    props: {
      editData: Object,
    },
    emits: ['edit-pelunasan'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
    },
    methods: {
        validateFile(file, allowedFileTypes, maxFileSize) {
            if (!allowedFileTypes.includes(file.type)) {
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
                });
                return false;
            }

            if (file.size > maxFileSize) {
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
                });
                return false;
            }

            return true;
        },
        selectFileAttachment(event, column) {
            const file = event.target.files[0];
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const maxFileSize = 2 * 1024 * 1024; // 2 MB

            if (!this.validateFile(file, allowedFileTypes, maxFileSize)) {
                return;
            }
            if (file) {
                this.files[column] = file;

                var loading = this.$loading.show();

                uploadFile(file)
                    .then((resp) => {
                        // Directly update the formCreate object
                        this.formEdit[column] = resp;  // Ensure that resp contains a 'url' property
                        loading.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: 'File berhasil diupload !!',
                        });
                    })
                    .catch((e) => {
                        loading.hide();
                        this.$swal.fire({
                            icon: 'warning',
                            text: 'Terjadi kesalahan, ' + e,
                        });
                    });
            }
        },
        editSubmit() {
            const id = this.formEdit.id;
            var loading = this.$loading.show()
            this.$store
            .dispatch("order/updateOrder", { id: id, data: this.formEdit })
            .then((data) => {
                if (data.status == 200) {
                loading.hide()
                this.formEdit = {};
                this.files= {
                    bukti_dp: null,
                    bukti_lunas: null,
                };
                document.getElementById('close-edit').click();
                this.$refs.closeButtonPelunasan.click()
                this.$emit('dataUpdated');
                }
            },
            (error) => {
                console.log(error)
                loading.hide();
            })
            .catch((e) => {
                loading.hide()
            })
        },
    }
  };
  </script>
  