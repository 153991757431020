import axios from 'axios';

export default {
    namespaced: true,
    state: {
        user: {},
        isLoggedin: false,
        companyInfo: null,
        errorMessage: null,
    },
    mutations: {
        setUser(state, data) {
            state.user = data;
            state.isLoggedin = true;
        },
        setCompanyInfo(state, companyInfo) {
          state.companyInfo = companyInfo;
        },
        setError(state, message) {
          state.errorMessage = message;
        },
        clearError(state) {
          state.errorMessage = null;
        },
    },
    actions: {

        register(_, data){
            return new Promise((resolve,reject) => {
                axios.post('register-company', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        login({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('/login', data).then(resp => {
                    localStorage.setItem('token', resp.data.token)
                    localStorage.setItem('user', JSON.stringify(resp.data.user))
                    localStorage.setItem('expiresAt', resp.data.expires_in)
                    commit('setUser', resp.data.user)
                    commit('setCompanyInfo', resp.data.user.company)
                    axios.defaults.headers.common['Authorization'] = 'Bearer' + localStorage.getItem('token');
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        changePassword(_, data){
            return new Promise((resolve,reject) => {
                axios.post('change-password', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        me(_) {
            return new Promise((resolve, reject) => {
                axios.get('/me').then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        logout(_) {
            return new Promise(resolve => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                resolve();
            })
        },

        requestEmailVerify(_) {
            return new Promise((resolve, reject) => {
                axios.post('email/request-verification').then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        emailVerify(_, token) {
            localStorage.setItem('token',token);
            axios.defaults.headers.common['Authorization'] = 'Bearer' + localStorage.getItem('token');
            return new Promise((resolve, reject) => {
                axios.get('email/verify?token='+token).then(resp => {
                    localStorage.setItem('user', JSON.stringify(resp.data.data))
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        forgetPassword(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('email/forget-password', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        
        resetPassword(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('reset-password?token=' + data.token, data.data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },
    },
    getters: {
        getUser: state => { return state.user.user },
        getIsLoggedin: state => { return state.user.isLoggedin },
        getCompanyInfo: state => { return state.user.companyInfo },
    }
}