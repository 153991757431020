<template>
    <div class="home-page-five">
        <div class="main-wrapper">
            <div class="page-wrapper">

                <indexsixbanner />
                
                <indexsixcompany />

                <indexsixwork />

                <indexsixteam />

                <indexsixpricing />

                <indexsixfooter />

                <scroll />
            </div>
        </div>
    </div>
</template>