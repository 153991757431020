<template>
  <div class="col-lg-8">
    <div class="service-wrap">
      <h5>Service Details</h5>
      <p>
        Car wash is a facility used to clean the exterior and, in some cases, the interior
        of motor vehicles. Car washes can be self-serve, fully automated, or full-service
        with attendants who wash the vehicle.
      </p>
    </div>
    <div class="service-wrap provide-service">
      <h5>Service Provider</h5>
      <div class="row">
        <div class="col-md-4">
          <div class="provide-box">
            <img src="@/assets/img/profiles/avatar-02.jpg" class="img-fluid" alt="img" />
            <div class="provide-info">
              <h6>Member Since</h6>
              <div class="serv-review">
                <i class="fa-solid fa-star"></i> <span>4.9 </span>(255 reviews)
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="provide-box">
            <span><i class="feather-user"></i></span>
            <div class="provide-info">
              <h6>Member Since</h6>
              <p>Apr 2023</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="provide-box">
            <span><i class="feather-map-pin"></i></span>
            <div class="provide-info">
              <h6>Address</h6>
              <p>Hanover, Maryland</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="provide-box">
            <span><i class="feather-mail"></i></span>
            <div class="provide-info">
              <h6>Email</h6>
              <p>thomash@example.com</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="provide-box">
            <span><i class="feather-phone"></i></span>
            <div class="provide-info">
              <h6>Phone</h6>
              <p>+1 888 888 8888</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="social-icon provide-social">
            <ul>
              <li>
                <a href="javascript:;" target="_blank"
                  ><i class="feather-instagram"></i>
                </a>
              </li>
              <li>
                <a href="javascript:;" target="_blank"
                  ><i class="feather-twitter"></i>
                </a>
              </li>
              <li>
                <a href="javascript:;" target="_blank"><i class="feather-youtube"></i></a>
              </li>
              <li>
                <a href="javascript:;" target="_blank"
                  ><i class="feather-linkedin"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="service-wrap">
      <div class="row">
        <div class="col-md-6">
          <h5>Gallery</h5>
        </div>
        <div class="col-md-6 text-md-end">
          <div class="owl-nav mynav3"></div>
        </div>
      </div>
      <div class="owl-carousel gallery-slider">
        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="item in ServiceContentGallery" :key="item.id">
            <div class="gallery-widget">
              <a
                :src="require(`@/assets/img/gallery/${item.picture}`)"
                data-fancybox="gallery"
              >
                <img
                  class="img-fluid"
                  alt="Image"
                  :src="require(`@/assets/img/gallery/${item.image}`)"
                />
              </a>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
    <div class="service-wrap">
      <h5>Video</h5>
      <div id="background-video">
        <button class="play-btn" @click="openLightbox"><i class="fa-solid fa-play"></i
        ></button>
      </div>
    </div>
    <div class="service-wrap">
      <h5>Reviews</h5>
      <ul>
        <li class="review-box" v-for="record in Servicedetails" :key="record.id">
          <div class="review-profile">
            <div class="review-img">
              <img
                :src="require(`@/assets/img/profiles/${record.Avatar}`)"
                class="img-fluid"
                alt="img"
              />
              <div class="review-name">
                <h6>{{ record.name }}</h6>
                <p>{{ record.date }} {{ record.time }}</p>
              </div>
            </div>
            <div class="rating">
              <i :class="record.class1"></i>
              <i :class="record.class2"></i>
              <i :class="record.class3"></i>
              <i :class="record.class4"></i>
              <i :class="record.class5"></i>
            </div>
          </div>
          <p>
            {{ record.content }}
          </p>
          <div class="recommend-item">
            <a href="javascript:;"
              ><img
                alt="Image"
                :src="require(`@/assets/img/icons/${record.Replyimg}`)"
                class="me-2"
              />
              Reply</a
            >
            <div class="recommend-info">
              <p>Recommend?</p>
              <a href="javascript:;"><i class="feather-thumbs-up"></i> Yes</a>
              <a href="javascript:;"><i class="feather-thumbs-down"></i> No</a>
            </div>
          </div>
          <div class="reply-area">
            <textarea
              :class="record.ClassOne"
              rows="3"
              placeholder="Type your response....."
            ></textarea>
          </div>
        </li>
      </ul>
      <div class="text-center">
        <router-link to="/customer-reviews" class="btn btn-primary btn-review"
          >View All Reviews</router-link
        >
      </div>
    </div>

    <div v-if="lightboxVisible" class="video-lightbox">
      <div class="video-container">
        <!-- Embed YouTube video using an iframe -->
        <iframe
          width="640"
          height="360"
          :src="videoLink"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <button class="close-btn" @click="closeLightbox"><i class="feather-x"></i></button>
      </div>
    </div>

    <relatedservice />
  </div>
</template>
<script>
import ServiceContentGallery from "@/assets/json/servicecontentgallery.json";
import Servicedetails from "@/assets/json/servicedetails.json";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  data() {
    return {
      lightboxVisible: false,
      videoLink: 'https://www.youtube.com/embed/Vdp6x7Bibtk',
      Servicedetails: Servicedetails,
      ServiceContentGallery: ServiceContentGallery,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    openLightbox() {
      this.lightboxVisible = true;
    },
    closeLightbox() {
      this.lightboxVisible = false;
    },
  },
};
</script>
<style>
.gallery-widget {
  margin-right: 24px;
}
/* Add your CSS styling here */
.video-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.video-container {
  position: relative;
}
.close-btn {
  position: absolute;
  background: #9c9c9c00;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
</style>
