<template>
  <div class="main-wrapper">
    <custombookcalendarnav />
    <div class="content">
      <div class="container">
        <div class="row">
          <customer-sidebar></customer-sidebar>
          <div class="col-lg-9">
            <div class="widget-title">
              <h4>Login Activity</h4>
            </div>
            <div class="provide-table manage-table">
              <div class="table-responsive">
                <table class="table custom-table datatable mb-0" id="data-table">
                  <a-table :columns="columns" :data-source="data">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'Status'">
                        <td>
                          <span class="badge-success">{{ record.Status }}</span>
                        </td>
                      </template>
                    </template>
                  </a-table>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <div id="tablelength"></div>
              </div>
              <div class="col-md-7">
                <div class="table-ingopage">
                  <div id="tableinfo"></div>
                  <div id="tablepagination"></div>
                </div>
              </div>
            </div>
            <!-- /Login Activity -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Device",
    dataIndex: "Device",
    sorter: {
      compare: (a, b) => {
        a = a.Device.toLowerCase();
        b = b.Device.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "IP Address",
    dataIndex: "IPAddress",
    sorter: {
      compare: (a, b) => {
        a = a.IPAddress.toLowerCase();
        b = b.IPAddress.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Location",
    dataIndex: "Location",
    sorter: {
      compare: (a, b) => {
        a = a.Location.toLowerCase();
        b = b.Location.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    Date: "21 Sep 2022, 10:20:20",
    Device: "Safari Macos",
    IPAddress: "224.111.12.75",
    Location: "Newyork / USA",
    Status: "Compeleted",
  },
  {
    Date: "21 Sep 2022, 10:20:20",
    Device: "Safari Macos",
    IPAddress: "224.111.12.75",
    Location: "Newyork / USA",
    Status: "Compeleted",
  },
  {
    Date: "20 Sep 2022, 14:16:10",
    Device: "Firefox Windows",
    IPAddress: "232.222.12.72",
    Location: "Newyork / USA",
    Status: "Compeleted",
  },
  {
    Date: "20 Sep 2022, 14:16:10",
    Device: "Firefox Windows",
    IPAddress: "232.222.12.72",
    Location: "Newyork / USA",
    Status: "Compeleted",
  },
  {
    Date: "19 Sep 2022, 13:10:50",
    Device: "Safari Macos",
    IPAddress: "333.555.10.54",
    Location: "Newyork / USA",
    Status: "Compeleted",
  },
  {
    Date: "19 Sep 2022, 13:10:50",
    Device: "Safari Macos",
    IPAddress: "333.555.10.54",
    Location: "Newyork / USA",
    Status: "Compeleted",
  },
  {
    Date: "22 Sep 2022, 11:40:30",
    Device: "Chrome - Windows",
    IPAddress: "232.222.12.72",
    Location: "Chennai / India",
    Status: "Compeleted",
  },
  {
    Date: "22 Sep 2022, 11:40:30",
    Device: "Chrome - Windows",
    IPAddress: "232.222.12.72",
    Location: "Chennai / India",
    Status: "Compeleted",
  },
];
export default {
  data() {
    return {
      abcd: ["A -> Z", "Most helful"],
      columns,
      data,
    };
  },
};
</script>
