<template>
  <div class="main-wrapper">
    <custombookcalendarnav />

    <div class="page-wrwpper">
      <customerprofilecontent />

      <!-- <customprofiledelete /> -->

      <customnotifydeleteacc />
    </div>
  </div>
</template>
