<template>
    <!-- Footer -->
    <footer class="footer footer-six">
        <div class="footer-top-content">
            <img src="@/assets/img/bg-wave.png" alt="">
        </div>
        <!-- Footer Top -->
        <div class="footer-top aos" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-wid-six">
                            <div class="footer-logo">
                                <router-link to="/"><img src="@/assets/img/logo-02.svg" alt="logo"></router-link>
                            </div>
                            <div class="footer-content">
                                <p>We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit, Ut enim ad
                                    minim veniam,
                                    quis nostrud equip consectetur... <a href="javascript:;">Read More</a></p>
                                <div class="footer-six-main">
                                    <div class="footer-six-left">
                                        <img src="@/assets/img/icons/call-calling.svg" alt="">
                                        <div class="footer-six-ryt">
                                            <span>Phone Number</span>
                                            <h6>310-437-2766</h6>
                                        </div>
                                    </div>
                                    <div class="footer-six-left">
                                        <img src="@/assets/img/icons/sms.svg" alt="">
                                        <div class="footer-six-ryt">
                                            <span>Mail Address</span>
                                            <h6>support@truelysell.com</h6>
                                        </div>
                                    </div>
                                    <div class="footer-six-left ">
                                        <img src="@/assets/img/icons/location.svg" alt="">
                                        <div class="footer-six-ryt">
                                            <span>Address</span>
                                            <h6>706 Campfire Ave. Meriden, CT 06450</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Locations</h2>
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">Atlanta, GA</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Boston, MA</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Chapel Hill, nC</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Chicago, IL</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Dallas, TX</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Home Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Home Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" class="footer-btns">View All Locations</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Support</h2>
                            <ul>
                                <li>
                                    <router-link to="/about-us">Contact us</router-link>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Online Chat</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Whatsapp</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Telegram</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Ticketing</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Call Centre</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Video Centre</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" class="footer-btns">Book Now</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Services</h2>
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">Home Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Office Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Apartment Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Commercial Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Building Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Vehicle Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Carpet Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" class="footer-btns">Book Now</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Others</h2>
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">Home Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Office Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Apartment Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Commercial Cleaning</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Building Cleaning</a>
                                </li>

                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>

                </div>
            </div>
        </div>
        <!-- /Footer Top -->

        <!-- Footer Bottom -->
        <div class="footer-bottom footer-bottom-six">
            <div class="container">
                <!-- Copyright -->
                <div class="copyright">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="copyright-text">
                                <p class="mb-0">Copyright 2023 © Truelysell. All rights reserved.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <!-- Copyright Menu -->
                            <div class="copyright-menu">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa-brands fa-google"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa-brands fa-facebook-f"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa-brands fa-linkedin"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa-brands fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa-brands fa-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"><i class="fa-brands fa-whatsapp"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <!-- /Copyright Menu -->
                        </div>
                    </div>
                </div>
                <!-- /Copyright -->
            </div>
        </div>
        <!-- /Footer Bottom -->

    </footer>
    <!-- /Footer -->
</template>