<template>
  <section class="blog-eight-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-eight aos" data-aos="fade-up">
            <img src="@/assets/img/icons/dog.svg" alt="" />
            <h2>Our Blog</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel blog-eight-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexeightblog" :key="item.id">
                <div class="service-widget service-widget-eight aos" data-aos="fade-up">
                  <div class="service-img">
                    <router-link to="/blog-details">
                      <img
                        class="img-fluid"
                        alt="Service Image"
                        :src="require(`@/assets/img/blog/${item.image}`)"
                      />
                    </router-link>
                  </div>
                  <div class="service-content service-eight-blogs">
                    <h3 class="title">
                      <router-link to="/blog-details"
                        >Top Grooming tips for your dog for this summer</router-link
                      >
                    </h3>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard.
                    </p>
                    <div class="usefull-bottom useful-bottom-eight">
                      <div class="useful-img">
                        <img
                          :src="require(`@/assets/img/profiles/${item.avatar}`)"
                          alt=""
                        />
                        <span>Admin</span>
                      </div>
                      <h6><i class="feather-tag"></i>Grooming</h6>
                    </div>
                  </div>
                  <div class="service-eight-img-inside">
                    <router-link to="/blog-details">
                      <img src="@/assets/img/icons/dog-feet.svg" alt="" />
                      Read more
                    </router-link>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
      <div class="btn-sec btn-saloons btn-pets aos" data-aos="fade-up">
        <router-link to="/search" class="btn btn-primary btn-view"
          >VIEW ALL BLOGS</router-link
        >
      </div>
    </div>
  </section>

  <section class="healthy-eight-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-12">
          <div class="pets-content-all">
            <h2>Keeping your pets</h2>
            <h1>Happy, Healthy And Safe!</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry. Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the industry.
            </p>
            <a href="javascript:;" class="btn btn-primary">Book Now</a>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="healthy-pets-img">
            <img src="@/assets/img/healthy-pets.png" alt="" class="img-fluid" />
            <div class="healthy-eight-bg">
              <img src="@/assets/img/her-section-eight-bg.png" alt="" />
            </div>
            <div class="healthy-eight-bg-two">
              <img src="@/assets/img/her-section-eight-bg.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexeightblog from "@/assets/json/indexeightblog.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexeightblog: indexeightblog,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
