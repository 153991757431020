<template>
    <!-- pricing Section -->
    <section class="pricing-service-five">
        <div class="pricing-service-topimg">
            <img src="@/assets/img/bubbles-bottom-section-pricing-1.png" alt="" class="pricing-five-img-one">
            <img src="@/assets/img/bubbles-bottom-section-pricing-2.png" alt="" class="pricing-five-img-two">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="section-heading car-wash-heading aos" data-aos="fade-up">
                        <div class="car-wash-img-five">
                            <img src="@/assets/img/icons/car-wash-header-1.svg" alt="" class="car-wash-header-one">
                            <h2>Pricing Plans</h2>
                            <img src="@/assets/img/icons/car-wash-header-2.svg" alt="" class="car-wash-header-two">
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                </div>
            </div>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                        type="button" role="tab" aria-controls="pills-home" aria-selected="true">MONTHLY</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                        type="button" role="tab" aria-controls="pills-profile" aria-selected="false">YEARLY</button>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="row aos" data-aos="fade-up">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="pricing-plans pricing-plans-five">
                                <div class="pricing-planshead-five">
                                    <div class="providerset-prices-five">
                                        <h6>$20</h6><span>/month</span>
                                    </div>
                                    <h4>Standard Pack</h4>
                                </div>
                                <div class="pricing-planscontent pricing-planscontent-five">
                                    <ul>
                                        <li>
                                            <span>Unleash the power of automation</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Exterior Washing</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Vacuum Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Interior Wet Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Window Wiping</span>
                                        </li>
                                    </ul>
                                    <div class="pricing-btn-five">
                                        <router-link to="/pricing" class="btn btn-primary btn-view">Choose plan</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="pricing-plans pricing-plans-five">
                                <div class="pricing-planshead-five">
                                    <div class="providerset-prices-five">
                                        <h6>$54</h6><span>/month</span>
                                    </div>
                                    <h4>Premium Pack</h4>
                                </div>
                                <div class="pricing-planscontent pricing-planscontent-five">
                                    <ul>
                                        <li>
                                            <span>Unleash the power of automation</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Exterior Washing</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Vacuum Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Interior Wet Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Window Wiping</span>
                                        </li>
                                    </ul>
                                    <div class="pricing-btn-five">
                                        <router-link to="/pricing" class="btn btn-primary btn-view">Choose plan</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="pricing-plans pricing-plans-five">
                                <div class="pricing-planshead-five">
                                    <div class="providerset-prices-five">
                                        <h6>$89</h6><span>/month</span>
                                    </div>
                                    <h4>Ultimate Pack</h4>
                                </div>
                                <div class="pricing-planscontent pricing-planscontent-five">
                                    <ul>
                                        <li>
                                            <span>Unleash the power of automation</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Exterior Washing</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Vacuum Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Interior Wet Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Window Wiping</span>
                                        </li>
                                    </ul>
                                    <div class="pricing-btn-five">
                                        <router-link to="/pricing" class="btn btn-primary btn-view">Choose plan</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="row aos" data-aos="fade-up">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="pricing-plans pricing-plans-five">
                                <div class="pricing-planshead-five">
                                    <div class="providerset-prices-five">
                                        <h6>$20</h6><span>/month</span>
                                    </div>
                                    <h4>Standard Pack</h4>
                                </div>
                                <div class="pricing-planscontent pricing-planscontent-five">
                                    <ul>
                                        <li>
                                            <span>Unleash the power of automation</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Exterior Washing</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Vacuum Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Interior Wet Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Window Wiping</span>
                                        </li>
                                    </ul>
                                    <div class="pricing-btn-five">
                                        <router-link to="/pricing" class="btn btn-primary btn-view">Choose plan</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="pricing-plans pricing-plans-five">
                                <div class="pricing-planshead-five">
                                    <div class="providerset-prices-five">
                                        <h6>$54</h6><span>/month</span>
                                    </div>
                                    <h4>Premium Pack</h4>
                                </div>
                                <div class="pricing-planscontent pricing-planscontent-five">
                                    <ul>
                                        <li>
                                            <span>Unleash the power of automation</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Exterior Washing</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Vacuum Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Interior Wet Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Window Wiping</span>
                                        </li>
                                    </ul>
                                    <div class="pricing-btn-five">
                                        <router-link to="/search" class="btn btn-primary btn-view">Choose plan</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="pricing-plans pricing-plans-five">
                                <div class="pricing-planshead-five">
                                    <div class="providerset-prices-five">
                                        <h6>$89</h6><span>/month</span>
                                    </div>
                                    <h4>Ultimate Pack</h4>
                                </div>
                                <div class="pricing-planscontent pricing-planscontent-five">
                                    <ul>
                                        <li>
                                            <span>Unleash the power of automation</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Exterior Washing</span>
                                        </li>
                                        <li>
                                            <i class="feather-check me-2 text-success"></i>
                                            <span>Vacuum Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Interior Wet Cleaning</span>
                                        </li>
                                        <li>
                                            <i class="feather-x me-2 text-danger"></i>
                                            <span>Window Wiping</span>
                                        </li>
                                    </ul>
                                    <div class="pricing-btn-five">
                                        <router-link to="/search" class="btn btn-primary btn-view">Choose plan</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /pricing Section -->

    <!-- testimonals section -->
    <section class="testimonals-five-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="section-heading car-wash-heading aos" data-aos="fade-up">
                        <div class="car-wash-img-five">
                            <img src="@/assets/img/icons/car-wash-header-1.svg" alt="" class="car-wash-header-one">
                            <h2>Our testimonials</h2>
                            <img src="@/assets/img/icons/car-wash-header-2.svg" alt="" class="car-wash-header-two">
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="car-wash-bg-five-main">
                        <div class="car-wash-bg-five">
                            <div class="row justify-content-center">
                                <div class="col-md-8">
                                    <div class="owl-carousel car-testimonials-five-slider">
                                        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                                            <Slide v-for="item in indexfivetestimonial" :key="item.id">
                                                <div class="testimonials-five-top">
                                                    <div class="test-five-img">
                                                        <img :src="require(`@/assets/img/profiles/${item.image}`)" alt="">
                                                    </div>
                                                    <h2>Best Design and delivered in time</h2>
                                                    <p>Nay likely her length sooner thrown sex lively income. The expense windows
                                                        adapted
                                                        sir. Wrong widen drawn ample eat off doors money. Offending belonging
                                                        promotion
                                                        provision an be oh consulted ourselves it. Blessing welcomed ladyship she
                                                        met
                                                        humoured sir breeding her. Six curiosity day assurance bed necessary.</p>
                                                    <div class="rating">
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                    </div>
                                                    <h5>Mansur</h5>
                                                    <h6>CEO @ airbnb</h6>
                                                </div>
                                            </Slide>
                                                <template #addons>
                                                    <Navigation />
                                                </template>
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /testimonals section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfivetestimonial from '@/assets/json/indexfivetestimonial.json'
import "vue3-carousel/dist/carousel.css";
export default {
    data() {
        return {
            indexfivetestimonial: indexfivetestimonial,
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
				loop: true,
				margin: 24,
			},

			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 700px and up
				767: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 991px and up
				991: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 1024 and up
				1024: {
					itemsToShow: 1,
					snapAlign: "start"

				}
			}
        }
    },
    components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
}
</script>

<style>
.testimonials-five-top{
    margin-right: 24px;
}
</style>