<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <!-- Customer Menu -->
        <customer-sidebar></customer-sidebar>
        <!-- /Customer Menu -->

        <div class="col-lg-9">
          <!-- Reviews Sort -->
          <div class="row">
            <div class="col-sm-6">
              <div class="widget-title">
                <h4>Reviews</h4>
              </div>
            </div>

            <div class="col-sm-6 text-sm-end">
              <div class="review-sort d-flex align-items-center justify-content-end">
                <p>Sort</p>
                <vue-select :options="Select" placeholder="Newest" />
              </div>
            </div>
          </div>
          <!-- /Reviews Sort -->

          <!-- Review List -->
          <div class="review-list" v-for="record in CustomerReview" :key="record.id">
            <div class="review-imgs">
              <router-link to="/service-details"
                ><img
                  class="rounded img-fluid"
                  :src="require(`@/assets/img/services/${record.serviceImg}`)"
                  alt=""
              /></router-link>
            </div>
            <div class="review-info">
              <h5>
                <router-link to="/service-details">{{ record.service }}</router-link>
                <span>{{ record.service1 }}</span>
              </h5>
              <div class="review-user">
                <img
                  class="avatar rounded-circle"
                  :src="require(`@/assets/img/profiles/${record.avatar}`)"
                  alt=""
                />{{ record.name }}
                <span class="review-date">{{ record.date }}</span>
              </div>
            </div>
            <div class="review-count">
              <div class="rating">
                <i :class="record.class1"></i>
                <i :class="record.class2"></i>
                <i :class="record.class3"></i>
                <i :class="record.class4"></i>
                <i :class="record.class5"></i>
              </div>
            </div>
            <p>
              {{ record.content }}
            </p>
          </div>
          <!-- /Review List -->

          <pagination></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerReview from "@/assets/json/customerreview.json";
export default {
  data() {
    return {
      Select: ["Newest", "Highest", "Lowest"],
      CustomerReview: CustomerReview,
    };
  },
};
</script>
