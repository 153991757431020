<template>
  <div class="card add-service">
    <div class="row">
      <div class="col-md-12">
        <div class="sub-title">
          <h6>Service Information</h6>
        </div>
        <div class="form-group">
          <label class="col-form-label">Service Title</label>
          <input type="text" class="form-control" placeholder="Enter Services Name" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-form-label">Category</label>
          <vue-select
            :options="Category"
            id="select-category"
            placeholder="Select Category"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-form-label">Sub Category</label>
          <vue-select
            :options="SubCategory"
            id="sub-category"
            placeholder="Select Sub Category"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-form-label d-block"
            >Price<span class="brief-bio float-end">Set 0 for free</span></label
          >
          <input type="text" class="form-control" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="col-form-label d-block">Duration</label>
          <div class="form-duration">
            <input type="text" class="form-control" />
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-0">
          <label class="col-form-label">Description</label>
          <!-- <textarea class="form-control ck-editor"></textarea> -->
          <summernoteEditor
            v-model="myValue"
            @update:modelValue="summernoteChange($event)"
            @summernoteImageLinkInsert="summernoteImageLinkInsert"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="card add-service">
    <div class="add-service-toggle">
      <div class="row">
        <div class="col-sm-6">
          <div class="sub-title">
            <h6>Additional Service</h6>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="status-toggle sml-status float-sm-end">
            <input type="checkbox" id="status_1" class="check" checked="" />
            <label for="status_1" class="checktoggle">checkbox</label>
          </div>
        </div>
      </div>
    </div>

    <div class="addservice-info">
      <div class="row service-cont">
        <div class="col-md-4">
          <div class="form-group">
            <label class="col-form-label">Additional Service</label>
            <input type="text" class="form-control" placeholder="Enter Service Item" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="col-form-label">Price</label>
            <input type="text" class="form-control" placeholder="Enter Price" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="col-form-label">Duration</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Service Duration"
            />
          </div>
        </div>
      </div>

      <div class="row service-cont" v-for="(certify, index) in certify" :key="index">
        <div class="col-md-4">
          <div class="form-group">
            <label class="col-form-label">Additional Service</label
            ><input type="text" class="form-control" placeholder="Enter Service Item" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="col-form-label">Price</label
            ><input type="text" class="form-control" placeholder="Enter Price" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex align-items-center">
            <div class="form-group w-100">
              <label class="col-form-label">Duration</label
              ><input
                type="text"
                class="form-control"
                placeholder="Enter Service Duration"
              />
            </div>
            <div class="form-group">
              <label class="col-form-label">&nbsp;</label
              ><a
                href="javascript:;"
                @click="deletecertify(index)"
                class="btn btn-danger-outline trash"
                ><i class="far fa-trash-alt"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:;" class="add-text add-extra" @click="addcertify"
      ><i class="feather-plus-circle"></i> Add Additional Service</a
    >
  </div>
  <div class="card add-service">
    <div class="row">
      <div class="col-md-12">
        <div class="sub-title">
          <h6>Video</h6>
        </div>
        <div class="form-group mb-0">
          <label class="col-form-label">Video Link</label>
          <input
            type="text"
            class="form-control"
            placeholder="https://www.youtube.com/shorts/Lf-Z7H8bZ8o"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SummernoteEditor from "vue3-summernote-editor";
export default {
  components: {
    SummernoteEditor,
  },
  data() {
    return {
      Category: ["Select Category", "Car wash", "House Cleaning"],
      SubCategory: ["Select Sub Category", "House Cleaning", "Car wash"],
      myValue: "Description",
      certify: [],
    };
  },
  methods: {
    summernoteChange(newValue) {
      // console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      // console.log("summernoteImageLinkInsert()", args);
    },
    addcertify() {
      this.certify.push({});
    },
    deletecertify(index) {
      this.certify.splice(index, 1);
    },
  },
};
</script>
