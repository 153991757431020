import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getCompany(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/companies', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        showCompany(_, {id}){
            return new Promise((resolve,reject) => {
                axios.get('companies/show/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        addCompany(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/companies/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateCompany(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('companies/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteCompany(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('companies/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getCompanyInfo(_,){
            return new Promise((resolve,reject) => {
                axios.get('companies/info').then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },



    },
    getters: {

    }

}