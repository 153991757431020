<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <!-- Choose Signup -->
        <div class="col-md-6 col-lg-6 mx-auto">
          <div class="login-wrap">
            <div class="login-header">
              <h3>Sign Up</h3>
            </div>

            <div class="row">
              <!-- Provider Signup -->
              <div class="col-md-6 d-flex">
                <div class="choose-signup flex-fill">
                  <h6>Providers</h6>
                  <div class="choose-img">
                    <img src="@/assets/img/provider.png" alt="" />
                  </div>
                  <router-link to="/provider-signup" class="btn btn-secondary w-100"
                    >Sign Up<i class="feather-arrow-right-circle ms-1"></i
                  ></router-link>
                </div>
              </div>
              <!-- /Provider Signup -->

              <!-- User Signup -->
              <div class="col-md-6 d-flex">
                <div class="choose-signup flex-fill mb-0">
                  <h6>Users</h6>
                  <div class="choose-img">
                    <img src="@/assets/img/user.png" alt="" />
                  </div>
                  <router-link to="/user-signup" class="btn btn-secondary w-100"
                    >Sign Up<i class="feather-arrow-right-circle ms-1"></i
                  ></router-link>
                </div>
              </div>
              <!-- /User Signup -->
            </div>
          </div>
        </div>
        <!-- /Choose Signup -->
      </div>
    </div>
  </div>
</template>
