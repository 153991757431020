<template>
  <!-- Add Payout -->
  <div class="modal fade" id="add-payout">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Payout</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Provider Name</label>

              <vue-select
                :options="Provider"
                id="providerrequest"
                placeholder="Select any Provider"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Withdrawal Method</label>
              <vue-select
                :options="Payment"
                id="providerrequestone"
                placeholder="Select any Payment"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Withdrawal Amount</label>
              <input type="text" placeholder="0.00" class="form-control" />
            </div>
            <div class="mb-4">
              <label class="form-label">Status</label>
              <vue-select
                :options="Status"
                id="statusrequest"
                placeholder="Select Status"
              />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Payout -->
</template>
<script>
export default {
  data() {
    return {
      Provider: ["Select any Provider", "Provider 1", "Provider 2", "Provider 3"],
      Payment: ["Select any Payment", "Payment 1", "Payment 2", "Payment 3"],
      Status: ["Select Status", "Completed", "Inprogress"],
    };
  },
};
</script>
