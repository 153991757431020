<template>
  <!-- Service Section -->
  <section class="service-section-two">
    <div class="container">
      <div class="row">
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="section-heading-two">
            <h2>Featured Services</h2>
            <p>Explore the greates our services. You won’t be disappointed</p>
          </div>
        </div>
        <div class="col-md-6 text-md-end aos" data-aos="fade-up">
          <div class="owl-nav mynav"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel service-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indextwofeatured" :key="item.id">
                <div
                  class="service-widget service-widget-space service-two aos"
                  data-aos="fade-up"
                >
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="fav-item">
                      <router-link to="/categories"
                        ><span class="item-cat">{{ item.badge }}</span></router-link
                      >
                      <a href="javascript:void(0)" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                    <div class="item-info">
                      <router-link to="/providers"
                        ><span class="item-img"
                          ><img
                            :src="require(`@/assets/img/profiles/${item.avatar}`)"
                            class="avatar"
                            alt=""
                          />
                          {{ item.name }}</span
                        ></router-link
                      >
                    </div>
                  </div>
                  <div class="service-content">
                    <h3 class="title">
                      <router-link to="/service-details">{{ item.title }}</router-link>
                    </h3>
                    <p>
                      <i class="feather-map-pin"></i>{{ item.country
                      }}<span class="rate"
                        ><i class="feather-phone"></i>+1 123 456 7890</span
                      >
                    </p>
                    <div class="serv-info">
                      <div class="rating">
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <span>(10)</span>
                      </div>
                      <h6>
                        {{ item.offer }}<span class="old-price">{{ item.price }}</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Service Section -->

  <!-- Work Section -->
  <section class="work-section-two">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading sec-header aos" data-aos="fade-up">
            <h2>How It Works</h2>
            <p>Aliquam lorem ante, dapibus in, viverra quis</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-4 col-sm-6">
          <div class="work-wrap-box work-first aos" data-aos="fade-up">
            <div class="work-icon">
              <span>
                <img src="@/assets/img/icons/work-icon-01.svg" alt="img" />
              </span>
            </div>
            <h5>Choose What To Do</h5>
            <p>
              Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore magna
              aliqua.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="work-wrap-box work-last aos" data-aos="fade-up">
            <div class="work-icon">
              <span>
                <img src="@/assets/img/icons/work-icon-02.svg" alt="img" />
              </span>
            </div>
            <h5>Find What You Want</h5>
            <p>
              Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore magna
              aliqua.
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="work-wrap-box aos" data-aos="fade-up">
            <div class="work-flex">
              <div class="work-icon">
                <span>
                  <img src="@/assets/img/icons/work-icon-03.svg" alt="img" />
                </span>
              </div>
              <h5>Amazing Places</h5>
              <p>
                Lorem ipsum dolor amet, consectetur adipiscing tempor labore et dolore
                magna aliqua.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Work Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indextwofeatured from "@/assets/json/indextwofeatured.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indextwofeatured: indextwofeatured,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        320: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  mounted() {
    var favIcons = document.querySelectorAll(".fav-icon");
    favIcons.forEach(function (icon) {
      icon.addEventListener("click", function () {
        if (this.classList.contains("selected")) {
          this.classList.remove("selected");
        } else {
          this.classList.add("selected");
        }
      });
    });
  },
};
</script>
