<template>
    <div class="main-wrapper">
        <layout></layout>
        <adminsidebar></adminsidebar>

        <div class="page-wrapper page-settings">

           <sidelinkheading />
           
            <div class="content w-100">
                <div class="content-page-header">
                    <h5>Header Setting</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-groupheads">
                                    <h2>Nav Menus</h2>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="nav-menus">
                                            <h4>Language</h4>
                                            <div class="active-switch">
                                                <label class="switch">
                                                    <input type="checkbox" checked>
                                                    <span class="sliders round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="nav-menus">
                                            <h4>Currency</h4>
                                            <div class="active-switch">
                                                <label class="switch">
                                                    <input type="checkbox" checked>
                                                    <span class="sliders round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="nav-menus">
                                            <h4>Stikcy header</h4>
                                            <div class="active-switch">
                                                <label class="switch">
                                                    <input type="checkbox" checked>
                                                    <span class="sliders round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="form-groupheads d-flex d-flex justify-content-between">
                                    <h2>Header Menu</h2>
                                    <div class="active-switch">
                                        <label class="switch">
                                            <input type="checkbox" checked>
                                            <span class="sliders round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="row add-headers">
                                    <div class="col-12" v-for="item in event" :key="item.id">
                                        <div class="form-group-set">
                                            <div class="row align-items-center">
                                                <div class="col-lg-3 col-sm-6">
                                                    <div >
                                                        <input type="text" class="form-control" :value="item.Name">
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-sm-12">
                                                    <input type="text" class="form-control" 
                                                    :value="item.placeholder">
                                                </div>
                                                <div class="col-lg-1 col-sm-12">
                                                    <a href="javascript:void(0);"
                                                        @click="deleteEvent(event)"
                                                        class="delete-links">
                                                        <i class="far fa-trash-alt "></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <a href="javascript:void(0);" @click="add()" class="addnewheader link-sets"><i class="fa fa-plus-circle me-2" aria-hidden="true"></i>Add New</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="btn-path">
                                <a href="javascript:void(0);" class="btn btn-cancel me-3">Reset</a>
                                <a href="javascript:void(0);" class="btn btn-primary">Save Changes</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>


<script>
const event = [
    {
        id: "1",
        Name: "Home",
        placeholder: "https://truelysell-testing.dreamguystech.com/home",
    },
    {
        id: "2",
        Name: "About",
        placeholder: "https://truelysell-testing.dreamguystech.com/about",
    },
    {
        id: "3",
        Name: "Categories",
        placeholder: "https://truelysell-testing.dreamguystech.com/categories",
    },
    {
        id: "4",
        Name: "Blog",
        placeholder: "https://truelysell-testing.dreamguystech.com/blog",
    },
    {
        id: "5",
        Name: "Contact Us",
        placeholder: "https://truelysell-testing.dreamguystech.com/contactus",
    },
    {
        id: "6",
        Name: "Pages",
        placeholder: "https://truelysell-testing.dreamguystech.com/pages",
    },
]
export default {
    data() {
        return {
            event
        }
    },
    methods: {
        deleteEvent: function (event) {
        this.event.splice(this.event.indexOf(event), 1);
        },
        add() {
        this.event.push({
            id: "7",
            Name: '',
            placeholder: "",
        });
        },
    },
}
</script>