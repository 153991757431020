<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>All Blog</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select
                          :options="SelectBlog"
                          id="selectblog"
                          placeholder="A -> Z"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <router-link class="btn btn-primary" to="/admin/add-blog"
                  ><i class="fa fa-plus me-2"></i>Add Blog
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="tab-sets mb-4">
              <div class="tab-contents-sets">
                <ul>
                  <li>
                    <router-link to="/admin/all-blog" class="active"
                      >Active Blog</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/admin/pending-blog">Pending Blog</router-link>
                  </li>
                  <li>
                    <router-link to="/admin/inactive-blog">Inactive Blog</router-link>
                  </li>
                </ul>
              </div>
              <div class="tab-contents-count">
                <h6>Showing 8-10 of 84 results</h6>
              </div>
            </div>
          </div>
        </div>
        <active-blog></active-blog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SelectBlog: ["A -> Z", "Z -> A"],
    };
  },
};
</script>
