<template>
  <div class="login-body">
    <div class="main-wrapper">
      <otpheader />

      <div class="page-wrapper">
        <phoneotpcontent />
      </div>
    </div>
  </div>
</template>
