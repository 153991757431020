<template>
    <!-- See How It Works -->
    <section class="see-works-six-section">
        <div class="container">
            <div class="section-heading section-heading-six">
                <div class="row">
                    <div class="col-md-6 aos" data-aos="fade-up">
                        <div class="reason-six">
                            <img src="@/assets/img/icons/window-cleaner-detergent-1.svg" alt="">
                            <p>HOW WE ARE WORKING?</p>
                        </div>
                        <h2>See How It <span>Works</span></h2>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="how-it-works-six">
                        <div class="works-six-num">
                            <h2>01</h2>
                        </div>
                        <div class="work-online-schedule">
                            <div class="work-online-img">
                                <img src="@/assets/img/works-six-1.png" alt="" class="img-fluid">
                            </div>
                            <div class="work-online-bottom">
                                <img src="@/assets/img/icons/gui-calendar-planner.svg" alt="">
                                <h4>Easy Online Scheduling</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="how-it-works-six">
                        <div class="works-six-num works-six-num-two">
                            <h2>02</h2>
                        </div>
                        <div class="work-online-schedule">
                            <div class="work-online-img">
                                <img src="@/assets/img/works-six-2.png" alt="" class="img-fluid">
                            </div>
                            <div class="work-online-bottom work-online-bottom-two">
                                <h4>Get A Certified Cleaner</h4>
                                <img src="@/assets/img/icons/carpet.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="how-it-works-six">
                        <div class="works-six-num works-six-num-three">
                            <h2>03</h2>
                        </div>
                        <div class="work-online-schedule">
                            <div class="work-online-img">
                                <img src="@/assets/img/works-six-3.png" alt="" class="img-fluid">
                            </div>
                            <div class="work-online-bottom">
                                <img src="@/assets/img/icons/like-svg.svg" alt="">
                                <h4>Enjoy Cleanliness!</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /See How It Works -->

    <!-- Latest Blog -->
    <section class="blogs-service-section">
        <div class="container">
            <div class="section-heading section-heading-six">
                <div class="row align-items-end">
                    <div class="col-md-6 aos" data-aos="fade-up">
                        <div class="reason-six">
                            <img src="@/assets/img/icons/cleaning-mop.svg" alt="">
                            <p>AFFORDABLE & RELIABLE</p>
                        </div>
                        <h2>Latest <span>Blog</span></h2>
                    </div>
                    <div class="col-md-6 aos" data-aos="fade-up">
                        <router-link to="/categories" class="afford-btn">Explore All</router-link>
                    </div>
                </div>
            </div>
            <div class="row">
        <div class="col-lg-4 col-md-6 col-12 d-flex">
          <div class="service-widget service-six w-100 aos" data-aos="fade-up">
            <div class="service-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  src="@/assets/img/services/service-60.png"
                />
              </router-link>
            </div>
            <div class="service-content service-content-six">
              <div class="latest-blog-six">
                <div class="latest-blog-content">
                  <h5>15<span>Dec</span></h5>
                </div>
                <div class="latest-profile-name">
                  <img src="@/assets/img/profiles/avatar-10.jpg" alt="" />
                  <h6>Annie Oakley</h6>
                </div>
              </div>
              <h5 class="blog-import-service">
                Importance Of Professional Cleaning Services
              </h5>
              <p>
                Importance Of Professional Cleaning ServicesImportance Of Professional
                Cleaning Services
              </p>
              <a href="javascript:;">Read More</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12 d-flex">
          <div class="service-widget service-six w-100 aos" data-aos="fade-up">
            <div class="service-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  src="@/assets/img/services/service-61.png"
                />
              </router-link>
            </div>
            <div class="service-content service-content-six">
              <div class="latest-blog-six">
                <div class="latest-blog-content">
                  <h5>07<span>Apr</span></h5>
                </div>
                <div class="latest-profile-name">
                  <img src="@/assets/img/profiles/avatar-12.jpg" alt="" />
                  <h6>Annie Oakley</h6>
                </div>
              </div>
              <h5 class="blog-import-service">
                Importance Of Professional Cleaning Services
              </h5>
              <p>
                Importance Of Professional Cleaning ServicesImportance Of Professional
                Cleaning Services
              </p>
              <a href="javascript:;">Read More</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12 d-flex">
          <div class="service-widget service-six w-100 aos" data-aos="fade-up">
            <div class="service-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  src="@/assets/img/services/service-62.png"
                />
              </router-link>
            </div>
            <div class="service-content service-content-six">
              <div class="latest-blog-six">
                <div class="latest-blog-content">
                  <h5>02<span>Dec</span></h5>
                </div>
                <div class="latest-profile-name">
                  <img src="@/assets/img/profiles/avatar-19.jpg" alt="" />
                  <h6>Annie Oakley</h6>
                </div>
              </div>
              <h5 class="blog-import-service">
                Importance Of Professional Cleaning Services
              </h5>
              <p>
                Importance Of Professional Cleaning ServicesImportance Of Professional
                Cleaning Services
              </p>
              <a href="javascript:;">Read More</a>
            </div>
          </div>
        </div>
      </div>
        </div>
    </section>
    <!-- /Latest Blog -->
</template>

<script>
export default {
    
}
</script>

<style>
.blogs-service-section .service-six{
    margin-right: 0px;
}
</style>