<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <!-- Customer Menu -->
        <customer-sidebar></customer-sidebar>
        <!-- /Customer Menu -->

        <div class="col-lg-9">
          <div class="widget-title">
            <h4>Dashboard</h4>
          </div>

          <!-- Dashboard Widget -->
          <dashboardwidget></dashboardwidget>
          <!-- /Dashboard Widget -->

          <div class="row">
            <!-- Recent Booking -->
            <recentbooking></recentbooking>
            <!-- /Recent Booking -->

            <!-- Recent Transaction -->
            <recenttransaction></recenttransaction>
            <!-- /Recent Transaction -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
