<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Edit Customer</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="profile-upload">
                    <div class="profile-upload-img">
                      <img
                        src="@/assets/admin_img/customer/user-01.jpg"
                        alt="img"
                        id="blah"
                      />
                    </div>
                    <div class="profile-upload-content">
                      <div class="profile-upload-btn">
                        <div class="profile-upload-file">
                          <input type="file" id="imgInp" />
                          <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                        </div>
                        <a href="javascript:void(0);" class="btn btn-remove">Remove</a>
                      </div>
                      <div class="profile-upload-para">
                        <p>
                          *image size should be at least 320px big,and less then 500kb.
                          Allowed files .png and .jpg.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Name "
                      value="John Doe"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>User Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter User Name"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Email"
                      value="johndoe@gmail.com"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Phone Number"
                      value="302-372-7812"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-groupheads d-flex d-flex justify-content-between">
                    <h2>Status</h2>
                    <div class="active-switch">
                      <label class="switch">
                        <input type="checkbox" checked="" />
                        <span class="sliders round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" variant="primary"> Save Changes</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/customers");
    },
  },
};
</script>
