<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <customer-sidebar></customer-sidebar>

        <div class="col-lg-9">
          <bookingsort />
          <bookinglist />
          <pagination />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
