<template>
  <!-- Client Section -->
  <section class="client-section-two">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading sec-header aos" data-aos="fade-up">
            <h2>Testimonials</h2>
            <p>Lorem ipsum dolor sit amet, consectetur elit</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel client-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indextwoclient" :key="item.id">
                <div class="client-box">
                  <div class="client-content">
                    <div class="rating">
                      <i class="fa-solid fa-star filled me-1"></i>
                      <i class="fa-solid fa-star filled me-1"></i>
                      <i class="fa-solid fa-star filled me-1"></i>
                      <i class="fa-solid fa-star filled me-1"></i>
                      <i class="fa-solid fa-star filled me-1"></i>
                    </div>
                    <h6>{{ item.title }}</h6>
                    <p>{{ item.content }}</p>
                  </div>
                  <div class="client-img">
                    <a href="javascript:;">
                      <img
                        class="img-fluid"
                        alt="Image"
                        :src="require(`@/assets/img/profiles/${item.image}`)"
                      />
                    </a>
                    <div class="client-name">
                      <h5>{{ item.name }}</h5>
                      <h6>{{ item.position }}</h6>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Client Section -->

  <!-- Blog Section -->
  <section class="blog-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center aos" data-aos="fade-up">
          <div class="section-heading sec-header">
            <h2>Our Recent News & Blog</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 d-flex">
          <div class="blog blog-new flex-fill aos" data-aos="fade-up">
            <div class="blog-image">
              <router-link to="/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-01.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="date">25<span>Dec</span></div>
            </div>
            <div class="blog-content">
              <ul class="blog-item">
                <li>
                  <div class="post-author">
                    <a href="javascript:;"
                      ><i class="feather-user"></i><span>Hal Lewis</span></a
                    >
                  </div>
                </li>
                <li><i class="feather-message-square"></i>Comments (5)</li>
              </ul>
              <h3 class="blog-title">
                <router-link to="/blog-details"
                  >Lorem ipsum dolor sit amet, adipiscing elit, sed do
                  eiusmod</router-link
                >
              </h3>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium,eaque ipsa
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex">
          <div class="blog blog-new flex-fill aos" data-aos="fade-up">
            <div class="blog-image">
              <router-link to="blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-02.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="date">25<span>Dec</span></div>
            </div>
            <div class="blog-content">
              <ul class="blog-item">
                <li>
                  <div class="post-author">
                    <a href="javascript:;"
                      ><i class="feather-user"></i><span>JohnDoe</span></a
                    >
                  </div>
                </li>
                <li><i class="feather-message-square"></i>Comments (5)</li>
              </ul>
              <h3 class="blog-title">
                <router-link to="/blog-details"
                  >Lorem ipsum dolor sit amet, adipiscing elit, sed do
                  eiusmod</router-link
                >
              </h3>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium,eaque ipsa
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex">
          <div class="blog blog-new flex-fill aos" data-aos="fade-up">
            <div class="blog-image">
              <router-link to="/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-03.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="date">25<span>Dec</span></div>
            </div>
            <div class="blog-content">
              <ul class="blog-item">
                <li>
                  <div class="post-author">
                    <a href="javascript:;"
                      ><i class="feather-user"></i><span>Greg Avery</span></a
                    >
                  </div>
                </li>
                <li><i class="feather-message-square"></i>Comments (5)</li>
              </ul>
              <h3 class="blog-title">
                <router-link to="/blog-details"
                  >Lorem ipsum dolor sit amet, adipiscing elit, sed do
                  eiusmod</router-link
                >
              </h3>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium,eaque ipsa
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Blog Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indextwoclient from "@/assets/json/indextwoclient.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indextwoclient: indextwoclient,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style>
.client-box {
  margin-right: 24px;
}
</style>
