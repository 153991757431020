<template>
    <div class="provider-body">
        <div class="main-wrapper">
            <dashboardnavbar />

            <div class="page-wrapper">
                <sidebar /> 

                <providerearningcontent />

                <providerearningdeleteacc />
            </div>
        </div>
    </div>
</template>