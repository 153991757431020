<template>
  <div class="main-wrapper">
    <layout></layout>
    <!-- <adminsidebar></adminsidebar> -->

    <div class="page-wrapper">
      <div class="content">
        <section class="feature-section">			
          <div class="container">			
            <div class="section-heading">
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-3" v-for="(item, index) in menuItems" :key="index">
                <router-link :to="item.route" class="feature-box aos" data-aos="fade-up">
                  <div class="feature-icon">
                    <span>
                      <img src="@/assets/img/icons/feature-icon-01.svg" alt="img">
                    </span>
                  </div>
                  <h5>{{ item.title }}</h5>
                  <div class="feature-overlay">
                    <img src="@/assets/img/services/service-02.jpg" alt="img">
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </section>
          <!-- <div class="col-md-6 col-lg-4 d-flex" v-for="(item, index) in menuItems" :key="index" >
              <div class="category-card flex-fill">
                  <div class="category-img">
                      <router-link :to="item.route">
                        <i :class="['fas', item.icon]"></i>
                          <img src="@/assets/img/services/service-06.jpg" class="img-fluid" alt="">
                      </router-link>
                  </div>
                  <div class="category-info">
                      <div class="category-name">
                          <h6><router-link :to="item.route">{{ item.title }}</router-link></h6>
                      </div>
                  </div>
              </div>
          </div> -->
        <!-- <div class="row">
          <div class="col-lg-3 col-sm-6 col-12 d-flex widget-path widget-service">
            <div class="card">
              <div class="card-body">
                <div class="home-user">
                  <div class="home-userhead">
                    <div class="home-usercount">
                      <span
                        ><img src="@/assets/admin_img/icons/user.svg" alt="img"
                      /></span>
                      <h6>User</h6>
                    </div>
                    <div class="home-useraction">
                      <a
                        class="delete-table bg-white"
                        href="javascript:void(0);"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu" data-popper-placement="bottom-end">
                        <li>
                          <router-link to="/admin/users" class="dropdown-item">
                            View</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/admin/edit-user" class="dropdown-item">
                            Edit</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="home-usercontent">
                    <div class="home-usercontents">
                      <div class="home-usercontentcount">
                        <img
                          src="@/assets/admin_img/icons/arrow-up.svg"
                          alt="img"
                          class="me-2"
                        />
                        <span class="counters" data-count="30">30</span>
                      </div>
                      <h5>Current Month</h5>
                    </div>
                    <div class="homegraph">
                      <img src="@/assets/admin_img/graph/graph1.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-12 d-flex widget-path widget-service">
            <div class="card">
              <div class="card-body">
                <div class="home-user home-provider">
                  <div class="home-userhead">
                    <div class="home-usercount">
                      <span
                        ><img src="@/assets/admin_img/icons/user-circle.svg" alt="img"
                      /></span>
                      <h6>Providers</h6>
                    </div>
                    <div class="home-useraction">
                      <a
                        class="delete-table bg-white"
                        href="javascript:void(0);"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu" data-popper-placement="bottom-end">
                        <li>
                          <a href="javascript:void(0);" class="dropdown-item"> View</a>
                        </li>
                        <li>
                          <router-link to="/admin/edit-provider" class="dropdown-item">
                            Edit</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="home-usercontent">
                    <div class="home-usercontents">
                      <div class="home-usercontentcount">
                        <img
                          src="@/assets/admin_img/icons/arrow-up.svg"
                          alt="img"
                          class="me-2"
                        />
                        <span class="counters" data-count="25">25</span>
                      </div>
                      <h5>Current Month</h5>
                    </div>
                    <div class="homegraph">
                      <img src="@/assets/admin_img/graph/graph2.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-12 d-flex widget-path widget-service">
            <div class="card">
              <div class="card-body">
                <div class="home-user home-service">
                  <div class="home-userhead">
                    <div class="home-usercount">
                      <span
                        ><img src="@/assets/admin_img/icons/service.svg" alt="img"
                      /></span>
                      <h6>Service</h6>
                    </div>
                    <div class="home-useraction">
                      <a
                        class="delete-table bg-white"
                        href="javascript:void(0);"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu" data-popper-placement="bottom-end">
                        <li>
                          <router-link to="/admin/services" class="dropdown-item">
                            View</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/admin/edit-service" class="dropdown-item">
                            Edit</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="home-usercontent">
                    <div class="home-usercontents">
                      <div class="home-usercontentcount">
                        <img
                          src="@/assets/admin_img/icons/arrow-up.svg"
                          alt="img"
                          class="me-2"
                        />
                        <span class="counters" data-count="18">18</span>
                      </div>
                      <h5>Current Month</h5>
                    </div>
                    <div class="homegraph">
                      <img src="@/assets/admin_img/graph/graph3.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-12 d-flex widget-path widget-service">
            <div class="card">
              <div class="card-body">
                <div class="home-user home-subscription">
                  <div class="home-userhead">
                    <div class="home-usercount">
                      <span
                        ><img src="@/assets/admin_img/icons/money.svg" alt="img"
                      /></span>
                      <h6>Subscription</h6>
                    </div>
                    <div class="home-useraction">
                      <a
                        class="delete-table bg-white"
                        href="javascript:void(0);"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu" data-popper-placement="bottom-end">
                        <li>
                          <router-link to="membership" class="dropdown-item">
                            View</router-link
                          >
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="dropdown-item"> Edit</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="home-usercontent">
                    <div class="home-usercontents">
                      <div class="home-usercontentcount">
                        <img
                          src="@/assets/admin_img/icons/arrow-up.svg"
                          alt="img"
                          class="me-2"
                        />
                        <span class="counters" data-count="650">650</span>
                      </div>
                      <h5>Current Month</h5>
                    </div>
                    <div class="homegraph">
                      <img src="@/assets/admin_img/graph/graph4.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-6 col-12 d-flex widget-path">
            <div class="card">
              <div class="card-body">
                <div class="home-user">
                  <div class="home-head-user">
                    <h2>Revenue</h2>
                    <div class="home-select">
                      <div class="dropdown">
                        <button
                          class="btn btn-action btn-sm dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Monthly
                        </button>
                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item">Weekly</a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"
                              >Monthly</a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item">Yearly</a>
                          </li>
                        </ul>
                      </div>
                      <div class="dropdown">
                        <a
                          class="delete-table bg-white"
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"> View</a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"> Edit</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="chartgraph">
                    <apexchart
                      type="area"
                      height="350"
                      :options="areaChart.chart"
                      :series="areaChart.series"
                    >
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12 d-flex widget-path">
            <div class="card">
              <div class="card-body">
                <div class="home-user">
                  <div class="home-head-user">
                    <h2>Booking Summary</h2>
                    <div class="home-select">
                      <div class="dropdown">
                        <button
                          class="btn btn-action btn-sm dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Monthly
                        </button>
                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item">Weekly</a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"
                              >Monthly</a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item">Yearly</a>
                          </li>
                        </ul>
                      </div>
                      <div class="dropdown">
                        <a
                          class="delete-table bg-white"
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"> View</a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"> Edit</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="chartgraph">
                    <apexchart
                      type="bar"
                      height="350"
                      :options="barChart.chart"
                      :series="barChart.series"
                    >
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12 d-flex widget-path">
            <div class="card">
              <div class="card-body">
                <div class="home-user">
                  <div class="home-head-user home-graph-header">
                    <h2>Top Services</h2>
                    <router-link to="/admin/services" class="btn btn-viewall"
                      >View All<img
                        src="@/assets/admin_img/icons/arrow-right.svg"
                        class="ms-2"
                        alt="img"
                    /></router-link>
                  </div>
                  <div class="table-responsive datatable-nofooter">
                    <table class="table datatable">
                      <a-table :columns="columns3" :data-source="data3">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'Service'">
                            <a href="javascript:;" class="table-imgname">
                              <img
                                :src="
                                  require(`@/assets/admin_img/services/${record.Image}`)
                                "
                                class="me-2"
                                alt="img"
                              />
                              <span>{{ record.Service }}</span>
                            </a>
                          </template>
                        </template>
                      </a-table>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 d-flex widget-path">
            <div class="card">
              <div class="card-body">
                <div class="home-user">
                  <div class="home-head-user home-graph-header">
                    <h2>Top Providers</h2>
                    <router-link to="/admin/providers" class="btn btn-viewall"
                      >View All<img
                        src="@/assets/admin_img/icons/arrow-right.svg"
                        class="ms-2"
                        alt="img"
                    /></router-link>
                  </div>
                  <div class="table-responsive datatable-nofooter">
                    <table class="table datatable">
                      <a-table :columns="columns1" :data-source="data1">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'Provider_Name'">
                            <a href="javascript:void(0);" class="table-profileimage">
                              <img
                                :src="
                                  require(`@/assets/admin_img/customer/${record.Image}`)
                                "
                                class="me-2"
                                alt="img"
                              />
                              <span>{{ record.Provider_Name }}</span>
                            </a>
                          </template>
                        </template>
                      </a-table>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-sm-12 d-flex widget-path">
            <div class="card">
              <div class="card-body">
                <div class="home-user">
                  <div class="home-head-user home-graph-header">
                    <h2>Top Countries</h2>
                    <div class="home-select">
                      <div class="dropdown">
                        <button
                          class="btn btn-action btn-sm dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Monthly
                        </button>
                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item">Weekly</a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"
                              >Monthly</a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item">Yearly</a>
                          </li>
                        </ul>
                      </div>
                      <div class="dropdown">
                        <a
                          class="delete-table bg-white"
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown-menu" data-popper-placement="bottom-end">
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"> View</a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"> Edit</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="chartgraph">
                    <div class="row align-items-center">
                      <div class="col-lg-7">
                        <Map />
                      </div>
                      <div class="col-lg-5">
                        <div class="bookingmap">
                          <ul>
                            <li>
                              <span
                                ><img
                                  src="@/assets/admin_img/flags/us.png"
                                  alt="img"
                                  class="me-2"
                                />United State</span
                              >
                              <h6>60%</h6>
                            </li>
                            <li>
                              <span
                                ><img
                                  src="@/assets/admin_img/flags/in.png"
                                  alt="img"
                                  class="me-2"
                                />India</span
                              >
                              <h6>80%</h6>
                            </li>
                            <li>
                              <span
                                ><img
                                  src="@/assets/admin_img/flags/ca.png"
                                  alt="img"
                                  class="me-2"
                                />Canada</span
                              >
                              <h6>50%</h6>
                            </li>
                            <li>
                              <span
                                ><img
                                  src="@/assets/admin_img/flags/au.png"
                                  alt="img"
                                  class="me-2"
                                />Australia</span
                              >
                              <h6>75%</h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12 d-flex widget-path">
            <div class="card">
              <div class="card-body">
                <div class="home-user">
                  <div class="home-head-user home-graph-header">
                    <h2>Booking Statistics</h2>
                    <router-link to="/admin/booking" class="btn btn-viewall"
                      >View All<img
                        src="@/assets/admin_img/icons/arrow-right.svg"
                        class="ms-2"
                        alt="img"
                    /></router-link>
                  </div>
                  <div class="chartgraph">
                    <div class="row align-items-center">
                      <div class="col-lg-7 col-sm-6">
                        <apexchart
                          type="pie"
                          height="200"
                          :options="pieChart.chart"
                          :series="pieChart.series"
                        >
                        </apexchart>
                      </div>
                      <div class="col-lg-5 col-sm-6">
                        <div class="bookingstatus">
                          <ul>
                            <li>
                              <span></span>
                              <h6>Completed</h6>
                            </li>
                            <li class="process-status">
                              <span></span>
                              <h6>Process</h6>
                            </li>
                            <li class="process-pending">
                              <span></span>
                              <h6>Pending</h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 widget-path">
            <div class="card mb-0">
              <div class="card-body">
                <div class="home-user">
                  <div class="home-head-user home-graph-header">
                    <h2>Recent Booking</h2>
                    <router-link to="/admin/booking" class="btn btn-viewall"
                      >View All<img
                        src="@/assets/admin_img/icons/arrow-right.svg"
                        class="ms-2"
                        alt="img"
                    /></router-link>
                  </div>
                  <div class="table-responsive datatable-nofooter">
                    <div class="table datatable">
                      <a-table :columns="columns" :data-source="data">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'Provider'">
                            <a href="javascript:void(0);" class="table-profileimage">
                              <img
                                :src="
                                  require(`@/assets/admin_img/customer/${record.Provider_img}`)
                                "
                                class="me-2"
                                alt="img"
                              />
                              <span>{{ record.Provider }}</span>
                            </a>
                          </template>
                          <template v-else-if="column.key === 'User'">
                            <a href="javascript:void(0);" class="table-profileimage">
                              <img
                                :src="
                                  require(`@/assets/admin_img/customer/${record.User_img}`)
                                "
                                class="me-2"
                                alt="img"
                              />
                              <span>{{ record.User }}</span>
                            </a>
                          </template>
                          <template v-else-if="column.key === 'Service'">
                            <a href="javascript:void(0);" class="table-imgname">
                              <img
                                :src="
                                  require(`@/assets/admin_img/services/${record.Service_img}`)
                                "
                                class="me-2"
                                alt="img"
                              />
                              <span>{{ record.Service }}</span>
                            </a>
                          </template>
                          <template v-else-if="column.key === 'Status'">
                            <td>
                              <h6 :class="record.Class">{{ record.Status }}</h6>
                            </td>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
const data = [
  {
    id: "1",
    Date: "28 Sep 2023",
    Booking_Time: "10:00:00 - 11:00:00",
    Provider_img: "user-01.jpg",
    Provider: "John Smith",
    User_img: "user-03.jpg",
    User: "Sharon",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    Class: "badge-pending",
    Amount: "$80",
    Status: "Pending",
  },
  {
    id: "2",
    Date: "10 Sep 2023",
    Booking_Time: "18:00:00 - 19:00:00",
    Provider_img: "user-04.jpg",
    Provider: "Johnny",
    User_img: "user-05.jpg",
    User: "Pricilla",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    Class: "badge-active",
    Amount: "$50",
    Status: "Completed",
  },
  {
    id: "3",
    Date: "25 Sep 2023",
    Booking_Time: "12:00:00 - 13:00:00",
    Provider_img: "user-06.jpg",
    Provider: "Robert",
    User_img: "user-02.jpg",
    User: "Amanda",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    Class: "badge-inactive",
    Amount: "$50",
    Status: "Inprogress",
  },
  {
    id: "4",
    Date: "08 Sep 2023",
    Booking_Time: "07 Oct 2023 11:22:51",
    Provider_img: "user-09.jpg",
    Provider: "Sharonda",
    User_img: "user-01.jpg",
    User: "James",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    Class: "badge-delete",
    Amount: "$50",
    Status: "cancelled",
  },
  {
    id: "5",
    Date: "28 Sep 2023",
    Booking_Time: "10:00:00 - 11:00:00",
    Provider_img: "user-01.jpg",
    Provider: "John Smith",
    User_img: "user-03.jpg",
    User: "Sharon",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    Class: "badge-pending",
    Amount: "$80",
    Status: "Pending",
  },
];
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    key: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Booking Time",
    dataIndex: "Booking_Time",
    sorter: {
      compare: (a, b) => {
        a = a.Booking_Time.toLowerCase();
        b = b.Booking_Time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Provider",
    dataIndex: "Provider",
    key: "Provider",
    sorter: {
      compare: (a, b) => {
        a = a.Provider.toLowerCase();
        b = b.Provider.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User",
    dataIndex: "User",
    key: "User",
    sorter: {
      compare: (a, b) => {
        a = a.User.toLowerCase();
        b = b.User.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data1 = [
  {
    id: "1",
    Image: "user-06.jpg",
    Provider_Name: "Robert",
    Email: "robert@gmail.com",
    Phone: "+1 347-679-8275",
  },
  {
    id: "2",
    Image: "user-09.jpg",
    Provider_Name: "Sharonda",
    Email: "sharonda@gmail.com",
    Phone: "+1 570-621-248",
  },
  {
    id: "3",
    Image: "user-01.jpg",
    Provider_Name: "John Smith",
    Email: "johnsmith@gmail.com",
    Phone: "+1 646-957-0004",
  },
  {
    id: "4",
    Image: "user-05.jpg",
    Provider_Name: "Pricilla",
    Email: "pricilla@gmail.com",
    Phone: "+1 614-915-8101",
  },
  {
    id: "5",
    Image: "user-09.jpg",
    Provider_Name: "James",
    Email: "james@gmail.com",
    Phone: "+1 918-543-3702",
  },
];
const columns1 = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Provider Name",
    dataIndex: "Provider_Name",
    key: "Provider_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Provider_Name.toLowerCase();
        b = b.Provider_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "Email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    sorter: {
      compare: (a, b) => {
        a = a.Phone.toLowerCase();
        b = b.Phone.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data3 = [
  {
    id: "1",
    Image: "service-03.jpg",
    Service: "Computer Repair",
    Category: "Computer",
    Amount: "$80",
  },
  {
    id: "2",
    Image: "service-02.jpg",
    Service: "Car Repair Services",
    Category: "Automobile",
    Amount: "$50",
  },
  {
    id: "3",
    Image: "service-04.jpg",
    Service: "Car Wash",
    Category: "Automobile",
    Amount: "$14",
  },
  {
    id: "4",
    Image: "service-09.jpg",
    Service: "House Cleaning",
    Category: "Cleaning",
    Amount: "$100",
  },
  {
    id: "5",
    Image: "service-10.jpg",
    Service: "Interior",
    Category: "Cleaning",
    Amount: "$50",
  },
];
const columns3 = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category",
    dataIndex: "Category",
    sorter: {
      compare: (a, b) => {
        a = a.Category.toLowerCase();
        b = b.Category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];

import { areaChart, barChart, areaChart1, pieChart } from "./data";

import Map from "./map.vue";

export default {
  name: "WorldMap",
  components: {
    Map,
  },
  data() {
    return {
      columns,
      data,
      columns1,
      data1,
      columns3,
      data3,
      areaChart: areaChart,
      barChart: barChart,
      areaChart1: areaChart1,
      pieChart: pieChart,
      menuItems: [
        { title: 'Pengaturan', icon: 'fa-cogs', route: '/admin-setting/pengaturan' },
        { title: 'Monitoring', icon: 'fa-chart-line', route: '/admin-setting/monitoring' },
        { title: 'Kendaraan', icon: 'fa-car', route: '/admin-setting/vehicle' },
        { title: 'Order', icon: 'fa-users', route: '/admin-setting/order' },
        { title: 'Pelanggan', icon: 'fa-users', route: '/admin-setting/customer' },
        { title: 'Finance', icon: 'fa-money-bill-wave', route: '/admin-setting/finance-menu' },
        { title: 'Berlangganan', icon: 'fa-phone', route: '/admin-setting/berlangganan' },
        { title: 'Bantuan', icon: 'fa-question-circle', route: '/admin-setting/bantuan' }
      ]
    };
  },
  // mounted() {
  //     setTimeout(function () {
  // 	$('#loader');
  // 	setTimeout(function () {
  // 		$("#loader").fadeOut("slow");
  // 		$("#overlayer").delay(2000).fadeOut("slow");
  // 	}, 100);
  // }, 500);

  // if($('.sidebar .active').length > 0) {
  // 	var container = $('div'),
  // 		scrollTo = $('.sidebar .active');
  // 		container.animate({
  // 			scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop() - 100
  // 	});
  // }
  // },
  methods: {
    reloadRoute() {
      this.$router.push({ path: this.$route.path });
    },

    initMap() {
      const usMap = $("#us-map");
      usMap.emty();
      usMap.vectorMap({
        map: "world_mill_en",
        backgroundColor: "transparent",
        zoomButtons: false,
        zoomOnScroll: false,
        regionStyle: {
          initial: {
            fill: "#e4e4e4",
            "fil-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opactity": 0,
          },
        },
        series: {
          regions: [
            {
              values: ["someData"],
            },
          ],
        },
      });
    },
  },
};
</script>

<!-- <style scoped>
.category-card {
	background: #fff;
	border: 2px solid #F5F5F5;
	border-radius: 10px;
	margin-bottom: 24px;
}
.category-img {
	overflow: hidden;
	border-radius: 10px 10px 0px 0px;
}
.category-img img {
	width: 100%;
  height: 282px;
	border-radius: 10px 10px 0px 0px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.category-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.category-info {
	padding: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;		
}
.category-name {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;	
}
.category-icon {
	width: 45px;
	height: 45px;
	background: #F7F7FF;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;	
	margin-right: 10px;
}
.category-name h6 {
	font-size: 20px;
	margin-bottom: 0;
}
.category-name h6 a {
	color: #28283C;
}
.category-name h6 a:hover {
	color: #35a7f4;
}
.category-info p {
	font-size: 16px;
	color: #74788d;
	margin-bottom: 0;
}
</style> -->

<style scoped>
.feature-section {    
	/* background: url(../assets/img/bg/feature-bg-01.png), url(../assets/img/bg/feature-bg-02.png), #F7F7FF; */
  background: #F7F7FF;
	background-repeat: no-repeat;
	background-position: left center, right center;
	padding: 90px 0 66px;
}
.feature-section.nearby-section .section-heading h2 {
	font-weight: 700;
}
.feature-section.nearby-section .section-heading .btn-view {
	padding: 14px 20px;
	border-radius: 50px;
}
.feature-section, .work-section, .client-section {
  padding: 60px 0 36px;
}
.feature-box {
	background: #fff;
	box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
	padding: 25px;
	text-align: center;
	margin-bottom: 1.5rem;
	border-radius: 10px;
	position: relative;	
	display: block;
    z-index: 1;
	overflow: hidden;
}
.feature-icon span {	
    z-index: 1;
}
.feature-icon span {
	width: 94px;
	height: 94px;
	background: #F7F7FF;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	border-radius: 50%;
	margin: 0 auto 20px;
}
.feature-box h5 {
	margin-bottom: 0;
}
.feature-box .feature-overlay {
	opacity: 0;
	position: absolute;
	top: 0;
	left: -100%;	
    z-index: -1;
	-webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
	width: 100%;
}
.feature-box .feature-overlay img {	
	border-radius: 10px;	
}
.feature-box:hover .feature-overlay {
	opacity: 1;
	left: 0;
}
.feature-box:hover .feature-overlay:before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 50%);
    border-radius: 10px;
}
.feature-box:hover h5 {
	color: #fff;
}
.feature-box .feature-overlay img {
		width: 100%;
	}
</style>
