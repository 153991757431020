<template>
    <!-- Footer -->
    <footer class="footer footer-seven">

        <!-- Footer Top -->
        <div class="footer-top aos" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-widget-seven">
                            <div class="footer-logo">
                                <router-link to="/"><img src="@/assets/img/logo-03.png" alt="logo"></router-link>
                            </div>
                            <div class="footer-content">
                                <p>We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                            </div>
                            <div class="footer-selects footer-selects-seven">
                                <h2 class="footer-subtitle">Newsletter Signup</h2>
                                <div class="subscribe-form">
                                    <input type="email" class="form-control" placeholder="Enter your email">
                                    <button type="submit" class="btn footer-btn">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Support</h2>
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">Account</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Book Appointment</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Orders</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Payments</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Returns</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">About</h2>
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">Account</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Book Appointment</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Orders</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Payments</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Returns</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Services</h2>
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">Account</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Book Appointment</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Orders</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Payments</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Returns</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Privacy Policy</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget">
                            <h2 class="footer-title">Contact</h2>
                            <div class="footer-six-main">
                                <div class="footer-six-left">
                                    <span class="footer-seven-icon">
                                        <img src="@/assets/img/icons/call-calling.svg" alt="">
                                    </span>
                                    <div class="footer-six-ryt">
                                        <span>Phone Number</span>
                                        <h6>310-437-2766</h6>
                                    </div>
                                </div>
                                <div class="footer-six-left">
                                    <span class="footer-seven-icon">
                                        <img src="@/assets/img/icons/sms.svg" alt="">
                                    </span>
                                    <div class="footer-six-ryt">
                                        <span>Mail Address</span>
                                        <h6>support@truelysell.com</h6>
                                    </div>
                                </div>
                                <div class="footer-six-left ">
                                    <span class="footer-seven-icon">
                                        <img src="@/assets/img/icons/location.svg" alt="">
                                    </span>
                                    <div class="footer-six-ryt">
                                        <span>Address</span>
                                        <h6>706 Campfire Ave. Meriden, CT 06450</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="social-icon">
                                <ul>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fa-brands fa-facebook"></i> </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fab fa-twitter"></i> </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top -->

        <!-- Footer Bottom -->
        <div class="footer-bottom footer-bottom-seven">
            <div class="container">
                <!-- Copyright -->
                <div class="copyright">
                    <div class="row text-center">
                        <div class="col-md-12">
                            <div class="copyright-text">
                                <p>Copyright 2023 © Truelysell. All right reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Copyright -->
            </div>
        </div>
        <!-- /Footer Bottom -->

    </footer>
    <!-- /Footer -->
</template>