<template>
  <div class="content container-fluid">
    <div class="row">
      <div class="col-md-12">
        <!-- Security settings -->
        <div class="widget-title">
          <h4>Plan & Billings</h4>
        </div>
        <div class="row">
          <div class="col-lg-6 d-flex flex-column">
            <!-- Current Plan -->
            <h6 class="subhead-title">Current Plan</h6>
            <div class="card flex-fill">
              <div class="card-body">
                <div class="plan-info">
                  <div class="plan-term">
                    <h5>Standard</h5>
                    <p>Our most popular plan for small teams.</p>
                    <h6>
                      <span><i class="feather-calendar"></i> Renew Date:</span> Jan 22,
                      2023
                    </h6>
                  </div>
                  <div class="plan-price">
                    <h4>$291</h4>
                    <p>per user/Year</p>
                  </div>
                </div>
                <div class="plan-btns">
                  <a href="javascript:;" class="btn btn-danger-light"
                    >Cancel Subscription</a
                  >
                  <a href="javascript:;" class="btn btn-secondary-outline">Upgrade</a>
                </div>
              </div>
            </div>
            <!-- /Current Plan -->
          </div>
          <div class="col-lg-6 d-flex flex-column">
            <!-- Payments -->
            <h6 class="subhead-title">Payment</h6>
            <div class="card flex-fill">
              <div class="card-body pay-next">
                <div class="plan-info">
                  <div class="plan-term">
                    <h5>Last Payment</h5>
                    <h6><i class="feather-calendar"></i> January 22, 2023</h6>
                  </div>
                  <div class="plan-price">
                    <a href="javascript:;" class="btn btn-light-success">Active</a>
                  </div>
                </div>
                <div class="plan-info">
                  <div class="plan-term">
                    <h5>Next Payment</h5>
                    <h6><i class="feather-calendar"></i> January 22, 2023</h6>
                  </div>
                  <div class="plan-price">
                    <a href="javascript:;" class="btn btn-primary">Manage Payment</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Payments -->
          </div>

          <div class="col-md-12">
            <h6 class="user-title">Payment Method</h6>
            <div class="row">
              <!-- Credit Card -->
              <div class="col-md-6 col-xl-4 d-flex flex-column">
                <div
                  class="card card-payments flex-fill"
                  @click="activate(1)"
                  :class="{ active: active_el == 1 }"
                >
                  <div class="card-body">
                    <div>
                      <h6>Credit Card</h6>
                      <div class="card-num">
                        <div class="visa-img">
                          <img src="@/assets/img/icons/visa.svg" alt="" />
                        </div>
                        <h6>**** **** **** <span>9525</span></h6>
                      </div>
                    </div>
                    <div class="card-remove">
                      <a href="javascript:;"
                        >Remove Card <i class="feather-trash-2"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Credit Card -->

              <!-- Debit Card -->
              <div class="col-md-6 col-xl-4 d-flex flex-column">
                <div
                  class="card card-payments flex-fill"
                  @click="activate(2)"
                  :class="{ active: active_el == 2 }"
                >
                  <div class="card-body">
                    <div>
                      <h6>Debit Card</h6>
                      <div class="card-num">
                        <div class="visa-img">
                          <img src="@/assets/img/icons/Mastercard.svg" alt="" />
                        </div>
                        <h6>**** **** **** <span>9525</span></h6>
                      </div>
                    </div>
                    <div class="card-remove">
                      <a href="javascript:;"
                        >Remove Card <i class="feather-trash-2"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Debit Card -->

              <!-- Add Card -->
              <div class="col-md-6 col-xl-4 d-flex flex-column">
                <div class="card-payment-add flex-fill">
                  <a href="javascript:;">
                    <span><i class="feather-plus"></i></span>
                  </a>
                </div>
              </div>
              <!-- /Add Card -->
            </div>

            <!-- Billing History -->
            <div class="page-header">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <h6 class="subhead-title">Billing History</h6>
                </div>
                <div
                  class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap"
                >
                  <div class="review-sort d-flex align-items-center me-2">
                    <p>Sort</p>
                    <vue-select :options="abcd" placeholder="A -> Z" />
                  </div>
                  <div class="grid-listview">
                    <ul>
                      <li>
                        <a href="javascript:void(0);">
                          <img src="@/assets/img/icons/filter-icon.svg" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="provide-table">
              <div class="table-responsive">
                <div class="table custom-table datatable mb-0" id="data-table">
                  <a-table :columns="columns" :data-source="data">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'Payout_Date'">
                        <td class="text-body">{{ record.Payout_Date }}</td>
                      </template>
                      <template v-else-if="column.key === 'Status'">
                        <td>
                          <span :class="record.Class">{{ record.Status }}</span>
                        </td>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <div id="tablelength"></div>
              </div>
              <div class="col-md-7">
                <div class="table-ingopage">
                  <div id="tableinfo"></div>
                  <div id="tablepagination"></div>
                </div>
              </div>
            </div>
            <!-- /Billing History -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "Payout Date",
    dataIndex: "Payout_Date",
    key: "Payout_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Payout_Date.toLowerCase();
        b = b.Payout_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Plan Details",
    dataIndex: "Plan_Details",
    sorter: {
      compare: (a, b) => {
        a = a.Plan_Details.toLowerCase();
        b = b.Plan_Details.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Invoice",
    dataIndex: "Invoice",
    sorter: {
      compare: (a, b) => {
        a = a.Invoice.toLowerCase();
        b = b.Invoice.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    Payout_Date: "Sep 26, 2023",
    Plan_Details: "Basic, Monthly",
    Amount: "$29.00",
    Invoice: "Invoice 08 sep, 2023",
    Class: "badge-success",
    Status: "Paid",
  },
  {
    Payout_Date: "Sep 26, 2023",
    Plan_Details: "Basic, Monthly",
    Amount: "$29.00",
    Invoice: "Invoice 08 sep, 2023",
    Class: "badge-success",
    Status: "Paid",
  },
  {
    Payout_Date: "Sep 26, 2023",
    Plan_Details: "Basic, Monthly",
    Amount: "$29.00",
    Invoice: "Invoice 08 sep, 2023",
    Class: "badge-success",
    Status: "Paid",
  },
  {
    Payout_Date: "Sep 26, 2023",
    Plan_Details: "Basic, Monthly",
    Amount: "$29.00",
    Invoice: "Invoice 08 sep, 2023",
    Class: "badge-danger",
    Status: "Pending",
  },
];
export default {
  data() {
    return {
      abcd: ["A -> Z", "Most helful"],
      columns,
      data,
      active_el: 0,
    };
  },
  methods: {
    activate: function (el) {
      this.active_el = el;
    },
  },
};
</script>
