<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Edit Post</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Titles</label>
                    <b-form-input type="text" class="form-control" value="Car Wash" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Language</label>
                    <vue-select
                      :options="Languageeditblog"
                      id="lanaguageeditblog"
                      placeholder="English"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Category</label>
                    <vue-select
                      :options="Categoryeditblog"
                      id="categoryeditblog"
                      placeholder="Category 1"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label
                      >Slug
                      <span
                        >(If you leave it empty, it will be generated
                        automatically.)</span
                      ></label
                    >
                    <b-form-input type="text" class="form-control" value="  Title" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Description<span>(Meta Tag)</span></label>
                    <b-form-input type="text" class="form-control" value=" Description" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Keywords<span>(Meta Tag)</span></label>
                    <b-form-input type="text" class="form-control" value=" Keywords" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group mb-1">
                    <label>Blog Image</label>
                  </div>
                  <div class="form-uploads mb-4">
                    <div class="form-uploads-path">
                      <img src="@/assets/admin_img/icons/upload.svg" alt="img" />
                      <div class="file-browse">
                        <h6>Drag & drop image or</h6>
                        <div class="file-browse-path">
                          <input type="file" />
                          <a href="javascript:void(0);"> Browse</a>
                        </div>
                      </div>
                      <h5>Supported formates: JPEG, PNG</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label> Descriptions</label>

                    <summernoteEditor
                      v-model="myValue"
                      @update:modelValue="summernoteChange($event)"
                      @summernoteImageLinkInsert="summernoteImageLinkInsert"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" variant="primary">Save Post</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import SummernoteEditor from "vue3-summernote-editor";
export default {
  components: {
    SummernoteEditor,
  },
  data() {
    return {
      Languageeditblog: ["English", "German"],
      Categoryeditblog: ["Category 1", "Category 2"],
      myValue: "description",
    };
  },
  methods: {
    summernoteChange(newValue) {
      // console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      // console.log("summernoteImageLinkInsert()", args);
    },
    submitForm() {
      this.$router.push("/admin/all-blog");
    },
  },
};
</script>
