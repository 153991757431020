<template>
    <!-- Add Menu -->
    <div class="modal fade" id="add-menu" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Menu</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="mb-3">
                <label class="form-label">Name</label>
                <input type="text" class="form-control" v-model="formCreate.name"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Link</label>
                <input type="text" class="form-control" v-model="formCreate.link"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Parent</label>
                <input type="text" class="form-control" v-model="formCreate.parent_id"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Position</label>
                <input type="text" class="form-control" v-model="formCreate.position"/>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Menu -->
  
    <!-- Edit Menu -->
    <div class="modal fade" id="edit-menu" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Menu</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="mb-3">
                <label class="form-label">Name</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name" />
              </div>
              <div class="mb-3">
                <label class="form-label">Link</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.link" />
              </div>
              <div class="mb-3">
                <label class="form-label">Parent</label>
                <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.parent_id" />
              </div>
              <div class="mb-3">
                <label class="form-label">Position</label>
                <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.position" />
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Menu -->
  
    <div class="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Menu ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {}
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object
    },
    emits: ['edit-menu'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
    },
    methods: {
      editSubmit() {
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("menu/updateMenu", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {}
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createSubmit() {
        var loading = this.$loading.show()
        this.$store
          .dispatch("menu/addMenu", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {}
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteData() {
        // Perform deletion using this.deleteItemId
        console.log('Deleting item with ID:', this.deleteItemId);
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("menu/deleteMenu", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      }
    }
  };
  </script>
  