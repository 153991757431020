<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <!-- Customer Menu -->
        <customer-sidebar></customer-sidebar>
        <!-- /Customer Menu -->

        <div class="col-lg-9">
          <div class="widget-title">
            <h4>Account Settings</h4>
          </div>
          <h6 class="user-title">Profile Picture</h6>
          <div class="pro-picture">
            <div class="pro-img">
              <img :src="imageUrl" alt="user" />
            </div>
            <div class="pro-info">
              <div class="d-flex">
                <div class="img-upload">
                  <i class="feather-upload-cloud me-1"></i>Upload
                  <input type="file" />
                </div>
                <a href="javascript:;" class="btn btn-remove">Remove</a>
              </div>
              <p>
                *image size should be at least 320px big,and less then 500kb. Allowed
                files .png and .jpg.
              </p>
            </div>
          </div>
          <h6 class="user-title">General Information</h6>
          <div class="general-info">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input type="text" class="form-control" placeholder="Enter Your Name" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">User Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Your User Name"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label"
                    >Email <span class="text-danger">*</span></label
                  >
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter Email Address"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label"
                    >Mobile Number <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Mobile Number"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Gender</label>
                  <vue-select :options="Gender" id="gender" placeholder="Select Gender" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label">Date of birth</label>
                  <div class="form-icon">
                    <datepicker
                      v-model="startdate"
                      :input-format="dateFormat"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                      placeholder="DD/MM/YYYY"
                    />
                    <span class="cus-icon"><i class="feather-calendar"></i></span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="col-form-label d-block"
                    >Your Bio
                    <span class="brief-bio float-end"
                      >Brief description for your profile. URLs are hyperlinked.</span
                    ></label
                  >
                  <textarea
                    class="form-control"
                    rows="5"
                    placeholder="Add a Short Bio....."
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <h6 class="user-title">Address</h6>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="col-form-label"
                  >Address<span class="text-danger"> *</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your address"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label"
                  >Country<span class="text-danger"> *</span></label
                >
                <input type="text" class="form-control" placeholder="Enter your state" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label"
                  >State<span class="text-danger"> *</span></label
                >
                <input type="email" class="form-control" placeholder="Enter your state" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label"
                  >City<span class="text-danger"> *</span></label
                >
                <input type="text" class="form-control" placeholder="Enter your city" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label"
                  >Postal Code<span class="text-danger"> *</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your postal code"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Currency Code</label>
                <vue-select
                  :options="Currency"
                  id="currency"
                  placeholder="Choose Currency"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label"
                  >Language<span class="text-danger"> *</span></label
                >
                <vue3-tags-input
                  class="input-tags form-control"
                  type="tag"
                  data-role="tagsinput"
                  name="specialist"
                  id="specialist"
                  :tags="tags"
                />
              </div>
            </div>
          </div>
          <div class="acc-submit">
            <a href="javascript:;" class="btn btn-secondary">Cancel</a>
            <a href="javascript:;" class="btn btn-primary">Save Changes</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Vue3TagsInput from "vue3-tags-input";
const currentDate = ref(new Date());
const image = require(`@/assets/img/profiles/avatar-02.jpg`);
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      Gender: ["Select Gender", "Male", "Female"],
      Currency: ["Choose Currency", "Eur", "Aud"],
      startdate: currentDate,
      imageUrl: image,
      tags: ["English", "French"],
      myValue: "description",
      dateFormat: "dd-MM-yyyy",
    };
  },
  methods: {
    onFileSelected(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        this.imageUrl = event.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
};
</script>

<style>
.v3ti .v3ti-tag {
  background-color: #f4f7ff;
  color: #74788d;
}
.v3ti .v3ti-tag .v3ti-remove-tag {
  color: black;
}

.v3ti.input-tags.form-control:focus {
  outline: none;
}
</style>
