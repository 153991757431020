<template>
    <div class="main-wrapper">
        <layout></layout>
        <adminsidebar></adminsidebar>

        <div class="page-wrapper page-settings">
			<sidelinkheading />
			<div class="content w-100">
                <div class="content-page-header">
                    <h5>Connected Apps</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                       <div class="connectetapps">
                            <div class="connectetappsimg">
                                <div class="connectet-img">
                                    <img src="@/assets/admin_img/icons/google.svg" alt="img">
                                </div>
                                <div class="connectet-content">
                                    <h3>Google Calendar</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur </p>
                                </div>
                            </div>
                            <div class="connectetappscontent">
                                <h6 class="success-labels">Connect</h6>
                                <div class="active-switch">
                                    <label class="switch">
                                        <input type="checkbox" checked>
                                        <span class="sliders round"></span>
                                      </label>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
			</div>
		</div> 
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    name: 'adminconnected-apps'
}
</script>