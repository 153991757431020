<template>
  <!-- Change Email -->
  <div class="modal fade custom-modal" id="change-email">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Change Email</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form @submit.prevent="submitFormOne">
            <div class="wallet-add">
              <div class="form-group">
                <label class="col-form-label">Current Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Email Address"
                />
              </div>
              <div class="form-group">
                <label class="col-form-label"
                  >New Email Address <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Email Address"
                />
                <p class="brief-bio mb-0 mt-2">
                  <i class="feather-alert-circle"></i> New Email Address Only Updated Once
                  You Verified
                </p>
              </div>
              <div class="form-group">
                <label class="col-form-label">Confirm New Password</label>
                <div class="pass-group">
                  <input
                    v-if="showPassword"
                    type="text"
                    class="form-control pass-input"
                    v-model="password"
                    placeholder="**********"
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder="**********"
                    v-model="password"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <button class="btn btn-primary">Change Email</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Change Email -->

  <!-- Change Phone  -->
  <div class="modal fade custom-modal" id="change-phone">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Change Phone Number</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form @submit.prevent="submitFormOne">
            <div class="wallet-add">
              <div class="form-group">
                <label class="col-form-label">Current Phone Number</label>
                <vue-tel-input
                  mode="international"
                  class="form-control form-control-lg group_formcontrol"
                  id="phone"
                  name="phone"
                  value="(256) 789-6253"
                ></vue-tel-input>
              </div>
              <div class="form-group">
                <label class="col-form-label"
                  >New Phone Number <span class="text-danger">*</span></label
                >
                <vue-tel-input
                  mode="international"
                  class="form-control form-control-lg group_formcontrol"
                  id="phone1"
                  name="phone"
                  value="(256) 789-6253"
                ></vue-tel-input>
                <p class="brief-bio mb-0 mt-2">
                  <i class="feather-alert-circle"></i> New Phone Number Only Updated Once
                  You Verified
                </p>
              </div>
              <div class="form-group">
                <label class="col-form-label">Confirm New Password</label>
                <div class="pass-group">
                  <input
                    v-if="showPassword2"
                    type="text"
                    class="form-control pass-input"
                    v-model="password2"
                    placeholder=".............."
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder=".............."
                    v-model="password2"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow1"
                    :class="{
                      'feather-eye': showPassword2,
                      'feather-eye-off': !showPassword2,
                    }"
                  ></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <button class="btn btn-primary">Change Number</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Change Phone  -->

  <!-- Delete Account -->
  <div class="modal fade custom-modal" id="del-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sureyou want to delete This Account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <b-button type="submit" variant="danger">Delete Account</b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
      showPassword2: false,
      password2: null,
      phone: "",
      phone1: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    buttonLabel1() {
      return this.showPassword2 ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword2 = !this.showPassword2;
    },
    submitForm() {
      this.$router.push("/login");
    },
    submitFormOne() {
      this.$router.push("/provider-security-settings");
    },
  },
};
</script>
