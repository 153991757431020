<template>
    <div v-if="isOwner == false" class="main-wrapper">
      <mari-layout></mari-layout>
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>Perusahaan</h4>
                  <p>List daftar perusahaan disni!</p>
                </div>
                <div class="col-md-6 text-md-end aos" data-aos="fade-up"><button
                      class="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#add-company"
                    >
                      <i class="fa fa-plus me-2"></i>Tambah Perusahaan
                    </button>
                </div>
              </div>
            </div>
            
            <div class="row mt-4">
              <div class="input-group" style="justify-content: end;">
                <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="max-width: 25% !important;" @keyup.enter="search">
                <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <div class="table-resposnive table-div">
                  <div class="table datatable">
                    <a-table :columns="columns" :data-source="data" :pagination="pagination">
                      <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'id'">
                          <div class="table-actions d-flex">
                              <button
                              class="btn delete-table me-2"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#show-company"
                              @click="openEditModal(record)"
                            >
                              <i class="feather feather-eye"></i>
                            </button>
                            <button
                              class="btn delete-table me-2"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#edit-company"
                              @click="openEditModal(record)"
                            >
                              <i class="feather feather-edit"></i>
                            </button>
                            <button
                              class="btn delete-table"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#delete"
                              @click="openDeleteModal(record)"
                            >
                              <i class="feather feather-trash-2"></i>
                            </button>
                          </div>
                        </template>
                      </template>
                    </a-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isOwner == true" class="main-wrapper">
      <mari-layout></mari-layout>
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>Perusahaan</h4>
                  <p>Edit data perusahaan disni!</p>
                </div>
                <div class="col-md-6 text-md-end aos" data-aos="fade-up">
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="editCurrentSubmit"
                    >
                      Update
                    </button>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="col-12">
                    <div class="col-6">
                        <div class="mb-3">
                            <div class="profile-upload-content">
        
                                <!-- company logo here -->
                                <img v-if="(data !== undefined) && (data.logo !== '')" :src="data.logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                <!-- company logo here -->
        
                                <!-- upload file form logo here -->
                                <div class="profile-upload-btn">
                                    <div class="profile-upload-file">
                                        <input
                                            type="file"
                                            class="form-control"
                                            ref="uploadFieldAttachment"
                                            id="imgInp"
                                            @change="selectFileAttachment"
                                        />
                                    </div>
                                </div>
                                    <!-- upload file form logo here -->
                                <div class="profile-upload-para">
                                    <p style="font-size: 12px;">
                                    <span style="color: red;">*</span>ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                                    File yang diizinkan .png dan .jpg.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nama Perusahaan<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="text" class="form-control" v-model="data.name"/>
                        </div>
                        <div>
                            <label class="form-label">Alamat<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="text" class="form-control" v-model="data.address"/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nomer Telepon (WA)<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="text" class="form-control" v-model="data.phone_number"/>
                        </div>
                        <div>
                            <label class="form-label">Nama Pemilik<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="text" class="form-control" v-model="data.owner_name"/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Tanggal Bergabung<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="datetime-local" class="form-control" v-model="data.join_date" disabled/>
                        </div>
                        <div>
                            <label class="form-label">Tanggal Habis Berlangganan<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="date" class="form-control" v-model="data.due_date" disabled/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div style="margin-right: 10px;">
                          <label class="form-label">Nama Bank<span style="color: red;">*</span></label>
                          <select v-if="data !== undefined" class="form-control" v-model="data.bank_account">
                            <option value="">Pilih Bank</option>
                            <option value="bca">BCA (Bank Central Asia)</option>
                            <option value="bri">BRI (Bank Rakyat Indonesia)</option>
                            <option value="bni">BNI (Bank Negara Indonesia)</option>
                            <option value="mandiri">Bank Mandiri</option>
                            <option value="cimb">CIMB Niaga</option>
                            <option value="btn">Bank BTN</option>
                            <option value="permata">Bank Permata</option>
                            <option value="danamon">Bank Danamon</option>
                            <option value="panin">Bank Panin</option>
                            <option value="ocbc">OCBC NISP</option>
                          </select>
                      </div>
                      <div>
                          <label class="form-label">Nama Pemilik Bank<span style="color: red;">*</span></label>
                          <input v-if="data !== undefined" type="text" class="form-control" v-model="data.nama_pemilik_bank"/>
                      </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                      <div style="margin-right: 10px;">
                          <label class="form-label">No Rekening<span style="color: red;">*</span></label>
                          <input v-if="data !== undefined" type="text" class="form-control" v-model="data.no_rekening"/>
                      </div>
                      <div>
                        <label class="form-label">Tentang Perusahaan</label>
                        <input v-if="data !== undefined" type="text" class="form-control" v-model="data.about"/>
                      </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="mt-3">
                        <div class="profile-upload-content">
                          <p>Tanda Tangan dan Stample</p>
    
                            <div class="d-flex">
                              <img v-if="stample" :src="stample" alt="logo" class="img-thumbnail mb-3 me-3" width="100" height="100">
                              <img v-else-if="formEdit && formEdit.stample" :src="formEdit.stample" alt="logo" class="img-thumbnail mb-3 me-3" width="100" height="100">
                              <img v-else :src="require('@/assets/img/stample-ttd.png')" alt="logo" class="img-thumbnail mb-3 me-3" width="100" height="100">
                              <p v-if="!stample && (!formEdit || !formEdit.stample)">Ini adalah contoh gambar Tanda Tangan dan Stample</p>
                            </div>
    
                            <!-- upload file form logo here -->
                            <div class="profile-upload-btn">
                                <div class="profile-upload-file">
                                    <input
                                        type="file"
                                        class="form-control"
                                        ref="uploadFieldAttachment"
                                        id="imgInp"
                                        @change="uploadStample"
                                    />
                                </div>
                            </div>
                                <!-- upload file form logo here -->
                            <div class="profile-upload-para">
                                <p style="font-size: 12px;">
                                <span style="color: red;">*</span>Pastikan gambar berlatar putih atau transparan, dan kurang dari 500kb.
                                File yang diizinkan .png
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-3">
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="col-6">
                            </div>
                        </div>
                    </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <company-model :editData="editData" :deleteItemId="itemIdToDelete" @dataUpdated="getData"></company-model>
  </template>
  
  <script>
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Nama Perusahaan",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Alamat",
      dataIndex: "address",
      key: "address",
      sorter: {
        compare: (a, b) => {
          a = a.address.toLowerCase();
          b = b.address.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Nomer Telepon",
      dataIndex: "phone_number",
      key: "phone_number",
      sorter: {
        compare: (a, b) => {
          a = a.phone_number.toLowerCase();
          b = b.phone_number.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Nama Pemilik",
      dataIndex: "owner_name",
      key: "owner_name",
      sorter: {
        compare: (a, b) => {
          a = a.owner_name.toLowerCase();
          b = b.owner_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataRole: [],
        isOwner: false,
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        logo: '',
        stample: null
      };
    },
    name: "companysetting",
    computed: {

    },
    methods: {
        search() {
          this.params.page = 1;
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            const userData = localStorage.getItem('user');
            const user = JSON.parse(userData);
            var loading = this.$loading.show();
            if (user.company_id == 0 || !user.company) {
                this.$store
                    .dispatch("company/getCompany", this.params)
                    .then((resp) => {
                        this.data = resp.data.data.map((item, index) => {
                            return {
                                ...item,
                                index: index + 1
                            };
                        });
                        this.pagination.current = resp.data.current_page;
                        this.pagination.total = resp.data.total;
                        this.pagination.pageSize = this.params.row;
                        loading.hide();
                    })
                    .catch((e) => {
                        this.$toast.error(e);
                        loading.hide();
                    });
            } else {
                this.$store
                    .dispatch("company/showCompany", {id : user.company_id})
                    .then((resp) => {
                        this.data = resp.data.data;
                        this.isOwner = true;
                        loading.hide();
                    })
                    .catch((e) => {
                        this.$toast.error(e);
                        loading.hide();
                    });
            }
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
        editCurrentSubmit() {
        this.data.logo = this.logo !== '' ? this.logo : this.data.logo;
        const id = this.data.id;
        if (this.stample) {
          this.data.stample = this.stample
        }
        var loading = this.$loading.show()
        this.$store
          .dispatch("company/updateCompany", { id: id, data: this.data })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.data = {};
              this.logo = '';
              this.getData();
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      validateFile(file, allowedFileTypes, maxFileSize) {
        if (!allowedFileTypes.includes(file.type)) {
            this.$swal.fire({
                icon: 'warning',
                text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
            });
            return false;
        }

        if (file.size > maxFileSize) {
            this.$swal.fire({
                icon: 'warning',
                text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
            });
            return false;
        }

        return true;
      },
      // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      uploadStample(event) {
        const file = event.target.files[0];
        const allowedFileTypes = ['image/png'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(file)
            .then((resp) => {
                this.stample = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
    },
    mounted() {
        this.getData();
    },
  };
  </script>
      <style>
      .ant-select-selection-item {
        height: 30px;
        width: 90px;
        margin-top: -6px;
        font-size: 15px;
      }
      </style>
