<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
            <div class="content-page-header content-page-headersplit mb-0" style="margin-bottom: 20px !important;">
                <h5>Monitoring</h5>
                <div class="list-btn">
                <ul>
                    <li>
                      <router-link to="/admin-setting/create-vehicle"
                        class="btn btn-primary"
                        type="button"
                        >
                            <i class="fa fa-plus me-2"></i>Tambah Kendaraan
                      </router-link>
                    <!-- <button
                        class="btn btn-primary"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#add-vehicle"
                    >
                        <i class="fa fa-plus me-2"></i>Tambah Kendaraan
                    </button> -->
                    </li>
                </ul>
                </div>
            </div>
            <div class="row">
                <div class="button-container">
                    <button class="btn btn-primary" @click="prevMonth" :disabled="prevMonthButtonDisabled">Previous Month</button>
                    <button class="btn btn-primary" @click="nextMonth" :disabled="nextMonthButtonDisabled">Next Month</button>
                </div> 
                <div class="d-flex">
                  <div class="flex-grow-0 d-flex flex-column mb-2">
                    <span>Jangka waktu</span>
                    <span class="fs-6 fw-bold">{{ scheduler.from.locale('id').format('DD MMM YYYY') }} - {{ scheduler.to.locale('id').format('DD MMM YYYY') }}</span>
                  </div>
                  <div class="flex-grow-0 ms-4">
                    <span>Order indicator</span>
                    <div class="d-flex">
                      <div class="me-2 d-flex align-items-center">
                        <span style="width: 8px; height: 8px; background-color: #35a7f4;" class="d-block rounded-circle me-2"></span>
                        <span>Booking</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <span style="width: 8px; height: 8px; background-color: #6DCC76;" class="d-block rounded-circle me-2"></span>
                        <span>Proses</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">

                    <Scheduler
                      :from="scheduler.from"
                      :to="scheduler.to"
                      :list="scheduler.list"
                      :rows="scheduler.rows"
                      :items="scheduler.items"
                      @row="onRowClick"
                      @cell="onCellClick"
                      @item="onItemClick"
                    />
                    <SchedulePopupDetail
                      ref="popupDetail"
                      :vehicles="dataVehicle"
                      @on-vehicle-updated="onVehicleUpdated"
                    />
                </div>
            </div>
        </div>
      </div>
    </div>
    <vehicle-model :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" @dataUpdated="getData"></vehicle-model>
    <order-model :startDate="startDate" :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :customerData="dataCustomer" :vehicleData="dataVehicle" :userData="dataUser" @dataUpdated="getData" :dataId="dataId"></order-model>
  </template>
  
  <script>

  import moment from 'moment'
  import Scheduler from '@/views/mari/pages/monitoring/Scheduler.vue'
  import SchedulePopupDetail from '@/views/mari/pages/monitoring/SchedulePopupDetail.vue'

  const SCHEDULER_START_DATE = '2024-2-1'

  export default {
    components: {
      Scheduler,
      SchedulePopupDetail,
    },
    data() {
      return {
        scheduler: {
          from: moment(SCHEDULER_START_DATE),
          to: moment(SCHEDULER_START_DATE).add(6, 'months'),
          list: [
            {
              id: 'textId',
              width: 80,
              type: 'identifier',
              header: {
                content: '#'
              }
            },
            {
              id: 'title',
              width: 200,
              type: 'vehicle',
              header: {
                content: 'Kendaraan'
              },
              vehicle: {
                licenseNumber: 'licenseNumber'
              }
            },
          ],
          rows: [],
          items: []
        },

        paramUser: {
            row: 10,
            keyword: "Driver",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        editPrice: {},
        itemIdToDelete: {},
        dataCustomer: [],
        dataId: null,
        dataProduct: [],
        dataVehicle: [],
        dataBrand: [],
        dataVendor: [],
        dataUser: [],
        startDate: null,


        barsData: [],
        vehicles: [],
        currentMonth: new Date().getMonth(),
        startMonth: '',
        endMonth: '',
        prevMonthButtonDisabled: false,
        nextMonthButtonDisabled: false,
        dates: [],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
      };
    },
    computed: {
    },
    methods: {
        onRowClick(row) {
          this.$router.push(`/admin-setting/vehicle-detail/${row.id}`);
        },

        onCellClick(cell) {
          this.dataId = cell.row.row.id
          this.startDate = cell.slot.moment.format('YYYY-MM-DDThh:mm')
        },

        onItemClick({ event, item }) {
          this.$refs.popupDetail.toggle(item.item.order)
          this.editData = { data: item.item.order }
        },

        onVehicleUpdated(data) {
          const newItems = this.scheduler.items.map((item) => {
            if (item.order.id === data.order.id) {
              return {
                ...item,
                rowId: data.order.vehicle_id
              }
            }

            return item
          })

          this.scheduler.items = newItems
        },

        setStartEndMonth() {
            this.startMonth = new Date();
            this.startMonth.setDate(1);
            this.endMonth = new Date(this.startMonth);
            this.endMonth.setMonth(this.endMonth.getMonth() + 1);
            this.endMonth.setDate(0);
        },
        prevMonth() {
          this.scheduler.from = moment(this.scheduler.from).subtract(1, 'months')
          this.scheduler.to = moment(this.scheduler.to).subtract(1, 'months')
        },
        nextMonth() {
            this.scheduler.from = moment(this.scheduler.from).add(1, 'months')
            this.scheduler.to = moment(this.scheduler.to).add(1, 'months')
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params)
                .then((resp) => {
                    this.dataProduct = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getCustomer() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("customer/getCustomer", this.params)
                .then((resp) => {
                    this.dataCustomer = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getUser() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("user/getUser", this.paramUser)
                .then((resp) => {
                    this.dataUser = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVehicle() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params)
                .then((resp) => {
                    this.dataVehicle = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },

        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params)
                .then(async (resp) => {
                    this.vehicles = resp.data.data.map((item, index) => ({
                        ...item,
                        index: index + 1,
                        order: item.orders.map((order, index) => ({
                            ...order,
                            index: index + 1,
                        })),
                    }));

                    this.scheduler.rows = resp.data.data.map((item, index) => {
                      return {
                        id: item.id,
                        textId: `#${index + 1}`,
                        title: item.name,
                        licenseNumber: item.nomor_plat
                      }
                    })

                    const items = []
                    for (const vehicle of resp.data.data) {
                      for (const order of vehicle.orders) {
                        const customer = await this.$store.dispatch('customer/getCustomerDetail', order.customer_id) 
                        const status = order.status.toLowerCase()
                        items.push({
                          rowId: vehicle.id,
                          label: order.no_order,
                          style: {
                            background: status === 'booking' ? '#35a7f4' : '#6DCC76' 
                          },
                          time: {
                            start: moment(order.starting_date),
                            end: moment(order.finish_date)
                          },
                          order,
                          customer: customer.data
                        }) 
                      }
                    }

                    this.scheduler.items = items

                    this.vehicles.forEach((vehicle) => {
                        vehicle.order.forEach((order) => {
                        if (order.status !== "Finish") {
                            let color = '';
                            if(order.status == "Proses") {
                                color = "green";
                            } else if (order.status == "Booking") {
                                color = "orange";
                            } else {
                                color = "blue";
                            }
                            this.barsData.push({
                                label: vehicle.name,
                                vehicleId: vehicle.id,
                                myBeginDate: new Date(order.starting_date),
                                myEndDate: new Date(order.finish_date),
                                ganttBarConfig : {
                                    id: order.id, // make sure this is unique!
                                    immobile: true,
                                    style: {
                                        // arbitrary CSS styling for your bar
                                        background: color,
                                        borderRadius: "20px",
                                        color: "black"
                                    }
                                }
                            });
                        }
                        });
                    });
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        goToLink(data) {
            console.log(data.vehicleId);
            this.$router.push(`/admin-setting/vehicle-detail/${data.vehicleId}`); // navigate to another view with a parameter
        },
    },
    mounted() {
        this.setStartEndMonth();
        this.getData();
        this.getProduct();
        this.getCustomer();
        this.getUser();
        this.getVehicle();
    },
  };
  </script>

<style>
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.button-container button:first-child {
  margin-right: auto;
}

.button-container button:last-child {
  margin-left: auto;
}
</style>

<style scoped>
.gantt-chart {
  width: 100%;
  height: 400px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: auto;
}

.gantt-chart .gantt-grid {
  background-color: #f5f5f5;
}

.gantt-chart .gantt-grid .gantt-grid-row {
  height: 30px;
}

.gantt-chart .gantt-grid .gantt-grid-row .gantt-grid-cell {
  border-right: 1px solid #e0e0e0;
}

.gantt-chart .gantt-grid .gantt-grid-row .gantt-grid-cell:last-child {
  border-right: none;
}

.gantt-chart .gantt-grid .gantt-grid-row .gantt-grid-cell .gantt-grid-label {
  font-size: 12px;
  color: #666;
}

.gantt-chart .gantt-bars {
  position: relative;
  height: 100%;
}

.gantt-chart .gantt-bars .gantt-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #4caf50;
}

.gantt-chart .gantt-bars .gantt-bar .gantt-bar-label {
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  font-size: 12px;
  color: #fff;
  background-color: #4caf50;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
