<template>
  <!-- Add Review Type -->
  <div class="modal fade" id="add-review-type">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Review Type</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-4">
              <label class="form-label">Reviews Type</label>
              <input type="text" class="form-control" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Review Type -->

  <!-- Edit Review Type -->
  <div class="modal fade" id="edit-review-type">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Review Type</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-4">
              <label class="form-label">Reviews Type</label>
              <input type="text" class="form-control" value="Excellent" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Review Type -->

  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Review Type ?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to delete this?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
