<template>
    <div class="main-wrapper">
        <layout></layout>
        <adminsidebar></adminsidebar>

        <div class="page-wrapper page-settings">

			<sidelinkheading />

			<div class="content w-100">
                <div class="content-page-header">
                    <h5>Email Configuration</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row location-set">
                                    <div class="col-lg-6">
                                        <div class="form-grouphead mb-2">
                                            <h2 class="fs-14">Profile Picture</h2>
                                        </div>
                                        <div class="nav-menus">
                                            <h4>Mail Provider</h4>
                                            <div class="settings-view">
                                                <a href="javascript:void(0);"><i class="feather feather-settings"></i></a>
                                                <div class="active-switch">
                                                    <label class="switch">
                                                        <input type="checkbox" checked="">
                                                        <span class="sliders round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-grouphead mb-2">
                                            <h2 class="fs-14">&nbsp;</h2>
                                        </div>
                                        <div class="nav-menus">
                                            <h4>SMTP</h4>
                                            <div class="settings-view">
                                                <a href="javascript:void(0);"><i class="feather feather-settings"></i></a>
                                                <div class="active-switch">
                                                    <label class="switch">
                                                        <input type="checkbox" checked="">
                                                        <span class="sliders round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="location-set">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6 col-12">
                                            <div class="location-setcontent">
                                                <h5>Email From Name</h5>
                                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <div class="form-group mb-0">
                                                <label>Name</label>
                                                <input type="text" class="form-control" placeholder="Enter From Name">
                                            </div>
                                        </div>
                                    </div>
                               </div>
                               <div class="location-set">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6 col-12">
                                            <div class="location-setcontent">
                                                <h5>Email From Address</h5>
                                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <div class="form-group mb-0">
                                                <label>Address</label>
                                                <input type="text" class="form-control" placeholder="Enter Address">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="location-set">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6 col-12">
                                            <div class="location-setcontent">
                                                <h5>Email Global Footer</h5>
                                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <div class="form-group mb-0">
                                                <label>Global Footer</label>
                                                <input type="text" class="form-control" placeholder="Enter Global Footer">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="form-groupheads ">
                                    <h2>Send Test Email</h2>
                                </div>
                                <div class="location-set">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6 col-12">
                                            <div class="location-setcontent">
                                                <h5>Timezone</h5>
                                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-12">
                                            <div class="form-group mb-0">
                                                <label>Timezone</label>
                                                <div class="group-image">
                                                    <img src="@/assets/admin_img/icons/clock.svg" alt="img">
                                                    <vue-select 
                                                        :options="Timezone"
                                                        placeholder="USD"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                               <div class="col-lg-12">
                                    <div class="btn-path">
                                        <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                                        <a href="javascript:void(0);" class="btn btn-primary">Send Email</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Timezone: ["USD", "INR"]
        }
    },
}
</script>