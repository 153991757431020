<template>
    <div class="content">
        <div class="container">

            <div class="row">

                <div class="col-lg-8 col-md-12">

                    <!-- Blog Post -->
                    <div class="blog blog-list">
                        <div class="blog-image">
                            <router-link to="/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-04.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <div class="blog-category">
                                <ul>
                                    <li><span class="cat-blog">Car Wash</span></li>
                                    <li><i class="feather-calendar me-1"></i>28 Sep 2023</li>
                                </ul>
                            </div>
                            <h3 class="blog-title"><router-link to="/blog-details">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                    veniam,</router-link></h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa aspernatur aut odit aut fugit, sed quia
                                consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <div class="blog-info">
                                <ul>
                                    <li>
                                        <div class="post-author">
                                            <a href="javascript:;"><img src="@/assets/img/profiles/avatar-02.jpg"
                                                    alt="Post Author"><span>Admin</span></a>
                                        </div>
                                    </li>
                                    <li><a href="javascript:;"><i class="feather-thumbs-up me-1"></i>25 Like(s)</a></li>
                                    <li><a href="javascript:;"><i class="feather-message-circle me-1"></i>20 Comment(s)</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /Blog Post -->

                    <!-- Blog Post -->
                    <div class="blog blog-list">
                        <div class="blog-image">
                            <router-link to="/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-07.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <div class="blog-category">
                                <ul>
                                    <li><span class="cat-blog">Construction</span></li>
                                    <li><i class="feather-calendar me-1"></i>28 Sep 2023</li>
                                </ul>
                            </div>
                            <h3 class="blog-title"><router-link to="/blog-details">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                    veniam,</router-link></h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa aspernatur aut odit aut fugit, sed quia
                                consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <div class="blog-info">
                                <ul>
                                    <li>
                                        <div class="post-author">
                                            <a href="javascript:;"><img src="@/assets/img/profiles/avatar-02.jpg"
                                                    alt="Post Author"><span>Admin</span></a>
                                        </div>
                                    </li>
                                    <li><a href="javascript:;"><i class="feather-thumbs-up me-1"></i>25 Like(s)</a></li>
                                    <li><a href="javascript:;"><i class="feather-message-circle me-1"></i>20 Comment(s)</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /Blog Post -->

                    <!-- Blog Post -->
                    <div class="blog blog-list">
                        <div class="blog-image">
                            <router-link to="/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-06.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <div class="blog-category">
                                <ul>
                                    <li><span class="cat-blog">Cleaning</span></li>
                                    <li><i class="feather-calendar me-1"></i>28 Sep 2023</li>
                                </ul>
                            </div>
                            <h3 class="blog-title"><router-link to="/blog-details">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                    veniam,</router-link></h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa aspernatur aut odit aut fugit, sed quia
                                consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <div class="blog-info">
                                <ul>
                                    <li>
                                        <div class="post-author">
                                            <a href="javascript:;"><img src="@/assets/img/profiles/avatar-02.jpg"
                                                    alt="Post Author"><span>Admin</span></a>
                                        </div>
                                    </li>
                                    <li><a href="javascript:;"><i class="feather-thumbs-up me-1"></i>25 Like(s)</a></li>
                                    <li><a href="javascript:;"><i class="feather-message-circle me-1"></i>20 Comment(s)</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /Blog Post -->

                    <!-- Blog Post -->
                    <div class="blog blog-list">
                        <div class="blog-image">
                            <router-link to="/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-08.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <div class="blog-category">
                                <ul>
                                    <li><span class="cat-blog">Carpentry</span></li>
                                    <li><i class="feather-calendar me-1"></i>28 Sep 2023</li>
                                </ul>
                            </div>
                            <h3 class="blog-title"><router-link to="/blog-details">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                    veniam,</router-link></h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                laudantium, totam rem aperiam, eaque ipsa aspernatur aut odit aut fugit, sed quia
                                consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                            <div class="blog-info">
                                <ul>
                                    <li>
                                        <div class="post-author">
                                            <a href="javascript:;"><img src="@/assets/img/profiles/avatar-02.jpg"
                                                    alt="Post Author"><span>Admin</span></a>
                                        </div>
                                    </li>
                                    <li><a href="javascript:void(0);"><i class="feather-thumbs-up me-1"></i>35 Like(s)</a>
                                    </li>
                                    <li><a href="javascript:;"><i class="feather-message-circle me-1"></i>10 Comment(s)</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /Blog Post -->

                    <div class="blog-pagination">
                        <nav>
                            <ul class="pagination justify-content-center">
                                <li class="page-item disabled">
                                    <router-link class="page-link page-prev" to="/#" tabindex="-1"><i
                                            class="fa-solid fa-arrow-left me-1"></i> PREV</router-link>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="javascript:;">1</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="javascript:;">2</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="javascript:;">3</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page-next" href="javascript:;">NEXT <i
                                        class="fa-solid fa-arrow-right ms-1"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>

                <!-- Blog Sidebar -->
                <div class="col-lg-4 col-md-12 blog-sidebar  theiaStickySidebar">
                    <div class="stickysidebar">

                    <!-- Search -->
                    <div class="card search-widget">
                        <div class="card-body">
                            <form class="search-form">
                                <div class="input-group">
                                    <input type="text" placeholder="Search your keyword" class="form-control" />
                                    <b-button type="submit" variant="primary"><i class="fa fa-search"></i></b-button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- /Search -->

                    <!-- Categories -->
                    <div class="card about-widget">
                        <div class="card-body">
                            <h4 class="side-title">About Me</h4>
                            <img src="@/assets/img/profile.jpg" alt="">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                            <a href="javascript:;" class="btn btn-primary">About Author</a>
                        </div>
                    </div>
                    <!-- /Categories -->

                    <!-- Categories -->
                    <div class="card category-widget">
                        <div class="card-body">
                            <h4 class="side-title">Categories</h4>
                            <ul class="categories">
                                <li><router-link to="/categories">Car Wash</router-link></li>
                                <li><router-link to="/categories">Plumbing</router-link></li>
                                <li><router-link to="/categories">Carpenter</router-link></li>
                                <li><router-link to="/categories">Computer Service</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <!-- /Categories -->

                    <!-- Latest Posts -->
                    <div class="card post-widget">
                        <div class="card-body">
                            <h4 class="side-title">Latest News</h4>
                            <ul class="latest-posts">
                                <li>
                                    <div class="post-thumb">
                                        <router-link to="/blog-details">
                                            <img class="img-fluid" src="@/assets/img/services/service-01.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="post-info">
                                        <p>06 Nov 2023</p>
                                        <h4>
                                            <router-link to="/blog-details">Lorem ipsum dolor amet, consectetur adipiscing elit.
                                                Amet.</router-link>
                                        </h4>
                                    </div>
                                </li>
                                <li>
                                    <div class="post-thumb">
                                        <router-link to="/blog-details">
                                            <img class="img-fluid" src="@/assets/img/services/service-02.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="post-info">
                                        <p>06 Nov 2023</p>
                                        <h4>
                                            <router-link to="/blog-details">Lorem ipsum dolor amet, consectetur adipiscing elit.
                                                Amet.</router-link>
                                        </h4>
                                    </div>
                                </li>
                                <li>
                                    <div class="post-thumb">
                                        <router-link to="/blog-details">
                                            <img class="img-fluid" src="@/assets/img/services/service-03.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="post-info">
                                        <p>06 Nov 2023</p>
                                        <h4>
                                            <router-link to="/blog-details">Lorem ipsum dolor amet, consectetur adipiscing elit.
                                                Amet.</router-link>
                                        </h4>
                                    </div>
                                </li>
                                <li>
                                    <div class="post-thumb">
                                        <router-link to="/blog-details">
                                            <img class="img-fluid" src="@/assets/img/services/service-04.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="post-info">
                                        <p>06 Nov 2023</p>
                                        <h4>
                                            <router-link to="/blog-details">Lorem ipsum dolor amet, consectetur adipiscing elit.
                                                Amet.</router-link>
                                        </h4>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- /Latest Posts -->

                    <!-- Tags -->
                    <div class="card tags-widget">
                        <div class="card-body">
                            <h4 class="side-title">Tags</h4>
                            <ul class="tags">
                                <li><a href="javascript:;" class="tag">Consulting</a></li>
                                <li><a href="javascript:;" class="tag">Design</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- /Tags -->

                    <!-- Archives -->
                    <div class="card widget widget-archive mb-0">
                        <div class="card-body">
                            <h4 class="side-title">Archives</h4>
                            <ul>
                                <li><a href="javascript:void(0);">January 2023</a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- /Archives -->

                </div>
                <!-- /Blog Sidebar -->
            </div>
            </div>
        </div>
    </div>
</template>