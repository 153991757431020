<template>
  <!-- Additional Services -->
  <section class="additional-service-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-nine aos" data-aos="fade-up">
            <p>TRUSTED & QUALITY SERVICE</p>
            <h2>Additional Services</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel service-nine-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexnineadditional" :key="item.id">
                <div
                  class="service-widget service-widget-space service-widget-nine service-widget-nine-two"
                >
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="item-info items-nine items-nine-two">
                      <img
                        :src="require(`@/assets/img/profiles/${item.avatar}`)"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="service-content service-feature-nine">
                    <span>25 Mechanics</span>
                    <h3 class="title">{{ item.Title }}</h3>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
      <div class="btn-sec btn-service-nine aos" data-aos="fade-up">
        <router-link to="/search" class="btn btn-primary btn-view"
          >VIEW ALL 255 SERVICES</router-link
        >
      </div>
    </div>
  </section>
  <!-- /Additional Services -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexnineadditional from "@/assets/json/indexnineadditional.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexnineadditional: indexnineadditional,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
