<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title"  :text="text" :text1="text1" />

            <privacypolicycontent />

            <truelyfooter />
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            title: "Privacy Policy",
            text: "Home",
            text1: "Privacy Policy",
            
        }
    },
}
</script>