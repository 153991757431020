<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Delete Account Request</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'User_Name'">
                      <td class="table-namesplit">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="require(`@/assets/admin_img/customer/${record.Image}`)"
                            class="me-2"
                            alt="img"
                          />
                        </a>
                        <a href="javascript:void(0);" class="table-name">
                          <span>{{ record.User_Name }}</span>
                          <p>{{ record.User_mail }}</p>
                        </a>
                      </td>
                    </template>
                    <template v-else-if="column.key === 'Action'">
                      <div class="table-actions d-flex align-items-center">
                        <a
                          href="javascript:void(0);"
                          :class="record.Class"
                          data-bs-toggle="modal"
                          data-bs-target="#delete-item"
                          >Confirm</a
                        >
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "User ID",
    dataIndex: "User_ID",
    sorter: {
      compare: (a, b) => {
        a = a.User_ID.toLowerCase();
        b = b.User_ID.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User Name",
    dataIndex: "User_Name",
    key: "User_Name",
    sorter: {
      compare: (a, b) => {
        a = a.User_Name.toLowerCase();
        b = b.User_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Requisition Date",
    dataIndex: "Requisition_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Requisition_Date.toLowerCase();
        b = b.Requisition_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Delete Request Date",
    dataIndex: "Delete_Request_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Delete_Request_Date.toLowerCase();
        b = b.Delete_Request_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action",
    class: "text-end",
    sorter: {
      compare: (a, b) => {
        a = a.Action.toLowerCase();
        b = b.Action.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    User_ID: "#2572",
    Image: "user-01.jpg",
    User_Name: "John Smith",
    User_mail: "johnsmith@gmail.com",
    Requisition_Date: "28 Sep 2023 16:43PM",
    Delete_Request_Date: "28 Sep 2023 16:43PM",
    Class: "btn badge-active",
    Action: "Confirm",
  },
  {
    User_ID: "#4572",
    Image: "user-07.jpg",
    User_Name: "Johnny",
    User_mail: "johnny@gmail.com",
    Requisition_Date: "14 Sep 2023 16:43PM",
    Delete_Request_Date: "16 Sep 2023 16:43PM",
    Class: "btn badge-active",
    Action: "Confirm",
  },
  {
    User_ID: "#5324",
    Image: "user-09.jpg",
    User_Name: "Robert",
    User_mail: "robert@gmail.com",
    Requisition_Date: "04 Sep 2023 16:43PM",
    Delete_Request_Date: "06 Sep 2023 16:43PM",
    Class: "btn badge-active",
    Action: "Confirm",
  },
  {
    User_ID: "#54356",
    Image: "user-04.jpg",
    User_Name: "Nicholas",
    User_mail: "nicholas@gmail.com",
    Requisition_Date: "23 Sep 2023 16:43PM",
    Delete_Request_Date: "27 Sep 2023 16:43PM",
    Class: "btn badge-active",
    Action: "Confirm",
  },
  {
    User_ID: "#7651",
    Image: "user-06.jpg",
    User_Name: "Sharonda",
    User_mail: "sharonda@gmail.com",
    Requisition_Date: "14 Sep 2023 16:43PM",
    Delete_Request_Date: "17 Sep 2023 16:43PM",
    Class: "btn badge-active",
    Action: "Confirm",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
