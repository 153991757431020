<template>
    <div class="home-page-three">
        <div class="main-wrapper">
            <indexthreeheader />

            <div class="page-wrapper">
                <indexthreebanner />

                <indexthreeappointment />

                <indexthreeservice />

                <indexthreework />

                <indexthreelatest />

                <indexthreefooter />

                
            </div>
        </div>
    </div>
</template>