<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
            <h5>Bantuan</h5>
          <div class="row">
            <div class="col-12 text-center">
                <div class="card">
                    <div class="card-body" style="background-color: #358ef1">
                        <h5>BUTUH BANTUAN ?</h5>
                        <h4>08xxxxxxxx</h4>
                        <p>Full akses ke semua fitur di aplikasi dan perubahan/penambahan fitur yang disepakati.</p>
                        <p>Minimal berlangganan 1 Tahun</p>
                        <p>Hubungi kami untuk harga berlangganan</p>
                        <button type="submit" class="btn btn-primary">Hubungi Kami</button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        
      };
    },
    name: "subscribesetting",
    computed: {

    },
    methods: {

    },
    mounted() {

    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>