<template>
  <div class="main-wrapper">
    <layouts></layouts>

    <div class="page-wrapper">
      <provider-header :title="title" />

      <providerlistcontent />

      <truelyfooter />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Providers",
    };
  },
};
</script>
