<template>
  <!--change email-modal -->
  <div
    class="modal fade"
    id="add-faq"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Create FAQ’s</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Title</label>
                <input type="text" class="form-control" placeholder="Enter FAQ Title" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Category</label>
                <vue-select
                  :options="Category"
                  id="faqcate"
                  placeholder="Select Category"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Current Password</label>
                <textarea
                  class="form-control"
                  placeholder="Enter Your Details"
                ></textarea>
              </div>
            </div>
            <div class="col-12">
              <div class="form-groupheads d-flex d-flex justify-content-between">
                <h2>Status</h2>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <b-button type="button" variant="primary" data-bs-dismiss="modal"
            >Submit</b-button
          >
          <b-button type="button" variant="secondary" data-bs-dismiss="modal"
            >Close</b-button
          >
        </div>
      </div>
    </div>
  </div>
  <!--change email-modal -->
  <div
    class="modal fade"
    id="edit-faq"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit FAQ’s</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Title</label>
                <input
                  type="text"
                  class="form-control"
                  value="How Can I Book Service? "
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Category</label>
                <vue-select :options="Service" id="faqservice" placeholder="Services" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Current Password</label>
                <textarea
                  class="form-control"
                  placeholder="Enter Your Details"
                ></textarea>
              </div>
            </div>
            <div class="col-12">
              <div class="form-groupheads d-flex d-flex justify-content-between">
                <h2>Status</h2>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <b-button type="button" variant="primary" data-bs-dismiss="modal"
            >Submit</b-button
          >
          <b-button type="button" variant="secondary" data-bs-dismiss="modal"
            >Close</b-button
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Delete -->
  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Faq's</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to Delete?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete -->
</template>
<script>
export default {
  data() {
    return {
      Category: ["Select Category", "Category 1", "Category 2"],
      Service: ["Services"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/faq");
    },
  },
};
</script>
