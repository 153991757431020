<template>
  <!-- Hero Section -->
  <section class="hero-section-seven">
    <div class="hero-sectionseven-top">
      <div class="container">
        <div class="home-banner homer-banner-seven">
          <div class="row align-items-center w-100">
            <div class="col-lg-6 col-12">
              <div class="section-search aos" data-aos="fade-up">
                <p>Search From 150 Awesome Verified Ads!</p>
                <h1>
                  Best Solution for Every
                  <span>House Problems</span>
                </h1>
                <div class="solution-seven">
                  <h6>2M+ Professionals registered</h6>
                  <ul class="total-client-avatar total-client-avatar-seven">
                    <li>
                      <a href="javascript:;">
                        <img src="@/assets/img/profiles/avatar-06.jpg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;">
                        <img src="@/assets/img/profiles/avatar-07.jpg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;">
                        <img src="@/assets/img/profiles/avatar-08.jpg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;">
                        <img src="@/assets/img/profiles/avatar-09.jpg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;">
                        <img src="@/assets/img/profiles/avatar-10.jpg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="hero-banner-ryt">
                <img
                  src="@/assets/img/hero-section-seven-ryt.png"
                  alt=""
                  class="img-fluid"
                />
                <div class="hero-banner-ryt-content">
                  <div class="hero-banner-ryt-top">
                    <h5>+21 k</h5>
                    <p>Services Completed</p>
                  </div>
                  <span>
                    <img src="@/assets/img/icons/suitcase.svg" alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Hero Section -->

  <!-- Service Section -->
  <div class="search-box-two search-box-seven">
    <form @submit.prevent="submitForm">
      <div class="search-input-new line">
        <i class="feather-tv bficon"></i>
        <div class="form-group mb-0">
          <input
            type="text"
            class="form-control"
            placeholder="What are you looking for?"
          />
        </div>
      </div>
      <div class="search-input-new">
        <i class="fas fa-location-arrow bficon"></i>
        <div class="form-group mb-0">
          <input type="text" class="form-control" placeholder="Your Location" />
          <a class="current-loc-icon current_location" href="javascript:void(0);"></a>
        </div>
      </div>
      <div class="search-btn">
        <button class="btn search_service" type="submit">
          <i class="feather-search"></i> Search
        </button>
      </div>
    </form>
  </div>
  <div class="popularsearch-top">
    <h6>Popular Searches</h6>
    <ul>
      <li>Electrical Works</li>
      <li>Cleaning</li>
      <li>AC Repair</li>
    </ul>
  </div>
  <section class="service-section-seven">
    <div class="container">
      <div class="section-heading section-heading-seven">
        <div class="row">
          <div class="col-md-6 aos" data-aos="fade-up">
            <h2>Featured Categories</h2>
            <p>What do you need to find?</p>
          </div>
          <div class="col-md-6 text-md-end aos" data-aos="fade-up">
            <div class="owl-nav mynav mynav-seven"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel categories-slider-seven">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexsevenfeatured" :key="item.id">
                <router-link
                  to="/service-details"
                  class="feature-box feature-box-seven aos"
                  data-aos="fade-up"
                >
                  <div class="feature-icon feature-icon-seven">
                    <span>
                      <img
                        :src="require(`@/assets/img/icons/${item.image}`)"
                        alt="img"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                  <h5>{{ item.title }}</h5>
                  <div class="feature-overlay">
                    <img :src="require(`@/assets/img/services/${item.img}`)" alt="img" />
                  </div>
                </router-link>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Service Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexsevenfeatured from "@/assets/json/indexsevenfeatured.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexsevenfeatured: indexsevenfeatured,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
  },
};
</script>
