<template>
  <div class="login-body">
    <div class="main-wrapper">
      <otpheader />

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-6 mx-auto">
                <div class="login-wrap">
                  <div class="login-header">
                    <h3>Provider Signup</h3>
                  </div>

                  <!-- Signup Form -->
                  <form @submit.prevent="submitForm">
                    <div class="form-group">
                      <label class="col-form-label">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">E-mail</label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="example@email.com"
                      />
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="col-form-label">Phone Number</label>
                        <div class="form-group">
                          <vue-tel-input
                            v-model="phone"
                            mode="international"
                            class="form-control form-control-lg group_formcontrol"
                            id="phone"
                            name="phone"
                            placeholder="(256) 789-6253"
                          ></vue-tel-input>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label d-block"
                        >Password<span class="brief-bio float-end"
                          >Must be 8 Characters at Least</span
                        ></label
                      >
                      <div class="pass-group">
                        <input
                          v-if="showPassword"
                          type="text"
                          class="form-control pass-input"
                          v-model="password"
                          placeholder="**********"
                        />
                        <input
                          v-else
                          type="password"
                          class="form-control pass-input"
                          placeholder="**********"
                          v-model="password"
                        />
                        <span
                          class="toggle-password"
                          @click="toggleShow"
                          :class="{
                            'feather-eye': showPassword,
                            'feather-eye-off': !showPassword,
                          }"
                        ></span>
                      </div>
                    </div>

                    <b-button type="submit" variant="primary w-100 login-btn"
                      >Submit</b-button
                    >
                    <div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">Or, log in with your email</span>
                    </div>
                    <div class="social-login">
                      <a href="javascript:;" class="btn btn-google w-100"
                        ><img
                          src="@/assets/img/icons/google.svg"
                          class="me-2"
                          alt="img"
                        />Log in with Google</a
                      >
                      <a href="javascript:;" class="btn btn-google w-100"
                        ><img src="@/assets/img/icons/fb.svg" class="me-2" alt="img" />Log
                        in with Facebook</a
                      >
                    </div>
                    <p class="no-acc">
                      Already have an account ? <a href="login.html"> Sign In</a>
                    </p>
                  </form>
                  <!-- /Signup Form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
      phone: "123-456-7890",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    submitForm() {
      this.$router.push("/provider-dashboard");
    },
  },
};
</script>
