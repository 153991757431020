<template>
  <!-- Add Sub Category -->
  <div class="modal fade" id="add-subcategory">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Sub Category</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Parent Category</label>

              <vue-select
                :options="ParentCategory"
                id="parentcategory"
                placeholder="Select Category"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Sub Category</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >Sub Category Slug
                <small class="form-text text-muted">(Ex:test-slug)</small></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="mb-4">
              <label class="form-label">Sub Category Image</label>
              <div class="form-uploads">
                <div class="form-uploads-path">
                  <img src="@/assets/admin_img/icons/upload.svg" alt="img" />
                  <div class="file-browse">
                    <h6>Drag & drop image or</h6>
                    <div class="file-browse-path">
                      <input type="file" />
                      <a href="javascript:void(0);"> Browse</a>
                    </div>
                  </div>
                  <h5>Supported formates: JPEG, PNG</h5>
                </div>
              </div>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Sub Category -->

  <!-- Edit Sub Category -->
  <div class="modal fade" id="edit-subcategory">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Sub Category</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Parent Category</label>

              <vue-select
                :options="ParentCategory1"
                id="parentcategory1"
                placeholder="Car Wash"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Sub Category</label>
              <input type="text" class="form-control" value="Polish" />
            </div>
            <div class="mb-3">
              <label class="form-label"
                >Sub Category Slug
                <small class="form-text text-muted">(Ex:test-slug)</small></label
              >
              <input type="text" class="form-control" value="polish" />
            </div>
            <div class="mb-4">
              <label class="form-label">Sub Category Image</label>
              <div class="form-uploads">
                <div class="form-uploads-path">
                  <img src="@/assets/admin_img/icons/upload.svg" alt="img" />
                  <div class="file-browse">
                    <h6>Drag & drop image or</h6>
                    <div class="file-browse-path">
                      <input type="file" />
                      <a href="javascript:void(0);"> Browse</a>
                    </div>
                  </div>
                  <h5>Supported formates: JPEG, PNG</h5>
                </div>
              </div>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Sub Category -->

  <!-- Delete Sub Category -->
  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Sub Category ?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to delete this?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Sub Category -->
</template>
<script>
export default {
  data() {
    return {
      ParentCategory: ["Select Category", "Construction", "Car Wash", "Computer"],
      ParentCategory1: ["Select Category", "Construction", "Car Wash", "Computer"],
    };
  },
};
</script>
