<template>
	<div class="main-wrapper">
		<mari-layout></mari-layout>
		<div class="page-wrapper background" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
			<div class="content">		
				<div class="container">			
					<div class="section-heading">
						<div class="row">
							<div class="col-md-6 aos" data-aos="fade-up">
								<h4>Finance</h4>
                <p>Kamu bisa melihat keuangan perusahaan disni!</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-lg-3" v-for="(item, index) in menuItems" :key="index">
              <router-link :to="item.route" class="feature-box aos" data-aos="fade-up">
                <div class="feature-icon">
                  <span>
                    <!-- Menggunakan interpolasi string untuk membuat nama file gambar dinamis -->
                    <img :src="require(`@/assets/img/mari/menu/${item.icon}.svg`)" alt="img">
                  </span>
                </div>
                <h5>{{ item.title }}</h5>
                <div class="feature-overlay">
                  <img src="@/assets/img/mari/menu/slide.svg" alt="img">
                </div>
              </router-link>
            </div>
					</div>
				</div>
			</div>
		</div>
    <mari-footer></mari-footer>
	</div>
</template>
  
  <script>
  export default {
    data() {
      return {
        menuItems: [
        // { title: 'Denda', icon: 'denda', route: '/mari/denda' },
        { title: 'Pengeluaran', icon: 'Pengeluaran', route: '/mari/pengeluaran' },
        { title: 'Pelunasan Order', icon: 'Pengeluaran', route: '/mari/pelunasan' },
        { title: 'Keuangan', icon: 'keuangan', route: '/mari/keuangan' },
        { title: 'Rekap', icon: 'keuangan', route: '/mari/recap-debit-credit' },
        { title: 'Invoice Vendor', icon: 'Vendor', route: '/mari/finance-vendor' },
        { title: 'List Report Driver', icon: 'Vendor', route: '/mari/report-driver' },
        { title: 'Invoice Pelanggan', icon: 'Vendor', route: '/mari/invoice' }
        ],
        backgroundImage: require(`@/assets/img/mari/background.svg`)
      };
    },
    mounted() {
        
    },
    methods: {
     
    },
  };
  </script>


<style scoped>
  .feature-icon span {
    background: #f7f7ff00;
  }

  .background {
    /* Full height */
    height: 100%; 

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
