<template>
  <!-- Add Role -->
  <div
    class="modal fade"
    id="add-role"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Role</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <b-form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Role Name <span class="manitory">*</span></label>
                  <b-form-input
                    type="text"
                    class="form-control"
                    placeholder="Enter Role Name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <b-button type="submit" variant="primary" data-bs-dismiss="modal"
              >Submit</b-button
            >
            <b-button type="button" variant="secondary" data-bs-dismiss="modal"
              >Close</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
  <!-- /Add Role -->

  <!-- Edit Role -->
  <div
    class="modal fade"
    id="edit-role"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Role</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <b-form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Role Name <span class="manitory">*</span></label>
                  <b-form-input
                    type="text"
                    class="form-control"
                    placeholder="Enter Role Name"
                    value="Admin"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <b-button type="submit" variant="primary" data-bs-dismiss="modal"
              >Submit</b-button
            >
            <b-button type="button" variant="secondary" data-bs-dismiss="modal"
              >Close</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
  <!-- /Edit Role -->

  <!-- Delete -->
  <div
    class="modal fade"
    id="delete-item"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Roles Permission</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <b-form @submit.prevent="submitFormOne">
          <div class="modal-body py-0">
            <div class="del-modal">
              <p>Are you sure want to Delete?</p>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <b-button type="button" variant="secondary" data-bs-dismiss="modal"
              >Cancel</b-button
            >
            <b-button type="submit" variant="primary" data-bs-dismiss="modal"
              >Yes</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
  <!-- /Delete -->
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/roles");
    },
    submitFormOne() {
      this.$router.push("/admin/roles");
    },
  },
};
</script>
