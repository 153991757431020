<template>
  <div class="main-wrapper">
    <custombookcalendarnav />

    <!-- Breadcrumb -->
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <!-- /Breadcrumb -->
    <div class="content-notify content">
      <div class="container">
        <!-- Contact Details -->
        <div class="notification-details notify-content-swap">
          <div class="row">
            <div class="col-lg-10 mx-auto">
              <div
                class="notification-wrap-date d-flex align-items-center justify-content-between"
              >
                <div class="search-input search-calendar-line">
                  <i class="feather-calendar"></i>
                  <div class="mb-0">
                    <datepicker
                      v-model="startdate"
                      :input-format="dateFormat"
                      class="form-control datetimepicker"
                      placeholder="09-04-2023"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>

                <div class="noti-read-delete d-flex align-items-center">
                  <div class="notification-read">
                    <a href="javascript:;" class="mark-all">
                      <i class="feather-check me-2"></i> Mark all as read</a
                    >
                  </div>
                  <div class="notification-delete-content">
                    <a href="javascript:;">
                      <i class="feather-trash-2 me-2"></i>
                      Delete all
                    </a>
                  </div>
                </div>
              </div>

              <div class="noti-content">
                <ul class="notification-list">
                  <li class="notification-message">
                    <div
                      class="notification-delete-wrap d-flex align-items-center justify-content-between"
                    >
                      <div class="media noti-img d-flex">
                        <span class="avatar avatar-sm flex-shrink-0">
                          <img
                            class="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="@/assets/img/notifications/avatar-01.jpg"
                          />
                        </span>
                        <div class="media-body flex-grow-1">
                          <router-link to="notification"
                            ><p class="noti-details">
                              Lex Murphy left 6 comments on Isla Nublar SOC2 compliance
                              report
                            </p></router-link
                          >
                          <p class="noti-time">
                            <span class="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                      <div class="notification-delete d-flex align-items-center">
                        <div class="notification-dropdown">
                          <a href="javascript:;" class="d-flex" data-bs-toggle="dropdown"
                            ><i class="feather-more-vertical"></i
                          ></a>
                          <div class="dropdown-menu">
                            <a href="javascript:;" class="dropdown-item"
                              ><i class="far fa-check-circle me-1"></i> Mark as read</a
                            >
                          </div>
                        </div>
                        <a href="javascript:void(0);" class="btn-acc">Delete</a>
                      </div>
                    </div>
                  </li>
                  <li class="notification-message">
                    <div
                      class="notification-delete-wrap d-flex align-items-center justify-content-between"
                    >
                      <div class="media noti-img d-flex">
                        <router-link to="notification">
                          <span class="avatar avatar-sm flex-shrink-0">
                            <img
                              class="avatar-img rounded-circle img-fluid"
                              alt="User Image"
                              src="@/assets/img/notifications/avatar-02.jpg"
                            />
                          </span>
                        </router-link>
                        <div class="media-body flex-grow-1">
                          <router-link to="notification"
                            ><p class="noti-details">
                              Ray Arnold requested access to UNIX directory tree hierarchy
                            </p></router-link
                          >
                          <p class="noti-time">
                            <span class="notification-time">1 min ago</span>
                          </p>
                          <div class="notify-btns">
                            <button class="btn btn-secondary">Decline</button>
                            <button class="btn btn-primary">Accept</button>
                          </div>
                        </div>
                      </div>
                      <div class="notification-delete d-flex align-items-center">
                        <div class="notification-dropdown">
                          <a href="javascript:;" class="d-flex" data-bs-toggle="dropdown"
                            ><i class="feather-more-vertical"></i
                          ></a>
                          <div class="dropdown-menu">
                            <a href="javascript:;" class="dropdown-item"
                              ><i class="far fa-check-circle me-1"></i> Mark as read</a
                            >
                          </div>
                        </div>
                        <a href="javascript:void(0);" class="btn-acc">Delete</a>
                      </div>
                    </div>
                  </li>
                  <li class="notification-message">
                    <div
                      class="notification-delete-wrap d-flex align-items-center justify-content-between"
                    >
                      <div class="media noti-img d-flex">
                        <span class="avatar avatar-sm flex-shrink-0">
                          <img
                            class="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="@/assets/img/notifications/avatar-04.jpg"
                          />
                        </span>
                        <div class="media-body flex-grow-1">
                          <router-link to="notification"
                            ><p class="noti-details">
                              John Hammond created Isla Nublar SOC2 compliance report
                            </p></router-link
                          >
                          <p class="noti-time">
                            <span class="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                      <div class="notification-delete d-flex align-items-center">
                        <div class="notification-dropdown">
                          <a href="javascript:;" class="d-flex" data-bs-toggle="dropdown"
                            ><i class="feather-more-vertical"></i
                          ></a>
                          <div class="dropdown-menu">
                            <a href="javascript:;" class="dropdown-item"
                              ><i class="far fa-check-circle me-1"></i> Mark as read</a
                            >
                          </div>
                        </div>
                        <a href="javascript:void(0);" class="btn-acc">Delete</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Contact Details -->
      </div>
    </div>
    <truelyfooter />
  </div>
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      title: "Notificationss",
      text: "Home",
      text1: "Notifications",
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
    };
  },
};
</script>
