<template>
  <!-- Services Section -->
  <section class="services-section">
    <div class="container">
      <div class="services-header aos" data-aos="fade-up">
        <div class="row">
          <div class="col-md-12">
            <div class="section-content">
              <h2>Featured Stylists</h2>
              <div class="our-img-all">
                <img src="@/assets/img/icons/scissor.svg" alt="" />
              </div>
              <p>
                Our Barbershop & Tattoo Salon provides classic services combined with
                innovative techniques.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel stylists-slider aos" data-aos="fade-up">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexthreestylists" :key="item.id">
                <div class="stylists-all">
                  <div class="stylists-main-img">
                    <a href="javascript:;">
                      <img
                        class="img-fluid serv-image"
                        alt="Service Image"
                        :src="require(`@/assets/img/profiles/${item.image}`)"
                      />
                    </a>
                  </div>
                  <div class="stylists-bottom">
                    <div class="stylists-foot">
                      <h4>{{ item.name }}</h4>
                      <h6>{{ item.booking }}</h6>
                      <div class="stylists-rating">
                        <div class="rating">
                          <i class="fa-solid fa-star filled me-1"></i>
                          <i class="fa-solid fa-star filled me-1"></i>
                          <i class="fa-solid fa-star filled me-1"></i>
                          <i class="fa-solid fa-star filled me-1"></i>
                          <i class="fa-solid fa-star filled me-1"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /service Section -->

  <!-- Service Section -->
  <section class="service-section populars-section">
    <div class="container">
      <div class="services-header aos" data-aos="fade-up">
        <div class="row">
          <div class="col-md-12">
            <div class="section-content">
              <h2>Popular Locations</h2>
              <div class="our-img-all">
                <img src="@/assets/img/icons/scissor.svg" alt="" />
              </div>
              <p>
                Our Barbershop & Tattoo Salon provides classic services combined with
                innovative techniques.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row aos" data-aos="fade-up">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="service-slider">
            <div class="service-widget">
              <div class="service-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-30.jpg"
                  />
                </router-link>
              </div>
              <div class="service-content popular-content">
                <h3>USA</h3>
                <h6>49 Saloons</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="service-slider">
            <div class="service-widget">
              <div class="service-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-31.jpg"
                  />
                </router-link>
              </div>
              <div class="service-content popular-content">
                <h3>UK</h3>
                <h6>49 Saloons</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="service-slider">
            <div class="service-widget">
              <div class="service-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-32.jpg"
                  />
                </router-link>
              </div>
              <div class="service-content popular-content">
                <h3>Mexico</h3>
                <h6>49 Saloons</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="service-slider">
            <div class="service-widget">
              <div class="service-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-33.jpg"
                  />
                </router-link>
              </div>
              <div class="service-content popular-content">
                <h3>UAE</h3>
                <h6>49 Saloons</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="service-slider">
            <div class="service-widget">
              <div class="service-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-34.png"
                  />
                </router-link>
              </div>
              <div class="service-content popular-content">
                <h3>France</h3>
                <h6>49 Saloons</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="service-slider">
            <div class="service-widget">
              <div class="service-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-35.png"
                  />
                </router-link>
              </div>
              <div class="service-content popular-content">
                <h3>Germany</h3>
                <h6>49 Saloons</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="service-slider">
            <div class="service-widget">
              <div class="service-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-36.jpg"
                  />
                </router-link>
              </div>
              <div class="service-content popular-content">
                <h3>Italy</h3>
                <h6>49 Saloons</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="service-slider">
            <div class="service-widget">
              <div class="service-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-37.jpg"
                  />
                </router-link>
              </div>
              <div class="service-content popular-content">
                <h3>Argentina</h3>
                <h6>49 Saloons</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-sec btn-saloons aos" data-aos="fade-up">
          <router-link to="/search" class="btn btn-primary btn-view"
            >VIEW ALL 590 LOCATION</router-link
          >
        </div>
      </div>
    </div>
  </section>
  <!-- /Service Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexthreestylists from "@/assets/json/indexthreestylists.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexthreestylists: indexthreestylists,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style>
.stylists-slider .stylists-all {
  margin-right: 24px;
}
</style>
