<template>
    <div class="card add-service">
        <div class="row mt-4">
            <div class="col-12">
                <div class="col-12">
                    <div class="col-6">
                        <div class="mb-3">
                            <div class="profile-upload-content">
        
                                <!-- company logo here -->
                                <img v-if="(data !== undefined) && (data.logo !== '')" :src="data.logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                <!-- company logo here -->
        
                                <!-- upload file form logo here -->
                                <div class="profile-upload-btn">
                                    <div class="profile-upload-file">
                                        <input
                                            type="file"
                                            class="form-control"
                                            ref="uploadFieldAttachment"
                                            id="imgInp"
                                            @change="selectFileAttachment"
                                        />
                                    </div>
                                </div>
                                    <!-- upload file form logo here -->
                                <div class="profile-upload-para">
                                    <p style="font-size: 12px;">
                                    <span style="color: red;">*</span>ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                                    File yang diizinkan .png dan .jpg.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nama Perusahaan<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="text" class="form-control" v-model="data.name"/>
                        </div>
                        <div>
                            <label class="form-label">Alamat<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="text" class="form-control" v-model="data.address"/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Nomer Telepon (WA)<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="text" class="form-control" v-model="data.phone_number"/>
                        </div>
                        <div>
                            <label class="form-label">Nama Pemilik<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="text" class="form-control" v-model="data.owner_name"/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div style="margin-right: 10px;">
                            <label class="form-label">Tanggal Bergabung<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="datetime-local" class="form-control" v-model="data.join_date"/>
                        </div>
                        <div>
                            <label class="form-label">Tanggal Pembayaran<span style="color: red;">*</span></label>
                            <input v-if="data !== undefined" type="datetime-local" class="form-control" v-model="data.payment_date"/>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="col-6">
                                <div class="col-lg-12">
                                    <div class="split-check-title">
                                        <label class="form-label">Jeni Pembayaran<span style="color: red;">*</span></label>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-check form-check-inline">
                                        <input v-if="data !== undefined" class="form-check-input" type="radio" id="inlineRadio1" :checked="data.payment_type === 1" name="payment_type" value=1 v-model="data.payment_type">
                                        <label class="form-check-label" for="inlineRadio1">Cash</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="data !== undefined" class="form-check-input" type="radio" id="inlineRadio2" :checked="data.payment_type === 2" name="payment_type" value=2 v-model="data.payment_type">
                                        <label class="form-check-label" for="inlineRadio2">Termin</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="form-label">Tentang Perusahaan</label>
                                <input v-if="data !== undefined" type="text" class="form-control" v-model="data.about"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="field-bottom-btns">
                    <div class="field-btns">
                        <button class="btn btn-primary next_btn" 
                        type="button"
                        @click="save"
                    :disabled="currentStep === totalSteps">Selanjutnya <i
                                class="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        // mari
        data: {},
        logo: ''
      };
    },
    props: {
        detailCompany : Object,
        currentStep : Object,
        totalSteps : Object,
    },
    watch: { 
        detailCompany: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.data = newValue;
                }
            }
        }
    },
    methods: {
        validateFile(file, allowedFileTypes, maxFileSize) {
            if (!allowedFileTypes.includes(file.type)) {
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
                });
                return false;
            }

            if (file.size > maxFileSize) {
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
                });
                return false;
            }

            return true;
        },
        // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      save() {
        this.data.logo = this.data.logo !== '' ? this.data.logo : this.logo;
        this.data.payment_value = 0;
        const id = this.data.id;
        var loading = this.$loading.show()
        this.$store
        .dispatch("company/updateCompany", { id: id, data: this.data })
        .then((data) => {
            if (data.status == 200) {
                this.updateGuide();
                loading.hide()
                this.data = {};
                this.logo = '';
                this.$emit('nextProfile');
            }
        },
        (error) => {
            console.log(error)
            loading.hide();
        })
        .catch((e) => {
            loading.hide()
        })
      },
      updateGuide() {
        this.$store
        .dispatch("guide/updateGuide", { id: this.data.id, data: { guide_step : "is_profile"} })
        .then((data) => {
            if (data.status == 200) {
                // loading.hide()
            }
        },
        (error) => {
            console.log(error)
            // loading.hide();
        })
        .catch((e) => {
            // loading.hide()
        })
      }
    },
  };
  </script>
  