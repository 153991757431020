<template>
<div class="modal fade" id="detail-debit-credit" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail debit/credit</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <div v-if="detail && detail.invoice" class="mt-3">
              <p>Pemasukan dari {{ detail.invoice.no_invoice }}</p>
              <p>Customer {{ detail.invoice.customer.name }}</p>
              <p class="fw-bold text-uppercase mb-0"><small>Pembayaran</small></p>
              <ul class="list-group">
                  <li v-for="line of detail.invoice.invoice_lines" :key="line.id" class="list-group-item">
                    <div class="d-flex w-100 justify-content-between">
                      <h6>{{ formatCurrency(line.line_total) }}</h6>
                      <small>{{ formatDate(line.created_at) }}</small>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <p class="mb-1">{{ line.remark }}</p>
                        <span v-if="!line.is_approved" style="color:red">*Belum di approve</span>
                      </div>
                    </div>
                  </li>
                </ul>
              <p class="fw-bold text-uppercase mt-3 mb-0"><small>Orders</small></p>
              <ul class="list-group">
                <li v-for="ov of detail.invoice.invoice_products" :key="ov.id" class="list-group-item">
                  {{ ov.order.no_order }}
                </li>
              </ul>
            </div>
            <div v-if="detail && detail.expenditure" class="mt-3">
              <p>Pengeluaran {{ detail.expenditure.name }} pada tanggal {{ formatDate(detail.expenditure.date) }} sebesar {{ formatCurrency(detail.expenditure.nominal) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
      return {
        detail: null
      }
    },
    props: {
        detailData: Object
    },
    watch: {
        detailData: {
            immediate: true,
            handler(newValue) {
              if (newValue) {
                  this.detail = newValue;
                  console.log('detail', this.detail)
              }
            }
        }
    },
    methods: {
      formatDate(date) {
        return moment(date).format('DD MMM YYYY')
      },
      formatCurrency(value) {
        if (!value && value !== 0) return '';
        // Convert value to a string
        let stringValue = value.toString();
        // Split the string into integer and decimal parts
        let parts = stringValue.split('.');
        // Format the integer part with thousand separators
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        // Join the parts and return the formatted value
        return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
      },
    }
}
</script>