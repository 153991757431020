<template>
  <div
    class="modal fade add-service-modal"
    id="successmodal"
    tabindex="-1"
    aria-labelledby="successmodal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <span>
            <i class="fa-regular fa-circle-check"></i>
          </span>
          <h3>Success</h3>
          <p>Service has been created succeessfully</p>
          <div class="popup-btn">
            <router-link
              to="provider-services"
              @click="redirectReloadProvider"
              class="btn btn-primary"
              >Go to Dashboard<i class="fa-solid fa-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete Account -->
  <div class="modal fade custom-modal" id="del-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sureyou want to delete This Account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye-off"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <button type="submit" class="btn btn-danger">Delete Account</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->

  <!-- Change Phone  -->
  <div class="modal fade custom-modal verify-modal" id="change-phone">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header verfication-modal-head">
          <h5 class="modal-title">Verify Your Phone Number</h5>
          <p>You will receive a 4 digit code to verify next</p>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitFormOne">
            <div class="wallet-add">
              <div class="form-group">
                <input
                  class="form-control"
                  name="phone"
                  type="text"
                  placeholder="Enter Phone Number"
                />
              </div>
              <div class="modal-submit">
                <a
                  href="javascript:;"
                  class="btn btn-primary w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#otp"
                  >Change Number</a
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Change Phone  -->

  <!-- OTP  -->
  <div class="modal fade custom-modal verify-modal" id="otp">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header verfication-modal-head">
          <h5 class="modal-title">Enter OTP</h5>
          <p>Code is sent to +1 23456789</p>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitFormOne" class="digit-group">
            <div class="wallet-add">
              <div class="otp-box">
                <div class="forms-block text-center">
                  <input
                    type="text"
                    id="digit-1"
                    name="digit-1"
                    data-next="digit-2"
                    maxlength="1"
                    value="1"
                  />
                  <input
                    type="text"
                    id="digit-2"
                    name="digit-2"
                    data-next="digit-3"
                    data-previous="digit-1"
                    maxlength="1"
                    value="2"
                  />
                  <input
                    type="text"
                    id="digit-3"
                    name="digit-3"
                    data-next="digit-4"
                    data-previous="digit-2"
                    maxlength="1"
                    value="3"
                  />
                  <input
                    type="text"
                    id="digit-4"
                    name="digit-4"
                    data-next="digit-5"
                    data-previous="digit-3"
                    maxlength="1"
                    value="4"
                  />
                </div>
              </div>
              <div class="otp-timer text-center">
                <h6>00:29</h6>
              </div>
              <div class="resend-code-otp text-center">
                <p>
                  Don’t <span> receive </span> code?
                  <a href="javascript:;"> Resend Code </a>
                </p>
              </div>
              <div class="modal-submit">
                <a
                  href="javascript:;"
                  class="btn btn-primary w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#success"
                  >Verify</a
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- OTP  -->

  <!-- Success phone -->
  <div class="modal fade success-modal verify-modal" id="success">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body success-body text-center">
          <span>
            <i class="fa-regular fa-circle-check"></i>
          </span>
          <h3>Success</h3>
          <p class="success-modal-body">
            Your phone number has been successfully verified
          </p>
        </div>
        <div class="popup-btn text-center">
          <a
            href="javascript:;"
            class="btn btn-primary verify-phone"
            onclick="verifiePhone()"
            >Go to Dashboard</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Success  -->

  <!-- Change mail  -->
  <div class="modal fade custom-modal verify-modal" id="change-mail">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header verfication-modal-head">
          <h5 class="modal-title">Verify Your Email Address</h5>
          <p>Check your inbox for an verification link</p>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitFormOne">
            <div class="wallet-add">
              <div class="form-group">
                <input
                  class="form-control"
                  name="phone"
                  type="text"
                  placeholder="Enter email address"
                />
              </div>
              <div class="mail-check-verify">
                <p>
                  Didn't Receive verification email? Please check your spam folder or try
                  to send the email
                </p>
              </div>
              <div class="modal-submit">
                <a
                  href="javascript:;"
                  class="btn btn-primary w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#success-mail"
                  >Continue</a
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Change Phone  -->

  <!-- Success mail -->
  <div class="modal fade success-modal verify-modal" id="success-mail">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body success-body text-center">
          <span>
            <i class="fa-regular fa-circle-check"></i>
          </span>
          <h3>Success</h3>
          <p class="success-modal-body">
            Your Email address has been successfully verified
          </p>
        </div>
        <div class="popup-btn text-center">
          <a href="javascript:;" class="btn btn-primary verify-mail">Go to Dashboard</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Success  -->

  <!-- Change Document -->
  <div class="modal fade custom-modal verify-modal" id="change-document">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header verfication-modal-head">
          <h5 class="modal-title">Verify Your Identity</h5>
          <p>Upload document</p>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitFormOne">
            <div class="wallet-add">
              <div class="form-group">
                <label>Document Name</label>
                <input class="form-control" name="phone" type="text" />
              </div>
              <div class="file-uploaded-mail">
                <img
                  src="@/assets/img/icons/upload-icon-02.svg"
                  class="img-fluid"
                  alt="Upload"
                />
                <h4>Drag & drop files or <span> Browse </span></h4>
                <p>Supported formats: PDF</p>
                <input type="file" id="file-input" class="image-upload" />
              </div>
              <div class="document-upload-file">
                <p>
                  <img
                    src="@/assets/img/icons/file-pdf.svg"
                    class="img-fluid"
                    alt="Pdf"
                  />
                  adharcard.pdf
                </p>
                <span>
                  <a href="javascript:;">
                    <img
                      src="@/assets/img/icons/trash-alt.svg"
                      class="img-fluid"
                      alt="Trash"
                    />
                  </a>
                </span>
              </div>
              <div class="document-update-success">
                <p>
                  <i class="fa-regular fa-circle-check"></i> Document uploaded
                  successfully
                </p>
              </div>
              <div class="modal-submit">
                <a
                  href="javascript:;"
                  class="btn btn-primary w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#success-document"
                  >Continue</a
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Change Phone  -->

  <!-- Success mail -->
  <div class="modal fade success-modal verify-modal" id="success-document">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body success-body text-center">
          <span>
            <i class="fa-regular fa-circle-check"></i>
          </span>
          <h3>Success</h3>
          <p class="success-modal-body">
            Document is sent for approval for the admin once approved you will notified.
          </p>
        </div>
        <div class="popup-btn text-center">
          <a href="javascript:;" class="btn btn-primary verify-document"
            >Go to Dashboard</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- Success  -->

  <!-- Add Review -->
  <div class="modal fade custom-modal" id="add-review">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile p-0">
        <div
          class="modal-header border-bottom-0 text-strat justify-content-between mb-0 p-4"
        >
          <h5 class="modal-title">Write A Review</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form @submit.prevent="submitFormThree">
            <div class="write-review">
              <div class="review-add">
                <div class="rev-img">
                  <img src="@/assets/img/services/service-19.jpg" alt="image" />
                </div>
                <div class="rev-info">
                  <h6>Computer Services</h6>
                  <p>Newyork, USA</p>
                </div>
              </div>
              <div class="form-group form-info">
                <label class="col-form-label">Rate The Service</label>
                <div class="rating rating-select mb-0">
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star me-1"></i></a>
                </div>
              </div>
              <div class="form-group form-info">
                <label class="col-form-label">Write your Review</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Please write your review"
                ></textarea>
              </div>
              <div class="modal-submit text-end">
                <button type="submit" class="btn btn-primary">Send</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Review -->
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    redirectReloadProvider() {
      this.$router.push({ path: "/provider-services" }).then(() => {
        this.$router.go();
      });
    },
    submitForm() {
      this.$router.push("/login");
    },
    submitFormOne() {
      this.$router.push("/provider-security-settings");
    },
    submitFormThree() {
      this.$router.push("/booking-details");
    },
  },
};
</script>
