<template>
  <div class="col-xl-3 chat-cont-profile d-flex" id="task_window">
    <div class="card mb-sm-3 mb-md-0 flex-fill">
      <div class="card-header">
        <div class="profile-wrap">
          <div class="chat-profile">
            <div class="chat-profile-img">
              <img src="@/assets/admin_img/profiles/avatar-02.jpg" alt="" />
            </div>
            <div class="chat-profile-info">
              <h6>John Doe</h6>
              <p>Newyork</p>
            </div>
          </div>
          <ul>
            <li>
              <a href="javascript:void(0);"><i class="feather feather-mail"></i></a>
            </li>
            <li>
              <a href="javascript:void(0);"><i class="feather feather-phone"></i></a>
            </li>
            <li>
              <a href="javascript:void(0);"><i class="feather feather-map-pin"></i></a>
            </li>
            <li>
              <a href="javascript:void(0);"
                ><i class="feather feather-more-horizontal"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <div class="chat-pro-list">
          <ul>
            <li>
              <span class="role-title"><i class="fa fa-briefcase"></i>Company</span
              ><span class="role-info">Company Name</span>
            </li>
            <li>
              <span class="role-title"><i class="fa fa-user"></i>Role</span
              ><span class="role-info">Co-founder</span>
            </li>
            <li>
              <span class="role-title"><i class="fa fa-phone"></i>Phone</span
              ><span class="role-info">+1 123 456 7890</span>
            </li>
            <li>
              <span class="role-title"><i class="fa fa-envelope"></i>Email</span
              ><span class="role-info">info@johnsteven.com</span>
            </li>
          </ul>
        </div>
        <div class="chat-media">
          <div class="chat-media-title">
            <h6>Shared Media</h6>
            <span>(3475 items)</span>
          </div>
          <div class="media-list">
            <ul class="nav">
              <li>
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="tab"
                  data-bs-target="#chat-photo"
                  class="active"
                  >Photos</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="tab"
                  data-bs-target="#chat-file"
                  >File</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="tab"
                  data-bs-target="#chat-vdo"
                  >Video</a
                >
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="tab"
                  data-bs-target="#chat-link"
                  >Link</a
                >
              </li>
            </ul>
          </div>
          <div class="tab-content pt-0">
            <div class="tab-pane active" id="chat-photo">
              <div class="photo-list">
                <ul>
                  <li>
                    <img src="@/assets/admin_img/services/service-03.jpg" alt="img" />
                  </li>
                  <li>
                    <img src="@/assets/admin_img/services/service-04.jpg" alt="img" />
                  </li>
                  <li>
                    <img src="@/assets/admin_img/services/service-05.jpg" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-pane fade" id="chat-vdo">
              <div class="photo-list">
                <ul>
                  <li>
                    <img src="@/assets/admin_img/services/service-01.jpg" alt="img" />
                  </li>
                  <li>
                    <img src="@/assets/admin_img/services/service-04.jpg" alt="img" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-pane fade" id="chat-file">
              <div class="photo-file">
                <ul>
                  <li><i class="feather feather-file-text me-2"></i> admin_v1.0.zip</li>
                  <li><i class="feather feather-file-text me-2"></i> test.pdf</li>
                </ul>
              </div>
            </div>
            <div class="tab-pane fade" id="chat-link">
              <div class="photo-link">
                <ul>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="feather feather-link-2 me-2"></i> Sed ut perspiciatis</a
                    >
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="feather feather-link-2 me-2"></i> https:example.com</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="chat-notification">
            <ul>
              <li>
                <p><i class="fa fa-bell"></i> Notifications</p>
                <div
                  class="status-toggle d-flex justify-content-sm-end align-items-center"
                >
                  <input type="checkbox" id="status_1" class="check" checked="" />
                  <label for="status_1" class="checktoggle">checkbox</label>
                </div>
              </li>
              <li>
                <p><i class="fa fa-star"></i> Add To Favourites</p>
                <div
                  class="status-toggle d-flex justify-content-sm-end align-items-center"
                >
                  <input type="checkbox" id="status_2" class="check" checked="" />
                  <label for="status_2" class="checktoggle">checkbox</label>
                </div>
              </li>
              <li>
                <p><i class="fa fa-volume-mute"></i> Mute</p>
                <div
                  class="status-toggle d-flex justify-content-sm-end align-items-center"
                >
                  <input type="checkbox" id="status_3" class="check" />
                  <label for="status_3" class="checktoggle">checkbox</label>
                </div>
              </li>
            </ul>
          </div>
          <div class="clear-list">
            <ul>
              <li>
                <a href="javascript:void(0);"
                  ><i class="feather feather-trash-2"></i>Clear Chat</a
                >
              </li>
              <li>
                <a href="javascript:void(0);"
                  ><i class="feather feather-external-link"></i>Export Chat</a
                >
              </li>
              <li>
                <a href="javascript:void(0);"
                  ><i class="feather feather-alert-circle"></i>Report Contact</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
