<template>
    <!-- Edit Invoice -->
    <div class="modal fade" id="edit-invoice" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div v-if="formEdit" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Invoice</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="my-edit-invoice-container mt-3">
              <div class="col">
                <div class="mb-3">
                  <label class="form-label">No Invoice</label>
                  <input v-if="formEdit.no_invoice" type="text" class="form-control" :value="formEdit.no_invoice" disabled/>
                </div>
                <div class="mb-3">
                  <label class="form-label">Total Price</label>
                  <input v-if="formEdit.total_price" type="text" class="form-control" :value="formatCurrency(formEdit.total_price)" disabled/>
                </div>
                <div class="mb-3">
                  <label class="form-label">Sudah Bayar</label>
                  <input v-if="formEdit.sudah_bayar" type="text" class="form-control" :value="formatCurrency(formEdit.sudah_bayar)" disabled/>
                </div>
                <div class="mb-3">
                  <label class="form-label">Harus Bayar</label>
                  <input v-if="formEdit.harus_bayar" type="text" class="form-control" :value="formatCurrency(formEdit.harus_bayar)" disabled/>
                </div>
              </div>
              <div v-if="formEdit.invoice_products" class="col">
                <div v-for="product of formEdit.invoice_products" :key="product.id" class="mb-3">
                  Order <span class="fw-bold">{{ product.order.no_order }}</span>
                  <ul class="list-group mt-2">
                    <li v-for="ov of product.order.order_vehicle" :key="ov.id" class="list-group-item">
                      {{ ov.vehicle.name }} - {{ ov.vehicle.nomor_plat }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col">
                <span>Pembayaran</span> 
                <ul v-if="formEdit.invoice_lines" class="list-group mt-2">
                  <li v-for="line of formEdit.invoice_lines" :key="line.id" class="list-group-item">
                    <div class="d-flex w-100 justify-content-between">
                      <h6>{{ formatCurrency(line.line_total) }}</h6>
                      <small>{{ formatDate(line.created_at) }}</small>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <p class="mb-1">{{ line.remark }}</p>
                        <span v-if="!line.is_approved" style="color:red">*Belum di approve</span>
                      </div>
                      <div v-if="!line.is_approved" class="flex-grow-0 d-flex">
                        <button class="btn btn-primary me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-tambah-bayar"
                          @click="openEditInvoiceLine(line)"
                        >
                          <i class="feather feather-edit"></i>
                        </button>
                        <button class="btn btn-danger"
                          type="button"
                          @click="removeInvoiceLine(line)"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="mt-3">
                  <button
                    class="btn btn-primary" 
                    data-bs-toggle="modal"
                    data-bs-target="#tambah-bayar"
                  >
                    Tambah bayar
                  </button>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Invoice -->

    <!-- Tambah bayar -->
    <div class="modal fade" id="tambah-bayar" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div v-if="formEdit" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah pembayaran</h5>
            <button
              id="close-edit"
              type="button"
              ref="closeBtnInvoiceLine"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createInvoiceLine">
              <div class="mb-3">
                <label class="form-label">Amount <span style="color:red">*</span></label>
                <input v-model="formattedCreateInvoiceLineValues.line_total" @input="onInput('formAddInvoiceLine', 'line_total', $event)" type="text" class="form-control" />
              </div>
              <div class="mb-3">
                <label class="form-label">Metode Pembayaran <span style="color:red">*</span></label>
                <select class="form-select" v-model="formAddInvoiceLine.payment_method">
                  <option value="Transfer">Transfer</option>
                  <option value="Cash">Cash</option>
                  <option value="Debit/Credit">Debit/Credit</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Bukti Pembayaran <span style="color:red">*</span></label>
                <div class="profile-upload-content">
                    <img v-if="formAddInvoiceLine.bukti_pembayaran" :src="formAddInvoiceLine.bukti_pembayaran" alt="Preview Bukti DP" class="img-thumbnail mb-3" width="100" height="100" />
                    <div class="profile-upload-btn">
                        <div class="profile-upload-file">
                          <input type="file" @change="event => selectFileAttachment(event, 'bukti_pembayaran')" />
                        </div>
                    </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Catatan <span style="color:red">*</span></label>
                <input v-model="formAddInvoiceLine.remark" type="text" class="form-control" />
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="formAddInvoiceLine.is_approved">
                <label class="form-check-label" for="flexCheckDefault">
                  Approve
                </label>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form> 
          </div>
        </div>
      </div>
    </div>
    <!-- /Tambah bayar -->

    <!-- Edit tambah bayar -->
    <div class="modal fade" id="edit-tambah-bayar" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div v-if="formEdit" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah pembayaran</h5>
            <button
              id="close-edit"
              type="button"
              ref="closeBtnInvoiceLineEdit"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editInvoiceLine" v-if="formEditInvoiceLine && formattedEditInvoiceLineValues">
              <div class="mb-3">
                <label class="form-label">Amount <span style="color:red">*</span></label>
                <input v-model="formattedEditInvoiceLineValues.line_total" @input="onInput('formEditInvoiceLine', 'line_total', $event)" type="text" class="form-control" />
              </div>
              <div class="mb-3">
                <label class="form-label">Metode Pembayaran <span style="color:red">*</span></label>
                <select class="form-select" v-model="formEditInvoiceLine.payment_method">
                  <option value="Transfer">Transfer</option>
                  <option value="Cash">Cash</option>
                  <option value="Debit/Credit">Debit/Credit</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Bukti Pembayaran <span style="color:red">*</span></label>
                <div class="profile-upload-content">
                    <img v-if="formEditInvoiceLine.bukti_pembayaran" :src="formEditInvoiceLine.bukti_pembayaran" alt="Preview Bukti DP" class="img-thumbnail mb-3" width="100" height="100" />
                    <div class="profile-upload-btn">
                        <div class="profile-upload-file">
                          <input type="file" @change="event => editSelectFileAttachment(event, 'bukti_pembayaran')" />
                        </div>
                    </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">Catatan <span style="color:red">*</span></label>
                <input v-model="formEditInvoiceLine.remark" type="text" class="form-control" />
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="formEditInvoiceLine.is_approved" >
                <label class="form-check-label" for="flexCheckDefault">
                  Approve
                </label>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form> 
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit tambah bayar -->

    <!-- Tambah Invoice -->
    <div class="modal fade" id="tambah-invoice" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Buat Invoice</h5>
            <button
              id="close-edit"
              type="button"
              ref="closeAddInvoiceModal"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div v-if="selectedOrdersData && selectedOrdersData.length > 0" class="modal-body pt-0">
            <div v-if="customersData" class="mt-3">
              <label class="form-label">Pelanggan yang bertanggung jawab</label>
              <p>{{ selectedOrdersData[0].customer.name }}</p>
            </div>
            <div v-for="order of selectedOrdersData" :key="order.id" class="mt-3">
              Order <span class="fw-bold">{{ order.no_order }}</span>
              <ul class="list-group mt-2">
                <li v-for="ov of order.order_vehicle" :key="ov.id" class="list-group-item">
                  {{ ov.vehicle.name }} - {{ ov.vehicle.nomor_plat }}
                </li>
              </ul>
            </div>
            <div class="mt-3">
              <label class="form-label">Category <span style="color:red">*</span></label>
              <select class="form-select" v-model="formCreate.category">
                <option value="bikin-invoice-baru">Bikin Invoice Baru</option>
                <option value="denda">Denda</option>
              </select>
            </div>
            <div v-if="formCreate.category == 'denda'" class="mt-3">
                <label class="form-label">Denda</label>
                <input type="text" class="form-control" v-model="formattedCreateValues.denda" @input="onInput('formCreate', 'denda', $event)"/>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" @click="emitCreateInvoice" class="btn btn-primary">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Tambah Invoice -->

    <!-- /Delete Order-->
    <div class="modal fade" id="delete-invoice" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Invoice ?</h5>
  
            <button
              id="close-delete"
              ref="closeBtnInvoiceDelete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Order-->

    <!-- Invoice Pelanggan PDF -->
    <div class="modal fade" id="invoice-pelanggan-pdf" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="d-flex">
            <button class="btn btn-primary" @click="downloadPdf">Download</button>
          </div>
          <div id="invoice-pelanggan-pdf-content" v-if="invoicePdfData">
            <table class="container">
                <tbody>
                    <td class="left">
                        <h1 class="title" style="font-size: 24px;">Finance Invoice</h1>
                    </td>
                    <td class="right" align="right">
                        <img  class="logo" :src="invoicePdfData.invoice.company && invoicePdfData.invoice.company.logo ? invoicePdfData.invoice.company.logo : require('@/assets/img/mari/logo_marirental3.svg')" />
                    </td>
                </tbody>
            </table>
            <div class="hr mt-2"></div>
            <table class="container mt">
                <tbody>
                    <td class="left">
                        <p class="title mb-0">Kepada:</p>
                        <p>{{ invoicePdfData.invoice.customer.name }}</p>
                    </td>
                    <td class="right" align="right">
                        <p class="title mb-0">Tanggal:</p>
                        <p>{{ formatDate(invoicePdfData.invoice.created_at) }}</p>
                    </td>
                </tbody>
            </table>
            <table class="container mt">
                <tbody>
                    <td class="left">
                    </td>
                    <td class="right" align="right">
                        <p class="title mb-0">No Invoice:</p>
                        <p>{{ invoicePdfData.invoice.no_invoice }}</p>
                    </td>
                </tbody>
            </table>

            <table class="container table-border">
                    <thead>
                        <th>Order</th>
                        <th>Detail</th>
                        <th>Nominal</th>
                    </thead>
                    <tbody>
					<tr v-for="product of invoicePdfData.invoice.invoice_products" :key="product.id">
                        <td>{{ product.order ? product.order.no_order : `Order ID: ${product.order_id}`  }}</td>
						<td>
							<ul>
                                <li v-for="line of invoicePdfData.invoice.invoice_lines" :key="line.id">
                                    <p style="margin: 0">{{ line.remark }} - {{ formatDate(line.created_at) }}</p>
                                    <p style="margin: 0">{{ line.is_approved ? 'Sudah di approve' : 'Belum di approve' }}</p>
                                </li>
							</ul>
						</td>
						<td>
							<ul>
                                <li v-for="line of invoicePdfData.invoice.invoice_lines">
                                    <p style="margin: 0">{{ formatCurrency(line.line_total) }}</p>
                                </li>
							</ul>
						</td>
					</tr>

                    </tbody>
                </table>

                <table style="width: 100%; margin-top: 20px; text-align: center;" class="container table-border">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Unit</th>
                        <th>No Polisi</th>
                        <th>Tanggal Pemakaian</th>
                        <th>Keterangan</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="(order, i) of invoicePdfData.vendorOrders">
                    <td>{{ i + 1 }}</td>
                    <td>{{ order['unit'] }}</td>
                    <td>{{ order['no_polisi'] }}</td>
                    <td>{{ order['tanggal_pemakaian'] }}</td>
                    <td>{{ order['keterangan'] }}</td>
                </tr>

                </tbody>
            </table>



            <table class="container">
                <tbody>
                    <td>
                        <p class="title" style="margin-bottom: 0;">Total harga:</p>
                        <span>{{ formatCurrency(invoicePdfData.invoice.total_price) }}</span>
                    </td>
                    <td>
                        <p class="title" style="margin-bottom: 0;">Sudah bayar:</p>
                        <span>{{ formatCurrency(invoicePdfData.invoice['sudah_bayar']) }}</span>
                    </td>
                    <td>
                        <p class="title" style="margin-bottom: 0;">Harus bayar:</p>
                        <span>{{ formatCurrency(invoicePdfData.invoice.total_price - invoicePdfData.invoice['sudah_bayar']) }}</span>
                    </td>
                </tbody>
            </table>

            <table class="container mt">
                <tbody>
                    <td class="left">
                        <p class="title mb-0">Pembayaran:</p>
                        <p class="mb-0">{{ invoicePdfData.invoice.company && invoicePdfData.invoice.company.nama_pemilik_bank ? `Nama: ${invoicePdfData.invoice.company.nama_pemilik_bank}` : 'Nama: Mari Rental' }}</p>
                        <p>{{ invoicePdfData.invoice.company && invoicePdfData.invoice.company.bank_account && invoicePdfData.invoice.company.no_rekening ? `${invoicePdfData.invoice.company.bank_account.toUpperCase()} ${invoicePdfData.invoice.company.no_rekening}` : 'MariBank 123123123123' }}</p>
                    </td>
                </tbody>
            </table>
            <table class="container mt-big">
                <tbody>
                    <td class="left">
                        <p class="title">Terimakasih atas pembelian anda</p>
                    </td>
                    <td class="right" align="right">
                      <img  class="logo" :src="invoicePdfData.invoice.company && invoicePdfData.invoice.company.stample ? invoicePdfData.invoice.company.stample : ''" />
                    </td>
                </tbody>
            </table>
          </div>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Invoice Pelanggan PDF -->

  </template>
  <script>
  import moment from "moment"
  import { uploadFile } from "@/utils/fileUpload";
  import { formatCurrency } from '@/utils/currency'

  export default {
    data() {
      return {
        formEdit: null,
        formAddInvoiceLine: {},
        formEditInvoiceLine: null,
        files: {
            bukti_pembayaran: null,
        },
        selectedOrdersData: null,
        customersData: null,
        createOrderCustomerId: null,
        formCreate: {},
        formattedCreateValues: {},
        formattedCreateInvoiceLineValues: {},
        formattedEditInvoiceLineValues: {},
        invoicePdfData: null
      };
    },
    props: {
      editData: Object,
      selectedOrders: Object,
      customers: Object,
      deleteItemId: Object,
      invoicePdfDataProp: Object
    },
    emits: ['createInvoice'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
            this.formEdit.invoice_lines = this.formEdit.invoice_lines.map((line) => {
              return {
                ...line,
                is_approved: line.is_approved == 1 ? true : false
              }
            })
          }
        }
      },
      selectedOrders: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.selectedOrdersData = newValue.data;
          }
        }
      },
      customers: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.customersData = newValue;
          }
        }
      },
      invoicePdfDataProp: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.invoicePdfData = newValue;
            console.log(this.invoicePdfData);
          }
        }
      }
    },
    methods: {
      formatCurrency: formatCurrency,
      downloadPdf() {
        var opt = {
          margin: 0.5,
          filename: "file.pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 1, scrollY: 0, scrollX: 0 },
          jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };
        const el = document.getElementById('invoice-pelanggan-pdf-content')
        let loading = this.$loading.show();
        
        if (this.invoicePdfData.invoice.company && this.invoicePdfData.invoice.company.logo) {
          this.loadImageToCanvas(this.invoicePdfData.invoice.company.logo, (dataUrl) => {
            this.invoicePdfData.invoice.company.logo = dataUrl

            if (this.invoicePdfData.invoice.company.stample) {
              this.loadImageToCanvas(this.invoicePdfData.invoice.company.stample, (dataUrl2) => {
                this.invoicePdfData.invoice.company.stample = dataUrl2
                html2pdf()
                .set(opt)
                .from(el)
                .save()
                .then(() => {
                  loading.hide();
                });
              })
            } else {
              html2pdf()
                .set(opt)
                .from(el)
                .save()
                .then(() => {
                  loading.hide();
                });
            }
          })
        } else {
          html2pdf()
            .set(opt)
            .from(el)
            .save()
            .then(() => {
              loading.hide();
            });
        }
      },
      loadImageToCanvas(url, callback) {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Atur ini jika gambar berasal dari domain lain
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.fillStyle = 'white'; // Use white color for background
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);
          callback(canvas.toDataURL('image/jpeg'));
        };
        img.onerror = () => {
          console.error('Gagal memuat gambar');
        };
        img.src = url;
      },
      openEditInvoiceLine(invoiceLine) {
        this.formEditInvoiceLine = invoiceLine
        this.formattedEditInvoiceLineValues.line_total = this.formatCurrency(invoiceLine.line_total)
      },
      emitCreateInvoice() {
        this.$emit('createInvoice', {
          customer_id: this.createOrderCustomerId,
          orders: this.selectedOrdersData,
          formCreate: this.formCreate
        })
        this.$refs.closeAddInvoiceModal.click()
      },
      async removeInvoiceLine(line) {
        const data = {
          id: line.id,
        }

        const result = await this.$swal.fire({
          title: "Delete this invoice line?",
          text: "Are you sure? You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!"
        })

        if (result.value) {
          var loading = this.$loading.show();
          try {
            await this.$store.dispatch('financeInvoice/removeInvoiceLine', data)
          } catch (e) {
            this.$toast.error(e);
          } finally {
            this.$refs.closeBtnInvoiceLineEdit.click()
            loading.hide()
          }
        }

        this.$router.go()
      },
      async editInvoiceLine() {
        if (!this.formEditInvoiceLine) {
          return
        }
        const data = {
          id: this.formEditInvoiceLine.id,
          ...this.formEditInvoiceLine
        }

        var loading = this.$loading.show();
        try {
          await this.$store.dispatch('financeInvoice/updateInvoiceLine', data)
        } catch (e) {
          this.$toast.error(e);
        } finally {
          this.$refs.closeBtnInvoiceLineEdit.click()
          loading.hide()
        }

        this.$router.go()
      },
      async createInvoiceLine() {
        if (!this.formEdit) {
          return
        }
        if (!this.formEdit.id) {
          return
        }

        const data = {
          invoice_id: this.formEdit.id,
          ...this.formAddInvoiceLine
        }

        var loading = this.$loading.show();
        try {
          await this.$store.dispatch('financeInvoice/addInvoiceLine', data)
        } catch (e) {
          this.$toast.error(e);
        } finally {
          this.$refs.closeBtnInvoiceLine.click()
          loading.hide()
        }

        this.$router.go()
      },
      formatDate(date) {
        return moment(date).format('DD MMM YYYY')
      },
      formatCurrency(value) {
          if (!value && value !== 0) return '';
          // Convert value to a string
          let stringValue = value.toString();
          // Split the string into integer and decimal parts
          let parts = stringValue.split('.');
          // Format the integer part with thousand separators
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          // Join the parts and return the formatted value
          return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
        },
      unformatCurrency(value) {
        return value.replace(/\./g, '').replace(',', '.');
      },
      onInput(form, field, event) {
        const rawValue = event.target.value;
        const unformattedValue = this.unformatCurrency(rawValue);
        const numberValue = parseFloat(unformattedValue);
        const formattedValue = this.formatCurrency(numberValue);

        if (form === 'formCreate') {
          this.formCreate[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedCreateValues[field] = formattedValue;
        } else if (form === 'formEdit') {
          this.formEdit[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedEditValues[field] = formattedValue;
        } else if (form == 'formAddInvoiceLine') {
          this.formAddInvoiceLine[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedCreateInvoiceLineValues[field] = formattedValue;
        } else if (form == 'formEditInvoiceLine') {
          this.formEditInvoiceLine[field] = isNaN(numberValue) ? '' : numberValue;
          this.formattedEditInvoiceLineValues[field] = formattedValue;
        }
      },
      validateFile(file, allowedFileTypes, maxFileSize) {
          if (!allowedFileTypes.includes(file.type)) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
              });
              return false;
          }

          if (file.size > maxFileSize) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
              });
              return false;
          }

          return true;
      },
      editSelectFileAttachment(event, column) {
              const file = event.target.files[0];
              const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
              const maxFileSize = 2 * 1024 * 1024; // 2 MB

              if (!this.validateFile(file, allowedFileTypes, maxFileSize)) {
                  return;
              }
              if (file) {
                  this.files[column] = file;

                  var loading = this.$loading.show();

                  uploadFile(file)
                      .then((resp) => {
                          // Directly update the formCreate object
                          this.formEditInvoiceLine[column] = resp;  // Ensure that resp contains a 'url' property
                          loading.hide();
                          this.$swal.fire({
                              icon: 'success',
                              text: 'File berhasil diupload !!',
                          });
                      })
                      .catch((e) => {
                          loading.hide();
                          this.$swal.fire({
                              icon: 'warning',
                              text: 'Terjadi kesalahan, ' + e,
                          });
                      });
              }
          },
      selectFileAttachment(event, column) {
            const file = event.target.files[0];
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const maxFileSize = 2 * 1024 * 1024; // 2 MB

            if (!this.validateFile(file, allowedFileTypes, maxFileSize)) {
                return;
            }
            if (file) {
                this.files[column] = file;

                var loading = this.$loading.show();

                uploadFile(file)
                    .then((resp) => {
                        // Directly update the formCreate object
                        this.formAddInvoiceLine[column] = resp;  // Ensure that resp contains a 'url' property
                        loading.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: 'File berhasil diupload !!',
                        });
                    })
                    .catch((e) => {
                        loading.hide();
                        this.$swal.fire({
                            icon: 'warning',
                            text: 'Terjadi kesalahan, ' + e,
                        });
                    });
            }
        },
        deleteData() {
          // Perform deletion using this.deleteItemId
          const id = this.deleteItemId.data.id;
          var loading = this.$loading.show()
          this.$store
            .dispatch("financeInvoice/deleteInvoices", id )
            .then((data) => {
              if (data.status == 200) {
                loading.hide()
                this.$refs.closeBtnInvoiceDelete.click();
                this.$emit('dataUpdated');
              }
            },
            (error) => {
              console.log(error)
              loading.hide();
            })
            .catch((e) => {
              loading.hide()
            })
        },
    }
  };
  </script>
  
  <style>
          * {
            box-sizing: border-box;
            font-family: sans-serif;
        }

        .container {
            width: 100%;
        }

        .left {
            width: 50%;
            padding: 0;
        }

        .right {
            width: 50%;
            padding: 0;
        }

        .logo {
            width: 150px;
            height: 70px;
        }

        .hr {
            width: 100%;
            border-bottom: 1px solid black;
        }

        .mt {
            margin-top: 12px;
        }

        .mt-big {
            margin-top: 48px;
        }

        .title {
            font-weight: bold;
            text-transform: uppercase;
        }

        .table-border {
            border-collapse: collapse;
        }

        .table-border, .table-border th, .table-border td {
            border: 1px solid black;
            padding: 4px;
        }

        td {
            margin: 0;
            padding: 0;
        }
        .my-edit-invoice-container {
          display: flex;
          flex-direction: column;
        }

        @media (min-width: 600px) {
          .my-edit-invoice-container {
            flex-direction: row;
            gap: 1rem;
          }
        }

  </style>
