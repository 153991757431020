<template>
  <!-- Our Services -->
  <section class="service-nine-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-nine aos" data-aos="fade-up">
            <p>TRUSTED & QUALITY SERVICE</p>
            <h2>Our Services</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel service-nine-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexnineservice" :key="item.id">
                <div
                  class="service-widget service-widget-space service-widget-nine aos"
                  data-aos="fade-up"
                >
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                  </div>
                  <div class="service-content service-feature-nine">
                    <div class="shop-content-logo">
                      <img :src="require(`@/assets/img/icons/${item.icon}`)" alt="" />
                    </div>
                    <span>{{ item.Mechanics }}</span>
                    <p>{{ item.title }}</p>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
      <div class="btn-sec btn-service-nine aos" data-aos="fade-up">
        <router-link to="/search" class="btn btn-primary btn-view"
          >VIEW ALL 255 SERVICES</router-link
        >
      </div>
    </div>
  </section>
  <!-- /Our Services -->

  <!-- Featured Services -->
  <section class="feature-service-nine">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-nine aos" data-aos="fade-up">
            <p>TRUSTED & QUALITY SERVICE</p>
            <h2>Featured Services</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-sm-6 col-12"
          v-for="item in IndexNineFeatured"
          :key="item.id"
        >
          <div class="service-widget service-widget-nine">
            <div class="service-img">
              <router-link to="service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  :src="require(`@/assets/img/services/${item.imageSrc}`)"
                />
              </router-link>
              <div class="item-info items-nine">
                <img
                  :src="require(`@/assets/img/profiles/${item.profileImageSrc}`)"
                  alt=""
                />
                <div class="rating">
                  <i class="fa-solid fa-star filled me-1"></i>
                  <i class="fa-solid fa-star filled me-1"></i>
                  <i class="fa-solid fa-star filled me-1"></i>
                  <i class="fa-solid fa-star filled me-1"></i>
                  <i class="fa-solid fa-star filled me-1"></i>
                  <span>({{ item.numRatings }})</span>
                </div>
              </div>
            </div>
            <div class="service-content service-content-nine">
              <span class="sub-title">{{ item.serviceType }}</span>
              <h3 class="title">
                <a href="javascript:;">{{ item.serviceName }}</a>
              </h3>
              <div class="main-saloons-profile">
                <div class="saloon-profile-left">
                  <div class="saloon-content">
                    <div class="saloon-content-top">
                      <i class="feather-clock"></i>
                      <span>{{ item.openingHours }} </span>
                    </div>
                    <div class="saloon-content-btn">
                      <span><i class="feather-map-pin"></i></span>
                      <span>{{ item.location }}</span>
                    </div>
                  </div>
                </div>
                <div class="saloon-right">
                  <span>{{ item.price }}</span>
                </div>
              </div>
              <div class="service-bottom-nine">
                <a href="javascript:;" class="btn btn-primary">Make An Appointment</a>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-sec btn-service-nine aos" data-aos="fade-up">
          <router-link to="/search" class="btn btn-primary btn-view"
            >VIEW ALL 255 SERVICES</router-link
          >
        </div>
      </div>
    </div>
  </section>
  <!-- /Featured Services -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexnineservice from "@/assets/json/indexnineservice.json";
import IndexNineFeatured from "@/assets/json/indexninefeatured.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexnineservice: indexnineservice,
      IndexNineFeatured: IndexNineFeatured,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
