<template>
    <div class="mt-0">
        <!-- Error 404 -->
	<div class="main-wrapper error-page">
		<div class="bg-img">
			<img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg1">
			<img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg2">
			<img src="@/assets/img/bg/feature-bg-03.png" alt="img" class="bgimg3">
		</div>
		<div class="content">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 mx-auto">
						<div class="error-wrap text-center">
							<div class="error-logo">
								<router-link to="/"><img class="img-fluid" src="@/assets/img/logo.svg" alt="img"></router-link>
							</div>					
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 mx-auto">
						<div class="error-wrap">
							<div class="error-img">
								<img class="img-fluid" src="@/assets/img/error-404.png" alt="img">
							</div>
							<h2>404 Oops! Page Not Found</h2>
							<p>This page doesn't exist or was removed! We suggest you back to home.</p>
							<router-link to="/" class="btn btn-primary"><i class="feather-arrow-left-circle me-2"></i>Back to Home</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- /Error 404 -->
    </div>
</template>