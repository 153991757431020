import axios from 'axios'; 


export default {
    namespaced: true,
    state: {
        guide: {},
        isGuide: false
    },
    mutations: {
        setGuide(state, data) {
            state.isGuide = data;
        },
    },
    actions: {

        getGuide(_, data) {
            console.log(data.id, "store")
            return new Promise((resolve, reject) => {
              axios.get(`/guide/show/${data.id}`).then(resp => {
                resolve(resp.data);
              }).catch(e => {
                reject(e);
              });
            });
        },

        updateGuide(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('guide/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

    },
    getters: {
        getIsGuide: state => { return state.guide.isGuide }
    }

}