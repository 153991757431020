<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Add State</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Country</label>
                    <vue-select :options="Country" placeholder="Select Country" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>State Name</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter State Name"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" to="/admin/states" variant="primary">
                      Add State
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Country: ["Select Country", "United State", "India"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/states");
    },
  },
};
</script>
