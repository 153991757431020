<template>
  <div class="bg-img">
    <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg1" />
    <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg2" />
    <img src="@/assets/img/bg/feature-bg-03.png" alt="img" class="bgimg3" />
  </div>

  <div class="content">
    <div class="container">
      <div class="row">
        <!-- Filter -->
        <div class="col-lg-3 col-sm-12 theiaStickySidebar">
          <div class="stickysidebar">
            <div class="filter-div">
              <div class="filter-head">
                <h5>Filter by</h5>
                <a href="javascript:;" class="reset-link">Reset Filters</a>
              </div>
              <div class="filter-content">
                <h2>Keyword</h2>
                <input
                  type="text"
                  class="form-control"
                  placeholder="What are you looking for?"
                />
              </div>
              <div class="filter-content">
                <h2>
                  Categories <span><i class="fas fa-chevron-down"></i></span>
                </h2>
                <div class="filter-checkbox" id="fill-more">
                  <ul>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">All Categories</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Construction</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Car Wash</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Electrical</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Cleaning</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Interior</b>
                      </label>
                    </li>
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Computer</b>
                      </label>
                    </li>
                  </ul>
                </div>
                <a href="javascript:void(0);" id="more" class="more-view"
                  >View More <i class="feather-arrow-down-circle ms-1"></i
                ></a>
              </div>
              <div class="filter-content">
                <h2>Sub Category</h2>
                <vue-select :options="Category" placeholder="All Sub Category" />
              </div>
              <div class="filter-content">
                <h2>Location</h2>
                <div class="group-img">
                  <input type="text" class="form-control" placeholder="Select Location" />
                  <i class="feather-map-pin"></i>
                </div>
              </div>
              <div class="filter-content">
                <h2 class="mb-5">Price Range</h2>
                <div class="filter-range">
                  <input type="text" id="range_03" />
                </div>
                <div class="filter-range-amount">
                  <h5>Price: <span>$05 - $210</span></h5>
                </div>
              </div>
              <div class="filter-content">
                <h2>
                  By Rating <span><i class="fas fa-chevron-down"></i></span>
                </h2>
                <ul class="rating-set">
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(35)</span>
                    </a>
                  </li>
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(40)</span>
                    </a>
                  </li>
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(40)</span>
                    </a>
                  </li>
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(20)</span>
                    </a>
                  </li>
                  <li>
                    <label class="checkboxs d-inline-flex">
                      <input type="checkbox" />
                      <span><i></i></span>
                    </label>
                    <a class="rating" href="javascript:;">
                      <i class="fa-regular fa-star filled me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <i class="fa-regular fa-star me-1"></i>
                      <span class="d-inline-block average-rating float-end">(5)</span>
                    </a>
                  </li>
                </ul>
              </div>
              <b-button variant="primary">Search</b-button>
            </div>
          </div>
        </div>
        <!-- /Filter -->

        <!-- Service -->
        <div class="col-lg-9 col-sm-12">
          <div class="row sorting-div">
            <div class="col-lg-4 col-sm-12">
              <div class="count-search">
                <h6>Found 11 Services On Search</h6>
              </div>
            </div>
            <div class="col-lg-8 col-sm-12 d-flex justify-content-end">
              <div class="sortbyset">
                <h4>Sort</h4>
                <div class="sorting-select">
                  <vue-select :options="Select" placeholder="Price Low to High" />
                </div>
              </div>
              <div class="grid-listview">
                <ul>
                  <li>
                    <router-link to="/service-grid">
                      <i class="feather-grid"></i>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/service-list" class="active">
                      <i class="feather-list"></i>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <!-- Service List -->
              <div class="service-list" v-for="item in SearchList" :key="item.id">
                <div class="service-cont">
                  <div class="service-cont-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="fav-item">
                      <a
                        href="javascript:void(0)"
                        class="fav-icon"
                        @click="toggleSelected"
                      >
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="service-cont-info">
                    <router-link to="/categories">
                      <span class="item-cat">{{ item.category }}</span>
                    </router-link>
                    <h3 class="title">
                      <router-link to="/service-details">{{ item.title }}</router-link>
                    </h3>
                    <p><i class="feather-map-pin"></i>{{ item.location }}</p>
                    <div class="service-pro-img">
                      <img src="@/assets/img/profiles/avatar-01.jpg" alt="" />
                      <span
                        ><i class="fa-solid fa-star filled me-1"></i
                        >{{ item.rating }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="service-action">
                  <h6>
                    {{ item.price }}<span :class="item.Class">{{ item.oldprice }}</span>
                  </h6>
                  <router-link to="/booking" class="btn btn-secondary"
                    >Apply Offer</router-link
                  >
                </div>
              </div>
              <!-- /Service List -->
            </div>
          </div>

          <!-- Pagination -->
          <div class="row">
            <div class="col-sm-12">
              <div class="blog-pagination rev-page">
                <nav>
                  <ul class="pagination justify-content-center">
                    <li class="page-item disabled">
                      <a class="page-link page-prev" href="javascript:;" tabindex="-1"
                        ><i class="fa-solid fa-arrow-left me-1"></i> PREV</a
                      >
                    </li>
                    <li class="page-item active">
                      <a class="page-link" href="javascript:;">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:;">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:;">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link page-next" href="javascript:;"
                        >NEXT <i class="fa-solid fa-arrow-right ms-1"></i
                      ></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!-- /Pagination -->
        </div>
        <!-- /Service -->
      </div>
    </div>
  </div>
</template>

<script>
import SearchList from "@/assets/json/search-list.json";
export default {
  data() {
    return {
      SearchList: SearchList,
      Select: ["Price Low to High", "Price High to Low"],
      Category: ["All Sub Category", "Computer", "Construction"],
    };
  },
  methods: {
    toggleSelected(event) {
      const element = event.target;
      element.classList.toggle("selected");
    },
  },
};
</script>
