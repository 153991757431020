import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
      getPreview(_, id) {
        return new Promise((resolve, reject) => {
            axios.get(`/invoice-preview/${id}`, { responseType: 'blob' }).then((resp) => {
              console.log('resp', resp)
              resolve(resp.data)
            }).catch((err) => {
              reject(err)
            })
          })
      },
      getInvoiceDetailByOrderId(_, id) {
          return new Promise((resolve, reject) => {
            axios.get(`/x/get-invoice-detail-by-order-id/${id}`).then((resp) => {
              resolve(resp.data)
            }).catch((err) => {
              reject(err)
            })
          })
      }
    },
    getters: {

    }

}
