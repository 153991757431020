<template>
  <!-- Hero Section -->
  <section class="catering-banner-section">
    <div class="container">
      <div class="home-banner slider-service">
        <div class="row align-items-center w-100">
          <div class="col-lg-7 col-md-10 mx-auto">
            <div class="section-search section-search-four">
              <h1>
                Reviews & Ratings of Best <span>Catering Service</span> Providers Near You
              </h1>
              <p>
                Helped 10,000+ Customers to find the right caterers for their functions
                and events around the world.
              </p>
              <div class="search-box search-box-four">
                <form @submit.prevent="submitForm" class="search-three-form">
                  <div class="search-input search-input-three search-input-four">
                    <i class="feather-check-square"></i>
                    <div class="form-group m-0">
                      <vue-select :options="Category" placeholder="Select Category" />
                    </div>
                  </div>
                  <div class="search-input search-input-three search-input-four">
                    <i class="feather-map-pin me-2"></i>
                    <div class="form-group mb-0">
                      <input class="form-control" type="text" placeholder="Location" />
                    </div>
                  </div>
                  <div class="search-btn">
                    <b-button variant="primary" type="submit"
                      ><i class="feather-search me-2"></i
                    ></b-button>
                  </div>
                </form>
              </div>
              <div class="catering-banner-botton">
                <div class="catering-btn-services">
                  <div class="catering-btn-icon">
                    <img src="@/assets/img/icons/server.svg" />
                  </div>
                  <h5>3000+</h5>
                  <h6>Services</h6>
                </div>
                <div class="catering-btn-services">
                  <div class="catering-btn-icon">
                    <img src="@/assets/img/icons/Fries.svg" />
                  </div>
                  <h5>900+</h5>
                  <h6>Food Items</h6>
                </div>
                <div class="catering-btn-services">
                  <div class="catering-btn-icon">
                    <img src="@/assets/img/icons/Vector-1.svg" />
                  </div>
                  <h5>15k Review</h5>
                  <h6>(4.8)</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="catering-banner-img">
              <img src="@/assets/img/catering-banner.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Hero Section -->

  <!-- Feature Section -->
  <section class="nearby-section">
    <div class="container">
      <div class="section-heading section-heading-four">
        <div class="row align-items-center">
          <div class="col-md-6 aos" data-aos="fade-up">
            <h2>Nearby Cateres For You</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
          <div class="col-md-6 text-md-end aos" data-aos="fade-up">
            <router-link to="/service-details" class="btn btn-primary btn-view"
              >VIEW ALL CATERERS<i class="feather-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel catering-slider common-four-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexfournearby" :key="item.id">
                <div class="service-widget service-widget-space service-two service-four">
                  <div class="service-common-four">
                    <div class="service-img">
                      <router-link to="/service-details">
                        <img
                          class="img-fluid serv-img"
                          alt="Service Image"
                          :src="require(`@/assets/img/services/${item.image}`)"
                        />
                      </router-link>
                    </div>
                    <div class="service-content">
                      <div class="catering-main-bottom">
                        <div class="rating">
                          <i class="fa-solid fa-star filled me-1"></i>
                          <i class="fa-solid fa-star filled me-1"></i>
                          <i class="fa-solid fa-star filled me-1"></i>
                          <i class="fa-solid fa-star filled me-1"></i>
                          <i class="fa-solid fa-star filled me-1"></i>
                          <span>{{ item.review }}</span>
                        </div>
                        <h3 class="title">
                          <router-link to="/service-details">{{
                            item.title
                          }}</router-link>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="service-content-bottom">
                    <div class="service-cater-img">
                      <img :src="require(`@/assets/img/profiles/${item.avatar}`)" />
                      <p>{{ item.name }}</p>
                    </div>
                    <h6>{{ item.KM }}</h6>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Feature Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfournearby from "@/assets/json/indexfournearby.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      Category: ["Select Category", "Tornoto", "Texas"],
      indexfournearby: indexfournearby,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
  },
};
</script>
