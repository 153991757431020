<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>Recap Debit Credit</h4>
                  <p>Kamu bisa lihat recap debit credit disni!</p>
                </div>
              </div>

              <div v-if="dataInfo" class="row mt-4">
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/eye.svg" alt="img"
                            /></span>
                            <h6>Debit terbesar</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ dataInfo.highest_debit }}">{{ formatCurrency(dataInfo.highest_debit) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user home-provider">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/monitor.svg" alt="img"
                            /></span>
                            <h6>Kredit terbesar</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ dataInfo.highest_credit }}">{{ formatCurrency(dataInfo.highest_credit) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                  <div class="card">
                    <div class="card-body">
                      <div class="home-user home-service">
                        <div class="home-userhead">
                          <div class="home-usercount">
                            <span
                              ><img src="@/assets/admin_img/icons/global.svg" alt="img"
                            /></span>
                            <h6>Total saldo</h6>
                          </div>
                        </div>
                        <div class="home-usercontent">
                          <div class="home-usercontents">
                            <div class="home-usercontentcount">
                              <span class="counters" data-count="{{ dataInfo.total }}">{{ formatCurrency(dataInfo.total) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-page-header content-page-headersplit mb-0">
                <div class="list-btn">
                  <ul>
                    <li>
                      <div class="input-group">
                        <input type="datetime-local" :value="params.dateStart" @input="updateDateStart($event.target.value)" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text">s/d</span>
                        <input type="datetime-local" :value="params.dateEnd" @input="updateDateEnd($event.target.value)" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text"></span>
                        <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" @keyup.enter="search">
                        <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <div class="table-resposnive table-div">
                    <div class="table datatable">
                      <a-table :columns="columns" :data-source="data" :pagination="pagination" style="overflow-x: auto;">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'debit_credit'">
                            <span :style="{
                              'color': record.type === 'debit' ? 'green' : 'red'
                            }">{{ record.type === 'debit' ? `+${formatCurrency(record.debit_credit)}` : `-${formatCurrency(record.debit_credit)}` }}</span>
                          </template>
                          <template v-if="column.key === 'created_at'">
                            {{ formatDate(record.created_at) }}
                          </template>
                          <template v-if="column.key === 'total'">
                            {{ formatCurrency(record.total) }}
                          </template>
                          <template v-if="column.key === 'id'">
                            <div class="table-actions d-flex">
                                <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#detail-debit-credit"
                                @click="openDetailModal(record)"
                              >
                                <i class="feather feather-eye"></i>
                              </button>
                            </div>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <recap-debit-credit-model :detailData="detailData"></recap-debit-credit-model>
  </template>
  
  <script>
import moment from 'moment'

  const columns = [
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Kategori",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Debit/Credit",
      dataIndex: "debit_credit",
      key: "debit_credit",
    },
    {
      title: "Saldo",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Action",
      key: "id",
      class: "text-end",
    },
  ];
  export default {
    data() {
      return {
        columns,
        data: [],
        dataInfo: null,
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            dateStart: '2024-01-01T00:00',
            dateEnd: '2024-12-31T00:00',
            jadwal: false
        },
        paramUser: {
            row: 10,
            keyword: "Driver",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        detailData: null,
        itemIdToDelete: {},
        dataProduct: [],
        dataVehicle: [],
        dataCustomer: [],
        dataVendor: [],
        dataUser: [],
        dataBrand: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        totalB: 0,
        totalP: 0,
        total: 0,
        dataOrder: {},
        previewPdf: null,
      };
    },
    name: "ordersetting",
    watch: {
      'params.dateStart'(_) {
        this.getData();
      },
      'params.dateEnd'(_) {
        this.getData()
      } 
    },
    computed: {

    },
    methods: {
        openDetailModal(record) {
          this.detailData = record.detail
        },
        updateDateStart(value) {
          this.params.dateStart = value;
        },
        updateDateEnd(value) {
          this.params.dateEnd = value
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
            .dispatch("recap/getRecapDebitCredit", {startDate: moment(this.params.dateStart).format('YYYY-MM-DD'), endDate: moment(this.params.dateEnd).format('YYYY-MM-DD')})
                .then((resp) => {
                  this.data = resp.data
                  this.dataInfo = resp.info
                  loading.hide()
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        formatDate(item) {
            // Assuming dateString is in a different format, you need to format it to YYYY-MM-DD
            const date = new Date(item);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        formatCurrency(value) {
          if (!value && value !== 0) return '';
          // Convert value to a string
          let stringValue = value.toString();
          // Split the string into integer and decimal parts
          let parts = stringValue.split('.');
          // Format the integer part with thousand separators
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          // Join the parts and return the formatted value
          return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
        },
    },
    mounted() {
        this.getData();
    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>
