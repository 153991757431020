<template>
  <div class="content container-fluid">
    <!-- Profile settings -->
    <div class="row">
      <div class="col-md-12">
        <!-- Account Settings -->
        <div class="widget-title">
          <h4>Account Settings</h4>
        </div>
        <h6 class="user-title">Profile Picture</h6>
        <div class="pro-picture">
          <div class="pro-img">
            <img src="@/assets/img/profiles/avatar-02.jpg" alt="user" />
          </div>
          <div class="pro-info">
            <div class="d-flex">
              <div class="img-upload">
                <i class="feather-upload-cloud me-1"></i>Upload
                <input type="file" />
              </div>
              <a href="javascript:;" class="btn btn-remove">Remove</a>
            </div>
            <p>
              *image size should be at least 320px big,and less then 500kb. Allowed files
              .png and .jpg.
            </p>
          </div>
        </div>
        <h6 class="user-title">General Information</h6>
        <div class="general-info">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Name</label>
                <input type="text" class="form-control" placeholder="Enter your name" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">User Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your user name"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Email Address"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Mobile Number</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Mobile Number"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Gender</label>
                <vue-select :options="Gender" placeholder="Select Gender" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Date of birth</label>
                <div class="form-icon">
                  <datepicker
                    v-model="startdate"
                    :input-format="dateFormat"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    placeholder="DD/MM/YYYY"
                  />
                  <span class="cus-icon"><i class="feather-calendar"></i></span>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="col-form-label d-block"
                  >Your Bio
                  <span class="brief-bio float-end"
                    >Brief description for your profile. URLs are hyperlinked.</span
                  ></label
                >
                <textarea
                  class="form-control"
                  placeholder="Add a Short Bio....."
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <h6 class="user-title">Address</h6>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="col-form-label"
                >Address<span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" placeholder="Enter your address" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label"
                >Country<span class="text-danger">*</span></label
              >
              <input type="text" class="form-control" placeholder="Enter your Country" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label"
                >State<span class="text-danger">*</span></label
              >
              <input type="email" class="form-control" placeholder="Enter your state" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label">City<span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter your city" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label"
                >Postal Code<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Enter your postal code"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label">Currency Code</label>
              <vue-select :options="Currency" placeholder="Choose Currency" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-form-label">Language</label>
              <vue3-tags-input
                class="input-tags form-control"
                type="text"
                data-role="tagsinput"
                name="specialist"
                id="specialist"
                :tags="tags"
              />
            </div>
          </div>
        </div>
        <div class="acc-submit">
          <a href="javascript:;" class="btn btn-secondary">Cancel</a>
          <a href="javascript:;" class="btn btn-primary">Save Changes</a>
        </div>
      </div>
      <!-- /Account Settings -->
    </div>
    <!-- /profile-settings -->
  </div>
</template>

<script>
import { ref } from "vue";
import Vue3TagsInput from "vue3-tags-input";
const currentDate = ref(new Date());
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      Currency: ["Choose Currency", "Eur", "Aud"],
      Gender: ["Select Gender", "Male", "Female"],
      tags: ["English", "French"],
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
    };
  },
};
</script>

<style>
.v3ti .v3ti-tag {
  background-color: #f4f7ff;
  color: #74788d;
}
.v3ti .v3ti-tag .v3ti-remove-tag {
  color: black;
}

.v3ti.input-tags.form-control:focus {
  outline: none;
}
</style>
