<template>
  <!-- popular service -->
  <section class="popular-service-seven-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-seven aos" data-aos="fade-up">
            <h2>Most Popular Services</h2>
            <p>What do you need to find?</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel popular-service-seven">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexsevenpopular" :key="item.id">
                <div
                  class="service-widget service-widget-space service-two service-seven aos"
                  data-aos="fade-up"
                >
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="fav-item">
                      <router-link to="/categories"
                        ><span class="item-cat">{{ item.badge }}</span></router-link
                      >
                      <a
                        href="javascript:void(0)"
                        class="fav-icon"
                        @click="toggleSelected"
                      >
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                    <div class="item-info">
                      <router-link to="/providers"
                        ><span class="item-img"
                          ><img
                            :src="require(`@/assets/img/profiles/${item.avatar}`)"
                            class="avatar"
                            alt=""
                          />
                          Jeny Doe</span
                        ></router-link
                      >
                    </div>
                  </div>
                  <div class="service-content service-content-seven">
                    <h3 class="title">
                      <router-link to="/service-details">{{ item.title }}</router-link>
                    </h3>
                    <p>
                      <span class="rate"><i class="feather-phone"></i>28-62-76-32</span
                      ><i class="feather-map-pin"></i>New Jersey, USA
                    </p>
                    <div class="serv-info">
                      <div class="rating">
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <span>(234)</span>
                      </div>
                      <h6>{{ item.price }}</h6>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /popular service -->

  <!-- Work Section -->
  <section class="work-section-seven">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-seven aos" data-aos="fade-up">
            <h2>How It Works</h2>
            <p>Aliquam lorem ante, dapibus in, viverra quis</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="work-box-seven aos" data-aos="fade-up">
            <div class="work-icon-seven">
              <h1>1</h1>
              <span>
                <img src="@/assets/img/icons/work-seven-3.svg" alt="img" />
              </span>
            </div>
            <h5>Choose What To Do</h5>
            <p>
              Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra
              nulla ut metus varius laoreet.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="work-box-seven aos" data-aos="fade-up">
            <div class="work-icon-seven">
              <h1>2</h1>
              <span>
                <img src="@/assets/img/icons/work-seven-2.svg" alt="img" />
              </span>
            </div>
            <h5>Find What You Want</h5>
            <p>
              Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra
              nulla ut metus varius laoreet.
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="work-box-seven aos" data-aos="fade-up">
            <div class="work-icon-seven">
              <h1>3</h1>
              <span>
                <img src="@/assets/img/icons/work-seven-1.svg" alt="img" />
              </span>
            </div>
            <h5>Amazing Places</h5>
            <p>
              Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra
              nulla ut metus varius laoreet.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Work Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexsevenpopular from "@/assets/json/indexsevenpopular.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexsevenpopular: indexsevenpopular,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    toggleSelected(event) {
      const element = event.target;
      element.classList.toggle("selected");
    },
  },
};
</script>
