<template>
  <div
    class="modal fade"
    id="add-reply"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">REPLY CONTACT</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Content</label>
                  <div id="editor"></div>
                  <summernoteEditor
                    v-model="myValue"
                    @update:modelValue="summernoteChange($event)"
                    @summernoteImageLinkInsert="summernoteImageLinkInsert"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button type="submit" class="btn btn-primary">Save Changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import SummernoteEditor from "vue3-summernote-editor";
export default {
  components: {
    SummernoteEditor,
  },
  data() {
    return {
      myValue: "description",
    };
  },
  methods: {
    summernoteChange(newValue) {
      // console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      // console.log("summernoteImageLinkInsert()", args);
    },
    submitForm() {
      this.$router.push("/admin/contact-messages-view");
    },
  },
};
</script>
