<template>
    <div class="content">
        <div class="container">
            <div class="row">

                <!-- Category List -->
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-fill">
                        <div class="category-img">
                            <router-link to="/search">
                                <img src="@/assets/img/services/service-06.jpg" class="img-fluid" alt="">
                            </router-link>
                        </div>
                        <div class="category-info">
                            <div class="category-name">
                                <span class="category-icon">
                                    <img src="@/assets/img/icons/category-01.svg" alt="">
                                </span>
                                <h6><router-link to="/search">Computer</router-link></h6>
                            </div>
                            <p>22 Services</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-fill">
                        <div class="category-img">
                            <router-link to="/search">
                                <img src="@/assets/img/services/service-09.jpg" class="img-fluid" alt="">
                            </router-link>
                        </div>
                        <div class="category-info">
                            <div class="category-name">
                                <span class="category-icon">
                                    <img src="@/assets/img/icons/category-02.svg" alt="">
                                </span>
                                <h6><router-link to="/search">Cleaning</router-link></h6>
                            </div>
                            <p>22 Services</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-fill">
                        <div class="category-img">
                            <router-link to="/search">
                                <img src="@/assets/img/services/service-01.jpg" class="img-fluid" alt="">
                            </router-link>
                        </div>
                        <div class="category-info">
                            <div class="category-name">
                                <span class="category-icon">
                                    <img src="@/assets/img/icons/category-03.svg" alt="">
                                </span>
                                <h6><router-link to="/search">Electrical</router-link></h6>
                            </div>
                            <p>22 Services</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-fill">
                        <div class="category-img">
                            <router-link to="/search">
                                <img src="@/assets/img/services/service-10.jpg" class="img-fluid" alt="">
                            </router-link>
                        </div>
                        <div class="category-info">
                            <div class="category-name">
                                <span class="category-icon">
                                    <img src="@/assets/img/icons/category-04.svg" alt="">
                                </span>
                                <h6><router-link to="/search">Construction</router-link></h6>
                            </div>
                            <p>22 Services</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-fill">
                        <div class="category-img">
                            <router-link to="/search">
                                <img src="@/assets/img/services/service-15.jpg" class="img-fluid" alt="">
                            </router-link>
                        </div>
                        <div class="category-info">
                            <div class="category-name">
                                <span class="category-icon">
                                    <img src="@/assets/img/icons/category-05.svg" alt="">
                                </span>
                                <h6><router-link to="/search">Interior</router-link></h6>
                            </div>
                            <p>22 Services</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-fill">
                        <div class="category-img">
                            <router-link to="/search">
                                <img src="@/assets/img/services/service-08.jpg" class="img-fluid" alt="">
                            </router-link>
                        </div>
                        <div class="category-info">
                            <div class="category-name">
                                <span class="category-icon">
                                    <img src="@/assets/img/icons/category-06.svg" alt="">
                                </span>
                                <h6><router-link to="/search">Car Wash</router-link></h6>
                            </div>
                            <p>22 Services</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-fill">
                        <div class="category-img">
                            <router-link to="/search">
                                <img src="@/assets/img/services/service-11.jpg" class="img-fluid" alt="">
                            </router-link>
                        </div>
                        <div class="category-info">
                            <div class="category-name">
                                <span class="category-icon">
                                    <img src="@/assets/img/icons/category-07.svg" alt="">
                                </span>
                                <h6><router-link to="/search">Plumbing</router-link></h6>
                            </div>
                            <p>22 Services</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-fill">
                        <div class="category-img">
                            <router-link to="/search">
                                <img src="@/assets/img/services/service-03.jpg" class="img-fluid" alt="">
                            </router-link>
                        </div>
                        <div class="category-info">
                            <div class="category-name">
                                <span class="category-icon">
                                    <img src="@/assets/img/icons/category-08.svg" alt="">
                                </span>
                                <h6><router-link to="/search">Carpentry</router-link></h6>
                            </div>
                            <p>22 Services</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 d-flex">
                    <div class="category-card flex-fill">
                        <div class="category-img">
                            <router-link to="/search">
                                <img src="@/assets/img/services/service-16.jpg" class="img-fluid" alt="">
                            </router-link>
                        </div>
                        <div class="category-info">
                            <div class="category-name">
                                <span class="category-icon">
                                    <img src="@/assets/img/icons/category-09.svg" alt="">
                                </span>
                                <h6><router-link to="/search">Appliance</router-link></h6>
                            </div>
                            <p>22 Services</p>
                        </div>
                    </div>
                </div>
                <!-- /Category List -->

            </div>
        </div>
    </div>
</template>