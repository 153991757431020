<template>
  <!-- Add Wallet -->
  <div class="modal fade custom-modal" id="add-payout">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content doctor-profile">
        <div class="modal-header border-bottom-0 justify-content-between pb-0">
          <h5 class="modal-title">Set Your Payouts</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitFormOne">
            <div class="wallet-add">
              <div class="row">
                <div class="col-md-4">
                  <div class="bank-selection">
                    <input
                      type="radio"
                      value="attach_link"
                      id="rolelink"
                      name="attachment"
                      checked=""
                    />
                    <label for="rolelink">
                      <img src="@/assets/img/paypal.png" alt="" />
                      <span class="role-check"
                        ><i class="fa-solid fa-circle-check"></i
                      ></span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="bank-selection">
                    <input
                      type="radio"
                      value="attach_link"
                      id="rolelink1"
                      name="attachment"
                    />
                    <label for="rolelink1">
                      <img src="@/assets/img/stripe.png" alt="" />
                      <span class="role-check"
                        ><i class="fa-solid fa-circle-check"></i
                      ></span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="bank-selection">
                    <input
                      type="radio"
                      value="attach_link"
                      id="rolelink2"
                      name="attachment"
                    />
                    <label for="rolelink2">
                      <img src="@/assets/img/bank.png" alt="" />
                      <span class="role-check"
                        ><i class="fa-solid fa-circle-check"></i
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Card Number</label>
                <input type="text" class="form-control" placeholder="Enter Card Number" />
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <b-button type="submit" variant="primary">Submit</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Wallet -->

  <!-- Delete Account -->
  <div class="modal fade custom-modal" id="del-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sureyou want to delete This Account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <b-button type="submit" variant="danger">Delete Account</b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/login");
    },
    submitFormOne() {
      this.$router.push("/provider-payout");
    },
  },
};
</script>
