<template>
    <div class="home-page-five">
        <div class="main-wrapper">
            <div class="page-wrapper">
                
                <indexfivebanner />

                <indexfivefeatured />

                <indexfivecategories />

                <indexfivetestimonial />

                <indexfivepartner />

                <indexfiveservice />

                <indexfiveapp />

                <indexfivefooter />

                <scroll />
            </div>
        </div>
    </div>
</template>