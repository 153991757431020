<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Bank Transfer</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="tab-sets">
              <div class="tab-contents-sets">
                <ul>
                  <li>
                    <router-link to="/admin/banktransferlist" class="active"
                      >All List</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/admin/approved-transferlist">Approved </router-link>
                  </li>
                  <li>
                    <router-link to="/admin/pending-transferlist">Pending </router-link>
                  </li>
                  <li>
                    <router-link to="/admin/successful-transferlist"
                      >Successful
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/admin/rejected-transferlist"
                      >Rejected lists</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Service'">
                      <td>
                        <a href="javascript:void(0);" class="table-imgname">
                          <img
                            :src="
                              require(`@/assets/admin_img/services/${record.Service_img}`)
                            "
                            class="me-2"
                            alt="img"
                          />
                          <span>{{ record.Service }}</span>
                        </a>
                      </td>
                    </template>
                    <template v-else-if="column.key === 'Customer'">
                      <a href="javascript:void(0);" class="table-profileimage">
                        <img
                          :src="
                            require(`@/assets/admin_img/customer/${record.Customer_img}`)
                          "
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Customer }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'Receipt'">
                      <a href="javascript:void(0);" class="table-imgname">
                        <img
                          :src="require(`@/assets/admin_img/${record.Receipt_img}`)"
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Receipt }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <td>
                        <h6 :class="record.class">{{ record.Status }}</h6>
                      </td>
                    </template>
                    <template v-else-if="column.key === 'Action'">
                      <td class="text-center">
                        <a
                          class="delete-table"
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                        >
                          <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"
                              >Transfer View</a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0);" class="dropdown-item"
                              >Transfer Edit</a
                            >
                          </li>
                        </ul>
                      </td>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Customer",
    dataIndex: "Customer",
    key: "Customer",
    sorter: {
      compare: (a, b) => {
        a = a.Customer.toLowerCase();
        b = b.Customer.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Receipt",
    dataIndex: "Receipt",
    key: "Receipt",
    sorter: {
      compare: (a, b) => {
        a = a.Receipt.toLowerCase();
        b = b.Receipt.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "Description",
    sorter: {
      compare: (a, b) => {
        a = a.Description.toLowerCase();
        b = b.Description.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "Action",
    sorter: true,
  },
];
const data = [
  {
    id: "1",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    Customer_img: "user-01.jpg",
    Customer: "John Smith",
    Receipt_img: "invoice.jpg",
    Receipt: "#123456",
    Description: "Lorem ipsum dolor sit",
    Date: "28 Sep 2023",
    class: "badge-pending",
    Status: "Pending",
  },
  {
    id: "2",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    Customer_img: "user-04.jpg",
    Customer: "Johnny",
    Receipt_img: "invoice.jpg",
    Receipt: "#123456",
    Description: "Lorem ipsum dolor sit",
    Date: "23 Sep 2023",
    class: "badge-active",
    Status: "Successful",
  },
  {
    id: "3",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    Customer_img: "user-02.jpg",
    Customer: "Amanda",
    Receipt_img: "invoice.jpg",
    Receipt: "#123456",
    Description: "Lorem ipsum dolor sit",
    Date: "13 Sep 2023",
    class: "badge-inactive",
    Status: "Approved",
  },
  {
    id: "4",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    Customer_img: "user-01.jpg",
    Customer: "James",
    Receipt_img: "invoice.jpg",
    Receipt: "#123456",
    Description: "Lorem ipsum dolor sit",
    Date: "10 Sep 2023",
    class: "badge-delete",
    Status: "Rejected",
  },
  {
    id: "5",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    Customer_img: "user-01.jpg",
    Customer: "John Smith",
    Receipt_img: "invoice.jpg",
    Receipt: "#123456",
    Description: "Lorem ipsum dolor sit",
    Date: "28 Sep 2023",
    class: "badge-pending",
    Status: "Pending",
  },
  {
    id: "6",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    Customer_img: "user-04.jpg",
    Customer: "Johnny",
    Receipt_img: "invoice.jpg",
    Receipt: "#123456",
    Description: "Lorem ipsum dolor sit",
    Date: "23 Sep 2023",
    class: "badge-active",
    Status: "Successful",
  },
  {
    id: "7",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    Customer_img: "user-02.jpg",
    Customer: "Amanda",
    Receipt_img: "invoice.jpg",
    Receipt: "#123456",
    Description: "Lorem ipsum dolor sit",
    Date: "13 Sep 2023",
    class: "badge-inactive",
    Status: "Approved",
  },
  {
    id: "8",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    Customer_img: "user-01.jpg",
    Customer: "James",
    Receipt_img: "invoice.jpg",
    Receipt: "#123456",
    Description: "Lorem ipsum dolor sit",
    Date: "10 Sep 2023",
    class: "badge-delete",
    Status: "Rejected",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
