<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>List Pelunasan</h4>
                  <p>Kamu bisa lihat daftar pelunasan order kendaraan disni!</p>
                </div>
                <div class="col-md-6 text-md-end aos" data-aos="fade-up">
                    
                </div>
              </div>

              <div class="content-page-header content-page-headersplit mb-0 mt-4">
                <div class="list-btn">
                  <ul>
                    <li>
                      <div class="input-group">
                        <div class="col-4" style="margin-right: 10px;">
                          <div class="input-group">
                            <input type="text" v-model="paramsPelunasan.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" @keyup.enter="search">
                            <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                          </div>
                        </div>
                        <div class="col-2">
                          <select class="form-select" v-model="paramsPelunasan.keyword" @change="search">
                              <option disabled value="">Pilih Pelanggan</option>
                              <option v-for="customer in dataCustomer" :key="customer.id" :value="customer.name">{{ customer.name }}</option>
                          </select>
                        </div>
                        <div class="col-2">
                            <select class="form-select" v-model="paramsPelunasan.status" @change="search">
                                <option value="Sudah Lunas">Sudah Lunas</option>
                                <option value="Belum Lunas">Belum Lunas</option>
                            </select>
                        </div>
                        <div class="ms-auto">
                          <button
                            @click="createInvoiceWrapper()"
                            class="btn btn-primary"
                            :disabled="!canCreateInvoice(selectedOrders)"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#tambah-invoice"
                          >Bikin Invoice</button>
                        </div>
                        <!-- <input type="date" v-model="params.dateStart" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text">s/d</span>
                        <input type="date" v-model="params.dateEnd" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text"></span>
                        <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button> -->
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <div class="table-resposnive table-div">
                    <div class="table datatable">
                      <a-table :columns="columns" :data-source="data" :pagination="pagination" style="overflow-x: auto;">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'Status'">
                            <h6 v-if="record.status === 'AKTIF'" class="badge-active">{{ record.status }}</h6>
                            <h6 v-else class="badge-active">{{ record.status }}</h6>
                          </template>
                          <template v-if="column.key === 'selection' && paramsPelunasan.status == 'Belum Lunas'">
                            <input class="form-check-input" type="checkbox" @input="onOrderSelected($event, record)">
                          </template>
                          <!-- <template v-if="column.key === 'id'">
                            <div class="table-actions d-flex">
                              <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#show-order"
                                @click="openEditModal(record)"
                              >
                                <i class="feather feather-eye"></i>
                              </button>

                              <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#add-pelunasan"
                                @click="openEditModal(record)"
                                >
                                <i class="feather feather-dollar-sign"></i>
                              </button>
                            </div>
                          </template> -->
                        </template>
                      </a-table>
                    </div>
                  </div>
                </div>
              </div>

              <order-model :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :customerData="dataCustomer" :vehicleData="dataVehicle" :brandData="dataBrand" :userData="dataUser" :vehicleId="vehicleId ? vehicleId : null" @dataUpdated="getData"></order-model>
              <inspection-model :editData="editData" :productData="dataProduct" :customerData="dataCustomer" :vehicleData="dataVehicle" :brandData="dataBrand" :userData="dataUser" :orderData="dataOrder" @dataUpdated="getData"></inspection-model>
              <pelunasan-model :editData="editData" @dataUpdated="getData"></pelunasan-model>
              <invoice-model :selectedOrders="selectedOrdersData" :customers="dataCustomer" @createInvoice="createInvoice"></invoice-model>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="previewPdf" @click="previewPdf = null" class="modal-backdrop fade show">
    </div>
    <iframe class="position-absolute" v-if="previewPdf" title="pdf" :src="previewPdf" :style="{ height: '80%', width: '60%', zIndex: '10000', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }"></iframe>

  </template>
  
  <script>
  const columns = [
    {
      title: "Pilih",
      key: "selection",
    },
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "No Order",
      dataIndex: "no_order",
      key: "no_order",
      sorter: {
        compare: (a, b) => {
          a = a.no_order.toLowerCase();
          b = b.no_order.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "customerName",
      sorter: {
        compare: (a, b) => {
          a = a.customerName.toLowerCase();
          b = b.customerName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      sorter: {
        compare: (a, b) => {
          a = a.productName.toLowerCase();
          b = b.productName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Tanggal Berangkat",
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        compare: (a, b) => {
          a = a.startDate.toLowerCase();
          b = b.startDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Tanggal Kembali",
      dataIndex: "finishDate",
      key: "finishDate",
      sorter: {
        compare: (a, b) => {
          a = a.finishDate.toLowerCase();
          b = b.finishDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Total Sewa",
      dataIndex: "totalPrice",
      key: "totalPrice",
      sorter: {
        compare: (a, b) => {
          a = a.totalPrice.toLowerCase();
          b = b.totalPrice.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: totalPrice => `${totalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.00`,
    },
    {
      title: "Total DP",
      dataIndex: "totalDp",
      key: "totalDp",
      sorter: {
        compare: (a, b) => {
          a = a.totalDp.toLowerCase();
          b = b.totalDp.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    
    {
      title: "Status Pembayaran",
      dataIndex: "status_payment",
      key: "status_payment",
      sorter: {
        compare: (a, b) => {
          a = a.status_payment.toLowerCase();
          b = b.status_payment.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Action",
    //   key: "id",
    //   sorter: true,
    //   class: "text-end",
    // },
  ];
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            dateStart: '',
            dateEnd: '',
            jadwal: false,
            driverId: null
        },
        paramsCustomer: {
            row: 999999,
            sortby: "id",
            sorttype: "asc",
        },
        paramsPelunasan: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            dateStart: '',
            dateEnd: '',
            jadwal: false,
            driverId: null,
            status: 'Belum Lunas'
        },
        paramUser: {
            row: 10,
            keyword: "Driver",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataProduct: [],
        dataVehicle: [],
        dataCustomer: [],
        dataVendor: [],
        dataUser: [],
        dataBrand: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        totalB: 0,
        totalP: 0,
        total: 0,
        dataOrder: {},
        previewPdf: null,
        dataUserCompany: null,
        orderId: null,
        selectedOrders: [],
        selectedOrdersData: null
      };
    },
    name: "ordersetting",
    computed: {

    },
    methods: {
        onOrderSelected(event, record) {
          if (event.target.checked) {
            this.selectedOrders.push(record)
          } else {
            this.selectedOrders = this.selectedOrders.filter((order) => order.id != record.id)
          }
        },
        canCreateInvoice(orders) {
          if (!Array.isArray(orders)) {
            return false 
          }

          if (orders.length == 0) {
            return false
          }

          const duplicates = orders.reduce((acc, order) => {
            if (!acc.some((obj) => obj.customer_id == order.customer_id)) {
              acc.push(order)
            }

            return acc
          }, [])

          return duplicates.length == 1
        },
        createInvoiceWrapper() {
          this.selectedOrdersData = { data: this.selectedOrders }
        },
        async createInvoice(data) {
          var loading = this.$loading.show();
          const selectedOrderIds = data.orders.map((order) => {
            return order.id
          })
          let isOk = true;
          let no_invoice = '';

          try {
            const response = await this.$store.dispatch('financeInvoice/createInvoice', {
              customer_id: data.customer_id,
              order_ids: selectedOrderIds,
              category: data.formCreate.category,
              denda: data.formCreate.denda
            })

            no_invoice = response.data.no_invoice;
            
          } catch (e) {
            isOk = false
            this.$toast.error(e);
          } finally {
            loading.hide()
          }

          if (isOk) {
            // this.$router.push('/mari/invoice')
            this.$router.push({ path: '/mari/invoice', query: { keyword: no_invoice } });
          }
        },
        search() {
          console.log('paramsPelunanasa', this.paramsPelunasan)
          this.params.page = 1;
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.paramsPelunasan.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.paramsPelunasan.row = size;
          this.paramsPelunasan.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("order/getOrderPelunasan", this.paramsPelunasan)
                .then((resp) => {
                  console.log(resp);
                  const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
                    this.data = resp.data.data.map((item, index) => {
                        const sDate = new Date(item.starting_date);
                        const fDate = new Date(item.finish_date);
                        const time = new Date(item.starting_time);
                        // const startTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        // const vehicle = item.vehicle; //this.dataVehicle.find(vehicle => vehicle.id === item.vehicle_id);
                        // const vehicleName = vehicle ? vehicle.name : '';
                        const product = item.product; //this.dataProduct.find(product => product.id === item.product_id);
                        let rentalTypeName;
                        if (product && product.rental_type == 1) {
                            rentalTypeName = "Sewa Lepas Kunci";
                        } else {
                            rentalTypeName = "Sewa Dengan Driver";
                        }
                        const productName = product ? product.name : '';
                        const customer = item.customer;  //this.dataCustomer.find(customer => customer.id === item.customer_id);
                        const customerName = customer ? customer.name : '';
                        const totalPrice = this.formatCurrency(item.total_price);
                        const totalDp = this.formatCurrency(item.dp);
                        return {
                            ...item,
                            index: startIndex + index + 1,
                            startDate: sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear(),
                            finishDate: fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear(),
                            // startTime,
                            // vehicleName,
                            productName,
                            customerName,
                            totalPrice,
                            totalDp
                        };
                    });
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    this.totalB = resp.totalB !== null ? resp.totalB : 0;
                    this.totalP = resp.totalP !== null ? resp.totalP : 0;
                    this.total = resp.total !== null ? resp.total : 0;
                    this.params.dateStart = this.formatDate(resp.dateStart);
                    this.params.dateEnd = this.formatDate(resp.dateEnd);
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        async openPreviewInvoice(data) {
          const previewBlob = await this.$store.dispatch('invoice/getPreview', data.id)
          const newBlob = new Blob([previewBlob], { type: 'application/pdf' });

          // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
          } else {
              // For other browsers: create a link pointing to the ObjectURL containing the blob.
              const objUrl = window.URL.createObjectURL(newBlob);

              this.previewPdf = objUrl


              // For Firefox it is necessary to delay revoking the ObjectURL.
              setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
          }
          console.log('preview', previewBlob)
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openReportModal(data) {
          // Set the edit data before opening the modal
          this.orderId = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
        openInspectionModal(data) {
          this.dataOrder = { data };
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params)
                .then((resp) => {
                    this.dataProduct = resp.data.data.map((item, index) => {
                        let rentalTypeName;
                        if (item.rental_type == 1) {
                            rentalTypeName = "Sewa Lepas Kunci";
                        } else {
                            rentalTypeName = "Sewa Dengan Driver";
                        }
                        return {
                            ...item,
                            rentalTypeName,
                        };
                    });
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVehicle() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params)
                .then((resp) => {
                    this.dataVehicle = resp.data.data.map((item,index) => {
                      const user = JSON.parse(localStorage.getItem('user'))
                      let vendorName = '---'
                      if (user && user.company) {
                        vendorName = user.company.name
                      }
                      if (item.vendor_id) {
                        const data = this.dataVendor.find(vendor => vendor.id === item.vendor_id);
                        vendorName = data.full_name;
                      }
                      const vehicleName = item.name + ' - ' + item.nomor_plat + ' - ' + vendorName;
                      return {
                        ...item,
                        vehicleName
                      };
                    });
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getCustomer() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("customer/getCustomer", this.paramsCustomer)
                .then((resp) => {
                    this.dataCustomer = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getUser() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("user/getUser", this.paramUser)
                .then((resp) => {
                    this.dataUser = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVendor() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vendor/getVendor", this.params)
                .then((resp) => {
                  this.dataVendor = resp.data.data;
                  loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getBrand() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("brand/getBrand", this.params)
                .then((resp) => {
                  this.dataBrand = resp.data.data;
                  loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        formatDate(item) {
            // Assuming dateString is in a different format, you need to format it to YYYY-MM-DD
            const date = new Date(item);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getLoginCompany() {
          const userData = localStorage.getItem('user');
          const user = JSON.parse(userData);
          this.userLogin = user;

          if (!user.company_id || !user.company) {
            return
          }

          var loading = this.$loading.show();
          this.$store
            .dispatch("company/showCompany", {id : user.company_id})
            .then((resp) => {
                this.dataUserCompany = resp.data.data;
                this.isOwner = true;
                loading.hide();
            })
            .catch((e) => {
                this.$toast.error(e);
                loading.hide();
            });
        },
        sendWhatsAppMessage(order) {
          if (!this.dataUserCompany) {
            alert('CANT DO THE ACTION, maybe you logged in as an superadmin, cause your company id = 0')
            return
          }

          const companyName = this.dataUserCompany.name;
          const { no_order, dp, total_price, customerName, jml_org, pick_up_point, interpoint, remark, payment_method } = order;
          const customerSelected = this.dataCustomer.find(customer => customer.id === order.customer_id);
          const phone_number = customerSelected ? customerSelected.phone_number : null;
          // const email = customerSelected ? customerSelected.email : null;
          const productSelected = this.dataProduct.find(product => product.id === order.product_id);
          const productName = productSelected ?  productSelected.rentalTypeName + ' - ' + productSelected.name : '';
          const vehicleSelected = this.dataVehicle.find(vehicle => vehicle.id === order.vehicle_id);
          const vehicleName = vehicleSelected ? vehicleSelected.name : '';
          const sDate = new Date(order.starting_date);
          const startDate = sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear();
          const fDate = new Date(order.finish_date);
          const finishDate = fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear();
          const countryCode = '62'; // Example country code for Indonesia
          const formattedPhoneNumber = `+${countryCode}${phone_number.slice(1)}`; // Format phone number
          const message = `${companyName}\nNo Order ${no_order}\nNama Pemesan : ${customerName}\nNomor telpon : ${formattedPhoneNumber}\nJumlah Pax : ${jml_org}\nPilihan Produk Sewa Mobil : ${productName}\nJenis Kendaraan di pesan : ${vehicleName}\nTanggal dan Waktu Penjemputan : ${startDate}\nTanggal akhir Penghantaran : ${finishDate}\nAlamat Penjemputan/Pengambilan : ${pick_up_point}\nRute Tujuan Awal dan Akhir Pemakaian Kendaraan : ${interpoint}\nHARGA DEAL : ${total_price}\nDEPOSITE PAYMENT : ${dp}\n\nMetode Pembayaran : ${payment_method}\nRequest / Catatan : ${remark}`;
          const encodedMessage = encodeURIComponent(message);
          const whatsappUrl = `https://api.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`;
          window.open(whatsappUrl, '_blank');
        },
        formatCurrency(value) {
          if (!value && value !== 0) return '';
          // Convert value to a string
          let stringValue = value.toString();
          // Split the string into integer and decimal parts
          let parts = stringValue.split('.');
          // Format the integer part with thousand separators
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          // Join the parts and return the formatted value
          return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
        },
    },
    mounted() {
        this.getLoginCompany();
        this.getVendor();
        this.getBrand();
        this.getProduct();
        this.getVehicle();
        this.getCustomer();
        this.getUser();
        this.getData();
    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>
