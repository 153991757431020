<template>
    <div class="main-wrapper">
        <headertop />
        <indextwoheader /> 

        <div class="page-wrapper">
            <indextwohero />

            <indextwofeaturedservice />

            <indextwopopular />

            <indextwopricing />

            <indextwoclient />

            <indextwopartners />

            <indextwofooter />

            <scroll />
        </div>
    </div>
</template>