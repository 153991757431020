<template>
  <!-- latest section -->
  <section class="services-section latest-section">
    <div class="container">
      <div class="services-header aos" data-aos="fade-up">
        <div class="row">
          <div class="col-md-12">
            <div class="section-content">
              <h2>Our Latest News</h2>
              <div class="our-img-all">
                <img src="@/assets/img/icons/scissor.svg" alt="" />
              </div>
              <p>
                Our Barbershop & Tattoo Salon provides classic services combined with
                innovative techniques.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel latest-slider aos" data-aos="fade-up">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexthreelatest" :key="item.id">
                <div class="service-widget service-widget-space">
                  <div class="service-img service-latest-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="latest-date">
                      <span>15</span>
                      Nov 2023
                    </div>
                  </div>
                  <div class="service-content latest-content">
                    <span>{{ item.title }}</span>
                    <p>Consectetur adipisicing elit, sed do eiusmod</p>
                    <a href="javascript:;" class="latest-news">Read More</a>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /latest section -->

  <!-- register section -->
  <section class="register-section aos" data-aos="fade-up">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="register-content">
            <h2>Get Registered and List your Saloon for free!!!</h2>
            <div class="register-btn">
              <a href="javascript:;"><i class="feather-users me-2"></i>Register /</a>
              <router-link to="login">Login</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--/register section  -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexthreelatest from "@/assets/json/indexthreelatest.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexthreelatest: indexthreelatest,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
