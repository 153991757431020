<template>
    <div class="card add-service">
        <div class="row">
            <div class="col-md-12">
                <div class="sub-title">
                    <h6>Location</h6>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label class="col-form-label">Address</label>
                    <input type="text" class="form-control" placeholder="Enter Your Address">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-form-label">Country</label>
                    <input type="text" class="form-control" placeholder="Enter Country">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-form-label">City</label>
                    <input type="text" class="form-control" placeholder="Enter Your City">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-form-label">State</label>
                    <input type="text" class="form-control" placeholder="Enter Your State">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-form-label">Pincode</label>
                    <input type="text" class="form-control" placeholder="Enter Your Pincode">
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label class="col-form-label">Google Maps Place ID</label>
                    <input type="text" class="form-control" placeholder="Enter Maps Place ID">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-form-label">Latitude</label>
                    <input type="text" class="form-control" placeholder="Enter Latitude Number">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-form-label">Longitude</label>
                    <input type="text" class="form-control" placeholder="Enter Longitude Number">
                </div>
            </div>
            <div class="form-group mb-0">
                <div class="map-grid">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
                        allowfullscreen="" aria-hidden="false" tabindex="0" class="contact-map"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },

}
</script>