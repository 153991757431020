<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  import 'leaflet/dist/leaflet.css';

export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style>
  .error-feedback {
    color: red;
  }
</style>
