<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <div class="sidebar-header">
      <div class="sidebar-logo">
        <router-link to="/admin/dashboard">
          <img src="@/assets/admin_img/logo.svg" class="img-fluid logo" alt="" />
        </router-link>
        <router-link to="/admin/dashboard">
          <img
            src="@/assets/admin_img/logo-small.svg"
            class="img-fluid logo-small"
            alt=""
          />
        </router-link>
      </div>
      <div class="siderbar-toggle">
        <label class="switch" id="toggle_btn">
          <input type="checkbox" @click="toggleSidebar" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="sidebar-inner slimscroll">
      <perfect-scrollbar
        class="scroll-area"
        :settings="settings"
        @ps-scroll-y="scrollHanle"
      >
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li class="menu-title m-0">
              <h6>Home</h6>
            </li>
            <li
              v-bind:class="{
                active: currentPath == 'index',
              }"
            >
              <router-link to="/admin/dashboard"
                ><i class="feather feather-grid"></i> <span>Dashboard</span>
              </router-link>
              <!-- <router-link to="/admin-setting/monitoring"
                ><i class="feather feather-grid"></i> <span>Monitoring</span>
              </router-link> -->
              <router-link to="/admin-setting/monitoring2"
                ><i class="feather feather-monitor"></i> <span>Monitoring</span>
              </router-link>
            </li>
            <li class="menu-title">
              <h6>Pengaturan</h6>
            </li>
            <li>
              <router-link v-if="company_id == 0" to="/admin-setting/role"
                ><i class="feather feather-settings"></i>
                <span>Role</span>
              </router-link>
              <router-link v-if="company_id == 0" to="/admin-setting/menu"
                ><i class="feather feather-settings"></i>
                <span>Menu</span>
              </router-link>
              <router-link v-if="company_id == 0" to="/admin-setting/acces"
                ><i class="feather feather-settings"></i>
                <span>Access Menu</span>
              </router-link>
              <router-link to="/admin-setting/company"
                ><i class="feather feather-user"></i>
                <span>Perusahaan</span>
              </router-link>
              <!-- <router-link to="/admin-setting/branch"
                ><i class="feather feather-users"></i>
                <span>Cabang</span>
              </router-link> -->
              <router-link to="/admin-setting/papper"
                ><i class="feather feather-printer"></i>
                <span>Ukuran Kertas</span>
              </router-link>
              <router-link to="/admin-setting/fines"
                ><i class="feather feather-dollar-sign"></i>
                <span>Denda</span>
              </router-link>
            </li>
            <li class="menu-title">
              <h6>Master</h6>
            </li>
            <li>
              <router-link to="/admin-setting/user"
                ><i class="feather feather-user-plus"></i>
                <span>User</span>
              </router-link>
              <router-link to="/admin-setting/customer"
                ><i class="feather feather-users"></i>
                <span>Pelanggan</span>
              </router-link>
              <router-link to="/admin-setting/product"
                ><i class="feather feather-sliders"></i>
                <span>Paket Sewa</span>
              </router-link>
              <router-link to="/admin-setting/vehicle"
                ><i class="feather feather-layers"></i>
                <span>Kendaraan</span>
              </router-link>
              <!-- <router-link to="/admin-setting/check-vehicle"
                ><i class="feather feather-layers"></i>
                <span>Kendaraan Tersedia</span>
              </router-link> -->
            </li>
            <li class="menu-title">
              <h6>Transaksi</h6>
            </li>
            <li>
              <router-link to="/admin-setting/order"
                ><i class="feather feather-shopping-cart"></i>
                <span>Order</span>
              </router-link>
              <!-- <router-link to="/admin-setting/jadwal-order"
                ><i class="feather feather-calendar"></i>
                <span>Jadwal Order</span>
              </router-link> -->
              <router-link to="/admin-setting/return-list"
                ><i class="feather feather-shopping-cart"></i>
                <span>List Pengembalian</span>
              </router-link>
              <router-link to="/admin-setting/expenditure"
                ><i class="feather feather-dollar-sign"></i>
                <span>Pengeluaran</span>
              </router-link>
              <router-link to="/admin-setting/maintenance"
                ><i class="feather feather-settings"></i>
                <span>Service</span>
              </router-link>
              <router-link to="/admin-setting/jadwal-maintenance"
                ><i class="feather feather-calendar"></i>
                <span>Jadwal Service</span>
              </router-link>
            </li>
            <li class="menu-title">
              <h6>Laporan</h6>
            </li>
            <li>
              <router-link to="/admin-setting/keuangan"
                ><i class="feather feather-dollar-sign"></i>
                <span>Keuangan</span>
              </router-link>
            </li>
            <li class="menu-title">
              <h6>Bantuan</h6>
            </li>
            <li>
              <router-link to="/admin-setting/berlangganan"
                ><i class="feather feather-phone-incoming"></i>
                <span>Berlangganan</span>
              </router-link>
              <router-link to="/admin-setting/bantuan"
                ><i class="feather feather-help-circle"></i>
                <span>Bantuan</span>
              </router-link>
            </li>
            <!-- <li class="menu-title">
              <h6>Services</h6>
            </li>
            <li class="submenu">
              <a
                href="#sidebarServices"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarServices"
                :class="{ active: ServicesMenu, subdrop: ServicesMenu }"
              >
                <i class="feather feather-briefcase"></i>
                <span>Services</span>
                <span class="menu-arrow"
                  ><i class="feather feather-chevron-right"></i
                ></span>
              </a>
              <ul class="collapse" id="sidebarServices" :class="{ show: ServicesMenu }">
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/add-services',
                    }"
                    to="/admin/add-service"
                    >Add Service</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{
                      active:
                        currentPath == '/admin/services' ||
                        currentPath == '/admin/active-services' ||
                        currentPath == '/admin/pending-services' ||
                        currentPath == '/admin/inactive-services' ||
                        currentPath == '/admin/deleted-services',
                    }"
                    to="/admin/services"
                    >Services</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/service-settings',
                    }"
                    to="/admin/service-settings"
                    >Service Settings</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/admin/categories"
                ><i class="feather feather-file-text"></i>
                <span>Categories</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/sub-categories"
                ><i class="feather feather-clipboard"></i>
                <span>Sub Categories</span></router-link
              >
            </li>
            <li class="submenu">
              <a
                href="#sidebarReview"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarReview"
                :class="{ active: ReviewMenu, subdrop: ReviewMenu }"
                ><i class="feather fas fa-star"></i>
                <span>Review</span>
                <span class="menu-arrow"
                  ><i class="feather feather-chevron-right"></i
                ></span>
              </a>
              <ul class="collapse" id="sidebarReview" :class="{ show: ReviewMenu }">
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/review-type',
                    }"
                    to="/admin/review-type"
                    >Review Type</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{ active: currentPath == '/admin/review' }"
                    to="/admin/review"
                    >Review</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="menu-title">
              <h6>Booking</h6>
            </li>
            <li>
              <router-link
                to="/admin/booking"
                v-bind:class="{
                  active:
                    currentPath == '/admin/booking' ||
                    currentPath == '/admin/pending-booking' ||
                    currentPath == '/admin/inprogress-booking' ||
                    currentPath == '/admin/completed-booking' ||
                    currentPath == '/admin/cancelled-booking',
                }"
                ><i class="feather feather-smartphone"></i>
                <span> Bookings</span></router-link
              >
            </li>
            <li class="menu-title">
              <h6>Finance & Accounts</h6>
            </li>
            <li>
              <router-link
                to="/admin/banktransferlist"
                v-bind:class="{
                  active:
                    currentPath == '/admin/banktransferlist' ||
                    currentPath == '/admin/approved-transferlist' ||
                    currentPath == '/admin/pending-transferlist' ||
                    currentPath == '/admin/successful-transferlist' ||
                    currentPath == '/admin/rejected-transferlist',
                }"
                ><i class="feather feather-file"></i>
                <span>Bank Transfer</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/wallet"
                ><i class="feather feather-credit-card"></i>
                <span>Wallet</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/refund-request"
                ><i class="feather feather-git-pull-request"></i>
                <span>Refund Request</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/cash-on-delivery"
                ><i class="feather feather-dollar-sign"></i>
                <span>Cash on Delivery</span></router-link
              >
            </li>
            <li class="submenu">
              <a
                href="#sidebarPayout"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarPayout"
                :class="{ active: PayoutMenu, subdrop: PayoutMenu }"
                ><i class="feather feather-credit-card"></i>
                <span>Payouts</span>
                <span class="menu-arrow"
                  ><i class="feather feather-chevron-right"></i
                ></span>
              </a>
              <ul class="collapse" id="sidebarPayout" :class="{ show: PayoutMenu }">
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/payout-request',
                    }"
                    to="/admin/payout-request"
                    >Payout Requests</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/payout-settings',
                    }"
                    to="/admin/payout-settings"
                    >Payout Settings</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/admin/sales-transactions"
                ><i class="feather feather-bar-chart"></i>
                <span>Sales Transactions</span></router-link
              >
            </li>
            <li class="menu-title">
              <h6>Others</h6>
            </li>
            <li>
              <router-link to="/admin/chat"
                ><i class="feather feather-message-square"></i>
                <span>Chat</span></router-link
              >
            </li>
            <li class="menu-title">
              <h6>Content</h6>
            </li>
            <li class="submenu">
              <a
                href="#sidebarAdd"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarAdd"
                :class="{ active: AddMenu, subdrop: AddMenu }"
                ><i class="feather feather-file"></i>
                <span>Pages</span>
                <span class="menu-arrow"
                  ><i class="feather feather-chevron-right"></i
                ></span>
              </a>
              <ul class="collapse" id="sidebarAdd" :class="{ show: AddMenu }">
                <li>
                  <router-link
                    v-bind:class="{
                      active:
                        currentPath == '/admin/add-page' ||
                        currentPath == '/admin/edit-page',
                    }"
                    to="/admin/add-page"
                    >Add Page</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/pages-list',
                    }"
                    to="/admin/pages-list"
                    >Pages</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{ active: currentPath == '/admin/page-list' }"
                    to="/admin/page-list"
                    >Pages List</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="submenu">
              <a
                href="#sidebarAll"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarAll"
                :class="{ active: AllMenu, subdrop: AllMenu }"
                ><i class="feather feather-file-text"></i>
                <span>Blog</span>
                <span class="menu-arrow"
                  ><i class="feather feather-chevron-right"></i
                ></span>
              </a>
              <ul class="collapse" id="sidebarAll" :class="{ show: AllMenu }">
                <li>
                  <router-link
                    v-bind:class="{
                      active:
                        currentPath == '/admin/all-blog' ||
                        currentPath == '/admin/pending-blog' ||
                        currentPath == '/admin/inactive-blog',
                    }"
                    to="/admin/all-blog"
                    >All Blog</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{
                      active:
                        currentPath == '/admin/add-blog' ||
                        currentPath == '/admin/edit-blog',
                    }"
                    to="/admin/add-blog"
                    >Add Blog</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/blogs-categories',
                    }"
                    to="/admin/blogs-categories"
                    >Categories</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{
                      active: currentPath == '/admin/blogs-comments',
                    }"
                    to="/admin/blogs-comments"
                    >Blog Comments</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="submenu">
              <a
                href="#sidebarCountries"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarCountries"
                :class="{ active: CountriesMenu, subdrop: CountriesMenu }"
                ><i class="feather feather-map-pin"></i>
                <span>Location</span>
                <span class="menu-arrow"
                  ><i class="feather feather-chevron-right"></i
                ></span>
              </a>
              <ul class="collapse" id="sidebarCountries" :class="{ show: CountriesMenu }">
                <li>
                  <router-link
                    v-bind:class="{ active: currentPath == '/admin/countries' }"
                    to="/admin/countries"
                    >Countries</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{ active: currentPath == '/admin/states' }"
                    to="/admin/states"
                    >States</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{ active: currentPath == '/admin/cities' }"
                    to="/admin/cities"
                    >Cities</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/admin/testimonials"
                ><i class="feather fas fa-star"></i>
                <span>Testimonials</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/faq"
                ><i class="feather feather-help-circle"></i> <span>FAQ</span></router-link
              >
            </li>
            <li class="menu-title">
              <h6>Membership</h6>
            </li>
            <li>
              <router-link to="/admin/membership"
                ><i class="feather feather-user"></i> <span>Membership</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/membership-addons"
                ><i class="feather feather-user-plus"></i>
                <span>Membership Addons</span></router-link
              >
            </li>
            <li class="menu-title">
              <h6>Reports</h6>
            </li>
            <li>
              <router-link to="/admin/admin-earnings"
                ><i class="feather feather-user"></i>
                <span>Admin Earnings</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/provider-earnings"
                ><i class="feather feather-dollar-sign"></i>
                <span>Provider Earnings</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/provider-sales"
                ><i class="feather feather-dollar-sign"></i>
                <span>Provider Sales</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/provider-wallet"
                ><i class="feather feather-credit-card"></i>
                <span>Provider Wallet</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/customer-wallet"
                ><i class="feather feather-user"></i>
                <span>Customer Wallet</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/membership-transaction"
                ><i class="feather feather-tv"></i>
                <span>Membership Transaction</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/refund-report"
                ><i class="feather feather-file-text"></i>
                <span>Refund Report</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/register-report"
                ><i class="feather feather-git-pull-request"></i>
                <span>Register Report</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/service-sales"
                ><i class="feather feather-bar-chart"></i>
                <span>Sales Report</span>
              </router-link>
            </li>
            <li class="menu-title">
              <h6>User Management</h6>
            </li>
            <li class="submenu">
              <a
                href="#sidebarNew"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarNew"
                :class="{ active: NewMenu, subdrop: NewMenu }"
                ><i class="feather feather-user"></i>
                <span> Users</span>
                <span class="menu-arrow"
                  ><i class="feather feather-chevron-right"></i
                ></span>
              </a>
              <ul class="collapse" id="sidebarNew" :class="{ show: NewMenu }">
                <li>
                  <router-link
                    v-bind:class="{ active: currentPath == '/admin/users' }"
                    to="/admin/users"
                    >New User</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{ active: currentPath == '/admin/customers' }"
                    to="/admin/customers"
                    >Customers</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-bind:class="{ active: currentPath == '/admin/providers' }"
                    to="/admin/providers"
                    >Providers
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link
                v-bind:class="{
                  active:
                    currentPath == '/admin/roles' || currentPath == '/admin/permissions',
                }"
                to="/admin/roles"
                ><i class="feather feather-file"></i>
                <span>Roles & Permissions</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/delete-account-requests"
                ><i class="feather feather-trash-2"></i>
                <span>Delete Account Requests</span></router-link
              >
            </li>
            <li class="menu-title">
              <h6>Marketing</h6>
            </li>
            <li>
              <router-link to="/admin/coupons"
                ><i class="feather feather-bookmark"></i>
                <span>Coupons</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/offers"
                ><i class="feather feather-briefcase"></i>
                <span>Service Offers</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/featured-services"
                ><i class="feather feather-briefcase"></i>
                <span>Featured Services</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/email-newsletter"
                ><i class="feather feather-mail"></i>
                <span>Email Newsletter</span></router-link
              >
            </li>
            <li class="menu-title">
              <h6>Management</h6>
            </li>
            <li>
              <router-link to="/admin/cachesystem"
                ><i class="feather feather-user"></i>
                <span>Cache System</span>
              </router-link>
            </li>
            <li>
              <router-link to="/admin/email-templates"
                ><i class="feather feather-mail"></i>
                <span>Email Templates</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/sms-templates"
                ><i class="feather feather-message-square"></i>
                <span>SMS Templates</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/menu-management"
                ><i class="feather feather-file-text"></i>
                <span>Menu Management</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/website-settings"
                ><i class="feather feather-credit-card"></i>
                <span>Widgets</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/create-menu"
                ><i class="feather feather-list"></i>
                <span>Create Menu</span></router-link
              >
            </li>
            <li>
              <router-link
                to="/admin/plugins-manager"
                v-bind:class="{
                  active:
                    currentPath == '/admin/plugins-manager' ||
                    currentPath == '/admin/available-plugins',
                }"
                ><i class="feather feather-tv"></i>
                <span>Plugin Managers</span></router-link
              >
            </li>
            <li class="menu-title">
              <h6>Support</h6>
            </li>
            <li>
              <router-link
                v-bind:class="{
                  active:
                    currentPath == '/admin/contact-messages-view' ||
                    currentPath == '/admin/contact-messages',
                }"
                to="/admin/contact-messages"
                ><i class="feather feather-message-square"></i>
                <span>Contact Messages</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/abuse-reports"
                ><i class="feather feather-file-text"></i>
                <span>Abuse Reports</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/announcements"
                ><i class="feather feather-volume-2"></i>
                <span>Announcements</span></router-link
              >
            </li>
            <li class="menu-title">
              <h6>Settings</h6>
            </li>
            <li>
              <router-link
                v-bind:class="{
                  active:
                    currentPath == '/admin/localization' ||
                    currentPath == '/admin/account-settings' ||
                    currentPath == '/admin/security' ||
                    currentPath == '/admin/social-profiles' ||
                    currentPath == '/admin/notifications' ||
                    currentPath == '/admin/connected-apps' ||
                    currentPath == '/admin/appointment-settings' ||
                    currentPath == '/admin/site-information' ||
                    currentPath == '/admin/seo-settings' ||
                    currentPath == '/admin/preference-settings' ||
                    currentPath == '/admin/appearance' ||
                    currentPath == '/admin/header-settings' ||
                    currentPath == '/admin/footer-settings' ||
                    currentPath == '/admin/authentication-settings' ||
                    currentPath == '/admin/social-authentication' ||
                    currentPath == '/admin/language' ||
                    currentPath == '/admin/typography-settings' ||
                    currentPath == '/admin/email-settings' ||
                    currentPath == '/admin/sms-settings' ||
                    currentPath == '/admin/gdpr-settings' ||
                    currentPath == '/admin/calendar-settings' ||
                    currentPath == '/admin//payment-gateways' ||
                    currentPath == '/admin/payment-settings' ||
                    currentPath == '/admin/tax-rates' ||
                    currentPath == '/admin/currencies' ||
                    currentPath == '/admin/service-settings' ||
                    currentPath == '/admin/provider-settings' ||
                    currentPath == '/admin/storage-settings' ||
                    currentPath == '/admin/ban-ip-address' ||
                    currentPath == '/admin/cronjob' ||
                    currentPath == '/admin/system-backup' ||
                    currentPath == '/admin/database-backup' ||
                    currentPath == '/admin/system-information',
                }"
                to="/admin/localization"
                ><i class="feather feather-settings"></i>
                <span>Settings</span></router-link
              >
            </li>
            <li>
              <router-link to="/admin/signin"
                ><i class="feather feather-log-out"></i> <span>Logout</span></router-link
              >
            </li> -->
          </ul>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- /Sidebar -->
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
      ReviewMenus: false,
      PayoutMenus: false,
      AddMenus: false,
      AllMenus: false,
      CountriesMenus: false,
      NewMenus: false,
      ServicesMenus: false,
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
      company_id: null
    };
  },
  methods: {
    scrollHanle(evt) {},
    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("keyup", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchOptions.addEventListener("click", () => {
        searchInput.value = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#sidebar")) {
          let a = document
            .querySelector("#sidebar")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
    toggleSidebar() {
      const body = document.body;
      body.classList.toggle("mini-sidebar");
    },
  },
  mounted() {
    document.addEventListener("mouseover", function (e) {
      e.stopPropagation();
      if (
        document.body.classList.contains("mini-sidebar") &&
        document.getElementById("toggle_btn").offsetParent !== null
      ) {
        var target = e.target.closest(".sidebar");
        if (target) {
          document.body.classList.add("expand-menu");
          var subdropUl = document.querySelectorAll(".subdrop + ul");
          subdropUl.forEach(function (ul) {
            ul.style.display = "block";
          });
        } else {
          document.body.classList.remove("expand-menu");
          var subdropUl = document.querySelectorAll(".subdrop + ul");
          subdropUl.forEach(function (ul) {
            ul.style.display = "none";
          });
        }
        e.preventDefault();
        return false;
      }
    });
    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);
    this.company_id = user.company_id;
  },
  components: {
    PerfectScrollbar,
  },

  computed: {
    currentPath() {
      return this.$route.name;
    },
    ReviewMenu() {
      return (
        this.$route.name == "/admin/review-type" || this.$route.name == "/admin/review"
      );
    },
    ServicesMenu() {
      return (
        this.$route.name == "/admin/add-service" ||
        this.$route.name == "/admin/services" ||
        this.$route.name == "/admin/active-services" ||
        this.$route.name == "/admin/pending-services" ||
        this.$route.name == "/admin/inactive-services" ||
        this.$route.name == "/admin/deleted-services" ||
        this.$route.name == "/admin/service-settings"
      );
    },
    PayoutMenu() {
      return (
        this.$route.name == "/admin/payout-request" ||
        this.$route.name == "/admin/payout-settings"
      );
    },
    AddMenu() {
      return (
        this.$route.name == "/admin/add-page" ||
        this.$route.name == "/admin/pages-list" ||
        this.$route.name == "/admin/page-list" ||
        this.$route.name == "/admin/edit-page"
      );
    },
    AllMenu() {
      return (
        this.$route.name == "/admin/all-blog" ||
        this.$route.name == "/admin/add-blog" ||
        this.$route.name == "/admin/pending-blog" ||
        this.$route.name == "/admin/inactive-blog" ||
        this.$route.name == "/admin/blogs-categories" ||
        this.$route.name == "/admin/blogs-comments" ||
        this.$route.name == "/admin/edit-blog"
      );
    },
    CountriesMenu() {
      return (
        this.$route.name == "/admin/countries" ||
        this.$route.name == "/admin/states" ||
        this.$route.name == "/admin/cities"
      );
    },
    NewMenu() {
      return (
        this.$route.name == "/admin/users" ||
        this.$route.name == "/admin/customers" ||
        this.$route.name == "/admin/providers"
      );
    },
  },
};
</script>
