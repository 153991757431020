<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <sidelinkheading />

      <div class="content w-100">
        <div class="content-page-header">
          <h5>Social Profiles</h5>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="social-info">
              <div class="row align-items-center">
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>Name</label>
                    <vue-select :options="Name" placeholder="Facebook" />
                  </div>
                </div>
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>URL</label>
                    <vue-select :options="URL" placeholder="Ex. www.facebook.com" />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <label>&nbsp;</label>
                  <div class="form-group">
                    <div class="active-switch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="sliders round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center social-cont">
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>Name</label>
                    <vue-select :options="Name2" placeholder="Instagram" />
                  </div>
                </div>
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>URL</label>
                    <vue-select :options="URL1" placeholder="Ex. www.instagram.com" />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <label>&nbsp;</label>
                  <div class="form-group d-flex align-items-center">
                    <div class="active-switch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="sliders round"></span>
                      </label>
                    </div>
                    <a href="javascript:;" class="del-action ms-2 trash"
                      ><i class="feather feather-trash-2"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="row align-items-center social-cont">
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>Name</label>
                    <vue-select :options="Name4" placeholder="Youtube" />
                  </div>
                </div>
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>URL</label>
                    <vue-select :options="URL4" placeholder="Ex. www.youtube.com" />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <label>&nbsp;</label>
                  <div class="form-group d-flex align-items-center">
                    <div class="active-switch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="sliders round"></span>
                      </label>
                    </div>
                    <a href="javascript:;" class="del-action ms-2 trash"
                      ><i class="feather feather-trash-2"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="row align-items-center social-cont">
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>Name</label>
                    <vue-select :options="Name1" placeholder="Twitter" />
                  </div>
                </div>
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>URL</label>
                    <vue-select :options="URL2" placeholder="Ex. www.twitter.com" />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <label>&nbsp;</label>
                  <div class="form-group d-flex align-items-center">
                    <div class="active-switch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="sliders round"></span>
                      </label>
                    </div>
                    <a href="javascript:;" class="del-action ms-2 trash"
                      ><i class="feather feather-trash-2"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div
                class="row align-items-center social-cont"
                v-for="(certify3, index3) in certify3"
                :key="index3"
              >
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>Name</label>
                    <vue-select :options="Name3" placeholder="Linkedin" />
                  </div>
                </div>
                <div class="col-lg-5 col-sm-12">
                  <div class="form-group">
                    <label>URL</label>
                    <vue-select :options="URL3" placeholder="Ex. www.linkedin.com" />
                  </div>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <label>&nbsp;</label>
                  <div class="form-group d-flex align-items-center">
                    <div class="active-switch">
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="sliders round"></span>
                      </label>
                    </div>
                    <a href="javascript:;" class="del-action ms-2 trash"
                      ><i
                        class="feather feather-trash-2"
                        @click="deletecertifyThree(index3)"
                      ></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <a
              href="javascript:void(0);"
              class="link-sets social-add"
              @click="addcertifyThree"
              ><i class="fa fa-plus-circle me-2" aria-hidden="true"></i>Add More</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      certify3: [],
      Name: ["Facebook", "Instagram"],
      URL: ["Ex. www.facebook.com", "Ex. www.instagram.com"],
      Name1: ["Twitter", "facebook"],
      URL1: ["Ex. www.instagram.com", "Ex. www.facebook.com"],
      Name2: ["Instagram", "facebook"],
      URL2: ["Ex. www.twitter.com", "Ex. www.facebook.com"],
      Name3: ["Linkedin", "facebook"],
      URL3: ["Ex. www.linkedin.com", "Ex. www.facebook.com"],
      Name4: ["Youtube", "facebook"],
      URL4: ["Ex. www.youtube.com", "Ex. www.facebook.com"],
    };
  },
  methods: {
    addcertifyThree() {
      this.certify3.push({});
    },
    deletecertifyThree(index3) {
      this.certify3.splice(index3, 1);
    },
  },
};
</script>
