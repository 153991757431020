<template>
  <section class="hero-section-five">
    <indexfiveheader />
    <div class="container">
      <div class="home-banner home-banner-three">
        <div class="row align-items-center w-100">
          <div class="col-lg-6 col-md-12 mx-auto">
            <div class="section-search section-section-five">
              <h1>The Largest Professional <span>Car</span> Wash</h1>
              <h4>Search From 150 Awesome Verified Ads!</h4>
              <p>
                We value your time and therefore washing your car at our car wash will
                take no more than 15 minutes.
              </p>
              <div class="search-box search-box-five">
                <form @submit.prevent="submitForm" class="search-box-form">
                  <div class="search-input">
                    <div class="search-group-icon search-group-icon-5">
                      <i class="feather-map-pin"></i>
                    </div>
                    <div class="form-group mb-0">
                      <label>What</label>
                      <vue-select :options="What" placeholder="Job Title, Keywords" />
                    </div>
                  </div>
                  <div class="search-input">
                    <div class="search-group-icon search-group-icon-5">
                      <i class="feather-search"></i>
                    </div>
                    <div class="form-group mb-0">
                      <label>Where</label>
                      <vue-select :options="Where" placeholder="Choose Location" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="floating-img">
              <img src="@/assets/img/audi.png" alt="audi" class="img-fluid rotate-img" />
            </div>
            <div class="car-five-arrow-img">
              <img src="@/assets/img/car-five-arrow.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Feature Section -->

  <section class="featured-categories-section">
    <div class="featured-category-bg-img">
      <img src="@/assets/img/car-category-five.png" alt="" />
    </div>
    <div class="container">
      <div class="floating-five-main">
        <div class="floating-five-buttom">
          <div class="floating-five-buttom-all">
            <h5>1430 k</h5>
            <p>Cars Washed</p>
          </div>
          <div class="floating-five-buttom-img">
            <i class="feather-users"></i>
          </div>
        </div>
        <div class="floating-five-buttom">
          <div class="floating-five-buttom-all">
            <h5>987 k</h5>
            <p>Happy Clients</p>
          </div>
          <div class="floating-five-buttom-img floating-fives-buttom-img">
            <i class="feather-users"></i>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <div
            class="section-heading car-wash-heading car-wash-feature aos"
            data-aos="fade-up"
          >
            <div class="car-wash-img-five">
              <img
                src="@/assets/img/icons/car-wash-header-1.svg"
                alt=""
                class="car-wash-header-one"
              />
              <h2>Featured Categories</h2>
              <img
                src="@/assets/img/icons/car-wash-header-2.svg"
                alt=""
                class="car-wash-header-two"
              />
            </div>
            <p>What do you need to find?</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="service-widget service-two aos" data-aos="fade-up">
            <div class="service-img service-four-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  src="@/assets/img/services/service-54.jpg"
                />
              </router-link>
            </div>
            <div class="service-content service-content-five">
              <div class="feature-content-bottom">
                <p>Exterior Washing</p>
                <a href="javascript:void(0);"><i class="feather-users me-2"></i>15</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="service-widget service-two aos" data-aos="fade-up">
            <div class="service-img service-four-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  src="@/assets/img/services/service-55.jpg"
                />
              </router-link>
            </div>
            <div class="service-content service-content-five">
              <div class="feature-content-bottom">
                <p>Interior Washing</p>
                <a href="javascript:void(0);"><i class="feather-users me-2"></i>05</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="service-widget service-two aos" data-aos="fade-up">
            <div class="service-img service-four-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  src="@/assets/img/services/service-56.jpg"
                />
              </router-link>
            </div>
            <div class="service-content service-content-five">
              <div class="feature-content-bottom">
                <p>Vacuum Cleaning</p>
                <a href="javascript:void(0);"><i class="feather-users me-2"></i>95</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="service-widget service-two aos" data-aos="fade-up">
            <div class="service-img service-four-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  src="@/assets/img/services/service-57.jpg"
                />
              </router-link>
            </div>
            <div class="service-content service-content-five">
              <div class="feature-content-bottom">
                <p>Hand Car Wash</p>
                <a href="javascript:void(0);"><i class="feather-users me-2"></i>23</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="service-widget service-two aos" data-aos="fade-up">
            <div class="service-img service-four-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  src="@/assets/img/services/service-58.jpg"
                />
              </router-link>
            </div>
            <div class="service-content service-content-five">
              <div class="feature-content-bottom">
                <p>Chemical Car Wash</p>
                <a href="javascript:void(0);"><i class="feather-users me-2"></i>10</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="service-widget service-two aos" data-aos="fade-up">
            <div class="service-img service-four-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  src="@/assets/img/services/service-59.jpg"
                />
              </router-link>
            </div>
            <div class="service-content service-content-five">
              <div class="feature-content-bottom">
                <p>Steam Car Wash</p>
                <a href="javascript:void(0);"><i class="feather-users me-2"></i>45</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Feature Section -->
</template>

<script>
export default {
  data() {
    return {
      What: ["Job Title, Keywords", "Tornoto", "Texas"],
      Where: ["Choose Location", "Tornoto", "Texas"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
  },
};
</script>
