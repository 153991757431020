<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Add Role</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Role Name</label>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Enter Your Role Name "
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-groupheads">
                    <h2>Manage Permission</h2>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="location-setcontent">
                    <h5 class="mb-4">Manage Users</h5>
                  </div>
                </div>
                <div class="col-12">
                  <div class="filter-checkbox m-0 role-checkset">
                    <ul class="d-flex">
                      <li>
                        <h6>Create</h6>
                        <label class="checkboxs">
                          <input type="checkbox" />
                          <span><i></i></span>
                        </label>
                      </li>
                      <li>
                        <h6>View</h6>
                        <label class="checkboxs">
                          <input type="checkbox" />
                          <span><i></i></span>
                        </label>
                      </li>
                      <li>
                        <h6>Edit</h6>
                        <label class="checkboxs">
                          <input type="checkbox" />
                          <span><i></i></span>
                        </label>
                      </li>
                      <li>
                        <h6>Delete</h6>
                        <label class="checkboxs">
                          <input type="checkbox" />
                          <span><i></i></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" variant="primary"> Save Changes</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/roles");
    },
  },
};
</script>
