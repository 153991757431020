<template>
    <!-- Blog Sidebar -->
    <div class="col-lg-4 col-md-12 blog-sidebar  theiaStickySidebar">
        <div class="stickysidebar">

        <!-- Search -->
        <div class="card search-widget">
            <div class="card-body">
                <form class="search-form">
                    <div class="input-group">
                        <input type="text" placeholder="Search..." class="form-control" />
                        <b-button type="submit" variant="primary"><i class="fa fa-search"></i></b-button>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Search -->

        <!-- Categories -->
        <div class="card about-widget">
            <div class="card-body">
                <h4 class="side-title">About Me</h4>
                <img src="@/assets/img/profile.jpg" alt="">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam, </p>
                    <a href="javascript:;" class="btn btn-primary">About Author</a>
            </div>
        </div>
        <!-- /Categories -->

        <!-- Categories -->
        <div class="card category-widget">
            <div class="card-body">
                <h4 class="side-title">Categories</h4>
                <ul class="categories">
                    <li><router-link to="/categories">Car Wash</router-link></li>
                    <li><router-link to="/categories">Plumbing</router-link></li>
                    <li><router-link to="/categories">Carpenter</router-link></li>
                    <li><router-link to="/categories">Computer Service</router-link></li>
                </ul>
            </div>
        </div>
        <!-- /Categories -->

        <!-- Latest Posts -->
        <div class="card post-widget">
            <div class="card-body">
                <h4 class="side-title">Latest News</h4>
                <ul class="latest-posts">
                    <li>
                        <div class="post-thumb">
                            <router-link to="/blog-details">
                                <img class="img-fluid" src="@/assets/img/services/service-01.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="post-info">
                            <p>06 Nov 2023</p>
                            <h4>
                                <router-link to="/blog-details">Lorem ipsum dolor amet, consectetur adipiscing elit. Amet.</router-link>
                            </h4>
                        </div>
                    </li>
                    <li>
                        <div class="post-thumb">
                            <router-link to="/blog-details">
                                <img class="img-fluid" src="@/assets/img/services/service-02.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="post-info">
                            <p>06 Nov 2023</p>
                            <h4>
                                <router-link to="/blog-details">Lorem ipsum dolor amet, consectetur adipiscing elit. Amet.</router-link>
                            </h4>
                        </div>
                    </li>
                    <li>
                        <div class="post-thumb">
                            <router-link to="/blog-details">
                                <img class="img-fluid" src="@/assets/img/services/service-03.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="post-info">
                            <p>06 Nov 2023</p>
                            <h4>
                                <router-link to="/blog-details">Lorem ipsum dolor amet, consectetur adipiscing elit. Amet.</router-link>
                            </h4>
                        </div>
                    </li>
                    <li>
                        <div class="post-thumb">
                            <router-link to="/blog-details">
                                <img class="img-fluid" src="@/assets/img/services/service-04.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="post-info">
                            <p>06 Nov 2023</p>
                            <h4>
                                <router-link to="/blog-details">Lorem ipsum dolor amet, consectetur adipiscing elit. Amet.</router-link>
                            </h4>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Latest Posts -->

        <!-- Tags -->
        <div class="card tags-widget">
            <div class="card-body">
                <h4 class="side-title">Tags</h4>
                <ul class="tags">
                    <li><a href="javascript:;" class="tag">Consulting</a></li>
                    <li><a href="javascript:;" class="tag">Design</a></li>
                </ul>
            </div>
        </div>
        <!-- /Tags -->

        <!-- Archives -->
        <div class="card widget widget-archive mb-0">
            <div class="card-body">
                <h4 class="side-title">Archives</h4>
                <ul>
                    <li><a href="javascript:void(0);">January 2023</a></li>
                </ul>
            </div>
        </div>
        <!-- /Archives -->
    </div>
    </div>
    <!-- /Blog Sidebar -->

</template>