<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header d-flex justify-content-between">
                <h5>Edit Coupon</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" placeholder="Name" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Coupon</label>
                    <input type="text" class="form-control" placeholder="Coupon" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="form-group">
                    <label>Type</label>
                    <vue-select :options="Type" placeholder="English" />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <label>Discount</label>
                    <input type="text" class="form-control" value="5%" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Limit</label>
                    <input type="number" value="1" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Used</label>
                    <input type="number" value="1" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Valid Date</label>
                    <input
                      type="date"
                      value="02 Sep 2023 - 10 Sep 2023"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Services Name</label>
                    <input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="specialist"
                      value="House Cleaning,Painting"
                      id="specialist"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-grouphead d-flex justify-content-between">
                    <h2>Status</h2>
                    <div class="active-switch d-flex align-items-center">
                      <h6>Enable</h6>
                      <label class="switch">
                        <input type="checkbox" checked="" />
                        <span class="sliders round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" class="primary"> Save Changes</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Type: ["English", "French"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/coupons");
    },
  },
};
</script>
