<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <sidelinkheading />

      <div class="content w-100">
        <div class="content-page-header">
          <h5>Email Settings</h5>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <label>Mail Provider</label>
                  </div>
                  <div class="col-lg-6">
                    <div class="nav-menus location-set">
                      <h4>PHP Mail</h4>
                      <div class="settings-view">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#phpmail-config"
                          ><i class="feather feather-settings"></i
                        ></a>
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="nav-menus location-set">
                      <h4>SMTP</h4>
                      <div class="settings-view">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#smtp-config"
                          ><i class="feather feather-settings"></i
                        ></a>
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group-set">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-sm-6">
                          <h4>Email From Name</h4>
                          <h5>Lorem ipsum dolor sit amet, consectetur adipiscing</h5>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <div class="form-group mb-0">
                            <label>Name</label>
                            <input
                              type="text"
                              placeholder="Enter From Name"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group-set">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-sm-6">
                          <h4>Email From Address</h4>
                          <h5>Lorem ipsum dolor sit amet, consectetur adipiscing</h5>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <div class="form-group mb-0">
                            <label>Address</label>
                            <input
                              type="text"
                              placeholder="Enter Address"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group-set mb-0">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-sm-6">
                          <h4>Email Global Footer</h4>
                          <h5>Lorem ipsum dolor sit amet, consectetur adipiscing</h5>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                          <div class="form-group mb-0">
                            <label>Global Footer</label>
                            <input
                              type="text"
                              placeholder="Enter Your Message"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="form-groupheads">
                  <h2>Send Test Email</h2>
                </div>
                <div class="form-group">
                  <label>Default Currency</label>
                  <div class="group-image">
                    <img src="@/assets/admin_img/icons/clock.svg" alt="img" />
                    <vue-select :options="Currency" placeholder="USD" />
                  </div>
                </div>
                <div class="btn-path">
                  <a href="javascript:void(0);" class="btn btn-cancel me-3">Reset</a>
                  <a href="javascript:void(0);" class="btn btn-primary">Send Email</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <email-model></email-model>
</template>

<script>
export default {
  data() {
    return {
      Currency: ["USD", "INR"],
    };
  },
};
</script>
