<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Sales Transactions</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Customer'">
                      <div class="table-namesplit">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="
                              require(`@/assets/admin_img/customer/${record.Customer_Img}`)
                            "
                            class="me-2"
                            alt="img"
                          />
                        </a>
                        <a href="javascript:void(0);" class="table-name">
                          <span>{{ record.Customer }}</span>
                          <p>{{ record.Customer_Email }}</p>
                        </a>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Provider'">
                      <div class="table-namesplit">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="
                              require(`@/assets/admin_img/customer/${record.Provider_Img}`)
                            "
                            class="me-2"
                            alt="img"
                          />
                        </a>
                        <a href="javascript:void(0);" class="table-name">
                          <span>{{ record.Provider }}</span>
                          <p>{{ record.Provider_Email }}</p>
                        </a>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Service'">
                      <a href="javascript:void(0);" class="table-imgname">
                        <img
                          :src="
                            require(`@/assets/admin_img/services/${record.Service_Img}`)
                          "
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Service }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <h6 :class="record.class">{{ record.Status }}</h6>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <a class="delete-table me-2" href="javascript:void(0);">
                          <img src="@/assets/admin_img/icons/pdf.svg" alt="svg" />
                        </a>
                        <a
                          class="delete-table"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <img src="@/assets/admin_img/icons/delete.svg" alt="svg" />
                        </a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <delete-model :delete_title="delete_title" :delete_text="delete_text" />
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Customer",
    dataIndex: "Customer",
    key: "Customer",
    sorter: {
      compare: (a, b) => {
        a = a.Customer.toLowerCase();
        b = b.Customer.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Provider",
    dataIndex: "Provider",
    key: "Provider",
    sorter: {
      compare: (a, b) => {
        a = a.Provider.toLowerCase();
        b = b.Provider.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Discount",
    dataIndex: "Discount",
    sorter: {
      compare: (a, b) => {
        a = a.Discount.toLowerCase();
        b = b.Discount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Tax",
    dataIndex: "Tax",
    sorter: {
      compare: (a, b) => {
        a = a.Tax.toLowerCase();
        b = b.Tax.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payment Type",
    dataIndex: "Payment_Type",
    sorter: {
      compare: (a, b) => {
        a = a.Payment_Type.toLowerCase();
        b = b.Payment_Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];

const data = [
  {
    id: "1",
    Customer_Img: "user-01.jpg",
    Customer: "John Smith",
    Customer_Email: "johnsmith@gmail.com",
    Provider_Img: "user-04.jpg",
    Provider: "Robert",
    Provider_Email: "robert@gmail.com",
    Service_Img: "service-03.jpg",
    Service: "Computer Repair",
    Amount: "$80",
    Discount: "$4",
    Tax: "$2",
    Date: "28 Sep 2023",
    Payment_Type: "Paypal",
    class: "badge-active",
    Status: "successful",
    Action: "",
  },
  {
    id: "2",
    Customer_Img: "user-04.jpg",
    Customer: "Johnny",
    Customer_Email: "johnny@gmail.com",
    Provider_Img: "user-05.jpg",
    Provider: "Pricilla",
    Provider_Email: "pricilla@gmail.com",
    Service_Img: "service-02.jpg",
    Service: "Car Repair Services",
    Amount: "$100",
    Discount: "$10",
    Tax: "$3",
    Date: "27 Sep 2023",
    Payment_Type: "Paypal",
    class: "badge-delete",
    Status: "Refund",
    Action: "",
  },
  {
    id: "3",
    Customer_Img: "user-06.jpg",
    Customer: "Robert",
    Customer_Email: "robert@gmail.com",
    Provider_Img: "user-02.jpg",
    Provider: "Amanda",
    Provider_Email: "amanda@gmail.com",
    Service_Img: "service-04.jpg",
    Service: "Steam Car Wash",
    Amount: "$70",
    Discount: "$30",
    Tax: "$20",
    Date: "27 Sep 2023",
    Payment_Type: "Paypal",
    class: "badge-active",
    Status: "successful",
    Action: "",
  },
  {
    id: "4",
    Customer_Img: "user-09.jpg",
    Customer: "Sharonda",
    Customer_Email: "sharonda@gmail.com",
    Provider_Img: "user-01.jpg",
    Provider: "James",
    Provider_Email: "james@gmail.com",
    Service_Img: "service-09.jpg",
    Service: "House Cleaning",
    Amount: "$150",
    Discount: "$12",
    Tax: "$4",
    Date: "22 Sep 2023",
    Payment_Type: "Paypal",
    class: "badge-delete",
    Status: "Refund",
    Action: "",
  },
  {
    id: "5",
    Customer_Img: "user-01.jpg",
    Customer: "John Smith",
    Customer_Email: "johnsmith@gmail.com",
    Provider_Img: "user-04.jpg",
    Provider: "Sharon",
    Provider_Email: " sharon@gmail.com",
    Service_Img: "service-03.jpg",
    Service: "Computer Repair",
    Amount: "$100",
    Discount: "$10",
    Tax: "$3",
    Date: "27 Sep 2023",
    Payment_Type: "Paypal",
    class: "badge-delete",
    Status: "Refund",
    Action: "",
  },
];

export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
      delete_title: "Delete Sales Transactions",
      delete_text: "Are you sure want to Delete?",
    };
  },
};
</script>
