<template>
  <section class="blog-section partner-section-nine">
    <div class="container">
      <div class="row">
        <div class="owl-carousel quote-slider aos" data-aos="fade-up">
          <Carousel
            :autoplay="3000"
            :wrap-around="true"
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <Slide v-for="item in indexninepartner" :key="item.id">
              <div class="partner-img">
                <img :src="require(`@/assets/img/partner/${item.image}`)" alt="img" />
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexninepartner from "@/assets/json/indexninepartner.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexninepartner: indexninepartner,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
