<template>
    <!-- Cancel Appointment -->
		<div class="modal fade custom-modal" id="alertmsg">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content doctor-profile">
					<div class="modal-header border-bottom-0 justify-content-between">
						<h5 class="modal-title">Cancel Appointment</h5>
						<button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x"></i></button>
					</div>
					<div class="modal-body pt-0">
						<form action="javascript:;">
							<div class="msg-alert">
								<p>Are you sure you want to cnacel <span>John Doe</span> appointment on <span>Oct 28, 2023</span> at time <span>10AM - 12PM</span> </p>
								<p>You wan't be able to revert this action later?</p>
							</div>
							<div class="modal-submit text-end">
								<a href="javascript:;" class="btn btn-secondary me-2" data-bs-dismiss="modal">Dismiss</a>
								<button type="submit" class="btn btn-primary">Yes, cancel</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- /Cancel Appointment -->
</template>