<template>
  <!-- Footer -->
  <footer class="footer">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <div class="footer-logo">
                <router-link to="/"
                  ><img src="@/assets/img/mari/logo_marirental3.svg" alt="logo"
                /></router-link>
              </div>
              <div class="footer-content">
                <p>
                  Lorem ipsum dolor sit consectetur adipiscing elit, sed do eiusmod tempor
                  commodo consequat.
                </p>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-2 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Quick Links</h2>
              <ul>
                <li>
                  <router-link to="/about-us">About Us</router-link>
                </li>
                <li>
                  <router-link to="/contact-us">Contact Us</router-link>
                </li>
                <li>
                  <router-link to="/user-signup">Become a User</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-contact">
              <h2 class="footer-title">Contact Us</h2>
              <div class="footer-contact-info">
                <div class="footer-address">
                  <p>
                    <span><i class="feather-map-pin"></i></span> Cilengkrang,
                    Kota Bandung, Indonesia
                  </p>
                </div>
                <p>
                  <span><i class="feather-phone"></i></span> 321 546 8764
                </p>
                <p>
                  <span><i class="feather-mail"></i></span> admin@marirental.com
                </p>
                <p class="mb-0">
                  <span><i class="feather-globe"></i></span> www.marirental.com
                </p>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <h2 class="footer-title">Follow Us</h2>
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="javascript:;" target="_blank"
                      ><i class="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" target="_blank"
                      ><i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" target="_blank"
                      ><i class="fa-brands fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:;" target="_blank"
                      ><i class="fa-brands fa-linkedin"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <h2 class="footer-subtitle">Newsletter Signup</h2>
              <div class="subscribe-form">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Email Address"
                />
                <b-button type="submit" variant="btn footer-btn">
                  <i class="feather-send"></i>
                </b-button>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row align-items-center">
            <div class="col-md-4">
              <div class="copyright-text">
                <p class="mb-0">Copyright &copy; 2024. All Rights Reserved.</p>
              </div>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li>
                    <router-link to="/privacy-policy">Privacy Policy</router-link>
                  </li>
                  <li>
                    <router-link to="/terms-condition">Terms & Conditions</router-link>
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>

<script>
export default {
  data() {
    return {
      lang: ["English", "France", "Spanish"],
      Dollars: ["US Dollars", "INR", "Kuwait"],
    };
  },
};
</script>
