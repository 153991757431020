<template>
    <div class="provider-body">
        <div class="main-wrapper">
            <dashboardnavbar />

            <div class="page-wrapper">
                <sidebar />

                <providerservicecontent />

                <providersserviceactive />

                <providerserviceinactive />

                <providerservicedeleteservice />
            </div>
        </div>
    </div>
</template>