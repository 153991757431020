<template>
  <div class="content container-fluid">
    <div class="row">
      <div class="col-md-12">
        <!-- Security settings -->
        <div class="widget-title">
          <h4>Security Settings</h4>
        </div>
        <div class="linked-item">
          <div class="linked-wrap">
            <div class="linked-acc">
              <span class="link-icon">
                <img src="@/assets/img/icons/lock-icon.svg" alt="Lock" />
              </span>
              <div class="linked-info">
                <h6>Password</h6>
                <p>Last Changed <span>22 Sep 2023, 10:30:55 AM</span></p>
              </div>
            </div>
            <div class="linked-action">
              <button
                class="btn btn-secondary btn-set"
                data-bs-toggle="modal"
                data-bs-target="#change-password"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
        <div class="linked-item">
          <div class="row">
            <div class="col-md-8">
              <div class="linked-acc">
                <span class="link-icon">
                  <img src="@/assets/img/icons/scan-icon.svg" alt="Search" />
                </span>
                <div class="linked-info">
                  <h6>
                    Two Factor Authendication
                    <span class="badge badge-success">Enable</span>
                  </h6>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 text-md-end">
              <div class="d-flex align-items-center justify-content-md-end">
                <a href="javascript:;" class="btn btn-secondary btn-set">Disable</a>
                <div
                  class="status-toggle status-tog d-inline-flex justify-content-between align-items-center"
                >
                  <input type="checkbox" id="status_1" class="check" checked />
                  <label for="status_1" class="checktoggle">checkbox</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="linked-item">
          <div class="linked-wrap">
            <div class="linked-acc">
              <span class="link-icon">
                <img src="@/assets/img/icons/monitor-icon.svg" alt="image" />
              </span>
              <div class="linked-info">
                <h6>Device Management</h6>
                <p>Last Changed <span>22 Sep 2023, 10:30:55 AM</span></p>
              </div>
            </div>
            <div class="linked-action">
              <router-link
                to="provider-device-management"
                class="btn btn-secondary btn-set"
                >Manage</router-link
              >
            </div>
          </div>
        </div>
        <div class="linked-item">
          <div class="linked-wrap">
            <div class="linked-acc">
              <span class="link-icon">
                <img src="@/assets/img/icons/user-edit.svg" alt="image" />
              </span>
              <div class="linked-info">
                <h6>Account Activity</h6>
                <p>Last login <span>22 Sep 2023, 10:21:55 AM</span></p>
              </div>
            </div>
            <div class="linked-action">
              <router-link to="provider-login-activity" class="btn btn-secondary btn-set"
                >View All</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Security-settings -->
    </div>
  </div>
  <provider-settings-model></provider-settings-model>
</template>
