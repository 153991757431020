<template>
    <!-- Categories Section -->
    <section class="trust-us-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="section-heading category-heading aos" data-aos="fade-up">
                        <h2>Why Trust us</h2>
                        <p>We are growing day by day in terms of catering service providers listing,</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="trust-us-main">
                        <div class="trust-us-img">
                            <i class="fas fa-star"></i>
                        </div>
                        <h6>Verified Reviews</h6>
                        <p>We has more than 4000 Caterers & catering service companies (and counting) listed with
                            detailed information</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="trust-us-main">
                        <div class="trust-us-img">
                            <i class="feather-eye"></i>
                        </div>
                        <h6>Catering Services</h6>
                        <p>We has more than 4000 Caterers & catering service companies (and counting) listed with
                            detailed information</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="trust-us-main">
                        <div class="trust-us-img">
                            <i class="feather-file-text"></i>
                        </div>
                        <h6>Research & Surveys</h6>
                        <p>We has more than 4000 Caterers & catering service companies (and counting) listed with
                            detailed information</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div class="trust-us-main">
                        <div class="trust-us-img">
                            <i class="feather-briefcase"></i>
                        </div>
                        <h6>Experience In Business</h6>
                        <p>We has more than 4000 Caterers & catering service companies (and counting) listed with
                            detailed information</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Categories Section -->


    <!-- Popular Services -->
    <section class="popular-four-section">
        <div class="container">
            <div class="section-heading section-heading-four">
                <div class="row align-items-center">
                    <div class="col-md-6 aos" data-aos="fade-up">
                        <h2>Popular Locations</h2>
                        <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
                    </div>
                    <div class="col-md-6 text-md-end aos" data-aos="fade-up">
                        <router-link to="/categories" class="btn btn-primary btn-view">VIEW ALL LOCATIONS<i
                                class="feather-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
            <div class="popular-four-main">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">

                        <div class="popular-portfolio">
                            <a href="javascript:void(0);">
                                <div class="popular-portfolio-img">
                                    <img src="@/assets/img/gallery/gallery-img-5.jpg" alt="image">
                                    <div class="popular-portfolio-overlay">
                                        <h6>NewYork</h6>
                                        <p>165 Caterings</p>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:void(0);">
                                <div class="popular-portfolio-img">
                                    <img src="@/assets/img/gallery/gallery-img-6.jpg" alt="image">
                                    <div class="popular-portfolio-overlay">
                                        <h6>Los Angels</h6>
                                        <p>45 Caterings</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="popular-portfolio">
                            <a href="javascript:void(0);">
                                <div class="popular-portfolio-img">
                                    <img src="@/assets/img/gallery/gallery-img-4.jpg" alt="image">
                                    <div class="popular-portfolio-overlay">
                                        <h6>Istanbul</h6>
                                        <p>30 Caterings</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="popular-portfolio">
                            <a href="javascript:void(0);">
                                <div class="popular-portfolio-img">
                                    <img src="@/assets/img/gallery/gallery-img-7.jpg" alt="image">
                                    <div class="popular-portfolio-overlay">
                                        <h6>Paris</h6>
                                        <p>47 Caterings</p>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:void(0);">
                                <div class="popular-portfolio-img">
                                    <img src="@/assets/img/gallery/gallery-img-8.jpg" alt="image">
                                    <div class="popular-portfolio-overlay">
                                        <h6>London</h6>
                                        <p>78 Caterings</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-12">
                        <div class="popular-portfolio">
                            <a href="javascript:void(0);">
                                <div class="popular-portfolio-img">
                                    <img src="@/assets/img/gallery/gallery-img-9.jpg" alt="image">
                                    <div class="popular-portfolio-overlay">
                                        <h6>Florida</h6>
                                        <p>14 Caterings</p>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:void(0);">
                                <div class="popular-portfolio-img">
                                    <img src="@/assets/img/gallery/gallery-img-10.jpg" alt="image">
                                    <div class="popular-portfolio-overlay">
                                        <h6>Miami</h6>
                                        <p>54 Caterings</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Popular Services -->
</template>