<template>
  <!-- Banner Section -->
  <section class="hero-section-six">
    <indexsixheader />

    <div class="container">
      <div class="side-social-media-six">
        <ul>
          <li>
            <a href="javascript:void(0)"><i class="fa-brands fa-google"></i></a>
          </li>
          <li>
            <a href="javascript:void(0)"><i class="fa-brands fa-facebook-f"></i></a>
          </li>
          <li>
            <a href="javascript:void(0)"><i class="fa-brands fa-linkedin"></i></a>
          </li>
          <li>
            <a href="javascript:void(0)"><i class="fa-brands fa-instagram"></i></a>
          </li>
          <li>
            <a href="javascript:void(0)"><i class="fa-brands fa-twitter"></i></a>
          </li>
          <li>
            <a href="javascript:void(0)"><i class="fa-brands fa-whatsapp"></i></a>
          </li>
        </ul>
      </div>
      <div class="home-banner home-banner-six">
        <div class="row w-100 justify-content-center">
          <div class="col-lg-6 col-12">
            <div class="home-banner-main">
              <img src="@/assets/img/banner-six-bg.png" alt="Image" class="img-fluid" />
              <div class="home-banner-six-bg">
                <img src="@/assets/img/banner-six-bg2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="home-banner-six-bottom">
              <div class="home-banner-six-detergent">
                <img
                  src="@/assets/img/icons/cleaner-detergent.svg"
                  alt=""
                  class="img-fluid"
                />
                <h5>AFFORDABLE & RELIABLE</h5>
              </div>
              <h1>Professional Cleaning Service <span>You Can Trust</span></h1>
              <p>
                Our professional cleaning service comes up with a complete solution that
                makes your space sparkle!
              </p>
              <div class="search-box-two search-box-six">
                <form @submit.prevent="submitForm">
                  <div class="search-input-new line-six">
                    <div class="form-group mb-0">
                      <vue-select :options="Service" placeholder="Service Type" />
                    </div>
                  </div>
                  <div class="search-input-new line-two-six">
                    <div class="form-group mb-0">
                      <datepicker
                        :input-format="dateFormat"
                        v-model="startdate"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        placeholder="Select Date"
                      />
                      <i class="feather-calendar"></i>
                    </div>
                  </div>
                  <div class="search-btn">
                    <button class="btn search_service" type="submit">Book Now</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-six-side-img">
      <img src="@/assets/img/banner-six-side.png" alt="" />
    </div>
  </section>
  <!-- /Banner Section -->

  <!-- Choose Us -->
  <section class="reason-choose-us">
    <div class="container">
      <div class="section-heading section-heading-six">
        <div class="row">
          <div class="col-md-6 col-12 aos" data-aos="fade-up">
            <div class="reason-six">
              <img src="@/assets/img/icons/cleaner-six.svg" alt="" />
              <p>WHY TRUELY SELL?</p>
            </div>
            <h2>Reasons to <span>Choose Us</span></h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="total-client-all">
            <ul class="total-client-half">
              <li class="total-client-mini">
                <div class="total-experts-main">
                  <h3>540</h3>
                  <p>Total<span>Experts</span></p>
                </div>
              </li>
              <li class="total-client-mini">
                <div class="total-experts-main">
                  <h3>540</h3>
                  <p>Total<span>Experts</span></p>
                </div>
              </li>
              <li>
                <ul class="total-client-avatar">
                  <li>
                    <a href="javascript:;">
                      <img src="@/assets/img/profiles/avatar-06.jpg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <img src="@/assets/img/profiles/avatar-07.jpg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <img src="@/assets/img/profiles/avatar-08.jpg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <img src="@/assets/img/profiles/avatar-09.jpg" alt="" />
                    </a>
                  </li>
                  <li class="more-set">
                    <a href="javascript:;">
                      <i class="feather-plus"></i>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <p class="total-client-content">
                  Our professional cleaning service comes up with a
                  <span>complete solution that makes your space sparkle!</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-sm-4 col-12">
          <div class="professional-cleaning-main">
            <img src="@/assets/img/icons/quality-cleaning.svg" alt="" />
            <h5>High Quality Cleaning</h5>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 col-12">
          <div class="professional-cleaning-main">
            <img src="@/assets/img/icons/cdn-globe.svg" alt="" />
            <h5>Global Locations</h5>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 col-12">
          <div class="professional-cleaning-main">
            <img src="@/assets/img/icons/technical-support.svg" alt="" />
            <h5>Anytime Support</h5>
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 col-12">
          <div class="professional-cleaning-main">
            <img src="@/assets/img/icons/money-cash.svg" alt="" />
            <h5>Spend Efficiently</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Choose Us -->
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      Service: ["Service Type", "Tornoto", "Texas"],
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
  },
};
</script>
