<template>
  <div
    class="modal fade"
    id="image-view"
    tabindex="-1"
    aria-labelledby="image-view-label"
    aria-hidden="true"
    ref="imageModal"
  >
    <div class="modal-dialog modal-dialog-centered modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="image-view-label">Image View</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeImageModal"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <img v-if="imageData" :src="imageData" alt="Large Preview" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boundHandleBackButton: null,
      imageData: ''
    };
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
  },
  watch: {
    imageSrc(newSrc) {
      if (newSrc) {
        this.imageData = newSrc;
      }
    },
  },
  methods: {
    closeModal() {
        if (this.$refs.closeImageModal) {
            this.$refs.closeImageModal.click();
        }
    },
    handleBackButton(event) {
        event.preventDefault();
        this.closeModal();
    },
  },
  mounted() {
      this.boundHandleBackButton = this.handleBackButton.bind(this);
      window.addEventListener('popstate', this.boundHandleBackButton);
      window.history.pushState({ modalOpen: true }, null, window.location.href);
  },
  beforeDestroy() {
      window.removeEventListener('popstate', this.boundHandleBackButton);
  }
};
</script>

<style scoped>
.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0; /* Remove padding for better centering */
}

.img-fluid {
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

@media (max-width: 768px) {
  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }

  .img-fluid {
    max-width: 90%; /* Slight padding on smaller screens */
  }
}

@media (min-width: 768px) {
  .img-fluid {
    max-width: 80%; /* Allow more space on larger screens */
  }
}
</style>


