<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Membership Transaction</h5>
          <div class="list-btn">
            <ul>
              <li>
                <a class="btn-downloads down-pdf" href="javascript:;"
                  ><img src="@/assets/admin_img/icons/pdf-icon.svg" alt="img" /> Download
                  as PDF</a
                >
              </li>
              <li>
                <a class="btn-downloads down-excel" href="javascript:;"
                  ><img src="@/assets/admin_img/icons/excel-icon.svg" alt="img" />Download
                  as Excel
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <!-- Chart -->
            <div class="card card-chart-blk">
              <div class="card-head-blk">
                <div class="row align-items-center">
                  <div class="col-xl-6 col-md-6">
                    <div class="card-table-head">
                      <h4>Transaction report</h4>
                      <ul>
                        <li><span class="earning-black"></span>Amount</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-6">
                    <div class="earning-group">
                      <div class="sortby-section invoice-sort">
                        <div class="sorting-info">
                          <div class="sortby-filter-group court-sortby">
                            <div class="sortbyset week-bg me-0">
                              <div class="sorting-select">
                                <vue-select
                                  :options="ServiceDate"
                                  id="servicedate"
                                  placeholder="Date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sortby-section invoice-sort">
                        <div class="sorting-info">
                          <div class="sortby-filter-group court-sortby">
                            <div class="sortbyset week-bg me-0">
                              <div class="sorting-select">
                                <vue-select
                                  :options="ServiceMonth"
                                  id="servicemonth"
                                  placeholder="July"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sortby-section invoice-sort">
                        <div class="sorting-info">
                          <div class="sortby-filter-group court-sortby">
                            <div class="sortbyset week-bg me-0">
                              <div class="sorting-select">
                                <vue-select
                                  :options="ServiceYear"
                                  id="serviceYear"
                                  placeholder="2023"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsBar.chart"
                  :series="optionsBar.series"
                >
                </apexchart>
              </div>
            </div>
            <!-- /Chart -->
          </div>
        </div>
        <div class="card card-status">
          <div class="card-head-blk border-bottom-0">
            <div class="row align-items-center">
              <div class="col-xl-12 col-md-12">
                <div class="earning-group">
                  <div class="sortby-section status-provider">
                    <div class="sorting-info">
                      <div class="sortby-filter-group court-sortby">
                        <div class="sortbyset week-bg me-0">
                          <div class="sorting-select">
                            <vue-select
                              :options="ServiceSelect"
                              id="serviceselect"
                              placeholder="Select Service"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sortby-section status-provider">
                    <div class="sorting-info">
                      <div class="sortby-filter-group court-sortby">
                        <div class="sortbyset week-bg me-0">
                          <div class="sorting-select">
                            <vue-select
                              :options="ServiceProvider"
                              id="serviceprovider"
                              placeholder="Select Provider"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="chart-date-group">
                    <div class="cal-icon">
                      <datepicker
                        v-model="startdate"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        placeholder="Add Date"
                      />
                    </div>
                  </div>
                  <div class="chart-date-group">
                    <div class="cal-icon">
                      <datepicker
                        v-model="startdate1"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        placeholder="Add Date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <a-table class="table datatable" :columns="columns" :data-source="data">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Provider_Name'">
                    <a href="javascript:void(0);" class="table-profileimage">
                      <span>{{ record.Provider_Name }}</span>
                    </a>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Provider Name",
    dataIndex: "Provider_Name",
    key: "Provider_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Provider_Name.toLowerCase();
        b = b.Provider_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Subscription",
    dataIndex: "Subscription",
    sorter: {
      compare: (a, b) => {
        a = a.Subscription.toLowerCase();
        b = b.Subscription.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Durations",
    dataIndex: "Durations",
    sorter: {
      compare: (a, b) => {
        a = a.Durations.toLowerCase();
        b = b.Durations.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Start Date",
    dataIndex: "Start_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Start_Date.toLowerCase();
        b = b.Start_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "End Date",
    dataIndex: "End_Date",
    sorter: {
      compare: (a, b) => {
        a = a.End_Date.toLowerCase();
        b = b.End_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    id: "1",
    Provider_Name: "John Smith",
    Subscription: "Enterprice",
    Amount: "$650",
    Durations: "One Year",
    Start_Date: "05 May 2023 12:47 PM",
    End_Date: "05 May 2023 12:47 PM",
  },
  {
    id: "2",
    Provider_Name: "Johnny",
    Subscription: "Standard",
    Amount: "$350",
    Durations: "6 Month",
    Start_Date: "10 May 2023 12:47 PM",
    End_Date: "15 May 2023 12:47 PM",
  },
  {
    id: "3",
    Provider_Name: "Amanda",
    Subscription: "Basic",
    Amount: "$100",
    Durations: "One Year",
    Start_Date: "05 May 2023 12:47 PM",
    End_Date: "15 May 2023 12:47 PM",
  },
  {
    id: "4",
    Provider_Name: "James",
    Subscription: "Enterprice",
    Amount: "$150",
    Durations: "One Year",
    Start_Date: "05 May 2023 12:47 PM",
    End_Date: "15 May 2023 12:47 PM",
  },
  {
    id: "5",
    Provider_Name: "Jerry",
    Subscription: "Enterprice",
    Amount: "$650",
    Durations: "One Month",
    Start_Date: "06 Nov 2023 10:43 PM",
    End_Date: "06 Dec 2023 10:43 PM",
  },
  {
    id: "6",
    Provider_Name: "Pauline",
    Subscription: "Standard",
    Amount: "$350",
    Durations: "6 Month",
    Start_Date: "02 Nov 2023 02:14 AM",
    End_Date: "01 May 2023 02:14 AM",
  },
  {
    id: "7",
    Provider_Name: "Kim",
    Subscription: "Basic",
    Amount: "$100",
    Durations: "One Year",
    Start_Date: "27 Oct 2023 07:20 PM",
    End_Date: "26 Nov 2023 07:20 PM",
  },
  {
    id: "8",
    Provider_Name: "Robert",
    Subscription: "Enterprice",
    Amount: "$150",
    Durations: "One Year",
    Start_Date: "30 Oct 2023 08:04 AM",
    End_Date: "28 Apr 2023 08:04 AM",
  },
];
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDate1 = ref(new Date());
import { optionsBar } from "./data";
export default {
  data() {
    return {
      ServiceDate: ["Date", "01", "02", "03"],
      ServiceMonth: ["July", "Aug", "Sep"],
      ServiceYear: ["2023", "2022", "2021"],
      ServiceSelect: ["Select Status", "Completed", "UnCompleted"],
      ServiceProvider: ["Jerry", "Pauline", "Robert"],
      delete_title: "Delete Membership Transaction",
      delete_text: "Are you sure want to Delete?",
      Select: ["A - Z", "Z - A"],
      startdate: currentDate,
      startdate1: currentDate1,
      columns,
      data,
      optionsBar: optionsBar,
      title: "Charts",
      dateFormat: "dd-MM-yyyy",
    };
  },
};
</script>
