<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="content-page-header content-page-headersplit mb-0">
            <h5>Kendaraan</h5>
            <div class="list-btn">
              <ul>
                <li>
                  <div class="input-group">
                    <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="height: 43px !important;">
                    <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                  </div>
                </li>
                <li>
                  <router-link to="/admin-setting/create-vehicle"
                    class="btn btn-primary"
                    type="button"
                    >
                        <i class="fa fa-plus me-2"></i>Tambah Kendaraan
                  </router-link>
                  <!-- <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#add-vehicle"
                  >
                    <i class="fa fa-plus me-2"></i>Tambah Kendaraan
                  </button> -->
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-resposnive table-div">
                <div class="table datatable">
                  <a-table :columns="columns" :data-source="data" :pagination="pagination">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'Status'">
                        <h6 v-if="record.status === 'AKTIF'" class="badge-active">{{ record.status }}</h6>
                        <h6 v-else class="badge-active">{{ record.status }}</h6>
                      </template>
                      <template v-if="column.key === 'id'">
                        <div class="table-actions d-flex">
                            <button
                            class="btn delete-table me-2"
                            type="button"
                            @click="goToDetailPage(record)"
                          >
                            <i class="feather feather-eye"></i>
                          </button>
                          <button
                            class="btn delete-table me-2"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-vehicle"
                            @click="openEditModal(record)"
                          >
                            <i class="feather feather-edit"></i>
                          </button>
                          <button
                            class="btn delete-table"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                            @click="openDeleteModal(record)"
                          >
                            <i class="feather feather-trash-2"></i>
                          </button>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vehicle-model :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :brandData="dataBrand" :vendorData="dataVendor" @dataUpdated="getData"></vehicle-model>
  </template>
  
  <script>
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Jenis Kendaraan",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Nome Plat",
      dataIndex: "nomor_plat",
      key: "nomor_plat",
      sorter: {
        compare: (a, b) => {
          a = a.nomor_plat.toLowerCase();
          b = b.nomor_plat.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Transmisi",
      dataIndex: "transmisi",
      key: "transmisi",
      sorter: {
        compare: (a, b) => {
          a = a.transmisi.toLowerCase();
          b = b.transmisi.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
      key: "capacity",
      sorter: {
        compare: (a, b) => {
          a = a.capacity.toLowerCase();
          b = b.capacity.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Production Year",
      dataIndex: "production_year",
      key: "production_year",
      sorter: {
        compare: (a, b) => {
          a = a.production_year.toLowerCase();
          b = b.production_year.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Brand",
      dataIndex: "brandName",
      key: "brandName",
      sorter: {
        compare: (a, b) => {
          a = a.brandName.toLowerCase();
          b = b.brandName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendorName",
      key: "vendorName",
      sorter: {
        compare: (a, b) => {
          a = a.vendorName.toLowerCase();
          b = b.vendorName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataProduct: [],
        dataBrand: [],
        dataVendor: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        }
      };
    },
    name: "vehiclesetting",
    computed: {

    },
    methods: {
        search() {
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params)
                .then((resp) => {
                    this.data = resp.data.data.map((item, index) => {
                        const brand = this.dataBrand.find(brand => brand.id === item.brand_id);
                        const brandName = brand ? brand.name : '';
                        const vendor = this.dataVendor.find(vendor => vendor.id === item.vendor_id);
                        const vendorName = vendor ? vendor.full_name : '-';
                        return {
                          ...item,
                          index: index + 1 ,
                          brandName,
                          vendorName
                        };
                    });
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    loading.hide();
                    console.log(this.data);
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
        goToDetailPage(data) {
          this.$router.push(`/admin-setting/vehicle-detail/${data.id}`);
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params)
                .then((resp) => {
                    this.dataProduct = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getBrand() {
          var loading = this.$loading.show();
          this.$store
          .dispatch("brand/getBrand", this.params)
          .then((resp) => {
              this.dataBrand = resp.data.data;
              loading.hide();
          })
          .catch((e) => {
              this.$toast.error(e);
              loading.hide();
          });
        },
        getVendor() {
          var loading = this.$loading.show();
          this.$store
          .dispatch("vendor/getVendor", this.params)
          .then((resp) => {
              this.dataVendor = resp.data.data;
              loading.hide();
          })
          .catch((e) => {
              this.$toast.error(e);
              loading.hide();
          });
        },
    },
    mounted() {
        this.getVendor();
        this.getBrand();
        this.getData();
        this.getProduct();
    },
  };
  </script>
  <style>
  .ant-select-selection-item {
    height: 30px;
    width: 90px;
    margin-top: -6px;
    font-size: 15px;
  }
  </style>