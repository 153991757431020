<script setup>
  import { ref, reactive } from 'vue'

  const emit = defineEmits(['data-click', 'input'])

  const props = defineProps({
    list: {
      type: Array,
      default: []
    },
    listKey: {
      type: String,
      required: true
    },
    value: '',
    disabled: false
  })

  const isShowList = ref(false)
  const timeoutId = ref(null)

  function onFocusOut() {
    clearTimeout(timeoutId.value)
    timeoutId.value = setTimeout(() => {
        isShowList.value = false
    }, 100)
  }

  function onDataClick(data) {
    isShowList.value = false
    emit('data-click', data)
  }
</script>

<template> 
  <div class="position-relative" @focusout="onFocusOut">
    <input :disabled="props.disabled" :value="props.value" @input="emit('input', $event.target.value)" @focus="isShowList = true" class="form-control" type="text" />
    <ul v-if="isShowList" :class="{'invisible': !isShowList}" class="list-group position-absolute w-100" style="z-index: 100000;">
      <li v-if="value ? value.length > 0 : false" class="list-group-item">{{ value }}</li>
      <li v-for="data in props.list" :key="data[props.listKey]" @click="onDataClick(data)" class="list-group-item">
        <button class="btn btn-secondary" type="button">
            {{ data[props.listKey] }}
        </button>
      </li>
    </ul>
  </div>
</template>
