
<template>
    <div class="main-wrapper">
        <custombookcalendarnav />

        <div class="page-wrapper">
            <customercalendarcontent />

            <customerbookdetails />

            <customerreschedule />
        </div>
    </div>
</template>