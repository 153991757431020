import axios from 'axios'; 


export default {
    namespaced: true,
    state: {
        driver: {},
        // isDriver: false
        isDriver: JSON.parse(localStorage.getItem('isDriver')) || false

    },
    mutations: {
        setDriver(state, data) {
            state.isDriver = data;
            localStorage.setItem('isDriver', JSON.stringify(data));
        },
    },
    actions: {

        getUser(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/user', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addUser(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/register', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateUser(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('user/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteUser(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('user/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        showUser(_, id) {
            return new Promise((resolve, reject) => {
              axios.get(`/user/show/${id}`).then(resp => {
                resolve(resp.data);
              }).catch(e => {
                reject(e);
              });
            });
        },

        getDriverStaff(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/user/getUser', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateUserPassword(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('user/update-password/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },


    },
    getters: {
        // getIsDriver: state => { return state.user.isDriver }
        getIsDriver: state => state.isDriver
    }

}