<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content w-100">
        <div class="form-groupheads d-flex d-flex justify-content-between">
          <h2>Enable Social Authendication</h2>
          <div class="active-switch d-flex align-items-center">
            <h6 class="fs-14">Enable</h6>
            <label class="switch">
              <input type="checkbox" checked="" />
              <span class="sliders round"></span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set loc-set soc-active">
              <img src="@/assets/admin_img/company/facebook.png" alt="img" />
              <div class="settings-view">
                <a
                  href="javascript:void(0);"
                  class="me-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#facebook-auth"
                  ><i class="feather feather-settings"></i
                ></a>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set loc-set">
              <img src="@/assets/admin_img/company/google.png" alt="img" />
              <div class="settings-view">
                <a
                  href="javascript:void(0);"
                  class="me-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#google-auth"
                  ><i class="feather feather-settings"></i
                ></a>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <social-model></social-model>
</template>

<script>
export default {};
</script>
