<template>
  <!-- Change Password -->
  <div class="modal fade" id="change-password">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change Password</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Current Password</label>
              <div class="pass-group">
                <b-form-input
                  v-if="showPassword"
                  type="text"
                  class="form-control pass-input"
                  v-model="password"
                  placeholder="password"
                />
                <b-form-input
                  v-else
                  type="password"
                  class="form-control pass-input"
                  placeholder="password"
                  v-model="password"
                />
                <span
                  class="toggle-password"
                  @click="toggleShow"
                  :class="{
                    'feather-eye': showPassword,
                    'feather-eye-off': !showPassword,
                  }"
                ></span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">New Password</label>
              <div class="pass-group" id="passwordInput">
                <b-form-input
                  v-if="showPassword3"
                  type="text"
                  class="form-control pass-input"
                  v-model="password3"
                  placeholder=".............."
                />
                <b-form-input
                  v-else
                  type="password"
                  class="form-control pass-input"
                  placeholder=".............."
                  v-model="password3"
                />
                <span
                  class="toggle-password"
                  @click="toggleShow2"
                  :class="{
                    'feather-eye': showPassword3,
                    'feather-eye-off': !showPassword3,
                  }"
                ></span>
              </div>
              <div class="password-strength" id="passwordStrength">
                <span id="poor"></span>
                <span id="weak"></span>
                <span id="strong"></span>
                <span id="heavy"></span>
              </div>
              <div id="passwordInfo"></div>
            </div>
            <div class="mb-4">
              <label class="form-label">New Password</label>
              <div class="pass-group">
                <b-form-input
                  v-if="showPassword2"
                  type="text"
                  class="form-control pass-input"
                  v-model="password2"
                  placeholder=".............."
                />
                <b-form-input
                  v-else
                  type="password"
                  class="form-control pass-input"
                  placeholder=".............."
                  v-model="password2"
                />
                <span
                  class="toggle-password"
                  @click="toggleShow1"
                  :class="{
                    'feather-eye': showPassword2,
                    'feather-eye-off': !showPassword2,
                  }"
                ></span>
              </div>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Change Password</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Change Password -->
  <!--change email-modal -->
  <div
    class="modal fade"
    id="change-email"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Change Email</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Current Email Address</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Email Address"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>New Email Address<span class="manitory">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Email Address"
                />
                <p>
                  <i class="fa fa-info-circle me-2 ms-1"></i>New Email Address Only
                  Updated Once You Verified
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Current Password</label>
                <div class="pass-group">
                  <b-form-input
                    v-if="showPassword"
                    type="text"
                    class="form-control pass-input"
                    v-model="password"
                    placeholder="**********"
                  />
                  <b-form-input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder="**********"
                    v-model="password"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <b-button type="button" variant="primary" data-bs-dismiss="modal"
            >Change Email</b-button
          >
          <b-button type="button" variant="secondary" data-bs-dismiss="modal"
            >Close</b-button
          >
        </div>
      </div>
    </div>
  </div>

  <!--change email-no -->
  <div
    class="modal fade"
    id="change-no"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Change Phone Number</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-md-12">
              <label class="col-form-label">Phone Number</label>
            </div>
            <div class="col-md-2 pe-0">
              <div class="form-group">
                <input
                  class="form-control form-control-lg group_formcontrol"
                  id="phone"
                  name="phone"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-10 ps-0">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="(256) 789-6253" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="col-form-label">New Phone Number</label>
            </div>
            <div class="col-md-2 pe-0">
              <div class="form-group mb-0">
                <input
                  class="form-control form-control-lg group_formcontrol"
                  id="phone1"
                  name="phone"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-10 ps-0">
              <div class="form-group mb-0">
                <input type="text" class="form-control" placeholder="(256) 789-6253" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <p>
                  <i class="fa fa-info-circle me-2 ms-1"></i>New Phone Number Only Updated
                  Once You Verified
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Current Password</label>
                <div class="pass-group">
                  <b-form-input
                    v-if="showPassword"
                    type="text"
                    class="form-control pass-input"
                    v-model="password"
                    placeholder="**********"
                  />
                  <b-form-input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder="**********"
                    v-model="password"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <b-button type="button" variant="primary" data-bs-dismiss="modal"
            >Change Number</b-button
          >
          <b-button type="button" variant="secondary" data-bs-dismiss="modal"
            >Close</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
      showPassword2: false,
      password2: null,
      showPassword3: false,
      password3: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    buttonLabel1() {
      return this.showPassword2 ? "Hide" : "Show";
    },
    buttonLabel2() {
      return this.showPassword3 ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword2 = !this.showPassword2;
    },
    toggleShow2() {
      this.showPassword3 = !this.showPassword3;
    },
  },
  mounted() {
    if (document.getElementById("passwordInput")) {
      let passwordInput = document
        .getElementById("passwordInput")
        .querySelector('input[type="password"]');
      let passwordStrength = document.getElementById("passwordStrength");
      let passwordInfo = document.getElementById("passwordInfo");

      let poor = passwordStrength.getElementsByClassName("poor")[0];
      let weak = passwordStrength.getElementsByClassName("weak")[0];
      let strong = passwordStrength.getElementsByClassName("strong")[0];
      let heavy = passwordStrength.getElementsByClassName("heavy")[0];

      let poorRegExp = /[a-z]/;
      let weakRegExp = /(?=.*?[0-9])/;
      let strongRegExp = /(?=.*?[#?!@$%^&*-])/;

      let whitespaceRegExp = /^$|\s+/;

      passwordInput.addEventListener("input", function () {
        let passwordValue = passwordInput.value;
        let passwordLength = passwordValue.length;
        let poorPassword = passwordValue.match(poorRegExp);
        let weakPassword = passwordValue.match(weakRegExp);
        let strongPassword = passwordValue.match(strongRegExp);
        let whitespace = passwordValue.match(whitespaceRegExp);

        if (passwordValue !== "") {
          passwordStrength.style.display = "block";
          passwordStrength.style.display = "flex";
          passwordInfo.style.display = "block";
          passwordInfo.style.color = "black";

          if (whitespace) {
            passwordInfo.textContent = "whitespaces are not allowed";
          } else {
            poorPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
            weakPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
            strongPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
            heavyPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
          }
        } else {
          passwordInfo.style.display = "none";
          removeClass(passwordStrength, "poor-active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
        }
      });

      function poorPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength < 8) {
          addClass(poor, "active");
          addClass(passwordStrength, "poor-active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
          passwordInfo.style.display = "block";
          passwordInfo.style.color = "#FF0000";
          passwordInfo.innerHTML = "Weak. Must contain at least 8 characters";
        }
      }

      function weakPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength >= 8 && (poorPassword || weakPassword || strongPassword)) {
          addClass(weak, "active");
          removeClass(passwordStrength, "poor-active");
          addClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
          passwordInfo.style.display = "block";
          passwordInfo.style.color = "#FFB54A";
          passwordInfo.innerHTML = "Average. Must contain at least 1 letter or number";
        } else {
          removeClass(weak, "active");
        }
      }

      function strongPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength >= 8 && poorPassword && (weakPassword || strongPassword)) {
          addClass(strong, "active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "poor-active");
          addClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
          passwordInfo.innerHTML = "Almost. Must contain a special symbol";
          passwordInfo.style.color = "#1D9CFD";
        } else {
          removeClass(strong, "active");
        }
      }

      function heavyPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength >= 8 && poorPassword && weakPassword && strongPassword) {
          addClass(heavy, "active");
          removeClass(passwordStrength, "poor-active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          addClass(passwordStrength, "heavy-active");
          passwordInfo.innerHTML = "Awesome! You have a secure password.";
          passwordInfo.style.color = "#159F46";
        } else {
          removeClass(heavy, "active");
        }
      }

      function addClass(element, className) {
        if (element && !element.classList.contains(className)) {
          element.classList.add(className);
        }
      }

      function removeClass(element, className) {
        if (element && element.classList.contains(className)) {
          element.classList.remove(className);
        }
      }
    }
  },
};
</script>
