<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header d-flex justify-content-between">
                <h5>Edit Offers</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Service</label>
                    <vue-select :options="Service" placeholder="Computer Repair" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>User Name</label>
                    <vue-select :options="Name" placeholder="John Smith" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <div class="form-group">
                    <label>Amount</label>
                    <input type="text" class="form-control" value="$80" />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <label>Offer</label>
                    <input type="text" class="form-control" value="25%  " />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Offer Price</label>
                    <input type="number" value="20%" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Valid Date</label>
                    <input
                      type="date"
                      value="02 Sep 2023 - 10 Sep 2023"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" variant="primary"> Save Changes</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Service: ["Computer Repair", "House Cleaning"],
      Name: ["John Smith", "Johnny"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/offers");
    },
  },
};
</script>
