<template>
  <div class="login-body">
    <div class="main-wrapper">
      <mari-header-login />
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-6 mx-auto">
              <div class="login-wrap">
                <div class="login-header">
                  <h4>Reset Password</h4>
                  <p>Kata sandi baru Anda harus berbeda dengan kata sandi yang digunakan sebelumnya.</p>
                </div>

                <!-- Reset Password Form -->
                <Form @submit="submitForm" :validation-schema="schema">
                  <div class="log-form">
                    <div class="form-group">
                      <label class="col-form-label">New Password</label>
                      <div class="pass-group" id="passwordInput">
                        <Field  v-if="showPassword3" name="password" type="text" placeholder="**********"  class="form-control pass-input" />
                        <Field  v-else name="password" type="password" placeholder="**********"  class="form-control pass-input" />
                        <span
                          class="toggle-password"
                          @click="toggleShow2"
                          :class="{
                            'feather-eye': showPassword3,
                            'feather-eye-off': !showPassword3,
                          }"
                        ></span>
                        <ErrorMessage name="password" class="error-feedback" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Confirm Password</label>
                      <div class="pass-group">
                        <Field  v-if="showPassword" name="password_confirmation" type="text" placeholder="**********"  class="form-control pass-input" />
                        <Field  v-else name="password_confirmation" type="password" placeholder="**********"  class="form-control pass-input" />
                        <span
                          class="toggle-password"
                          @click="toggleShow"
                          :class="{
                            'feather-eye': showPassword,
                            'feather-eye-off': !showPassword,
                          }"
                        ></span>
                        <ErrorMessage name="password_confirmation" class="error-feedback" />
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-primary" style="width: 100%;" type="submit"
                    >Save Change</button
                  >
                </Form>
                <!-- /Reset Password Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as yup from "yup";
  import { Form, Field, ErrorMessage } from "vee-validate";

  export default {
    name: 'mari-reset-password',
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
        password: yup
          .string()
          .required("Password harus diisi!")
          .min(6, "Harus lebih dari 6 karakter!"),
        password_confirmation: yup
          .string()
          .required("Confirm Password harus diisi!")
          .oneOf([yup.ref('password'), null], 'Password tidak sama'),
      });

      return {
        showPassword: false,
        password: null,
        showPassword3: false,
        password3: null,
        schema
      };
    },
    computed: {
      buttonLabel() {
        return this.showPassword ? "Hide" : "Show";
      },
      buttonLabel2() {
        return this.showPassword3 ? "Hide" : "Show";
      },
    },
    methods: {
      toggleShow() {
        this.showPassword = !this.showPassword;
      },
      toggleShow2() {
        this.showPassword3 = !this.showPassword3;
      },
      submitForm(user) {
        let loading = this.$loading.show();

        let data = {
          token: this.$route.query.token,
          data: user
        }

        this.$store.dispatch("auth/resetPassword", data).then(
          (data) => {
            loading.hide();
            
            if (data.status == 201) {
              this.$swal.fire({
                icon: 'success',
                text: data.data.message,
                showCancelButton: false,
                confirmButtonText: 'Menuju halaman login',
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then(() => {
                this.$router.push({path: '/mari/login'});
              })
            }
          },
        // eslint-disable-next-line no-unused-vars
        (_error) => {
          loading.hide();
        }
      );
      },
    },
    mounted() {
     
    },
  };
</script>
