<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title"  :text="text" :text1="text1" />

            <servicegridcontent />

            <truelyfooter />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Service Grid",
            text: "Home",
            text1: "Service Grid",
        }
    },
}
</script>