<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper">
        <div class="content">
        <section class="feature-section">			
          <div class="container">			
            <div class="section-heading">
              <h5 style="text-align: center;">Pilih Brand Kendaraan Yang Akan di Buat</h5>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-3" v-for="(item, index) in menuItems" :key="index">
                <div 
                  data-bs-toggle="modal"
                  data-bs-target="#add-vehicle"
                  @click="openModal(item)"
                    class="feature-box aos" data-aos="fade-up"
                >
                  <div class="feature-icon">
                    <span>
                      <img src="@/assets/img/icons/feature-icon-02.svg" alt="img">
                    </span>
                  </div>
                  <h5>{{ item.title }}</h5>
                  <div class="feature-overlay">
                    <!-- <img src="@/assets/img/services/service-02.jpg" alt="img"> -->
                    <img v-if="item.icon === undefined || item.icon === ''" src="@/assets/img/services/service-06.jpg" class="img-fluid" alt="">
                    <img v-else :src="item.icon" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <!-- <div class="row" style="margin-bottom: 20px;">
                <h5 style="text-align: center;">Pilih Brand Kendaraan Yang Akan di Buat</h5>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-4 d-flex" v-for="(item, index) in menuItems" :key="index" >
              <div class="category-card flex-fill">
                  <div class="category-img">
                      <div 
                        data-bs-toggle="modal"
                        data-bs-target="#add-vehicle"
                        @click="openModal(item)"
                      >
                        <i :class="['fas', item.icon]"></i>
                          <img v-if="item.icon === undefined || item.icon === ''" src="@/assets/img/services/service-06.jpg" class="img-fluid" alt="">
                          <img v-else :src="item.icon" class="img-fluid" alt="">
                      </div>
                  </div>
                  <div class="category-info">
                      <div class="category-name">
                          <h6>{{ item.title }}</h6>
                      </div>
                  </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <vehicle-model :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :brandData="dataBrand" :vendorData="dataVendor" @dataUpdated="getData" :idBrand="brandId"></vehicle-model>
  </template>
  
  <script>
  export default {
    data() {
      return {
        menuItems: [],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataProduct: [],
        dataBrand: [],
        dataVendor: [],
        brandId: null
      };
    },
    methods: {
        openModal(item) {
            const data = this.dataBrand.find(data => data.name === item.title);
            console.log(data.id);
            this.brandId = data.id;
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
            .dispatch("brand/getBrand", this.params)
            .then((resp) => {
                this.menuItems = resp.data.data.map((item, index) => ({
                    title: item.name,
                    icon: item.file, // Assuming "icon_file" contains the file path for icons
                    route: "/"
                }));
                this.dataBrand = resp.data.data;
                console.log(this.menuItems);
                loading.hide();
            })
            .catch((e) => {
                this.$toast.error(e);
                loading.hide();
            });
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params)
                .then((resp) => {
                    this.dataProduct = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVendor() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vendor/getVendor", this.params)
                .then((resp) => {
                    this.dataVendor = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
    },
    mounted() {
        this.getData();
        this.getProduct();
        this.getVendor();
    },
  };
  </script>
  
<!-- <style scoped>
.category-card {
	background: #fff;
	border: 2px solid #F5F5F5;
	border-radius: 10px;
	margin-bottom: 24px;
}
.category-img {
	overflow: hidden;
	border-radius: 10px 10px 0px 0px;
}
.category-img img {
	width: 100%;
  height: 282px;
	border-radius: 10px 10px 0px 0px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.category-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.category-info {
	padding: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;		
}
.category-name {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;	
}
.category-icon {
	width: 45px;
	height: 45px;
	background: #F7F7FF;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;	
	margin-right: 10px;
}
.category-name h6 {
	font-size: 20px;
	margin-bottom: 0;
}
.category-name h6 a {
	color: #28283C;
}
.category-name h6 a:hover {
	color: #35a7f4;
}
.category-info p {
	font-size: 16px;
	color: #74788d;
	margin-bottom: 0;
}
</style> -->

<style scoped>
.feature-section {    
	/* background: url(../assets/img/bg/feature-bg-01.png), url(../assets/img/bg/feature-bg-02.png), #F7F7FF; */
  background: #F7F7FF;
	background-repeat: no-repeat;
	background-position: left center, right center;
	padding: 90px 0 66px;
}
.feature-section.nearby-section .section-heading h2 {
	font-weight: 700;
}
.feature-section.nearby-section .section-heading .btn-view {
	padding: 14px 20px;
	border-radius: 50px;
}
.feature-section, .work-section, .client-section {
  padding: 60px 0 36px;
}
.feature-box {
	background: #fff;
	box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
	padding: 25px;
	text-align: center;
	margin-bottom: 1.5rem;
	border-radius: 10px;
	position: relative;	
	display: block;
    z-index: 1;
	overflow: hidden;
}
.feature-icon span {	
    z-index: 1;
}
.feature-icon span {
	width: 94px;
	height: 94px;
	background: #F7F7FF;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	border-radius: 50%;
	margin: 0 auto 20px;
}
.feature-box h5 {
	margin-bottom: 0;
}
.feature-box .feature-overlay {
	opacity: 0;
	position: absolute;
	top: 0;
	left: -100%;	
    z-index: -1;
	-webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
	width: 100%;
}
.feature-box .feature-overlay img {	
	border-radius: 10px;	
}
.feature-box:hover .feature-overlay {
	opacity: 1;
	left: 0;
}
.feature-box:hover .feature-overlay:before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 50%);
    border-radius: 10px;
}
.feature-box:hover h5 {
	color: #fff;
}
.feature-box .feature-overlay img {
		width: 100%;
	}
</style>