<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <customer-sidebar></customer-sidebar>

        <div class="col-lg-9">
          <!-- Sort -->
          <favouritesort></favouritesort>
          <!-- /Sort -->
          <favouritelist></favouritelist>
          <!-- Pagination -->
          <pagination></pagination>
          <!-- /Pagination -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from "../Booking/Customerbooking/pagination.vue";
export default {
  components: { pagination },
  data() {
    return {};
  },
  mounted() {
    document.querySelectorAll(".fav-icon").forEach(function (element) {
      element.addEventListener("click", function () {
        element.classList.toggle("selected");
      });
    });
  },
};
</script>
