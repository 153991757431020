<template>
    <!-- Footer -->
		<footer class="footer footer-two">
		
        <!-- Footer Top -->
        <div class="footer-top aos" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <router-link to="/"><img src="@/assets/img/logo-02.svg" alt="logo"></router-link>
                            </div>
                            <!-- Footer Widget -->
                            <div class="footer-widget footer-contact">
                                <div class="footer-contact-info">
                                    <div class="footer-address">
                                        <span><i class="feather-phone-call"></i></span>
                                        <div>
                                            <h6>Tel</h6>
                                            <p>310-437-2766</p>
                                        </div>
                                    </div>
                                    <div class="footer-address">
                                        <span><i class="feather-mail"></i></span>
                                        <div>
                                            <h6>Email Address</h6>
                                            <p>truelysell@example.com</p>
                                        </div>
                                    </div>
                                    <div class="footer-address">
                                        <span><i class="feather-map-pin"></i></span>
                                        <div>
                                            <h6>Address</h6>
                                            <p>367 Hillcrest Lane, Irvine, California, United States</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Footer Widget -->
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Company</h2>
                            <ul>
                                <li>
                                    <router-link to="/about-us">About Us</router-link>
                                </li>
                                <li>
                                    <router-link to="/categories">Categories</router-link>
                                </li>
                                <li>
                                    <router-link to="/blog-grid">Blogs</router-link>
                                </li>
                                <li>
                                    <router-link to="/faq">FAQ</router-link>
                                </li>
                                <li>
                                    <router-link to="/blog-list">Blog List</router-link>
                                </li>
                                <li>
                                    <a href="javascript:;">Gallery</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Quick Links</h2>
                            <ul>
                                <li>
                                    <router-link to="/contact-us">Contact us</router-link>
                                </li>
                                <li>
                                    <router-link to="/customer-chat">Online Chat</router-link>
                                </li>
                                <li>
                                    <a href="javascript:;">Whatsapp</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Telegram</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Ticketing</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Call Center</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Support</h2>
                            <ul>
                                <li>
                                    <a href="javascript:;">Account</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Manage Deliveries</a>
                                </li>
                                <li>
                                    <router-link to="/orders">Orders</router-link>
                                </li>
                                <li>
                                    <a href="javascript:;">Payments</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Returns</a>
                                </li>
                                <li>
                                    <router-link to="/privacy-policy">Privacy Policy</router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget">
                            <h2 class="footer-title">Instagram Feed</h2>
                            <ul class="row insta-row">
                                <li class="col-3 col-md-4">
                                    <a href="javascript:void(0);">
                                        <img src="@/assets/img/services/service-01.jpg" alt="Instagram Image" class="img-fluid">
                                    </a>
                                </li>
                                <li class="col-3 col-md-4">
                                    <a href="javascript:void(0);">
                                        <img src="@/assets/img/services/service-02.jpg" alt="Instagram Image" class="img-fluid">
                                    </a>
                                </li>
                                <li class="col-3 col-md-4">
                                    <a href="javascript:void(0);">
                                        <img src="@/assets/img/services/service-03.jpg" alt="Instagram Image" class="img-fluid">
                                    </a>
                                </li>
                                <li class="col-3 col-md-4">
                                    <a href="javascript:void(0);">
                                        <img src="@/assets/img/services/service-04.jpg" alt="Instagram Image" class="img-fluid">
                                    </a>
                                </li>
                                <li class="col-3 col-md-4">
                                    <a href="javascript:void(0);">
                                        <img src="@/assets/img/services/service-05.jpg" alt="Instagram Image" class="img-fluid">
                                    </a>
                                </li>
                                <li class="col-3 col-md-4">
                                    <a href="javascript:void(0);">
                                        <img src="@/assets/img/services/service-06.jpg" alt="Instagram Image" class="img-fluid">
                                    </a>
                                </li>
                                <li class="col-3 col-md-4">
                                    <a href="javascript:void(0);">
                                        <img src="@/assets/img/services/service-07.jpg" alt="Instagram Image" class="img-fluid">
                                    </a>
                                </li>
                                <li class="col-3 col-md-4">
                                    <a href="javascript:void(0);">
                                        <img src="@/assets/img/services/service-08.jpg" alt="Instagram Image" class="img-fluid">
                                    </a>
                                </li>
                                <li class="col-3 col-md-4">
                                    <a href="javascript:void(0);">
                                        <img src="@/assets/img/services/service-09.jpg" alt="Instagram Image" class="img-fluid">
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top -->
        
        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <!-- Copyright -->
                <div class="copyright">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="payment-image">
                                <ul>
                                    <li>
                                        <a href="javascript:;"><img src="@/assets/img/payment/card-01.png" alt="img"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:;"><img src="@/assets/img/payment/card-02.png" alt="img"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:;"><img src="@/assets/img/payment/card-03.png" alt="img"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:;"><img src="@/assets/img/payment/card-04.png" alt="img"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:;"><img src="@/assets/img/payment/card-05.png" alt="img"></a>
                                    </li>
                                    <li>
                                        <a href="javascript:;"><img src="@/assets/img/payment/card-06.png" alt="img"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="social-icon">
                                <ul>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fa-brands fa-facebook"></i> </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fab fa-twitter"></i> </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fa-brands fa-google"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" target="_blank"><i class="fa-brands fa-youtube"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <!-- Copyright Menu -->								
                            <div class="copyright-text">
                                <p class="mb-0">Copyright &copy; 2023. All Rights Reserved.</p>
                            </div>
                            <!-- /Copyright Menu -->
                        </div>
                    </div>
                </div>
                <!-- /Copyright -->
            </div>
        </div>
        <!-- /Footer Bottom -->
        
    </footer>
    <!-- /Footer -->
</template>