<template>
  <div class="main-wrapper">
    <layouts></layouts>
    <div class="page-wrapper">
      <breadcrumb :title="title" :text="text" :text1="text1" />
      <div class="content section-booking">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="service-view">
                <div class="service-header">
                  <div class="d-flex justify-content-between align-items-center">
                    <h1 class="electric-header">Electrical Panel Repairing Service</h1>
                  </div>
                  <address class="service-location">
                    <i class="fas fa-location-arrow"></i> Hanover, Maryland
                  </address>
                  <div class="rating">
                    <i class="fas fa-star me-1"></i>
                    <i class="fas fa-star me-1"></i>
                    <i class="fas fa-star me-1"></i>
                    <i class="fas fa-star me-1"></i>
                    <i class="fas fa-star me-1"></i>
                    <span class="d-inline-block average-rating">(5)</span>
                  </div>
                  <div class="service-cate d-flex align-items-center">
                    <router-link to="search">Electrical</router-link>
                    <span class="ms-2"><i class="fas fa-heart"></i></span>
                  </div>
                </div>
                <div class="service-images">
                  <div class="item">
                    <img
                      src="@/assets/img/services/service-033.jpg"
                      alt="image"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <serviceoverview></serviceoverview>
              </div>
              <h4 class="card-title">Related Services</h4>
              <div class="service-carousel">
                <div class="popular-slider">
                  <div class="row">
                    <relatedservice2></relatedservice2>
                  </div>
                </div>
              </div>
            </div>
            <servicedetails2stick></servicedetails2stick>
          </div>
        </div>
      </div>
      <truelyfooter />
    </div>
  </div>
  <booking-service></booking-service>
</template>
<script>
export default {
  data() {
    return {
      title: "Service Details 2",
      text: "Home",
      text1: "Service Details 2",
    };
  },
};
</script>
