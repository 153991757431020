<template>
  <!-- Header -->
  <header class="header">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/" class="navbar-brand logo-small">
            <img src="@/assets/img/logo-small.png" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);">
              <i class="fas fa-times"></i
            ></a>
          </div>
          <headerMenu />
        </div>
        <ul class="nav header-navbar-rht">
          <li class="nav-item">
            <router-link class="link" to="/faq">FAQ</router-link>
          </li>
          <li class="nav-item">
            <router-link class="link" to="/login">Login</router-link>
          </li>
          <li class="nav-item dropdown flag-nav">
            <a
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              href="javascript:;"
              role="button"
              aria-expanded="false"
            >
              <img src="@/assets/img/flag.png" alt="" /> En
            </a>
            <div class="dropdown-menu dropdown-menu-right" style="">
              <a href="javascript:void(0);" class="dropdown-item">
                <img src="@/assets/img/flags/us.png" alt="" height="16" /> En
              </a>
              <a href="javascript:void(0);" class="dropdown-item">
                <img src="@/assets/img/flags/fr.png" alt="" height="16" /> French
              </a>
              <a href="javascript:void(0);" class="dropdown-item">
                <img src="@/assets/img/flags/de.png" alt="" height="16" /> German
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <!-- /Header -->
</template>
