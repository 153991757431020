<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>Earnings</h6>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div class="review-sort d-flex align-items-center me-2">
            <p>Sort</p>
            <vue-select :options="abcd" placeholder="A -> Z" />
          </div>
          <div class="grid-listview me-2">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <i class="feather-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <!-- Coupons -->
    <div class="row">
      <div class="col-md-12">
        <div class="provide-table manage-table">
          <div class="table-responsive">
            <table class="table custom-table datatable mb-0" id="data-table">
              <a-table :columns="columns" :data-source="data">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Service'">
                    <h2 class="table-avatar table-sml">
                      <a href="javascript:;" class="avatar avatar-m me-2"
                        ><img
                          class="avatar-img rounded"
                          :src="require(`@/assets/img/services/${record.Image}`)"
                          alt="User Image"
                      /></a>
                      <a href="javascript:;">{{ record.Service }}</a>
                    </h2>
                  </template>
                  <template v-else-if="column.key === 'id'">
                    <div class="table-actions">
                      <a class="action-set" href="javascript:;">
                        <i class="feather-edit"></i>
                      </a>
                      <a
                        class="action-set confirm-text"
                        href="javascript:;"
                        @click="showAlert"
                      >
                        <i class="feather-trash-2"></i>
                      </a>
                    </div>
                  </template>
                </template>
              </a-table>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div id="tablelength"></div>
          </div>
          <div class="col-md-9">
            <div class="table-ingopage">
              <div id="tableinfo"></div>
              <div id="tablepagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Coupons -->
  </div>

  <!-- Delete Account -->
  <div class="modal fade custom-modal" id="del-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sureyou want to delete This Account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <b-button type="submit" variant="danger">Delete Account</b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Earned Amount",
    dataIndex: "Earned_Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Earned_Amount.toLowerCase();
        b = b.Earned_Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
  },
];
const data = [
  {
    id: "1",
    Image: "service-06.jpg",
    Service: "Computer Repair",
    Earned_Amount: "$38.25",
    Date: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "2",
    Image: "service-04.jpg",
    Service: "Car Repair Services",
    Earned_Amount: "$20",
    Date: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "3",
    Image: "service-08.jpg",
    Service: "Steam Car Wash",
    Earned_Amount: "$10",
    Date: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "4",
    Image: "service-09.jpg",
    Service: "House Cleaning",
    Earned_Amount: "$90",
    Date: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "5",
    Image: "service-02.jpg",
    Service: "Building Construction",
    Earned_Amount: "$55",
    Date: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "6",
    Image: "service-07.jpg",
    Service: "Interior Designing",
    Earned_Amount: "$20",
    Date: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "7",
    Image: "service-05.jpg",
    Service: "Commercial Painting",
    Earned_Amount: "$400",
    Date: "07 Oct 2023 11:22:51",
    Action: "",
  },
  {
    id: "8",
    Image: "service-11.jpg",
    Service: "Plumbing Services",
    Earned_Amount: "$70",
    Date: "07 Oct 2023 11:22:51",
    Action: "",
  },
];
export default {
  data() {
    return {
      abcd: ["A -> Z", "Z -> A", "Most helful"],
      columns,
      data,
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/login");
    },
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#35a7f4",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.");
        }
      });
    },
  },
};
</script>
