<template>
  <div class="main-wrapper">
    <div class="login-pages">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="login-images">
              <img src="@/assets/admin_img/login-banner.png" alt="img" />
            </div>
          </div>
          <div class="col-lg-6">
            <Form class="login-content" @submit="handleRegister" :validation-schema="schema">
              <div class="login-contenthead">
                <h5>Daftar Akun</h5>
                <h6>Daftar sekarang dan gunakan gratis selama 14 hari</h6>
              </div>
              <div class="login-input">
                <div class="form-group">
                  <label>Nama Lengkap</label>
                  <Field name="full_name" type="text" placeholder="Nama Lengkap" class="form-control" />
                  <ErrorMessage name="full_name" class="error-feedback" />
                </div>
                <div class="form-group">
                  <label>E-mail</label>
                  <Field name="email" type="text" placeholder="Email" class="form-control" />
                  <ErrorMessage name="email" class="error-feedback" />
                </div>
                <div class="form-group">
                  <label>No. Handphone</label>
                  <Field name="phone_number" type="text" placeholder="No. Handphone" class="form-control" />
                  <ErrorMessage name="phone_number" class="error-feedback" />
                </div>
                <div class="form-group">
                  <label>Nama Bisnis</label>
                  <Field name="name" type="text" placeholder="Nama Bisnis" class="form-control" />
                  <ErrorMessage name="name" class="error-feedback" />
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <label>Password</label>
                  </div>
                  <div class="pass-group">
                    <Field  v-if="showPassword" name="password" type="text" placeholder="**********"  class="form-control pass-input" />
                    <Field  v-else name="password" type="password" placeholder="**********"  class="form-control pass-input" />
                    <span
                      class="toggle-password"
                      @click="toggleShow"
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></span>
                    <ErrorMessage name="password" class="error-feedback" />
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between">
                    <label>Konfirmasi Password</label>
                  </div>
                  <div class="pass-group">
                    <Field  v-if="showPassword" name="password_confirmation" type="text" placeholder="**********"  class="form-control pass-input" />
                    <Field  v-else name="password_confirmation" type="password" placeholder="**********"  class="form-control pass-input" />
                    <span
                      class="toggle-password"
                      @click="toggleShow"
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></span>
                    <ErrorMessage name="password" class="error-feedback" />
                  </div>
                </div>
                <div class="filter-checkbox m-0">
                  <ul class="d-flex justify-content-between">
                    <li>
                      <label class="checkboxs">
                        <input type="checkbox" />
                        <span><i></i></span>
                        <b class="check-content">Remember Me</b>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="login-button">
                <button class="btn btn-login" :disabled="loading">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span> 
                Register</button>
              </div>
              <div class="signinform text-center">
                <h4>
                  Sudah Punya Akun ?
                  <router-link to="/admin/signin" class="hover-a">Login</router-link>
                </h4>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  components: {
      Form,
      Field,
      ErrorMessage,
  },
  data() {
      const schema = yup.object().shape({
      full_name: yup
        .string()
        .required("Nama lengkap harus diisi!")
        .min(3, "Harus lebih dari 3 karakter!"),
      name: yup
        .string()
        .required("Nama bisnis harus diisi!")
        .min(3, "Harus lebih dari 3 karakter!"),
      email: yup
        .string()
        .required("Email harus diisi!")
        .email("Harus berupa format email!")
        .max(50, "Tidak boleh lebih dari 50 karakter!"),
      password: yup
        .string()
        .required("Password harus diisi!")
        .min(6, "Harus lebih dari 6 karakter!"),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Password tidak sama'),
      phone_number: yup
        .string()
        .required("No HP harus diisi!")
        .min(6, "Harus lebih dari 6 karakter!"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
      showPassword: false,
      password: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    handleRegister(user) {
      let loading = this.$loading.show();
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          loading.hide();
          
          if (data.status == 201) {
            this.$swal.fire({
              icon: 'success',
              text: data.data.message,
              showCancelButton: false,
              confirmButtonText: 'Menuju halaman login',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then(() => {
              this.$router.push({path: 'login'});
            })
          }
        },
        (error) => {
          loading.hide();
        }
      );
    },
  },
};
</script>
