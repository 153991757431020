<template>
  <div class="main-wrapper">
    <custombookcalendarnav />
    <div class="page-wrapper">
      <customerwalletcontent />

      <addwallet />

      <customnotifydeleteacc />
    </div>
  </div>
</template>
