<template>
  <!-- Blog Section -->
  <section class="blog-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center aos" data-aos="fade-up">
          <div class="section-heading">
            <h2>Latest Blog</h2>
            <p>Lorem ipsum dolor sit amet, consectetur elit</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="blog flex-fill aos" data-aos="fade-up">
            <div class="blog-image">
              <router-link to="/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-01.jpg"
                  alt="Post Image"
              /></router-link>
            </div>
            <div class="blog-content">
              <ul class="blog-item">
                <li><i class="feather-calendar"></i>09 Aug 2023</li>
                <li>
                  <div class="post-author">
                    <a href="javascript:;"
                      ><i class="feather-user"></i><span>Hal Lewis</span></a
                    >
                  </div>
                </li>
              </ul>
              <h3 class="blog-title">
                <router-link to="/blog-details"
                  >How to Choose a Electrical ServiceProvider?</router-link
                >
              </h3>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="blog flex-fill aos" data-aos="fade-up">
            <div class="blog-image">
              <router-link to="/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-02.jpg"
                  alt="Post Image"
              /></router-link>
            </div>
            <div class="blog-content">
              <ul class="blog-item">
                <li><i class="feather-calendar"></i>09 Aug 2023</li>
                <li>
                  <div class="post-author">
                    <a href="javascript:;"
                      ><i class="feather-user"></i><span>JohnDoe</span></a
                    >
                  </div>
                </li>
              </ul>
              <h3 class="blog-title">
                <router-link to="/blog-details"
                  >Lorem ipsum dolor sit amet, consectetur adipiscing elit</router-link
                >
              </h3>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="blog flex-fill aos" data-aos="fade-up">
            <div class="blog-image">
              <router-link to="/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-03.jpg"
                  alt="Post Image"
              /></router-link>
            </div>
            <div class="blog-content">
              <ul class="blog-item">
                <li><i class="feather-calendar"></i>09 Aug 2023</li>
                <li>
                  <div class="post-author">
                    <a href="javascript:;"
                      ><i class="feather-user"></i><span>Greg Avery</span></a
                    >
                  </div>
                </li>
              </ul>
              <h3 class="blog-title">
                <router-link to="/blog-details"
                  >Construction Service Scams: How to Avoid Them</router-link
                >
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Blog Section -->

  <!-- Partners Section -->
  <section class="blog-section pt-0">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center aos" data-aos="fade-up">
          <div class="section-heading">
            <h2>Our Partners</h2>
            <p>Lorem ipsum dolor sit amet, consectetur elit</p>
          </div>
        </div>
        <div class="owl-carousel partners-slider">
          <Carousel
            :autoplay="3000"
            :loop="true"
            :wrap-around="true"
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <Slide v-for="item in indexpartners" :key="item.id">
              <div class="partner-img">
                <a href="javascript:void(0);">
                  <img :src="require(`@/assets/img/partner/${item.image}`)" alt="img" />
                </a>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>
  <!-- Partners Section -->

  <!-- App Section -->
  <section class="app-section">
    <div class="container">
      <div class="app-sec">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="col-md-12">
              <div class="heading aos" data-aos="fade-up">
                <h2>Download Our App</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <h6>Scan the QR code to get the app now</h6>
                <div class="scan-img">
                  <img src="@/assets/img/scan-img.png" class="img-fluid" alt="img" />
                </div>
              </div>
            </div>
            <div class="downlaod-btn aos" data-aos="fade-up">
              <a href="javascript:;" target="_blank">
                <img src="@/assets/img/googleplay.svg" alt="img" />
              </a>
              <a href="javascript:;" target="_blank">
                <img src="@/assets/img/appstore.svg" alt="img" />
              </a>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="appimg-set aos" data-aos="fade-up">
              <img src="@/assets/img/app-img.png" class="img-fluid" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /App Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexpartners from "@/assets/json/indexpartners.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexpartners: indexpartners,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        320: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style>
.partner-img {
  margin-right: 24px;
}
</style>
