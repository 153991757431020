<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="content-page-header content-page-headersplit mb-0">
            <h5>List Pegembalian</h5>
            <div class="list-btn">
              <ul>
                <li>
                    <div class="input-group">
                    <input type="date" v-model="params1.dateStart" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="height: 43px !important;">
                    <span class="input-group-text">s/d</span>
                    <input type="date" v-model="params1.dateEnd" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="height: 43px !important;">
                    <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-resposnive table-div">
                <div class="table datatable">
                  <a-table :columns="columns" :data-source="data" :pagination="pagination">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'Status'">
                        <h6 v-if="record.status === 'AKTIF'" class="badge-active">{{ record.status }}</h6>
                        <h6 v-else class="badge-active">{{ record.status }}</h6>
                      </template>
                      <template v-if="column.key === 'id'">
                        <div class="table-actions d-flex">
                          <button
                            class="btn delete-table"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#update"
                            @click="openUpdateModal(record)"
                          >
                            <i class="feather feather-upload"></i>
                          </button>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <return-model :editData="editData" @dataUpdated="getData"></return-model>
  </template>
  
  <script>
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "No Order",
      dataIndex: "no_order",
      key: "no_order",
      sorter: {
        compare: (a, b) => {
          a = a.no_order.toLowerCase();
          b = b.no_order.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "customerName",
      sorter: {
        compare: (a, b) => {
          a = a.customerName.toLowerCase();
          b = b.customerName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      sorter: {
        compare: (a, b) => {
          a = a.productName.toLowerCase();
          b = b.productName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Starting Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        compare: (a, b) => {
          a = a.startDate.toLowerCase();
          b = b.startDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Starting Time",
      dataIndex: "startTime",
      key: "startTime",
      sorter: {
        compare: (a, b) => {
          a = a.startTime.toLowerCase();
          b = b.startTime.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Finish Date",
      dataIndex: "finishDate",
      key: "finishDate",
      sorter: {
        compare: (a, b) => {
          a = a.finishDate.toLowerCase();
          b = b.finishDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vehicleName",
      key: "vehicleName",
      sorter: {
        compare: (a, b) => {
          a = a.vehicleName.toLowerCase();
          b = b.vehicleName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params1: {
            row: 10,
            keyword: "Proses",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            dateStart: '',
            dateEnd: '',
            jadwal: false
        },
        params2: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
        },
        paramUser: {
            row: 10,
            keyword: "8",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataProduct: [],
        dataVehicle: [],
        dataCustomer: [],
        dataUser: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        }
      };
    },
    name: "returnList",
    computed: {

    },
    methods: {
        search() {
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params1.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params1.row = size;
          this.params1.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("order/getOrder", this.params1)
                .then((resp) => {
                    this.data = resp.data.data.map((item, index) => {
                        const sDate = new Date(item.starting_date);
                        const fDate = new Date(item.finish_date);
                        const time = new Date(item.starting_time);
                        const startTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        const vehicle = this.dataVehicle.find(vehicle => vehicle.id === item.vehicle_id);
                        const vehicleName = vehicle ? vehicle.name : '';
                        const product = this.dataProduct.find(product => product.id === item.product_id);
                        const productName = product ? product.name : '';
                        const customer = this.dataCustomer.find(customer => customer.id === item.customer_id);
                        const customerName = customer ? customer.name : '';
                        return {
                            ...item,
                            index: index + 1,
                            startDate: sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear(),
                            finishDate: fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear(),
                            startTime,
                            vehicleName,
                            productName,
                            customerName
                        };
                    });
                    console.log(resp);
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params1.row;
                    this.params1.dateStart = this.formatDate(resp.dateStart);
                    this.params1.dateEnd = this.formatDate(resp.dateEnd);
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        openUpdateModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params2)
                .then((resp) => {
                    this.dataProduct = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVehicle() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params2)
                .then((resp) => {
                    this.dataVehicle = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getCustomer() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("customer/getCustomer", this.params2)
                .then((resp) => {
                    this.dataCustomer = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getUser() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("user/getUser", this.paramUser)
                .then((resp) => {
                    this.dataUser = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        formatDate(item) {
            // Assuming dateString is in a different format, you need to format it to YYYY-MM-DD
            const date = new Date(item);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    },
    mounted() {
        const currentDate = new Date();
        this.getProduct();
        this.getVehicle();
        this.getCustomer();
        this.getUser();
        this.getData();
    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>