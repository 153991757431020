<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import L from 'leaflet'
import markerIcon from 'leaflet/dist/images/marker-icon.png'

const props = defineProps(['center', 'startMarker', 'disabled'])
const emit = defineEmits(['location-change'])

const map = ref(null)
const mapContainer = ref(null)
const locationMarker = ref(null)

function createMarker(latLng) {
    return L.marker(latLng, {
        icon:L.icon({
            iconUrl: markerIcon
        })
    })
}

function onMapClick(e) {
    if (!locationMarker.value) {
        locationMarker.value = createMarker(e.latlng).addTo(map.value)
    } else {
        locationMarker.value.setLatLng(e.latlng).update()
    }
    emit('location-change', e.latlng)
}

const unwatchCenter = watch(() => props.center, (v) => {
    map.value = map.value.panTo(v)
})

onMounted(() => {
    map.value = L.map(mapContainer.value).setView(props.center, 15);

    let disabled = false
    if (props.disabled) {
        disabled = props.disabled
    }

    if (!disabled) {
        map.value.on('click', (e) => {
            onMapClick(e)
        })
    }
    
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map.value);

    if (props.startMarker) {
        locationMarker.value = createMarker(props.startMarker).addTo(map.value)
    }

    setTimeout(() => { 
        map.value.invalidateSize()
    }, 500);
})

onUnmounted(() => {
    unwatchCenter()
})
</script>

<template>
    <div ref="mapContainer" style="width: 100%; height: 100%"></div>
</template>