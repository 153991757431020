<template>
  <!-- Add Category -->
  <div class="modal fade" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Category</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Category Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label me-1">Category Slug</label
              ><small class="form-text text-muted">(Ex:test-slug)</small>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Category Image</label>
              <div class="form-uploads">
                <div class="form-uploads-path">
                  <img src="@/assets/admin_img/icons/upload.svg" alt="img" />
                  <div class="file-browse">
                    <h6>Drag & drop image or</h6>
                    <div class="file-browse-path">
                      <input type="file" />
                      <a href="javascript:void(0);"> Browse</a>
                    </div>
                  </div>
                  <h5>Supported formates: JPEG, PNG</h5>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label">Is Featured?</label>
              <ul class="custom-radiosbtn">
                <li>
                  <label class="radiossets"
                    >Yes
                    <input type="radio" checked="checked" name="radio" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
                <li>
                  <label class="radiossets"
                    >No
                    <input type="radio" name="radio" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Category -->

  <!-- Edit Category -->
  <div class="modal fade" id="edit-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Category</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Category Name</label>
              <input type="text" class="form-control" value="Car Wash" />
            </div>
            <div class="mb-3">
              <label class="form-label me-1">Category Slug</label
              ><small class="form-text text-muted">(Ex:test-slug)</small>
              <input type="text" class="form-control" value="car-wash" />
            </div>
            <div class="mb-3">
              <label class="form-label">Category Image</label>
              <div class="form-uploads">
                <div class="form-uploads-path">
                  <img src="@/assets/admin_img/icons/upload.svg" alt="img" />
                  <div class="file-browse">
                    <h6>Drag & drop image or</h6>
                    <div class="file-browse-path">
                      <input type="file" />
                      <a href="javascript:void(0);"> Browse</a>
                    </div>
                  </div>
                  <h5>Supported formates: JPEG, PNG</h5>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label">Is Featured?</label>
              <ul class="custom-radiosbtn">
                <li>
                  <label class="radiossets"
                    >Yes
                    <input type="radio" checked="checked" name="radio" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
                <li>
                  <label class="radiossets"
                    >No
                    <input type="radio" name="radio" />
                    <span class="checkmark-radio"></span>
                  </label>
                </li>
              </ul>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Category -->

  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Category ?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to delete this?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
