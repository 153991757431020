<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="row chat-window">
          <!-- Chat User List -->
          <chatuser-list></chatuser-list>
          <!-- Chat User List -->

          <!-- Chat Content -->
          <chatcontent></chatcontent>
          <!-- /Chat Content -->

          <!-- Chat Profile -->
          <chat-profile></chat-profile>
          <!-- /Chat Content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
