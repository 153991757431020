<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Edit SMS Template</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="sms-group">
                    <ul>
                      <li>Email Title <span>E-Mail Registration Confirmation</span></li>
                      <li>Reference ID <span>1</span></li>
                      <li>
                        Sent To
                        <span
                          ><img
                            src="@/assets/admin_img/customer/user-01.jpg"
                            class="avatar-sm me-2"
                            alt="img"
                          />William</span
                        >
                      </li>
                      <li>Date <span>27 Sep 2023 10:43:20</span></li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <label> Template Content</label>
                    <summernoteEditor
                      v-model="myValue"
                      @update:modelValue="summernoteChange($event)"
                      @summernoteImageLinkInsert="summernoteImageLinkInsert"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <button type="submit" class="btn btn-primary me-3">
                      Save Changes
                    </button>
                    <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import SummernoteEditor from "vue3-summernote-editor";
export default {
  components: {
    SummernoteEditor,
  },
  data() {
    return {
      myValue:
        "Hi {user_name},Welcome to Truelysell.com, Thanks for signing up.Click the button below to to view site:  View Cheers, {sitetitle} Team",
    };
  },
  methods: {
    summernoteChange(newValue) {
      // console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      // console.log("summernoteImageLinkInsert()", args);
    },
    submitForm() {
      this.$router.push("/admin/email-templates");
    },
  },
};
</script>
