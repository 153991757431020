<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>
      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>List Report Driver</h4>
                  <p>List data driver tersedia disni!</p>
                </div>
                <div class="col-md-6 text-md-end aos" data-aos="fade-up">
                    <!-- <div class="input-group" style="justify-content: end;">
                        <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="max-width: 25% !important;">
                        <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                    </div> -->
                </div>
              </div>
            </div>
            <div class="content-page-header content-page-headersplit mb-0">
                <div class="list-btn">
                  <ul>
                    <li>
                      <div class="input-group">
                        <input type="date" v-model="params.dateStart" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text">s/d</span>
                        <input type="date" v-model="params.dateEnd" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
                        <span class="input-group-text"></span>
                        <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" @keyup.enter="search">
                        <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

            <div class="row mt-4">
              <div class="col-12">
                <div class="table-resposnive table-div">
                  <div class="table datatable">
                    <a-table :columns="columns" :data-source="data" :pagination="pagination">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'order'">
                            {{ record.order_vehicle_detail.order_vehicle.order.no_order }}
                          </template>
                          <template v-if="column.key === 'vehicle'">
                            {{ record.order_vehicle_detail.vehicle.name }} - {{ record.order_vehicle_detail.vehicle.nomor_plat }}
                          </template>
                          <template v-if="column.key === 'tgl_order'">
                            {{ formatDate(record.order_vehicle_detail.order_vehicle.order.created_at) }}
                          </template>
                          <template v-if="column.key === 'file'">
                            <a href="javascript:void(0);" class="table-profileimage">
                              <img
                                :src="record.file"
                                class="me-2"
                                alt="img"
                              />
                            </a>
                        </template>
                        <template v-if="column.key === 'id'">
                            <div class="table-actions d-flex">
                              <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#show-driver-report"
                                @click="openReportModal(record)"
                              >
                                <i class="feather feather-eye"></i>
                              </button>
                              <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#incentive-driver"
                                @click="openIncentiveModal(record)"
                                >
                                <i class="feather feather-dollar-sign"></i>
                              </button>
                            </div>
                          </template>
                      </template>
                    </a-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <report-driver-model :reportData="reportData" :dataId="orderId" @dataUpdated="getData"></report-driver-model>
    <incentive-driver-model :editData="editData"></incentive-driver-model>
  </template>
  
  <script>
  import Dialog from '@/views/mari/components/dialog.vue'
  import Datalist from '@/views/mari/components/datalist.vue'
  import VuePdfEmbed from 'vue-pdf-embed'
  // import 'vue-pdf-embed/dist/style/index.css'

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Nama Driver",
      dataIndex: "nama_driver",
      key: "nama_driver",
      sorter: {
        compare: (a, b) => {
          a = a.nama_driver.toLowerCase();
          b = b.nama_driver.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Nama Tamu",
      dataIndex: "nama_tamu",
      key: "nama_tamu",
      sorter: {
        compare: (a, b) => {
          a = a.nama_driver.toLowerCase();
          b = b.nama_driver.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "Kendaraan",
      dataIndex: "vehicle",
      key: "vehicle",
    },
    {
      title: "Tanggal order",
      dataIndex: "tgl_order",
      key: "tgl_order",
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    components: {
      VuePdfEmbed,
      Dialog,
    },
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
            dateStart: '',
            dateEnd: '',
            jadwal: false
        },
        editData: null,
        itemIdToDelete: {},
        dataRole: [],
        dataMenu: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        invoice: null,
        reportData: {},
      };
    },
    name: "brandsetting",
    computed: {

    },
    methods: {
        search() {
          this.params.page = 1;
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("reportDriver/getReportDriver", this.params)
                .then((resp) => {
                  const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
                    this.data = resp.data.data.map((item, index) => {
                      const sDate = new Date(item.created_at);
                      return {
                        ...item,
                        index: startIndex + index + 1 ,
                        tglBuat: sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear(),
                      }
                    });
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    this.params.dateStart = this.formatDate(resp.dateStart);
                    this.params.dateEnd = this.formatDate(resp.dateEnd);
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        formatDate(item) {
            // Assuming dateString is in a different format, you need to format it to YYYY-MM-DD
            const date = new Date(item);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        openReportModal(data) {
          const newData = data;
          const orderData = data.order_vehicle_detail.order_vehicle.order;
          const vehicleData = data.order_vehicle_detail.vehicle;
          this.reportData = { newData, orderData, vehicleData };
        },
        openIncentiveModal(data) {
          this.editData = data
        }
    },
    mounted() {
        this.getData();
    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>
