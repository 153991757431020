<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content w-100 ps-0 pt-0">
        <sidelinkheading />
        <div class="row">
          <div class="content-table">
            <div class="content-page-header content-page-headersplit">
              <h5>Ban IP Address</h5>
              <div class="page-head-btn">
                <a
                  class="btn btn-primary"
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#add-tax"
                  ><i class="fa fa-plus me-2"></i>Ban IP Address</a
                >
              </div>
            </div>
            <div class="col-12">
              <div class="table-resposnive table-bottom">
                <div class="table datatable">
                  <a-table :columns="columns" :data-source="data">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'IP_Address'">
                        <td>{{ record.IP_Address }}</td>
                      </template>
                      <template v-else-if="column.key === 'id'">
                        <div class="action-language">
                          <ul>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="delete-table"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-tax"
                                ><i class="far fa-edit"></i
                              ></a>
                            </li>
                            <li>
                              <a
                                class="delete-table"
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#delete"
                              >
                                <i class="far fa-trash-alt"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <banip-model></banip-model>
</template>

<script>
const columns = [
  {
    title: "IP_Address",
    dataIndex: "IP_Address",
    key: "IP_Address",
    sorter: {
      compare: (a, b) => {
        a = a.IP_Address.toLowerCase();
        b = b.IP_Address.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reason",
    dataIndex: "Reason",
    key: "Reason",
    sorter: {
      compare: (a, b) => {
        a = a.Reason.toLowerCase();
        b = b.Reason.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
  },
];

const data = [
  {
    id: "1",
    IP_Address: "211.11.222",
    Reason: "Lorem ipsum dolor sit amet",
    Date: "27 Sep 2023",
    Action: "",
  },
  {
    id: "2",
    IP_Address: "213.12.333",
    Reason: "Lorem ipsum dolor sit",
    Date: "24 Sep 2023",
    Action: "",
  },
  {
    id: "3",
    IP_Address: "210.10.444",
    Reason: "Lorem ipsum dolor sit amet",
    Date: "24 Sep 2023",
    Action: "",
  },
  {
    id: "4",
    IP_Address: "214.13.111",
    Reason: "Lorem ipsum dolor sit amet",
    Date: "18 Sep 2023",
    Action: "",
  },
  {
    id: "5",
    IP_Address: "211.11.111",
    Reason: "Lorem ipsum dolor sit amet",
    Date: "18 Sep 2023",
    Action: "",
  },
];
export default {
  data() {
    return {
      data,
      columns,
    };
  },
};
</script>
