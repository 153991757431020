<template>
  <ul class="main-nav">
    <li class="has-submenu megamenu" :class="IndexMenu ? 'active' : 'notactive'">
      <a href="javascript:void(0);" @click="toggleVisibilityindex"
        >Home <i class="fas fa-chevron-down"></i
      ></a>
      <ul
        class="submenu mega-submenu"
        :style="{ display: isVisibleindex ? 'block' : 'none' }"
      >
        <li>
          <div class="megamenu-wrapper">
            <div class="row">
              <div class="col-lg-2">
                <div
                  class="single-demo"
                  :class="currentPath == 'index' ? 'active' : 'notactive'"
                >
                  <div class="demo-img">
                    <router-link to="/"
                      ><img src="@/assets/img/home-01.jpg" class="img-fluid" alt="img"
                    /></router-link>
                  </div>
                  <div class="demo-info">
                    <router-link to="/">Electrical Home</router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div
                  class="single-demo"
                  :class="currentPath == 'index-2' ? 'active' : 'notactive'"
                >
                  <div class="demo-img">
                    <router-link to="index-2"
                      ><img src="@/assets/img/home-02.jpg" class="img-fluid" alt="img"
                    /></router-link>
                  </div>
                  <div class="demo-info">
                    <router-link to="index-2">Cleaning Home</router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div
                  class="single-demo"
                  :class="currentPath == 'index-3' ? 'active' : 'notactive'"
                >
                  <div class="demo-img">
                    <router-link to="index-3"
                      ><img src="@/assets/img/home-03.jpg" class="img-fluid" alt="img"
                    /></router-link>
                  </div>
                  <div class="demo-info">
                    <router-link to="index-3">Saloon Home</router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div
                  class="single-demo"
                  :class="currentPath == 'index-4' ? 'active' : 'notactive'"
                >
                  <div class="demo-img">
                    <router-link to="index-4"
                      ><img src="@/assets/img/home-04.jpg" class="img-fluid" alt="img"
                    /></router-link>
                  </div>
                  <div class="demo-info">
                    <router-link to="index-4">Catering Home</router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div
                  class="single-demo"
                  :class="currentPath == 'index-5' ? 'active' : 'notactive'"
                >
                  <div class="demo-img">
                    <router-link to="index-5"
                      ><img src="@/assets/img/home-05.jpg" class="img-fluid" alt="img"
                    /></router-link>
                  </div>
                  <div class="demo-info">
                    <router-link to="index-5">Car Wash Home</router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div
                  class="single-demo"
                  :class="currentPath == 'index-6' ? 'active' : 'notactive'"
                >
                  <div class="demo-img">
                    <router-link to="index-6"
                      ><img src="@/assets/img/home-06.jpg" class="img-fluid" alt="img"
                    /></router-link>
                  </div>
                  <div class="demo-info">
                    <router-link to="index-6">Cleaning Home</router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div
                  class="single-demo"
                  :class="currentPath == 'index-7' ? 'active' : 'notactive'"
                >
                  <div class="demo-img">
                    <router-link to="index-7"
                      ><img src="@/assets/img/home-07.jpg" class="img-fluid" alt="img"
                    /></router-link>
                  </div>
                  <div class="demo-info">
                    <router-link to="index-7">House Problem Home</router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div
                  class="single-demo"
                  :class="currentPath == 'index-8' ? 'active' : 'notactive'"
                >
                  <div class="demo-img">
                    <router-link to="index-8"
                      ><img src="@/assets/img/home-08.jpg" class="img-fluid" alt="img"
                    /></router-link>
                  </div>
                  <div class="demo-info">
                    <router-link to="index-8">Pet Grooming Home</router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-2">
                <div
                  class="single-demo"
                  :class="currentPath == 'index-9' ? 'active' : 'notactive'"
                >
                  <div class="demo-img">
                    <router-link to="index-9"
                      ><img src="@/assets/img/home-09.jpg" class="img-fluid" alt="img"
                    /></router-link>
                  </div>
                  <div class="demo-info">
                    <router-link to="index-9">Mechanic Home</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </li>
    <li class="has-submenu" :class="ServicesMenu ? 'active' : 'notactive'">
      <a href="javascript:void(0);" @click="toggleVisibilityservice"
        >Services <i class="fas fa-chevron-down"></i
      ></a>
      <ul class="submenu" :style="{ display: isVisibleservice ? 'block' : 'none' }">
        <li :class="currentPath == 'service-grid' ? 'active' : 'notactive'">
          <router-link to="/service-grid">Service Grid</router-link>
        </li>
        <li :class="currentPath == 'service-list' ? 'active' : 'notactive'">
          <router-link to="/service-list">Service List</router-link>
        </li>
        <li class="has-submenu" :class="ServiceDetailsMenu ? 'active' : 'notactive'">
          <a href="javascript:void(0);" @click="toggleVisibilitydetails"
            >Service Details</a
          >
          <ul class="submenu" :style="{ display: isVisibledetails ? 'block' : 'none' }">
            <li :class="currentPath == 'service-details' ? 'active' : 'notactive'">
              <router-link to="service-details">Service Details 1</router-link>
            </li>
            <li :class="currentPath == 'service-details2' ? 'active' : 'notactive'">
              <router-link to="service-details2">Service Details 2</router-link>
            </li>
          </ul>
        </li>
        <li :class="currentPath == 'search' ? 'active' : 'notactive'">
          <router-link to="/search">Search</router-link>
        </li>
        <li class="has-submenu" :class="ProviderMenu ? 'active' : 'notactive'">
          <a href="javascript:void(0);" @click="toggleVisibilityprovide">Providers</a>
          <ul class="submenu" :style="{ display: isVisibleprovide ? 'block' : 'none' }">
            <li :class="currentPath == 'providers' ? 'active' : 'notactive'">
              <router-link to="/providers">Providers List</router-link>
            </li>
            <li :class="currentPath == 'provider-details' ? 'active' : 'notactive'">
              <router-link to="/provider-details">Providers Details</router-link>
            </li>
          </ul>
        </li>
        <li :class="currentPath == 'create-service' ? 'active' : 'notactive'">
          <router-link @click="redirectReloadCreate" to="/create-service"
            >Create Service</router-link
          >
        </li>
      </ul>
    </li>
    <li class="has-submenu" :class="CustomersMenu ? 'active' : 'notactive'">
      <a href="javascript:void(0);" @click="toggleVisibilitycustomer"
        >Customers <i class="fas fa-chevron-down"></i
      ></a>
      <ul class="submenu" :style="{ display: isVisiblecustomers ? 'block' : 'none' }">
        <li :class="currentPath == 'customer-dashboard' ? 'active' : 'notactive'">
          <router-link to="/customer-dashboard">Dashboard</router-link>
        </li>
        <li :class="currentPath == 'customer-booking' ? 'active' : 'notactive'">
          <router-link to="/customer-booking">Booking</router-link>
        </li>
        <li :class="currentPath == 'customer-favourite' ? 'active' : 'notactive'">
          <router-link to="/customer-favourite">Favorites</router-link>
        </li>
        <li :class="currentPath == 'customer-wallet' ? 'active' : 'notactive'">
          <router-link to="/customer-wallet">Wallet</router-link>
        </li>
        <li :class="currentPath == 'customer-reviews' ? 'active' : 'notactive'">
          <router-link to="/customer-reviews">Reviews</router-link>
        </li>
        <li :class="currentPath == 'customer-chat' ? 'active' : 'notactive'">
          <router-link to="/customer-chat">Chat</router-link>
        </li>
        <li :class="currentPath == 'customer-profile' ? 'active' : 'notactive'">
          <router-link to="/customer-profile">Settings</router-link>
        </li>
      </ul>
    </li>
    <li class="has-submenu" :class="ProvidersMenu ? 'active' : 'notactive'">
      <a href="javascript:;" @click="toggleVisibilityproviders"
        >Providers <i class="fas fa-chevron-down"></i
      ></a>
      <ul class="submenu" :style="{ display: isVisibleproviders ? 'block' : 'none' }">
        <li :class="currentPath == 'provider-dashboard' ? 'active' : 'notactive'">
          <router-link to="/provider-dashboard">Dashboard</router-link>
        </li>
        <li :class="currentPath == 'provider-services' ? 'active' : 'notactive'">
          <router-link to="/provider-services">My Services</router-link>
        </li>
        <li :class="currentPath == 'provider-booking' ? 'active' : 'notactive'">
          <router-link to="/provider-booking">Booking</router-link>
        </li>
        <li :class="currentPath == 'provider-payout' ? 'active' : 'notactive'">
          <router-link to="/provider-payout">Payout</router-link>
        </li>
        <li class="has-submenu">
          <a href="javascript:void(0);" @click="toggleVisibilitysettings">Settings</a>
          <ul class="submenu" :style="{ display: isVisiblesettings ? 'block' : 'none' }">
            <li
              :class="
                currentPath == 'provider-appointment-settings' ? 'active' : 'notactive'
              "
            >
              <router-link to="/provider-appointment-settings"
                >Appointment Settings</router-link
              >
            </li>
            <li
              :class="currentPath == 'provider-profile-settings' ? 'active' : 'notactive'"
            >
              <router-link to="/provider-profile-settings">Account Settings</router-link>
            </li>
            <li
              :class="currentPath == 'provider-social-profile' ? 'active' : 'notactive'"
            >
              <router-link to="/provider-social-profile">Social Profiles</router-link>
            </li>
            <li
              :class="
                currentPath == 'provider-security-settings' ? 'active' : 'notactive'
              "
            >
              <router-link to="/provider-security-settings">Security</router-link>
            </li>
            <li :class="currentPath == 'provider-plan' ? 'active' : 'notactive'">
              <router-link to="/provider-plan">Plan & Billings</router-link>
            </li>
            <li :class="currentPath == 'provider-notifcations' ? 'active' : 'notactive'">
              <router-link to="/provider-notifcations">Notifications</router-link>
            </li>
            <li
              :class="currentPath == 'provider-connected-apps' ? 'active' : 'notactive'"
            >
              <router-link to="/provider-connected-apps">Connected Apps</router-link>
            </li>
            <li>
              <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#del-account"
                >Delete Account</a
              >
            </li>
          </ul>
        </li>
        <li :class="currentPath == 'provider-availability' ? 'active' : 'notactive'">
          <router-link to="/provider-availability">Availability</router-link>
        </li>
        <li :class="currentPath == 'provider-holiday' ? 'active' : 'notactive'">
          <router-link to="/provider-holiday">Holidays & Leave</router-link>
        </li>
        <li :class="currentPath == 'provider-coupons' ? 'active' : 'notactive'">
          <router-link to="/provider-coupons">Coupons</router-link>
        </li>
        <li :class="currentPath == 'provider-offers' ? 'active' : 'notactive'">
          <router-link to="/provider-offers">Offers</router-link>
        </li>
        <li :class="currentPath == 'provider-reviews' ? 'active' : 'notactive'">
          <router-link to="/provider-reviews">Reviews</router-link>
        </li>
        <li :class="currentPath == 'provider-earnings' ? 'active' : 'notactive'">
          <router-link to="/provider-earnings">Earnings</router-link>
        </li>
        <li :class="currentPath == 'provider-chat' ? 'active' : 'notactive'">
          <router-link to="/provider-chat">Chat</router-link>
        </li>
      </ul>
    </li>
    <li class="has-submenu" :class="PagesMenu ? 'active' : 'notactive'">
      <a href="javascript:void(0);" @click="toggleVisibilitypages"
        >Pages <i class="fas fa-chevron-down"></i
      ></a>
      <ul class="submenu" :style="{ display: isVisiblepages ? 'block' : 'none' }">
        <li :class="currentPath == 'about-us' ? 'active' : 'notactive'">
          <router-link to="/about-us">About</router-link>
        </li>
        <li :class="currentPath == 'contact-us' ? 'active' : 'notactive'">
          <router-link to="/contact-us">Contact Us</router-link>
        </li>
        <li :class="currentPath == 'how-it-works' ? 'active' : 'notactive'">
          <router-link to="/how-it-works">How It Works</router-link>
        </li>
        <li class="has-submenu">
          <a href="javascript:void(0);" @click="toggleVisibilityerror">Error Page</a>
          <ul class="submenu" :style="{ display: isVisibleerror ? 'block' : 'none' }">
            <li :class="currentPath == 'error-404' ? 'active' : 'notactive'">
              <router-link to="/error-404">404 Error</router-link>
            </li>
            <li :class="currentPath == 'error-500' ? 'active' : 'notactive'">
              <router-link to="/error-500">500 Error</router-link>
            </li>
          </ul>
        </li>
        <li class="has-submenu" :class="AuthenticationMenu ? 'active' : 'notactive'">
          <a href="javascript:void(0);" @click="toggleVisibilityauth">Authentication</a>
          <ul class="submenu" :style="{ display: isVisibleauth ? 'block' : 'none' }">
            <li :class="currentPath == 'choose-signup' ? 'active' : 'notactive'">
              <router-link to="/choose-signup">Signup Choose </router-link>
            </li>
            <li :class="currentPath == 'user-signup' ? 'active' : 'notactive'">
              <router-link to="/user-signup">Customer Signup</router-link>
            </li>
            <li :class="currentPath == 'provider-signup' ? 'active' : 'notactive'">
              <router-link to="/provider-signup">Provider Signup</router-link>
            </li>
            <li :class="currentPath == 'login' ? 'active' : 'notactive'">
              <router-link to="/login">Login</router-link>
            </li>
            <li :class="currentPath == 'reset-password' ? 'active' : 'notactive'">
              <router-link to="/reset-password">Reset Password</router-link>
            </li>
            <li :class="currentPath == 'password-recovery' ? 'active' : 'notactive'">
              <router-link to="/password-recovery">Password Update</router-link>
            </li>
            <li :class="currentPath == 'phone-otp' ? 'active' : 'notactive'">
              <router-link to="/phone-otp">Phone OTP</router-link>
            </li>
            <li :class="currentPath == 'email-otp' ? 'active' : 'notactive'">
              <router-link to="/email-otp">Email OTP</router-link>
            </li>
            <li :class="currentPath == 'free-trial' ? 'active' : 'notactive'">
              <router-link to="/free-trial">Free Trial</router-link>
            </li>
          </ul>
        </li>
        <li class="has-submenu" :class="BookingMenu ? 'active' : 'notactive'">
          <a href="javascript:void(0);" @click="toggleVisibilitybook">Booking</a>
          <ul class="submenu" :style="{ display: isVisiblebook ? 'block' : 'none' }">
            <li :class="currentPath == 'booking' ? 'active' : 'notactive'">
              <router-link to="/booking">Booking</router-link>
            </li>
            <li :class="currentPath == 'booking-2' ? 'active' : 'notactive'">
              <router-link to="/booking-2" @click="redirectReload2"
                >Booking-2</router-link
              >
            </li>
            <li :class="currentPath == 'booking-payment' ? 'active' : 'notactive'">
              <router-link to="/booking-payment">Booking Checkout</router-link>
            </li>
            <li :class="currentPath == 'booking-done' ? 'active' : 'notactive'">
              <router-link to="/booking-done">Booking Success</router-link>
            </li>
            <li :class="currentPath == 'booking-details' ? 'active' : 'notactive'">
              <router-link to="/booking-details">Booking Details</router-link>
            </li>
          </ul>
        </li>
        <li :class="currentPath == 'categories' ? 'active' : 'notactive'">
          <router-link to="/categories">Categories</router-link>
        </li>
        <li :class="currentPath == 'pricing' ? 'active' : 'notactive'">
          <router-link to="/pricing">Pricing Plan</router-link>
        </li>
        <li :class="currentPath == 'faq' ? 'active' : 'notactive'">
          <router-link to="/faq">FAQ</router-link>
        </li>
        <li :class="currentPath == 'maintenance' ? 'active' : 'notactive'">
          <router-link to="/maintenance">Maintenance</router-link>
        </li>
        <li :class="currentPath == 'coming-soon' ? 'active' : 'notactive'">
          <router-link to="/coming-soon">Coming Soon</router-link>
        </li>
        <li :class="currentPath == 'privacy-policy' ? 'active' : 'notactive'">
          <router-link to="/privacy-policy">Privacy Policy</router-link>
        </li>
        <li :class="currentPath == 'terms-condition' ? 'active' : 'notactive'">
          <router-link to="/terms-condition">Terms & Conditions</router-link>
        </li>
        <li :class="currentPath == 'session-expired' ? 'active' : 'notactive'">
          <router-link to="/session-expired">Session Expired</router-link>
        </li>
      </ul>
    </li>
    <li class="has-submenu" :class="BlogMenu ? 'active' : 'notactive'">
      <a href="javascript:;" @click="toggleVisibilityblog"
        >Blog <i class="fas fa-chevron-down"></i
      ></a>
      <ul class="submenu" :style="{ display: isVisibleblog ? 'block' : 'none' }">
        <li :class="currentPath == 'blog-grid' ? 'active' : 'notactive'">
          <router-link to="/blog-grid">Blog Grid</router-link>
        </li>
        <li :class="currentPath == 'blog-list' ? 'active' : 'notactive'">
          <router-link to="/blog-list">Blog List</router-link>
        </li>
        <li :class="currentPath == 'blog-details' ? 'active' : 'notactive'">
          <router-link to="/blog-details">Blog Details</router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      isVisibleindex: false,
      isVisibleservice: false,
      isVisiblecustomers: false,
      isVisibleproviders: false,
      isVisiblepages: false,
      isVisibleblog: false,
      isVisibledetails: false,
      isVisibleprovide: false,
      isVisiblesettings: false,
      isVisibleauth: false,
      isVisibleerror: false,
      isVisiblebook: false,
    };
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    IndexMenu() {
      return this.$route.name == "index" || this.$route.name == "index-2";
    },
    ServicesMenu() {
      return (
        this.$route.name == "service-grid" ||
        this.$route.name == "service-list" ||
        this.$route.name == "search" ||
        this.$route.name == "providers" ||
        this.$route.name == "provider-details" ||
        this.$route.name == "create-service"
      );
    },
    ProviderMenu() {
      return this.$route.name == "providers" || this.$route.name == "provider-details";
    },
    ServiceDetailsMenu() {
      return (
        this.$route.name == "service-details" || this.$route.name == "service-details2"
      );
    },
    CustomersMenu() {
      return (
        this.$route.name == "customer-dashboard" ||
        this.$route.name == "customer-booking" ||
        this.$route.name == "customer-favourite" ||
        this.$route.name == "customer-wallet" ||
        this.$route.name == "customer-reviews" ||
        this.$route.name == "customer-chat" ||
        this.$route.name == "customer-profile"
      );
    },
    ProvidersMenu() {
      return (
        this.$route.name == "provider-dashboard" ||
        this.$route.name == "provider-services" ||
        this.$route.name == "provider-booking" ||
        this.$route.name == "provider-payout" ||
        this.$route.name == "provider-appointment-settings" ||
        this.$route.name == "provider-profile-settings" ||
        this.$route.name == "provider-social-profile" ||
        this.$route.name == "provider-security-setting" ||
        this.$route.name == "provider-plan" ||
        this.$route.name == "provider-notifcation" ||
        this.$route.name == "provider-connected-apps" ||
        this.$route.name == "provider-availability" ||
        this.$route.name == "provider-holiday" ||
        this.$route.name == "provider-coupons" ||
        this.$route.name == "provider-offers" ||
        this.$route.name == "provider-reviews" ||
        this.$route.name == "provider-earnings" ||
        this.$route.name == "provider-chat"
      );
    },
    BookingMenu() {
      return this.$route.name == "booking-details" || this.$route.name == "booking";
    },
    PagesMenu() {
      return (
        this.$route.name == "about-us" ||
        this.$route.name == "contact-us" ||
        this.$route.name == "how-it-works" ||
        this.$route.name == "error-404" ||
        this.$route.name == "error-500" ||
        this.$route.name == "choose-signup" ||
        this.$route.name == "user-signup" ||
        this.$route.name == "provider-signup" ||
        this.$route.name == "login" ||
        this.$route.name == "reset-password" ||
        this.$route.name == "password-recovery" ||
        this.$route.name == "phone-otp" ||
        this.$route.name == "email-otp" ||
        this.$route.name == "free-trial" ||
        this.$route.name == "booking" ||
        this.$route.name == "booking-2" ||
        this.$route.name == "booking-payment" ||
        this.$route.name == "booking-done" ||
        this.$route.name == "categories" ||
        this.$route.name == "pricing" ||
        this.$route.name == "faq" ||
        this.$route.name == "maintenance" ||
        this.$route.name == "coming-soon" ||
        this.$route.name == "privacy-policy" ||
        this.$route.name == "terms-condition" ||
        this.$route.name == "session-expired" ||
        this.$route.name == "booking-details"
      );
    },
    BlogMenu() {
      return (
        this.$route.name == "blog-grid" ||
        this.$route.name == "blog-list" ||
        this.$route.name == "blog-details"
      );
    },
    AuthenticationMenu() {
      return (
        this.$route.name == "choose-signup" ||
        this.$route.name == "user-signup" ||
        this.$route.name == "provider-signup" ||
        this.$route.name == "free-trial"
      );
    },
  },
  methods: {
    redirectReload() {
      this.$router.push({ path: "/booking-2" }).then(() => {
        this.$router.go();
      });
    },
    redirectReloadCreate() {
      this.$router.push({ path: "/create-service" }).then(() => {
        this.$router.go();
      });
    },
    redirectReload2() {
      this.$router.push({ path: "/booking-2" }).then(() => {
        this.$router.go();
      });
    },
    redirectReloadCustomer() {
      this.$router.push({ path: "/customer-booking" }).then(() => {
        this.$router.go();
      });
    },
    toggleVisibilityindex() {
      this.isVisibleindex = !this.isVisibleindex;
    },
    toggleVisibilityservice() {
      this.isVisibleservice = !this.isVisibleservice;
    },
    toggleVisibilitycustomer() {
      this.isVisiblecustomers = !this.isVisiblecustomers;
    },
    toggleVisibilityproviders() {
      this.isVisibleproviders = !this.isVisibleproviders;
    },
    toggleVisibilitypages() {
      this.isVisiblepages = !this.isVisiblepages;
    },
    toggleVisibilityblog() {
      this.isVisibleblog = !this.isVisibleblog;
    },
    toggleVisibilitydetails() {
      this.isVisibledetails = !this.isVisibledetails;
    },
    toggleVisibilityprovide() {
      this.isVisibleprovide = !this.isVisibleprovide;
    },
    toggleVisibilitysettings() {
      this.isVisiblesettings = !this.isVisiblesettings;
    },
    toggleVisibilityauth() {
      this.isVisibleauth = !this.isVisibleauth;
    },
    toggleVisibilityerror() {
      this.isVisibleerror = !this.isVisibleerror;
    },
    toggleVisibilitybook() {
      this.isVisiblebook = !this.isVisiblebook;
    },
  },
};
</script>
