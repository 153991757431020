import {createRouter, createWebHistory} from 'vue-router';

import AboutUs from '@/views/frontend/pages/Pages/AboutUs/aboutus.vue'
import AddCoupon from '@/views/frontend/pages/Providers/Coupons/Addcoupon/addcoupon.vue'
import AddSubscription from '@/views/frontend/pages/Providers/Subscription/Addsubscription/addsubscription.vue'
import Blogdetails from '@/views/frontend/pages/Blog/blogdetails/blogdetails.vue'
import BlogGrid from '@/views/frontend/pages/Blog/bloggrid/bloggrid.vue'
import BlogList from '@/views/frontend/pages/Blog/bloglist/bloglist.vue'
import BookingDone from '@/views/frontend/pages/Pages/Booking/Booking-done/bookingdone.vue'
import BookingPayment from '@/views/frontend/pages/Pages/Booking/Booking-payment/bookingpayment.vue'
import BookingTwo from '@/views/frontend/pages/Pages/Booking/booking2/booking-2.vue'
import PagesBooking from '@/views/frontend/pages/Pages/Booking/booking/booking.vue'
import Categories from '@/views/frontend/pages/Pages/Categories/categories.vue'
import ChangePassword from '@/views/frontend/pages/Pages/ChangePassword/changepassword.vue'
import ChooseSignup from '@/views/frontend/pages/Pages/Authentication/ChooseSignup/choosesignup.vue'
import ComingSoon from '@/views/frontend/pages/Pages/Comingsoon/comingsoon.vue'
import ConnecedApps from '@/views/frontend/pages/Customers/Settings/Connectedapps/connectedapps.vue'
import ContactUs from '@/views/frontend/pages/Pages/ContactUs/contact.vue'
import CustomerCalendar from '@/views/frontend/pages/Customers/Booking/CustomerBookingCalender/Customerbookcalendar'
import CustomerBooking from '@/views/frontend/pages/Customers/Booking/Customerbooking/customerbooking.vue'
import CustomerChat from '@/views/frontend/pages/Customers/Chat/customerchat.vue'
import CustomerDashboard from '@/views/frontend/pages/Customers/Dashboard/customerdashboard.vue'
import CustomerFavourite from '@/views/frontend/pages/Customers/Favourites/customerfavourite.vue'
import CustomerNotifications from '@/views/frontend/pages/Customers/Settings/Customernotification/customernotification.vue'
import CustomerProfile from '@/views/frontend/pages/Customers/Settings/Customerprofile/customerprofile.vue'
import CustomerReviews from '@/views/frontend/pages/Customers/Reviews/customerreview.vue'
import CustomerWallet from '@/views/frontend/pages/Customers/Wallet/customerwallet.vue'
import DeviceManagement from '@/views/frontend/pages/Devicemanagement/devicemanagement.vue'
import EmailOtp from '@/views/frontend/pages/Pages/Authentication/Emailotp/emailotp.vue'
import ErrorFour from '@/views/frontend/pages/Pages/Errorpages/Error404/error404.vue'
import ErrorFive from '@/views/frontend/pages/Pages/Errorpages/Error500/error500.vue'
import Faq from '@/views/frontend/pages/Pages/Faq/faq.vue'
import FreeTrial from '@/views/frontend/pages/Pages/Authentication/Freetrial/freetrial.vue'
import HowItWorks from '@/views/frontend/pages/Pages/Howitswork/howitswork.vue'
import Invoice from '@/views/frontend/pages/Providers/Invoice/invoice.vue'
import LoginActivity from '@/views/frontend/pages/loginactivity.vue'
import LoginEmail from '@/views/frontend/pages/Login/loginemail.vue'
import LoginPhone from '@/views/frontend/pages/Login/loginphone.vue'
import LoginPhoneOne from '@/views/frontend/pages/Login/loginphone1.vue'
import Login from '@/views/frontend/pages/Pages/Authentication/Login/login.vue'
// import Maintenance from '@/views/frontend/pages/Pages/Maintenance/maintenance.vue'
import Notifications from '@/views/frontend/pages/notification.vue'
import Orders from '@/views/frontend/pages/orders.vue'
import PasswordRecovery from '@/views/frontend/pages/Pages/Authentication/Passwordupdate/passwordrecovery.vue'
import PaymentSettings from '@/views/frontend/pages/Providers/settings/Paymentsettings/paymentsettings.vue'
import PhoneOtp from '@/views/frontend/pages/Pages/Authentication/Phoneotp/phoneotp.vue'
import Pricing from '@/views/frontend/pages/Pages/Pricingplan/pricing.vue'
import PrivacyPolicy from '@/views/frontend/pages/Pages/Privacypolicy/privacypolicy.vue'
import ProviderAddons from '@/views/frontend/pages/Providers/Subscription/Provideraddons/provideraddons.vue'
import ProviderAppointmentSetting from '@/views/frontend/pages/Providers/settings/Appointmentsetting/appointmentsettings.vue'
import ProviderAvailability from '@/views/frontend/pages/Providers/Availability/availability.vue'
import ProviderBookDetails from '@/views/frontend/pages/Providers/Booking/Bookingdetails/bookingdetails.vue'
import ProviderBooking from '@/views/frontend/pages/Providers/Booking/Booking/booking.vue'
import ProviderChangePassword from '@/views/frontend/pages/Providers/changepassword.vue'
import ProviderChat from '@/views/frontend/pages/Providers/Chat/chat.vue'
import ProviderConnectApps from '@/views/frontend/pages/Providers/settings/Connectedapps/connectedapps.vue'
import ProviderCoupons from '@/views/frontend/pages/Providers/Coupons/Coupons/coupons.vue'
import ProviderDashboard from '@/views/frontend/pages/Providers/Dashboard/dashboard.vue'
import ProviderDetails from '@/views/frontend/pages/Services/Provider/ProviderDetails/details.vue'
import ProviderDeviceManagement from '@/views/frontend/pages/Providers/Devicemanagement/devicemanagement.vue'
import ProviderEarnings from '@/views/frontend/pages/Providers/Earnings/earnings.vue'
import ProviderEditService from '@/views/frontend/pages/Providers/Myservices/Editservices/editservice.vue'
import ProviderHoliday from '@/views/frontend/pages/Providers/Holiday&Leaves/holiday.vue'
import ProviderLoginActivity from '@/views/frontend/pages/Providers/loginactivity.vue'
import ProviderNotifications from '@/views/frontend/pages/Providers/settings/Notification/providenotifications.vue'
import ProviderOffers from '@/views/frontend/pages/Providers/Offers/offers.vue'
import ProviderPayout from '@/views/frontend/pages/Providers/Payout/payout.vue'
import ProviderPlan from '@/views/frontend/pages/Providers/settings/Plan&billings/planbilling.vue'
import ProviderProfileSettings from '@/views/frontend/pages/Providers/settings/Accountsettings/profile.vue'
import ProviderReviews from '@/views/frontend/pages/Providers/Reviews/reviews.vue'
import ProviderSecuritySettings from '@/views/frontend/pages/Providers/settings/Security/security.vue'
import ProviderServicesList from '@/views/frontend/pages/Providers/Myservices/Serviceslist/myservice.vue'
import ProviderSignupPayment from '@/views/frontend/pages/Providers/signuppayment.vue'
import ProviderServices from '@/views/frontend/pages/Providers/Myservices/services.vue'
import ProviderSignupSubscription from '@/views/frontend/pages/Providers/signupsubscription.vue'
import ProviderSignup from '@/views/frontend/pages/Providers/signup.vue'
import ProviderSocialProfile from '@/views/frontend/pages/Providers/settings/Socialprofiles/socialprofiles.vue'
import ProviderSubscription from '@/views/frontend/pages/Providers/Subscription/subscription.vue'
import Providers from '@/views/frontend/pages/Services/Provider/Providerlist/providerlist.vue'
import ResetPassword from '@/views/frontend/pages/Pages/Authentication/Resetpassword/resetpassword.vue'
import SearchList from '@/views/frontend/pages/Services/Searchlist/searchlist.vue'
import Search from '@/views/frontend/pages/Services/Search/search.vue'
import SecuritySettings from '@/views/frontend/pages/Customers/Settings/Securitysettings/securitysettings.vue'
import Servicedetails from '@/views/frontend/pages/Services/Servicedetails/servicedetails.vue'
import ServiceGrid from '@/views/frontend/pages/Services/Servicegrid/servicegrid.vue'
import CreateService from '@/views/frontend/pages/Services/Createservice/create-service.vue'
import ServiceList from '@/views/frontend/pages/Services/Servicelist/servicelist.vue'
import ServiceExpried from '@/views/frontend/pages/sessionexpried.vue'
import Success from '@/views/frontend/pages/success.vue'
import TermsConditions from '@/views/frontend/pages/Pages/termscondition.vue'
import UserSignup from '@/views/frontend/pages/Pages/Authentication/ChooseSignup/Usersignup/usersignup.vue'
import Index from '@/views/frontend/pages/Index/index.vue'
import IndexTwo from '@/views/frontend/pages/Indextwo/indextwo.vue'
import IndexThree from '@/views/frontend/pages/Indexthree/indexthree.vue'
import IndexFour from '@/views/frontend/pages/indexfour/indexfour.vue'
import IndexFive from '@/views/frontend/pages/indexfive/indexfive.vue'
import IndexSix from '@/views/frontend/pages/indexsix/indexsix.vue'
import IndexSeven from '@/views/frontend/pages/indexseven/indexseven.vue'
import IndexEight from '@/views/frontend/pages/indexeight/indexeight.vue'
import IndexNine from '@/views/frontend/pages/indexnine/indexnine.vue'
import Verification from '@/views/frontend/pages/Providers/settings/profile-verification/verification.vue'
import BookingDetails from '@/views/frontend/pages/Pages/Booking/booking-details/booking-details.vue'
import SessionExpired from '@/views/frontend/pages/Pages/session-expired.vue'
import Servicedetails2 from '@/views/frontend/pages/Services/servicedetails2/servicedetails2.vue'


/**************** Admin  *************/
import abusereports from '@/views/admin/pages/Abusereport/abuse-reports.vue'
import Accountsettings from '@/views/admin/pages/settings/Generalsettings/accountsettings.vue'
import Addannouncement from '@/views/admin/pages/Support/addannouncement.vue'
import AddBlogcategories from '@/views/admin/pages/Content/Blog/addblogcategories.vue'
import AddBlog from '@/views/admin/pages/Content/Blog/addblog.vue'
import AddCategories from '@/views/admin/pages/Services/addcategories.vue'
import AddCity from '@/views/admin/pages/Content/Locations/addcities.vue'
import AddCountries from '@/views/admin/pages/Content/Locations/addcountries.vue'
import AddCustomer from '@/views/admin/pages/Usermanagement/User/addcustomer.vue'
import AddMembership from '@/views/admin/pages/Membership/addmembership.vue'
import AddPage from '@/views/admin/pages/Content/pages/addpages.vue'
import AddPayout from '@/views/admin/pages/Finance&accounts/Payouts/addpayout.vue'
import AddProvider from '@/views/admin/pages/Usermanagement/User/addprovider.vue'
import AddReviewtype from '@/views/admin/pages/Services/Review/addreviewtype.vue'
import AddRole from '@/views/admin/pages/Usermanagement/addrole.vue'
import AddService from '@/views/admin/pages/Services/Addservice/addservice.vue'
import AddState from '@/views/admin/pages/Content/Locations/addstate.vue'
import AddSubCategories from '@/views/admin/pages/Services/addsubcategories.vue'
import AddTestimonials from '@/views/admin/pages/Content/addtestimonials.vue'
import AddUser from '@/views/admin/pages/Usermanagement/User/adduser.vue'
import AddEarnings from '@/views/admin/pages/Reports/adminearning.vue'
import AllBlog from '@/views/admin/pages/Content/Blog/allblog.vue'
import Announcements from '@/views/admin/pages/Support/announcements.vue'
import ApprovedTransferlist from '@/views/admin/pages/Finance&accounts/approved-transferlist.vue'
import Appearance from '@/views/admin/pages/settings/Websitesettings/appearance.vue'
import AuthenticationSettings from '@/views/admin/pages/settings/Websitesettings/authentication-settings.vue'
import AvailablePlugins from '@/views/admin/pages/Services/Services/available-plugins.vue'
import AwsStorage from '@/views/admin/pages/aws-storage.vue'
import BanIpAddress from '@/views/admin/pages/settings/Othersettings/banipaddress.vue'
import Banktransfer from '@/views/admin/pages/settings/Generalsettings/banktransfer.vue'
import BanktransferList from '@/views/admin/pages/Finance&accounts/banktransferlist.vue'
import BlogsCategories from '@/views/admin/pages/Content/Blog/blogs-categories.vue'
import BlogsComments from '@/views/admin/pages/Content/Blog/blogs-comments.vue'
import AdminBooking from '@/views/admin/pages/Booking/booking.vue'
import CacheSystem from '@/views/admin/pages/management/cachesystem.vue'
import CancelleBooking from '@/views/admin/pages/Booking/cancelled-booking.vue'
import CashOnDelivery from '@/views/admin/pages/Finance&accounts/cash-on-delivery.vue'
import AdminCategories from '@/views/admin/pages/Services/categories.vue'
import Changepassword from '@/views/admin/pages/change-password.vue'
import Chat from '@/views/admin/pages/Others/chat.vue'
import Cities from '@/views/admin/pages/Content/Locations/cities.vue'
import CompletedBooking from '@/views/admin/pages/Booking/completed-booking.vue'
import AdminconnectedApps from '@/views/admin/pages/settings/Generalsettings/connected-apps.vue'
import ContactMessagesView from '@/views/admin/pages/Support/contact-messages-view.vue'
import ConnectedMessages from '@/views/admin/pages/Support/connected-messages.vue'
import Countries from '@/views/admin/pages/Content/Locations/countries.vue'
import CreateMenu from '@/views/admin/pages/Services/create-menu.vue'
import Cronjob from '@/views/admin/pages/settings/Othersettings/cronjob.vue'
import Currencies from '@/views/admin/pages/settings/Financialsettings/currencies.vue'
import CurrencySettings from '@/views/admin/pages/settings/Paymentsettings/currency-settings.vue'
import DatabaseBackup from '@/views/admin/pages/settings/Othersettings/database-backup.vue'
import DeleteAccountRequest from '@/views/admin/pages/Usermanagement/delete-account-requests.vue'
import DeleteServices from '@/views/admin/pages/Services/Services/deleted-services.vue'
import AdminDeviceManagement from '@/views/admin/pages/device-management.vue'
import EditAnnouncement from '@/views/admin/pages/Support/edit-announcement.vue'
import EditBlogCategories from '@/views/admin/pages/Content/Blog/edit-blog-categories.vue'
import EditBlog from '@/views/admin/pages/Content/Blog/edit-blog.vue'
import EditCategories from '@/views/admin/pages/Services/edit-categories.vue'
import EditCity from '@/views/admin/pages/Content/Locations/edit-city.vue'
import EditCountries from '@/views/admin/pages/Content/Locations/edit-countries.vue'
import EditCoupon from '@/views/admin/pages/Marketing/edit-coupon.vue'
import EditCustomer from '@/views/admin/pages/Usermanagement/User/edit-customer.vue'
import EditEmailTemplate from '@/views/admin/pages/management/edit-email-template.vue'
import EditManagement from '@/views/admin/pages/Services/edit-managemenet.vue'
import EditOffer from '@/views/admin/pages/Marketing/edit-offer.vue'
import EditPage from '@/views/admin/pages/Content/pages/edit-page.vue'
import EditProvider from '@/views/admin/pages/Usermanagement/User/edit-provider.vue'
import EditReviewtype from '@/views/admin/pages/Services/Review/edit-reviewtype.vue'
import EditRole from '@/views/admin/pages/Usermanagement/edit-role.vue'
import EditService from '@/views/admin/pages/Services/Addservice/edit-service.vue'
import EditSmsTemplate from '@/views/admin/pages/management/edit-sms-template.vue'
import EditState from '@/views/admin/pages/Content/Locations/edit-state.vue'
import EditSubcategories from '@/views/admin/pages/Services/edit-subcategories.vue'
import EditTestimonials from '@/views/admin/pages/Content/edit-testimonials.vue'
import EditUser from '@/views/admin/pages/Usermanagement/User/edit-user.vue'
import EmailSettings from '@/views/admin/pages/settings/Systemsettings/email-settings.vue'
import EmailStorageSettings from '@/views/admin/pages/settings/Systemsettings/email-storage-settings.vue'
import EmailTemplates from '@/views/admin/pages/management/email-templates.vue'
import FacebookApi from '@/views/admin/pages/facebook-api.vue'
import AdminFaq from '@/views/admin/pages/Content/faq.vue'
import FooterSettings from '@/views/admin/pages/settings/Websitesettings/footer-settings.vue'
import GdprSettings from '@/views/admin/pages/settings/Systemsettings/gdpr-settings.vue'
import GoogleApi from '@/views/admin/pages/google-api.vue'
import HeaderSettings from '@/views/admin/pages/settings/Websitesettings/header-settings.vue'
import InactiveBlog from '@/views/admin/pages/Content/Blog/inactive-blog.vue'
import InactiveServices from '@/views/admin/pages/Services/Services/inactive-services.vue'
import AdminIndex from '@/views/admin/pages/Home/index.vue'
import InprogressBooking from '@/views/admin/pages/Booking/inprogress-booking.vue'
import Language from '@/views/admin/pages/settings/Websitesettings/language.vue'
import Localization from '@/views/admin/pages/settings/Generalsettings/localization.vue'
import Loginactivity from '@/views/admin/pages/login-activity.vue'
import MarketingCoupons from '@/views/admin/pages/Marketing/marketing-coupons.vue'
import MarketingFeatured from '@/views/admin/pages/Marketing/marketing-featured.vue'
import MarketingNewsLetter from '@/views/admin/pages/Marketing/marketing-newsletter.vue'
import MarketingService from '@/views/admin/pages/Marketing/marketing-service.vue'
import MembershipAddons from '@/views/admin/pages/Membership/membership-addons.vue'
import MembershipTransaction from '@/views/admin/pages/Reports/membership-transaction.vue'
import Membership from '@/views/admin/pages/Membership/membership.vue'
import MenuManagement from '@/views/admin/pages/Marketing/menu-management.vue'
import Nexmo from '@/views/admin/pages/nexmo.vue'
import AdminNotifications from '@/views/admin/pages/settings/Generalsettings/notifications.vue'
import PageList from '@/views/admin/pages/Content/pages/page-list.vue'
import PagesList from '@/views/admin/pages/Content/pages/pages-list.vue'
import PaymentGateways from '@/views/admin/pages/settings/Financialsettings/payment-gateways.vue'
import AdminPaymentSettings from '@/views/admin/pages/settings/Financialsettings/payment-settings.vue'
import PayoutRequest from '@/views/admin/pages/Finance&accounts/Payouts/payout-request.vue'
import PayoutSettings from '@/views/admin/pages/Finance&accounts/Payouts/payout-settings.vue'
import Paypal from '@/views/admin/pages/paypal.vue'
import pendingBlog from '@/views/admin/pages/Content/Blog/pending-blog.vue'
import PendingBooking from '@/views/admin/pages/Booking/pending-booking.vue'
import PendingServices from '@/views/admin/pages/Services/Services/pending-services.vue'
import PendingTransferlist from '@/views/admin/pages/Finance&accounts/pending-transferlist.vue'
import PhpMail from '@/views/admin/pages/php-mail.vue'
import PluginsManager from '@/views/admin/pages/Services/Services/plugins-manager.vue'
import PreferenceSettings from '@/views/admin/pages/settings/Websitesettings/preference-settings.vue'
import providerEarnings from '@/views/admin/pages/Reports/provider-earnings.vue'
import providerSettings from '@/views/admin/pages/settings/provider-settings.vue'
import RefundRequest from '@/views/admin/pages/Finance&accounts/refund-request.vue'
import RejectedTransferlist from '@/views/admin/pages/Finance&accounts/rejected-transferlist.vue'
import ReviewType from '@/views/admin/pages/Services/Review/review-type.vue'
import Review from '@/views/admin/pages/Services/Review/review.vue'
import RolePermission from '@/views/admin/pages/Usermanagement/roles.vue'
import RolesPermissions from '@/views/admin/pages/Usermanagement/permissions.vue'
import SalesTransactions from '@/views/admin/pages/Finance&accounts/sales-transactions.vue'
import Security from '@/views/admin/pages/settings/Generalsettings/security.vue'
import SeoSettings from '@/views/admin/pages/settings/Websitesettings/seo-settings.vue'
import ServiceSettings from '@/views/admin/pages/settings/service-settings.vue'
import Services from '@/views/admin/pages/Services/Services/services.vue'
import Signin from '@/views/admin/pages/signin.vue'
import SiteInformation from '@/views/admin/pages/settings/Websitesettings/site-information.vue'
import SmsSettings from '@/views/admin/pages/settings/Systemsettings/sms-settings.vue'
import SmsTemplates from '@/views/admin/pages/management/sms-templates.vue'
import Smtp from '@/views/admin/pages/smtp.vue'
import SocialAuthentication from '@/views/admin/pages/settings/Websitesettings/social-authentication.vue'
import SocialProfiles from '@/views/admin/pages/settings/Generalsettings/social-profiles.vue'
import States from '@/views/admin/pages/Content/Locations/states.vue'
import StorageSettings from '@/views/admin/pages/settings/Othersettings/storage-settings.vue'
import SubCategories from '@/views/admin/pages/Services/sub-categories.vue'
import SuccessfulTransferlist from '@/views/admin/pages/Finance&accounts/successful-transferlist.vue'
import SystemBackup from '@/views/admin/pages/settings/Othersettings/system-backup.vue'
import SystemInformation from '@/views/admin/pages/settings/Othersettings/system-information.vue'
import TaxRates from '@/views/admin/pages/settings/Financialsettings/tax-rates.vue'
import Testimonials from '@/views/admin/pages/Content/testimonials.vue'
import UserCustomers from '@/views/admin/pages/Usermanagement/User/customers.vue'
import UserProviders from '@/views/admin/pages/Usermanagement/User/providers.vue'
import UserList from '@/views/admin/pages/Usermanagement/User/users.vue'
import ViewService from '@/views/admin/pages/Services/Services/view-service.vue'
import WalletHistory from '@/views/admin/pages/Finance&accounts/wallet-history.vue'
import Wallet from '@/views/admin/pages/Finance&accounts/wallet.vue'
import WebsiteSettings from '@/views/admin/pages/Services/website-settings.vue'
import Active_Services from '@/views/admin/pages/Services/Services/active-services.vue'
import Resgister_Report from '@/views/admin/pages/Reports/register-report.vue'
import Provider_Sales from '@/views/admin/pages/Reports/provider-sales.vue'
import Provider_Wallet from '@/views/admin/pages/Reports/provider-wallet.vue'
import Customer_Wallet from '@/views/admin/pages/Reports/customer-wallet.vue'
import Refund_Report from '@/views/admin/pages/Reports/refund-report.vue'
import Service_Sales from '@/views/admin/pages/Reports/service-sales.vue'
import ContactMessages from '@/views/admin/pages/Support/contact-messages.vue'
import Appointment_Settings from '@/views/admin/pages/settings/Generalsettings/appointment-settings.vue'
import Typography_Settings from '@/views/admin/pages/settings/Websitesettings/typography-settings.vue'
import Calendar_Settings from '@/views/admin/pages/settings/Systemsettings/calendar-settings.vue'
import Edit_Management from '@/views/admin/pages/Marketing/edit-management.vue'
import Verification_Request from '@/views/admin/pages/Usermanagement/verification-request.vue'
import ForgetPassword from '@/views/admin/pages/forget-password.vue'
import Signup from '@/views/admin/pages/signup.vue'
// Start Rental
import Role from '@/views/admin/pages/AdminSettings/role-setting.vue'
import Menu from '@/views/admin/pages/AdminSettings/menu-setting.vue'
import Acces from '@/views/admin/pages/AdminSettings/acces-setting.vue'
import User from '@/views/admin/pages/AdminSettings/user-setting.vue'
import Company from '@/views/admin/pages/AdminSettings/company-setting.vue'
import Branch from '@/views/admin/pages/AdminSettings/branch-setting.vue'
import Papper from '@/views/admin/pages/AdminSettings/papper-setting.vue'
import Fines from '@/views/admin/pages/AdminSettings/fines-setting.vue'
import Expenditure from '@/views/admin/pages/AdminSettings/expenditure-setting.vue'
import Maintenance from '@/views/admin/pages/AdminSettings/maintenance-setting.vue'
import Customer from '@/views/admin/pages/AdminSettings/customer.vue'
import Product from '@/views/admin/pages/AdminSettings/product.vue'
import Vehicle from '@/views/admin/pages/AdminSettings/vehicle.vue'
import VehicleDetail from '@/views/admin/pages/AdminSettings/vehicle-detail.vue'
import Order from '@/views/admin/pages/AdminSettings/order.vue'
import CheckVehicle from '@/views/admin/pages/AdminSettings/check-vehicle.vue'
import ReturnList from '@/views/admin/pages/AdminSettings/return-list.vue'
import JadwalOrder from '@/views/admin/pages/AdminSettings/jadwal-order.vue'
import JadwalMaintenance from '@/views/admin/pages/AdminSettings/jadwal-maintenance.vue'
import Finance from '@/views/admin/pages/AdminSettings/finance.vue'
import Subscribe from '@/views/admin/pages/AdminSettings/subscribe.vue'
import Help from '@/views/admin/pages/AdminSettings/help.vue'
import Monitoring3 from '@/views/admin/pages/AdminSettings/monitoring3.vue'
import Pengaturan from '@/views/admin/pages/AdminSettings/pengaturan.vue'
import FinanceMenu from '@/views/admin/pages/AdminSettings/finance-menu.vue'
import Brand from '@/views/admin/pages/AdminSettings/brand.vue'
import Vendor from '@/views/admin/pages/AdminSettings/vendor.vue'
import CreateVehicle from '@/views/admin/pages/AdminSettings/create-vehicle.vue'
// End Rental


// rental here
import MariEmailVerify from '@/views/mari/pages/auth/email-verify.vue'
import MariEmailValidation from '@/views/mari/pages/auth/email-validation.vue'
import MariHome from '@/views/mari/pages/home.vue'
import MariMonitoring from '@/views/mari/pages/monitoring.vue'
import MariVechile from '@/views/mari/pages/vehicle.vue'
import MariOrder from '@/views/mari/pages/order.vue'
import MariCreateVehicle from '@/views/mari/pages/create-vehicle.vue'

import MariLogin from '@/views/mari/pages/auth/login.vue'
import MariRegister from '@/views/mari/pages/auth/register.vue'
import MariForgotPassword from '@/views/mari/pages/auth/forgot-password.vue'
import MariResetPassword from '@/views/mari/pages/auth/reset-password.vue'
import MariPengaturan from '@/views/mari/pages/pengaturan.vue'
import MariFinance from '@/views/mari/pages/finance-menu.vue'
import MariSubscribe from '@/views/mari/pages/subscribe.vue'
import MariHelp from '@/views/mari/pages/help.vue'
import MariCompany from '@/views/mari/pages/company-setting.vue'
import MariPaket from '@/views/mari/pages/product.vue'
import MariUser from '@/views/mari/pages/user-setting.vue'
import MariVendor from '@/views/mari/pages/vendor.vue'
import MariBrand from '@/views/mari/pages/brand.vue'
import MariRole from '@/views/mari/pages/role-setting.vue'
import MariMenu from '@/views/mari/pages/menu-setting.vue'
import MariAcces from '@/views/mari/pages/acces-setting.vue'
import MariDenda from '@/views/mari/pages/fines-setting.vue'
import MariPengeluaran from '@/views/mari/pages/expenditure-setting.vue'
import MariKeuangan from '@/views/mari/pages/finance.vue'
import MariPelanggan from '@/views/mari/pages/customer.vue'
import MariGuide from '@/views/mari/pages/guide/list-guide.vue'
import MariDetailVechile from '@/views/mari/pages/vehicle-detail.vue'
import MariRecapDebitCredit from '@/views/mari/pages/recap-debit-credit.vue'
import MariFinanceVendor from '@/views/mari/pages/finance-vendor.vue'
import MariHomeDriver from '@/views/mari/pages/driver/home-driver.vue'
import MariOrderDriver from '@/views/mari/pages/driver/order.vue'
import MariPelunasan from '@/views/mari/pages/pelunasan.vue'
import MariInvoice from '@/views/mari/pages/invoice.vue'
import MariAbsenDriver from '@/views/mari/pages/absen-driver.vue'
import MariReportDriver from '@/views/mari/pages/list-report-driver.vue'
import MariHomeStaff from '@/views/mari/pages/staff/home-staff.vue'
import MariOrderStaff from '@/views/mari/pages/staff/order.vue'
import MariProfileDriver from '@/views/mari/pages/driver/profile.vue'
import MariProfileStaff from '@/views/mari/pages/staff/profile.vue'
import MariErrorLimit from '@/views/mari/pages/error/limit-company.vue'
import MariDriverGrafik from '@/views/mari/pages/driver-grafik.vue'
import MariDriverAbsen from '@/views/mari/pages/driver/absen.vue'
// rental here

const routes = [
    // about rental here
    {
        path: '/mari/login',
        name: '/mari/login',
        component: MariLogin
    },
    {
        path: '/mari/register',
        name: '/mari/register',
        component: MariRegister
    },
    {
        path: '/mari/email-verify',
        name: '/mari/email-verify',
        component: MariEmailVerify
    },
    {
        path: '/mari/email-validation',
        name: '/mari/email-validation',
        component: MariEmailValidation
    },
    {
        path: '/mari/forgot-password',
        name: '/mari/forgot-password',
        component: MariForgotPassword
    },
    {
        path: '/mari/reset-password',
        name: '/mari/reset-password',
        component: MariResetPassword
    },
    {
        path: '/mari',
        name: '/mari',
        component: MariHome,
        meta: { requiresAuth: true, roles: ['SUPER ADMIN', 'OWNER', 'Operator'] },
    },
    {
        path: '/mari/monitoring',
        name: '/mari/monitoring',
        component: MariMonitoring
    },
    {
        path: '/mari/vehicle',
        name: '/mari/vehicle',
        component: MariVechile
    },
    {
        path: '/mari/order',
        name: '/mari/order',
        component: MariOrder
    },
    {
        path: '/mari/create-vehicle',
        name: '/mari/create-vehicle',
        component: MariCreateVehicle
    },
    {
        path: '/mari/pengaturan',
        name: '/mari/pengaturan',
        component: MariPengaturan
    },
    {
        path: '/mari/finance-menu',
        name: '/mari/finance-menu',
        component: MariFinance
    },
    {
        path: '/mari/subscribe',
        name: '/mari/subscribe',
        component: MariSubscribe
    },
    {
        path: '/mari/help',
        name: '/mari/help',
        component: MariHelp
    },
    {
        path: '/mari/company',
        name: '/mari/company',
        component: MariCompany
    },
    {
        path: '/mari/paket',
        name: '/mari/paket',
        component: MariPaket
    },
    {
        path: '/mari/user-setting',
        name: '/mari/user-setting',
        component: MariUser
    },
    {
        path: '/mari/vendor',
        name: '/mari/vendor',
        component: MariVendor
    },
    {
        path: '/mari/brand',
        name: '/mari/brand',
        component: MariBrand
    },
    {
        path: '/mari/role',
        name: '/mari/role',
        component: MariRole
    },
    {
        path: '/mari/menu',
        name: '/mari/menu',
        component: MariMenu
    },
    {
        path: '/mari/acces',
        name: '/mari/acces',
        component: MariAcces
    },
    {
        path: '/mari/denda',
        name: '/mari/denda',
        component: MariDenda
    },
    {
        path: '/mari/pengeluaran',
        name: '/mari/pengeluaran',
        component: MariPengeluaran
    },
    {
        path: '/mari/keuangan',
        name: '/mari/keuangan',
        component: MariKeuangan
    },
    {
        path: '/mari/pelanggan',
        name: '/mari/pelanggan',
        component: MariPelanggan
    },
    {
        path: '/mari/recap-debit-credit',
        name: '/mari/recap-debit-credit',
        component: MariRecapDebitCredit,
    },
    {
        path: '/mari/finance-vendor',
        name: '/mari/finance-vendor',
        component: MariFinanceVendor,
    },
    {
        path: '/mari/guide',
        name: '/mari/guide',
        component: MariGuide
    },
    {
        path: '/mari/detail-vehicle/:id',
        name: '/mari/detail-vehicle',
        component: MariDetailVechile
    },
    {
        path: '/mari/driver/home',
        name: '/mari/driver/home',
        component: MariHomeDriver,
        meta: { requiresAuth: true, role: 'Driver' },
    },
    {
        path: '/mari/driver/order',
        name: '/mari/driver/order',
        component: MariOrderDriver
    },
    {
        path: '/mari/pelunasan',
        name: '/mari/pelunasan',
        component: MariPelunasan
    },
    {
        path: '/mari/invoice',
        name: '/mari/invoice',
        component: MariInvoice
    },
    {
        path: '/mari/absen-driver',
        name: '/mari/absen-driver',
        component: MariAbsenDriver
    },
    {
        path: '/mari/report-driver',
        name: '/mari/report-driver',
        component: MariReportDriver
    },
    {
        path: '/mari/staff/home',
        name: '/mari/staff/home',
        component: MariHomeStaff,
        meta: { requiresAuth: true, role: 'Staff Kendaraan' },
    },
    {
        path: '/mari/staff/order',
        name: '/mari/staff/order',
        component: MariOrderStaff
    },
    {
        path: '/mari/driver/profile/:id',
        name: '/mari/driver/profile',
        component: MariProfileDriver
    },
    {
        path: '/mari/staff/profile/:id',
        name: '/mari/staff/profile',
        component: MariProfileStaff
    },
    {
        path: '/mari/limit-company',
        name: '/mari/limit-company',
        component: MariErrorLimit
    },
    {
        path: '/mari/driver-grafik',
        name: '/mari/driver-grafik',
        component: MariDriverGrafik
    },
    {
        path: '/mari/driver/absen',
        name: '/mari/driver/absen',
        component: MariDriverAbsen
    },
    // about rental here
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs
    },
    {
        path: '/add-coupon',
        name: 'add-coupon',
        component: AddCoupon
    },
    {
        path: '/add-subscription',
        name: 'add-subscription',
        component: AddSubscription
    },
    {
        path: '/blog-details',
        name: 'blog-details',
        component: Blogdetails
    },
    {
        path: '/blog-grid',
        name: 'blog-grid',
        component: BlogGrid
    },
    {
        path: '/blog-list',
        name: 'blog-list',
        component: BlogList
    },
    {
        path: '/booking-done',
        name: 'booking-done',
        component: BookingDone
    },
    {
        path: '/booking-payment',
        name: 'booking-payment',
        component: BookingPayment
    },
    {
        path: '/booking',
        name: 'booking',
        component: PagesBooking
    },
    {
        path: '/booking-2',
        name: 'booking-2',
        component: BookingTwo
    },
    {
        path: '/categories',
        name: 'categories',
        component: Categories
    },
    {
        path: '/change-password',
        name: 'change-password',
        component: ChangePassword
    },
    {
        path: '/choose-signup',
        name: 'choose-signup',
        component: ChooseSignup
    },
    {
        path: '/coming-soon',
        name: 'coming-soon',
        component: ComingSoon
    },
    {
        path: '/connected-apps',
        name: 'connected-apps',
        component: ConnecedApps
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
    {
        path: '/customer-booking-calendar',
        name: 'customer-booking-calendar',
        component: CustomerCalendar
    },
    {
        path: '/customer-booking',
        name: 'customer-booking',
        component: CustomerBooking
    },
    {
        path: '/customer-chat',
        name: 'customer-chat',
        component: CustomerChat
    },
    {
        path: '/customer-dashboard',
        name: 'customer-dashboard',
        component: CustomerDashboard
    },
    {
        path: '/customer-favourite',
        name: 'customer-favourite',
        component: CustomerFavourite
    },
    {
        path: '/customer-notifications',
        name: 'customer-notifications',
        component: CustomerNotifications
    },
    {
        path: '/customer-profile',
        name: 'customer-profile',
        component: CustomerProfile
    },
    {
        path: '/customer-reviews',
        name: 'customer-reviews',
        component: CustomerReviews
    },
    {
        path: '/customer-wallet',
        name: 'customer-wallet',
        component: CustomerWallet
    },
    {
        path: '/device-management',
        name: 'device-management',
        component: DeviceManagement
    },
    {
        path: '/email-otp',
        name: 'email-otp',
        component: EmailOtp
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: ErrorFour
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: ErrorFive
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq
    },
    {
        path: '/free-trial',
        name: 'free-trial',
        component: FreeTrial
    },
    {
        path: '/how-it-works',
        name: 'how-it-works',
        component: HowItWorks
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: Invoice
    },
    {
        path: '/login-activity',
        name: 'login-activity',
        component: LoginActivity
    },
    {
        path: '/login-email',
        name: 'login-email',
        component: LoginEmail
    },
    {
        path: '/login-phone',
        name: 'login-phone',
        component: LoginPhone
    },
    {
        path: '/login-phone1',
        name: 'login-phone1',
        component: LoginPhoneOne
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance
    },
    {
        path: '/notification',
        name: 'notification',
        component: Notifications
    },
    {
        path: '/orders',
        name: 'orders',
        component: Orders
    },
    {
        path: '/password-recovery',
        name: 'password-recovery',
        component: PasswordRecovery
    },
    {
        path: '/payment-settings',
        name: 'payment-settings',
        component: PaymentSettings
    },
    {
        path: '/phone-otp',
        name: 'phone-otp',
        component: PhoneOtp
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: Pricing
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/provider-addons',
        name: 'provider-addons',
        component: ProviderAddons
    },
    {
        path: '/provider-appointment-settings',
        name: 'provider-appointment-settings',
        component: ProviderAppointmentSetting
    },
    {
        path: '/provider-availability',
        name: 'provider-availability',
        component: ProviderAvailability
    },
    {
        path: '/provider-book-details',
        name: 'provider-book-details',
        component: ProviderBookDetails
    },
    {
        path: '/provider-booking',
        name: 'provider-booking',
        component: ProviderBooking
    },
    {
        path: '/provider-change-password',
        name: 'provider-change-password',
        component: ProviderChangePassword
    },
    {
        path: '/provider-chat',
        name: 'provider-chat',
        component: ProviderChat
    },
    {
        path: '/provider-connected-apps',
        name: 'provider-connected-apps',
        component: ProviderConnectApps
    },
    {
        path: '/provider-coupons',
        name: 'provider-coupons',
        component: ProviderCoupons
    },
    {
        path: '/provider-dashboard',
        name: 'provider-dashboard',
        component: ProviderDashboard
    },
    {
        path: '/provider-details',
        name: 'provider-details',
        component: ProviderDetails
    },
    {
        path: '/provider-device-management',
        name: 'provider-device-management',
        component: ProviderDeviceManagement
    },
    {
        path: '/provider-earnings',
        name: 'provider-earnings',
        component: ProviderEarnings
    },
    {
        path: '/provider-edit-service',
        name: 'provider-edit-service',
        component: ProviderEditService
    },
    {
        path: '/provider-holiday',
        name: 'provider-holiday',
        component: ProviderHoliday
    },
    {
        path: '/provider-login-activity',
        name: 'provider-login-activity',
        component: ProviderLoginActivity
    },
    {
        path: '/provider-notifcations',
        name: 'provider-notifcations',
        component: ProviderNotifications
    },
    {
        path: '/provider-offers',
        name: 'provider-offers',
        component: ProviderOffers
    },
    {
        path: '/provider-payout',
        name: 'provider-payout',
        component: ProviderPayout
    },
    {
        path: '/provider-plan',
        name: 'provider-plan',
        component: ProviderPlan
    },
    {
        path: '/provider-profile-settings',
        name: 'provider-profile-settings',
        component: ProviderProfileSettings
    },
    {
        path: '/provider-reviews',
        name: 'provider-reviews',
        component: ProviderReviews
    },
    {
        path: '/provider-security-settings',
        name: 'provider-security-settings',
        component: ProviderSecuritySettings
    },
    {
        path: '/provider-services-list',
        name: 'provider-services-list',
        component: ProviderServicesList
    },
    {
        path: '/provider-signup-payment',
        name: 'provider-signup-payment',
        component: ProviderSignupPayment
    },
    {
        path: '/provider-services',
        name: 'provider-services',
        component: ProviderServices
    },
    {
        path: '/provider-signup-subscription',
        name: 'provider-signup-subscription',
        component: ProviderSignupSubscription
    },
    {
        path: '/provider-signup',
        name: 'provider-signup',
        component: ProviderSignup
    },
    {
        path: '/provider-social-profile',
        name: 'provider-social-profile',
        component: ProviderSocialProfile
    },
    {
        path: '/provider-subscription',
        name: 'provider-subscription',
        component: ProviderSubscription
    },
    {
        path: '/providers',
        name: 'providers',
        component: Providers
    },
    {
        path: '/reset-password',
        name: 'reset-passeord',
        component: ResetPassword
    },
    {
        path: '/search-list',
        name: 'search-list',
        component: SearchList
    },
    {
        path: '/search',
        name: 'search',
        component: Search
    },
    {
        path: '/security-settings',
        name: 'security-settings',
        component: SecuritySettings
    },
    {
        path: '/service-details',
        name: 'service-details',
        component: Servicedetails
    },
    {
        path: '/service-grid',
        name: 'service-grid',
        component: ServiceGrid
    },
    {
        path: '/create-service',
        name: 'create-service',
        component: CreateService
    },
    {
        path: '/service-list',
        name: 'service-list',
        component: ServiceList
    },
    {
        path: '/session-expried',
        name: 'service-expried',
        component: ServiceExpried
    },
    {
        path: '/success',
        name: 'success',
        component: Success
    },
    {
        path: '/terms-condition',
        name: 'terms-condition',
        component: TermsConditions
    },
    {
        path: '/user-signup',
        name: 'user-signup',
        component: UserSignup
    },
    {
        path: '/',
        name: 'index',
        redirect: '/mari/',
    },
    {
        path: '/index-2',
        name: 'index-2',
        component: IndexTwo
    },
    {
        path: '/index-3',
        name: 'index-3',
        component: IndexThree
    },
    {
        path: '/index-4',
        name: 'index-4',
        component: IndexFour
    },
    {
        path: '/index-5',
        name: 'index-5',
        component: IndexFive
    },
    {
        path: '/index-6',
        name: 'index-6',
        component: IndexSix
    },
    {
        path: '/index-7',
        name: 'index-7',
        component: IndexSeven
    },
    {
        path: '/index-8',
        name: 'index-8',
        component: IndexEight
    },
    {
        path: '/index-9',
        name: 'index-9',
        component: IndexNine
    },
    {
        path: '/verification',
        name: 'verification',
        component: Verification
    },
    {
        path: '/booking-details',
        name: 'booking-details',
        component: BookingDetails
    },
    {
        path: '/session-expired',
        name: 'session-expired',
        component: SessionExpired
    },
    {
        path: '/service-details2',
        name: 'service-details2',
        component: Servicedetails2
    },



/**************** Admin  *************/

{
    path: '/admin/abuse-reports',
    name: '/admin/abuse-reports',
    component: abusereports
},
{
    path: '/admin/account-settings',
    name: '/admin/account-settings',
    component: Accountsettings
},
{
    path: '/admin/add-announcement',
    name: '/admin/add-announcement',
    component: Addannouncement
},
{
    path: '/admin/add-blog-categories',
    name: '/admin/add-blog-categories',
    component: AddBlogcategories
},
{
    path: '/admin/add-blog',
    name: '/admin/add-blog',
    component: AddBlog
},
{
    path: '/admin/add-categories',
    name: '/admin/add-categories',
    component: AddCategories
},
{
    path: '/admin/add-city',
    name: '/admin/add-city',
    component: AddCity
},
{
    path: '/admin/add-countries',
    name: '/admin/add-countries',
    component: AddCountries
},
{
    path: '/admin/add-customer',
    name: '/admin/add-customer',
    component: AddCustomer
},
{
    path: '/admin/add-membership',
    name: '/admin/add-membership',
    component: AddMembership
},
{
    path: '/admin/add-page',
    name: '/admin/add-page',
    component: AddPage
},
{
    path: '/admin/add-payout',
    name: '/admin/add-payout',
    component: AddPayout
},
{
    path: '/admin/add-provider',
    name: '/admin/add-provider',
    component: AddProvider
},
{
    path: '/admin/add-reviewtype',
    name: '/admin/add-reviewtype',
    component: AddReviewtype
},
{
    path: '/admin/add-role',
    name: '/admin/add-role',
    component: AddRole
},
{
    path: '/admin/add-service',
    name: '/admin/add-service',
    component: AddService
},
{
    path: '/admin/add-state',
    name: '/admin/add-state',
    component: AddState
},
{
    path: '/admin/add-subcategories',
    name: '/admin/add-subcategories',
    component: AddSubCategories
},
{
    path: '/admin/add-testimonials',
    name: '/admin/add-testimonials',
    component: AddTestimonials
},
{
    path: '/admin/add-user',
    name: '/admin/add-user',
    component: AddUser
},
{
    path: '/admin/admin-earnings',
    name: '/admin/admin-earnings',
    component: AddEarnings
},
{
    path: '/admin/all-blog',
    name: '/admin/all-blog',
    component: AllBlog
},
{
    path: '/admin/announcements',
    name: '/admin/announcements',
    component: Announcements
},
{
    path: '/admin/approved-transferlist',
    name: '/admin/approved-transferlist',
    component: ApprovedTransferlist
},
{
    path: '/admin/appearance',
    name: '/admin/appearance',
    component: Appearance
},
{
    path: '/admin/authentication-settings',
    name: '/admin/authentication-settings',
    component: AuthenticationSettings
},
{
    path: '/admin/available-plugins',
    name: '/admin/available-plugins',
    component: AvailablePlugins
},
{
    path: '/admin/aws-storage',
    name: '/admin/aws-storage',
    component: AwsStorage
},
{
    path: '/admin/ban-ip-address',
    name: '/admin/ban-ip-address',
    component: BanIpAddress
},
{
    path: '/admin/banktransfer',
    name: '/admin/banktransfer',
    component: Banktransfer
},
{
    path: '/admin/banktransferlist',
    name: '/admin/banktransferlist',
    component: BanktransferList
},
{
    path: '/admin/blogs-categories',
    name: '/admin/blogs-categories',
    component: BlogsCategories
},
{
    path: '/admin/blogs-comments',
    name: '/admin/blogs-comments',
    component: BlogsComments
},
{
    path: '/admin/booking',
    name: '/admin/adminbooking',
    component: AdminBooking
},
{
    path: '/admin/cachesystem',
    name: '/admin/cachesystem',
    component: CacheSystem
},
{
    path: '/admin/cancelled-booking',
    name: '/admin/cancelled-booking',
    component: CancelleBooking
},
{
    path: '/admin/cash-on-delivery',
    name: '/admin/cash-on-delivery',
    component: CashOnDelivery
},
{
    path: '/admin/categories',
    name: '/admin/admincategories',
    component: AdminCategories
},
{
    path: '/admin/change-password',
    name: '/admin/adminchange-password',
    component: Changepassword
},
{
    path: '/admin/chat',
    name: '/admin/chat',
    component: Chat
},
{
    path: '/admin/cities',
    name: '/admin/cities',
    component: Cities
},
{
    path: '/admin/completed-booking',
    name: '/admin/completed-booking',
    component: CompletedBooking
},
{
    path: '/admin/connected-apps',
    name: '/admin/adminconnected-apps',
    component: AdminconnectedApps
},
{
    path: '/admin/contact-messages-view',
    name: '/admin/contact-messages-view',
    component: ContactMessagesView
},
{
    path: '/admin/connected-messages',
    name: '/admin/connected-messages',
    component: ConnectedMessages
},
{
    path: '/admin/countries',
    name: '/admin/countries',
    component: Countries
},
{
    path: '/admin/create-menu',
    name: '/admin/admincreate-menu',
    component: CreateMenu
},
{
    path: '/admin/cronjob',
    name: '/admin/cronjob',
    component: Cronjob
},
{
    path: '/admin/currencies',
    name: '/admin/currencies',
    component: Currencies
},
{
    path: '/admin/currency-settings',
    name: '/admin/currency-settings',
    component: CurrencySettings
},
{
    path: '/admin/database-backup',
    name: '/admin/database-backup',
    component: DatabaseBackup
},
{
    path: '/admin/delete-account-requests',
    name: '/admin/delete-account-requests',
    component: DeleteAccountRequest
},
{
    path: '/admin/deleted-services',
    name: '/admin/deleted-services',
    component: DeleteServices
},
{
    path: '/admin/device-management',
    name: '/admin/admindevice-management',
    component: AdminDeviceManagement
},
{
    path: '/admin/edit-announcement',
    name: '/admin/edit-announcement',
    component: EditAnnouncement
},
{
    path: '/admin/edit-blog-categories',
    name: '/admin/edit-blog-categories',
    component: EditBlogCategories
},
{
    path: '/admin/edit-blog',
    name: '/admin/edit-blog',
    component: EditBlog
},
{
    path: '/admin/edit-categories',
    name: '/admin/edit-categories',
    component: EditCategories
},
{
    path: '/admin/edit-city',
    name: '/admin/edit-city',
    component: EditCity
},
{
    path: '/admin/edit-countries',
    name: '/admin/edit-countries',
    component: EditCountries
},
{
    path: '/admin/edit-coupon',
    name: '/admin/edit-coupon',
    component: EditCoupon
},
{
    path: '/admin/edit-customer',
    name: '/admin/edit-customer',
    component: EditCustomer
},
{
    path: '/admin/edit-email-template',
    name: '/admin/edit-email-template',
    component: EditEmailTemplate
},
{
    path: '/admin/edit-management',
    name: '/admin/edit-management',
    component: EditManagement
},
{
    path: '/admin/edit-offer',
    name: '/admin/edit-offer',
    component: EditOffer
},
{
    path: '/admin/edit-page',
    name: '/admin/edit-page',
    component: EditPage
},
{
    path: '/admin/edit-provider',
    name: '/admin/edit-provider',
    component: EditProvider
},
{
    path: '/admin/edit-reviewtype',
    name: '/admin/edit-reviewtype',
    component: EditReviewtype
},
{
    path: '/admin/edit-role',
    name: '/admin/edit-role',
    component: EditRole
},
{
    path: '/admin/edit-service',
    name: '/admin/edit-service',
    component: EditService
},
{
    path: '/admin/edit-sms-template',
    name: '/admin/edit-sms-template',
    component: EditSmsTemplate
},
{
    path: '/admin/edit-state',
    name: '/admin/edit-state',
    component: EditState
},
{
    path: '/admin/edit-subcategories',
    name: 'edit-subcategories',
    component: EditSubcategories
},
{
    path: '/admin/edit-testimonials',
    name: '/admin/edit-testimonials',
    component: EditTestimonials
},
{
    path: '/admin/edit-user',
    name: '/admin/edit-user',
    component: EditUser
},
{
    path: '/admin/email-settings',
    name: '/admin/email-settings',
    component: EmailSettings
},
{
    path: '/admin/email-storage-settings',
    name: '/admin/email-storage-settings',
    component: EmailStorageSettings
},
{
    path: '/admin/email-templates',
    name: '/admin/email-templates',
    component: EmailTemplates
},
{
    path: '/admin/facebook-api',
    name: '/admin/facebook-api',
    component: FacebookApi
},
{
    path: '/admin/faq',
    name: '/admin/adminfaq',
    component: AdminFaq
},
{
    path: '/admin/footer-settings',
    name: '/admin/footer-settings',
    component: FooterSettings
},
{
    path: '/admin/gdpr-settings',
    name: '/admin/gdpr-settings',
    component: GdprSettings
},
{
    path: '/admin/google-api',
    name: '/admin/google-api',
    component: GoogleApi
},
{
    path: '/admin/header-settings',
    name: '/admin/header-settings',
    component: HeaderSettings
},
{
    path: '/admin/inactive-blog',
    name: '/admin/inactive-blog',
    component: InactiveBlog
},
{
    path: '/admin/inactive-services',
    name: '/admin/inactive-services',
    component: InactiveServices
},
{
    path: '/admin/dashboard',
    name: '/admin/dashboard',
    component: AdminIndex
},
{
    path: '/admin/inprogress-booking',
    name: '/admin/inprogress-booking',
    component: InprogressBooking
},
{
    path: '/admin/language',
    name: '/admin/language',
    component: Language
},
{
    path: '/admin/localization',
    name: '/admin/localization',
    component: Localization
},
{
    path: '/admin/login-activity',
    name: '/admin/adminlogin-activity',
    component: Loginactivity
},
{
    path: '/admin/coupons',
    name: '/admin/coupons',
    component: MarketingCoupons
},
{
    path: '/admin/featured-services',
    name: '/admin/featured-services',
    component: MarketingFeatured
},
{
    path: '/admin/email-newsletter',
    name: '/admin/email-newsletter',
    component: MarketingNewsLetter
},
{
    path: '/admin/offers',
    name: '/admin/offers',
    component: MarketingService
},
{
    path: '/admin/membership-addons',
    name: '/admin/membership-addons',
    component: MembershipAddons
},
{
    path: '/admin/membership-transaction',
    name: '/admin/membership-transaction',
    component: MembershipTransaction
},
{
    path: '/admin/membership',
    name: '/admin/membership',
    component: Membership
},
{
    path: '/admin/menu-management',
    name: '/admin/menu-management',
    component: MenuManagement
},
{
    path: '/admin/nexmo',
    name: '/admin/nexmo',
    component: Nexmo
},
{
    path: '/admin/notifications',
    name: '/admin/adminnotifications',
    component: AdminNotifications
},
{
    path: '/admin/page-list',
    name: '/admin/page-list',
    component: PageList
},
{
    path: '/admin/pages-list',
    name: '/admin/pages-list',
    component: PagesList
},
{
    path: '/admin/payment-gateways',
    name: '/admin/payment-gateways',
    component: PaymentGateways
},
{
    path: '/admin/payment-settings',
    name: '/admin/adminpayment-settings',
    component: AdminPaymentSettings
},
{
    path: '/admin/payout-request',
    name: '/admin/payout-request',
    component: PayoutRequest
},
{
    path: '/admin/payout-settings',
    name: '/admin/payout-settings',
    component: PayoutSettings
},
{
    path: '/admin/paypal',
    name: '/admin/paypal',
    component: Paypal
},
{
    path: '/admin/pending-blog',
    name: '/admin/pending-blog',
    component: pendingBlog
},
{
    path: '/admin/pending-booking',
    name: '/admin/pending-booking',
    component: PendingBooking
},
{
    path: '/admin/pending-services',
    name: '/admin/pending-services',
    component: PendingServices
},
{
    path: '/admin/pending-transferlist',
    name: '/admin/pending-transferlist',
    component: PendingTransferlist
},
{
    path: '/admin/php-mail',
    name: '/admin/php-mail',
    component: PhpMail
},
{
    path: '/admin/plugins-manager',
    name: '/admin/plugins-manager',
    component: PluginsManager
},
{
    path: '/admin/preference-settings',
    name: '/admin/preference-settings',
    component: PreferenceSettings
},
{
    path: '/admin/provider-earnings',
    name: '/admin/adminprovider-earnings',
    component: providerEarnings
},
{
    path: '/admin/provider-settings',
    name: '/admin/adminprovider-settings',
    component: providerSettings
},
{
    path: '/admin/refund-request',
    name: '/admin/refund-request',
    component: RefundRequest
},
{
    path: '/admin/rejected-transferlist',
    name: '/admin/rejected-transferlist',
    component: RejectedTransferlist
},
{
    path: '/admin/review-type',
    name: '/admin/review-type',
    component: ReviewType
},
{
    path: '/admin/review',
    name: '/admin/review',
    component: Review
},
{
    path: '/admin/roles',
    name: '/admin/roles',
    component: RolePermission
},
{
    path: '/admin/permissions',
    name: '/admin/permissions',
    component: RolesPermissions
},
{
    path: '/admin/sales-transactions',
    name: '/admin/sales-transactions',
    component: SalesTransactions
},
{
    path: '/admin/security',
    name: '/admin/security',
    component: Security
},
{
    path: '/admin/seo-settings',
    name: '/admin/seo-settings',
    component: SeoSettings
},
{
    path: '/admin/service-settings',
    name: '/admin/service-settings',
    component: ServiceSettings
},
{
    path: '/admin/services',
    name: '/admin/services',
    component: Services
},
{
    path: '/admin/signin',
    name: '/admin/signin',
    component: Signin
},
{
    path: '/admin/site-information',
    name: '/admin/site-information',
    component: SiteInformation
},
{
    path: '/admin/sms-settings',
    name: '/admin/sms-settings',
    component: SmsSettings
},
{
    path: '/admin/sms-templates',
    name: '/admin/sms-templates',
    component: SmsTemplates
},
{
    path: '/admin/smtp',
    name: '/admin/smtp',
    component: Smtp
},
{
    path: '/admin/social-authentication',
    name: '/admin/social-authentication',
    component: SocialAuthentication
},
{
    path: '/admin/social-profiles',
    name: '/admin/social-profiles',
    component: SocialProfiles
},
{
    path: '/admin/states',
    name: '/admin/states',
    component: States
},
{
    path: '/admin/storage-settings',
    name: '/admin/storage-settings',
    component: StorageSettings
},
{
    path: '/admin/sub-categories',
    name: '/admin/sub-categories',
    component: SubCategories
},
{
    path: '/admin/successful-transferlist',
    name: '/admin/successful-transferlist',
    component: SuccessfulTransferlist
},
{
    path: '/admin/system-backup',
    name: '/admin/system-backup',
    component: SystemBackup
},
{
    path: '/admin/system-information',
    name: '/admin/system-information',
    component: SystemInformation
},
{
    path: '/admin/tax-rates',
    name: '/admin/tax-rates',
    component: TaxRates
},
{
    path: '/admin/testimonials',
    name: '/admin/testimonials',
    component: Testimonials
},
{
    path: '/admin/customers',
    name: '/admin/customers',
    component: UserCustomers
},
{
    path: '/admin/providers',
    name: '/admin/providers',
    component: UserProviders
},
{
    path: '/admin/users',
    name: '/admin/users',
    component: UserList
},
{
    path: '/admin/view-service',
    name: '/admin/view-service',
    component: ViewService
},
{
    path: '/admin/wallet-history',
    name: '/admin/wallet-history',
    component: WalletHistory
},
{
    path: '/admin/wallet',
    name: '/admin/wallet',
    component: Wallet
},
{
    path: '/admin/website-settings',
    name: '/admin/website-settings',
    component: WebsiteSettings
},
{
    path: '/admin/active-services',
    name: '/admin/active-services',
    component: Active_Services
},

{
    path: '/admin/register-report',
    name: '/admin/register-report',
    component: Resgister_Report
},
{
    path: '/admin/provider-sales',
    name: '/admin/provider-sales',
    component: Provider_Sales
},
{
    path: '/admin/provider-wallet',
    name: '/admin/provider-wallet',
    component: Provider_Wallet
},
{
    path: '/admin/customer-wallet',
    name: '/admin/customer-wallet',
    component: Customer_Wallet
},
{
    path: '/admin/refund-report',
    name: '/admin/refund-report',
    component: Refund_Report
},
{
    path: '/admin/service-sales',
    name: '/admin/service-sales',
    component: Service_Sales
},
{
    path: '/admin/contact-messages',
    name: '/admin/contact-messages',
    component: ContactMessages
},
{
    path: '/admin/appointment-settings',
    name: '/admin/appointment-settings',
    component: Appointment_Settings
},
{
    path: '/admin/typography-settings',
    name: '/admin/typography-settings',
    component: Typography_Settings
},
{
    path: '/admin/calendar-settings',
    name: '/admin/calendar-settings',
    component: Calendar_Settings
},
{
    path: '/admin/edit-management',
    name: '/admin/edit-management',
    component: Edit_Management
},
{
    path: '/admin/verification-request',
    name: '/admin/verification-request',
    component: Verification_Request
},
{
    path: '/admin/forgot-password',
    name: '/admin/forgot-password',
    component: ForgetPassword
},
{
    path: '/admin/signup',
    name: '/admin/signup',
    component: Signup
},
{
    path: '/admin/',
    name: '/admin/adminindex',
    component: AdminIndex
},
// Start Rental
{
    path: '/admin-setting/role',
    name: '/admin-setting/role',
    component: Role
},
{
    path: '/admin-setting/menu',
    name: '/admin-setting/menu',
    component: Menu
},
{
    path: '/admin-setting/acces',
    name: '/admin-setting/acces',
    component: Acces
},
{
    path: '/admin-setting/user',
    name: '/admin-setting/user',
    component: User
},
{
    path: '/admin-setting/company',
    name: '/admin-setting/company',
    component: Company
},
{
    path: '/admin-setting/branch',
    name: '/admin-setting/branch',
    component: Branch
},
{
    path: '/admin-setting/papper',
    name: '/admin-setting/papper',
    component: Papper
},
{
    path: '/admin-setting/fines',
    name: '/admin-setting/fines',
    component: Fines
},
{
    path: '/admin-setting/expenditure',
    name: '/admin-setting/expenditure',
    component: Expenditure
},
{
    path: '/admin-setting/maintenance',
    name: '/admin-setting/maintenance',
    component: Maintenance
},
{
    path: '/admin-setting/customer',
    name: '/admin-setting/customer',
    component: Customer
},
{
    path: '/admin-setting/product',
    name: '/admin-setting/product',
    component: Product
},
{
    path: '/admin-setting/vehicle',
    name: '/admin-setting/vehicle',
    component: Vehicle
},
{
    path: '/admin-setting/vehicle-detail/:id',
    name: '/admin-setting/vehicle-detail',
    component: VehicleDetail
},
{
    path: '/admin-setting/order',
    name: '/admin-setting/order',
    component: Order
},
{
    path: '/admin-setting/check-vehicle',
    name: '/admin-setting/check-vehicle',
    component: CheckVehicle
},
{
    path: '/admin-setting/return-list',
    name: '/admin-setting/return-list',
    component: ReturnList
},
{
    path: '/admin-setting/jadwal-order',
    name: '/admin-setting/jadwal-order',
    component: JadwalOrder
},
{
    path: '/admin-setting/jadwal-maintenance',
    name: '/admin-setting/jadwal-maintenance',
    component: JadwalMaintenance
},
{
    path: '/admin-setting/keuangan',
    name: '/admin-setting/keuangan',
    component: Finance
},
{
    path: '/admin-setting/berlangganan',
    name: '/admin-setting/berlangganan',
    component: Subscribe
},
{
    path: '/admin-setting/bantuan',
    name: '/admin-setting/bantuan',
    component: Help
},
{
    path: '/admin-setting/monitoring',
    name: '/admin-setting/monitoring',
    component: Monitoring3
},
{
    path: '/admin-setting/pengaturan',
    name: '/admin-setting/pengaturan',
    component: Pengaturan
},
{
    path: '/admin-setting/finance-menu',
    name: '/admin-setting/finance-menu',
    component: FinanceMenu
},
{
    path: '/admin-setting/brand',
    name: '/admin-setting/brand',
    component: Brand
},
{
    path: '/admin-setting/vendor',
    name: '/admin-setting/vendor',
    component: Vendor
},
{
    path: '/admin-setting/create-vehicle',
    name: '/admin-setting/create-vehicle',
    component: CreateVehicle
},
// End Rental

]


export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes,
}); 

router.beforeEach((to, from, next) => {
// Scroll to the top of the page
window.scrollTo({ top: 0, behavior: 'smooth' });
// Continue with the navigation
next();
});
    
