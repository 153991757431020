<template>
  <div class="tab-pane active" id="active-service">
    <div class="row">
      <!-- Favorite Item -->
      <div class="col-xl-4 col-md-6" v-for="record in Myserviceactive" :key="record.id">
        <div class="service-widget pro-service">
          <div class="service-img">
            <router-link to="/service-details">
              <img
                class="img-fluid serv-img"
                alt="Service Image"
                :src="require(`@/assets/img/services/${record.serviceImage}`)"
              />
            </router-link>
            <div class="fav-item">
              <div class="item-info">
                <router-link to="/categories"
                  ><span class="item-cat">{{ record.category }}</span></router-link
                >
              </div>
              <span class="serv-rating"
                ><i class="fa-solid fa-star"></i>{{ record.rating }}</span
              >
            </div>
          </div>
          <div class="service-content">
            <h3 class="title">
              <router-link to="/service-details">{{ record.serviceName }}</router-link>
            </h3>
            <div class="addrs-item">
              <p><i class="feather-map-pin"></i>{{ record.location }}</p>
              <h6 class="price">
                {{ record.price }}<span class="old-price">{{ record.oldPrice }}</span
                ><span class="price-hr">/hr</span>
              </h6>
            </div>
            <div class="serv-info">
              <div>
                <router-link to="/provider-edit-service" class="serv-edit"
                  ><i class="feather-edit"></i> Edit</router-link
                >
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#in-active"
                  ><span><i class="feather-alert-circle"></i> Active</span></a
                >
              </div>
              <router-link to="/provider-offers" class="btn btn-book"
                >Apply Offer</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <!-- /Favorite Item -->
    </div>
  </div>
</template>
<script>
import Myserviceactive from "@/assets/json/myserviceactive.json";
export default {
  data() {
    return {
      Myserviceactive: Myserviceactive,
    };
  },
};
</script>
