<template>
  <div class="content-page-header content-page-headersplit">
    <h5>{{ title }}</h5>
    <div class="list-btn">
      <ul>
        <li>
          <div class="filter-sorting">
            <ul>
              <li>
                <a href="javascript:void(0);" class="filter-sets"
                  ><img
                    src="@/assets/admin_img/icons/filter1.svg"
                    class="me-2"
                    alt="img"
                  />Filter</a
                >
              </li>
              <li>
                <span
                  ><img src="@/assets/admin_img/icons/sort.svg" class="me-2" alt="img"
                /></span>
                <div class="review-sort">
                  <vue-select :options="AZ" id="abce" placeholder="A - Z" />
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    name: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      AZ: ["A - Z", "Z - A"],
    };
  },
};
</script>
