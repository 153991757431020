import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getPapper(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/papper', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addPapper(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/papper/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updatePapper(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('papper/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deletePapper(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('papper/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }



    },
    getters: {

    }

}