<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Add Payout</h5>
              </div>
              <div class="col-lg-12">
                <div class="form-grouphead">
                  <h2>Payout Details</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Provider Name</label>
                    <vue-select :options="Provider" placeholder="Select any Provider" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Withdrawal Method</label>
                    <vue-select :options="Payment" placeholder="Select any Payment" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Withdrawal Amount</label>
                    <b-form-input type="text" placeholder="0.00" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Status</label>
                    <vue-select :options="Status" placeholder="Select Status" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" variant="primary" to="/admin/payout-request"
                      >Save Changes</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Provider: ["Select any Provider", "Provider 1", "Provider 2", "Provider 3"],
      Payment: ["Select any Payment", "Payment 1", "Payment 2", "Payment 3"],
      Status: ["Select Status", "Completed", "Inprogress"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/payout-request");
    },
  },
};
</script>
