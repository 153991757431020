<template>
    <div v-if="isOwner == false" class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="content-page-header content-page-headersplit mb-0">
            <h5>Perusahaan</h5>
            <div class="list-btn">
              <ul>
                <li>
                  <div class="input-group">
                    <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="height: 43px !important;">
                    <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                  </div>
                </li>
                <li>
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#add-company"
                  >
                    <i class="fa fa-plus me-2"></i>Tambah Perusahaan
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-resposnive table-div">
                <div class="table datatable">
                  <a-table :columns="columns" :data-source="data" :pagination="pagination">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'id'">
                        <div class="table-actions d-flex">
                            <button
                            class="btn delete-table me-2"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#show-company"
                            @click="openEditModal(record)"
                          >
                            <i class="feather feather-eye"></i>
                          </button>
                          <button
                            class="btn delete-table me-2"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-company"
                            @click="openEditModal(record)"
                          >
                            <i class="feather feather-edit"></i>
                          </button>
                          <button
                            class="btn delete-table"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                            @click="openDeleteModal(record)"
                          >
                            <i class="feather feather-trash-2"></i>
                          </button>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="isOwner == true" class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="content-page-header content-page-headersplit mb-0">
            <h5>Perusahaan</h5>
            <div class="list-btn">
              <ul>
                <li>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="editCurrentSubmit"
                  >
                    <i class="fa fa-plus me-2"></i>Edit Perusahaan
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="mb-3">
                  <div class="profile-upload-content">

                      <!-- company logo here -->
                      <img v-if="(data !== undefined) && (data.logo !== '')" :src="data.logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                      <!-- company logo here -->

                      <!-- upload file form logo here -->
                      <div class="profile-upload-btn">
                          <div class="profile-upload-file">
                            <input
                                type="file"
                                class="form-control"
                                ref="uploadFieldAttachment"
                                id="imgInp"
                                @change="selectFileAttachment"
                            />
                            <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                          </div>
                      </div>
                        <!-- upload file form logo here -->
                      <div class="profile-upload-para">
                          <p>
                          *image size should be at least 320px big,and less then 500kb.
                          Allowed files .png and .jpg.
                          </p>
                      </div>
                  </div>
                </div>
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div style="margin-right: 10px;">
                        <label class="form-label">Company Name</label>
                        <input type="text" class="form-control" v-model="data.name"/>
                    </div>
                    <div>
                        <label class="form-label">Address</label>
                        <input type="text" class="form-control" v-model="data.address"/>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div style="margin-right: 10px;">
                        <label class="form-label">Phone Number</label>
                        <input type="text" class="form-control" v-model="data.phone_number"/>
                    </div>
                    <div>
                        <label class="form-label">Owner Name</label>
                        <input type="text" class="form-control" v-model="data.owner_name"/>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div style="margin-right: 10px;">
                        <label class="form-label">Invite Code</label>
                        <input type="text" class="form-control" v-model="data.invite_code"/>
                    </div>
                    <div>
                        <label class="form-label">Reference Code</label>
                        <input type="text" class="form-control" v-model="data.reference_code"/>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div style="margin-right: 10px;">
                        <label class="form-label">Join Date</label>
                        <input type="datetime-local" class="form-control" v-model="data.join_date"/>
                    </div>
                    <div>
                        <label class="form-label">Payment Date</label>
                        <input type="datetime-local" class="form-control" v-model="data.payment_date"/>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="split-check-title">
                                    <h6>Payment Type</h6>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="split-check-box">
                                    <ul class="custom-radiosbtn">
                                        <li>
                                            <label class="radiossets">Chass
                                                <input type="radio" :checked="data.payment_type === 1" name="payment_type" value=1 v-model="data.payment_type">
                                                <span class="checkmark-radio"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label class="radiossets">Termine
                                                <input type="radio" :checked="data.payment_type === 2" name="payment_type" value=2 v-model="data.payment_type">
                                                <span class="checkmark-radio"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                  <div class="d-flex">
                  <div style="margin-right: 10px;">
                      <label class="form-label">Payment Value</label>
                      <input type="number" class="form-control" v-model="data.payment_value"/>
                  </div>
                  <div>
                      <label class="form-label">About</label>
                      <input type="text" class="form-control" v-model="data.about"/>
                  </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <company-model :editData="editData" :deleteItemId="itemIdToDelete" @dataUpdated="getData"></company-model>
  </template>
  
  <script>
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: {
        compare: (a, b) => {
          a = a.address.toLowerCase();
          b = b.address.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      sorter: {
        compare: (a, b) => {
          a = a.phone_number.toLowerCase();
          b = b.phone_number.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Owner",
      dataIndex: "owner_name",
      key: "owner_name",
      sorter: {
        compare: (a, b) => {
          a = a.owner_name.toLowerCase();
          b = b.owner_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataRole: [],
        isOwner: false,
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        logo: ''
      };
    },
    name: "companysetting",
    computed: {

    },
    methods: {
        search() {
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            const userData = localStorage.getItem('user');
            const user = JSON.parse(userData);
            var loading = this.$loading.show();
            if (user.company_id === 0) {
                this.$store
                    .dispatch("company/getCompany", this.params)
                    .then((resp) => {
                        this.data = resp.data.data.map((item, index) => {
                            return {
                                ...item,
                                index: index + 1
                            };
                        });
                        this.pagination.current = resp.data.current_page;
                        this.pagination.total = resp.data.total;
                        this.pagination.pageSize = this.params.row;
                        loading.hide();
                    })
                    .catch((e) => {
                        this.$toast.error(e);
                        loading.hide();
                    });
            } else {
                this.$store
                    .dispatch("company/showCompany", {id : user.company_id})
                    .then((resp) => {
                        this.data = resp.data.data;
                        this.isOwner = true;
                        loading.hide();
                    })
                    .catch((e) => {
                        this.$toast.error(e);
                        loading.hide();
                    });
            }
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
        editCurrentSubmit() {
        this.data.logo = this.data.logo !== '' ? this.data.logo : this.logo;
        const id = this.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("company/updateCompany", { id: id, data: this.data })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.data = {};
              this.logo = '';
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
    },
    mounted() {
        this.getData();
    },
  };
  </script>
      <style>
      .ant-select-selection-item {
        height: 30px;
        width: 90px;
        margin-top: -6px;
        font-size: 15px;
      }
      </style>