<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Email Newsletter</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  class="btn btn-primaryline"
                  data-bs-toggle="modal"
                  data-bs-target="#send-email"
                  ><i class="fa fa-envelope me-2" aria-hidden="true"></i>Send Email</a
                >
              </li>
              <li>
                <a
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-subscriber"
                  ><i class="fa fa-plus me-2"></i>Add Subscriber</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <div class="table-namesplit">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="require(`@/assets/admin_img/customer/${record.Image}`)"
                            class="me-2"
                            alt="img"
                          />
                        </a>
                        <a href="javascript:void(0);" class="table-name">
                          <span>{{ record.Name }}</span>
                          <p>{{ record.Email }}</p>
                        </a>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <a
                          class="delete-table me-2"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#send-email"
                        >
                          <img src="@/assets/admin_img/icons/sms.svg" alt="svg" />
                        </a>
                        <a
                          class="delete-table"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <img src="@/assets/admin_img/icons/delete.svg" alt="svg" />
                        </a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <email-newsletter-model></email-newsletter-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Subcribed Date",
    dataIndex: "Subcribed_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Subcribed_Date.toLowerCase();
        b = b.Subcribed_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Last Activity",
    dataIndex: "Last_Activity",
    sorter: {
      compare: (a, b) => {
        a = a.Last_Activity.toLowerCase();
        b = b.Last_Activity.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Image: "user-01.jpg",
    Name: "John Smith",
    Subcribed_Date: "03 Sep 2023",
    Last_Activity: "07 Nov 2023",
    Action: "",
  },
  {
    id: "2",
    Image: "user-04.jpg",
    Name: "Johnny",
    Subcribed_Date: "03 Sep 2023",
    Last_Activity: "07 Nov 2023",
    Action: "",
  },
  {
    id: "3",
    Image: "user-06.jpg",
    Name: "Robert",
    Subcribed_Date: "03 Sep 2023",
    Last_Activity: "07 Nov 2023",
    Action: "",
  },
  {
    id: "4",
    Image: "user-09.jpg",
    Name: "Sharonda",
    Subcribed_Date: "15 Sep 2023",
    Last_Activity: "17 Nov 2023",
    Action: "",
  },
  {
    id: "5",
    Image: "user-01.jpg",
    Name: "John Smith",
    Subcribed_Date: "23 Sep 2023",
    Last_Activity: "27 Nov 2023",
    Action: "",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/email-newsletter");
    },
  },
};
</script>
