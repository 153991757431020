<template>
    <div class="main-wrapper">
        <layout></layout>
        <adminsidebar></adminsidebar>
        <div class="page-wrapper page-settings">

            <sidelinkheading />

            <div class="content w-100">
                <div class="content-page-header">
                    <h5>Footer Setting</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-groupheads d-flex justify-content-between">
                                            <h2>Categories Widget</h2>
                                            <div class="active-switch">
                                                <label class="switch">
                                                    <input type="checkbox" checked="">
                                                    <span class="sliders round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Title<span class="text-label"> (English)</span></label>
                                            <input type="text" class="form-control" placeholder="Categories">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Category-view</label>
                                            <vue-select :options="Category_view" placeholder="Orderby(ASC)" />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-0">
                                            <label>Category Count</label>
                                            <input type="text" class="form-control" placeholder="4">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-groupheads d-flex d-flex justify-content-between">
                                            <h2>Links Widget</h2>
                                            <div class="active-switch">
                                                <label class="switch">
                                                    <input type="checkbox" checked="">
                                                    <span class="sliders round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Title</label>
                                            <input type="text" class="form-control" placeholder="Quick Links">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group-set">
                                            <div class="row align-items-center">
                                                <div class="col-lg-12">
                                                    <h4 class="mb-0">About<span>(Max 6 only)</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12"  v-for="item in event" :key="item.id">
                                        <div class="form-group-set">
                                            <div class="row align-items-center">
                                                <div class="col-lg-3 col-sm-6">
                                                    <h4 class="mb-0">{{item.Name}}</h4>
                                                </div>
                                                <div class="col-lg-8 col-sm-12">
                                                    <input type="text" class="form-control"
                                                        :value="item.placeholder">
                                                </div>
                                                <div class="col-lg-1 col-sm-12">
                                                    <a href="javascript:void(0);" 
                                                        @click="deleteEvent(event)"
                                                        class="delete-links">
                                                        <i class="far fa-trash-alt "></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-groupheads d-flex d-flex justify-content-between">
                                            <h2>Contact Widget</h2>
                                            <div class="active-switch">
                                                <label class="switch">
                                                    <input type="checkbox" checked="">
                                                    <span class="sliders round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Title<span class="text-label"> (English)</span></label>
                                            <input type="text" class="form-control" placeholder="Contact Us">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Address</label>
                                            <input type="text" class="form-control"
                                                placeholder="367 Hillcrest Lane, Irvine, California, United States">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Phone</label>
                                            <input type="text" class="form-control" placeholder="321 546 8764">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mb-0">
                                            <label>Email</label>
                                            <input type="text" class="form-control" placeholder="truelysell@example.com">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-groupheads d-flex d-flex justify-content-between">
                                            <h2>Social Widget</h2>
                                            <div class="active-switch">
                                                <label class="switch">
                                                    <input type="checkbox" checked="">
                                                    <span class="sliders round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Title <span>(English)</span></label>
                                            <input type="text" class="form-control" placeholder="Quick Links">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group-set">
                                            <div class="row align-items-center">
                                                <div class="col-lg-12">
                                                    <h4 class="mb-0">Links <span>(Max 6 only)</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" v-for="item in Media" :key="item.id">
                                        <div class="form-group-set">
                                            <div class="row align-items-center">
                                                <div class="col-lg-3 col-sm-6">
                                                    <h4 class="mb-0">{{item.Name}}</h4>
                                                </div>
                                                <div class="col-lg-8 col-sm-12">
                                                    <input type="text" class="form-control" :value="item.value">
                                                </div>
                                                <div class="col-lg-1 col-sm-12">
                                                    <a href="javascript:void(0);"
                                                        @click="deleteEvent2(Media)"
                                                        class="delete-links">
                                                        <i class="far fa-trash-alt "></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="row add-headers">
                                    <div class="col-12">
                                        <div class="form-groupheads d-flex d-flex justify-content-between">
                                            <h2>Copyright</h2>
                                            <div class="active-switch">
                                                <label class="switch">
                                                    <input type="checkbox" checked="">
                                                    <span class="sliders round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Page Content <span>(English)</span></label>
                                            <input type="text" class="form-control" placeholder="@ 2023 Truelysell	">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group-set">
                                            <div class="row align-items-center">
                                                <div class="col-lg-12">
                                                    <h4 class="mb-0">Footer bottom links <span>(Max 3 only)</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" v-for="item in link" :key="item.id">
                                        <div class="form-group-set">
                                            <div class="row align-items-center">
                                                <div class="col-lg-3 col-sm-6">
                                                    <div >
                                                        <input type="text" class="form-control" :value="item.Name">
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-sm-12">
                                                    <input type="text" class="form-control"
                                                        :value="item.Value">
                                                </div>
                                                <div class="col-lg-1 col-sm-12">
                                                    <a href="javascript:void(0);" 
                                                        @click="deleteEvent1(link)"
                                                        class="delete-links">
                                                        <i class="far fa-trash-alt "></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <a href="javascript:void(0);" @click="add()" class="addnewheader link-sets"><i
                                                class="fa fa-plus-circle me-2" aria-hidden="true"></i>Add New</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="btn-path">
                                <a href="javascript:void(0);" class="btn btn-cancel me-3">Reset</a>
                                <a href="javascript:void(0);" class="btn btn-primary">Save Changes</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const event =[
    {
        id: "1",
        Name: "About",
        placeholder: "https://truelysell-testing.dreamguystech.com/about"
    },
    {
        id: "2",
        Name: "Contact Us",
        placeholder: "https://truelysell-testing.dreamguystech.com/contactus"
    },
    {
        id: "3",
        Name: "FAQ",
        placeholder: "https://truelysell-testing.dreamguystech.com/faq"
    }
]

const Media = [
    {
        id: "1",
        Name: "Facebook",
        value: "https://facebook.com"
    },
    {
        id: "2",
        Name: "Twitter",
        value: "https://twitter.com"
    },
    {
        id: "3",
        Name: "Youtube",
        value: "https://youtube.com"
    },
    {
        id: "4",
        Name: "Instagram",
        value: "https://instagram.com"
    },
    {
        id: "5",
        Name: "Google Plus",
        value: "https://googleplus.com"
    },
]

const link =[
    {
        id: "1",
        Name: "Privacy",
        Value: "https://truelysell.com/privacy"
    },
    {
        id: "2",
        Name: "Terms & Conditions",
        Value: "https://truelysell.com/terms&conditions"
    }
]
export default {
    data() {
        return {
            Category_view: ["Orderby(ASC)", "ASC"],
            event,
            Media,
            link
        }
    },
    methods: {
        deleteEvent: function (event) {
        this.event.splice(this.event.indexOf(event), 1);
        },
        deleteEvent2: function (Media) {
        this.Media.splice(this.Media.indexOf(Media), 1);
        },
        deleteEvent1: function (link) {
        this.link.splice(this.link.indexOf(link), 1);
        },
        add() {
            this.link.push({
                id: "3",
                Name: '',
                placeholder: "",
            });
        },
    },
}
</script>