<template>
    <!-- Cursor -->
		<div class="mouse-cursor cursor-outer"></div>
		<div class="mouse-cursor cursor-inner"></div>
	<!-- /Cursor -->
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    mounted() {
        function mim_tm_cursor() {
        var myCursor = document.querySelectorAll('.mouse-cursor');
        
        if (myCursor.length) {
            if (document.querySelector('body')) {
            const e = document.querySelector('.cursor-inner');
            const t = document.querySelector('.cursor-outer');
            let n, i = 0;
            let o = false;
            
            window.onmousemove = function(s) {
                if (!o) {
                t.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
                }
                e.style.transform = `translate(${s.clientX}px, ${s.clientY}px)`;
                n = s.clientY;
                i = s.clientX;
            };
            
            document.querySelector('body').addEventListener('mouseenter', function() {
                e.classList.add('cursor-hover');
                t.classList.add('cursor-hover');
            });
            
            document.querySelector('body').addEventListener('mouseleave', function(event) {
                if (event.target.nodeName === 'A' || event.target.classList.contains('cursor-pointer')) {
                return;
                }
                e.classList.remove('cursor-hover');
                t.classList.remove('cursor-hover');
            });
            
            e.style.visibility = 'visible';
            t.style.visibility = 'visible';
            }
        }
        }

        mim_tm_cursor();

    },
}
</script>