<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>
    <div class="page-wrapper page-settings">
      <sidelinkheading />
      <div class="content w-100">
        <div class="content-page-header">
          <h6>Device Management</h6>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-bottom">
              <a-table :columns="columns" :data-source="data" >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Status'">
                    <h6 class="delete-labels">{{ record.Status }}</h6>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Device",
    dataIndex: "Device",
    sorter: {
      compare: (a, b) => {
        a = a.Device.toLowerCase();
        b = b.Device.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Location",
    dataIndex: "Location",
    sorter: {
      compare: (a, b) => {
        a = a.Location.toLowerCase();
        b = b.Location.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "IP Address",
    dataIndex: "IP_Address",
    sorter: {
      compare: (a, b) => {
        a = a.IP_Address.toLowerCase();
        b = b.IP_Address.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    Device: "Chrome - Windows",
    Date: "22 Sep 2023, 11:40:30",
    IP_Address: "Chennai / India",
    Location: "232.222.12.72",
    Status: "Delete",
  },
  {
    Device: "Safari Macos",
    Date: "21 Sep 2023, 13:10:50",
    IP_Address: "Newyork / USA",
    Location: "224.111.12.75",
    Status: "Delete",
  },
  {
    Device: "Firefox Windows",
    Date: "20 Sep 2023, 14:16:10",
    IP_Address: "Newyork / USA",
    Location: "232.222.12.72",
    Status: "Delete",
  },
  {
    Device: "Safari Macos",
    Date: "21 Sep 2023, 13:10:50",
    IP_Address: "Newyork / USA",
    Location: "224.111.12.75",
    Status: "Delete",
  },
];
export default {
  data() {
    return {
      Select: ["A -> Z", "Most helful"],
      columns,
      data,
    };
  },
  name: "admindevice-management",
};
</script>
