<template>
  <div class="login-body">
    <div class="content">
      <div class="container">
        <div class="row">
          <!-- Password Recovery -->
          <div class="col-md-6 col-lg-6 mx-auto">
            <div class="login-wrap">
              <div class="login-header">
                <h3>Password Recovery</h3>
                <p>
                  Enter your email and we will send you a link to reset your password.
                </p>
              </div>

              <form @submit.prevent="submitForm">
                <div class="log-form">
                  <div class="form-group">
                    <label class="col-form-label">E-mail</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="example@email.com"
                    />
                  </div>
                </div>
                <b-button
                  variant="primary w-100 login-btn"
                  to="/reset-password"
                  type="submit"
                  >Submit</b-button
                >
                <p class="no-acc mt-0">
                  Remember Password ? <router-link to="login">Login</router-link>
                </p>
              </form>
            </div>
          </div>
          <!-- /Password Recovery -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  submitForm() {
    this.$router.push("/reset-password");
  },
};
</script>
>
