<template>
  <!-- Partners Section -->
  <section class="blog-section pt-0">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center aos" data-aos="fade-up">
          <div class="section-heading sec-header">
            <h2>Our Partners</h2>
            <p>Lorem ipsum dolor sit amet, consectetur elit</p>
          </div>
          <div class="owl-carousel partners-slider">
            <Carousel
              :autoplay="3000"
              :wrap-around="true"
              :settings="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="item in indextwopartners" :key="item.id">
                <div class="partner-img">
                  <img :src="require(`@/assets/img/partner/${item.image}`)" alt="img" />
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Partners Section -->

  <!-- Free Trial -->
  <section class="section-offer">
    <div class="container">
      <div class="row aos" data-aos="fade-up">
        <div class="col-md-12">
          <div class="offer-paths offer-sec">
            <div class="offer-path-content">
              <div class="section-heading-two">
                <p>14 Days Free Trial</p>
                <h3 class="mb-0">We Are Offering 14 Days Free Trial</h3>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore minim veniam, quis nostrud
                exercitation ullamco magna aliqua.
              </p>
              <router-link to="/free-trial" class="btn btn-views"
                >Try 14 Days Free Trial<i class="feather-arrow-right-circle"></i
              ></router-link>
            </div>
            <div class="offer-pathimg">
              <img src="@/assets/img/offer-img.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Free Trial -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indextwopartners from "@/assets/json/indextwopartners.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indextwopartners: indextwopartners,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style>
.partner-img {
  margin-right: 24px;
}
</style>
