<template>
  <div class="col-md-6" v-for="record in Servicedetails2" :key="record.id">
    <div class="service-widget">
      <div class="service-img">
        <router-link to="service-details">
          <img
            class="img-fluid serv-img"
            alt="Service Image"
            :src="require(`@/assets/img/services/${record.serviceImgSrc}`)"
          />
        </router-link>
        <div class="item-info">
          <div class="service-user">
            <a href="javascript:;">
              <img
                :src="require(`@/assets/img/provider/${record.providerImgSrc}`)"
                alt="image"
              />
            </a>
            <span class="service-price">{{ record.servicePrice }}</span>
          </div>
          <div class="cate-list">
            <router-link class="bg-yellow" to="service-details">{{
              record.serviceCategory
            }}</router-link>
          </div>
        </div>
      </div>
      <div class="service-content">
        <h3 class="title">
          <router-link to="service-details">{{ record.serviceName }}</router-link>
        </h3>
        <div class="rating">
          <i class="fas fa-star filled me-1"></i>
          <i class="fas fa-star filled me-1"></i>
          <i class="fas fa-star filled me-1"></i>
          <i class="fas fa-star filled me-1"></i>
          <i class="fas fa-star me-1"></i>
          <span class="d-inline-block average-rating">{{ record.Rating }}</span>
        </div>
        <div class="user-info d-flex justify-content-between align-items-center ms-0">
          <h6 class="ser-contact">
            <i class="fas fa-phone me-2"></i> <span>{{ record.serviceContact }}</span>
          </h6>
          <h6 class="ser-location">
            <span>{{ record.serviceLocation }}</span>
            <i class="fas fa-map-marker-alt ms-1"></i>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Servicedetails2 from "@/assets/json/servicedetails2.json";
export default {
  data() {
    return {
      Servicedetails2: Servicedetails2,
    };
  },
};
</script>
