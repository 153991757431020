<template>
  <section class="journey-nine-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div
            class="section-heading section-heading-nine journey-heading-nine aos"
            data-aos="fade-up"
          >
            <p>Activity</p>
            <h2>It’s Our journey</h2>
          </div>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center">
          <div class="journey-client-all">
            <div class="journey-client-main">
              <h2>
                <vue3-autocounter
                  class="counter"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="644"
                  :duration="5"
                  separator=","
                  :autoinit="true"
                />
                <span>+</span>
              </h2>
              <h5>Active Clients</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center">
          <div class="journey-client-all">
            <div class="journey-client-main">
              <h2>
                <vue3-autocounter
                  class="counter"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="290"
                  :duration="5"
                  separator=","
                  :autoinit="true"
                />
                <span>+</span>
              </h2>
              <h5>Expert Mechanics</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center">
          <div class="journey-client-all">
            <div class="journey-client-main">
              <h2>
                <vue3-autocounter
                  class="counter"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="100"
                  :duration="5"
                  separator=","
                  :autoinit="true"
                />
                <span>+</span>
              </h2>
              <h5>Total Rewards</h5>
            </div>
          </div>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center">
          <div class="journey-client-all">
            <div class="journey-client-main">
              <h2>
                <vue3-autocounter
                  class="counter"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="25"
                  :duration="5"
                  separator=","
                  :autoinit="true"
                />
                <span>+</span>
              </h2>
              <h5>Years Reputation</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Why Choose Us -->
  <section class="choose-nine-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-nine aos" data-aos="fade-up">
            <p>TRUSTED & QUALITY SERVICE</p>
            <h2>Why Choose Us?</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="reasonable-all">
            <img src="@/assets/img/icons/choose-icon-4.svg" alt="" />
            <h5>Reasonable Price</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="reasonable-all expert-all">
            <img src="@/assets/img/icons/choose-icon-3.svg" alt="" />
            <h5>Expert Mechanics</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="reasonable-all delivery-all">
            <img src="@/assets/img/icons/choose-icon-2.svg" alt="" />
            <h5>fast feature</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12">
          <div class="reasonable-all workshop-all">
            <img src="@/assets/img/icons/choose-icon-1.svg" alt="" />
            <h5>Awarded Workshop</h5>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Why Choose Us? -->

  <!-- our Mechanics -->
  <section class="mechanics-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-nine aos" data-aos="fade-up">
            <p>OUR TEAM</p>
            <h2>Our Mechanics</h2>
          </div>
        </div>
      </div>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Car Mechanic
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Commercial Mechanic
          </button>
        </li>
      </ul>
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div class="row aos" data-aos="fade-up">
            <div class="col-md-12">
              <div class="owl-carousel provider-nine-slider">
                <Carousel
                  :wrap-around="true"
                  :settings="settings"
                  :breakpoints="breakpoints"
                >
                  <Slide v-for="item in indexninemechanics" :key="item.id">
                    <div class="providerset providerset-nine">
                      <div class="providerset-img">
                        <router-link to="/provider-details">
                          <img
                            :src="require(`@/assets/img/gallery/${item.image}`)"
                            alt="img"
                          />
                        </router-link>
                      </div>
                      <div class="providerset-content">
                        <h6>{{ item.title }}</h6>
                        <h4>
                          <router-link to="/provider-details">{{
                            item.name
                          }}</router-link>
                        </h4>
                        <div class="provider-rating provider-rating-nine">
                          <div class="rating">
                            <i class="fa-solid fa-star filled me-1"></i>
                            <i class="fa-solid fa-star filled me-1"></i>
                            <i class="fa-solid fa-star filled me-1"></i>
                            <i class="fa-solid fa-star filled me-1"></i>
                            <i class="fa-solid fa-star-half-stroke filled"></i
                            ><span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slide>
                  <template #addons>
                    <Pagination />
                  </template>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /our Mechanics -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexninemechanics from "@/assets/json/indexninemechanics.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexninemechanics: indexninemechanics,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style>
.providerset-nine {
  margin-right: 24px;
}
</style>
