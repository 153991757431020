<template>
  <div class="login-body">
    <div class="main-wrapper">
      <mari-header-login />

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-6 mx-auto">
                <div class="login-wrap">
                  <div class="login-header">
                    <h4>Selamat Datang Kembali</h4>
                    <p>Silahkan Login dengan akun Anda</p>
                  </div>

                  <!-- Login Form -->
                  <Form @submit="handleRegister" :validation-schema="schema">
                    <div class="log-form">
                      <div class="form-group">
                        <label class="col-form-label">E-mail</label>
                        <Field name="email" type="email" placeholder="example@email.com"  class="form-control" />
                        <ErrorMessage name="email" class="error-feedback" />
                      </div>
                      <div class="form-group">
                        <label class="col-form-label d-block">Password</label>
                        <div class="pass-group">

                          <Field v-if="showPassword" name="password" type="text" placeholder="**********" class="form-control pass-input" />
                          <Field v-else name="password" type="password" placeholder="**********" class="form-control pass-input" />
                          <ErrorMessage name="password" class="error-feedback" />
                          <span
                            class="toggle-password"
                            @click="toggleShow()"
                            :class="{
                              'fas fa-eye': showPassword,
                              'fas fa-eye-slash': !showPassword,
                            }"
                          ></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="char-length">
                            <p></p>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="text-md-end">
                            <router-link class="forgot-link" to="/mari/forgot-password"
                              >Lupa password?</router-link
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label class="custom_check">
                            <input type="checkbox" name="rememberme" class="rememberme" />
                            <span class="checkmark"></span>Remember Me
                          </label>
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-primary" style="width: 100%;" :disabled="loading"><span v-show="loading" class="spinner-border spinner-border-sm"></span>  Login</button>
                    <p class="no-acc">
                      Belum punya akun ?
                      <router-link to="/mari/register">Daftar sekarang</router-link>
                    </p>
                  </Form>
                  <!-- /Login Form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scope>
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from "vee-validate";

    export default {
        name: 'mari-login',
        components: {
          Form,
          Field,
          ErrorMessage,
      },
      data() {
        const schema = yup.object().shape({
          email: yup
            .string()
            .required("Email harus diisi!")
            .email("Harus berupa format email!")
            .max(50, "Tidak boleh lebih dari 50 karakter!"),
          password: yup
            .string()
            .required("Password harus diisi!")
            .min(6, "Harus lebih dari 6 karakter!"),
        });

        return {
          successful: false,
          loading: false,
          message: "",
          schema,
          showPassword: false,
          company_id: null,
        };
      },
      computed: {
        loggedIn() {
          return this.$store.state.auth.status.loggedIn;
        },
        buttonLabel() {
          return this.showPassword ? "Hide" : "Show";
        },
      },
      mounted() {
        // if (this.loggedIn) {
        //   this.$router.push("/profile");
        // }
      },
      methods: {
        async handleRegister(user) {
          let loading = this.$loading.show();
          try {
            const data = await this.$store.dispatch("auth/login", user);
            loading.hide();
            if (data.status == 200) {
              if(data.data.user.role.name == "Driver") {
                this.$store.commit('user/setDriver', true);
                this.$router.replace({ path: "/mari/driver/home" });
              } else if(data.data.user.role.name == "Staff Kendaraan") {
                this.$store.commit('user/setDriver', true);
                this.$router.replace({ path: "/mari/staff/home" });
              } else {
                this.$store.commit('user/setDriver', false);
                this.$router.replace({ path: "/mari" });
              }
            }
          } catch (error) {
            console.error(error);
            loading.hide();
          }
        },
        toggleShow() {
          this.showPassword = !this.showPassword;
        },
      },
      
    }
</script>
