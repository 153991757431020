<template>
  <div class="main-wrapper">
    <div class="change-password">
      <div class="change-passwordback">
        <router-link to="/admin/email-settings"
          ><i class="feather feather-arrow-left me-2"></i>Back
        </router-link>
      </div>
      <div class="change-passwordhead d-flex justify-content-between">
        <h3>SMTP</h3>
        <div class="active-switch">
          <label class="switch">
            <input type="checkbox" checked="" />
            <span class="sliders round"></span>
          </label>
        </div>
      </div>
      <b-form @submit.prevent="submitForm">
        <div class="col-12">
          <div class="form-group">
            <label>Email From Address</label>
            <input type="text" class="form-control" value="demo2" />
          </div>
          <div class="form-group">
            <label>Email Password</label>
            <div class="form-icon">
              <input type="password" class="form-control" value="*************" />
              <span><i class="feather feather-lock"></i></span>
            </div>
          </div>
          <div class="form-group">
            <label>Email Host</label>
            <input type="text" class="form-control" value="ssl://smtp.googlemail.com" />
          </div>
          <div class="form-group">
            <label>Email Port</label>
            <input type="text" class="form-control" value="465" />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="btn-path">
            <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
            <b-button type="submit" variant="primary">Save Changes</b-button>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/email-settings");
    },
  },
};
</script>
