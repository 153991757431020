<template>
    <div class="content container-fluid">

        <div class="row">
            <div class="col-md-6">
                <div class="widget-title">
                    <h4>Subscription</h4>
                </div>
            </div>
            <div class="col-md-6 text-md-end">
                <ul class="subs-list">
                    <li>
                        <router-link to="/provider-subscription">Plan & Pricing</router-link>
                    </li>
                    <li>
                        <router-link to="/provider-addons" class="active">Addons</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Subscription -->
        <div class="row provider-price">
            <div class="col-md-12">
                <div class="choose-title">
                    <h6 class="me-0">Addons</h6>
                </div>
            </div>

            <div class="col-md-12">
                <!-- Addon List -->
                <div class="addon-box">
                    <div class="addon-name">
                        <label class="custom_check">
                            <input type="checkbox" name="rememberme" class="rememberme">
                            <span class="checkmark"></span>
                        </label>
                        <div>
                            <h6>Services</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                        </div>
                    </div>
                    <div class="addon-info">
                        <ul>
                            <li>
                                <vue-select 
                                    :options="Service"
                                    placeholder="10 Services"
                                />
                            </li>
                            <li>
                                <h6>$20.00</h6>
                            </li>
                            <li>
                                <router-link to="/add-subscription" class="btn btn-primary">Buy</router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="addon-box">
                    <div class="addon-name">
                        <label class="custom_check">
                            <input type="checkbox" name="rememberme" class="rememberme">
                            <span class="checkmark"></span>
                        </label>
                        <div>
                            <h6>Appointments</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                        </div>
                    </div>
                    <div class="addon-info">
                        <ul>
                            <li>
                                <vue-select 
                                    :options="Appointment"
                                    placeholder="10 Appointments"
                                />
                            </li>
                            <li>
                                <h6>$20.00</h6>
                            </li>
                            <li>
                                <router-link to="/add-subscription" class="btn btn-primary">Buy</router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="addon-box">
                    <div class="addon-name">
                        <label class="custom_check">
                            <input type="checkbox" name="rememberme" class="rememberme">
                            <span class="checkmark"></span>
                        </label>
                        <div>
                            <h6>Staffs</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
                        </div>
                    </div>
                    <div class="addon-info">
                        <ul>
                            <li>
                                <vue-select 
                                    :options="User"
                                    placeholder="10 Users"
                                />
                            </li>
                            <li>
                                <h6>$20.00</h6>
                            </li>
                            <li>
                                <router-link to="/add-subscription" class="btn btn-primary">Buy</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- /Addon List -->

            </div>
        </div>
        <!-- /Subscription -->

    </div>
</template>

<script>
export default {
    data() {
        return {
            Service: ["10 Services", "8 Services", "6 Services"],
            Appointment: ["10 Appointments", "8 Appointments", "6 Appointments"],
            User: ["10 Users", "8 Users", "6 Users"]
        }
    },
}
</script>