<template>
  <!-- Our Blogs -->
  <section class="blogs-nine-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-nine aos" data-aos="fade-up">
            <p>OUR LATEST ARTICLES</p>
            <h2>Our Blogs</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel blogs-nine-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexnineblog" :key="item.id">
                <div class="service-widget service-widget-space service-widget-nine">
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="latest-date latest-date-nine">
                      <span>15</span>
                      Nov
                    </div>
                  </div>
                  <div class="service-content service-content-nine service-blog-nine">
                    <div class="usefull-bottom useful-bottom-eight useful-bottom-nine">
                      <div class="useful-img">
                        <img
                          :src="require(`@/assets/img/profiles/${item.avatar}`)"
                          alt=""
                        />
                        <span>Admin</span>
                      </div>
                      <h6><i class="feather-tag"></i>Services</h6>
                    </div>
                    <h5>Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod</h5>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque.
                    </p>
                    <div class="service-bottom-nine">
                      <a href="javascript:;" class="btn btn-primary">Read More</a>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
        <div class="btn-sec btn-service-nine aos" data-aos="fade-up">
          <router-link to="/search" class="btn btn-primary btn-view"
            >VIEW ALL BLOGS</router-link
          >
        </div>
      </div>
      <div class="free-service-all">
        <div class="row aos" data-aos="fade-up">
          <div class="col-lg-6 col-12">
            <div class="free-service-nine">
              <div class="free-service-img-one">
                <img src="@/assets/img/service-img-9.jpg" alt="" />
              </div>
              <div class="free-service-img-two">
                <img src="@/assets/img/service-img-10.jpg" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="free-service-bottom-content">
              <div
                class="section-heading section-heading-nine free-heading-nine aos"
                data-aos="fade-up"
              >
                <p>welcome to truely sell Mechanic</p>
                <h2>Get A Free Service</h2>
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and ypesetting industry.
                Lorem Ipsum has been the industry's standard.
              </p>
              <a href="javascript:;" class="btn btn-primary">Get An Appointment</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Our Blogs -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexnineblog from "@/assets/json/indexnineblog.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexnineblog: indexnineblog,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
