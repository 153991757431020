<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import moment from 'moment'
import Big from 'big.js'

const props = defineProps({
  from: {},
  to: {},
  locale: String,
  height: Number,
  list: Array,
  rows: Array,
  items: Array,
  cells: Object,
  snapStartFunction: Function,
  snapEndFunction: Function,
  canSelectFunction: Function,
  moveable: () => false,
  resizeable: () => false,
  selectable: () => false
})

const emit = defineEmits([
  'zoom',
  'scroll-x',
  'scroll-y',
  'row',
  'cell',
  'item',
  'time-slot',
  'resized',
  'moved',
  'selected-cells'
])

const colHeight = 38 * 1
const DAY_LONG = 86400000
const HOUR_LONG = 3600000

const zoomParams = {
  8: {
    slot: DAY_LONG,
    width: 21,
    line: false,
    label: [
      { str: 'DD', size: 13 },
      { str: 'dd', size: 13 }
    ],
    calendar: 'MMMM YYYY'
  },
  9: {
    slot: DAY_LONG,
    width: 42,
    line: false,
    label: [
      { str: 'DD', size: 16 },
      { str: 'ddd', size: 13 }
    ],
    calendar: 'MMMM YYYY'
  },
  10: {
    slot: DAY_LONG,
    width: 84,
    line: false,
    label: [
      { str: 'DD', size: 18 },
      { str: 'dddd', size: 13 }
    ],
    calendar: 'MMMM YYYY'
  },
  11: {
    slot: DAY_LONG,
    width: 160,
    line: true,
    label: [
      { str: 'DD', size: 18 },
      { str: 'dddd', size: 16 }
    ],
    calendar: 'MMMM YYYY'
  },
  12: {
    slot: DAY_LONG,
    width: 320,
    line: true,
    label: [
      { str: 'DD', size: 18 },
      { str: 'dddd', size: 18 }
    ],
    calendar: 'MMMM YYYY'
  },
  13: {
    slot: HOUR_LONG,
    width: 21,
    line: true,
    label: [{ str: 'HH', size: 13 }],
    calendar: 'DD MMMM YYYY'
  },
  14: {
    slot: HOUR_LONG,
    width: 42,
    line: true,
    label: [{ str: 'HH:00', size: 14 }],
    calendar: 'DD MMMM YYYY'
  },
  15: {
    slot: HOUR_LONG,
    width: 84,
    line: true,
    label: [{ str: 'HH:00', size: 16 }],
    calendar: 'DD MMMM YYYY'
  },
  16: {
    slot: HOUR_LONG,
    width: 160,
    line: true,
    label: [{ str: 'HH:00', size: 16 }],
    calendar: 'DD MMMM YYYY'
  }
}

// refs element
const gantt = ref(null)
const scrollx = ref(null)
const scrolly = ref(null)
const datacalendar = ref(null)
const dataslots = ref(null)
const cellsRef = ref(null)
const rowlabel = ref(null)
const cellswrap = ref(null)

const width = ref(1000)
const zoom = ref(9)
const xOffset = ref(0)
const yOffset = ref(0)
const drag = ref(false)
const dragRef = ref(0)

const cellsAndDataEditable = ref({})
const moveItem = ref(null)
const resizeItem = ref(null)
const selectFrom = ref(null)
const selectTo = ref(null)
const selectedCellsBox = ref({})
const selectedCells = ref({})
const hoveredRowIndex = ref(-1)

const fromTime = props.from ? ref(moment(props.from)) : ref(moment().startOf('day').add(-7, 'days'))
const toTime = props.to ? ref(moment(props.to)) : ref(moment().startOf('day').add(1, 'months'))

const isMobile = ref(false)

onMounted(() => {
  width.value = gantt.value.clientWidth
  cellsAndDataEditable.value = Object.assign({}, cellsAndData.value)
  scrollX(0)
  scrollY(0)

  if (gantt.value.clientWidth <= 420) {
    isMobile.value = true
  }

  width.value = 1800 // HACK
})


const visibleRows = computed(() => {
  let from = Math.floor(yOffset.value / colHeight)
  let count = Math.ceil(dataHeight.value / colHeight) + 1

  return Object.values(props.rows)
    .slice(from, from + count)
    .map((row, k) => ({
      row,
      y: (from + k) * colHeight
    }))
})

const itemGroupedByRowIds = computed(() => {
  let rows = {}

  props.items.forEach((item) => {
    if (!rows[item.rowId]) {
      rows[item.rowId] = []
    }

    rows[item.rowId].push(item)
  })

  return rows
})

const pxPerMs = computed(() => {
  return zoomParams[zoom.value].width / zoomParams[zoom.value].slot
})

const cellsAndData = computed(() => {
  let cells = []
  let data = []
  let slotPeriod = zoomParams[zoom.value].slot
  let now = slotPeriod == DAY_LONG ? moment().startOf('day') : moment().startOf('hour')
  let from = null

  try {
    from = dataSlots.value.slots[0].moment.valueOf()
  } catch (e) {
    from = moment().valueOf()
  }

  let to = from + dataSlots.value.slots.length * slotPeriod
  let refFrom = moment(fromTime.value).startOf('day')
  let dataSlotWidth_ = dataSlotWidth.value

  visibleRows.value.forEach((row) => {
    dataSlots.value.slots.forEach((slot) => {
      let background = '#fff'
      let id = `${row.row.id}-${slot.moment.valueOf()}`
      let classes = {}

      if (slot.moment.isSame(now)) {
        background = 'rgba(53, 167, 244, 0.2)'
      }

      if (selectFrom.value && selectTo.value) {
        let x1 = Math.min(selectFrom.value.x, selectTo.value.x)
        let y1 = Math.min(selectFrom.value.y, selectTo.value.y)
        let x2 = Math.max(selectFrom.value.x, selectTo.value.x)
        let y2 = Math.max(selectFrom.value.y, selectTo.value.y)

        if (
          slot.offset < x2 &&
          slot.offset + dataSlotWidth_.value > x1 &&
          row.y < y2 &&
          row.y + colHeight > y1
        ) {
          let canSelect = true

          let cellData = {
            rowId: row.row.id,
            from: slot.moment.valueOf(),
            to: slot.moment.valueOf() + slotPeriod - 1,
            x1: slot.offset,
            y1: row.y,
            x2: slot.offset + dataSlotWidth_.value,
            y2: row.y + colHeight
          }

          if (props.canSelectFunction) {
            canSelect = props.canSelectFunction(cellData)
          }

          if (canSelect) {
            background = '#80d0ff'
            // this.selectedCellsBox[id] = cellData
          }
        }
      }

      if (selectedCells.value[id]) {
        background = '#40b0ff'
      }

      if (props.cells && props.cells[id]) {
        ;(props.cells[id].classes || []).forEach((name) => (classes[name] = true))
      }

      cells.push({
        x: slot.offset,
        y: row.y,
        width: dataSlotWidth_,
        height: colHeight,
        background,
        row,
        slot,
        classes
      })
    })

    if (itemGroupedByRowIds.value[row.row.id]) {
      itemGroupedByRowIds.value[row.row.id].forEach((item) => {
        if (item.time.start < to && item.time.end > from) {
          data.push({
            item,
            row,
            y: row.y + 3,
            x: Math.round((item.time.start - refFrom) * pxPerMs.value),
            width: Math.round((item.time.end - item.time.start) * pxPerMs.value),
            height: colHeight - 6
          })
        }
      })
    }
  })

  return {
    cells,
    data
  }
})

const componentHeight = computed(() => {
  return Math.max(72, props.height || dataTotalHeight.value + 72)
})

const dataHeight = computed(() => {
  return Math.max(0, componentHeight.value - 72)
})

const calendarFormat = computed(() => {
  return zoomParams[zoom.value].calendar
})

const dataSlotWidth = computed(() => {
  return zoomParams[zoom.value].width
})

const isSlotHeaderInLine = computed(() => {
  return zoomParams[zoom.value].line
})

const labelDescription = computed(() => {
  return zoomParams[zoom.value].label
})

const headersRowsWidth = computed(() => {
  return Object.values(props.list || {}).reduce((acc, cur) => acc + cur.width, 0)
})

const dataTotalHeight = computed(() => {
  return Object.keys(props.rows).length * colHeight
})

const dataWidth = computed(() => {
  return width.value - headersRowsWidth.value
})

const dataTotalSlots = computed(() => {
  let delta = Math.max(toTime.value.valueOf() - fromTime.value.valueOf(), 0)
  return Math.floor(delta / zoomParams[zoom.value].slot)
})

const dataTotalWidth = computed(() => {
  return dataTotalSlots.value * zoomParams[zoom.value].width
})

const dataSlots = computed(() => {
  let from = moment(fromTime.value).locale('id').startOf('day')
  let slotPeriod = zoomParams[zoom.value].slot
  let slotWidth = zoomParams[zoom.value].width
  let slotsCount = ~~((toTime.value.valueOf() - fromTime.value.valueOf()) / slotPeriod)
  let slots = []
  let xOffset_ = xOffset.value
  let xOffsetEnd = xOffset_ + dataWidth.value
  let offset = 0
  let calendar = []
  let calendarRef = null

  for (let i = 0; i < slotsCount; i++) {
    if (offset + slotWidth > xOffset_ && offset < xOffsetEnd) {
      slots.push({
        offset: Math.max(offset, xOffset_) - 1,
        moment: moment(from),
        zoom: zoomParams[zoom.value]
      })

      let current = slotPeriod == DAY_LONG ? from.month() : from.day()

      if (current != calendarRef) {
        calendarRef = current

        calendar.push({
          moment: moment(from),
          offset: Math.max(offset, xOffset_) - 1,
          width: slotWidth
        })
      } else {
        calendar[calendar.length - 1].width += slotWidth
      }
    }

    from.add(1, slotPeriod == DAY_LONG ? 'days' : 'hours')
    offset += slotWidth
  }

  return { slots, calendar }
})

function px(n) {
  return `${n}px`
}

function get(object, keys, defaultVal = null) {
  console.log('unimplemented', object, keys, defaultVal)
}

function scrollX(scrollLeft) {
  let x = Math.max(0, Math.min(dataTotalWidth.value - dataWidth.value, scrollLeft))
  xOffset.value = x
  scrollx.value.scrollLeft = x
  datacalendar.value.scrollLeft = x
  dataslots.value.scrollLeft = x
  cellsRef.value.scrollLeft = x
  emit('scroll-x', x)
}

function scrollY(scrollTop) {
  let y = Math.max(0, Math.min(dataTotalHeight.value - dataHeight.value, scrollTop))
  yOffset.value = y
  scrolly.value.scrollTop = y
  cellsRef.value.scrollTop = y
  emit('scroll-y', y)

  if (rowlabel.value) {
    rowlabel.value.forEach((el) => (el.scrollTop = y))
  }
}

function scrollBasic(ev) {
  if (ev.ctrlKey) {
    ev.stopPropagation()
    ev.preventDefault()
  }
}

function zoom_(modifie) {
  if ((zoom.value + modifie < 8) || (zoom.value + modifie > 16)) {
    return
  }

  const dataWidth_ = dataWidth.value
  const halfDataWidth = dataWidth_ / 2
  const w1 = Math.max(dataTotalWidth.value, dataWidth_)
  const o1 = xOffset.value + halfDataWidth
  zoom.value += modifie
  let w2 = Math.max(dataTotalWidth.value, dataWidth_)
  scrollX(~~Math.max(0, (o1 * w2) / w1 - halfDataWidth))
}

function scrollData(ev) {  
  if (ev.ctrlKey) {

    if (ev.deltaY > 0 && zoom.value > 8) {
      zoom_(-1)
    }

    if (ev.deltaY < 0 && zoom.value < 16) {
      zoom_(+1)
    }

    emit('zoom', zoom.value)
  } else {
    scrollX(scrollx.value.scrollLeft + ev.deltaX * 10)
    scrollY(scrolly.value.scrollTop + ev.deltaY * 10)
  }
}

function onXScroll(ev) {
  scrollX(ev.target.scrollLeft)
}

function onDataMove(ev) {
  if (ev.buttons == 1) {
    if (resizeItem.value) {
      let deltaX = Math.round(ev.movementX / pxPerMs.value)
      let time = resizeItem.value.item.time.end + deltaX

      if (props.snapEndFunction) {
        time =
          props.snapEndFunction(resizeItem.value.item.time.end, deltaX, resizeItem.value.item) ||
          time
      }

      resizeItem.value.item.time.end = time
      resizeItem.value.width += deltaX

      emit('resized', {
        item: resizeItem.value,
        deltaX
      })
    } else if (moveItem.value) {
      let deltaX = Math.round(ev.movementX / pxPerMs.value)

      deltaX = deltaX / 1000000

      let time = moveItem.value.item.time.start + (deltaX * 1000000)
      let refFrom = moment(fromTime.value).startOf('day')

      if (props.snapStartFunction) {
        time =
          props.snapStartFunction(moveItem.value.item.time.start, deltaX, moveItem.value.item) ||
          time
      }

      let diff = time - moveItem.value.item.time.start
      moveItem.value.item.time.start = time
      moveItem.value.item.time.end += diff
      moveItem.value.x += deltaX
      console.log('start', moment(moveItem.value.item.time.start).format('DD MMMM YYYY'))

      emit('moved', {
        item: moveItem.value,
        deltaX
      })
    } else if (selectFrom.value) {
      selectTo.value = getDataCoordinates(ev, cellswrap.value)
    }
  }
}

function getDataCoordinates(event, referenceElement) {
  const position = {
    x: event.pageX,
    y: event.pageY
  }

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop
  }

  let reference = referenceElement.offsetParent

  while (reference) {
    offset.left += reference.offsetLeft
    offset.top += reference.offsetTop
    reference = reference.offsetParent
  }

  return {
    x: position.x - offset.left + xOffset.value,
    y: position.y - offset.top + yOffset.value
  }
}

function onDataMouseDown(ev) {
  moveItem.value = resizeItem.value = null

  if (props.selectable) {
    selectFrom.value = getDataCoordinates(ev, cellswrap.value)

    if (!ev.ctrlKey) {
      selectedCells.value = {}
      emit('selected-cells', {})
    }
  }
}

function onDataMouseUp(ev) {
  if (selectFrom.value && selectTo.value) {
    for (let k in selectedCellsBox.value) {
      selectedCells.value[k] = selectedCellsBox.value[k]
    }

    emit('selected-cells', selectedCells.value)
  }

  selectFrom.value = selectTo.value = moveItem.value = resizeItem.value = null
}

function onItemMouseDown(event, item) {
  emit('item', {
    event,
    item
  })

  if (props.moveable ? item.item.moveable !== false : item.item.moveable) {
    moveItem.value = item
  }
}

function onItemMouseUp() {
  moveItem.value = null
}

watch(cellsAndData, (newState, oldState) => {
  cellsAndDataEditable.value = Object.assign({}, newState)
})

watch(() => props.from, (newState, oldState) => {
  fromTime.value = props.from ? moment(newState) : moment().startOf('day').add(-7, 'days')
})

watch(() => props.to, (newState, oldState) => {
  toTime.value = props.to ? moment(newState) : moment(props.from).startOf('day').add(3, 'months')
})

defineExpose({
  zoom_
})
</script>

<template>
  <div ref="gantt" class="gantt" :style="{ height: px(componentHeight) }" @wheel="scrollBasic">
    <div class="gantt-rows" @wheel="scrollRows">
      <div
        v-for="(rowHeader, rowHeaderIndex) in list"
        :key="rowHeaderIndex"
        :style="{ width: isMobile ? px(rowHeader.width / 2) : px(rowHeader.width) }"
        class="gantt-row"
      >
        <div class="gantt-row-header">
          <div class="gantt-row-header-title ps-2">
            {{ rowHeader.header.content }}
          </div>
          <div class="gantt-row-header-dots">
            <div class="gantt-row-header-dots" />
          </div>
        </div>
        <div
          ref="rowlabel"
          class="gantt-row-header-data"
          :style="{ width: isMobile ? px(rowHeader.width / 2) : px(rowHeader.width), height: px(dataHeight) }"
          @mousemove="onRowsHeaderMove"
        >
          <div
            v-for="(row, rowIndex) in rows"
            :key="rowIndex"
            :class="{ hovered: hoveredRowIndex == rowIndex }"
            class="gantt-row-header-data-row"
            :style="{ height: px(colHeight) }"
            @click="emit('row', row)"
            @mouseenter="hoveredRowIndex = rowIndex"
            @mouseleave="hoveredRowIndex = -1"
          >
            <div class="p-2">
              <span :class="{'fw-bold': rowHeader.type === 'vehicle'}">{{ row[rowHeader.id] }}</span>
              <span v-if="rowHeader.type === 'vehicle'">
                &#8226;
                <span>{{ row[rowHeader.vehicle.licenseNumber] }}</span>
                &#8226;
                <span>{{ row[rowHeader.vehicle.vendorName] }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gantt-data" @wheel="scrollData">
      <div ref="dataheader" class="gantt-data-header" @mousemove="onDataHeaderMove">
        <div
          ref="datacalendar"
          class="gantt-data-header-calendar-wrap"
          :style="{ width: px(dataWidth) }"
        >
          <div class="gantt-data-header-calendar" :style="{ width: px(dataTotalWidth) }">
            <div
              v-for="(slot, slotIndex) in dataSlots.calendar"
              :key="slotIndex"
              :style="{ width: px(Math.max(slot.width, 260)), left: px(slot.offset) }"
              class="gantt-data-header-calendar-date fw-bold"
            >
              {{ slot.moment.format(calendarFormat) }}
            </div>
          </div>
        </div>
        <div ref="dataslots" :style="{ width: px(dataWidth) }" class="gantt-data-header-slots-wrap">
          <div class="gantt-data-header-slots" :style="{ width: px(dataTotalWidth) }">
            <div
              v-for="(slot, slotIndex) in dataSlots.slots"
              :key="slotIndex"
              :style="{ width: px(dataSlotWidth), left: px(slot.offset), 'background-color': slot.moment.isSame(moment(), 'day') ? 'rgba(53, 167, 244, 0.2)' : '#f8f8f8' }"
              class="gantt-data-header-slot"
              @click="emit('time-slot', slot)"
            >
              <div v-if="isSlotHeaderInLine" class="gantt-data-header-slot-label">
                <span
                  v-for="(line, lineIndex) in labelDescription"
                  :key="lineIndex"
                  :style="{
                    fontSize: px(line.size || 14),
                    fontWeight: line.weight || 400
                  }"
                >
                  {{ slot.moment.format(line.str) }}
                </span>
              </div>
              <div v-else class="gantt-data-header-slot-label">
                <div
                  v-for="(line, lineIndex) in labelDescription"
                  :key="lineIndex"
                  :style="{
                    fontSize: px(line.size || 14),
                    fontWeight: line.weight || 400
                  }"
                >
                  {{ slot.moment.format(line.str) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :style="{ height: px(dataHeight) }" class="gantt-data-wrap-with-scroll">
        <div ref="scrolly" class="gantt-data-y-scroll" @scroll="onYScroll">
          <div class="gantt-data-y-scroll-ref" :style="{ height: px(dataTotalHeight) }" />
        </div>
        <div
          ref="cellsRef"
          :style="{ width: px(dataWidth), height: px(dataHeight) }"
          class="gantt-data-wrap"
        >
          <div
            ref="cellswrap"
            class="gantt-data-wrapped"
            :style="{ width: px(dataTotalWidth), height: px(dataTotalHeight) }"
            @mousedown="onDataMouseDown"
            @mouseup="onDataMouseUp"
            @mousemove="onDataMove"
            @moseleave="selectFrom = selectTo = moveItem = resizeItem = null"
          >
            <div
              v-for="(cell, cellIndex) in cellsAndDataEditable.cells"
              :key="cellIndex"
              :style="{
                left: px(cell.x),
                top: px(cell.y),
                width: px(cell.width),
                height: px(cell.height),
                background: cell.background
              }"
              :class="cell.classes"
              class="gantt-data-cell"

              @click="emit('cell', { event: $event, cell: cell })"
            />

            <div
              v-for="(item, itemIndex) in cellsAndDataEditable.data"
              :key="1e9 + itemIndex"
              :style="{
                left: px(item.x),
                top: px(item.y),
                width: px(item.width),
                height: px(item.height),
                background:
                  item.item.style && item.item.style.background ? item.item.style.background : null,
                cursor: item.item.moveable ? 'pointer' : null,
                zIndex:
                  (moveItem && item.item == moveItem.item) ||
                  (resizeItem && item.item == resizeItem.item)
                    ? 1000
                    : null
              }"
              class="gantt-data-item shadow-sm"
              @mousedown.stop="onItemMouseDown($event, item)"
              @mouseup.stop="onItemMouseUp"
            >
              <div class="gantt-data-item-label">
                <div class="d-flex flex-column pt-1">
                  <span class="fw-bold">{{ item.item.label }}</span>
                </div>
              </div>
              <div
                v-if="props.resizable ? item.item.resizable !== false : item.item.resizable"
                class="gantt-data-item-resizer"
                @mousedown.stop="onItemResizeMouseDown(item)"
                @mouseup.stop="onItemResizeMouseUp"
              >
                ⮕
              </div>
            </div>

            <div
              v-if="selectFrom && selectTo"
              :style="{
                left: `${Math.min(selectFrom.x, selectTo.x)}px`,
                top: `${Math.min(selectFrom.y, selectTo.y)}px`,
                width: `${Math.abs(selectTo.x - selectFrom.x)}px`,
                height: `${Math.abs(selectTo.y - selectFrom.y)}px`
              }"
              class="gantt-data-select"
            />
          </div>
        </div>
      </div>
      <div ref="scrollx" class="gantt-data-x-scroll" @scroll="onXScroll">
        <div class="gantt-data-x-scroll-ref" :style="{ width: px(dataTotalWidth) }" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.gantt {
  background: #fff;
  display: flex;
  user-select: none;
}
.gantt .gantt-rows {
  display: flex;
}
.gantt .gantt-rows .gantt-row .gantt-row-header {
  background: #f8f8f8;
  color: #606060;
  height: 72px;
  line-height: 70px;
  font-weight: bold;
  box-sizing: border-box;
  padding-left: 5px;
  border-left: solid 1px #fff;
  border-right: solid 2px #eee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: sticky;
  top: 85px;
  z-index: 100;
  border: 1px solid #ccc;
  /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}
.gantt .gantt-rows .gantt-row-header-data {
  overflow: hidden;
}
.gantt .gantt-rows .gantt-row-header-data .gantt-row-header-data-row {
  box-sizing: border-box;
  border-bottom: solid 1px #eee;
  border-right: solid 1px #eee;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #555;
  cursor: pointer;
}
.gantt .gantt-rows .gantt-row-header-data .gantt-row-header-data-row.hovered {
  background: rgba(0, 0, 0, 0.1);
}
.gantt .gantt-data {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
}
.gantt .gantt-data .gantt-data-header {
  height: 72px;
  background: #f8f8f8;
  overflow: hidden;
  position: sticky;
  top: 85px;
  z-index: 100;
  border: 1px solid #ccc;
  /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}
.gantt .gantt-data .gantt-data-header .gantt-data-header-calendar-wrap {
  height: 18px;
  line-height: 17px;
  font-size: 14px;
  overflow: hidden;
}
.gantt .gantt-data .gantt-data-header .gantt-data-header-calendar-wrap .gantt-data-header-calendar {
  position: relative;
}
.gantt
  .gantt-data
  .gantt-data-header
  .gantt-data-header-calendar-wrap
  .gantt-data-header-calendar
  .gantt-data-header-calendar-date {
  padding-left: 5px;
  position: absolute;
  top: 0;
  height: 18px;
  box-sizing: border-box;
  border-left: solid 1px #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #f8f8f8;
  color: #606060;
  text-align: left;
  text-transform: capitalize;
}
.gantt .gantt-data .gantt-data-header .gantt-data-header-slots-wrap {
  height: 54px;
  overflow: hidden;
}
.gantt .gantt-data .gantt-data-header .gantt-data-header-slots-wrap .gantt-data-header-slots {
  position: relative;
}
.gantt
  .gantt-data
  .gantt-data-header
  .gantt-data-header-slots-wrap
  .gantt-data-header-slots
  .gantt-data-header-slot {
  position: absolute;
  top: 0;
  box-sizing: border-box;
  border-left: solid 1px #ddd;
  height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #f8f8f8;
  color: #606060;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.gantt
  .gantt-data
  .gantt-data-header
  .gantt-data-header-slots-wrap
  .gantt-data-header-slots
  .gantt-data-header-slot
  .gantt-data-header-slot-label {
  line-height: 130%;
  text-transform: capitalize;
}
.gantt .gantt-data .gantt-data-wrap {
  overflow: hidden;
}
.gantt .gantt-data .gantt-data-wrap .gantt-data-wrapped {
  position: relative;
}
.gantt .gantt-data .gantt-data-wrap-with-scroll {
  position: relative;
}
.gantt .gantt-data .gantt-data-wrap-with-scroll .gantt-data-y-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -14px;
  width: 14px;
}
.gantt .gantt-data .gantt-data-wrap-with-scroll .gantt-data-y-scroll div {
  width: 14px;
}
.gantt .gantt-data .gantt-data-wrap-with-scroll .gantt-data-cell {
  position: absolute;
  box-sizing: border-box;
  border-left: solid 1px #eee;
  border-bottom: solid 1px #eee;
  transition: background-color 100ms ease;
  cursor: pointer;
}

.gantt-data-cell:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.gantt .gantt-data .gantt-data-wrap-with-scroll .gantt-data-item {
  display: flex;
  position: absolute;
  box-sizing: border-box;
  border-left: solid 1px #eee;
  border-bottom: solid 1px #eee;
  background: #ccc;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  overflow: hidden;
  padding-left: 7px;
  padding-right: 2px;
}
.gantt .gantt-data .gantt-data-wrap-with-scroll .gantt-data-item .gantt-data-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}
.gantt .gantt-data .gantt-data-wrap-with-scroll .gantt-data-item .gantt-data-item-resizer {
  width: 14px;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
}
.gantt .gantt-data .gantt-data-wrap-with-scroll .gantt-data-select {
  position: absolute;
  z-index: 1000;
  background: rgba(0, 140, 255, 0.2);
  border: dashed 2px #008fff;
}
.gantt .gantt-data .gantt-data-x-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 14px;
}
.gantt .gantt-data .gantt-data-x-scroll div {
  height: 14px;
}
</style>

