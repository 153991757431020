<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>
    <div class="page-wrapper page-settings">
      <div class="content">
        <abusereport :title="title" />
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <a-table :columns="columns" :data-source="data">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Provider'">
                    <a href="javascript:void(0);" class="table-profileimage">
                      <img
                        :src="
                          require(`@/assets/admin_img/customer/${record.Provider_img}`)
                        "
                        class="me-2"
                        alt="img"
                      />
                      <span>{{ record.Provider }}</span>
                    </a>
                  </template>
                  <template v-else-if="column.key === 'ReportedByUser'">
                    <a href="javascript:void(0);" class="table-profileimage">
                      <img
                        :src="require(`@/assets/admin_img/customer/${record.User_img}`)"
                        class="me-2"
                        alt="img"
                      />
                      <span>{{ record.ReportedByUser }}</span>
                    </a>
                  </template>
                  <template v-else-if="column.key === 'id'">
                    <div class="table-actions d-flex">
                      <a
                        class="delete-table me-2"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#add-abuse"
                      >
                        <img src="@/assets/admin_img/icons/eye.svg" alt="svg" />
                      </a>
                      <a
                        class="delete-table"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                      >
                        <img src="@/assets/admin_img/icons/delete.svg" alt="svg" />
                      </a>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <abuse-model></abuse-model>
</template>
<script>
const columns = [
  {
    title: "#",
    dataIndex: "num",
    sorter: {
      compare: (a, b) => {
        a = a.num.toLowerCase();
        b = b.num.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Provider",
    dataIndex: "Provider",
    key: "Provider",
    sorter: {
      compare: (a, b) => {
        a = a.Provider.toLowerCase();
        b = b.Provider.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reported By User",
    dataIndex: "ReportedByUser",
    key: "ReportedByUser",
    sorter: {
      compare: (a, b) => {
        a = a.ReportedByUser.toLowerCase();
        b = b.ReportedByUser.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "Description",
    sorter: {
      compare: (a, b) => {
        a = a.Description.toLowerCase();
        b = b.Description.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    num: "1",
    Provider_img: "user-01.jpg",
    Provider: "John Smith",
    User_img: "user-03.jpg",
    ReportedByUser: "Sharon",
    Description: "Lorem ipsum dolor sit",
    Date: "2023-09-28T16:43:20",
  },
  {
    num: "2",
    Provider_img: "user-04.jpg",
    Provider: "Johnny",
    User_img: "user-05.jpg",
    ReportedByUser: "Pricilla",
    Description: "Lorem ipsum dolor sit",
    Date: "2023-09-15T16:43:20",
  },
  {
    num: "3",
    Provider_img: "user-06.jpg",
    Provider: "Robert",
    User_img: "user-02.jpg",
    ReportedByUser: "Amanda",
    Description: "Lorem ipsum dolor sit",
    Date: "2023-09-13T16:43:20",
  },
  {
    num: "4",
    Provider_img: "user-09.jpg",
    Provider: "Sharonda",
    User_img: "user-09.jpg",
    ReportedByUser: "James",
    Description: "Lorem ipsum dolor sit",
    Date: "2023-09-03T16:43:20",
  },
  {
    num: "5",
    Provider_img: "user-01.jpg",
    Provider: "John Smith",
    User_img: "user-03.jpg",
    ReportedByUser: "Sharon",
    Description: "Lorem ipsum dolor sit",
    Date: "2023-09-18T16:43:20",
  },
  {
    num: "6",
    Provider_img: "user-04.jpg",
    Provider: "Johnny",
    User_img: "user-05.jpg",
    ReportedByUser: "Pricilla",
    Description: "Lorem ipsum dolor sit",
    Date: "2023-09-23T16:43:20",
  },
  {
    num: "7",
    Provider_img: "user-06.jpg",
    Provider: "Robert",
    User_img: "user-02.jpg",
    ReportedByUser: "Amanda",
    Description: "Lorem ipsum dolor sit",
    Date: "2023-09-18T16:43:20",
  },
  {
    num: "8",
    Provider_img: "user-09.jpg",
    Provider: "Sharonda",
    User_img: "user-09.jpg",
    ReportedByUser: "James",
    Description: "Lorem ipsum dolor sit",
    Date: "2023-09-15T16:43:20",
  },
];
export default {
  data() {
    return {
      title: "Abuse Reports",
      name: "/abuse-reports",
      data,
      columns,
    };
  },
  name: "abuse-reports",
};
</script>
