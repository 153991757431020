<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Contact Messages</h5>
        </div>
        <ul class="con-list">
          <li>
            <span class="con-img">
              <img src="@/assets/admin_img/customer/user-04.jpg" alt="" />
            </span>
            <div class="con-info">
              <h6>Name</h6>
              <p>John Smith</p>
            </div>
          </li>
          <li>
            <span class="con-icon">
              <i class="feather feather-message-square"></i>
            </span>
            <div class="con-info">
              <h6>Email</h6>
              <p>john@example.com</p>
            </div>
          </li>
          <li>
            <span class="con-icon">
              <i class="feather feather-phone-call"></i>
            </span>
            <div class="con-info">
              <h6>Phone</h6>
              <p>+1 347-679-8275</p>
            </div>
          </li>
          <li>
            <span class="con-icon">
              <i class="feather feather-message-circle"></i>
            </span>
            <div class="con-info">
              <h6>Message</h6>
              <p>Need this script</p>
            </div>
          </li>
          <li>
            <span class="con-icon">
              <i class="feather feather-calendar"></i>
            </span>
            <div class="con-info">
              <h6>Date</h6>
              <p>28 Sep 2023</p>
            </div>
          </li>
          <li>
            <a
              href="javascript:;"
              class="btn btn-primary btn-reply"
              data-bs-toggle="modal"
              data-bs-target="#add-reply"
              >Reply</a
            >
          </li>
        </ul>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <a href="javascript:void(0);" class="table-profileimage">
                        <img
                          :src="require(`@/assets/admin_img/customer/${record.image}`)"
                          class="me-2"
                          alt="img"
                        />
                        <span>John Smith</span>
                      </a>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <contact-message-model></contact-message-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "Email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    sorter: {
      compare: (a, b) => {
        a = a.Phone.toLowerCase();
        b = b.Phone.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Message",
    dataIndex: "Message",
    sorter: {
      compare: (a, b) => {
        a = a.Message.toLowerCase();
        b = b.Message.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    id: "1",
    image: "user-01.jpg",
    Name: "John Smith",
    Email: "john@example.com",
    Phone: "+1 347-679-8275",
    Date: "28 Sep 2023",
    Message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  },
  {
    id: "2",
    image: "user-04.jpg",
    Name: "Johnny",
    Email: "johnny@example.com",
    Phone: "+1 347-679-8275",
    Date: "285 Sep 2023",
    Message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  },
];
export default {
  data() {
    return {
      columns,
      data,
    };
  },
};
</script>
