<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <sidelinkheading />
      <div class="content w-100">
        <div class="content-page-header">
          <h5>Localization</h5>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-groupheads">
                      <h2>Profile Picture</h2>
                    </div>
                  </div>
                </div>
                <div class="location-set">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                      <div class="location-setcontent">
                        <h5>Timezone</h5>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group mb-0">
                        <label>Timezone</label>
                        <div class="group-image">
                          <img src="@/assets/admin_img/icons/clock.svg" alt="img" />
                          <vue-select
                            :options="Timezone"
                            placeholder="(UTC+09:00) Tokyo"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="location-set">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                      <div class="location-setcontent">
                        <h5>Date Format</h5>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group mb-0">
                        <label>Date</label>
                        <div class="group-image">
                          <img src="@/assets/admin_img/icons/calendar.svg" alt="img" />
                          <vue-select :options="Timezone2" placeholder="30 Sep 2023" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="location-set mb-0">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                      <div class="location-setcontent">
                        <h5>Time Format</h5>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group mb-0">
                        <label>Time</label>
                        <div class="group-image">
                          <img src="@/assets/admin_img/icons/clock.svg" alt="img" />
                          <vue-select :options="Timezone3" placeholder="12:00PM" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-groupheads">
                      <h2>Currency & Decimal</h2>
                    </div>
                  </div>
                </div>
                <div class="location-set">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                      <div class="location-setcontent">
                        <h5>Default Currency</h5>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group mb-0">
                        <label>Default Currency</label>
                        <div class="group-image">
                          <img src="@/assets/admin_img/icons/clock.svg" alt="img" />
                          <vue-select :options="Timezone4" placeholder="USD" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="location-set mb-0">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                      <div class="location-setcontent">
                        <h5>Choose Currency</h5>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group mb-0">
                        <label>Select Currency</label>
                        <vue3-tags-input
                          class="input-tags form-control"
                          type="tag"
                          data-role="tagsinput"
                          name="specialist"
                          id="specialist"
                          :tags="tags"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-groupheads">
                      <h2>Country Settings</h2>
                    </div>
                  </div>
                </div>
                <div class="location-set">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                      <div class="location-setcontent">
                        <h5>Country Restriction</h5>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group mb-0">
                        <label>Country Restriction</label>
                        <div class="group-image">
                          <img src="@/assets/admin_img/icons/global.svg" alt="img" />
                          <vue-select
                            :options="Restriction"
                            placeholder="Allow all countries"
                          />
                        </div>
                        <p>Allow or disallowed the countries</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="location-set mb-0">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                      <div class="location-setcontent">
                        <h5>Choose Countries</h5>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group mb-0">
                        <label>Select Countries</label>
                        <vue-select
                          multiple="multiple"
                          :options="Countries"
                          placeholder="USD"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-groupheads">
                      <h2>Language Settings</h2>
                    </div>
                  </div>
                </div>
                <div class="location-set">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                      <div class="location-setcontent">
                        <h5>Default Language</h5>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group mb-0">
                        <label>Select Language</label>
                        <div class="group-image">
                          <img src="@/assets/admin_img/icons/global.svg" alt="img" />
                          <vue-select :options="Language" placeholder="English" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="location-set mb-0">
                  <div class="row align-items-center">
                    <div class="col-lg-6 col-12">
                      <div class="location-setcontent">
                        <h5>Language Switcher</h5>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="active-switch">
                        <label class="switch">
                          <input type="checkbox" />
                          <span class="sliders round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="btn-path">
              <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
              <a href="javascript:void(0);" class="btn btn-primary">Save Changes</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3TagsInput from "vue3-tags-input";
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      Timezone: ["(UTC+09:00) Tokyo", "(UTC+11:00) INR"],
      Timezone2: ["30 Sep 2023", "Jun 01 2023", "2023 01 Jun"],
      Timezone3: ["12:00PM", "01:00PM"],
      Timezone4: ["USD", "INR"],
      tags: ["USD", "Rupees", "QWD"],
      Restriction: ["Allow all countries", "USA"],
      Countries: ["USD", "Rupees", "QWD"],
      Language: ["English", "Chinese"],
    };
  },
};
</script>
