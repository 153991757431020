<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>FAQ’s</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select
                          :options="Selectfaq"
                          id="selectfaq"
                          placeholder="A - Z"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  class="btn btn-primary"
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#add-faq"
                  ><i class="fa fa-plus me-2"></i>Add FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Title'">
                      <td>{{ record.Title }}</td>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <a
                          class="delete-table me-2"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-faq"
                        >
                          <img src="@/assets/admin_img/icons/edit.svg" alt="svg" />
                        </a>
                        <a
                          class="delete-table"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <img src="@/assets/admin_img/icons/delete.svg" alt="svg" />
                        </a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <faq-model></faq-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Title",
    dataIndex: "Title",
    key: "Title",
    sorter: {
      compare: (a, b) => {
        a = a.Title.toLowerCase();
        b = b.Title.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category",
    dataIndex: "Category",
    sorter: {
      compare: (a, b) => {
        a = a.Category.toLowerCase();
        b = b.Category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Details",
    dataIndex: "Details",
    sorter: {
      compare: (a, b) => {
        a = a.Details.toLowerCase();
        b = b.Details.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Title: "How Can I Book Service?",
    Category: "Services",
    Details: "Lorem ipsum dolor amet, adipiscing elit",
    Action: "",
  },
  {
    id: "2",
    Title: "Sed perspiciatis omnis iste natus error?",
    Category: "Services",
    Details: "At vero eos et accusamus et iusto dignissimos",
    Action: "",
  },
  {
    id: "3",
    Title: "How Can I Book Service?",
    Category: "Services",
    Details: "Lorem ipsum dolor amet, adipiscing elit",
    Action: "",
  },
  {
    id: "4",
    Title: "Sed perspiciatis omnis iste natus error?",
    Category: "Services",
    Details: "At vero eos et accusamus et iusto dignissimos",
    Action: "",
  },
];
export default {
  data() {
    return {
      Selectfaq: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
