<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="content-page-header content-page-headersplit mb-0">
            <h5>Brand Kendaraan</h5>
            <div class="list-btn">
              <ul>
                <!-- <li>
                  <div class="input-group">
                    <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="height: 43px !important;">
                    <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
                  </div>
                </li> -->
                <li>
                  <button
                    class="btn btn-primary"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#add-brand"
                  >
                    <i class="fa fa-plus me-2"></i>Tambah Brand
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-resposnive table-div">
                <div class="table datatable">
                  <a-table :columns="columns" :data-source="data" :pagination="pagination">
                      <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'file'">
                          <a href="javascript:void(0);" class="table-profileimage">
                            <img
                              :src="record.file"
                              class="me-2"
                              alt="img"
                            />
                          </a>
                      </template>
                      <template v-if="column.key === 'id'">
                        <div class="table-actions d-flex">
                          <button
                            class="btn delete-table me-2"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-brand"
                            @click="openEditModal(record)"
                          >
                            <i class="feather feather-edit"></i>
                          </button>
                          <button
                            class="btn delete-table"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                            @click="openDeleteModal(record)"
                          >
                            <i class="feather feather-trash-2"></i>
                          </button>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <brand-model :menuData="dataMenu" :roleData="dataRole" :editData="editData" :deleteItemId="itemIdToDelete" @dataUpdated="getData"></brand-model>
  </template>
  
  <script>
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Nama Brand",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Logo",
      dataIndex: "file",
      key: "file",
      sorter: {
        compare: (a, b) => {
          a = a.file.toLowerCase();
          b = b.file.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataRole: [],
        dataMenu: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        }
      };
    },
    name: "brandsetting",
    computed: {

    },
    methods: {
        search() {
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("brand/getBrand", this.params)
                .then((resp) => {
                    this.data = resp.data.data.map((item, index) => ({
                      ...item,
                      index: index + 1 
                    }));
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
            this.getRole();
            this.getMenu();
        },
        getRole() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("role/getRole", this.params)
                .then((resp) => {
                    this.dataRole = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getRoleName(roleId) {
            const role = this.dataRole.find(role => role.id === roleId);
            return role ? role.name : '-';
        },
        getMenu() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("menu/getMenu", this.params)
                .then((resp) => {
                    this.dataMenu = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getMenuName(menuId) {
            const menu = this.dataMenu.find(menu => menu.id === menuId);
            return menu ? menu.name : '-';
        },
        openEditModal(data) {
          // Set the edit data before opening the modal
          this.editData = { data };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
    },
    mounted() {
        this.getData();
    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>