<template>
  <div class="login-body">
    <div class="main-wrapper">
      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-6 mx-auto">
                <div class="login-wrap">
                  <div class="login-back">
                    <router-link to="provider-security-settings"
                      ><i class="feather-arrow-left"></i> Back</router-link
                    >
                  </div>
                  <div class="login-header">
                    <h3>Change Password</h3>
                    <p>Please enter your current password to change your password</p>
                  </div>

                  <!-- Reset Password Form -->
                  <form @submit.prevent="submitForm">
                    <div class="form-group">
                      <label class="col-form-label">Current Password</label>
                      <div class="pass-group">
                        <input
                          v-if="showPassword"
                          type="text"
                          class="form-control pass-input"
                          v-model="password"
                          placeholder="**********"
                        />
                        <input
                          v-else
                          type="password"
                          class="form-control pass-input"
                          placeholder="**********"
                          v-model="password"
                        />
                        <span
                          class="toggle-password"
                          @click="toggleShow"
                          :class="{
                            'feather-eye': showPassword,
                            'feather-eye-off': !showPassword,
                          }"
                        ></span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">New Password</label>
                      <div class="pass-group" id="passwordInput">
                        <input
                          v-if="showPassword3"
                          type="text"
                          class="form-control pass-input"
                          v-model="password3"
                          placeholder=".............."
                        />
                        <input
                          v-else
                          type="password"
                          class="form-control pass-input"
                          placeholder=".............."
                          v-model="password3"
                        />
                        <span
                          class="toggle-password"
                          @click="toggleShow2"
                          :class="{
                            'feather-eye': showPassword3,
                            'feather-eye-off': !showPassword3,
                          }"
                        ></span>
                      </div>
                      <div class="password-strength" id="passwordStrength">
                        <span id="poor"></span>
                        <span id="weak"></span>
                        <span id="strong"></span>
                        <span id="heavy"></span>
                      </div>
                      <div id="passwordInfo">
                        Use 8 or more characters with a mix of letters, numbers & symbols.
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Confirm New Password</label>
                      <div class="pass-group">
                        <input
                          v-if="showPassword"
                          type="text"
                          class="form-control pass-input"
                          v-model="password"
                          placeholder="**********"
                        />
                        <input
                          v-else
                          type="password"
                          class="form-control pass-input"
                          placeholder="**********"
                          v-model="password1"
                        />
                        <span
                          class="toggle-password"
                          @click="toggleShow1"
                          :class="{
                            'feather-eye': showPassword1,
                            'feather-eye-off': !showPassword1,
                          }"
                        ></span>
                      </div>
                    </div>

                    <div class="acc-submit">
                      <a href="javascript:;" class="btn btn-secondary">Cancel</a>
                      <button class="btn btn-primary" type="submit">
                        Update Password
                      </button>
                    </div>
                  </form>

                  <!-- /Reset Password Form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
      showPassword1: false,
      password1: null,
      showPassword3: false,
      password3: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    buttonLabel() {
      return this.showPassword1 ? "Hide" : "Show";
    },
    buttonLabel2() {
      return this.showPassword3 ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
    toggleShow2() {
      this.showPassword3 = !this.showPassword3;
    },
    submitForm() {
      this.$router.push("/success");
    },
  },
  mounted() {
    if (document.getElementById("passwordInput")) {
      let passwordInput = document
        .getElementById("passwordInput")
        .querySelector('input[type="password"]');
      let passwordStrength = document.getElementById("passwordStrength");
      let passwordInfo = document.getElementById("passwordInfo");

      let poor = passwordStrength.getElementsByClassName("poor")[0];
      let weak = passwordStrength.getElementsByClassName("weak")[0];
      let strong = passwordStrength.getElementsByClassName("strong")[0];
      let heavy = passwordStrength.getElementsByClassName("heavy")[0];

      let poorRegExp = /[a-z]/;
      let weakRegExp = /(?=.*?[0-9])/;
      let strongRegExp = /(?=.*?[#?!@$%^&*-])/;

      let whitespaceRegExp = /^$|\s+/;

      passwordInput.addEventListener("input", function () {
        let passwordValue = passwordInput.value;
        let passwordLength = passwordValue.length;
        let poorPassword = passwordValue.match(poorRegExp);
        let weakPassword = passwordValue.match(weakRegExp);
        let strongPassword = passwordValue.match(strongRegExp);
        let whitespace = passwordValue.match(whitespaceRegExp);

        if (passwordValue !== "") {
          passwordStrength.style.display = "block";
          passwordStrength.style.display = "flex";
          passwordInfo.style.display = "block";
          passwordInfo.style.color = "black";

          if (whitespace) {
            passwordInfo.textContent = "whitespaces are not allowed";
          } else {
            poorPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
            weakPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
            strongPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
            heavyPasswordStrength(
              passwordLength,
              poorPassword,
              weakPassword,
              strongPassword
            );
          }
        } else {
          passwordInfo.style.display = "none";
          removeClass(passwordStrength, "poor-active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
        }
      });

      function poorPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength < 8) {
          addClass(poor, "active");
          addClass(passwordStrength, "poor-active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
          passwordInfo.style.display = "block";
          passwordInfo.style.color = "#FF0000";
          passwordInfo.innerHTML = "Weak. Must contain at least 8 characters";
        }
      }

      function weakPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength >= 8 && (poorPassword || weakPassword || strongPassword)) {
          addClass(weak, "active");
          removeClass(passwordStrength, "poor-active");
          addClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
          passwordInfo.style.display = "block";
          passwordInfo.style.color = "#FFB54A";
          passwordInfo.innerHTML = "Average. Must contain at least 1 letter or number";
        } else {
          removeClass(weak, "active");
        }
      }

      function strongPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength >= 8 && poorPassword && (weakPassword || strongPassword)) {
          addClass(strong, "active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "poor-active");
          addClass(passwordStrength, "strong-active");
          removeClass(passwordStrength, "heavy-active");
          passwordInfo.innerHTML = "Almost. Must contain a special symbol";
          passwordInfo.style.color = "#1D9CFD";
        } else {
          removeClass(strong, "active");
        }
      }

      function heavyPasswordStrength(
        passwordLength,
        poorPassword,
        weakPassword,
        strongPassword
      ) {
        if (passwordLength >= 8 && poorPassword && weakPassword && strongPassword) {
          addClass(heavy, "active");
          removeClass(passwordStrength, "poor-active");
          removeClass(passwordStrength, "avg-active");
          removeClass(passwordStrength, "strong-active");
          addClass(passwordStrength, "heavy-active");
          passwordInfo.innerHTML = "Awesome! You have a secure password.";
          passwordInfo.style.color = "#159F46";
        } else {
          removeClass(heavy, "active");
        }
      }

      function addClass(element, className) {
        if (element && !element.classList.contains(className)) {
          element.classList.add(className);
        }
      }

      function removeClass(element, className) {
        if (element && element.classList.contains(className)) {
          element.classList.remove(className);
        }
      }
    }
  },
};
</script>
