<template>
  <div class="login-body">
    <div class="main-wrapper">
      <otpheader />

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-6 mx-auto">
                <div class="login-wrap">
                  <div class="login-header">
                    <h3>Sign in</h3>
                    <p>We'll send a confirmation code to your email.</p>
                    <h6>
                      Sign in with
                      <router-link to="/login-phone1">Phone Number</router-link>
                    </h6>
                  </div>

                  <!-- Login Form -->
                  <form @submit.prevent="submitForm">
                    <div class="log-form">
                      <div class="form-group">
                        <label class="col-form-label">E-mail</label>
                        <input
                          type="text"
                          class="form-control"
                          value="example@email.com"
                        />
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="custom_check">
                            <input type="checkbox" name="rememberme" class="rememberme" />
                            <span class="checkmark"></span>Remember Me
                          </label>
                        </div>
                        <div class="col-md-6 text-end">
                          <label class="custom_check">
                            <input type="checkbox" name="loginotp" class="loginotp" />
                            <span class="checkmark"></span>Login with OTP
                          </label>
                        </div>
                      </div>
                    </div>
                    <b-button variant="primary w-100 login-btn" type="submit"
                      >Sign in</b-button
                    >
                    <div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">Or, log in with your email</span>
                    </div>
                    <div class="social-login">
                      <a href="javascript:;" class="btn btn-google w-100"
                        ><img
                          src="@/assets/img/icons/google.svg"
                          class="me-2"
                          alt="img"
                        />Log in with Google</a
                      >
                      <a href="javascript:;" class="btn btn-google w-100"
                        ><img src="@/assets/img/icons/fb.svg" class="me-2" alt="img" />Log
                        in with Facebook</a
                      >
                    </div>
                    <p class="no-acc">
                      Don't have an account ?
                      <router-link to="/choose-signup">Sign up</router-link>
                    </p>
                  </form>
                  <!-- /Login Form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/login-email");
    },
  },
};
</script>
