<template>
  <!-- How It Works -->
  <section class="works-five-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading car-wash-heading aos" data-aos="fade-up">
            <div class="car-wash-img-five">
              <img
                src="@/assets/img/icons/car-wash-header-1.svg"
                alt=""
                class="car-wash-header-one"
              />
              <h2>Featured Categories</h2>
              <img
                src="@/assets/img/icons/car-wash-header-2.svg"
                alt=""
                class="car-wash-header-two"
              />
            </div>
            <p>What do you need to find?</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 col-12">
          <div class="works-it-five-button-main">
            <div class="works-it-five-button">
              <h4>01</h4>
            </div>
            <div class="works-it-dots">
              <span></span>
            </div>
            <div class="works-it-lines">
              <span></span>
            </div>
          </div>
          <div class="works-five-main">
            <div class="works-five-img">
              <img src="@/assets/img/works-3.jpg" alt="" />
            </div>
            <div class="works-five-bottom">
              <h5>Express washing</h5>
              <p>Car wash will take no more than 15 minutes.</p>
              <i class="feather-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-12">
          <div class="works-it-five-button-main">
            <div class="works-it-five-button">
              <h4>02</h4>
            </div>
            <div class="works-it-dots">
              <span></span>
            </div>
            <div class="works-it-lines">
              <span></span>
            </div>
          </div>
          <div class="works-five-main">
            <div class="works-five-img">
              <img src="@/assets/img/works-2.jpg" alt="" />
            </div>
            <div class="works-five-bottom">
              <h5>Express washing</h5>
              <p>Car wash will take no more than 15 minutes.</p>
              <i class="feather-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 col-12">
          <div class="works-it-five-button-main">
            <div class="works-it-five-button">
              <h4>03</h4>
            </div>
            <div class="works-it-dots">
              <span></span>
            </div>
          </div>
          <div class="works-five-main">
            <div class="works-five-img">
              <img src="@/assets/img/works-1.jpg" alt="" />
            </div>
            <div class="works-five-bottom">
              <h5>Express washing</h5>
              <p>Car wash will take no more than 15 minutes.</p>
              <i class="feather-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="offering-five-all">
        <div class="offering-five-all-img">
          <img src="@/assets/img/bubbles-offering-section.png" alt="" />
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="offering-five-main">
              <h2>We Are Offering 14 Days Free Trial</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore minim veniam, quis nostrud
                exercitation ullamco magna aliqua.
              </p>
              <div class="offering-five-button">
                <a href="javascript:;" class="btn btn-primary"
                  >Try 14 Days Free Trial<i class="feather-arrow-right-circle"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="offering-five-img">
              <img src="@/assets/img/offering-worker.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /How It Works -->

  <!-- Featured Services -->
  <section class="feature-category-section-five">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading car-wash-heading aos" data-aos="fade-up">
            <div class="car-wash-img-five">
              <img
                src="@/assets/img/icons/car-wash-header-1.svg"
                alt=""
                class="car-wash-header-one"
              />
              <h2>Featured Services</h2>
              <img
                src="@/assets/img/icons/car-wash-header-2.svg"
                alt=""
                class="car-wash-header-two"
              />
            </div>
            <p>What do you need to find?</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel feature-service-five-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexfivecategories" :key="item.id">
                <div class="service-widget service-widget-space aos" data-aos="fade-up">
                  <div class="service-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="fav-item">
                      <div class="features-service-five">
                        <div class="features-service-rating">
                          <i class="fa-solid fa-star filled me-1"></i><span>4.8</span>
                        </div>
                        <h6>Featured</h6>
                      </div>
                      <a
                        href="javascript:void(0)"
                        class="fav-icon fav-icon-five"
                        @click="toggleSelected"
                      >
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="service-content service-feature-five">
                    <h3 class="title">
                      <router-link to="/service-details"
                        >Basic Washing - Compact SUVs</router-link
                      >
                    </h3>
                    <p><i class="feather-map-pin"></i>New Jersey, USA</p>
                    <div class="feature-services-five">
                      <h6>$50.00</h6>
                      <span>$35.00</span>
                    </div>
                    <div class="feature-service-botton feature-service-botton-five">
                      <div class="feature-service-btn">
                        <a href="javascript:void(0)">Book Service</a>
                      </div>
                      <img
                        :src="require(`@/assets/img/profiles/${item.avatar}`)"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Featured Services -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfivecategories from "@/assets/json/indexfivecategories.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexfivecategories: indexfivecategories,
      isFavourite: false,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    toggleSelected(event) {
      const element = event.target;
      element.classList.toggle("selected");
    },
  },
};
</script>
