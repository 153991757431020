<template>
    <div class="main-wrapper">
        <indexeightheader />

        <div class="page-wrapper">

            <indexeightbanner />

            <indexeightpopular />

            <indexeightmeet />

            <indexeightcustomer />

            <indexeightblog />

            <indexeightfooter />

            <scroll />
        </div>
    </div>
</template>