<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <sidelinkheading />

      <div class="content w-100">
        <div class="content-page-header">
          <h5>Security</h5>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="connectetapps">
              <div class="connectetappsimg">
                <div class="connectet-img">
                  <img src="@/assets/admin_img/icons/lock.svg" alt="img" />
                </div>
                <div class="connectet-content">
                  <h3>Password</h3>
                  <p>Last Changed <span> 22 Sep 2023, 10:30:55 AM</span></p>
                </div>
              </div>
              <div class="connectetappscontent">
                <button
                  type="button"
                  class="btn btn-linedark"
                  data-bs-toggle="modal"
                  data-bs-target="#change-password"
                >
                  Change Password
                </button>
              </div>
            </div>
            <div class="connectetapps">
              <div class="connectetappsimg">
                <div class="connectet-img">
                  <img src="@/assets/admin_img/icons/scan.svg" alt="img" />
                </div>
                <div class="connectet-content">
                  <div class="d-flex align-items-center">
                    <h3>Two Factor Authendication</h3>
                    <h6 class="success-labels ms-3">Connect</h6>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                </div>
              </div>
              <div class="connectetappscontent">
                <a href="javascript:void(0);" class="btn btn-linedark">Disable</a>
              </div>
            </div>
            <div class="connectetapps">
              <div class="connectetappsimg">
                <div class="connectet-img">
                  <img src="@/assets/admin_img/icons/mobile.svg" alt="img" />
                </div>
                <div class="connectet-content">
                  <h3>Phone Number Verification</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                </div>
              </div>
              <div class="connectetappscontent">
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="text-danger"
                      ><i class="fa fa-times-circle" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0);"
                      class="btn btn-change"
                      data-bs-toggle="modal"
                      data-bs-target="#change-no"
                      >Change</a
                    >
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="btn btn-removes">Remove</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="connectetapps">
              <div class="connectetappsimg">
                <div class="connectet-img">
                  <img src="@/assets/admin_img/icons/email.svg" alt="img" />
                </div>
                <div class="connectet-content">
                  <h3>Email Verification</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                </div>
              </div>
              <div class="connectetappscontent">
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="text-success"
                      ><i class="fa fa-check-circle" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0);"
                      class="btn btn-change"
                      data-bs-toggle="modal"
                      data-bs-target="#change-email"
                      >Change</a
                    >
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="btn btn-removes">Remove</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="connectetapps">
              <div class="connectetappsimg">
                <div class="connectet-img">
                  <img src="@/assets/admin_img/icons/monitor.svg" alt="img" />
                </div>
                <div class="connectet-content">
                  <h3>Device Management</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                </div>
              </div>
              <div class="connectetappscontent">
                <router-link to="/admin/device-management" class="btn btn-linedark"
                  >Manage</router-link
                >
              </div>
            </div>
            <div class="connectetapps">
              <div class="connectetappsimg">
                <div class="connectet-img">
                  <img src="@/assets/admin_img/icons/user-edit.svg" alt="img" />
                </div>
                <div class="connectet-content">
                  <h3>Account Activity</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur</p>
                </div>
              </div>
              <div class="connectetappscontent">
                <router-link to="/admin/login-activity" class="btn btn-linedark"
                  >View All</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="btn-path">
              <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
              <a href="javascript:void(0);" class="btn btn-primary">Save Changes</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <security-model></security-model>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
      showPassword1: false,
      password1: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
