<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content w-100 ps-0 pt-0">
        <sidelinkheading />

        <div class="row">
          <div class="content-table">
            <div class="content-page-header content-page-headersplit">
              <h5>Languages</h5>
              <div class="page-head-btn">
                <a class="btn btn-primary me-2"
                  ><i class="feather feather-download-cloud me-2"></i>Web Export</a
                >
                <a class="btn btn-primaryline"
                  ><i class="feather feather-download-cloud me-2"></i>App Export</a
                >
              </div>
            </div>
            <div class="col-12">
              <div class="table-resposnive table-bottom">
                <table class="table datatable">
                  <a-table :columns="columns" :data-source="data">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'Language'">
                        <div class="language-set">
                          <img
                            :src="require(`@/assets/admin_img/flags/${record.Image}`)"
                            alt="img"
                          />
                          <span>{{ record.Language }}</span>
                        </div>
                      </template>
                      <template v-else-if="column.key === 'RTL'">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </template>
                      <template v-else-if="column.key === 'Default_Language'">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </template>
                      <template v-else-if="column.key === 'Status'">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </template>
                      <template v-else-if="column.key === 'Action'">
                        <div class="action-language">
                          <ul>
                            <li>
                              <a class="btn btn-grey" href="javascript:void(0);"
                                ><img
                                  src="@/assets/admin_img/icons/lanuage.svg"
                                  class="me-2"
                                  alt="img"
                                />Web</a
                              >
                            </li>
                            <li>
                              <a class="btn btn-grey" href="javascript:void(0);"
                                ><img
                                  src="@/assets/admin_img/icons/lanuage.svg"
                                  class="me-2"
                                  alt="img"
                                />App</a
                              >
                            </li>
                            <li>
                              <a class="btn btn-grey" href="javascript:void(0);"
                                ><img
                                  src="@/assets/admin_img/icons/lanuage.svg"
                                  class="me-2"
                                  alt="img"
                                />Admin</a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0);" class="delete-table"
                                ><i class="far fa-edit"></i
                              ></a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                class="delete-table"
                                data-bs-toggle="modal"
                                data-bs-target="#delete"
                                ><i class="far fa-trash-alt"></i
                              ></a>
                            </li>
                          </ul>
                        </div>
                      </template>
                      <template v-else-if="column.key === 'Import'">
                        <div class="action-language">
                          <ul>
                            <li>
                              <a class="btn btn-primary" href="javascript:void(0);"
                                ><img
                                  src="@/assets/admin_img/icons/lanuage1.svg"
                                  class="me-2"
                                  alt="img"
                                />Web</a
                              >
                            </li>
                            <li>
                              <a class="btn btn-primary" href="javascript:void(0);"
                                ><img
                                  src="@/assets/admin_img/icons/lanuage1.svg"
                                  class="me-2"
                                  alt="img"
                                />App</a
                              >
                            </li>
                          </ul>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Languages</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to Delete?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Language",
    dataIndex: "Language",
    key: "Language",
    sorter: {
      compare: (a, b) => {
        a = a.Language.toLowerCase();
        b = b.Language.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Code",
    dataIndex: "Code",
    sorter: {
      compare: (a, b) => {
        a = a.Code.toLowerCase();
        b = b.Code.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "RTL",
    dataIndex: "RTL",
    key: "RTL",
    sorter: {
      compare: (a, b) => {
        a = a.RTL.toLowerCase();
        b = b.RTL.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Default Language",
    dataIndex: "Default_Language",
    key: "Default_Language",
    sorter: {
      compare: (a, b) => {
        a = a.Default_Language.toLowerCase();
        b = b.Default_Language.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    dataIndex: "Action",
    key: "Action",
    sorter: {
      compare: (a, b) => {
        a = a.Action.toLowerCase();
        b = b.Action.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Import",
    dataIndex: "Import",
    key: "Import",
    sorter: {
      compare: (a, b) => {
        a = a.Import.toLowerCase();
        b = b.Import.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    id: "1",
    Image: "us1.png",
    Language: "English",
    Code: "en",
    RTL: "",
    Default_Language: "",
    Status: "",
    Action:
      "Web                                                                                                                                                                App                                                                                                                                                                Admin",
    Import:
      "Web                                                                                                                                                                App",
  },
  {
    id: "2",
    Image: "ae1.png",
    Language: "Arabic",
    Code: "ar",
    RTL: "",
    Default_Language: "",
    Status: "",
    Action:
      "Web                                                                                                                                                               App                                                                                                                                                                Admin",
    Import:
      "Web                                                                                                                                                                App",
  },
];
export default {
  data() {
    return {
      columns,
      data,
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/language");
    },
  },
};
</script>
