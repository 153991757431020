<template>
  <div class="addcoupon">
    <!-- Add Coupon -->
    <div class="modal fade custom-modal" id="add-coupon">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content doctor-profile">
          <div class="modal-header border-bottom-0 justify-content-between">
            <h5 class="modal-title">New Offer</h5>
            <button
              type="button"
              class="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form action="javascript:;">
              <div class="wallet-add">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="col-form-label">Offer Type</label>
                      <vue-select :options="Type" placeholder="Select Type" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Start Date</label>
                      <div class="form-icon">
                        <datepicker
                          v-model="startdate"
                          :input-format="dateFormat"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder="Select Date"
                        />
                        <span class="cus-icon"><i class="feather-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">End Date</label>
                      <div class="form-icon">
                        <datepicker
                          v-model="startdate"
                          :input-format="dateFormat"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder="Select Date"
                        />
                        <span class="cus-icon"><i class="feather-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-submit text-end">
                  <a
                    href="javascript:;"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >Cancel</a
                  >
                  <b-button type="submit" variant="primary">Submit</b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Coupon -->

    <!-- Edit Coupon -->
    <div class="modal fade custom-modal" id="edit-coupon">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content doctor-profile">
          <div class="modal-header border-bottom-0 justify-content-between">
            <h5 class="modal-title">Edit Offer</h5>
            <button
              type="button"
              class="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form action="javascript:;">
              <div class="wallet-add">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="col-form-label">Offer Type</label>
                      <vue-select :options="Type" id="fixed" placeholder="Select Type" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">Start Date</label>
                      <div class="form-icon">
                        <datepicker
                          v-model="startdate"
                          :input-format="dateFormat"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder="Add Date"
                        />
                        <span class="cus-icon"><i class="feather-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="col-form-label">End Date</label>
                      <div class="form-icon">
                        <datepicker
                          v-model="startdate1"
                          :input-format="dateFormat"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder="Add Date"
                        />
                        <span class="cus-icon"><i class="feather-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-submit text-end">
                  <a href="javascript:;" class="btn btn-secondary me-2" data-bs-dismiss="modal"
                    >Cancel</a
                  >
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Coupon -->
  </div>
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  data() {
    return {
      Type: ["Select Type", "fixed"],
      startdate: currentDate,
      startdate1: currentDateOne,
      Type: ["Select Type", "fixed"],
      dateFormat: "dd-MM-yyyy",
    };
  },
};
</script>
