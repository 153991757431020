import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        getIncentiveDriver(_, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/x/get-incentive-driver-from-report-driver/${id}`).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        createIncentiveDriver(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/x/create-incentive-driver', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getAbsenDriver(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/absen', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getAbsenDriverCurrent(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/absen/current', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addAbsenDriver(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/absen/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateAbsenDriver(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('absen/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        showAbsenDriver(_, id) {
            return new Promise((resolve, reject) => {
              axios.get(`/absen/show/${id}`).then(resp => {
                resolve(resp.data);
              }).catch(e => {
                reject(e);
              });
            });
        },

    },
    getters: {

    }

}