<template>
    <div class="addition-service card-section space-service">
        <div class="heading-service">
            <h4>SEO</h4>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Meta Title (English)</label>
                    <input type="text" class="form-control" placeholder="Enter metatitle">
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group meta-keys">
                    <label>Meta Keywords (English)</label>
                    <input class="input-tags form-control" type="text" data-role="tagsinput" name="specialist"
                        placeholder="Enter keywords" id="specialist">
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label>Meta Description (English)</label>
                    <textarea class="form-control" placeholder="Enter description" rows="5"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>