<template>
    <!-- Footer -->
    <footer class="footer footer-nine">
        <!-- Footer Top -->
        <div class="footer-top aos" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <router-link to="/"><img src="@/assets/img/logo-02.svg" alt="logo"></router-link>
                            </div>
                            <div>
                                <p>
                                    We ara a lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, Ut enim ad minim veniam, quis nostrud
                                    equip consectetur adipiscing... <a href="javascript:void(0);" class="read-more">Read
                                        More</a>
                                </p>
                            </div>
                            <!-- Footer Widget -->
                            <div class="footer-widget footer-contact">
                                <div class="footer-contact-info">
                                    <div class="footer-address">
                                        <span><i class="feather-phone-call"></i></span>
                                        <div>
                                            <h6>Phone Number</h6>
                                            <p>310-437-2766</p>
                                        </div>
                                    </div>
                                    <div class="footer-address">
                                        <span><i class="feather-mail"></i></span>
                                        <div>
                                            <h6>Mail Address</h6>
                                            <p>support@truelysell.com</p>
                                        </div>
                                    </div>
                                    <div class="footer-address">
                                        <span><i class="feather-map-pin"></i></span>
                                        <div>
                                            <h6>Address</h6>
                                            <p>706 Campfire Ave. Meriden, CT 06450</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Footer Widget -->
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="footer-widget footer-menu">
                                    <h2 class="footer-title">About</h2>
                                    <ul>
                                        <li>
                                            <router-link to="about-us">About Us</router-link>
                                        </li>
                                        <li>
                                            <router-link to="blog-details">Blog</router-link>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">Careers</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">Jobs</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">In Press</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Gallery</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="footer-widget footer-menu">
                                    <h2 class="footer-title">Support</h2>
                                    <ul>
                                        <li>
                                            <router-link to="contact-us">Contact us</router-link>
                                        </li>
                                        <li>
                                            <router-link to="customer-chat">Online Chat</router-link>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">Whatsapp</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">Telegram</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">Ticketing</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">Call Center</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <ul class="social-media-icon-img">
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i class="fa-brands fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i class="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i class="fa-brands fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i class="fa-brands fa-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">
                                            <i class="fa-brands fa-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Popular Tags</h2>
                            <ul class="footer-tags">
                                <li><a href="javascript:void(0);">FILTERS</a></li>
                                <li><a href="javascript:void(0);">BRAKES</a></li>
                                <li><a href="javascript:void(0);">DIAGNOSTICS</a></li>
                                <li><a href="javascript:void(0);">STEERING</a></li>
                                <li><a href="javascript:void(0);">BELTS</a></li>
                                <li><a href="javascript:void(0);">SUSPENSION</a></li>
                                <li><a href="javascript:void(0);">OILS</a></li>
                                <li><a href="javascript:void(0);">TIRES</a></li>
                                <li><a href="javascript:void(0);">ENGINE</a></li>
                                <li><a href="javascript:void(0);">STEERING</a></li>
                                <li><a href="javascript:void(0);">TIRES</a></li>
                                <li><a href="javascript:void(0);">ENGINE</a></li>
                                <li><a href="javascript:void(0);">STEERING</a></li>
                                <li><a href="javascript:void(0);">OILS</a></li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top -->

        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <!-- Copyright -->
                <div class="copyright">
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div class="copyright-text text-center">
                                <p class="mb-0">
                                    Copyright 2023 &copy; TruelySell. All rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Copyright -->
            </div>
        </div>
        <!-- /Footer Bottom -->
    </footer>
    <!-- /Footer -->
</template>