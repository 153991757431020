<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="content-page-header content-page-headersplit mb-3">
            <h5>Detail Kendaraan</h5>
          </div>
          <div class="row">
            <div class="col-12">
                <b-tabs content-class="mt-3">
                    <b-tab title="Detail" active>
                      <div class="row">
                        <div class="col-12 text-end mb-3">
                          <button
                            class="btn btn-primary me-2"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#add-vehicle-doc"
                          >
                            <i class="fa fa-image me-2"></i>Tambah Gambar
                          </button>
                          <button
                            class="btn btn-primary"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-vehicle"
                            @click="openEditModal(dataDetail)"
                          >
                            <i class="fa fa-edit me-2"></i>Edit Kendaraan
                          </button>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-6">
                              <div class="mb-3 form-group">
                                <div class="d-flex">
                                    <div style="margin-right: 10px;">
                                        <label class="form-label">Name</label>
                                        <input v-if="dataDetail !== undefined" type="text" class="form-control" v-model="dataDetail.name" disabled/>
                                    </div>
                                    <div>
                                        <label class="form-label">Nomer Plat</label>
                                        <input v-if="dataDetail !== undefined" type="text" class="form-control" v-model="dataDetail.nomor_plat" disabled/>
                                    </div>
                                </div>
                              </div>
                              <div class="mb-3">
                              <div class="form-group">
                                  <div class="row">
                                      <div class="col-lg-6">
                                          <div class="split-check-title">
                                              <h6>Transmisi</h6>
                                          </div>
                                      </div>
                                      <div class="col-lg-6">
                                          <div class="split-check-box">
                                              <ul class="custom-radiosbtn">
                                                  <li>
                                                      <label class="radiossets">Manual
                                                          <input v-if="dataDetail !== undefined" :checked="dataDetail.transmisi === 'Manual'" type="radio" name="transmisi" value="Manual" v-model="dataDetail.transmisi" disabled>
                                                          <span class="checkmark-radio"></span>
                                                      </label>
                                                  </li>
                                                  <li>
                                                      <label class="radiossets">Matic
                                                          <input v-if="dataDetail !== undefined" :checked="dataDetail.transmisi === 'Matic'" type="radio" name="transmisi" value="Matic" v-model="dataDetail.transmisi" disabled>
                                                          <span class="checkmark-radio"></span>
                                                      </label>
                                                  </li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              </div>
                              <div class="mb-3 form-group">
                              <div class="d-flex">
                                  <div style="margin-right: 10px;">
                                      <label class="form-label">Capacity</label>
                                      <input v-if="dataDetail !== undefined" type="number" class="form-control" v-model="dataDetail.capacity" disabled/>
                                  </div>
                                  <div>
                                      <label class="form-label">Production Year</label>
                                      <input v-if="dataDetail !== undefined" type="text" class="form-control" v-model="dataDetail.production_year" disabled/>
                                  </div>
                                  </div>
                              </div>
                              <div class="mb-3 form-group">
                                  <div class="d-flex">
                                  <div style="margin-right: 10px;">
                                      <label class="form-label">Nomer STNK</label>
                                      <input v-if="dataDetail !== undefined" type="text" class="form-control" v-model="dataDetail.nomor_stnk" disabled/>
                                  </div>
                                  <div>
                                      <label class="form-label">Warna</label>
                                      <input v-if="dataDetail !== undefined" type="text" class="form-control" v-model="dataDetail.color" disabled/>
                                  </div>
                                  </div>
                              </div>
                              <div class="mb-3 form-group">
                              <div class="d-flex">
                                <div style="margin-right: 10px;">
                                  <label class="form-label">Brand</label>
                                    <select v-if="dataDetail !== undefined" class="form-select" v-model="dataDetail.brand_id" disabled>
                                        <option v-for="brand in dataBrand" :key="brand.id" :value="brand.id" :selected="brand.id === dataDetail.brand_id">{{ brand.name }}</option>
                                    </select>
                                </div>
                                <div>
                                  <label class="form-label">Vendor</label>
                                    <select v-if="dataDetail !== undefined" class="form-select" v-model="dataDetail.vendor_id" disabled>
                                        <option v-for="vendor in dataVendor" :key="vendor.id" :value="vendor.id" :selected="vendor.id === dataDetail.vendor_id">{{ vendor.full_name }}</option>
                                    </select>
                                </div>
                              </div>
                            </div>
                              <div class="mb-3 form-group">
                                  <div class="d-flex">
                                      <div style="margin-right: 10px;">
                                      <label class="form-label">BBM Type</label>
                                      <input v-if="dataDetail !== undefined" type="text" class="form-control" v-model="dataDetail.bbm_type" disabled/>
                                      </div>
                                      <div>
                                      <label class="form-label">Harga BBM Per Liter</label>
                                      <input v-if="dataDetail !== undefined" type="number" class="form-control" v-model="dataDetail.bbm_per_liter" disabled/>
                                      </div>
                                  </div>
                              </div>
                              <div class="mb-3 form-group">
                                  <div class="d-flex">
                                      <div style="margin-right: 10px;">
                                      <label class="form-label">Tanggal Terakhir Ganti Oli</label>
                                      <input v-if="dataDetail !== undefined" type="datetime-local" class="form-control"  v-model="dataDetail.date_last_oil" disabled/>
                                      </div>
                                      <div>
                                      <label class="form-label">Tanggal Terakhir Service</label>
                                      <input v-if="dataDetail !== undefined" type="datetime-local" class="form-control" v-model="dataDetail.date_last_service" disabled/>
                                      </div>
                                  </div>
                              </div>
                              <div class="mb-3">
                                  <label class="form-label">Catatan</label>
                                  <input v-if="dataDetail !== undefined" type="text" class="form-control" v-model="dataDetail.remark" disabled/>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="mb-3">
                                <div class="profile-upload-content">
                                    <img v-if="(dataDetail !== undefined) && (dataDetail.image !== '')" :src="dataDetail.image" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                    <img v-else :src="require(`@/assets/admin_img/customer/user-01.jpg`)" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                </div>
                              </div>
                              <div class="mb-3">
                                <label class="form-label">Gambar Dokumen Tambahan</label>
                                <div v-if="dataDetail !== undefined" v-for="(item, index) in dataDetail.doc" :key="index" class="mb-3">
                                    <div class="profile-upload-content" style="display: flex; align-items: center;">
                                        <div style="flex: 1;">
                                            <img v-if="item.file !== ''" :src="item.file" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                            <img v-else :src="require(`@/assets/admin_img/customer/user-01.jpg`)" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                        </div>
                                        <button @click="deleteDoc(item)" class="btn btn-danger" style="padding: 0.1rem 0.1rem;">❌</button> <!-- Adjusted button size -->
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Jenis Sewa">
                      <div class="row">
                        <div class="col-12 text-end mb-3">
                          <button
                            class="btn btn-primary"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#add-product-vehicle"
                          >
                            <i class="fa fa-plus me-2"></i>Tambah Jenis Sewa
                          </button>
                        </div>
                        <div class="col-12">
                          <!-- <div class="table-resposnive table-div">
                            <div class="table datatable">
                              <a-table :columns="columnsSewa" :data-source="dataSewa" :pagination="false">
                                <template #bodyCell="{ column, record }">
                                  <template v-if="column.key === 'status'">
                                    <h6 v-if="record.status === 1" class="badge-active">Aktif</h6>
                                    <h6 v-else class="badge-active">Tidak Aktif</h6>
                                  </template>
                                  <template v-if="column.key === 'type'">
                                    <h6 v-if="record.type === 1" class="badge-active">Tarif Per Jam</h6>
                                    <h6 v-else-if="record.type === 2" class="badge-active">Tarif Per Hari</h6>
                                    <h6 v-else class="badge-active">??</h6>
                                  </template>
                                  <template v-if="column.key === 'id'">
                                    <div class="table-actions d-flex">
                                        <button
                                        class="btn delete-table me-2"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#show-product"
                                        @click="openEditModal(record)"
                                      >
                                        <i class="feather feather-eye"></i>
                                      </button>
                                      <button
                                        class="btn delete-table me-2"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#edit-product"
                                        @click="openEditModal(record)"
                                      >
                                        <i class="feather feather-edit"></i>
                                      </button>
                                      <button
                                        class="btn delete-table"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete-product"
                                        @click="openDeleteModal(record)"
                                      >
                                        <i class="feather feather-trash-2"></i>
                                      </button>
                                    </div>
                                  </template>
                                </template>
                              </a-table>
                            </div>
                          </div> -->
                          <table class="table">
                              <thead>
                              <tr>
                                  <th>Jenis</th>
                                  <th>Tarif</th>
                                  <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                                  <tr v-if="dataDetail !== undefined" v-for="(item, index) in dataDetail.vehicle_prices" :key="index">
                                      <td class="mb-3">
                                          <select v-model="item.product_id" class="form-select" disabled>
                                              <option v-for="option in dataProduct" :value="option.id">{{ option.name }}</option>
                                          </select>
                                      </td>
                                      <td class="mb-3">
                                          <input v-model.number="item.price" class="form-control" type="number" placeholder="Item Price" disabled>
                                      </td>
                                      <td>
                                        <div class="table-actions d-flex">
                                        <button
                                          class="btn delete-table me-2"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#edit-product-vehicle"
                                          @click="openEditprice(item)"
                                        >
                                          <i class="feather feather-edit"></i>
                                        </button>
                                        <button
                                          class="btn delete-table"
                                          type="button"
                                          data-bs-toggle="modal"
                                          data-bs-target="#delete-product-vehicle"
                                          @click="openEditprice(item)"
                                        >
                                          <i class="feather feather-trash-2"></i>
                                        </button>
                                      </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Riwayat Order">
                      <div class="row">
                        <div class="col-12 text-end mb-3">
                          <button
                            class="btn btn-primary"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#add-order2"
                            @click="openCreateModal()"
                          >
                            <i class="fa fa-plus me-2"></i>Tambah Order
                          </button>
                        </div>
                        <div class="col-12">
                          <h5>Riwayat Order</h5>
                          <div class="table-container" style="overflow-x: auto;">
                            <a-table :columns="columns" :data-source="dataOrder" :pagination="pagination">
                              <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'Status'">
                                  <h6 v-if="record.status === 'AKTIF'" class="badge-active">{{ record.status }}</h6>
                                  <h6 v-else class="badge-active">{{ record.status }}</h6>
                                </template>
                                <template v-if="column.key === 'id'">
                                  <div class="table-actions d-flex">
                                      <button
                                      class="btn delete-table me-2"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#show-order"
                                      @click="openEditModal(record)"
                                    >
                                      <i class="feather feather-eye"></i>
                                    </button>
                                    <button
                                      class="btn delete-table me-2"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#edit-order"
                                      @click="openEditModal(record)"
                                    >
                                      <i class="feather feather-edit"></i>
                                    </button>
                                    <button
                                      class="btn delete-table"
                                      type="button"
                                      data-bs-toggle="modal"
                                      data-bs-target="#delete-order"
                                      @click="openDeleteModal(record)"
                                    >
                                      <i class="feather feather-trash-2"></i>
                                    </button>
                                  </div>
                                </template>
                              </template>
                            </a-table>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Grafik Order">
                      <div class="card">
                        <div class="card-body">
                            <div class="home-user">
                            <div class="home-head-user">
                                <h2>Grafik Riwayat Sewa</h2>
                            </div>
                            <div class="chartgraph">
                                <apexchart
                                type="bar"
                                height="350"
                                :options="barChart.chart"
                                :series="barChart.series"
                                >
                                </apexchart>
                            </div>
                            </div>
                        </div>
                      </div>
                    </b-tab>
                </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <order-model :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :customerData="dataCustomer" :vehicleData="dataVehicle" :userData="dataUser" @dataUpdated="getData" :dataId="dataId"></order-model>
    <vehicle-model :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" @dataUpdated="getData" :dataId="dataId" :editPrice="editPrice" :brandData="dataBrand" :vendorData="dataVendor"></vehicle-model>
    <product-model :editData="editData" :deleteItemId="itemIdToDelete" @dataUpdated="getData"></product-model>
  </template>
  
  <script>
  const barChart = {
    colors: ['#4169E1'],
    series: [
      {
        name: "Received",
        type: "column",
        data: []
      }
    ],
    chart: {
      id: "vuechart-example",
      type: 'bar',
      fontFamily: 'Poppins, sans-serif',
      height: 350,
      toolbar: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          endingShape: 'rounded'
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      },
      yaxis: {
        title: {
          text: 'Jumlah Order'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    },
  };
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "No Order",
      dataIndex: "no_order",
      key: "no_order",
      sorter: {
        compare: (a, b) => {
          a = a.no_order.toLowerCase();
          b = b.no_order.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "customerName",
      sorter: {
        compare: (a, b) => {
          a = a.customerName.toLowerCase();
          b = b.customerName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      sorter: {
        compare: (a, b) => {
          a = a.productName.toLowerCase();
          b = b.productName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Starting Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        compare: (a, b) => {
          a = a.startDate.toLowerCase();
          b = b.startDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Starting Time",
      dataIndex: "startTime",
      key: "startTime",
      sorter: {
        compare: (a, b) => {
          a = a.startTime.toLowerCase();
          b = b.startTime.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Finish Date",
      dataIndex: "finishDate",
      key: "finishDate",
      sorter: {
        compare: (a, b) => {
          a = a.finishDate.toLowerCase();
          b = b.finishDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vehicleName",
      key: "vehicleName",
      sorter: {
        compare: (a, b) => {
          a = a.vehicleName.toLowerCase();
          b = b.vehicleName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  const columnsSewa = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Jenis Sewa",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: {
        compare: (a, b) => {
          a = a.code.toLowerCase();
          b = b.code.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: {
        compare: (a, b) => {
          a = a.type.toLowerCase();
          b = b.type.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      sorter: {
        compare: (a, b) => {
          a = a.duration.toLowerCase();
          b = b.duration.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    data() {
      return {
        columns,
        columnsSewa,
        dataOrder: [],
        dataSewa: [],
        vehicleId: 0,
        dataDetail : {},
        barChart: barChart,
        Select: ["A -> Z", "Z -> A"],
        params: {
          row: 10,
          keyword: "",
          sortby: "id",
          sorttype: "asc",
          page: 1
        },
        paramUser: {
            row: 10,
            keyword: "Driver",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        editPrice: {},
        itemIdToDelete: {},
        dataCustomer: [],
        dataId: null,
        dataProduct: [],
        dataVehicle: [],
        dataBrand: [],
        dataVendor: [],
        dataUser: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
      };
    },
    name: "vehiclesetting",
    computed: {

    },
    methods: {
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/showVehicle", this.vehicleId)
                .then((resp) => {
                    this.dataDetail = resp.data;
                    console.log(this.dataDetail);
                    loading.hide();
                    this.dataOrder = this.dataDetail.orders.map((item, index) => {
                        const sDate = new Date(item.starting_date);
                        const fDate = new Date(item.finish_date);
                        const time = new Date(item.starting_time);
                        const startTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        const vehicle = this.dataDetail;
                        const vehicleName = vehicle ? vehicle.name : '';
                        const product = this.dataProduct.find(product => product.id === item.product_id);
                        const productName = product ? product.name : '';
                        const customer = this.dataCustomer.find(customer => customer.id === item.customer_id);
                        const customerName = customer ? customer.name : '';
                        return {
                            ...item,
                            index: index + 1,
                            startDate: sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear(),
                            finishDate: fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear(),
                            startTime,
                            vehicleName,
                            productName,
                            customerName
                        };
                    });
                    this.dataSewa = this.dataDetail.vehicle_prices.map((item, index) => ({
                      ...item.products,
                      index: index + 1 
                    }));
                    this.barChart.series[0].data = this.orderCountPerMonth();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        openEditModal(data) {
          this.editData = { data };
        },
        openDeleteModal(data) {
          this.itemIdToDelete = { data };
        },
        openCreateModal() {
          this.dataId = this.vehicleId;
        },
        openEditprice(data) {
          this.editPrice = { data };
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params)
                .then((resp) => {
                    this.dataProduct = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getCustomer() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("customer/getCustomer", this.params)
                .then((resp) => {
                    this.dataCustomer = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getUser() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("user/getUser", this.paramUser)
                .then((resp) => {
                    this.dataUser = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVehicle() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params)
                .then((resp) => {
                    this.dataVehicle = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        orderCountPerMonth() {
          const ordersByMonth = {
            1: 0,  // January
            2: 0,  // February
            3: 0,  // March
            4: 0,  // April
            5: 0,  // May
            6: 0,  // June
            7: 0,  // July
            8: 0,  // August
            9: 0,  // September
            10: 0, // October
            11: 0, // November
            12: 0  // December
          };
          if (this.dataDetail && this.dataDetail.orders) {
            this.dataDetail.orders.forEach(order => {
              const month = new Date(order.starting_date).getMonth() + 1; 
              ordersByMonth[month]++;
            });
          }
          return Object.values(ordersByMonth);
        },
        deleteDoc(data) {
        const id = data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("vehicle/deleteDoc", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.getData();
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      getBrand() {
        var loading = this.$loading.show();
        this.$store
        .dispatch("brand/getBrand", this.params)
        .then((resp) => {
            this.dataBrand = resp.data.data;
            loading.hide();
        })
        .catch((e) => {
            this.$toast.error(e);
            loading.hide();
        });
      },
      getVendor() {
        var loading = this.$loading.show();
        this.$store
        .dispatch("vendor/getVendor", this.params)
        .then((resp) => {
            this.dataVendor = resp.data.data;
            loading.hide();
        })
        .catch((e) => {
            this.$toast.error(e);
            loading.hide();
        });
      },
    },
    mounted() {
        this.vehicleId = this.$route.params.id;
        this.dataId = this.vehicleId;
        this.getProduct();
        this.getCustomer();
        this.getUser();
        this.getVehicle();
        this.getData();
        this.getVendor();
        this.getBrand();
    },
  };
  </script>
  <style>
  .ant-select-selection-item {
    height: 30px;
    width: 90px;
    margin-top: -6px;
    font-size: 15px;
  }
  </style>
