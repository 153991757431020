<template>
    <div class="card add-service">
        <div class="row mt-4">
            <div class="col-12">
                <form>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                            <div class="col-6" style="margin-right: 10px;">
                                <label class="form-label">Jenis Sewa</label>
                                <select class="form-control" v-model="data.rental_type">
                                    <option value="1">Sewa Lepas Kunci</option>
                                    <option value="2">Sewa Dengan Driver</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <label class="form-label">Nama Sewa</label>
                                <input type="text" class="form-control" v-model="data.name"/>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                            <div class="col-6" style="margin-right: 10px;">
                                <label class="form-label">Kode Sewa</label>
                                <input type="text" class="form-control" v-model="data.code"/>
                            </div>
                            <div class="col-6">
                                <label class="form-label">Satuan Durasi</label>
                                <select class="form-control" v-model="data.type">
                                    <option value="1">Per Jam</option>
                                    <option value="2">Per Hari</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-group">
                            <div class="d-flex">
                                <div class="col-6" style="margin-right: 10px;">
                                    <label class="form-label">Durasi</label>
                                    <input type="number" class="form-control" v-model="data.duration"/>
                                </div>
                                <div class="col-6">
                                    <div class="col-lg-12">
                                        <label class="form-label">Status</label>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-check form-check-inline">
                                            <input v-if="data !== undefined" class="form-check-input" type="radio" id="inlineRadio1" name="status" value="true" v-model="data.status">
                                            <label class="form-check-label" for="inlineRadio1">AKTIF</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input v-if="data !== undefined" class="form-check-input" type="radio" id="inlineRadio2" name="status" value="false" v-model="data.status">
                                            <label class="form-check-label" for="inlineRadio2">TIDAK AKTIF</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="field-bottom-btns">
                    <!-- <div class="field-btns">
                        <button class="btn btn-primary prev_btn" type="button"
                        @click="previousStep" :disabled="currentStep === 1"><i
                                class="fa-solid fa-arrow-left"></i>Prev</button>
                    </div> -->
                    <div class="field-btns">
                        <button class="btn btn-primary next_btn" type="button"
                        @click="save" :disabled="currentStep === totalSteps">Selanjutnya <i
                                class="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // mari
        data: {},
      };
    },
    props: {
        currentStep : Object,
        totalSteps : Object,
        idCompany : Object
    },
    methods: {
      save() {
        this.data.status = this.data.status === 'true' ? 1 : 0;
        var loading = this.$loading.show()
        this.$store
        .dispatch("product/addProduct", this.data )
        .then((data) => {
            if (data.status == 201) {
                this.updateGuide();
                loading.hide()
                this.data = {};
                this.$emit('getPaket');
                this.$emit('nextPaket');
            }
        },
        (error) => {
            console.log(error)
            loading.hide();
        })
        .catch((e) => {
            loading.hide()
        })
      },
      updateGuide() {
        this.$store
        .dispatch("guide/updateGuide", { id: this.idCompany, data: { guide_step : "is_product"} })
        .then((data) => {
            if (data.status == 200) {
                // loading.hide()
            }
        },
        (error) => {
            console.log(error)
            // loading.hide();
        })
        .catch((e) => {
            // loading.hide()
        })
      },
      previousStep() {
        this.$emit('previous');
      }
    },
  };
  </script>
  