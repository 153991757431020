<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content w-100 ps-0 pt-0">
        <sidelinkheading />

        <div class="row">
          <div class="content-table">
            <div class="col-lg-12">
              <div class="content-page-header content-space">
                <h5>Service Details</h5>
                <span>Enable and disable based on your service request or provider</span>
              </div>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="location-set">
                    <div class="row align-items-center">
                      <div class="col-lg-8 col-12">
                        <div class="service-detail-blk">
                          <span
                            ><img
                              src="@/assets/admin_img/icons/service-icon-01.svg"
                              alt="Icon"
                          /></span>
                          <div class="location-setcontent">
                            <h5>Manual location based search</h5>
                            <h6>Based on search</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 text-end">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="location-set">
                    <div class="row align-items-center">
                      <div class="col-lg-8 col-12">
                        <div class="service-detail-blk">
                          <span
                            ><img
                              src="@/assets/admin_img/icons/service-icon-02.svg"
                              alt="Icon"
                          /></span>
                          <div class="location-setcontent">
                            <h5>Auto approval for services</h5>
                            <h6>Based on service</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 text-end">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="location-set">
                    <div class="row align-items-center">
                      <div class="col-lg-8 col-12">
                        <div class="service-detail-blk">
                          <span
                            ><img
                              src="@/assets/admin_img/icons/service-icon-03.svg"
                              alt="Icon"
                          /></span>
                          <div class="location-setcontent">
                            <h5>Additional services</h5>
                            <h6>Based on service</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 text-end">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="location-set">
                    <div class="row align-items-center">
                      <div class="col-lg-8 col-12">
                        <div class="service-detail-blk">
                          <span
                            ><img
                              src="@/assets/admin_img/icons/service-icon-04.svg"
                              alt="Icon"
                          /></span>
                          <div class="location-setcontent">
                            <h5>Service reviews</h5>
                            <h6>Based on reviews</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 text-end">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="location-set">
                    <div class="row align-items-center">
                      <div class="col-lg-8 col-12">
                        <div class="service-detail-blk">
                          <span
                            ><img
                              src="@/assets/admin_img/icons/service-icon-05.svg"
                              alt="Icon"
                          /></span>
                          <div class="location-setcontent">
                            <h5>Booking option</h5>
                            <h6>Based on options</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 text-end">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="location-set">
                    <div class="row align-items-center">
                      <div class="col-lg-8 col-12">
                        <div class="service-detail-blk">
                          <span
                            ><img
                              src="@/assets/admin_img/icons/service-icon-06.svg"
                              alt="Icon"
                          /></span>
                          <div class="location-setcontent">
                            <h5>Service offered</h5>
                            <h6>What’s included in this service</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 text-end">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="location-set">
                    <div class="row align-items-center">
                      <div class="col-lg-8 col-12">
                        <div class="service-detail-blk">
                          <span
                            ><img
                              src="@/assets/admin_img/icons/service-icon-07.svg"
                              alt="Icon"
                          /></span>
                          <div class="location-setcontent">
                            <h5>Service availability</h5>
                            <h6>What’s included in this service</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 text-end">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" checked="" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="location-set mb-0">
                    <div class="row align-items-center">
                      <div class="col-lg-8 col-12">
                        <div class="service-detail-blk">
                          <span
                            ><img
                              src="@/assets/admin_img/icons/service-icon-08.svg"
                              alt="Icon"
                          /></span>
                          <div class="location-setcontent">
                            <h5>Provider details like email, phone, provider status</h5>
                            <h6>online/offline</h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-12 text-end">
                        <div class="active-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="sliders round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
