<template>
    <div class="provider-body">
        <div class="main-wrapper">
            <dashboardnavbar />

            <div class="page-wrapper">
                <sidebar />

                <providerholidaycontent />

                <provideraddholiday />

                <provideralertmessage />
            </div>
        </div>
    </div>
</template>