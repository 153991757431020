<template>
  <section class="meet-eight-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-eight aos" data-aos="fade-up">
            <img src="@/assets/img/icons/dog.svg" alt="" />
            <h2>Meet our professionals</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel professional-eight-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexeightmeet" :key="item.id">
                <div class="professional-eight-main">
                  <div class="professional-eight-img-ryt">
                    <img
                      :src="require(`@/assets/img/gallery/${item.image}`)"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="professional-eight-img-inside">
                    <a href="javascript:;">
                      <img src="@/assets/img/icons/dog-feet.svg" alt="" />
                      Read more
                    </a>
                  </div>
                  <router-link to="/service-details">
                    <h6>Caroline Williams</h6>
                  </router-link>
                  <span class="act">2 Years Experience</span>
                  <div class="rating">
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <span>(10)</span>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexeightmeet from "@/assets/json/indexeightmeet.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexeightmeet: indexeightmeet,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style>
.professional-eight-main {
  margin-right: 24px;
}
</style>
