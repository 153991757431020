<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Add Blog Category</h5>
              </div>
              <b-form @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Language</label>
                      <vue-select
                        :options="Languageadd"
                        id="langugaeadd"
                        placeholder="Select Language"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Category Name</label>
                      <b-form-input
                        type="text"
                        class="form-control"
                        placeholder="Enter Category Name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label
                        >Slug
                        <span
                          >(If you leave it empty, it will be generated
                          automatically.)</span
                        ></label
                      >
                      <b-form-input
                        type="text"
                        class="form-control"
                        placeholder="Enter Slug"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Description <span>(Meta Tag)</span></label>
                      <b-form-input
                        type="text"
                        class="form-control"
                        placeholder="Enter Description"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Keywords <span>(Meta Tag)</span></label>
                      <b-form-input
                        type="text"
                        class="form-control"
                        placeholder="Enter Keywords"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="btn-path">
                      <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                      <b-button type="submit" variant="primary">Add Category</b-button>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Languageadd: ["Select Language", "English", "German"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/blogs-categories");
    },
  },
};
</script>
