<template>
  <div class="row day-cont">
    <div class="col-md-6">
      <div class="form-group">
        <label class="col-form-label">From</label>
        <div class="form-icon">
          <a-time-picker
            v-model:value="value"
            use12-hours
            format="h:mm:ss A"
            class="form-control timepicker"
          />
          <span class="cus-icon"><i class="feather-clock"></i></span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex">
        <div class="form-group w-100">
          <label class="col-form-label">To</label>
          <div class="form-icon">
            <a-time-picker
              v-model:value="value1"
              use12-hours
              format="h:mm:ss A"
              class="form-control timepicker"
            />
            <span class="cus-icon"><i class="feather-clock"></i></span>
          </div>
        </div>
        <div class="form-group">
          <label class="col-form-label">&nbsp;</label>
          <a href="javascript:;" class="btn btn-danger-outline trash" @click="remove"
            ><i class="far fa-trash-alt"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "",
      value1: "",
    };
  },
  methods: {
    remove() {
      this.$emit("remove");
    },
  },
};
</script>
