import { createStore } from 'vuex';

import auth from './module/auth';
import role from './module/role';
import menu from './module/menu';
import acces from './module/acces';
import customer from './module/customer';
import product from './module/product';
import vehicle from './module/vehicle';
import order from './module/order';
import user from './module/user';
import company from './module/company';
import branch from './module/branch';
import papper from './module/papper';
import fines from './module/fines';
import expenditure from './module/expenditure';
import maintenance from './module/maintenance';
import brand from './module/brand';
import vendor from './module/vendor';
import guide from './module/guide';
import invoice from './module/invoice';
import inspection from './module/inspection';
import recap from './module/recap';
import reportDriver from './module/reportDriver';
import scheduler from './module/scheduler';
import driver from './module/driver';
import financeInvoice from './module/financeInvoice';

export default new createStore({
  modules: {
    auth,
    role,
    menu,
    acces,
    customer,
    product,
    vehicle,
    order,
    user,
    company,
    branch,
    papper,
    fines,
    expenditure,
    maintenance,
    brand,
    vendor,
    guide,
    invoice,
    inspection,
    recap,
    reportDriver,
    scheduler,
    driver,
    financeInvoice
  },
});
