<template>
  <!-- Featured Services -->
  <section class="feature-category-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading car-wash-heading aos" data-aos="fade-up">
            <div class="car-wash-img-five">
              <div class="car-wash-img-five">
                <img
                  src="@/assets/img/icons/car-wash-header-1.svg"
                  alt=""
                  class="car-wash-header-one"
                />
                <h2>Featured Services</h2>
                <img
                  src="@/assets/img/icons/car-wash-header-2.svg"
                  alt=""
                  class="car-wash-header-two"
                />
              </div>
            </div>
            <p>What do you need to find?</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12"
          v-for="item in indexfivefeaturedGrid"
          :key="item.id"
        >
          <div class="service-widget aos" data-aos="fade-up">
            <div class="service-img">
              <router-link to="/service-details">
                <img
                  class="img-fluid serv-img"
                  alt="Service Image"
                  :src="require(`@/assets/img/services/${item.image}`)"
                />
              </router-link>
              <div class="fav-item">
                <div class="features-service-five">
                  <div class="features-service-rating">
                    <i class="fa-solid fa-star filled me-1"></i
                    ><span>{{ item.rating }}</span>
                  </div>
                  <h6>Featured</h6>
                </div>
                <a
                  href="javascript:void(0)"
                  class="fav-icon fav-icon-five"
                  @click="toggleSelected"
                >
                  <i class="feather-heart"></i>
                </a>
              </div>
            </div>
            <div class="service-content service-feature-five">
              <h3 class="title">
                <router-link to="/service-details">{{ item.title }}</router-link>
              </h3>
              <p><i class="feather-map-pin"></i>{{ item.location }}</p>
              <div class="feature-services-five">
                <h6>{{ item.originalPrice }}</h6>
                <span>{{ item.discountedPrice }}</span>
              </div>
              <div class="feature-service-botton">
                <div class="feature-service-btn">
                  <router-link to="/service-details">Book Service</router-link>
                </div>
                <img
                  :src="require(`@/assets/img/profiles/${item.profileImage}`)"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-sec btn-sec-five aos" data-aos="fade-up">
        <router-link to="/search" class="btn btn-primary btn-view">View All</router-link>
      </div>
    </div>
  </section>
  <!-- /Featured Services -->

  <!-- Providers Section -->
  <section class="providers-section-five">
    <div class="providers-five-bg-car">
      <img src="@/assets/img/car-provider-bg.png" alt="" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading car-wash-heading aos" data-aos="fade-up">
            <div class="car-wash-img-five">
              <img
                src="@/assets/img/icons/car-wash-header-1.svg"
                alt=""
                class="car-wash-header-one"
              />
              <h2>Top Providers</h2>
              <img
                src="@/assets/img/icons/car-wash-header-2.svg"
                alt=""
                class="car-wash-header-two"
              />
            </div>
            <p>Meet Our Experts</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel top-providers-five">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexfivefeatured" :key="item.id">
                <div class="providerset">
                  <div class="providerset-img">
                    <router-link to="/provider-details">
                      <img
                        :src="require(`@/assets/img/provider/${item.image}`)"
                        class="img-fluid"
                        alt="img"
                      />
                    </router-link>
                  </div>
                  <div class="providerset-content">
                    <h4>
                      <router-link to="/provider-details">John Smith</router-link
                      ><i class="fa fa-check-circle" aria-hidden="true"></i>
                    </h4>
                    <h5>Electrician</h5>
                    <div class="providerset-prices">
                      <h6>$20.00<span>/hr</span></h6>
                    </div>
                    <div class="provider-rating">
                      <div class="rating">
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star filled me-1"></i>
                        <i class="fa-solid fa-star-half-stroke filled"></i
                        ><span>(320)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Providers Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfivefeatured from "@/assets/json/indexfivefeatured.json";
import indexfivefeaturedGrid from "@/assets/json/indexfivefeaturedgrid.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexfivefeatured: indexfivefeatured,
      indexfivefeaturedGrid: indexfivefeaturedGrid,
      isFavourite: false,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    toggleSelected(event) {
      const element = event.target;
      element.classList.toggle("selected");
    },
  },
};
</script>

<style>
.top-providers-five .providerset {
  margin-right: 24px;
}
</style>
