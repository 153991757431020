<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Announcements</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-announcement"
                >
                  <i class="fa fa-plus me-2"></i>Add Announcement
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Send_To'">
                      <span :class="record.Class">{{ record.Send_To }}</span>
                    </template>
                    <template v-else-if="column.key === 'Action'">
                      <div class="table-actions d-flex">
                        <button
                          class="btn delete-table me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-announcement"
                        >
                          <i class="feather feather-edit"></i>
                        </button>
                        <button
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <announcement-model></announcement-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Subject",
    dataIndex: "Subject",
    key: "Subject",
    sorter: {
      compare: (a, b) => {
        a = a.Subject.toLowerCase();
        b = b.Subject.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Message",
    dataIndex: "Message",
    key: "Message",
    sorter: {
      compare: (a, b) => {
        a = a.Message.toLowerCase();
        b = b.Message.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Send To",
    dataIndex: "Send_To",
    key: "Send_To",
    sorter: {
      compare: (a, b) => {
        a = a.Send_To.toLowerCase();
        b = b.Send_To.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "Action",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Subject: "Offer",
    Message: "Special offers for service booking",
    Send_To: "Provider",
    Date: "13 Oct 2023 12:17:00",
    Class: "badge bg-warning badge-inactive-one",
  },
  {
    id: "2",
    Subject: "New Features",
    Message: "Two Factor Authendication added",
    Send_To: "User",
    Date: "12 Oct 2023 12:17:00",
    Class: "badge bg-success badge-inactive-one",
  },
  {
    id: "3",
    Subject: "Promotion",
    Message: "Sales promotion offers for Provider",
    Send_To: "Provider",
    Date: "13 Oct 2023 12:17:00",
    Class: "badge bg-warning badge-inactive-one",
  },
  {
    id: "4",
    Subject: "Selling",
    Message: "Best selling service will be awarded",
    Send_To: "User",
    Date: "12 Oct 2023 12:17:00",
    Class: "badge bg-success badge-inactive-one",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      data,
      columns,
    };
  },
};
</script>
