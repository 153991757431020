<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <sidelinkheading />

      <div class="content w-100">
        <div class="content-page-header content-page-headersplit">
          <h5>Bank Transfer Account</h5>
          <div class="list-btn">
            <ul>
              <li>
                <router-link class="btn-filters active" to="/banktransfer"
                  ><i class="feather feather-list"></i>
                </router-link>
              </li>
              <li>
                <router-link
                  class="btn btn-primary"
                  to="/localization"
                  data-bs-toggle="modal"
                  data-bs-target="#add-account"
                  ><i class="fa fa-plus me-2"></i>Add Account
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="transfer-lists">
              <div class="theme-image-content">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
              </div>
              <div class="transfer-liststop">
                <div class="transfer-listbank">
                  <h3>Karur vysya bank</h3>
                  <h4>**** **** 1832</h4>
                </div>
                <div class="transfer-listsname">
                  <h5>Holder Name</h5>
                  <h6>John Smith</h6>
                </div>
              </div>
              <div class="transfer-listsbottom">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-account"
                  ><i class="feather feather-edit me-2"></i>Edit</a
                >
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#delete-item"
                  ><i class="feather feather-trash-2 me-2"></i>Delete</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="transfer-lists active">
              <div class="theme-image-content">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
              </div>
              <div class="transfer-liststop">
                <div class="transfer-listbank">
                  <h3>Karur vysya bank</h3>
                  <h4>**** **** 1832</h4>
                </div>
                <div class="transfer-listsname">
                  <h5>Holder Name</h5>
                  <h6>John Smith</h6>
                </div>
              </div>
              <div class="transfer-listsbottom">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#edit-account"
                  ><i class="feather feather-edit me-2"></i>Edit</a
                >
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#delete-item"
                  ><i class="feather feather-trash-2 me-2"></i>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--change email-no -->
  <div
    class="modal fade"
    id="add-account"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Account</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Bank Name</label>
                <input type="text" class="form-control" placeholder="Enter Bank Name" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Account Holder Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Accont Holder Name"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Account Number</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Account Number"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>IFSC Code</label>
                <input type="text" class="form-control" placeholder="Enter IFSC Code" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Bank Name</label>
                <input type="text" class="form-control" placeholder="Enter Bank Name" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Branch Name</label>
                <input type="text" class="form-control" placeholder="Enter Branch Name" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>UPI Id</label>
                <input type="text" class="form-control" placeholder="Enter UPI Id" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-groupheads d-flex d-flex justify-content-between">
                <h2>Status</h2>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Submit
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="edit-account"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Account</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Bank Name</label>
                <input type="text" class="form-control" value="Karur vysya bank" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Account Holder Name</label>
                <input type="text" class="form-control" value="John " />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Account Number</label>
                <input type="text" class="form-control" value="123546789" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>IFSC Code</label>
                <input type="text" class="form-control" value="123456" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Branch Name</label>
                <input type="text" class="form-control" value=" Branch " />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>UPI Id</label>
                <input type="text" class="form-control" value="654321" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-groupheads d-flex d-flex justify-content-between">
                <h2>Status</h2>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Submit
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete -->
  <div
    class="modal fade"
    id="delete-item"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Bank Transfer Account</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <b-form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="del-modal">
              <p>Are you sure want to Delete?</p>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <b-button type="button" variant="secondary" data-bs-dismiss="modal"
              >Cancel</b-button
            >
            <b-button type="submit" variant="primary" data-bs-dismiss="modal"
              >Yes</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
  <!-- /Delete -->
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/banktransfer");
    },
  },
};
</script>
