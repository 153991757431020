<template>
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">

                        <div class="col-md-4 d-flex">

                            <!-- Blog Post -->
                            <div class="blog grid-blog flex-fill">
                                <div class="blog-image">
                                    <router-link to="/blog-details"><img class="img-fluid"
                                            src="@/assets/img/services/service-19.jpg"
                                            alt="Post Image"></router-link>
                                </div>
                                <div class="blog-content">
                                    <div class="blog-category">
                                        <ul>
                                            <li><span class="cat-blog">Computer</span></li>
                                            <li><i class="feather-calendar me-2"></i>28 Sep 2023</li>
                                            <li>
                                                <div class="post-author">
                                                    <router-link to="/#"><img
                                                            src="@/assets/img/profiles/avatar-02.jpg"
                                                            alt="Post Author"><span>Admin</span></router-link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="blog-title">
                                        <router-link to="/blog-details">How to Fix a Computer in Just 3 Steps?</router-link>
                                    </h3>
                                    <p>Sed ut perspiciatis omnis natus error voluptatem architecto beatae vitae dicta sunt
                                        explicabo.</p>
                                    <router-link to="/blog-details" class="read-more">Read More <i
                                            class="feather-arrow-right-circle"></i></router-link>
                                </div>
                            </div>
                            <!-- /Blog Post -->

                        </div>

                        <div class="col-md-4 d-flex">

                            <!-- Blog Post -->
                            <div class="blog grid-blog flex-fill">
                                <div class="blog-image">
                                    <router-link to="/blog-details"><img class="img-fluid"
                                            src="@/assets/img/services/service-10.jpg"
                                            alt="Post Image"></router-link>
                                </div>
                                <div class="blog-content">
                                    <div class="blog-category">
                                        <ul>
                                            <li><span class="cat-blog">Construction</span></li>
                                            <li><i class="feather-calendar me-2"></i>28 Sep 2023</li>
                                            <li>
                                                <div class="post-author">
                                                    <router-link to="/#"><img
                                                            src="@/assets/img/profiles/avatar-02.jpg"
                                                            alt="Post Author"><span>Admin</span></router-link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="blog-title">
                                        <router-link to="/blog-details">Construction Service Scams: How to Avoid
                                            Them</router-link>
                                    </h3>
                                    <p>Sed ut perspiciatis omnis natus error voluptatem architecto beatae vitae dicta sunt
                                        explicabo.</p>
                                    <router-link to="/blog-details" class="read-more">Read More <i
                                            class="feather-arrow-right-circle"></i></router-link>
                                </div>
                            </div>
                            <!-- /Blog Post -->

                        </div>

                        <div class="col-md-4 d-flex">

                            <!-- Blog Post -->
                            <div class="blog grid-blog flex-fill">
                                <div class="blog-image">
                                    <router-link to="/blog-details"><img class="img-fluid"
                                            src="@/assets/img/services/service-08.jpg"
                                            alt="Post Image"></router-link>
                                </div>
                                <div class="blog-content">
                                    <div class="blog-category">
                                        <ul>
                                            <li><span class="cat-blog">Car Wash</span></li>
                                            <li><i class="feather-calendar me-2"></i>28 Sep 2023</li>
                                            <li>
                                                <div class="post-author">
                                                    <router-link to="/#"><img
                                                            src="@/assets/img/profiles/avatar-02.jpg"
                                                            alt="Post Author"><span>Admin</span></router-link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="blog-title">
                                        <router-link to="/blog-details">Lorem ipsum dolor sit amet, consectetur sed
                                            do</router-link>
                                    </h3>
                                    <p>Sed ut perspiciatis omnis natus error voluptatem architecto beatae vitae dicta sunt
                                        explicabo.</p>
                                    <router-link to="/blog-details" class="read-more">Read More <i
                                            class="feather-arrow-right-circle"></i></router-link>
                                </div>
                            </div>
                            <!-- /Blog Post -->

                        </div>

                        <div class="col-md-4 d-flex">

                            <!-- Blog Post -->
                            <div class="blog grid-blog flex-fill">
                                <div class="blog-image">
                                    <router-link to="/blog-details"><img class="img-fluid"
                                            src="@/assets/img/services/service-19.jpg"
                                            alt="Post Image"></router-link>
                                </div>
                                <div class="blog-content">
                                    <div class="blog-category">
                                        <ul>
                                            <li><span class="cat-blog">Electrical</span></li>
                                            <li><i class="feather-calendar me-2"></i>28 Sep 2023</li>
                                            <li>
                                                <div class="post-author">
                                                    <router-link to="/#"><img
                                                            src="@/assets/img/profiles/avatar-02.jpg"
                                                            alt="Post Author"><span>Admin</span></router-link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="blog-title">
                                        <router-link to="/blog-details">Lorem ipsum dolor sit amet, consectetur sed
                                            do</router-link>
                                    </h3>
                                    <p>Sed ut perspiciatis omnis natus error voluptatem architecto beatae vitae dicta sunt
                                        explicabo.</p>
                                    <router-link to="/blog-details" class="read-more">Read More <i
                                            class="feather-arrow-right-circle"></i></router-link>
                                </div>
                            </div>
                            <!-- /Blog Post -->

                        </div>

                        <div class="col-md-4 d-flex">

                            <!-- Blog Post -->
                            <div class="blog grid-blog flex-fill">
                                <div class="blog-image">
                                    <router-link to="/blog-details"><img class="img-fluid"
                                            src="@/assets/img/services/service-09.jpg"
                                            alt="Post Image"></router-link>
                                </div>
                                <div class="blog-content">
                                    <div class="blog-category">
                                        <ul>
                                            <li><span class="cat-blog">Cleaning</span></li>
                                            <li><i class="feather-calendar me-2"></i>28 Sep 2023</li>
                                            <li>
                                                <div class="post-author">
                                                    <router-link to="/#"><img
                                                            src="@/assets/img/profiles/avatar-02.jpg"
                                                            alt="Post Author"><span>Admin</span></router-link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="blog-title">
                                        <router-link to="/blog-details">Lorem ipsum dolor sit amet, consectetur sed
                                            do</router-link>
                                    </h3>
                                    <p>Sed ut perspiciatis omnis natus error voluptatem architecto beatae vitae dicta sunt
                                        explicabo.</p>
                                    <router-link to="/blog-details" class="read-more">Read More <i
                                            class="feather-arrow-right-circle"></i></router-link>
                                </div>
                            </div>
                            <!-- /Blog Post -->

                        </div>

                        <div class="col-md-4 d-flex">

                            <!-- Blog Post -->
                            <div class="blog grid-blog flex-fill">
                                <div class="blog-image">
                                    <router-link to="/blog-details"><img class="img-fluid"
                                            src="@/assets/img/services/service-07.jpg"
                                            alt="Post Image"></router-link>
                                </div>
                                <div class="blog-content">
                                    <div class="blog-category">
                                        <ul>
                                            <li><span class="cat-blog">Interior</span></li>
                                            <li><i class="feather-calendar me-2"></i>28 Sep 2023</li>
                                            <li>
                                                <div class="post-author">
                                                    <a href="javascript:;"><img src="@/assets/img/profiles/avatar-02.jpg"
                                                            alt="Post Author"><span>Admin</span></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h3 class="blog-title">
                                        <router-link to="/blog-details">Lorem ipsum dolor sit amet, consectetur sed
                                            do</router-link>
                                    </h3>
                                    <p>Sed ut perspiciatis omnis natus error voluptatem architecto beatae vitae dicta sunt
                                        explicabo.</p>
                                    <router-link to="/blog-details" class="read-more">Read More <i
                                            class="feather-arrow-right-circle"></i></router-link>
                                </div>
                            </div>
                            <!-- /Blog Post -->

                        </div>

                        <div class="col-md-12">

                            <div class="blog-pagination">
                                <nav>
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item disabled">
                                            <a class="page-link page-prev" href="javascript:;" tabindex="-1"><i
                                                class="fa-solid fa-arrow-left me-2"></i> PREV</a>
                                    </li>
                                    <li class="page-item active">
                                        <a class="page-link" href="javascript:;">1</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link" href="javascript:;">2</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link" href="javascript:;">3</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page-next" to="#">NEXT <i
                                                class="fa-solid fa-arrow-right ms-2"></i></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>