<template>
    <div class="main-wrapper">
        <mari-layout></mari-layout>

        <div class="page-wrapper">
            <div class="content">
                <div class="container">
                    <div class="section-heading">
                        <div class="row">
                        <div class="col-md-6 aos" data-aos="fade-up">
                            <div class="user-infos">
                                <!-- <span class="user-img">
                                    <img
                                    src="@/assets/img/profiles/avatar-02.jpg"
                                    class="rounded-circle"
                                    alt=""
                                    />
                                </span> -->
                                <div class="user-info">
                                    <p>Selamat Datang!</p>
                                    <h5>Mari lengkapi data Rental Anda!</h5>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12 mx-auto">

                            <div class="service-wizard">
                                <ul id="progressbar" v-show="currentStep === 1">
                                    <li class="active">
                                        <div class="multi-step-icon span-info">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Profil Perusahaan</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Siapkan Jenis Sewa</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Tambahkan Kendaraan</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Karyawan Anda</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Pelanggan Anda</h6>
                                        </div>
                                    </li>
                                </ul>
                                <ul id="progressbar" v-show="currentStep === 2">
                                    <li class="activated">
                                        <div class="multi-step-icon span-info">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Profil Perusahaan</h6>
                                        </div>
                                    </li>
                                    <li class="active">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Siapkan Jenis Sewa</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Tambahkan Kendaraan</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Karyawan Anda</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Pelanggan Anda</h6>
                                        </div>
                                    </li>
                                </ul>
                                <ul id="progressbar" v-show="currentStep === 3">
                                    <li class="activated ">
                                        <div class="multi-step-icon span-info">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Profil Perusahaan</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Siapkan Jenis Sewa</h6>
                                        </div>
                                    </li>
                                    <li class="active">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Tambahkan Kendaraan</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Karyawan Anda</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Pelanggan Anda</h6>
                                        </div>
                                    </li>
                                </ul>
                                <ul id="progressbar" v-show="currentStep === 4">
                                    <li class="activated">
                                        <div class="multi-step-icon span-info">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Profil Perusahaan</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Siapkan Jenis Sewa</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Tambahkan Kendaraan</h6>
                                        </div>
                                    </li>
                                    <li class="active">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Karyawan Anda</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Pelanggan Anda</h6>
                                        </div>
                                    </li>
                                </ul>
                                <ul id="progressbar" v-show="currentStep === 5">
                                    <li class="activated">
                                        <div class="multi-step-icon span-info">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Profil Perusahaan</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Siapkan Jenis Sewa</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Tambahkan Kendaraan</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Karyawan Anda</h6>
                                        </div>
                                    </li>
                                    <li class="active">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Pelanggan Anda</h6>
                                        </div>
                                    </li>
                                </ul>
                                <ul id="progressbar" v-show="currentStep === 6">
                                    <li class="activated">
                                        <div class="multi-step-icon span-info">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Profil Perusahaan</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Siapkan Jenis Sewa</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Tambahkan Kendaraan</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Karyawan Anda</h6>
                                        </div>
                                    </li>
                                    <li class="activated">
                                        <div class="multi-step-icon">
                                            <span><i class="fa-regular fa-circle-check"></i></span>
                                        </div>
                                        <div class="multi-step-info">
                                            <h6>Daftarkan Pelanggan Anda</h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <!-- <div class="service-inform-fieldset"> -->
                                <fieldset id="first-field" v-if="currentStep === 1">
                                    <guide-profile :detailCompany="dataCompany" :idCompany="company_id" @nextProfile="nextStep" :currentStep="currentStep" :totalSteps="totalSteps"></guide-profile>
                                </fieldset>

                                <fieldset v-if="currentStep === 2">
                                    <guide-paket @nextPaket="nextStep" @previous="previousStep" @getPaket="getProduct" :idCompany="company_id" :currentStep="currentStep" :totalSteps="totalSteps"></guide-paket>
                                </fieldset>

                                <fieldset v-if="currentStep === 3">
                                    <guide-vehicle @nextVehicle="nextStep" @previous="previousStep" :idCompany="company_id" :currentStep="currentStep" :totalSteps="totalSteps" :productData="dataProduct" :brandData="dataBrand" :vendorData="dataVendor"></guide-vehicle>
                                </fieldset>

                                <fieldset v-if="currentStep === 4">
                                    <guide-driver @nextDriver="nextStep" @previous="previousStep" :idCompany="company_id" :currentStep="currentStep" :totalSteps="totalSteps" :roleData="dataRole"></guide-driver>
                                </fieldset>

                                <fieldset v-if="currentStep === 5">
                                    <guide-customer @nextCustomer="nextStep" @previous="previousStep" :idCompany="company_id" :currentStep="currentStep" :totalSteps="totalSteps"></guide-customer>
                                </fieldset>

                                <fieldset v-if="currentStep === 6">
                                    <div class="card add-service">
                                        <div class="row mt-4">
                                            <div class="col-12">
                                                <h5>Yeay, Anda berhasil melengkapi data perusahaan!!</h5>
                                                <p>Silahkan lanjut ke Halaman Dashboard</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-bottom-btns">
                                        <!-- <div class="field-btns">
                                            <button class="btn btn-primary prev_btn" type="button"
                                            @click="previousStep" :disabled="currentStep === 4"><i class="fa-solid fa-arrow-left"></i>Prev</button>
                                        </div> -->
                                        <div class="field-btns">
                                            <button class="btn btn-primary next_btn" 
                                                type="button"
                                                @click="toMenu">Selesai<i class="fa-solid fa-arrow-right"></i></button>
                                        </div>
                                    </div>
                                </fieldset>

                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <model></model>

    </div>
</template>

<script>
export default {
    data() {
        return {
            currentStep: 1,
			totalSteps: 6,
            userLogin: null,
            company_id: null,
            dataCompany: {},
            params: {
                row: 10,
                keyword: "",
                sortby: "id",
                sorttype: "asc",
                page: 1
            },
            dataProduct: [],
            dataBrand: [],
            dataVendor: [],
            dataRole: []
        }
    },
    methods: {
		nextStep() {
			if (this.currentStep < this.totalSteps) {
				this.currentStep++;
			}
		},
		previousStep() {
			if (this.currentStep > 1) {
				this.currentStep--;
			}
		},
		showProgress(step) {
			return this.currentStep === step;
		},
        getData() {
            var loading = this.$loading.show();
            this.$store
            .dispatch("company/showCompany", {id : this.company_id})
            .then((resp) => {
                this.dataCompany = resp.data.data;
                loading.hide();
            })
            .catch((e) => {
                this.$toast.error(e);
                loading.hide();
            });
        },
        getBrand() {
            var loading = this.$loading.show();
            this.$store
            .dispatch("brand/getBrand", this.params)
            .then((resp) => {
                this.dataBrand = resp.data.data;
                loading.hide();
            })
            .catch((e) => {
                this.$toast.error(e);
                loading.hide();
            });
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params)
                .then((resp) => {
                    this.dataProduct = resp.data.data.map((item, index) => {
                        let rentalTypeName;
                        if (item.rental_type == 1) {
                            rentalTypeName = "Sewa Lepas Kunci";
                        } else {
                            rentalTypeName = "Sewa Dengan Driver";
                        }
                        return {
                            ...item,
                            rentalTypeName
                        };
                    });
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVendor() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vendor/getVendor", this.params)
                .then((resp) => {
                    this.dataVendor = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getRole() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("role/getRole", this.params)
                .then((resp) => {
                    this.dataRole = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        // !! logic jika guide baru setengah jalan
        countStep () {
            var loading = this.$loading.show();
            this.$store
            .dispatch("guide/getGuide", {id : this.company_id})
            .then((resp) => {
                const data = resp.data;
                if (data.is_profile == 1) {
                    this.currentStep++;
                }
                if (data.is_product == 1) {
                    this.currentStep++;
                }
                if (data.is_vehicle == 1) {
                    this.getProduct();
                    this.currentStep++;
                }
                if (data.is_user == 1) {
                    this.currentStep++;
                }
                if (data.is_customer == 1) {
                    this.currentStep++;
                }
                loading.hide();
            })
            .catch((e) => {
                this.$toast.error(e);
                loading.hide();
            });
        },
        toMenu() {
            this.$store.commit('guide/setGuide', false);
            this.$router.replace({ path: "/mari" });
        }
	},
    mounted() {
        const userData = localStorage.getItem('user');
        const user = JSON.parse(userData);
        this.userLogin = user;
        this.company_id = this.userLogin.company_id;
        this.getData();
        this.getBrand();
        this.getRole();
        this.getVendor();
        this.getProduct();
        this.countStep();
    },
}
</script>

