<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <div class="sidebar-inner slimscroll">
      <perfect-scrollbar
        class="scroll-area"
        :settings="settings"
        @ps-scroll-y="scrollHanle"
      >
        <div id="sidebar-menu" class="sidebar-menu">
          <ul class="has-submenu">
            <li :class="currentPath == 'provider-dashboard' ? 'active' : 'notactive'">
              <router-link to="/provider-dashboard"
                ><i class="feather-grid"></i> <span>Dashboard</span></router-link
              >
            </li>
            <li :class="currentPath == 'provider-services' ? 'active' : 'notactive'">
              <router-link to="/provider-services"
                ><i class="feather-briefcase"></i> <span>My Services</span></router-link
              >
            </li>
            <li
              :class="
                currentPath == 'provider-booking' ||
                currentPath == 'provider-book-details'
                  ? 'active'
                  : 'notactive'
              "
            >
              <router-link to="/provider-booking"
                ><i class="feather-calendar"></i> <span>Bookings </span></router-link
              >
            </li>
            <li :class="currentPath == 'provider-payout' ? 'active' : 'notactive'">
              <router-link to="/provider-payout"
                ><i class="feather-credit-card"></i> <span>Payout</span></router-link
              >
            </li>
            <li :class="currentPath == 'provider-availability' ? 'active' : 'notactive'">
              <router-link to="/provider-availability"
                ><i class="feather-clock"></i> <span>Availability</span></router-link
              >
            </li>
            <li :class="currentPath == 'provider-holiday' ? 'active' : 'notactive'">
              <router-link to="/provider-holiday"
                ><i class="feather-calendar"></i>
                <span>Holidays & Leave</span></router-link
              >
            </li>
            <li :class="currentPath == 'provider-coupons' ? 'active' : 'notactive'">
              <router-link to="/provider-coupons"
                ><i class="feather-bookmark"></i> <span>Coupons</span></router-link
              >
            </li>
            <li
              :class="
                currentPath == 'provider-subscription' || currentPath == 'provider-addons'
                  ? 'active'
                  : 'notactive'
              "
            >
              <router-link to="/provider-subscription"
                ><i class="feather-dollar-sign"></i>
                <span>Subscription</span></router-link
              >
            </li>
            <li :class="currentPath == 'provider-offers' ? 'active' : 'notactive'">
              <router-link to="/provider-offers"
                ><i class="feather-percent"></i> <span>Offers</span></router-link
              >
            </li>
            <li :class="currentPath == 'provider-reviews' ? 'active' : 'notactive'">
              <router-link to="/provider-reviews"
                ><i class="fas fa-star"></i> <span>Reviews</span></router-link
              >
            </li>
            <li :class="currentPath == 'provider-earnings' ? 'active' : 'notactive'">
              <router-link to="/provider-earnings"
                ><i class="feather-dollar-sign"></i> <span>Earnings</span></router-link
              >
            </li>
            <li :class="currentPath == 'provider-chat' ? 'active' : 'notactive'">
              <router-link to="/provider-chat"
                ><i class="feather-message-circle"></i> <span>Chat</span></router-link
              >
            </li>
            <li class="submenu" :class="SettingsMenu ? 'active' : 'notactive'">
              <a
                href="#sidebarSettings"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarSettings"
                v-bind:class="SettingsMenu ? 'active SettingsMenu' : 'notactive'"
                v-on:click="SettingsMenus = !SettingsMenus"
                :class="{ subdrop: SettingsMenus }"
              >
                <i class="feather-settings"></i> <span>Settings</span>
                <span class="menu-arrow"></span>
              </a>
              <ul
                class="collapse menu-dropdown"
                id="sidebarSettings"
                :class="{ show: SettingsMenu }"
              >
                <li>
                  <router-link
                    :class="
                      currentPath == 'provider-appointment-settings'
                        ? 'active'
                        : 'notactive'
                    "
                    to="/provider-appointment-settings"
                    >Appointment Settings</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == 'provider-profile-settings' ? 'active' : 'notactive'
                    "
                    to="/provider-profile-settings"
                    >Account Settings</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == 'provider-social-profile' ? 'active' : 'notactive'
                    "
                    to="/provider-social-profile"
                    >Social Profiles</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == 'provider-security-settings' ? 'active' : 'notactive'
                    "
                    to="/provider-security-settings"
                    >Security Setting</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="currentPath == 'provider-plan' ? 'active' : 'notactive'"
                    to="/provider-plan"
                    >Plan & Billings</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="currentPath == 'payment-settings' ? 'active' : 'notactive'"
                    to="/payment-settings"
                    >Payment Settings</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == 'provider-notifcations' ? 'active' : 'notactive'
                    "
                    to="/provider-notifcations"
                    >Notifications</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == 'provider-connected-apps' ? 'active' : 'notactive'
                    "
                    to="/provider-connected-apps"
                    >Connected Apps</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="currentPath == 'verification' ? 'active' : 'notactive'"
                    to="/verification"
                    >Profile Verification</router-link
                  >
                </li>
                <li>
                  <a
                    href="javascript:;"
                    data-bs-toggle="modal"
                    data-bs-target="#del-account"
                    >Delete Account</a
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/"
                ><i class="feather-log-out"></i> <span>Logout</span></router-link
              >
            </li>
          </ul>
          <div class="menu-bottom">
            <div class="menu-user">
              <div class="menu-user-img avatar-online">
                <img src="@/assets/img/profiles/avatar-02.jpg" alt="user" />
              </div>
              <div class="menu-user-info">
                <h6>John Smith</h6>
                <p>johnsmith@gmail.com</p>
              </div>
            </div>
            <a href="javascript:;" class="select-set"><i class="feather-settings"></i></a>
            <div class="dropdown-menu user-drop" id="dropboxes">
              <div class="menu-user">
                <div class="menu-user-img avatar-online">
                  <img src="@/assets/img/profiles/avatar-02.jpg" alt="user" />
                </div>
                <div class="menu-user-info">
                  <h6>John Smith</h6>
                  <p>Active</p>
                </div>
              </div>
              <div class="set-user">
                <p>Set as Away</p>
                <div class="status-toggle">
                  <input type="checkbox" id="active-user" class="check" />
                  <label for="active-user" class="checktoggle">checkbox</label>
                </div>
              </div>
              <ul class="set-menu">
                <li>
                  <router-link to="/provider-security-settings">
                    <i class="feather-settings me-2"></i> Settings
                  </router-link>
                </li>
                <li>
                  <router-link to="/provider-profile-settings">
                    <i class="feather-user me-2"></i> Your Account
                  </router-link>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <i class="feather-check-circle me-2"></i> Identity Verification
                  </a>
                </li>
              </ul>
              <ul class="help-menu">
                <li>
                  <a href="javascript:;"> Help Center </a>
                </li>
                <li>
                  <router-link to="/terms-condition"> Terms of Condition </router-link>
                </li>
                <li>
                  <router-link to="/privacy-policy"> Privacy Policy </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- /Sidebar -->
  <div class="modal fade custom-modal" id="del-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sureyou want to delete This Account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    v-if="showPassword"
                    type="text"
                    class="form-control pass-input"
                    v-model="password"
                    placeholder="**********"
                  />
                  <input
                    v-else
                    type="password"
                    class="form-control pass-input"
                    placeholder="**********"
                    v-model="password"
                  />
                  <span
                    class="toggle-password"
                    @click="toggleShow"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <b-button type="submit" variant="danger">Delete Account</b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      SettingsMenus: false,
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
    };
    isactive: true;
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    currentPath() {
      return this.$route.name;
    },
    SettingsMenu() {
      return (
        this.$route.name == "provider-appointment-settings" ||
        this.$route.name == "provider-profile-settings" ||
        this.$route.name == "provider-social-profile" ||
        this.$route.name == "provider-security-settings" ||
        this.$route.name == "provider-social-profile" ||
        this.$route.name == "provider-plan" ||
        this.$route.name == "provider-security-settings" ||
        this.$route.name == "payment-settings" ||
        this.$route.name == "provider-notifcations" ||
        this.$route.name == "provider-connected-apps" ||
        this.$route.name == "verification"
      );
    },
    ProviderMenu() {
      return (
        this.$route.name == "provider-dashboard" ||
        this.$route.name == "provider-services" ||
        this.$route.name == "provider-booking" ||
        this.$route.name == "provider-payout" ||
        this.$route.name == "provider-availability" ||
        this.$route.name == "provider-holiday" ||
        this.$route.name == "provider-coupons" ||
        this.$route.name == "provider-subscription" ||
        this.$route.name == "provider-offers" ||
        this.$route.name == "provider-reviews" ||
        this.$route.name == "provider-earnings" ||
        this.$route.name == "provider-chat"
      );
    },
  },

  methods: {
    submitForm() {
      this.$router.push("/login");
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    scrollHanle(evt) {},
    isCustomDropdown() {
      //Search bar
      var searchOptions = document.getElementById("search-close-options");
      var dropdown = document.getElementById("search-dropdown");
      var searchInput = document.getElementById("search-options");

      searchInput.addEventListener("focus", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchInput.addEventListener("keyup", () => {
        var inputLength = searchInput.value.length;
        if (inputLength > 0) {
          dropdown.classList.add("show");
          searchOptions.classList.remove("d-none");
        } else {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });

      searchOptions.addEventListener("click", () => {
        searchInput.value = "";
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      });

      document.body.addEventListener("click", (e) => {
        if (e.target.getAttribute("id") !== "search-options") {
          dropdown.classList.remove("show");
          searchOptions.classList.add("d-none");
        }
      });
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#sidebar")) {
          let a = document
            .querySelector("#sidebar")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
};
</script>
