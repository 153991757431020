<template>
    
    <div class="main-wrapper">
        <div class="provider-body">

            <dashboardnavbar />

        <div class="page-wrapper">
            <sidebar />

            <couponadd />

        </div>

        </div>
        
        <cursorpointer />
    </div>
</template>