export function formatCurrency(value, decimalSeparator = ',', thousandSeparator = '.') {
    // Ensure the value is a number
    value = parseFloat(value);
    if (isNaN(value)) {
        throw new Error('Invalid number');
    }

    // Format the number to two decimal places
    let [integerPart, decimalPart] = value.toFixed(2).split('.');

    // Add thousands separators
    let regex = /(\d)(?=(\d{3})+(?!\d))/g;
    integerPart = integerPart.replace(regex, `$1${thousandSeparator}`);

    // Combine integer and decimal parts
    // let formattedValue = `${integerPart}${decimalSeparator}${decimalPart}`;
    let formattedValue = `${integerPart}`;

    // Remove leading commas if they exist
    formattedValue = formattedValue.replace(/^\,+/, '');

    return formattedValue;
}
