<template>
  <!-- Add Category -->
  <div class="modal fade" id="add-blog-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Blog Category</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Language</label>
              <vue-select
                :options="Blogcate"
                id="blogcate"
                placeholder="Select Language"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Category Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-4">
              <label class="form-label"
                >Slug
                <small class="form-text text-muted"
                  >(If you leave it empty, it will be generated automatically.)</small
                ></label
              >
              <input type="text" class="form-control" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Add Category</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Category -->

  <!-- Edit Category -->
  <div class="modal fade" id="edit-blog-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Blog Category</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Language</label>
              <vue-select :options="BlogLang" id="bloglang" placeholder="German" />
            </div>
            <div class="mb-3">
              <label class="form-label">Category Name</label>
              <input type="text" class="form-control" value="Electrical" />
            </div>
            <div class="mb-4">
              <label class="form-label"
                >Slug
                <small class="form-text text-muted"
                  >(If you leave it empty, it will be generated automatically.)</small
                ></label
              >
              <input type="text" class="form-control" value="electrical" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Category -->

  <!-- Delete Category -->
  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Category ?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to delete this?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete -->
</template>
<script>
export default {
  data() {
    return {
      Blogcate: ["Select Language", "English", "German"],
      BlogLang: ["Select Language", "English", "German"],
    };
  },
};
</script>
