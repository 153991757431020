<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
      <div class="page-wrapper">
        <div class="content">
		<section class="feature-section">			
          <div class="container">			
            <div class="section-heading">
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-3" v-for="(item, index) in menuItems" :key="index">
                <router-link :to="item.route" class="feature-box aos" data-aos="fade-up">
                  <div class="feature-icon">
                    <span>
                      <img src="@/assets/img/icons/feature-icon-01.svg" alt="img">
                    </span>
                  </div>
                  <h5>{{ item.title }}</h5>
                  <div class="feature-overlay">
                    <img src="@/assets/img/services/service-02.jpg" alt="img">
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </section>
          <!-- <div class="row">
            <div class="col-md-6 col-lg-4 d-flex" v-for="(item, index) in filteredMenuItems" :key="index" >
              <div class="category-card flex-fill">
                  <div class="category-img">
                      <router-link :to="item.route">
                        <i :class="['fas', item.icon]"></i>
                          <img src="@/assets/img/services/service-06.jpg" class="img-fluid" alt="">
                      </router-link>
                  </div>
                  <div class="category-info">
                      <div class="category-name">
                          <h6><router-link :to="item.route">{{ item.title }}</router-link></h6>
                      </div>
                  </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        menuItems: [
        { title: 'Perusahaan', icon: 'fa-building', route: '/admin-setting/company' },
        { title: 'Jenis Sewa', icon: 'fa-sliders', route: '/admin-setting/product' },
        // { title: 'Cabang', icon: 'fa-building', route: '/admin-setting/branch' },
        { title: 'User', icon: 'fa-user', route: '/admin-setting/user' },
        { title: 'Vendor', icon: 'fa-user', route: '/admin-setting/vendor' },
        { title: 'Brand', icon: 'fa-user', route: '/admin-setting/brand' },
        { title: 'Role', icon: 'fa-user', route: '/admin-setting/role' },
        { title: 'Menu', icon: 'fa-user', route: '/admin-setting/menu' },
        { title: 'Acces Menu', icon: 'fa-user', route: '/admin-setting/acces' },
        ],
      	company_id: null
      };
    },
	computed: {
		filteredMenuItems() {
		// If user company ID is 0, allow access to brand, role, menu, and access menu
		if (this.company_id === 0) {
			return this.menuItems;
		} else {
			// Filter out menu items that user should not have access to
			return this.menuItems.filter(
			(item) =>
				item.route !== '/admin-setting/brand' &&
				item.route !== '/admin-setting/role' &&
				item.route !== '/admin-setting/menu' &&
				item.route !== '/admin-setting/acces'
			);
		}
		},
	},
    mounted() {
        const userData = localStorage.getItem('user');
		const user = JSON.parse(userData);
		this.company_id = user.company_id;
		console.log(this.company_id);
    },
    methods: {
     
    },
  };
  </script>
  
<!-- <style scoped>
.category-card {
	background: #fff;
	border: 2px solid #F5F5F5;
	border-radius: 10px;
	margin-bottom: 24px;
}
.category-img {
	overflow: hidden;
	border-radius: 10px 10px 0px 0px;
}
.category-img img {
	width: 100%;
  	height: 282px;
	border-radius: 10px 10px 0px 0px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.category-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.category-info {
	padding: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;		
}
.category-name {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;	
}
.category-icon {
	width: 45px;
	height: 45px;
	background: #F7F7FF;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;	
	margin-right: 10px;
}
.category-name h6 {
	font-size: 20px;
	margin-bottom: 0;
}
.category-name h6 a {
	color: #28283C;
}
.category-name h6 a:hover {
	color: #35a7f4;
}
.category-info p {
	font-size: 16px;
	color: #74788d;
	margin-bottom: 0;
}
</style> -->

<style scoped>
.feature-section {    
	/* background: url(../assets/img/bg/feature-bg-01.png), url(../assets/img/bg/feature-bg-02.png), #F7F7FF; */
  background: #F7F7FF;
	background-repeat: no-repeat;
	background-position: left center, right center;
	padding: 90px 0 66px;
}
.feature-section.nearby-section .section-heading h2 {
	font-weight: 700;
}
.feature-section.nearby-section .section-heading .btn-view {
	padding: 14px 20px;
	border-radius: 50px;
}
.feature-section, .work-section, .client-section {
  padding: 60px 0 36px;
}
.feature-box {
	background: #fff;
	box-shadow: 0px 10px 20px -5px rgba(76, 64, 237, 0.08);
	padding: 25px;
	text-align: center;
	margin-bottom: 1.5rem;
	border-radius: 10px;
	position: relative;	
	display: block;
    z-index: 1;
	overflow: hidden;
}
.feature-icon span {	
    z-index: 1;
}
.feature-icon span {
	width: 94px;
	height: 94px;
	background: #F7F7FF;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	border-radius: 50%;
	margin: 0 auto 20px;
}
.feature-box h5 {
	margin-bottom: 0;
}
.feature-box .feature-overlay {
	opacity: 0;
	position: absolute;
	top: 0;
	left: -100%;	
    z-index: -1;
	-webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
	width: 100%;
}
.feature-box .feature-overlay img {	
	border-radius: 10px;	
}
.feature-box:hover .feature-overlay {
	opacity: 1;
	left: 0;
}
.feature-box:hover .feature-overlay:before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 50%);
    border-radius: 10px;
}
.feature-box:hover h5 {
	color: #fff;
}
.feature-box .feature-overlay img {
		width: 100%;
	}
</style>