<template>
  <!-- Header -->
  <div class="header">
    <div class="header-left">
      <div class="sidebar-logo">
        <router-link to="/">
          <img src="@/assets/img/logo.svg" class="img-fluid logo" alt="" />
        </router-link>
        <router-link to="/">
          <img src="@/assets/img/logo-small.png" class="img-fluid logo-small" alt="" />
        </router-link>
      </div>
      <div class="siderbar-toggle">
        <label class="switch" id="toggle_btn">
          <input type="checkbox" @click="toggleSidebar" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <a
      class="mobile_btns"
      id="mobile_btns"
      href="javascript:void(0);"
      @click="toggleSidebar1"
    >
      <i class="feather-align-left"></i>
    </a>
    <div class="header-split">
      <div class="page-headers">
        <div class="search-bar">
          <span><i class="feather-search"></i></span>
          <input type="text" placeholder="Search" class="form-control" />
        </div>
      </div>
      <ul class="nav user-menu noti-pop-detail">
        <!-- Notifications -->
        <li class="nav-item">
          <router-link to="/" class="viewsite"
            ><i class="feather-globe me-2"></i>View Site</router-link
          >
        </li>
        <li class="nav-item dropdown has-arrow dropdown-heads flag-nav">
          <a class="nav-link" data-bs-toggle="dropdown" href="javascript:;" role="button">
            <img src="@/assets/img/flags/us1.png" alt="" height="20" />
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a href="javascript:void(0);" class="dropdown-item">
              <img src="@/assets/img/flags/us.png" class="me-2" alt="" height="16" />
              English
            </a>
            <a href="javascript:void(0);" class="dropdown-item">
              <img src="@/assets/img/flags/fr.png" class="me-2" alt="" height="16" />
              French
            </a>
            <a href="javascript:void(0);" class="dropdown-item">
              <img src="@/assets/img/flags/es.png" class="me-2" alt="" height="16" />
              Spanish
            </a>
            <a href="javascript:void(0);" class="dropdown-item">
              <img src="@/assets/img/flags/de.png" class="me-2" alt="" height="16" />
              German
            </a>
          </div>
        </li>
        <li class="nav-item has-arrow dropdown-heads">
          <a href="javascript:;">
            <i class="feather-moon"></i>
          </a>
        </li>
        <!-- Notifications -->
        <li
          class="nav-item has-arrow dropdown-heads dropdown logged-item noti-nav noti-wrapper"
        >
          <a
            href="javascript:;"
            class="dropdown-toggle nav-link notify-link"
            data-bs-toggle="dropdown"
          >
            <span class="noti-message">1</span>
            <img src="@/assets/img/icons/bell-icon.svg" alt="Bell" />
          </a>
          <div class="dropdown-menu notify-blk notifications">
            <div class="topnav-dropdown-header">
              <div>
                <p class="notification-title">Notifications <span> 3 </span></p>
              </div>
              <a href="javascript:void(0)" class="clear-noti"
                ><i class="fa-regular fa-circle-check"></i> Mark all as read
              </a>
            </div>
            <div class="noti-content">
              <ul class="notification-list">
                <li class="notification-message">
                  <router-link to="notification">
                    <div class="media noti-img d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="@/assets/img/notifications/avatar-01.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          Lex Murphy left 6 comments on Isla Nublar SOC2 compliance report
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">1m ago</span>
                        </p>
                      </div>
                    </div>
                  </router-link>
                </li>
                <li class="notification-message">
                  <div class="media noti-img d-flex">
                    <router-link to="notification">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="@/assets/img/notifications/avatar-02.jpg"
                        />
                      </span>
                    </router-link>
                    <div class="media-body flex-grow-1">
                      <router-link to="notification"
                        ><p class="noti-details">
                          Ray Arnold requested access to UNIX directory tree hierarchy
                        </p></router-link
                      >
                      <p class="noti-time">
                        <span class="notification-time">1 min</span>
                      </p>
                      <div class="notify-btns">
                        <button class="btn btn-secondary">Decline</button>
                        <button class="btn btn-primary">Accept</button>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="notification-message">
                  <router-link to="notification">
                    <div class="media noti-img d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="@/assets/img/notifications/avatar-03.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          Dennis Nedry commented on Isla Nublar SOC2 compliance report
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">1m ago</span>
                        </p>
                      </div>
                    </div>
                  </router-link>
                </li>
                <li class="notification-message">
                  <router-link to="notification">
                    <div class="media noti-img d-flex">
                      <span class="avatar avatar-sm flex-shrink-0">
                        <img
                          class="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="@/assets/img/notifications/avatar-04.jpg"
                        />
                      </span>
                      <div class="media-body flex-grow-1">
                        <p class="noti-details">
                          John Hammond created Isla Nublar SOC2 compliance report
                        </p>
                        <p class="noti-time">
                          <span class="notification-time">1m ago</span>
                        </p>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="topnav-dropdown-footer">
              <router-link to="notification"
                >View All Notifications
                <img src="@/assets/img/icons/arrow-right-01.svg" alt="Arrow"
              /></router-link>
            </div>
          </div>
        </li>
        <!-- /Notifications -->
        <li class="nav-item has-arrow dropdown-heads" @click="initFullScreen">
          <a href="javascript:;" class="win-maximize">
            <i class="feather-maximize"></i>
          </a>
        </li>

        <!-- User Menu -->
        <li class="nav-item dropdown has-arrow account-item">
          <a
            href="javascript:;"
            class="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <div class="user-infos">
              <span class="user-img">
                <img
                  src="@/assets/img/profiles/avatar-02.jpg"
                  class="rounded-circle"
                  alt=""
                />
              </span>
              <div class="user-info">
                <h6>John Smith</h6>
                <p>Demo User</p>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end emp">
            <router-link class="dropdown-item" to="/provider-profile-settings">
              <i class="feather-user me-2"></i> Profile
            </router-link>
            <router-link class="dropdown-item" to="/">
              <i class="feather-log-out me-2"></i> Logout
            </router-link>
          </div>
        </li>
        <!-- /User Menu -->
      </ul>
    </div>
  </div>
  <!-- /Header -->
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleSidebar() {
      const body = document.body;
      body.classList.toggle("mini-sidebar");
    },
    toggleSidebar1() {
      const body = document.body;
      body.classList.toggle("slide-nav");
    },
  },
  mounted() {
    document.addEventListener("mouseover", function (e) {
      e.stopPropagation();
      if (
        document.body.classList.contains("mini-sidebar") &&
        document.getElementById("toggle_btn").offsetParent !== null
      ) {
        var target = e.target.closest(".sidebar");
        if (target) {
          document.body.classList.add("expand-menu");
          var subdropUl = document.querySelectorAll(".subdrop + ul");
          subdropUl.forEach(function (ul) {
            ul.style.display = "block";
          });
        } else {
          document.body.classList.remove("expand-menu");
          var subdropUl = document.querySelectorAll(".subdrop + ul");
          subdropUl.forEach(function (ul) {
            ul.style.display = "none";
          });
        }
        e.preventDefault();
        return false;
      }
    });
  },
};
</script>
