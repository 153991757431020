<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content w-100 ps-0 pt-0">
        <sidelinkheading />

        <div class="row">
          <div class="content-table">
            <div class="content-page-header content-page-headersplit">
              <h5>All Taxes</h5>
              <div class="list-btn">
                <ul>
                  <li class="tax-module">
                    <div class="active-switch me-1">
                      <label class="switch">
                        <input type="checkbox" checked="" />
                        <span class="sliders round"></span>
                      </label>
                    </div>
                    <span>Tax Module</span>
                  </li>
                  <li>
                    <div class="filter-sorting">
                      <ul>
                        <li>
                          <a href="javascript:void(0);" class="filter-sets"
                            ><img
                              src="@/assets/admin_img/icons/filter1.svg"
                              class="me-2"
                              alt="img"
                            />Filter</a
                          >
                        </li>
                        <li>
                          <span
                            ><img
                              src="@/assets/admin_img/icons/sort.svg"
                              class="me-2"
                              alt="img"
                          /></span>
                          <div class="review-sort">
                            <vue-select
                              :options="AZ"
                              id="aztaxtrate"
                              placeholder="A - Z"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      class="btn btn-primary"
                      href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#add-tax"
                      ><i class="fa fa-plus me-2"></i>Create Tax</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12">
              <div class="table-resposnive table-bottom">
                <table class="table datatable">
                  <a-table :columns="columns" :data-source="data">
                    <template #bodyCell="{ column }">
                      <template v-if="column.key === 'action'">
                        <div class="action-language">
                          <div class="active-switch">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="sliders round"></span>
                            </label>
                          </div>
                          <a
                            class="table-edit"
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-tax"
                          >
                            <i class="fa-regular fa-pen-to-square"></i>
                            <span>Edit</span>
                          </a>
                          <a
                            class="table-delete"
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#delete-item"
                          >
                            <i class="fa-solid fa-trash-can"></i>
                            <span>Delete</span>
                          </a>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <taxratemodel></taxratemodel>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "Id",
    key: "Id",
    sorter: {
      compare: (a, b) => {
        a = a.Id.toLowerCase();
        b = b.Id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Tax Name",
    dataIndex: "Tax_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Tax_Name.toLowerCase();
        b = b.Tax_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Tax Percentage",
    dataIndex: "Tax_Percentage",
    sorter: {
      compare: (a, b) => {
        a = a.Tax_Percentage.toLowerCase();
        b = b.Tax_Percentage.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

const data = [
  {
    Id: "1",
    Tax_Name: "Casual Customs",
    Tax_Percentage: "0.00%",
    Action: "",
  },
  {
    Id: "2",
    Tax_Name: "Your Tax Assistance",
    Tax_Percentage: "2.00%",
    Action: "",
  },
  {
    Id: "3",
    Tax_Name: "Essential Expenses",
    Tax_Percentage: "7.25%",
    Action: "",
  },
  {
    Id: "4",
    Tax_Name: "Taxing Co",
    Tax_Percentage: "4.50%",
    Action: "",
  },
  {
    Id: "5",
    Tax_Name: "Afflict Tax Payers",
    Tax_Percentage: "0.00%",
    Action: "",
  },
  {
    Id: "6",
    Tax_Name: "Financial Cub",
    Tax_Percentage: "1.00%",
    Action: "",
  },
  {
    Id: "7",
    Tax_Name: "The Pocketbook",
    Tax_Percentage: "2.55%",
    Action: "",
  },
  {
    Id: "8",
    Tax_Name: "Tax Deduction Collective",
    Tax_Percentage: "0.00%",
    Action: "",
  },
];
export default {
  data() {
    return {
      AZ: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
