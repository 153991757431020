<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>
    <div class="page-wrapper page-settings">
      <div class="content">
        <abusereport :title="title" />
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <a-table :columns="columns" :data-source="data">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Name'">
                    <a href="javascript:void(0);" class="table-profileimage">
                      <img
                        :src="
                          require(`@/assets/admin_img/customer/${record.Provider_img}`)
                        "
                        class="me-2"
                        alt="img"
                      />
                      <span>{{ record.Provider }}</span>
                    </a>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="table-actions d-flex">
                      <router-link class="delete-table me-2" to="contact-messages-view">
                        <img src="@/assets/admin_img/icons/eye.svg" alt="svg" />
                      </router-link>
                      <a
                        class="delete-table"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                      >
                        <img src="@/assets/admin_img/icons/delete.svg" alt="svg" />
                      </a>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <delete-model :delete_title="delete_title" :delete_text="delete_text" />
</template>
<script>
const columns = [
  {
    title: "#",
    dataIndex: "num",
    sorter: {
      compare: (a, b) => {
        a = a.num.toLowerCase();
        b = b.num.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "Provider",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Provider.toLowerCase();
        b = b.Provider.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "Email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    sorter: {
      compare: (a, b) => {
        a = a.Phone.toLowerCase();
        b = b.Phone.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Message",
    dataIndex: "Message",
    sorter: {
      compare: (a, b) => {
        a = a.Message.toLowerCase();
        b = b.Message.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    num: "1",
    Provider_img: "user-01.jpg",
    Provider: "John Smith",
    User_img: "user-03.jpg",
    Phone: "+1 347-679-8275",
    Message: "Need this script",
    Email: "john@example.com",
    Date: "28 Sep 2023",
  },
  {
    num: "2",
    Provider_img: "user-04.jpg",
    Provider: "Johnny",
    User_img: "user-05.jpg",
    Phone: "+1 347-679-8275",
    Message: "Need this script",
    Email: "johnny@example.com",
    Date: "25 Sep 2023",
  },
  {
    num: "3",
    Provider_img: "user-06.jpg",
    Provider: "Robert",
    User_img: "user-02.jpg",
    Phone: "+1 347-679-8275",
    Message: "Need this script",
    Email: "robert@example.com",
    Date: "23 Sep 2023",
  },
  {
    num: "4",
    Provider_img: "user-09.jpg",
    Provider: "Sharonda",
    User_img: "user-09.jpg",
    Phone: "+1 347-679-8275",
    Message: "Need this script",
    Email: "sharonda@example.com",
    Date: "03 Sep 2023",
  },
];
export default {
  data() {
    return {
      title: "Contact Messages",
      data,
      columns,
      delete_title: "Delete Contact Messages",
      delete_text: "Are you sure want to Delete?",
    };
  },
  name: "contact-message",
};
</script>
