<template>
  <div class="service-details">
    <ul class="nav nav-pills service-tabs" id="pills-tab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-home-tab"
          data-bs-toggle="pill"
          href="#pills-home"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
          >Overview</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link active"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          href="#pills-profile"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
          >Services Offered</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="pills-book-tab"
          data-bs-toggle="pill"
          href="#pills-book"
          role="tab"
          aria-controls="pills-book"
          aria-selected="false"
          >Reviews</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane fade"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="card service-description">
          <div class="card-body">
            <h5 class="card-title">Service Details</h5>
            <p class="mb-0">
              Car wash is a facility used to clean the exterior and, in some cases, the
              interior of motor vehicles. Car washes can be self-serve, fully automated,
              or full-service with attendants who wash the vehicle.
            </p>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade show active"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Services Offered</h5>
            <div class="serviced-offer">
              <ul class="list-bullet">
                <li>
                  <span> <i class="fa-regular fa-circle-check"></i> </span>
                  Changing Switch Boards
                </li>
                <li>
                  <span> <i class="fa-regular fa-circle-check"></i> </span>
                  Swapping of Lines & Circuits
                </li>
                <li>
                  <span> <i class="fa-regular fa-circle-check"></i> </span>
                  Main Board Switches alinged
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-book"
        role="tabpanel"
        aria-labelledby="pills-book-tab"
      >
        <div class="card review-box">
          <div class="card-body p-0">
            <span>No reviews found</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
