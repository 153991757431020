<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>
    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Coupons</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Status'">
                      <h6 :class="record.Class">{{ record.Status }}</h6>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="table-actions d-flex">
                        <button
                          class="btn delete-table me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-coupon"
                        >
                          <i class="feather feather-edit"></i>
                        </button>
                        <button
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <marketing-coupons-model></marketing-coupons-model>
  <delete-model :delete_title="delete_title" :delete_text="delete_text" />
</template>

<script>
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Code",
    dataIndex: "Code",
    sorter: {
      compare: (a, b) => {
        a = a.Code.toLowerCase();
        b = b.Code.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "Type",
    sorter: {
      compare: (a, b) => {
        a = a.Type.toLowerCase();
        b = b.Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Discount",
    dataIndex: "Discount",
    sorter: {
      compare: (a, b) => {
        a = a.Discount.toLowerCase();
        b = b.Discount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "limit",
    dataIndex: "limit",
    sorter: {
      compare: (a, b) => {
        a = a.limit.toLowerCase();
        b = b.limit.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Used",
    dataIndex: "Used",
    sorter: {
      compare: (a, b) => {
        a = a.Used.toLowerCase();
        b = b.Used.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Valid Date",
    dataIndex: "Valid_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Valid_Date.toLowerCase();
        b = b.Valid_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Services Name",
    dataIndex: "Services_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Services_Name.toLowerCase();
        b = b.Services_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];

const data = [
  {
    Name: "Black Friday",
    Code: "GIFTGUIDE",
    Type: "Percentage",
    Discount: "3%",
    limit: "100",
    Used: "1",
    Valid_Date: "23 Sep 2023 - 27 Sep 2023\nOnce per customer",
    Services_Name: "Car Repair",
    Class: "badge-active",
    Status: "Action",
  },
  {
    Name: "Christmas",
    Code: "JOLLY15",
    Type: "Fixed",
    Discount: "2%",
    limit: "100",
    Used: "1",
    Valid_Date: "04 Sep 2023 - 12 Sep 2023\nOnce per customer",
    Services_Name: "House Cleaning +1 more",
    Class: "badge-inactive",
    Status: "Inaction",
  },
  {
    Name: "Halloween",
    Code: "SPOOKY15",
    Type: "Fixed",
    Discount: "3%",
    limit: "100",
    Used: "1",
    Valid_Date: "23 Sep 2023 - 27 Sep 2023\nOnce per customer",
    Services_Name: "Computer Repair  +1 more",
    Class: "badge-inactive",
    Status: "Inaction",
  },
  {
    Name: "New Year",
    Code: "NEW2023",
    Type: "Percentage",
    Discount: "5%",
    limit: "100",
    Used: "1",
    Valid_Date: "02 Sep 2023 - 10 Sep 2023\nOnce per customer",
    Services_Name: "All Services",
    Class: "badge-active",
    Status: "Action",
  },

  {
    Name: "Valentines",
    Code: "LOVER",
    Type: "Percentage",
    Discount: "10%",
    limit: "100",
    Used: "1",
    Valid_Date: "23 Sep 2023 - 27 Sep 2023\nOnce per customer",
    Services_Name: "All Services",
    Class: "badge-active",
    Status: "Action",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
      delete_title: "Delete Coupons",
      delete_text: "Are you sure want to Delete?",
    };
  },
};
</script>
