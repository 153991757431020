<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>
      <div class="page-wrapper background" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }">
        <div class="content">
          
          <div class="container">			
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <div class="user-infos">
                    <span class="user-img">
                      <img
                        src="@/assets/img/profiles/avatar-02.jpg"
                        class="rounded-circle"
                        alt=""
                      />
                    </span>
                    <div class="user-info">
                      <p>Selamat Datang!</p>
                      <h5 v-if="userLogin !== null">{{ userLogin.full_name }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-md-6 col-lg-3" v-for="(item, index) in menuItems" :key="index">
                <router-link :to="item.route" class="feature-box aos" data-aos="fade-up">
                  <div class="feature-icon">
                    <span>
                      <img :src="require(`@/assets/img/mari/menu/${item.icon}.svg`)" alt="img">
                    </span>
                  </div>
                  <h5>{{ item.title }}</h5>
                  <div class="feature-overlay">
                    <img src="@/assets/img/mari/menu/slide.svg" alt="img">
                  </div>
                </router-link>
              </div> -->
              <!-- <div class="col-md-6 col-lg-3">
                <router-link class="feature-box aos" data-aos="fade-up" 
                    :data-bs-toggle="'modal'"
                    :data-bs-target="dataExists ? '#update-absen-driver' : '#absen-driver'"
                >
                  <div class="feature-icon">
                    <span>
                      <img :src="require(`@/assets/img/mari/menu/User.svg`)" alt="img">
                    </span>
                  </div>
                  <h5>Absen</h5>
                  <div class="feature-overlay">
                    <img src="@/assets/img/mari/menu/slide.svg" alt="img">
                  </div>
                </router-link>
              </div> -->
              <div class="col-md-6 col-lg-3" v-if="userLogin">
                <router-link :to="`/mari/staff/profile/${userLogin.id}`" class="feature-box aos" data-aos="fade-up">
                  <div class="feature-icon">
                    <span>
                      <img :src="require(`@/assets/img/mari/menu/User.svg`)" alt="img">
                    </span>
                  </div>
                  <h5>Profile</h5>
                  <div class="feature-overlay">
                    <img src="@/assets/img/mari/menu/slide.svg" alt="img">
                  </div>
                </router-link>
              </div>
              <div class="col-md-6 col-lg-3">
                <router-link to="/mari/staff/order" class="feature-box aos" data-aos="fade-up">
                  <div class="feature-icon">
                    <span>
                      <img :src="require(`@/assets/img/mari/menu/order.svg`)" alt="img">
                    </span>
                  </div>
                  <h5>Order</h5>
                  <div class="feature-overlay">
                    <img src="@/assets/img/mari/menu/slide.svg" alt="img">
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <report-driver-model :dataAbsen="dataAbsen" @dataUpdated="getAbsen"></report-driver-model>
      <mari-footer></mari-footer>
    </div>
  </template>
  
  <script scope>
  
  export default {
    data() {
      return {
        // menuItems: [
        //   { title: 'Monitoring', icon: 'monitoring', route: '/mari/monitoring' }, 
        //   { title: 'Order', icon: 'order', route: '/mari/driver/order' },
          
        // ],
        userLogin: null,
        guideStep: 1,
        backgroundImage: require(`@/assets/img/mari/background.svg`),
        dataExists: false,
        dataAbsen: null,
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1,
        },
      };
    },
    methods: {
      reloadRoute() {
        this.$router.push({ path: this.$route.path });
      },
      // checkUserGuide() {
      //   console.log("kesini")
      //   let loading = this.$loading.show();
      //   this.$store.dispatch("guide/getGuide", { id: this.userLogin.company_id }).then(
      //     (data) => {
      //         loading.hide();
      //         if (data.status == 200) {
      //           const dataGuide = data.data;
      //           if (dataGuide.is_profile == 1) {
      //             this.guideStep++;
      //           }
      //           if (dataGuide.is_product == 1) {
      //             this.guideStep++;
      //           }
      //           if (dataGuide.is_vehicle == 1) {
      //             this.guideStep++;
      //           }
      //           if (dataGuide.is_user == 1) {
      //             this.guideStep++;
      //           }
      //           if (dataGuide.is_customer == 1) {
      //             this.guideStep++;
      //           }
      //           this.$nextTick(() => {
      //             if (this.guideStep < 6) {
      //               console.log(true, "kadie")
      //               this.$store.commit('guide/setGuide', true);
      //               this.$router.replace({ path: "/mari/guide" });
      //             } else {
      //               this.$store.commit('guide/setGuide', false);
      //               this.$router.replace({ path: "/mari" });
      //             }
      //           });
      //         }
      //     },
      //     (error) => {
      //       console.log(error);
      //       loading.hide();
      //     }
      //   );
      // },
      async getAbsen() {
        var loading = this.$loading.show();
        try {
          const resp = await this.$store.dispatch("driver/getAbsenDriver", this.params);
          this.dataAbsen = resp.data.data;
          const today = new Date().toISOString().split('T')[0];
          this.dataExists = !!this.dataAbsen.find(entry => entry.absen_masuk === today);
          this.dataAbsen = this.dataAbsen.find(entry => entry.absen_masuk === today);
        } catch (e) {
          this.$toast.error(e);
        } finally {
          loading.hide();
        }
      },
    },
    mounted() {
        const userData = localStorage.getItem('user');
        if (userData) {
            const user = JSON.parse(userData);
            this.userLogin = user;
            this.params.keyword = user.id;
            this.getAbsen();
        }
    },
  };
  </script>
  
  <style scoped>
    .feature-icon span {
      background: #f7f7ff00;
    }
  
    .background {
      /* Full height */
      height: 100%; 
  
      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  </style>
  