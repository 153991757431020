<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>Coupons</h6>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div class="review-sort d-flex align-items-center me-2">
            <p>Sort</p>
            <vue-select :options="abcd" placeholder="A -> Z" />
          </div>
          <div class="grid-listview me-2">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <i class="feather-filter"></i>
                </a>
              </li>
            </ul>
          </div>
          <button
            class="btn btn-primary add-set"
            data-bs-toggle="modal"
            data-bs-target="#add-coupon"
          >
            <i class="feather-plus"></i> Add Coupon
          </button>
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <!-- Coupons -->
    <div class="row">
      <div class="col-md-12">
        <div class="provide-table manage-table">
          <div class="table-responsive">
            <div class="table custom-table datatable mb-0" id="data-table">
              <a-table :columns="columns" :data-source="data">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Valid_Date'">
                    <td>
                      {{ record.Valid_Date }}
                      <span class="d-block">{{ record.Valid }}</span>
                    </td>
                  </template>
                  <template v-else-if="column.key === 'Status'">
                    <td>
                      <span :class="record.Class">{{ record.Status }}</span>
                    </td>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="table-actions">
                      <a
                        class="action-set"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#edit-coupon"
                      >
                        <i class="feather-edit"></i>
                      </a>
                      <a
                        class="action-set confirm-text"
                        href="javascript:;"
                        @click="showAlert"
                      >
                        <i class="feather-trash-2"></i>
                      </a>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div id="tablelength"></div>
          </div>
          <div class="col-md-9">
            <div class="table-ingopage">
              <div id="tableinfo"></div>
              <div id="tablepagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Coupons -->
  </div>
  <provider-coupons-model></provider-coupons-model>
</template>

<script>
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Code",
    dataIndex: "Code",
    sorter: {
      compare: (a, b) => {
        a = a.Code.toLowerCase();
        b = b.Code.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "Type",
    sorter: {
      compare: (a, b) => {
        a = a.Type.toLowerCase();
        b = b.Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Discount",
    dataIndex: "Discount",
    sorter: {
      compare: (a, b) => {
        a = a.Discount.toLowerCase();
        b = b.Discount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Limit",
    dataIndex: "limit",
    sorter: {
      compare: (a, b) => {
        a = a.limit.toLowerCase();
        b = b.limit.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Used",
    dataIndex: "Used",
    sorter: {
      compare: (a, b) => {
        a = a.Used.toLowerCase();
        b = b.Used.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Valid Date",
    dataIndex: "Valid_Date",
    key: "Valid_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Valid_Date.toLowerCase();
        b = b.Valid_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Valid",
    dataIndex: "Valid",
    key: "Valid",
    sorter: {
      compare: (a, b) => {
        a = a.Valid.toLowerCase();
        b = b.Valid.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Services Name",
    dataIndex: "Services_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Services_Name.toLowerCase();
        b = b.Services_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    Name: "Black Friday	",
    Code: "GIFTGUIDE",
    Type: "Percentage",
    Discount: "5%",
    limit: "100",
    Used: "1",
    Valid_Date: "20 Sep 2023 - 22 Sep 2023",
    Valid: "Once per customer",
    Services_Name: "Car Repair",
    Class: "badge-success",
    Status: "Action",
    Action: "",
  },
  {
    Name: "Christmas",
    Code: "JOLLY15",
    Type: "Fixed",
    Discount: "$2.00",
    limit: "100",
    Used: "1",
    Valid_Date: "04 Sep 2023 - 12 Sep 2023",
    Valid: "Once per customer",
    Services_Name: "House Cleaning",
    Status: "Inaction",
    Class: "badge-danger",
    Action: "",
  },

  {
    Name: "Halloween",
    Code: "SPOOKY15",
    Type: "Fixed",
    Discount: "$3.00",
    limit: "100",
    Used: "1",
    Valid_Date: "15 Sep 2023 - 12 Sep 2023",
    Valid: "Once per customer",
    Services_Name: "Computer Services",
    Class: "badge-success",
    Status: "Inaction",
    Action: "",
  },
  {
    Name: "New Year",
    Code: "NEW2023",
    Type: "Percentage",
    Discount: "5%",
    limit: "100",
    Used: "1",
    Valid_Date: "02 Sep 2023 - 10 Sep 2023",
    Valid: "Once per customer",
    Services_Name: "Interior Designing",
    Class: "badge-success",
    Status: "Action",
    Action: "",
  },
  {
    Name: "Valentines",
    Code: "LOVERLOVER",
    Type: "Percentage",
    Discount: "5%",
    limit: "100",
    Used: "1",
    Valid_Date: "10 Sep 2023 - 20 Sep 2023",
    Valid: "Once per customer",
    Services_Name: "Car Wash",
    Class: "badge-danger",
    Status: "Action",
    Action: "",
  },
];
export default {
  data() {
    return {
      abcd: ["A -> Z", "Z -> A", "Most helful"],
      columns,
      data,
    };
  },
  methods: {
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#35a7f4",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.");
        }
      });
    },
  },
};
</script>
