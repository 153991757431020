<template>
    <div class="col-lg-8 col-md-12 blog-details">

        <div class="blog-head">
            <div class="blog-category">
                <ul>
                    <li><span class="cat-blog">Construction</span></li>
                </ul>
            </div>
            <h3>Lorem ipsum dolor sit amet, eiusmod tempor ut labore et dolore
                magna aliqua. </h3>
            <div class="blog-category sin-post">
                <ul>
                    <li><i class="feather-calendar me-1"></i>28 Sep 2023</li>
                    <li>
                        <div class="post-author">
                            <a href="javascript:;"><img src="@/assets/img/profiles/avatar-02.jpg"
                                    alt="Post Author"><span>Admin</span></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Blog Post -->
        <div class="blog blog-list">
            <div class="blog-image">
                <router-link to="/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-04.jpg" alt="Post Image"></router-link>
            </div>
            <div class="blog-content">
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
                    rem aperiam, eaque ipsa aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.</p>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
                    rem aperiam, eaque ipsa aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt.</p>
                <p class="test-info">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                    laudantium, totam rem aperiam, eaque ipsa aspernatur aut odit aut fugit, sed quia consequuntur magni
                    dolores eos qui ratione voluptatem sequi nesciunt.</p>
            </div>
        </div>
        <!-- /Blog Post -->

        <div class="social-widget blog-review">
            <h4>Tags</h4>
            <div class="ad-widget">
                <ul>
                    <li>
                        <a href="javascript:void(0);">Construction</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">Car Wash</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">Appliance</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">Interior</a>
                    </li>
                    <li>
                        <a href="javascript:void(0);">Carpentry</a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Reviews -->
        <div class="service-wrap blog-review">
            <h4>Comments</h4>
            <ul>
                <li>
                    <div class="review-box">
                        <div class="review-profile">
                            <div class="review-img">
                                <img src="@/assets/img/profiles/avatar-02.jpg" class="img-fluid" alt="img">
                                <div class="review-name">
                                    <h6>Dennis</h6>
                                    <p>a week ago</p>
                                </div>
                            </div>
                            <a href="javascript:void(0);" class="reply-box" @click="toggleReply"><i class="fas fa-reply me-2"></i> Reply</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqa. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                            nisi ut aliquip ex ea commodo consequat</p>
                        <div class="new-comment reply-com">
                            <form>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Name*</label>
                                            <input type="text" class="form-control" placeholder="Enter Your Name" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Email*</label>
                                            <input type="email" class="form-control" placeholder="Enter Email Address" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Website</label>
                                            <input type="email" class="form-control" placeholder="Enter Your Website" />
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="col-form-label">Message*</label>
                                            <textarea rows="4" class="form-control"
                                                placeholder="Enter Your Comment Here...."></textarea>
                                        </div>
                                    </div>
                                    <div class="submit-section">
                                        <b-button variant="primary submit-btn" type="submit">Send Message</b-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <ul class="comments-reply">
                        <li>
                            <div class="review-box">
                                <div class="review-profile">
                                    <div class="review-img">
                                        <img src="@/assets/img/profiles/avatar-02.jpg" class="img-fluid" alt="img">
                                        <div class="review-name">
                                            <h6>Dennis</h6>
                                            <p>a week ago</p>
                                        </div>
                                    </div>
                                    <a href="javascript:void(0);" class="reply-box" @click="toggleReply"><i class="fas fa-reply me-2"></i> Reply</a>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt
                                    ut labore et dolore magna aliqa. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <div class="review-box">
                        <div class="review-profile">
                            <div class="review-img">
                                <img src="@/assets/img/profiles/avatar-03.jpg" class="img-fluid" alt="img">
                                <div class="review-name">
                                    <h6>Jaime</h6>
                                    <p>a week ago</p>
                                </div>
                            </div>
                            <a href="javascript:void(0);" class="reply-box" @click="toggleReply"><i class="fas fa-reply me-2"></i> Reply</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqa. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                            nisi ut aliquip ex ea commodo consequat</p>
                        <div class="new-comment reply-com">
                            <form>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Name*</label>
                                            <input type="text" class="form-control" placeholder="Enter Your Name" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Email*</label>
                                            <input type="email" class="form-control" placeholder="Enter Email Address" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Website</label>
                                            <input type="email" class="form-control" placeholder="Enter Your Website" />
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="col-form-label">Message*</label>
                                            <textarea rows="4" class="form-control"
                                                placeholder="Enter Your Comment Here...."></textarea>
                                        </div>
                                    </div>
                                    <div class="submit-section">
                                        <b-button variant="primary submit-btn" type="submit">Send Message</b-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="review-box">
                        <div class="review-profile">
                            <div class="review-img">
                                <img src="@/assets/img/profiles/avatar-07.jpg" class="img-fluid" alt="img">
                                <div class="review-name">
                                    <h6>Joseph</h6>
                                    <p>a week ago</p>
                                </div>
                            </div>
                            <a href="javascript:void(0);" class="reply-box" @click="toggleReply"><i class="fas fa-reply me-2"></i> Reply</a>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqa. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                            nisi ut aliquip ex ea commodo consequat</p>
                        <div class="new-comment reply-com">
                            <form>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Name*</label>
                                            <input type="text" class="form-control" placeholder="Enter Your Name" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Email*</label>
                                            <input type="email" class="form-control" placeholder="Enter Email Address" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="col-form-label">Website</label>
                                            <input type="email" class="form-control" placeholder="Enter Your Website" />
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="col-form-label">Message*</label>
                                            <textarea rows="4" class="form-control"
                                                placeholder="Enter Your Comment Here...."></textarea>
                                        </div>
                                    </div>
                                    <div class="submit-section">
                                        <b-button class="primary submit-btn" type="submit">Send Message</b-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- /Reviews -->

        <!-- Comments -->
        <div class="new-comment">
            <h4>Write A Comments</h4>
            <form>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label">Name*</label>
                            <input type="text" class="form-control" placeholder="Enter Your Name" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-form-label">Email*</label>
                            <input type="email" class="form-control" placeholder="Enter Email Address" />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="col-form-label">Message*</label>
                        <textarea rows="6" class="form-control" placeholder="Enter Your Comment Here...."></textarea>
                    </div>
                </div>
                <div class="submit-section">
                    <b-button variant="primary submit-btn" type="submit">Post Comment</b-button>
                </div>
            </div>
        </form>
    </div>
    <!-- /Comments -->

</div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        toggleReply(event) {
            const replyBox = event.target;
            const reviewBox = replyBox.closest('.review-box');
            const replyCom = reviewBox.querySelector('.reply-com');
            replyCom.style.display = replyCom.style.display === 'none' ? 'block' : 'none';
        }
    }
}
</script>