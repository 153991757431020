<template>
  <div class="main-wrapper">
    <custombookcalendarnav />

    <div class="page-wrapper">
      <customerbookingcontent />

      <customeraddreview />

      <customnotifydeleteacc />
    </div>
  </div>
  <c-booking-model></c-booking-model>
</template>
