<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Providers Earnings</h5>
          <div class="list-btn">
            <ul>
              <li>
                <a class="btn-downloads down-pdf" href="javascript:;"
                  ><img src="@/assets/admin_img/icons/pdf-icon.svg" alt="img" /> Download
                  as PDF</a
                >
              </li>
              <li>
                <a class="btn-downloads down-excel" href="javascript:;"
                  ><img src="@/assets/admin_img/icons/excel-icon.svg" alt="img" />Download
                  as Excel
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <!-- Chart -->
            <div class="card card-chart-blk">
              <div class="card-head-blk">
                <div class="row align-items-center">
                  <div class="col-xl-6 col-md-6">
                    <div class="card-table-head">
                      <h4>Providers Earnings</h4>
                      <ul>
                        <li><span class="earning-black"></span>Earning</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-6">
                    <div class="earning-group">
                      <div class="sortby-section invoice-sort">
                        <div class="sorting-info">
                          <div class="sortby-filter-group court-sortby">
                            <div class="sortbyset week-bg me-0">
                              <div class="sorting-select">
                                <vue-select
                                  :options="ServiceDate"
                                  id="servicedate"
                                  placeholder="Date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sortby-section invoice-sort">
                        <div class="sorting-info">
                          <div class="sortby-filter-group court-sortby">
                            <div class="sortbyset week-bg me-0">
                              <div class="sorting-select">
                                <vue-select
                                  :options="ServiceMonth"
                                  id="servicemonth"
                                  placeholder="July"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sortby-section invoice-sort">
                        <div class="sorting-info">
                          <div class="sortby-filter-group court-sortby">
                            <div class="sortbyset week-bg me-0">
                              <div class="sorting-select">
                                <vue-select
                                  :options="ServiceYear"
                                  id="serviceYear"
                                  placeholder="2023"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsBar.chart"
                  :series="optionsBar.series"
                >
                </apexchart>
              </div>
            </div>
            <!-- /Chart -->
          </div>
        </div>
        <div class="card card-status">
          <div class="card-head-blk border-bottom-0">
            <div class="row align-items-center">
              <div class="col-xl-12 col-md-12">
                <div class="earning-group">
                  <div class="sortby-section status-provider">
                    <div class="sorting-info">
                      <div class="sortby-filter-group court-sortby">
                        <div class="sortbyset week-bg me-0">
                          <div class="sorting-select">
                            <vue-select
                              :options="ServiceSelect"
                              id="serviceselect"
                              placeholder="Select Service"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sortby-section status-provider">
                    <div class="sorting-info">
                      <div class="sortby-filter-group court-sortby">
                        <div class="sortbyset week-bg me-0">
                          <div class="sorting-select">
                            <vue-select
                              :options="ServiceProvider"
                              id="serviceprovider"
                              placeholder="Select Provider"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="chart-date-group">
                    <div class="cal-icon">
                      <datepicker
                        v-model="startdate"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        placeholder="Add Date"
                      />
                    </div>
                  </div>
                  <div class="chart-date-group">
                    <div class="cal-icon">
                      <datepicker
                        v-model="startdate1"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        placeholder="Add Date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Service'">
                      <a href="javascript:void(0);" class="table-imgname">
                        <img
                          :src="require(`@/assets/admin_img/services/${record.Image}`)"
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Service }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <h6 class="badge-active completed-active-badge">
                        {{ record.Status }}
                      </h6>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User",
    dataIndex: "User",
    sorter: {
      compare: (a, b) => {
        a = a.User.toLowerCase();
        b = b.User.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payment Type",
    dataIndex: "PaymentType",
    sorter: {
      compare: (a, b) => {
        a = a.PaymentType.toLowerCase();
        b = b.PaymentType.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    id: "1",
    Image: "service-03.jpg",
    Service: "Computer Repair",
    User: "Mark",
    Amount: "$80",
    PaymentType: "COD",
    Status: "Completed",
    Earned_Amount: "$38.25",
    Date: "07 Oct 2023",
  },
  {
    id: "2",
    Image: "service-02.jpg",
    Service: "Car Repair Services",
    User: "Bernice",
    Amount: "$50",
    PaymentType: "Wallet",
    Status: "Completed",
    Earned_Amount: "$20",
    Date: "07 Oct 2023",
  },
  {
    id: "3",
    Image: "service-04.jpg",
    Service: "Steam Car Wash",
    User: "Arlene",
    Amount: "$14",
    PaymentType: "Wallet",
    Status: "Completed",
    Earned_Amount: "$10",
    Date: "17 Oct 2023",
  },
  {
    id: "4",
    Image: "service-09.jpg",
    Service: "House Cleaning",
    User: "Margarett",
    Amount: "$100",
    PaymentType: "COD",
    Status: "Completed",
    Earned_Amount: "$90",
    Date: "17 Oct 2023",
  },
  {
    id: "5",
    Image: "service-03.jpg",
    Service: "Computer Repair",
    User: "Kevin",
    Amount: "$80",
    PaymentType: "COD",
    Status: "Completed",
    Earned_Amount: "$38.25",
    Date: "07 Oct 2023",
  },
  {
    id: "6",
    Image: "service-02.jpg",
    Service: "Car Repair Services",
    User: "Willard",
    Amount: "$50",
    PaymentType: "Wallet",
    Status: "Completed",
    Earned_Amount: "$20",
    Date: "07 Oct 2023",
  },
  {
    id: "7",
    Image: "service-04.jpg",
    Service: "Steam Car Wash",
    User: "Emma",
    Amount: "$14",
    PaymentType: "COD",
    Status: "Completed",
    Earned_Amount: "$10",
    Date: "17 Oct 2023",
  },
  {
    id: "8",
    Image: "service-09.jpg",
    Service: "House Cleaning",
    User: "Eva",
    Amount: "$100",
    PaymentType: "Wallet",
    Status: "Completed",
    Earned_Amount: "$90",
    Date: "17 Oct 2023",
  },
];
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDate1 = ref(new Date());
import { optionsBar } from "./data";
export default {
  data() {
    return {
      ServiceDate: ["Date", "01", "02", "03"],
      ServiceMonth: ["July", "Aug", "Sep"],
      ServiceYear: ["2023", "2022", "2021"],
      ServiceSelect: ["Select Status", "Completed", "UnCompleted"],
      ServiceProvider: ["Jerry", "Pauline", "Robert"],
      Select: ["A - Z", "Z - A"],
      columns,
      data,
      startdate: currentDate,
      startdate1: currentDate1,
      optionsBar: optionsBar,
      title: "Charts",
      dateFormat: "dd-MM-yyyy",
    };
  },
  name: "adminprovider-earnings",
};
</script>
