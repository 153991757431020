<template>
    <!-- Content -->
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6" v-for="item in ProviderList" :key="item.id">
                    <div class="providerset">
                        <div class="providerset-img">
                            <router-link to="/provider-details">
                                <img :src="require(`@/assets/img/provider/${item.image}`)" alt="img">
                            </router-link>
                        </div>
                        <div class="providerset-content">
                            <div class="providerset-price">
                                <div class="providerset-name">
                                    <h4><router-link to="/provider-details">{{item.name}}</router-link><i class="fa fa-check-circle"
                                            aria-hidden="true"></i></h4>
                                    <span>{{item.profession}}</span>
                                </div>
                                <div class="providerset-prices">
                                    <h6>{{item.hourlyRate}}<span>/hr</span></h6>
                                </div>
                            </div>
                            <div class="provider-rating">
                                <div class="rating">
                                    <i class="fa-solid fa-star filled me-1"></i>
                                    <i class="fa-solid fa-star filled me-1"></i>
                                    <i class="fa-solid fa-star filled me-1"></i>
                                    <i class="fa-solid fa-star filled me-1"></i>
                                    <i class="fa-solid fa-star-half-stroke filled"></i><span>({{item.totalReviews}})</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="blog-pagination">
                        <nav>
                            <ul class="pagination justify-content-center">
                                <li class="page-item disabled">
                                    <a class="page-link page-prev" href="javascript:;" tabindex="-1"><i
                                            class="fa-solid fa-arrow-left me-2"></i> PREV</a>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="javascript:;">1</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="javascript:;">2</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="javascript:;">3</a>
                                </li>
                                <li class="page-item">
                                <a class="page-link page-next" href="javascript:;">NEXT <i
                                        class="fa-solid fa-arrow-right ms-2"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- /Content -->
</template>

<script>
import ProviderList from '@/assets/json/providerlist.json'
export default {
    data() {
        return {
            ProviderList: ProviderList
        }
    },
}
</script>