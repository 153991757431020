<template>
    <!-- Add Vendor -->
    <div class="modal fade" id="add-vendor" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Vendor</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="mb-3">
                <label class="form-label">Nama Lengkap</label>
                <input type="text" class="form-control" v-model="formCreate.full_name"/>
              </div>
              <div class="mb-3">
                <label class="form-label">No Telepon</label>
                <input type="text" class="form-control" v-model="formCreate.phone_number"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Alamat</label>
                <input type="text" class="form-control" v-model="formCreate.address"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Type</label>
                <select class="form-select" v-model="formCreate.type">
                    <option value=1>Investor</option>
                    <option value=2>Rent To Rent</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Photo</label>
                    <div class="profile-upload-content">
                        <!-- company logo here -->
                        <img v-if="logo" :src="logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                        <!-- company logo here -->

                        <!-- upload file form logo here -->
                        <div class="profile-upload-btn">
                            <div class="profile-upload-file">
                            <input
                                type="file"
                                class="form-control"
                                ref="uploadFieldAttachment"
                                id="imgInp"
                                @change="selectFileAttachment"
                            />
                            <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                            </div>
                        </div>
                        <!-- upload file form logo here -->
                        <div class="profile-upload-para">
                            <p>
                            *ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                        File yang diizinkan .png dan .jpg.
                            </p>
                        </div>
                    </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Vendor -->
  
    <!-- Edit Vendor -->
    <div class="modal fade" id="edit-vendor" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Vendor</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="mb-3">
                <label class="form-label">Nama Lengkap</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.full_name"/>
              </div>
              <div class="mb-3">
                <label class="form-label">No Telepon</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Alamat</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.address"/>
              </div>
              <div class="mb-3">
                <label class="form-label">Type</label>
                <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.type">
                    <option value=1 :selected="formEdit.type === 1">Investor</option>
                    <option value=2 :selected="formEdit.type === 2">Rent To Rent</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Photo</label>
                    <div class="profile-upload-content">
                        <!-- company logo here -->
                        <img v-if="(formEdit !== undefined) && (formEdit.photo !== '')" :src="formEdit.photo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                        <!-- company logo here -->
                        <!-- upload file form logo here -->
                        <div class="profile-upload-btn">
                            <div class="profile-upload-file">
                            <input
                                type="file"
                                class="form-control"
                                ref="uploadFieldAttachment"
                                id="imgInp"
                                @change="selectFileAttachment"
                            />
                            <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                            </div>
                        </div>
                        <!-- upload file form logo here -->
                        <div class="profile-upload-para">
                            <p>
                            *ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                        File yang diizinkan .png dan .jpg.
                            </p>
                        </div>
                    </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Vendor -->
  
    <div class="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Vendor ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="invoice-vendor-pdf" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="d-flex">
            <button class="btn btn-primary" @click="downloadPdf">Download</button>
          </div>
          <div id="invoice-vendor-pdf-content" v-if="invoicePdfData">
                <div class="d-flex align-items-center">
                    <span class="me-4">
                        <img style="width: 100px; height: 100px; object-fit: contain;" class="logo" :src="invoicePdfData.vendor.company && invoicePdfData.vendor.company.logo ? invoicePdfData.vendor.company.logo : require('@/assets/img/mari/logo_marirental3.svg')" />
                    </span>
                    <span>
                        <p class="mb-0" style="font-weight: bold;">{{ invoicePdfData.vendor.company ? invoicePdfData.vendor.company.name : 'Mari Rental'}}</p>
                        <p class="mb-0">Alamat: {{ invoicePdfData.vendor.company ? invoicePdfData.vendor.company.address : 'Bandung' }}</p>
                        <p class="mb-0">Tel: {{ invoicePdfData.vendor.company ? invoicePdfData.vendor.company.phone_number : '123123123' }}</p>
                    </span>
                </div>

                <div class="hr" style="margin-top: 20px; margin-bottom: 20px;"></div>

                <p style="text-align: center;">Invoice</p>

                <div class="d-flex justify-content-between">
                    <span>
                        <p class="mb-0" style="font-weight: bold;">Diajukan Kepada:</p>
                        <p class="mb-0">{{ invoicePdfData.vendor.full_name }}</p>
                    </span>
                    <span style="text-align: right;">
                        <p class="mb-0" style="font-weight: bold;">No. Invoice:</p>
                        <p class="mb-0">123123123</p>
                    </span>
                </div>

                <p v-if="invoicePdfData.vendor_orders.length < 1" style="margin-top: 40px; margin-bottom: 40px; text-align: center; font-weight: bold">Tidak ada order</p>

                <div v-else style="overflow-x: auto;">
                  <table style="margin-top: 20px;" class="mytable table-bordered">
                      <thead>
                          <tr>
                              <th>Unit</th>
                              <th>Tanggal Pemakaian</th>
                              <th>Keterangan</th>
                              <th>Harga</th>
                              <th>Lama Pemakaian</th>
                              <th>Jumlah</th>
                          </tr>
                      </thead>
                      <tbody>
                              <tr v-for="(order, i) of invoicePdfData.vendor_orders">
                                  <td>{{ order['unit'] }} - {{ order['no_polisi'] }}</td>
                                  <td>{{ order['tanggal_pemakaian'] }}</td>
                                  <td>{{ order['keterangan'] }}</td>
                                  <td>{{ formatCurrency(order['harga']) }}</td>
                                  <td>{{ order['lama_pemakaian'] }}</td>
                                  <td>{{ formatCurrency(order['jumlah']) }}</td>
                              </tr>
                      </tbody>
                  </table>
                </div>

                <div class="hr" style="margin-top: 20px; margin-bottom: 20px;"></div>

                <table style="width: 100%;" class="table">
                    <tbody>
                        <tr>
                            <td style="text-align: right;">Pemakaian:</td>
                            <td>{{ 0 }}</td>
                        </tr>
                        <tr>
                            <td style="text-align: right; font-weight: bold;">Total bayar:</td>
                            <td>{{ formatCurrency(invoicePdfData.total_pay) }}</td>
                        </tr>
                        <tr>
                            <td style="text-align: right; font-weight: bold;">Sisa Tagihan:</td>
                            <td>{{ formatCurrency(invoicePdfData.sisa_tagihan) }}</td>
                        </tr>
                    </tbody>
                </table>

                <div style="margin-top: 20px;">
                    <p style="margin-bottom: 8px;">Pembayaran bisa dikirim ke:</p>
                    <p class="mb-0">Bank: {{ invoicePdfData.vendor.company && invoicePdfData.vendor.company.bank_account ? invoicePdfData.vendor.company.bank_account.toUpperCase() : 'MariBank' }}</p>
                    <p class="mb-0">No Rek: {{ invoicePdfData.vendor.company && invoicePdfData.vendor.company.no_rekening ?  invoicePdfData.vendor.company.no_rekening : '123123123123'}}</p>
                    <p class="mb-0">Atas Nama: {{ invoicePdfData.vendor.company && invoicePdfData.vendor.company.nama_pemilik_bank ? invoicePdfData.vendor.company.nama_pemilik_bank : 'Mari Rental' }}</p>
                </div>
                <div style="margin-top: 20px;">
                  <img v-if="invoicePdfData.vendor.company && invoicePdfData.vendor.company.stample"  class="logo" :src="invoicePdfData.vendor.company.stample" />
                </div>
              
            
          </div>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  import { formatCurrency } from '@/utils/currency'

  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {},
        dataRole: [],
        dataMenu: [],
        logo: '',
        invoicePdfData: null
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object,
      menuData: {
        type: Array,
        default: () => []
      },
      roleData: {
        type: Array,
        default: () => []
      },
      invoicePdfDataProp: Object
    },
    emits: ['edit-vendor'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
      invoicePdfDataProp: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.invoicePdfData = newValue;
          }
        }
      },
    },
    methods: {
      formatCurrency: formatCurrency,
      downloadPdf() {
        var opt = {
          margin: 0.5,
          filename: "file.pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 1, scrollY: 0, scrollX: 0 },
          jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };
        const el = document.getElementById('invoice-vendor-pdf-content')
        let loading = this.$loading.show();
        
        if (this.invoicePdfData.vendor.company && this.invoicePdfData.vendor.company.logo) {
          this.loadImageToCanvas(this.invoicePdfData.vendor.company.logo, (dataUrl) => {
            this.invoicePdfData.vendor.company.logo = dataUrl

            if (this.invoicePdfData.vendor.company.stample) {
              this.loadImageToCanvas(this.invoicePdfData.vendor.company.stample, (dataUrl2) => {
                this.invoicePdfData.vendor.company.stample = dataUrl2
                html2pdf()
                .set(opt)
                .from(el)
                .save()
                .then(() => {
                  loading.hide();
                });
              })
            } else {
              html2pdf()
                .set(opt)
                .from(el)
                .save()
                .then(() => {
                  loading.hide();
                });
            }
          })
        } else {
          html2pdf()
            .set(opt)
            .from(el)
            .save()
            .then(() => {
              loading.hide();
            });
        }
      },
      loadImageToCanvas(url, callback) {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Atur ini jika gambar berasal dari domain lain
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.fillStyle = 'white'; // Use white color for background
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0);
          callback(canvas.toDataURL('image/jpeg'));
        };
        img.onerror = () => {
          console.error('Gagal memuat gambar');
        };
        img.src = url;
      },
      validateFile(file, allowedFileTypes, maxFileSize) {
          if (!allowedFileTypes.includes(file.type)) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
              });
              return false;
          }

          if (file.size > maxFileSize) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
              });
              return false;
          }

          return true;
      },
    // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      editSubmit() {
        this.formEdit.photo = this.logo;
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("vendor/updateVendor", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {}
              this.logo = '';
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createSubmit() {
        this.formCreate.photo = this.logo;
        var loading = this.$loading.show()
        this.$store
          .dispatch("vendor/addVendor", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {}
              this.logo = '';
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteData() {
        // Perform deletion using this.deleteItemId
        console.log('Deleting item with ID:', this.deleteItemId);
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("vendor/deleteVendor", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
    },
  };
  </script>
  
  <style>
        .logo {
            width: 150px;
            height: 70px;
        }

        .mytable {
          text-align: center;
        }

        .mytable td {
          word-break: break-line !important;
        }

        .mytable th, .mytable td {
          padding-inline: 2px !important;
        }
  </style>
