<template>
    <!-- Show User -->
    <div class="modal fade" id="show-user" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail User</h5>
            <button
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                      <div class="profile-upload-content">
                        <img v-if="(formEdit !== undefined) && (formEdit.photo !== '')" :src="formEdit.photo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                        <img v-else :src="require(`@/assets/admin_img/customer/user-01.jpg`)" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                      </div>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Nama Lengkap</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.full_name" disabled/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Nomer Telepon (WA)</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number" disabled/>
                  </div>
                  <div class="col-6">
                      <label class="form-label">Email</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.email" disabled/>
                  </div>
                  </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Nik</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.nik" disabled/>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Jenis User</label>
                      <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.type" disabled>
                      <option v-for="role in filteredRoleData" :key="role.id" :value="role.id">{{ role.name }}</option>
                    </select>
                  </div>
                  </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div v-if="formEdit && (formEdit.type === driverRoleId)" class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Status Driver</label>
                      <input type="text" class="form-control" v-model="formEdit.status_mudi" disabled/>
                    </div>
                    <div class="col-6">

                    </div>
                  </div>
              </div>
              <div class="mb-3">
                  <div class="form-group">
                      <div class="d-flex">
                          <div class="col-6">
                              <div class="col-lg-12">
                                  <div class="split-check-title">
                                      <label class="form-label">Jenis Kelamin</label>
                                  </div>
                              </div>
                              <div class="col-lg-12">
                                  <div class="form-check form-check-inline">
                                      <input v-if="formEdit !== undefined" :checked="formEdit.gender === 'Laki - Laki'" class="form-check-input" type="radio" id="inlineRadio1" name="gender" value="Laki - Laki" v-model="formEdit.gender" disabled>
                                      <label class="form-check-label" for="inlineRadio1">Laki - Laki</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                      <input v-if="formEdit !== undefined" :checked="formEdit.gender === 'Perempuan'" class="form-check-input" type="radio" id="inlineRadio2" name="gender" value="Perempuan" v-model="formEdit.gender" disabled>
                                      <label class="form-check-label" for="inlineRadio2">Perempuan</label>
                                  </div>
                              </div>
                          </div>
                          <div class="col-6">
                              <label class="form-label">Tanggal Mulai Bekerja</label>
                              <input v-if="formEdit !== undefined" type="datetime-local" class="form-control"  v-model="formEdit.join_date" disabled/>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Fee Driver In</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_driver_in" disabled/>
                  </div>
                  <div>
                    <label class="form-label">Fee Driver Out</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_driver_out" disabled/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Fee Driver Guide</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_driver_guide" disabled/>
                  </div>
                  <div>
                    <label class="form-label">Fee Driver Tour</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_driver_tour" disabled/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="col-6">
                    <label class="form-label">Fee Operator</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_admin" disabled/>
                </div>
              </div> -->
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Show User -->

    <!-- Add User -->
    <div class="modal fade" id="add-user" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah User</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="col-12">
                <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                      <div class="mt-3">
                          <div class="profile-upload-content">
      
                              <!-- company logo here -->
                              <img v-if="logo" :src="logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                              <!-- company logo here -->
      
                              <!-- upload file form logo here -->
                              <div class="profile-upload-btn">
                                  <div class="profile-upload-file">
                                      <input
                                          type="file"
                                          class="form-control"
                                          ref="uploadFieldAttachment"
                                          id="imgInp"
                                          @change="selectFileAttachment"
                                      />
                                  </div>
                              </div>
                                  <!-- upload file form logo here -->
                              <div class="profile-upload-para">
                                  <p style="font-size: 12px;">
                                  <span style="color: red;">*</span>ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                                  File yang diizinkan .png dan .jpg.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-6">
                      <label class="form-label">Nama Lengkap</label>
                      <input type="text" class="form-control" v-model="formCreate.full_name"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Nomer Telepon (WA)</label>
                      <input type="text" class="form-control" v-model="formCreate.phone_number"/>
                  </div>
                  <div class="col-6">
                      <label class="form-label">Email</label>
                      <input type="text" class="form-control" v-model="formCreate.email"/>
                  </div>
                  </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Nik</label>
                      <input type="text" class="form-control" v-model="formCreate.nik"/>
                  </div>
                  <div class="col-6">
                      <label class="form-label">Password</label>
                      <input type="password" class="form-control" v-model="formCreate.password"/>
                  </div>
                  </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                        <label class="form-label">Ulangi Password</label>
                        <input type="password" class="form-control" v-model="formCreate.password_confirmation"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Jenis User</label>
                        <select class="form-select" v-model="formCreate.type">
                        <option v-for="role in filteredRoleData" :key="role.id" :value="role.id">{{ role.name }}</option>
                      </select>
                    </div>
                  </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div v-if="formCreate.type === driverRoleId" class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Status Driver</label>
                      <input type="text" class="form-control" v-model="formCreate.status_mudi"/>
                    </div>
                    <div class="col-6">

                    </div>
                  </div>
              </div>
              <div class="mb-3">
                  <div class="form-group">
                      <div class="d-flex">
                          <div class="col-6">
                              <div class="col-lg-12">
                                  <div class="split-check-title">
                                      <label class="form-label">Jenis Kelamin</label>
                                  </div>
                              </div>
                              <div class="col-lg-12">
                                  <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="radio" id="inlineRadio1" name="gender" value="Laki - Laki" v-model="formCreate.gender">
                                      <label class="form-check-label" for="inlineRadio1">Laki - Laki</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                      <input class="form-check-input" type="radio" id="inlineRadio2" name="gender" value="Perempuan" v-model="formCreate.gender">
                                      <label class="form-check-label" for="inlineRadio2">Perempuan</label>
                                  </div>
                              </div>
                          </div>
                          <div class="col-6">
                              <label class="form-label">Tanggal Mulai Bekerja</label>
                              <input type="datetime-local" class="form-control"  v-model="formCreate.join_date"/>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Fee Driver In</label>
                    <input type="number" class="form-control" v-model="formCreate.fee_driver_in"/>
                  </div>
                  <div>
                    <label class="form-label">Fee Driver Out</label>
                    <input type="number" class="form-control" v-model="formCreate.fee_driver_out"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Fee Driver Guide</label>
                    <input type="number" class="form-control" v-model="formCreate.fee_driver_guide"/>
                  </div>
                  <div>
                    <label class="form-label">Fee Driver Tour</label>
                    <input type="number" class="form-control" v-model="formCreate.fee_driver_tour"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="col-6">
                    <label class="form-label">Fee Operator</label>
                    <input type="number" class="form-control" v-model="formCreate.fee_admin"/>
                </div>
              </div> -->
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add User -->
  
    <!-- Edit User -->
    <div class="modal fade" id="edit-user" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit User</h5>
            <button
              id="close-edit"
              type="button"
              ref="closeButtonEditModalUser"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="col-12">
                <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                    <div class="mb-3">
                      <div class="profile-upload-content">
              
                          <!-- company logo here -->
                          <img v-if="(formEdit !== undefined) && (formEdit.photo !== '')" :src="formEdit.photo" alt="photo" class="img-thumbnail mb-3" width="100" height="100">
                          <img v-else-if="logo" :src="logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                          <!-- company logo here -->

                          <!-- upload file form logo here -->
                          <div class="profile-upload-btn">
                              <div class="profile-upload-file">
                                  <input
                                      type="file"
                                      class="form-control"
                                      ref="uploadFieldAttachment"
                                      id="imgInp"
                                      @change="selectFileAttachment"
                                  />
                              </div>
                          </div>
                              <!-- upload file form logo here -->
                          <div class="profile-upload-para">
                              <p style="font-size: 12px;">
                              <span style="color: red;">*</span>ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                              File yang diizinkan .png dan .jpg.
                              </p>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                      <label class="form-label">Nama Lengkap</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.full_name"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Nomer Telepon (WA)</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number"/>
                  </div>
                  <div class="col-6">
                      <label class="form-label">Email</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.email"/>
                  </div>
                  </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Nik</label>
                      <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.nik"/>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Jenis User</label>
                    <select v-if="formEdit !== undefined" class="form-select" v-model="formEdit.type">
                      <option v-for="role in filteredRoleData" :key="role.id" :value="role.id">{{ role.name }}</option>
                    </select>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div class="col-6" style="margin-right: 10px;">
                    <!-- <label class="form-label">Password</label>
                    <input v-if="formEdit !== undefined" type="password" class="form-control" v-model="formEdit.password"/> -->
                  </div>
                  <div class="col-6">
                    <!-- <label class="form-label">Ulangi Password</label>
                    <input v-if="formEdit !== undefined" type="password" class="form-control" v-model="formEdit.password_confirmation"/> -->
                  </div>
                  </div>
              </div>
              <div class="mb-3 form-group">
                  <div class="d-flex">
                    <div v-if="formEdit && (formEdit.type === driverRoleId)" class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Status Driver</label>
                      <input type="text" class="form-control" v-model="formEdit.status_mudi"/>
                    </div>
                    <div class="col-6">

                    </div>
                  </div>
              </div>
              <div class="mb-3">
                  <div class="form-group">
                      <div class="d-flex">
                          <div class="col-6">
                              <div class="col-lg-12">
                                  <div class="split-check-title">
                                      <label class="form-label">Jenis Kelamin</label>
                                  </div>
                              </div>
                              <div class="col-lg-12">
                                  <div class="form-check form-check-inline">
                                      <input v-if="formEdit !== undefined" :checked="formEdit.gender === 'Laki - Laki'" class="form-check-input" type="radio" id="inlineRadio1" name="gender" value="Laki - Laki" v-model="formEdit.gender">
                                      <label class="form-check-label" for="inlineRadio1">Laki - Laki</label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                      <input v-if="formEdit !== undefined" :checked="formEdit.gender === 'Perempuan'" class="form-check-input" type="radio" id="inlineRadio2" name="gender" value="Perempuan" v-model="formEdit.gender">
                                      <label class="form-check-label" for="inlineRadio2">Perempuan</label>
                                  </div>
                              </div>
                          </div>
                          <div class="col-6">
                              <label class="form-label">Tanggal Mulai Bekerja</label>
                              <input v-if="formEdit !== undefined" type="datetime-local" class="form-control"  v-model="formEdit.join_date"/>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Fee Driver In</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_driver_in"/>
                  </div>
                  <div>
                    <label class="form-label">Fee Driver Out</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_driver_out"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Fee Driver Guide</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_driver_guide"/>
                  </div>
                  <div>
                    <label class="form-label">Fee Driver Tour</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_driver_tour"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="col-6">
                    <label class="form-label">Fee Operator</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.fee_admin"/>
                </div>
              </div> -->
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit User -->
  
    <div class="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete User ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- change password User -->
    <div class="modal fade" id="change-password-user" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ganti Password User</h5>
            <button
              id="close-password"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="updatePassword">
              
              <div class="mb-3 form-group">
                  <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                    <label class="form-label">Password Baru</label>
                    <input type="password" class="form-control" v-model="formPassword.password"/>
                  </div>
                  <div class="col-6">
                    <label class="form-label">Ulangi Password Baru</label>
                    <input type="password" class="form-control" v-model="formPassword.password_confirmation"/>
                  </div>
                </div>
              </div>
              <!-- <div class="mb-3 form-group">
                <div class="d-flex">
                  <div class="col-6" style="margin-right: 10px;">
                      <label class="form-label">Password Lama</label>
                      <input type="password" class="form-control" v-model="formPassword.old_password"/>
                    </div>
                  </div>
              </div> -->
              
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Update Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /change password User -->

  </template>
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {},
        dataRole: [],
        dataMenu: [],
        logo: '',
        formPassword: {}
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object,
      menuData: {
        type: Array,
        default: () => []
      },
      roleData: {
        type: Array,
        default: () => []
      },
    },
    emits: ['edit-user'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
    },
    computed: {
      filteredRoleData() {
        const excludeName = 'SUPER ADMIN'; // The name of the role to exclude
        return this.roleData.filter(role => role.name !== excludeName);
      },
      driverRoleId() {
        const driverRole = this.filteredRoleData.find(role => role.name === 'Driver');
        return driverRole ? driverRole.id : null;
      }
    },
    methods: {
      validateFile(file, allowedFileTypes, maxFileSize) {
          if (!allowedFileTypes.includes(file.type)) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
              });
              return false;
          }

          if (file.size > maxFileSize) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
              });
              return false;
          }

          return true;
      },
      // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      editSubmit() {
        this.formEdit.photo = this.formEdit.photo !== '' ? this.formEdit.photo : this.logo;
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("user/updateUser", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {};
              this.logo = '';
              this.$refs.closeButtonEditModalUser.click()
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createSubmit() {
        this.formCreate.photo = this.formCreate.photo !== '' ? this.formCreate.photo : this.logo;
        var loading = this.$loading.show()
        this.$store
          .dispatch("user/addUser", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {};
              this.logo = '';
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteData() {
        // Perform deletion using this.deleteItemId
        console.log('Deleting item with ID:', this.deleteItemId);
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("user/deleteUser", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      updatePassword() {
        var loading = this.$loading.show()
        console.log(this.formEdit);
        const id = this.formEdit.id;
        this.$store
          .dispatch("user/updateUserPassword", { id: id, data: this.formPassword } )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formPassword = {};
              document.getElementById('close-password').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
    },
  };
  </script>
  