<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>Offer History</h6>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div class="review-sort d-flex align-items-center me-2">
            <p>Sort</p>
            <vue-select :options="abcd" placeholder="A -> Z" />
          </div>
          <div class="grid-listview me-2">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <img src="@/assets/img/icons/filter-icon.svg" alt="" />
                </a>
              </li>
            </ul>
          </div>
          <a
            href="javascript:;"
            data-bs-toggle="modal"
            data-bs-target="#add-coupon"
            class="btn btn-primary add-set"
            ><i class="feather-plus"> </i> New Offer</a
          >
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <!-- Coupons -->
    <div class="row">
      <div class="col-md-12">
        <div class="provide-table manage-table">
          <div class="table-responsive">
            <table class="table custom-table datatable mb-0" id="data-table">
              <a-table :columns="columns" :data-source="data">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Service'">
                    <h2 class="table-avatar table-sml">
                      <a href="javascript:;" class="avatar avatar-m me-2"
                        ><img
                          class="avatar-img rounded"
                          :src="require(`@/assets/img/services/${record.Image}`)"
                          alt="User Image"
                      /></a>
                      <a href="javascript:;">{{ record.Service }}</a>
                    </h2>
                  </template>
                  <template v-if="column.key === 'id'">
                    <div class="table-actions">
                      <a
                        class="action-set"
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#edit-coupon"
                      >
                        <i class="feather-edit"></i>
                      </a>
                      <a
                        class="action-set confirm-text"
                        href="javascript:;"
                        @click="showAlert"
                      >
                        <i class="feather-trash-2"></i>
                      </a>
                    </div>
                  </template>
                </template>
              </a-table>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div id="tablelength"></div>
          </div>
          <div class="col-md-9">
            <div class="table-ingopage">
              <div id="tableinfo"></div>
              <div id="tablepagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Coupons -->
  </div>
  <!-- Delete Account -->
  <div class="modal fade custom-modal" id="del-account">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 justify-content-between">
          <h5 class="modal-title">Delete Account ?</h5>
          <button
            type="button"
            class="close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="write-review">
            <form @submit.prevent="submitForm">
              <p>
                Are you sureyou want to delete This Account? To delete your account, Type
                your password.
              </p>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <div class="pass-group">
                  <input
                    type="password"
                    class="form-control pass-input"
                    placeholder="*************"
                  />
                  <span class="toggle-password feather-eye"></span>
                </div>
              </div>
              <div class="modal-submit text-end">
                <a
                  href="javascript:;"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  >Cancel</a
                >
                <b-button type="submit" variant="danger">Delete Account</b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Account -->
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Offer",
    dataIndex: "Offer",
    sorter: {
      compare: (a, b) => {
        a = a.Offer.toLowerCase();
        b = b.Offer.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Offer Price",
    dataIndex: "Offer_Price",
    sorter: {
      compare: (a, b) => {
        a = a.Offer_Price.toLowerCase();
        b = b.Offer_Price.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "End Date",
    dataIndex: "End_Date",
    sorter: {
      compare: (a, b) => {
        a = a.End_Date.toLowerCase();
        b = b.End_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
  },
];
const data = [
  {
    id: "1",
    Image: "service-06.jpg",
    Service: "Computer Repair",
    Amount: "$80",
    Offer: "25%",
    Offer_Price: "$20",
    Date: "28 Sep 2023",
    End_Date: "28 Sep 2023",
    Action: "",
  },
  {
    id: "2",
    Image: "service-04.jpg",
    Service: "Car Repair Services",
    Amount: "$50",
    Offer: "10%",
    Offer_Price: "$5",
    Date: "17 Sep 2023",
    End_Date: "17 Sep 2023",
    Action: "",
  },
  {
    id: "3",
    Image: "service-08.jpg",
    Service: "Steam Car Wash",
    Amount: "$14",
    Offer: "10%",
    Offer_Price: "$2",
    Date: "13 Sep 2023",
    End_Date: "13 Sep 2023",
    Action: "",
  },
  {
    id: "4",
    Image: "service-09.jpg",
    Service: "House Cleaning",
    Amount: "$100",
    Offer: "35%",
    Offer_Price: "$25",
    Date: "7 Sep 2023",
    End_Date: "7 Sep 2023",
    Action: "",
  },
  {
    id: "5",
    Image: "service-02.jpg",
    Service: "Building Construction",
    Amount: "$75",
    Offer: "20%",
    Offer_Price: "$10",
    Date: "1 Sep 2023",
    End_Date: "1 Sep 2023",
    Action: "",
  },
  {
    id: "6",
    Image: "service-07.jpg",
    Service: "Interior Designing",
    Amount: "$50",
    Offer: "5%",
    Offer_Price: "$6",
    Date: "30 Aug 2023",
    End_Date: "30 Aug 2023",
    Action: "",
  },
  {
    id: "7",
    Image: "service-05.jpg",
    Service: "Commercial Painting",
    Amount: "$500",
    Offer: "35%",
    Offer_Price: "$60",
    Date: "22 Aug 2023",
    End_Date: "22 Aug 2023",
    Action: "",
  },
  {
    id: "8",
    Image: "service-11.jpg",
    Service: "Plumbing Services",
    Amount: "$150",
    Offer: "25%",
    Offer_Price: "$40",
    Date: "15 Aug 2023",
    End_Date: "15 Aug 2023",
    Action: "",
  },
];
export default {
  data() {
    return {
      abcd: ["A -> Z", "Z -> A", "Most helful"],
      columns,
      data,
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/login");
    },
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#35a7f4",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.");
        }
      });
    },
  },
};
</script>
