const optionsBar = {
    chart: {
      type: 'bar',
      height: 350,
      width: '100%',
      stacked: false,
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    series: [{
      color: '#000',
      data: [420, 532, 516, 575, 519, 517, 454, 392, 262, 383, 446, 551],
    }],
    labels: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020],
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: '#777'
        }
      }
    },
    title: {
      text: '',
      align: 'left',
      style: {
        fontSize: '18px'
      }
    }

  }
  const Customerbar = {
    chart: {
      type: 'bar',
      height: 350,
      width: '100%',
      stacked: false,
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        columnWidth: '55%',
      },
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent']
    },
    series: [{
      color: '#333333',
      data: [120, 132, 116, 175, 119, 117, 154, 192, 162, 183, 146, 151],
    }, {
      color: '#35a7f4',
      data: [336, 612, 344, 647, 345, 563, 256, 344, 323, 300, 455, 456],
    }],
    labels: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020],
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: '#777'
        }
      }
    },
    title: {
      text: '',
      align: 'left',
      style: {
        fontSize: '18px'
      }
    }

  }
  const Registerbar = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth"
    },
    series: [{
      name: "Registered User",
      color: '#333333',
      data: [45, 60, 75, 51, 42, 42, 30]
    }],
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    }
  }
export {
    optionsBar,
    Customerbar,
    Registerbar
};