<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <sidelinkheading />

      <div class="content w-100">
        <div class="content-page-header">
          <h5>Payment Gateways</h5>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/paypal.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/stripe.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/mercoda.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/razorpay.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/pay.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/paystack.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/cod.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/bank-transfer.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/midtrans.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/flutterwave.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/checkout.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/payu.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 col-12">
            <div class="nav-menus location-set">
              <img src="@/assets/admin_img/company/paytm.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#paypal-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="btn-path">
              <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
              <a href="javascript:void(0);" class="btn btn-primary">Update</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <gateways-model></gateways-model>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
