<template>
  <!-- Hero Section -->
  <section class="hero-section-two">
    <div class="banner-slider slider">
      <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="item in indextwoHero" :key="item.id">
          <div class="banner">
            <img
              class="img-fluid"
              :src="require(`@/assets/img/${item.image}`)"
              alt="img"
            />
          </div>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
    <div class="banner-search aos" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-md-10 mx-auto">
            <h1>World's Largest <span>Marketplace</span></h1>
            <p>Search From 150 Awesome Verified Ads!</p>
            <div class="search-box-two">
              <form @submit.prevent="submitForm">
                <div class="search-input-new line">
                  <i class="feather-tv bficon"></i>
                  <div class="form-group mb-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="What are you looking for?"
                    />
                  </div>
                </div>
                <div class="search-input-new">
                  <i class="fas fa-location-arrow bficon"></i>
                  <div class="form-group mb-0">
                    <input type="text" class="form-control" placeholder="Your Location" />
                    <a
                      class="current-loc-icon current_location"
                      href="javascript:void(0);"
                      ><i class="fas fa-crosshairs"></i
                    ></a>
                  </div>
                </div>
                <div class="search-btn">
                  <b-button variant="search_service" type="submit"
                    ><i class="feather-search"></i> Search</b-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Hero Section -->

  <!-- Feature Section -->
  <section class="feature-section-two">
    <div class="container">
      <div class="row">
        <div class="col-md-6 aos" data-aos="fade-up">
          <div class="section-heading-two">
            <h2>Featured Categories</h2>
            <p>What do you need to find?</p>
          </div>
        </div>
        <div class="col-md-6 text-md-end aos" data-aos="fade-up">
          <router-link to="/categories" class="btn btn-pink btn-viewall"
            >View All<i class="feather-arrow-right-circle"></i
          ></router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="feature-widget">
            <div class="feature-img">
              <router-link to="/search">
                <img
                  src="@/assets/img/services/service-06.jpg"
                  class="img-fluid"
                  alt="img"
                />
              </router-link>
            </div>
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-09.svg" alt="img" />
              </span>
              <div class="feature-title">
                <h5>Construction</h5>
                <p>10 Services</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="feature-widget">
            <div class="feature-img">
              <router-link to="/search">
                <img
                  src="@/assets/img/services/service-07.jpg"
                  class="img-fluid"
                  alt="img"
                />
              </router-link>
            </div>
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-10.svg" alt="img" />
              </span>
              <div class="feature-title">
                <h5>Interior</h5>
                <p>08 Services</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="feature-widget">
            <div class="feature-img">
              <router-link to="/search">
                <img
                  src="@/assets/img/services/service-08.jpg"
                  class="img-fluid"
                  alt="img"
                />
              </router-link>
            </div>
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-11.svg" alt="img" />
              </span>
              <div class="feature-title">
                <h5>Car Wash</h5>
                <p>15 Services</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="feature-widget">
            <div class="feature-img">
              <router-link to="/search">
                <img
                  src="@/assets/img/services/service-09.jpg"
                  class="img-fluid"
                  alt="img"
                />
              </router-link>
            </div>
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-12.svg" alt="img" />
              </span>
              <div class="feature-title">
                <h5>Cleaning</h5>
                <p>20 Services</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="feature-widget">
            <div class="feature-img">
              <router-link to="/search">
                <img
                  src="@/assets/img/services/service-01.jpg"
                  class="img-fluid"
                  alt="img"
                />
              </router-link>
            </div>
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-13.svg" alt="img" />
              </span>
              <div class="feature-title">
                <h5>Electrical</h5>
                <p>17 Services</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="feature-widget">
            <div class="feature-img">
              <router-link to="/search">
                <img
                  src="@/assets/img/services/service-10.jpg"
                  class="img-fluid"
                  alt="img"
                />
              </router-link>
            </div>
            <div class="feature-icon">
              <span>
                <img src="@/assets/img/icons/feature-icon-14.svg" alt="img" />
              </span>
              <div class="feature-title">
                <h5>Construction</h5>
                <p>13 Services</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Feature Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indextwoHero from "@/assets/json/indextwohero.json";
import "vue3-carousel/dist/carousel.css";

export default {
  data() {
    return {
      indextwoHero: indextwoHero,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
  },
};
</script>
