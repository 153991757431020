<template>
    <div class="mt-0">
        <!-- Session Expired -->
        <div class="main-wrapper error-page">
            <div class="bg-img">
                <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg1">
                <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg2">
                <img src="@/assets/img/bg/feature-bg-03.png" alt="img" class="bgimg3">
            </div>
            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="error-wrap text-center">
                                <div class="error-logo">
                                    <router-link to="/"><img class="img-fluid" src="@/assets/admin_img/logo_marirental3.svg" alt="img"></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="error-wrap">
                                <p>Silahkan periksa email Anda untuk melakukan aktivasi akun Anda. Pastikan memeriksa semua kotak masuk serta folder Spam pada email tersebut.</p>
                                <div>
                                    <button class="btn btn-primary" @click="submitEmailVerify()">Kirim Ulang Email Verifikasi</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="error-wrap">
                                <div class="error-img">
                                    <img class="img-fluid" src="@/assets/img/mari/email-validation.svg" alt="img">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Session Expired -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            isSucces: false,
            message: "Email Kamu Gagal di Verifikasi"
        }
    },
    methods: {
        submitEmailVerify() {
          let loading = this.$loading.show();
          this.$store.dispatch("auth/requestEmailVerify").then(
            (data) => {
                loading.hide();
                if (data.status == 200) {
                    this.$swal.fire({icon: 'success', html: data.data.message});
                }
            },
          );
        },
    },
    mounted() {
        // let token = this.$route.query.token
        // this.submitEmailVerify(token);
    }
}
</script>