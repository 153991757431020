<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>Detail Kendaraan</h4>
                  <p>Lihat informasi kendaraan disni!</p>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                  <b-tabs content-class="mt-3">
                      <b-tab title="Detail" active>
                        <div class="row">
                          <div class="col-12 text-end mb-3">
                            <button
                              class="btn btn-primary me-2"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#add-vehicle-doc"
                            >
                              <i class="fa fa-image me-2"></i>Tambah Gambar
                            </button>
                            <button
                              class="btn btn-primary"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#edit-vehicle"
                              @click="openEditModal(dataDetail)"
                            >
                              <i class="fa fa-edit me-2"></i>Edit Kendaraan
                            </button>
                          </div>
                          <div class="col-12">
                            <div class="row">
                              <div class="col-6">
                                <div class="mb-3">
                                  <div class="service-book">
                                    <div class="service-book-img">
                                      <!-- <img src="@/assets/img/booking.jpg" alt="img" /> -->
                                      <img v-if="(dataDetail !== undefined) && (dataDetail.image !== '-')" :src="dataDetail.image">
                                      <img v-else :src="require(`@/assets/img/mari/default-car.png`)">
                                    </div>
                                    <div class="serv-profile">
                                      <span class="badge">{{ dataDetail && dataDetail.nomor_plat }}</span>
                                      <h2>{{ dataDetail &&  dataDetail.name }} | {{ brandName }}</h2>
                                      <ul>
                                        <li class="serv-pro">
                                          <div class="serv-pro-info">
                                            <h6>Warna {{ dataDetail &&  dataDetail.color }} | Ket : {{ dataDetail &&  dataDetail.remark }}</h6>
                                          </div>
                                        </li>
                                        <li class="serv-pro">
                                          <div class="serv-pro-info">
                                            <h6>Kepemilikan : {{ dataDetail &&  dataDetail.vendorName }} | Ket : {{ dataDetail &&  dataDetail.vendorType }}</h6>
                                          </div>
                                        </li>
                                        <li class="serv-pro">
                                          <div class="serv-pro-info">
                                            <h6>Status : {{ dataDetail &&  dataDetail.status }}</h6>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div class="mb-3 form-group">
                                  <div class="d-flex">
                                      <div style="margin-right: 10px;">
                                        <div class="provide-box">
                                          <span
                                            ><img src="@/assets/img/mari/icons/fuel.svg" alt="img"
                                          /></span>
                                          <div class="provide-info">
                                            <h6>Bahan Bakar</h6>
                                            <h5>{{ dataDetail &&  dataDetail.bbm_type }}</h5>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <div class="provide-box">
                                          <span
                                            ><img src="@/assets/img/mari/icons/kusri.svg" alt="img"
                                          /></span>
                                          <div class="provide-info">
                                            <h6>Kapasita Penumpang</h6>
                                            <h5>{{ dataDetail &&  dataDetail.capacity }} Penumpang</h5>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                <div class="mb-3 form-group">
                                  <div class="d-flex">
                                      <div style="margin-right: 10px;">
                                        <div class="provide-box">
                                          <span
                                            ><img src="@/assets/img/mari/icons/tanggal.svg" alt="img"
                                          /></span>
                                          <div class="provide-info">
                                            <h6>Tahun Produksi</h6>
                                            <h5>{{ dataDetail &&  dataDetail.production_year  }}</h5>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <div class="provide-box">
                                          <span
                                            ><img src="@/assets/img/mari/icons/transmisi.svg" alt="img"
                                          /></span>
                                          <div class="provide-info">
                                            <h6>Transmisi</h6>
                                            <h5>{{ dataDetail &&  dataDetail.transmisi }}</h5>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div><div class="mb-3 form-group">
                                  <div class="d-flex">
                                      <div style="margin-right: 10px;">
                                        <div class="provide-box">
                                          <span
                                            ><img src="@/assets/img/mari/icons/transmisi.svg" alt="img"
                                          /></span>
                                          <div class="provide-info">
                                            <h6>HPP Kendaraan</h6>
                                            <h5>{{ dataDetail &&  formatCurrency(dataDetail.hpp) }}</h5>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <div class="provide-box">
                                          <span
                                            ><img src="@/assets/img/mari/icons/transmisi.svg" alt="img"
                                          /></span>
                                          <div class="provide-info">
                                            <h6>HPP Rent To Rent</h6>
                                            <h5>{{ dataDetail &&  formatCurrency(dataDetail.hpp_rent_to_rent) }}</h5>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="mb-3">
                                  <label class="form-label">Gambar Dokumen Tambahan</label>
                                  <div v-if="dataDetail !== undefined" v-for="(item, index) in dataDetail.doc" :key="index" class="mb-3">
                                      <div class="profile-upload-content" style="display: flex; align-items: center;">
                                          <div style="flex: 1;">
                                              <img v-if="item.file !== ''" :src="item.file" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                              <img v-else :src="require(`@/assets/admin_img/customer/user-01.jpg`)" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                          </div>
                                          <button @click="deleteDoc(item)" class="btn btn-danger">Hapus</button> <!-- Adjusted button size -->
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                      <!-- <b-tab title="Jenis Sewa">
                        <div class="row">
                          <div class="col-12 text-end mb-3">
                            <button
                              class="btn btn-primary"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#add-product-vehicle"
                            >
                              <i class="fa fa-plus me-2"></i>Tambah Jenis Sewa
                            </button>
                          </div>
                          <div class="col-12">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Jenis</th>
                                    <th>Tarif</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="dataDetail !== undefined" v-for="(item, index) in dataDetail.vehicle_prices" :key="index">
                                        <td class="mb-3">
                                            <select v-model="item.product_id" class="form-select" disabled>
                                                <option v-for="option in dataProduct" :value="option.id">{{ option.rentalTypeName }} - {{ option.name }}</option>
                                            </select>
                                        </td>
                                        <td class="mb-3">
                                            <input v-model.number="item.price" class="form-control" type="number" placeholder="Item Price" disabled>
                                        </td>
                                        <td>
                                          <div class="table-actions d-flex">
                                          <button
                                            class="btn delete-table me-2"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#edit-product-vehicle"
                                            @click="openEditprice(item)"
                                          >
                                            <i class="feather feather-edit"></i>
                                          </button>
                                          <button
                                            class="btn delete-table"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete-product-vehicle"
                                            @click="openEditprice(item)"
                                          >
                                            <i class="feather feather-trash-2"></i>
                                          </button>
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                        </div>
                      </b-tab> -->
                      <b-tab title="Riwayat Order">
                        <div class="row">
                          <!-- <div class="col-12 text-end mb-3">
                            <button
                              class="btn btn-primary"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#add-order2"
                              @click="openCreateModal()"
                            >
                              <i class="fa fa-plus me-2"></i>Tambah Order
                            </button>
                          </div> -->
                          <div class="col-12">
                            <h5>Riwayat Order</h5>
                            <div class="table-container">
                              <a-table :columns="columns" :data-source="dataOrder" style="overflow-x: auto;">
                                <template #bodyCell="{ column, record }">
                                  <template v-if="column.key === 'Status'">
                                    <h6 v-if="record.status === 'AKTIF'" class="badge-active">{{ record.status }}</h6>
                                    <h6 v-else class="badge-active">{{ record.status }}</h6>
                                  </template>
                                  <!-- <template v-if="column.key === 'id'">
                                    <div class="table-actions d-flex">
                                        <button
                                        class="btn delete-table me-2"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#show-order"
                                        @click="openEditModal(record)"
                                      >
                                        <i class="feather feather-eye"></i>
                                      </button>
                                    </div>
                                  </template> -->
                                </template>
                              </a-table>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                      <b-tab title="Grafik Pemakaian">
                        <div class="card">
                          <div class="card-body">
                            <div v-if="vehicleStat" class="row mt-4">
                              <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                                <div class="card">
                                  <div class="card-body">
                                    <div class="home-user">
                                      <div class="home-userhead">
                                        <div class="home-usercount">
                                          <span
                                            ><img src="@/assets/admin_img/icons/eye.svg" alt="img"
                                          /></span>
                                          <h6>Penggunaan</h6>
                                        </div>
                                      </div>
                                      <div class="home-usercontent">
                                        <div class="home-usercontents">
                                          <div class="home-usercontentcount">
                                            <span class="counters" data-count="{{ vehicleStat.used }}">{{ vehicleStat.used }}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                                <div class="card">
                                  <div class="card-body">
                                    <div class="home-user home-provider">
                                      <div class="home-userhead">
                                        <div class="home-usercount">
                                          <span
                                            ><img src="@/assets/admin_img/icons/monitor.svg" alt="img"
                                          /></span>
                                          <h6>Di order</h6>
                                        </div>
                                      </div>
                                      <div class="home-usercontent">
                                        <div class="home-usercontents">
                                          <div class="home-usercontentcount">
                                            <span class="counters" data-count="{{ vehicleStat.ordered }}">{{ vehicleStat.ordered }}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-sm-6 col-12 d-flex widget-path widget-service">
                                <div class="card">
                                  <div class="card-body">
                                    <div class="home-user home-service">
                                      <div class="home-userhead">
                                        <div class="home-usercount">
                                          <span
                                            ><img src="@/assets/admin_img/icons/global.svg" alt="img"
                                          /></span>
                                          <h6>Di servis</h6>
                                        </div>
                                      </div>
                                      <div class="home-usercontent">
                                        <div class="home-usercontents">
                                          <div class="home-usercontentcount">
                                            <span class="counters" data-count="{{ vehicleStat.maintained }}">{{ vehicleStat.maintained }}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                              <div class="home-user">
                              <div class="home-head-user">
                                  <h2>Grafik Riwayat Pemakaian</h2>
                              </div>
                              <div class="chartgraph">
                                  <apexchart
                                  type="bar"
                                  height="350"
                                  :options="barChart.chart"
                                  :series="barChart.series"
                                  >
                                  </apexchart>
                              </div>
                              </div>
                          </div>
                        </div>
                      </b-tab>
                      <b-tab title="List Perbaikan">
                        <div class="row">
                          <div class="col-12 text-end mb-3">
                            <button
                              class="btn btn-primary"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#add-maintenance"
                              @click="openCreateModal()"
                            >
                              <i class="fa fa-plus me-2"></i>Tambah Jadwal
                            </button>
                          </div>
                          <div class="col-12">
                            <h5>Riwayat Perbaikan</h5>
                            <div class="table-container" style="overflow-x: auto;">
                              <table class="table">
                                  <thead>
                                  <tr>
                                      <th>Perbaikan</th>
                                      <th>Tanggal Mulai Perbaikan</th>
                                      <th>Tanggal Selesai Perbaikan</th>
                                      <th>Catatan</th>
                                      <th>Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-if="dataDetail !== undefined" v-for="(item, index) in dataMaintenance" :key="index">
                                          <td class="mb-3">
                                              {{ item.implementation }}
                                          </td>
                                          <td class="mb-3">
                                              {{ item.tgl_awal }}
                                          </td>
                                          <td class="mb-3">
                                              {{ item.tgl_akhir }}
                                          </td>
                                          <td>
                                            {{ item.remark }}
                                          </td>
                                          <td>
                                            <div class="table-actions d-flex">
                                            <button
                                              class="btn delete-table me-2"
                                              type="button"
                                              data-bs-toggle="modal"
                                              data-bs-target="#edit-maintenance"
                                              @click="openEditMaintenance(item)"
                                            >
                                              <i class="feather feather-edit"></i>
                                            </button>
                                            <button
                                              class="btn delete-table"
                                              type="button"
                                              data-bs-toggle="modal"
                                              data-bs-target="#delete-maintenance"
                                              @click="openEditMaintenance(item)"
                                            >
                                              <i class="feather feather-trash-2"></i>
                                            </button>
                                          </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                  </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <order-model ref="orderModalRef" :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" :customerData="dataCustomer" :vehicleData="dataVehicle" :driverData="dataDriver" :staffData="dataStaff" @dataUpdated="getOrder" :dataId="dataId"></order-model>
    <vehicle-model :editData="editData" :deleteItemId="itemIdToDelete" :productData="dataProduct" @dataUpdated="getData" :dataId="dataId" :editPrice="editPrice" :brandData="dataBrand" :vendorData="dataVendor"></vehicle-model>
    <product-model :editData="editData" :deleteItemId="itemIdToDelete" @dataUpdated="getProduct"></product-model>
    <maintenance-model :editData="editData" :deleteItemId="itemIdToDelete" :vehicleData="dataVehicle" @dataUpdated="getMaintenance" :dataId="dataId" :editMaintenance="editMaintenance"></maintenance-model>
  </template>
  
  <script>
  const barChart = {
    colors: ['#4169E1'],
    series: [
      {
        name: "Received",
        type: "column",
        data: []
      }
    ],
    chart: {
      id: "vuechart-example",
      type: 'bar',
      fontFamily: 'Poppins, sans-serif',
      height: 350,
      toolbar: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          endingShape: 'rounded'
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      },
      yaxis: {
        title: {
          text: 'Jumlah Pemakaian'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " Hari Pakai"
          }
        }
      }
    },
  };
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "No Order",
      dataIndex: "orderNumber",
      key: "orderNumber",
      sorter: {
        compare: (a, b) => {
          a = a.orderNumber.toLowerCase();
          b = b.orderNumber.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Customer",
      dataIndex: "namaTamu",
      key: "namaTamu",
      sorter: {
        compare: (a, b) => {
          a = a.namaTamu.toLowerCase();
          b = b.namaTamu.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Driver Name",
      dataIndex: "driverName",
      key: "driverName",
      sorter: {
        compare: (a, b) => {
          a = a.driverName.toLowerCase();
          b = b.driverName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Starting Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: {
        compare: (a, b) => {
          a = a.startDate.toLowerCase();
          b = b.startDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Starting Time",
    //   dataIndex: "startTime",
    //   key: "startTime",
    //   sorter: {
    //     compare: (a, b) => {
    //       a = a.startTime.toLowerCase();
    //       b = b.startTime.toLowerCase();
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    // },
    {
      title: "Finish Date",
      dataIndex: "finishDate",
      key: "finishDate",
      sorter: {
        compare: (a, b) => {
          a = a.finishDate.toLowerCase();
          b = b.finishDate.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: "Vehicle",
    //   dataIndex: "vehicleName",
    //   key: "vehicleName",
    //   sorter: {
    //     compare: (a, b) => {
    //       a = a.vehicleName.toLowerCase();
    //       b = b.vehicleName.toLowerCase();
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   sorter: {
    //     compare: (a, b) => {
    //       a = a.status.toLowerCase();
    //       b = b.status.toLowerCase();
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    // },
    // {
    //   title: "Total Sewa",
    //   dataIndex: "total_price",
    //   key: "total_price",
    //   sorter: {
    //     compare: (a, b) => {
    //       a = a.total_price.toLowerCase();
    //       b = b.total_price.toLowerCase();
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    // },
    // {
    //   title: "Total DP",
    //   dataIndex: "dp",
    //   key: "dp",
    //   sorter: {
    //     compare: (a, b) => {
    //       a = a.dp.toLowerCase();
    //       b = b.dp.toLowerCase();
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    // },
    // {
    //   title: "Action",
    //   key: "id",
    //   sorter: true,
    //   class: "text-end",
    // },
  ];
  export default {
    data() {
      return {
        columns,
        dataOrder: [],
        dataSewa: [],
        vehicleId: 0,
        dataDetail : {},
        barChart: barChart,
        Select: ["A -> Z", "Z -> A"],
        params: {
          row: 10000,
          keyword: "",
          sortby: "id",
          sorttype: "asc",
          page: 1
        },
        paramDriver: {
            row: 10000,
            keyword: "Driver",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        paramStaff: {
            row: 10000,
            keyword: "Staff Kendaraan",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        paramMaintenance: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        editPrice: {},
        editMaintenance:{},
        itemIdToDelete: {},
        dataCustomer: [],
        dataId: null,
        dataProduct: [],
        dataVehicle: [],
        dataBrand: [],
        dataVendor: [],
        dataDriver: [],
        dataStaff: [],
        brandName: '',
        dataMaintenance: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        vehicleStat: null
      };
    },
    name: "vehiclesetting",
    computed: {

    },
    methods: {
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/showVehicle", this.vehicleId)
                .then((resp) => {
                  this.dataDetail = resp.data;
                  const vendor = this.dataVendor.find(vendor => vendor.id === this.dataDetail.vendor_id);
                  let user = JSON.parse(localStorage.getItem('user'));
                  let company = user && user.company ? user.company.name : "-";
                  let vendorName = company || "-";
                  let vendorType = "-";

                  if (vendor) {
                    vendorName = vendor.full_name;

                    if (vendor.type === 1) {
                      vendorType = "Investor";
                    } else if (vendor.type === 2) {
                      vendorType = "Rent To Rent";
                    }
                  }
                  this.dataDetail.vendorName = vendorName;
                  this.dataDetail.vendorType = vendorType;
                  const brand = this.dataBrand.find(brand => brand.id === this.dataDetail.brand_id);
                  this.brandName = brand ? brand.name : '';
                    loading.hide();
                    this.dataOrder = this.dataDetail.order_vehicle_detail.map((item, index) => {
                        const sDate = new Date(item.tgl_awal);
                        const fDate = new Date(item.tgl_akhir);

                        const driverName = item.driver ? item.driver.full_name : '-'; 
                        const orderNumber = item.order_vehicle && item.order_vehicle.order 
                            ? item.order_vehicle.order.no_order 
                            : '-'; 
                        const namaTamu = item.nama_tamu 
                          ? item.nama_tamu 
                          : item.order_vehicle && item.order_vehicle.order && item.order_vehicle.order.customer
                              ? item.order_vehicle.order.customer.name
                              : '-';

                        return {
                            ...item,
                            index: index + 1,
                            startDate: `${sDate.getDate()}/${sDate.getMonth() + 1}/${sDate.getFullYear()}`,
                            finishDate: `${fDate.getDate()}/${fDate.getMonth() + 1}/${fDate.getFullYear()}`,
                            driverName,
                            orderNumber,
                            namaTamu
                        };
                    });

                    // this.dataOrder = this.dataDetail.orders.map((item, index) => {
                    //     const sDate = new Date(item.starting_date);
                    //     const fDate = new Date(item.finish_date);
                    //     const time = new Date(item.starting_time);
                    //     const startTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                    //     const vehicle = this.dataDetail;
                    //     const vehicleName = vehicle ? vehicle.name : '';
                    //     const product = this.dataProduct.find(product => product.id === item.product_id);
                    //     const productName = product ? product.rentalTypeName + ' - ' + product.name : '';
                    //     const customer = this.dataCustomer.find(customer => customer.id === item.customer_id);
                    //     const customerName = customer ? customer.name : '';
                        
                    //     return {
                    //         ...item,
                    //         index: index + 1,
                    //         startDate: sDate.getDate() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getFullYear(),
                    //         finishDate: fDate.getDate() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getFullYear(),
                    //         startTime,
                    //         vehicleName,
                    //         productName,
                    //         customerName
                    //     };
                    // });
                    this.dataSewa = this.dataDetail.vehicle_prices.map((item, index) => ({
                      ...item.products,
                      index: index + 1 
                    }));
                    this.barChart.series[0].data = this.orderDaysPerMonth();
                })
                .catch((e) => {
                    // this.$toast.error(e);
                    console.log(e)
                    loading.hide();
                });
        },
        openEditModal(data) {
          console.log(data);
          this.editData = { data };
        },
        openDeleteModal(data) {
          this.itemIdToDelete = { data };
        },
        openCreateModal() {
          this.dataId = this.vehicleId;
          this.openChildModal();
        },
        openEditprice(data) {
          this.editPrice = { data };
        },
        openEditMaintenance(data) {
          this.editMaintenance = { data }
        },
        getProduct() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("product/getProduct", this.params)
                .then((resp) => {
                    this.dataProduct = resp.data.data.map((item, index) => {
                        let rentalTypeName;
                        if (item.rental_type == 1) {
                            rentalTypeName = "Sewa Lepas Kunci";
                        } else {
                            rentalTypeName = "Sewa Dengan Driver";
                        }
                        return {
                            ...item,
                            rentalTypeName
                        };
                    });
                    loading.hide();
                })
                .catch((e) => {
                    // this.$toast.error(e);
                    console.log(e);
                    loading.hide();
                });
        },
        getCustomer() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("customer/getCustomer", this.params)
                .then((resp) => {
                    this.dataCustomer = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    // this.$toast.error(e);
                    console.log(e);
                    loading.hide();
                });
        },
        getDriver() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("user/getUser", this.paramDriver)
                .then((resp) => {
                    this.dataDriver = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getStaff() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("user/getUser", this.paramStaff)
                .then((resp) => {
                    this.dataStaff = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVehicle() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params)
                .then((resp) => {
                    this.dataVehicle = resp.data.data.map((item,index) => {
                      let vendorName = JSON.parse(localStorage.getItem('user')).company.name; 
                      if (item.vendor_id) {
                        const data = this.dataVendor.find(vendor => vendor.id == item.vendor_id);
                        vendorName = data.full_name;
                      }
                      const vehicleName = item.name + ' - ' + item.nomor_plat + ' - ' + vendorName;
                      return {
                        ...item,
                        vehicleName
                      };
                    });
                    loading.hide();
                })
                .catch((e) => {
                    // this.$toast.error(e);
                    console.log(e);
                    loading.hide();
                });
        },
        // orderCountPerMonth() {
        // count untuk order
        //   const ordersByMonth = {
        //     1: 0,  // January
        //     2: 0,  // February
        //     3: 0,  // March
        //     4: 0,  // April
        //     5: 0,  // May
        //     6: 0,  // June
        //     7: 0,  // July
        //     8: 0,  // August
        //     9: 0,  // September
        //     10: 0, // October
        //     11: 0, // November
        //     12: 0  // December
        //   };
        //   if (this.dataDetail && this.dataDetail.orders) {
        //     this.dataDetail.orders.forEach(order => {
        //       const month = new Date(order.starting_date).getMonth() + 1; 
        //       ordersByMonth[month]++;
        //     });
        //   }
        //   return Object.values(ordersByMonth);
        // },
        orderDaysPerMonth() {
          // count untuk pemakaian harian
          const daysByMonth = {
            1: 0,  // January
            2: 0,  // February
            3: 0,  // March
            4: 0,  // April
            5: 0,  // May
            6: 0,  // June
            7: 0,  // July
            8: 0,  // August
            9: 0,  // September
            10: 0, // October
            11: 0, // November
            12: 0  // December
          };

          if (this.dataDetail && this.dataDetail.order_vehicle_detail) {
            this.dataDetail.order_vehicle_detail.forEach(orderVehicleDetail => {
              const startDate = new Date(orderVehicleDetail.tgl_awal);
              const endDate = new Date(orderVehicleDetail.tgl_akhir);
              
              // Calculate total days between the two dates
              let current = new Date(startDate);
              while (current <= endDate) {
                const month = current.getMonth() + 1; // getMonth is zero-based
                daysByMonth[month]++;
                current.setDate(current.getDate() + 1);
              }
            });
          }

          return Object.values(daysByMonth);
        },
        deleteDoc(data) {
        const id = data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("vehicle/deleteDoc", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.getData();
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      getBrand() {
        var loading = this.$loading.show();
        this.$store
        .dispatch("brand/getBrandAll")
        .then((resp) => {
            this.dataBrand = resp.data;
            loading.hide();
        })
        .catch((e) => {
            this.$toast.error(e);
            loading.hide();
        });
      },
      getVendor() {
        var loading = this.$loading.show();
        this.$store
        .dispatch("vendor/getVendorAll")
        .then((resp) => {
            this.dataVendor = resp.data;
            loading.hide();
        })
        .catch((e) => {
            this.$toast.error(e);
            loading.hide();
        });
      },
      getMaintenance() {
        var loading = this.$loading.show();
        this.$store
            .dispatch("maintenance/getMaintenance", this.paramMaintenance)
            .then((resp) => {
                this.dataMaintenance = resp.data.data.map((item, index) => {
                    return {
                        ...item,
                        index: index + 1,
                    };
                });
                loading.hide();
            })
            .catch((e) => {
                this.$toast.error(e);
                loading.hide();
            });
      },
      openChildModal() {
        this.$refs.orderModalRef.openModal();
      },
      async getVehicleStat(id) {
        const data = await this.$store.dispatch('vehicle/getVehicleStat', id)
        this.vehicleStat = data
      },
      formatCurrency(value) {
          if (!value && value !== 0) return '';
          // Convert value to a string
          let stringValue = value.toString();
          // Split the string into integer and decimal parts
          let parts = stringValue.split('.');
          // Format the integer part with thousand separators
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          // Join the parts and return the formatted value
          return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
        },
    },
    async mounted() {
        this.vehicleId = this.$route.params.id;
        this.dataId = this.vehicleId;
        this.paramMaintenance.keyword = this.vehicleId;
        this.getVendor();
        this.getProduct();
        this.getCustomer();
        this.getDriver();
        this.getStaff();
        this.getVehicle();
        this.getBrand();
        this.getMaintenance();
        this.getData();

      const loading = this.$loading.show() 
      try {
        await this.getVehicleStat(this.vehicleId)
      } catch (e) {
        this.$toast.error(e)
      } finally {
        loading.hide()
      }
    },
  };
  </script>
  <style>
  .ant-select-selection-item {
    height: 30px;
    width: 90px;
    margin-top: -6px;
    font-size: 15px;
  }
  </style>
