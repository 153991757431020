<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>State</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-state"
                >
                  <i class="fa fa-plus me-2"></i>Add State
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Country_Name'">
                      <a href="javascript:void(0);" class="table-imgname flag-image">
                        <img
                          :src="require(`@/assets/admin_img/flags/${record.Image}`)"
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Country_Name }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <button
                          class="btn delete-table me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-state"
                        >
                          <i class="feather feather-edit"></i>
                        </button>
                        <button
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <states-model></states-model>
</template>

<script>
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Country Code",
    dataIndex: "Country_Code",
    sorter: {
      compare: (a, b) => {
        a = a.Country_Code.toLowerCase();
        b = b.Country_Code.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Country Name",
    dataIndex: "Country_Name",
    key: "Country_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Country_Name.toLowerCase();
        b = b.Country_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "State Name",
    dataIndex: "State_Name",
    sorter: {
      compare: (a, b) => {
        a = a.State_Name.toLowerCase();
        b = b.State_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Country_Code: "AS",
    Image: "us.png",
    Country_Name: "American Samoa(+684)",
    State_Name: "Swains Island",
    Action: "",
  },
  {
    id: "2",
    Country_Code: "AD",
    Image: "ad.png",
    Country_Name: "Andorra (+376)",
    State_Name: "Swains Island",
    Action: "",
  },
  {
    id: "3",
    Country_Code: "AO",
    Image: "ao.png",
    Country_Name: "Angola (+244)",
    State_Name: "Benguela",
    Action: "",
  },
  {
    id: "4",
    Country_Code: "AI",
    Image: "ai.png",
    Country_Name: "Anguilla (+1264)",
    State_Name: "The Valley",
    Action: "",
  },
  {
    id: "5",
    Country_Code: "AQ",
    Image: "qa.png",
    Country_Name: "Antarctica(+672)",
    State_Name: "Victoria Land",
    Action: "",
  },
  {
    id: "6",
    Country_Code: "AG",
    Image: "ag.png",
    Country_Name: "Antigua & Barbuda (+1268)",
    State_Name: "Saint Paul",
    Action: "",
  },
  {
    id: "7",
    Country_Code: "AR",
    Image: "ar.png",
    Country_Name: "Argentina (+54)",
    State_Name: "Santa Fe",
    Action: "",
  },
  {
    id: "8",
    Country_Code: "AU",
    Image: "au.png",
    Country_Name: "Australia (+61)",
    State_Name: "Queensland",
    Action: "",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
