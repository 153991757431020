<template>
  <!-- Service Section -->
  <section class="service-section featured-saloons">
    <div class="saloon-section-circle">
      <img src="@/assets/img/side-circle.png" />
    </div>
    <div class="container">
      <div class="services-header aos" data-aos="fade-up">
        <div class="row">
          <div class="col-md-12">
            <div class="section-content">
              <h2>Featured Saloons</h2>
              <div class="our-img-all">
                <img src="@/assets/img/icons/scissor.svg" alt="" />
              </div>
              <p>
                Our Barbershop & Tattoo Salon provides classic services combined with
                innovative techniques.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="service-slider aos" data-aos="fade-right">
            <div class="service-widget">
              <div class="service-img service-show-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-24.jpg"
                  />
                </router-link>
                <div class="item-info item-infos">
                  <div class="rating">
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                  </div>
                </div>
              </div>
              <div class="service-content">
                <h3 class="title">
                  <a href="javascript:;">The Rockstar Barber</a>
                </h3>
                <ul>
                  <li>Hair Cut</li>
                  <li>Hair Styling</li>
                  <li>Clean Shaving</li>
                  <li>Face Cleaning</li>
                </ul>
                <div class="main-saloons-profile">
                  <div class="saloon-profile-left">
                    <div class="saloon-img">
                      <img src="@/assets/img/profiles/avatar-20.jpg" />
                    </div>
                    <div class="saloon-content">
                      <div class="saloon-content-top">
                        <i class="feather-clock"></i>
                        <span>07:00 AM - 11:00 PM </span>
                      </div>
                      <div class="saloon-content-btn">
                        <i class="feather-map-pin"></i>
                        <span>Main Boulevard, Lahore,</span>
                      </div>
                    </div>
                  </div>
                  <div class="saloon-right">
                    <span>$70</span>
                  </div>
                </div>
                <div class="saloon-bottom">
                  <a href="javascript:;"
                    ><i class="feather-calendar me-2"></i>MAKE AN APPOINTMENT</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="service-slider aos" data-aos="fade-up">
            <div class="service-widget">
              <div class="service-img service-show-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-25.jpg"
                  />
                </router-link>
                <div class="item-info item-infos">
                  <div class="rating">
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                  </div>
                </div>
              </div>
              <div class="service-content">
                <h3 class="title">
                  <a href="javascript:;">Femina Hairstyle</a>
                </h3>
                <ul>
                  <li>Hair Cut</li>
                  <li>Hair Styling</li>
                  <li>Clean Shaving</li>
                  <li>Face Cleaning</li>
                </ul>
                <div class="main-saloons-profile">
                  <div class="saloon-profile-left">
                    <div class="saloon-img">
                      <img src="@/assets/img/profiles/avatar-19.jpg" />
                    </div>
                    <div class="saloon-content">
                      <div class="saloon-content-top">
                        <i class="feather-clock"></i>
                        <span>07:00 AM - 11:00 PM </span>
                      </div>
                      <div class="saloon-content-btn">
                        <i class="feather-map-pin"></i>
                        <span>Main Boulevard, Lahore,</span>
                      </div>
                    </div>
                  </div>
                  <div class="saloon-right">
                    <span>$70</span>
                  </div>
                </div>
                <div class="saloon-bottom">
                  <a href="javascript:;"
                    ><i class="feather-calendar me-2"></i>MAKE AN APPOINTMENT</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="service-slider aos" data-aos="fade-left">
            <div class="service-widget">
              <div class="service-img service-show-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-26.jpg"
                  />
                </router-link>
                <div class="item-info item-infos">
                  <div class="rating">
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                  </div>
                </div>
              </div>
              <div class="service-content">
                <h3 class="title">
                  <a href="javascript:;">The Macho Lever</a>
                </h3>
                <ul>
                  <li>Hair Cut</li>
                  <li>Hair Styling</li>
                  <li>Clean Shaving</li>
                  <li>Face Cleaning</li>
                </ul>
                <div class="main-saloons-profile">
                  <div class="saloon-profile-left">
                    <div class="saloon-img">
                      <img src="@/assets/img/profiles/avatar-18.jpg" />
                    </div>
                    <div class="saloon-content">
                      <div class="saloon-content-top">
                        <i class="feather-clock"></i>
                        <span>07:00 AM - 11:00 PM </span>
                      </div>
                      <div class="saloon-content-btn">
                        <i class="feather-map-pin"></i>
                        <span>Main Boulevard, Lahore,</span>
                      </div>
                    </div>
                  </div>
                  <div class="saloon-right">
                    <span>$70</span>
                  </div>
                </div>
                <div class="saloon-bottom">
                  <a href="javascript:;"
                    ><i class="feather-calendar me-2"></i>MAKE AN APPOINTMENT</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="service-slider aos" data-aos="fade-right">
            <div class="service-widget">
              <div class="service-img service-show-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-27.jpg"
                  />
                </router-link>
                <div class="item-info item-infos">
                  <div class="rating">
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                  </div>
                </div>
              </div>
              <div class="service-content">
                <h3 class="title">
                  <a href="javascript:;">Master Barber</a>
                </h3>
                <ul>
                  <li>Hair Cut</li>
                  <li>Hair Styling</li>
                  <li>Clean Shaving</li>
                  <li>Face Cleaning</li>
                </ul>
                <div class="main-saloons-profile">
                  <div class="saloon-profile-left">
                    <div class="saloon-img">
                      <img src="@/assets/img/profiles/avatar-17.jpg" />
                    </div>
                    <div class="saloon-content">
                      <div class="saloon-content-top">
                        <i class="feather-clock"></i>
                        <span>07:00 AM - 11:00 PM </span>
                      </div>
                      <div class="saloon-content-btn">
                        <i class="feather-map-pin"></i>
                        <span>Main Boulevard, Lahore,</span>
                      </div>
                    </div>
                  </div>
                  <div class="saloon-right">
                    <span>$70</span>
                  </div>
                </div>
                <div class="saloon-bottom">
                  <a href="javascript:;"
                    ><i class="feather-calendar me-2"></i>MAKE AN APPOINTMENT</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="service-slider aos" data-aos="fade-up">
            <div class="service-widget">
              <div class="service-img service-show-img">
                <div class="service-img-top">
                  <router-link to="/service-details">
                    <img
                      class="img-fluid serv-img"
                      alt="Service Image"
                      src="@/assets/img/services/service-28.jpg"
                    />
                  </router-link>
                </div>
                <div class="item-info item-infos">
                  <div class="rating">
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                  </div>
                </div>
              </div>
              <div class="service-content">
                <h3 class="title">
                  <a href="javascript:;">Rearhair Stylist</a>
                </h3>
                <ul>
                  <li>Hair Cut</li>
                  <li>Hair Styling</li>
                  <li>Clean Shaving</li>
                  <li>Face Cleaning</li>
                </ul>
                <div class="main-saloons-profile">
                  <div class="saloon-profile-left">
                    <div class="saloon-img">
                      <img src="@/assets/img/profiles/avatar-15.jpg" />
                    </div>
                    <div class="saloon-content">
                      <div class="saloon-content-top">
                        <i class="feather-clock"></i>
                        <span>07:00 AM - 11:00 PM </span>
                      </div>
                      <div class="saloon-content-btn">
                        <i class="feather-map-pin"></i>
                        <span>Main Boulevard, Lahore,</span>
                      </div>
                    </div>
                  </div>
                  <div class="saloon-right">
                    <span>$70</span>
                  </div>
                </div>
                <div class="saloon-bottom">
                  <a href="javascript:;"
                    ><i class="feather-calendar me-2"></i>MAKE AN APPOINTMENT</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="service-slider aos" data-aos="fade-left">
            <div class="service-widget">
              <div class="service-img service-show-img">
                <router-link to="/service-details">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    src="@/assets/img/services/service-24.jpg"
                  />
                </router-link>
                <div class="item-info item-infos">
                  <div class="rating">
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                    <i class="fa-solid fa-star filled me-1"></i>
                  </div>
                </div>
              </div>
              <div class="service-content">
                <h3 class="title">
                  <a href="javascript:;">The Rockstar Barber</a>
                </h3>
                <ul>
                  <li>Hair Cut</li>
                  <li>Hair Styling</li>
                  <li>Clean Shaving</li>
                  <li>Face Cleaning</li>
                </ul>
                <div class="main-saloons-profile">
                  <div class="saloon-profile-left">
                    <div class="saloon-img">
                      <img src="@/assets/img/profiles/avatar-20.jpg" />
                    </div>
                    <div class="saloon-content">
                      <div class="saloon-content-top">
                        <i class="feather-clock"></i>
                        <span>07:00 AM - 11:00 PM </span>
                      </div>
                      <div class="saloon-content-btn">
                        <i class="feather-map-pin"></i>
                        <span>Main Boulevard, Lahore,</span>
                      </div>
                    </div>
                  </div>
                  <div class="saloon-right">
                    <span>$70</span>
                  </div>
                </div>
                <div class="saloon-bottom">
                  <a href="javascript:;"
                    ><i class="feather-calendar me-2"></i>MAKE AN APPOINTMENT</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-sec btn-saloons aos" data-aos="fade-up">
          <router-link to="/search" class="btn btn-primary btn-view"
            >VIEW ALL 590 SALOONS</router-link
          >
        </div>
      </div>
    </div>
  </section>
  <!-- /Service Section -->

  <!-- Appointment Section -->
  <section class="appointment-section aos" data-aos="fade-up">
    <div class="container">
      <div class="appointment-main">
        <h6>GET A MODERN LOOK</h6>
        <h2>Upto 25% offer on First Appointment</h2>
        <p>
          No one you see is smarter than he so join us here are sure to get a smile from
          seven stranded.
        </p>
        <div class="appointment-btn">
          <a href="javascript:;" class="btn btn-primary">BOOK AN APPOINTMENT NOW</a>
        </div>
      </div>
    </div>
  </section>
  <!-- /Appointment Section -->
</template>

<style>
.featured-saloons .service-widget {
  margin-right: 0px;
}
</style>
