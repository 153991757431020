<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <blogbreadcrumb :title="title"  :text="text" :text1="text1" :text2="text2" />

            <bloggridcontent />

            <truelyfooter />
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Our Blog",
            text: "Home",
            text1: "Blog",
            text2: "Grid",
            
        }
    },
}
</script>