<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>
  
      <div class="page-wrapper page-settings">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-4 aos" data-aos="fade-up">
                  <h4>Profile User</h4>
                  <h5 v-if="dataDetail !== null">{{ dataDetail.full_name }}</h5>
                </div>
                <div class="col-md-8 text-md-end aos" data-aos="fade-up">
                    <router-link to="/mari/staff/home" class="btn btn-primary" style="margin-right: 10px;">Home</router-link>
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="editCurrentSubmit"
                      style="margin-right: 10px;"
                    >
                      Update Profile
                    </button>
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#change-password-user"
                      @click="openEditModal(dataDetail)"
                    >
                      Ganti Password
                    </button>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <div class="profile-upload-content">
        
                                <!-- company logo here -->
                                <img v-if="(dataDetail !== null) && (dataDetail.photo !== '')" :src="dataDetail.photo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                                <!-- company logo here -->

                                <!-- upload file form logo here -->
                                <div class="profile-upload-btn">
                                    <div class="profile-upload-file">
                                        <input
                                            type="file"
                                            class="form-control"
                                            ref="uploadFieldAttachment"
                                            id="imgInp"
                                            @change="selectFileAttachment"
                                        />
                                    </div>
                                </div>
                                    <!-- upload file form logo here -->
                                <div class="profile-upload-para">
                                    <p style="font-size: 12px;">
                                    <span style="color: red;">*</span>ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                                    File yang diizinkan .png dan .jpg.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Nama Lengkap</label>
                            <input v-if="dataDetail !== null" type="text" class="form-control" v-model="dataDetail.full_name"/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Nomer Telepon (WA)</label>
                            <input v-if="dataDetail !== null" type="text" class="form-control" v-model="dataDetail.phone_number"/>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Email</label>
                            <input v-if="dataDetail !== null" type="text" class="form-control" v-model="dataDetail.email" disabled/>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Nik</label>
                            <input v-if="dataDetail !== null" type="text" class="form-control" v-model="dataDetail.nik"/>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Jenis User</label>
                            <select v-if="dataDetail !== null" class="form-select" v-model="dataDetail.type" disabled>
                            <option v-for="role in dataRole" :key="role.id" :value="role.id">{{ role.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="mb-3 form-group">
                    <div class="d-flex">
                        <div v-if="dataDetail && (dataDetail.type === driverRoleId)" class="col-6" style="margin-right: 10px;">
                        <label class="form-label">Status Driver</label>
                        <input type="text" class="form-control" v-model="formCreate.status_mudi" disabled/>
                        </div>
                        <div class="col-6">

                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-group">
                        <div class="d-flex">
                            <div class="col-6">
                                <div class="col-lg-12">
                                    <div class="split-check-title">
                                        <label class="form-label">Jenis Kelamin</label>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-check form-check-inline">
                                        <input v-if="dataDetail !== null" :checked="dataDetail.gender === 'Laki - Laki'" class="form-check-input" type="radio" id="inlineRadio1" name="gender" value="Laki - Laki" v-model="dataDetail.gender">
                                        <label class="form-check-label" for="inlineRadio1">Laki - Laki</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-if="dataDetail !== null" :checked="dataDetail.gender === 'Perempuan'" class="form-check-input" type="radio" id="inlineRadio2" name="gender" value="Perempuan" v-model="dataDetail.gender">
                                        <label class="form-check-label" for="inlineRadio2">Perempuan</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label class="form-label">Tanggal Mulai Bekerja</label>
                                <input v-if="dataDetail !== null" type="datetime-local" class="form-control"  v-model="dataDetail.join_date" disabled/>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <user-model :editData="editData" :deleteItemId="itemIdToDelete" :roleData="dataRole" @dataUpdated="getData"></user-model>
  </template>
  
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        dataDetail: null,
        dataRole: [],
        params: {
          row: 10,
          keyword: "",
          sortby: "id",
          sorttype: "asc",
          page: 1
        },
        logo: '',
        editData: {},
        dataId: null,
        
      };
    },
    name: "profile",
    computed: {

    },
    methods: {
        editCurrentSubmit() {
            this.dataDetail.photo = this.logo !== '' ? this.logo : this.dataDetail.photo;
            const id = this.dataDetail.id;
            var loading = this.$loading.show()
            this.$store
            .dispatch("user/updateUser", { id: id, data: this.dataDetail })
            .then((data) => {
                if (data.status == 200) {
                loading.hide()
                this.logo = '';
                this.getData();
                }
            },
            (error) => {
                console.log(error)
                loading.hide();
            })
            .catch((e) => {
                loading.hide()
            })
        },
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("user/showUser", this.dataId)
                .then((resp) => {
                    console.log(resp);
                  loading.hide();
                  this.dataDetail = resp.data;
                })
                .catch((e) => {
                    // this.$toast.error(e);
                    console.log(e)
                    loading.hide();
                });
        },
        openEditModal(data) {
          this.editData = { data };
        },
        openDeleteModal(data) {
          this.itemIdToDelete = { data };
        },
        getRole() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("role/getRole", this.params)
                .then((resp) => {
                    this.dataRole = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        validateFile(file, allowedFileTypes, maxFileSize) {
          if (!allowedFileTypes.includes(file.type)) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
              });
              return false;
          }

          if (file.size > maxFileSize) {
              this.$swal.fire({
                  icon: 'warning',
                  text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
              });
              return false;
          }

          return true;
        },
        // method upload file / foto
        selectFileAttachment(event) {
            this.file = event.target.files[0];
            const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const maxFileSize = 2 * 1024 * 1024; // 2 MB

            if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
                return;
            }
            var loading = this.$loading.show();
            uploadFile(this.file)
                .then((resp) => {
                    this.logo = resp;
                    loading.hide();
                    this.$swal.fire({
                        icon: 'success',
                        text: 'File berhasil diupload !!',
                    });
                })
                .catch((e) => {
                    loading.hide();
                    this.$swal.fire({
                        icon: 'warning',
                        text: 'Terjadi kesalahan, ' + e,
                    })
                });
        },
    },
    async mounted() {
        this.dataId = this.$route.params.id;
        this.getRole();
        this.getData();
    },
  };
  </script>
  <style>
  .ant-select-selection-item {
    height: 30px;
    width: 90px;
    margin-top: -6px;
    font-size: 15px;
  }
  </style>
