<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <sidelinkheading />

      <div class="content w-100">
        <div class="content-page-header">
          <h5>SMS Settings</h5>
        </div>
        <div class="location-set">
          <div class="row align-items-center">
            <div class="col-lg-6 col-12">
              <div class="location-setcontent">
                <h5>SMS</h5>
                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing</h6>
              </div>
            </div>
            <div class="col-lg-6 col-12 text-end">
              <div class="active-switch">
                <label class="switch">
                  <input type="checkbox" checked />
                  <span class="sliders round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="location-set">
          <div class="row align-items-center">
            <div class="col-lg-6 col-12">
              <div class="location-setcontent">
                <h5>Default OTP</h5>
                <h6>You can use default OTP 1234 for Demo</h6>
              </div>
            </div>
            <div class="col-lg-6 col-12 text-end">
              <div class="active-switch">
                <label class="switch">
                  <input type="checkbox" checked />
                  <span class="sliders round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row location-set">
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-grouphead mb-2">
              <h2>Mail Provider</h2>
            </div>
            <div class="nav-menus">
              <img src="@/assets/admin_img/company/nexmo.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#nexmo-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-grouphead mb-2">
              <h2>&nbsp;</h2>
            </div>
            <div class="nav-menus">
              <img src="@/assets/admin_img/company/2-factor.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#twofactor-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-grouphead mb-2">
              <h2>&nbsp;</h2>
            </div>
            <div class="nav-menus">
              <img src="@/assets/admin_img/company/twilio.png" alt="img" />
              <div class="settings-view">
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  class="ms-3 me-0"
                  data-bs-toggle="modal"
                  data-bs-target="#twilio-config"
                  ><i class="feather feather-settings"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <sms-model></sms-model>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
