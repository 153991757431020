<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit mb-0">
          <h5>Categories</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A -> Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-category"
                >
                  <i class="fa fa-plus me-2"></i>Add Category
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Category'">
                      <div class="table-imgname">
                        <img
                          :src="
                            require(`@/assets/admin_img/services/${record.Category_img}`)
                          "
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Category }}</span>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Featured'">
                      <div class="active-switch">
                        <label class="switch">
                          <input type="checkbox" checked="" />
                          <span class="sliders round"></span>
                        </label>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <button
                          class="btn delete-table me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-category"
                        >
                          <i class="feather feather-edit"></i>
                        </button>
                        <button
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <categories-model></categories-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category",
    dataIndex: "Category",
    key: "Category",
    sorter: {
      compare: (a, b) => {
        a = a.Category.toLowerCase();
        b = b.Category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category Slug",
    dataIndex: "Category_Slug",
    sorter: {
      compare: (a, b) => {
        a = a.Category_Slug.toLowerCase();
        b = b.Category_Slug.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Featured",
    dataIndex: "Featured",
    key: "Featured",
    sorter: true,
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Category_img: "service-03.jpg",
    Category: "Computer Repair",
    Category_Slug: "Computer",
    Date: "28 Sep 2023",
    Featured: "",
    Action: "",
  },
  {
    id: "2",
    Category_img: "service-02.jpg",
    Category: "Car Repair Services",
    Category_Slug: "Automobile",
    Date: "17 Sep 2023",
    Featured: "",
    Action: "",
  },
  {
    id: "3",
    Category_img: "service-04.jpg",
    Category: "Car Wash",
    Category_Slug: "Car Wash",
    Date: "13 Sep 2023",
    Featured: "",
    Action: "",
  },
  {
    id: "4",
    Category_img: "service-09.jpg",
    Category: "House Cleaning",
    Category_Slug: "Cleaning",
    Date: "07 Sep 2023",
    Featured: "",
    Action: "",
  },
  {
    id: "5",
    Category_img: "service-08.jpg",
    Category: "Building Construction",
    Category_Slug: "Cleaning",
    Date: "07 Sep 2023",
    Featured: "",
    Action: "",
  },
  {
    id: "6",
    Category_img: "service-07.jpg",
    Category: "Interior Designing",
    Category_Slug: "Interior",
    Date: "07 Sep 2023",
    Featured: "",
    Action: "",
  },
  {
    id: "7",
    Category_img: "service-09.jpg",
    Category: "Commercial Painting",
    Category_Slug: "Painting",
    Date: "22 Aug 2023",
    Featured: "",
    Action: "",
  },
  {
    id: "8",
    Category_img: "service-12.jpg",
    Category: "Plumbing Services",
    Category_Slug: "Plumbing",
    Date: "15 Aug 2023",
    Featured: "",
    Action: "",
  },
];
export default {
  data() {
    return {
      columns,
      data,
      Select: ["A -> Z", "Z -> A"],
    };
  },
  name: "admincategories",
};
</script>
