<template>
  <div class="content">
    <div class="container">
      <!-- Contact Details -->
      <div class="contact-details">
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-4 d-flex">
            <div class="contact-info flex-fill">
              <span><i class="feather-phone"></i></span>
              <div class="contact-data">
                <h4>Phone Number</h4>
                <p>(888) 888-8888</p>
                <p>(123) 456-7890</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 d-flex">
            <div class="contact-info flex-fill">
              <span><i class="feather-mail"></i></span>
              <div class="contact-data">
                <h4>Email Address</h4>
                <p><a href="mailto:truelysell@example.com">truelysell@example.com</a></p>
                <p><a href="mailto:johnsmith@example.com">johnsmith@example.com</a></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 d-flex">
            <div class="contact-info flex-fill">
              <span><i class="feather-map-pin"></i></span>
              <div class="contact-data">
                <h4>Address</h4>
                <p>367 Hillcrest Lane, Irvine, California, United States</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Contact Details -->

      <!-- Get In Touch -->
      <div class="row">
        <div class="col-md-6">
          <div class="contact-img">
            <img src="@/assets/img/contact.jpg" class="img-fluid" alt="img" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="contact-queries">
            <h2>Get In Touch</h2>
            <form @submit.prevent="submitForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Name</label>
                    <input class="form-control" type="text" placeholder="Enter Name*" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label">Email</label>
                    <input
                      class="form-control"
                      type="email"
                      placeholder="Enter Email Address*"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="col-form-label">Phone Number</label>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Phone Number"
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Message</label>
                    <textarea
                      class="form-control"
                      rows="4"
                      placeholder="Type Message"
                    ></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <b-button variant="primary" type="submit"
                    >Send Message<i class="feather-arrow-right-circle ms-2"></i
                  ></b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- /Get In Touch -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/contact-us");
    },
  },
};
</script>
