<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar />

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Offers</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Service'">
                      <a href="javascript:void(0);" class="table-imgname">
                        <img
                          :src="
                            require(`@/assets/admin_img/services/${record.Service_img}`)
                          "
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Service }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'User_Name'">
                      <div class="table-namesplit">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="
                              require(`@/assets/admin_img/customer/${record.User_img}`)
                            "
                            class="me-2"
                            alt="img"
                          />
                        </a>
                        <a href="javascript:void(0);" class="table-name">
                          <span>{{ record.User_Name }}</span>
                          <p>{{ record.Email }}</p>
                        </a>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <button
                          class="btn delete-table me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-offer"
                        >
                          <i class="feather feather-edit"></i>
                        </button>

                        <button
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <offers-model></offers-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User_Name",
    dataIndex: "User_Name",
    key: "User_Name",
    sorter: {
      compare: (a, b) => {
        a = a.User_Name.toLowerCase();
        b = b.User_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "Email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Offer",
    dataIndex: "Offer",
    sorter: {
      compare: (a, b) => {
        a = a.Offer.toLowerCase();
        b = b.Offer.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Offer_Price",
    dataIndex: "Offer_Price",
    sorter: {
      compare: (a, b) => {
        a = a.Offer_Price.toLowerCase();
        b = b.Offer_Price.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "End_Date",
    dataIndex: "End_Date",
    sorter: {
      compare: (a, b) => {
        a = a.End_Date.toLowerCase();
        b = b.End_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];

const data = [
  {
    id: "1",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    User_img: "user-01.jpg",
    User_Name: "John Smith",
    Email: " johnsmith@gmail.com",
    Amount: "$80",
    Offer: "25%",
    Offer_Price: "$20",
    Date: "28 Sep 2023",
    End_Date: "28 Sep 2023",
    Action: "",
  },
  {
    id: "2",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    User_img: "user-04.jpg",
    User_Name: "Johnny",
    Email: "johnny@gmail.com",
    Amount: "$50",
    Offer: "10%",
    Offer_Price: "$5",
    Date: "17 Sep 2023",
    End_Date: "17 Sep 2023",
    Action: "",
  },
  {
    id: "3",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    User_img: "user-06.jpg",
    User_Name: "Robert",
    Email: "robert@gmail.com",
    Amount: "$50",
    Offer: "10%",
    Offer_Price: "$5",
    Date: "17 Sep 2023",
    End_Date: "17 Sep 2023",
    Action: "",
  },
  {
    id: "4",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    User_img: "user-09.jpg",
    User_Name: "Sharonda",
    Email: "sharonda@gmail.com",
    Amount: "$150",
    Offer: "20%",
    Offer_Price: "$15",
    Date: "23 Sep 2023",
    End_Date: "23 Sep 2023",
    Action: "",
  },
  {
    id: "5",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    User_img: "user-01.jpg",
    User_Name: "John Smith",
    Email: "johnsmith@gmail.com",
    Amount: "$250",
    Offer: "50%",
    Offer_Price: "$15",
    Date: "03 Sep 2023",
    End_Date: "03 Sep 2023",
    Action: "",
  },
  {
    id: "6",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    User_img: "user-04.jpg",
    User_Name: "Johnny",
    Email: "johnny@gmail.com",
    Amount: "$50",
    Offer: "10%",
    Offer_Price: "$5",
    Date: "17 Sep 2023",
    End_Date: "17 Sep 2023",
    Action: "",
  },
  {
    id: "7",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    User_img: "user-06.jpg",
    User_Name: "Robert",
    Email: "robert@gmail.com",
    Amount: "$50",
    Offer: "10%",
    Offer_Price: "$5",
    Date: "17 Sep 2023",
    End_Date: "17 Sep 2023",
    Action: "",
  },
  {
    id: "8",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    User_img: "user-09.jpg",
    User_Name: "Sharonda",
    Email: "sharonda@gmail.com",
    Amount: "$150",
    Offer: "20%",
    Offer_Price: "$15",
    Date: "23 Sep 2023",
    End_Date: "23 Sep 2023",
    Action: "",
  },
];

export default {
  data() {
    return {
      Select: ["A -> Z", "Z -> A"],
      columns,
      data,
    };
  },
};
</script>
