<template>
  <div class="row align-items-center">
    <div class="col-sm-6">
      <div class="widget-title">
        <h4>Favorites</h4>
      </div>
    </div>

    <div class="col-sm-6 d-flex align-items-center justify-content-end">
      <div class="review-sort d-flex align-items-center me-2">
        <p>Sort</p>
        <vue-select :options="abcd" placeholder="A -> Z" />
      </div>
      <div class="grid-listview">
        <ul>
          <li>
            <a href="javascript:void(0);">
              <i class="feather-filter"></i>
            </a>
          </li>
          <li>
            <router-link to="/customer-favourite" class="active">
              <i class="feather-grid"></i>
            </router-link>
          </li>
          <li>
            <router-link to="/customer-booking">
              <i class="feather-list"></i>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      abcd: ["A -> Z", "Most helful"],
    };
  },
};
</script>
