<script setup>
import { ref, onMounted } from 'vue'

const isOpen = ref(false)
const cell = ref(null)

const mouseX = ref(0)
const mouseY = ref(0)
const watchMouseX = ref(0)
const watchMouseY = ref(0)

function withState(props) {
  cell.value = props.cell
}

function open() {
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight

  const containerWidth = 300
  const containerHeight = 150

  const currentMouseX = watchMouseX.value
  const currentMouseY = watchMouseY.value

  const isXOverflow = (containerWidth + currentMouseX) > windowWidth
  if (isXOverflow) {
    mouseX.value = currentMouseX - containerWidth
  } else {
    mouseX.value = currentMouseX 
  }

  const isYOverflow = (containerHeight + currentMouseY) > windowHeight
  if (isYOverflow) {
    mouseY.value = currentMouseY - containerHeight
  } else {
    mouseY.value = currentMouseY
  }

  isOpen.value = true
}

function cleanup() {
  cell.value = null
}

function close() {
  isOpen.value = false
  cleanup()
}

function onMouseMove(event) {
  watchMouseX.value = event.pageX
  watchMouseY.value = event.pageY
}

onMounted(() => {
  document.addEventListener('mousemove', onMouseMove)
})

defineExpose({
  withState,
  open
})
</script>

<template>
  <div v-if="isOpen" class="position-absolute" style="left: 0px; right: 0px; top: 0px; bottom: 0px; background-color: transparent;" @click="close">
  </div>

  <div :style="{'top': mouseY + 'px', 'left': mouseX + 'px'}" class="position-absolute">
    <div :class="{'show': isOpen}" class="dropdown-menu dropdown-menu-right p-2 shadow-sm" style="width: 300px;">
      <div class="d-flex justify-content-between align-items-center">
        <h6 class="mb-0">Pilih</h6>
        <i @click="close" class="feather feather-x" style="font-size: 28px; cursor: pointer;"></i>
      </div>
      <div class="d-flex flex-column border-top mt-2 pt-2">
        <button data-bs-toggle="modal" data-bs-target="#add-order" @click="close" class="btn btn-secondary mb-2">Tambah order</button>
      </div>
    </div>
  </div>
</template>
