<template>
  <!-- Add Featured Services -->
  <div
    class="modal fade"
    id="add-features"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Featured Services</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Service</label>
                  <vue-select
                    :options="FeaturedService"
                    id="featuredservice"
                    placeholder="Select Service"
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-groupheads d-flex d-flex justify-content-between">
                <h2>Status</h2>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <button type="submit" class="btn btn-primary">Save</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add Featured Services -->

  <!-- Edit Featured Services -->
  <div
    class="modal fade"
    id="edit-features"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Featured Services</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Service</label>
                  <vue-select
                    :options="FeaturedService1"
                    id="featuredservice1"
                    placeholder="Select Service"
                  />
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-groupheads d-flex d-flex justify-content-between">
                <h2>Status</h2>
                <div class="active-switch">
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="sliders round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <button type="submit" class="btn btn-primary">Save</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add Featured Services -->

  <!-- Delete -->
  <div
    class="modal fade"
    id="delete-item"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Featured Services</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="del-modal">
              <p>Are you sure want to Delete?</p>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary">Yes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Delete -->
</template>
<script>
export default {
  data() {
    return {
      FeaturedService: ["Select Service", "Service 2"],
      FeaturedService1: ["Select Service", "Service 2"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/services");
    },
  },
};
</script>
