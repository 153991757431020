<template>
  <div class="bg-img">
    <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg1" />
    <img src="@/assets/img/bg/work-bg-03.png" alt="img" class="bgimg2" />
    <img src="@/assets/img/bg/feature-bg-03.png" alt="img" class="bgimg3 w-50" />
  </div>

  <div class="content book-content">
    <div class="container">
      <div class="row">
        <!-- Booking -->
        <div class="col-lg-10 mx-auto">
          <!-- Booking Step -->
          <ul class="step-register row">
            <li class="activate col-md-4">
              <div class="multi-step-icon">
                <span><img src="@/assets/img/icons/calendar-icon.svg" alt="img" /></span>
              </div>
              <div class="multi-step-info">
                <h6>Appointment</h6>
                <p>Choose time & date for the service</p>
              </div>
            </li>
            <li class="activate col-md-4">
              <div class="multi-step-icon">
                <span><img src="@/assets/img/icons/wallet-icon.svg" alt="img" /></span>
              </div>
              <div class="multi-step-info">
                <h6>Payment</h6>
                <p>Select Payment Gateway</p>
              </div>
            </li>
            <li class="active col-md-4">
              <div class="multi-step-icon">
                <span><img src="@/assets/img/icons/book-done.svg" alt="img" /></span>
              </div>
              <div class="multi-step-info">
                <h6>Done</h6>
                <p>Completion of Booking</p>
              </div>
            </li>
          </ul>
          <!-- /Booking Step -->

          <!-- Booking Done -->
          <div class="row align-items-center">
            <div class="col-md-7">
              <div class="booking-done">
                <h6>Successfully Completed Payment</h6>
                <p>Your Booking has been Successfully Competed</p>
                <div class="book-submit">
                  <router-link to="/" class="btn btn-primary"
                    ><i class="feather-arrow-left-circle"></i> Go to Home</router-link
                  >
                  <a href="javascript:;" class="btn btn-secondary"
                    ><i class="fa-solid fa-calendar-days me-2"></i>Add to Calender</a
                  >
                  <router-link to="/" class="btn btn-secondary"
                    >Booking History</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="booking-done">
                <img src="@/assets/img/booking-done.png" class="img-fluid" alt="" />
              </div>
            </div>
          </div>
          <!-- /Booking Done -->
        </div>
        <!-- /Booking -->
      </div>
    </div>
  </div>
</template>
