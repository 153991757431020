<template>
  <!-- Service Information -->
  <fieldset id="first-field">
    <div class="container-service space-service">
      <div class="sub-title">
        <h6>Service Information</h6>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Provider</label>
            <vue-select :options="Provider" id="provider" placeholder="Select Provider" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Service Title</label>
            <input type="text" class="form-control" placeholder="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Category</label>
            <vue-select :options="Category" id="category" placeholder="Select Category" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Sub Category</label>
            <vue-select
              :options="Sub_Category"
              id="sub-category"
              placeholder="Select Sub Category"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group price">
            <label>Price <span>Set 0 for free</span></label>
            <input type="text" class="form-control" placeholder="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Duration</label>
            <div class="form-duration">
              <input type="text" class="form-control" placeholder="" />
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group service-editor">
            <label>Description</label>
            <!-- <div id="editor"></div> -->
            <summernoteEditor
              v-model="myValue"
              @update:modelValue="summernoteChange($event)"
              @summernoteImageLinkInsert="summernoteImageLinkInsert"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container-service">
      <div class="row">
        <div class="col-sm-12">
          <div class="additional">
            <div class="sub-title Service">
              <h6>Additional Service</h6>
            </div>
            <div class="status-toggle float-sm-end mb-3">
              <input type="checkbox" id="status_1" class="check" checked="" />
              <label for="status_1" class="checktoggle">checkbox</label>
            </div>
          </div>
        </div>
      </div>
      <div class="addservice-info">
        <div class="row service-cont">
          <div class="col-md-4">
            <div class="form-group">
              <label>Additional Service</label>
              <input type="text" class="form-control" placeholder="Enter Service" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Price</label>
              <input type="text" class="form-control" placeholder="Enter Price" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group tax">
              <label>Duration <span>Include tax</span></label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Service Duration"
              />
            </div>
          </div>
        </div>

        <div class="row service-cont" v-for="(certify, index) in certify" :key="index">
          <div class="col-md-4">
            <div class="form-group">
              <label>Additional Service</label>
              <input type="text" class="form-control" placeholder="Enter Service" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Price</label>
              <input type="text" class="form-control" placeholder="Enter Price" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex align-items-center">
              <div class="form-group w-100">
                <label>Duration </label
                ><input
                  type="text"
                  class="form-control"
                  placeholder="Enter Service Duration"
                />
              </div>
              <div class="form-group">
                <label>&nbsp;</label
                ><a
                  href="javascript:;"
                  @click="deletecertify(index)"
                  class="btn btn-danger-outline trash"
                  ><i class="far fa-trash-alt"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href="javascript:void(0);" class="link-sets add-extra" @click="addcertify"
        ><i class="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Additional
        Service</a
      >
    </div>
    <div class="container-service space-service">
      <div class="row">
        <div class="col-lg-12">
          <div class="video">
            <div class="video-title">
              <h6>Video</h6>
            </div>
          </div>
          <div class="video-link">
            <div class="form-group">
              <label>Video Link</label>
              <input
                type="text"
                class="form-control"
                placeholder="https://www.youtube.com/shorts/Lf-Z7H8bZ8o"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  <!-- /Service Information -->
</template>

<script>
import SummernoteEditor from "vue3-summernote-editor";
export default {
  components: {
    SummernoteEditor,
  },
  data() {
    return {
      Provider: ["Select Provider", "Johnny", "James"],
      Category: ["Select Category", "Car Wash", "House Cleaning"],
      Sub_Category: ["Select Sub Category", "car Repair", "Plumbing"],
      myValue: "Description",
      certify: [],
    };
  },
  methods: {
    summernoteChange(newValue) {
      // console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      // console.log("summernoteImageLinkInsert()", args);
    },
    addcertify() {
      this.certify.push({});
    },
    deletecertify(index) {
      this.certify.splice(index, 1);
    },
  },
};
</script>
