<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>List Invoice</h4>
                  <p>Kamu bisa lihat daftar invoice disni!</p>
                </div>
                <div class="col-md-6 text-md-end aos" data-aos="fade-up">
                    
                </div>
              </div>

              <div class="content-page-header content-page-headersplit mb-0 mt-4">
                <div class="list-btn">
                  <ul>
                    <li>
                      <div class="input-group mt-2">
                        <div class="col-3">
                          <input class="form-control" style="height: 100%;" type="text" v-model="params.keyword" placeholder="Search" @keyup.enter="search"/>
                        </div>
                        <button @click="search" class="btn btn-primary" type="button">Search</button>
                        <div class="col-3 ms-3">
                            <select class="form-select" v-model="filterInvoice.status" @change="search">
                                <option value="SUDAH LUNAS">SUDAH LUNAS</option>
                                <option value="BELUM LUNAS">BELUM LUNAS</option>
                            </select>
                        </div>
                        <div class="col-3 ms-3">
                            <select class="form-select" v-model="filterInvoice.type" @change="search">
                                <option value="PEMBAYARAN">PEMBAYARAN</option>
                                <option value="DENDA">DENDA</option>
                            </select>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <div class="table-resposnive table-div">
                    <div class="table datatable">
                      <a-table :columns="columns" :data-source="data" :pagination="pagination" style="overflow-x: auto;">
                        <template #bodyCell="{ column, record }">
                          <template v-if="column.key === 'total_price'">
                            {{ formatCurrency(record.total_price) }}
                          </template>
                          <template v-if="column.key === 'sudah_bayar'">
                            {{ formatCurrency(record.sudah_bayar) }}
                          </template>
                          <template v-if="column.key === 'harus_bayar'">
                            {{ formatCurrency(record.harus_bayar) }}
                          </template>
                          <template v-if="column.key === 'id'">
                            <div class="table-actions d-flex">
                              <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#edit-invoice"
                                @click="openEditModal(record)"
                              >
                                <i class="feather feather-edit"></i>
                              </button>
                              <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#invoice-pelanggan-pdf"
                                @click="previewInvoicePdf(record)"
                              >
                                <i class="fa-solid fa-file-invoice"></i>
                              </button>

                              <button
                                class="btn delete-table me-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#delete-invoice"
                                @click="openDeleteModal(record)"
                              >
                                <i class="feather feather-trash-2"></i>
                              </button>
                            </div>
                          </template>
                        </template>
                      </a-table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    
    <invoice-model :editData="editData" :deleteItemId="itemIdToDelete" :invoicePdfDataProp="invoicePdfData" @dataUpdated="search"></invoice-model>

    <Dialog ref="dialog">
      <div style="width: 400px; height: 600px;">
        <header>
          <button class="btn btn-secondary" @click="onDownload">
            <i class="fa-solid fa-download"></i>
            Download
          </button>
        </header>
        <VuePdfEmbed v-if="previewPdf" ref="pdfEmbed" :source="previewPdf" />
      </div>
    </Dialog>
  </template>
  
  <script>
  import Dialog from '@/views/mari/components/dialog.vue'
  import VuePdfEmbed from 'vue-pdf-embed'
  // import 'vue-pdf-embed/dist/style/index.css'

  const columns = [
    {
      title: "No Invoice",
      dataIndex: "no_invoice",
      key: "no_invoice",
    }, 
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "customerName",
    }, 
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
    }, 
    {
      title: "Sudah Bayar",
      dataIndex: "sudah_bayar",
      key: "sudah_bayar",
    }, 
    {
      title: "Harus Bayar",
      dataIndex: "harus_bayar",
      key: "harus_bayar",
    }, 
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Tipe",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    components: {
      VuePdfEmbed,
      Dialog,
    },
    data() {
      return {
        columns,
        data: [],
        editData: null,
        params: {
          row: 10,
          page: 1,
          keyword: ''
        },
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        previewPdf: null,
        filterInvoice: {
          status: 'BELUM LUNAS',
          type: 'PEMBAYARAN'
        },
        itemIdToDelete: {},
        invoicePdfData: null
      };
    },
    name: "financeinvoice",
    computed: {

    },
    methods: {
      async search() {
        this.params.page = 1;
        await this.setInvoices();
      },
      async handlePageChange(page) {
        this.pagination.current = page;
        this.params.page = page;
        await this.setInvoices();
      },
      async handlePageSizeChange(current, size) {
        this.pagination.pageSize = size;
        this.pagination.current = 1;
        this.params.row = size;
        this.params.page = 1;
        await this.setInvoices();
      },
      async onDownload() {
        await this.$refs.pdfEmbed.download('test.pdf')
        this.$refs.dialog.close()
      },
      async previewInvoicePdf(record) {
        const loading = this.$loading.show()
        try {
          const data = await this.$store.dispatch('financeInvoice/getInvoicePdfData', record.id)
          this.invoicePdfData = data
        } catch (e) {
          this.$toast.error(e)
        } finally {
          loading.hide()
        }

      },
      async printInvoicePdf(record) {
        const loading = this.$loading.show()
        try {
          const previewBlob = await this.$store.dispatch('financeInvoice/printInvoicePdf', record.id)
          const newBlob = new Blob([previewBlob], { type: 'application/pdf' });

          function blobToBase64(blob) {
            return new Promise((resolve, _) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            });
          }

          this.previewPdf = await blobToBase64(newBlob);
          this.$refs.dialog.open()
        } catch (e) {
          this.$toast.error(e)
        } finally {
          loading.hide()
        }
      },
      getInvoices() {
        return this.$store.dispatch('financeInvoice/getInvoices', {
          ...this.params,
          ...this.filterInvoice
        })
      },
      async setInvoices() {
        var loading = this.$loading.show();
        try {
          const resp = await this.getInvoices()
          console.log('resp', resp)
          this.data = resp.data.map((invoice) => {
            return {
              ...invoice,
              customerName: invoice.customer ? invoice.customer.name : 'Pelanggun sudah di hapus',
              harus_bayar: parseFloat(invoice.total_price) - parseFloat(invoice.sudah_bayar)
            }
          })
        } catch (e) {
          console.log('e', e)
          this.$toast.error(e);
        } finally {
          loading.hide()
        }
      },
      openEditModal(record) {
        this.editData = {
          data: record
        }
      },
      formatCurrency(value) {
        if (!value && value !== 0) return '';
        // Convert value to a string
        let stringValue = value.toString();
        // Split the string into integer and decimal parts
        let parts = stringValue.split('.');
        // Format the integer part with thousand separators
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        // Join the parts and return the formatted value
        return parts[1] === undefined || parts[1] === '00' ? parts[0] : parts.join(',');
      },
      openDeleteModal(data) {
        // Set the edit data before opening the modal
        this.itemIdToDelete = { data };
      },
    },
    async mounted() {
      const keyword = this.$route.query.keyword || '';
      this.params.keyword = keyword;
      await this.setInvoices() 
    },
  };
  </script>
    <style>
    .ant-select-selection-item {
      height: 30px;
      width: 90px;
      margin-top: -6px;
      font-size: 15px;
    }
    </style>
