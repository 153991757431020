<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Roles & Permission</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  class="btn btn-primary"
                  href="javascript:;"
                  data-bs-toggle="modal"
                  data-bs-target="#add-role"
                  ><i class="fa fa-plus me-2"></i>Add Role</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <td>{{ record.Name }}</td>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-action d-flex">
                        <a
                          class="me-2"
                          href="javascript:;"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-role"
                        >
                          <i class="feather feather-edit-3"></i> Edit Role
                        </a>
                        <router-link to="/admin/permissions">
                          <i class="feather feather-shield"></i> Permissions
                        </router-link>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <roles-model></roles-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created",
    dataIndex: "Created",
    sorter: {
      compare: (a, b) => {
        a = a.Created.toLowerCase();
        b = b.Created.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
  },
];
const data = [
  {
    id: "1",
    Name: "Adminstrator",
    Created: "30 Sep 2023",
  },
  {
    id: "2",
    Name: "Manager",
    Created: "16 Sep 2023",
  },
  {
    id: "3",
    Name: "Accountant",
    Created: "23 Sep 2023",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
