<template>
  <!-- Nexmo -->
  <div class="modal fade" id="nexmo-config">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nexmo Configuration</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">API Key</label>
              <input type="text" class="form-control" placeholder="Enter API key" />
            </div>
            <div class="mb-3">
              <label class="form-label">API Secret Key</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter API Secret Key"
              />
            </div>
            <div class="mb-4">
              <label class="form-label">Sender ID</label>
              <input type="text" class="form-control" placeholder="Enter API Sender ID" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Nexmo -->

  <!-- 2Factor -->
  <div class="modal fade" id="twofactor-config">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">2Factor Configuration</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">API Key</label>
              <input type="text" class="form-control" placeholder="Enter API key" />
            </div>
            <div class="mb-3">
              <label class="form-label">API Secret Key</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter API Secret Key"
              />
            </div>
            <div class="mb-4">
              <label class="form-label">Sender ID</label>
              <input type="text" class="form-control" placeholder="Enter API Sender ID" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /2Factor -->

  <!-- Twilio -->
  <div class="modal fade" id="twilio-config">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Twilio Configuration</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">API Key</label>
              <input type="text" class="form-control" placeholder="Enter API key" />
            </div>
            <div class="mb-3">
              <label class="form-label">API Secret Key</label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter API Secret Key"
              />
            </div>
            <div class="mb-4">
              <label class="form-label">Sender ID</label>
              <input type="text" class="form-control" placeholder="Enter API Sender ID" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Twilio -->
</template>
