<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <!-- Settings Menu -->
        <customer-sidebar></customer-sidebar>
        <!-- /Settings Menu -->

        <!-- Security Settings -->
        <div class="col-lg-9">
          <div class="widget-title">
            <h4>Security Settings</h4>
          </div>
          <div class="linked-item">
            <div class="row">
              <div class="col-md-9">
                <div class="linked-acc mb-sm-3 mb-md-0">
                  <span class="link-icon">
                    <img src="@/assets/img/icons/lock-icon.svg" alt="" />
                  </span>
                  <div class="linked-info">
                    <h6>Password</h6>
                    <p>Last Changed <span>22 Sep 2023, 10:30:55 AM</span></p>
                  </div>
                </div>
              </div>
              <div class="col-md-3 text-md-end">
                <div class="security-verifi d-flex align-items-center">
                  <button
                    class="btn btn-secondary btn-set"
                    data-bs-toggle="modal"
                    data-bs-target="#change-password"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="linked-item">
            <div class="row">
              <div class="col-md-8">
                <div class="linked-acc mb-sm-3 mb-md-0">
                  <span class="link-icon">
                    <img src="@/assets/img/icons/scan-icon.svg" alt="" />
                  </span>
                  <div class="linked-info">
                    <h6>
                      Two Factor Authendication
                      <span class="badge badge-success">Enable</span>
                    </h6>
                    <p>Lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-md-end">
                <div class="d-flex align-items-center justify-content-md-end">
                  <a href="javascript:;" class="btn btn-secondary btn-set">Disable</a>
                  <div
                    class="status-toggle status-tog d-inline-flex justify-content-between align-items-center"
                  >
                    <input type="checkbox" id="status_1" class="check" checked />
                    <label for="status_1" class="checktoggle">checkbox</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="linked-item">
            <div class="row">
              <div class="col-md-8">
                <div class="linked-acc mb-sm-3 mb-md-0">
                  <span class="link-icon">
                    <img src="@/assets/img/icons/mobile-icon.svg" alt="" />
                  </span>
                  <div class="linked-info">
                    <h6>Phone Number Verification</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-md-end">
                <div class="security-verifi d-flex align-items-center">
                  <a href="javascript:void(0);" class="link-close"
                    ><i class="fa-solid fa-circle-xmark"></i
                  ></a>
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary btn-connect"
                    data-bs-toggle="modal"
                    data-bs-target="#change-phone"
                    >Change</a
                  >
                  <a href="javascript:void(0);" class="btn-acc">Remove</a>
                </div>
              </div>
            </div>
          </div>
          <div class="linked-item">
            <div class="row">
              <div class="col-md-8">
                <div class="linked-acc mb-sm-3 mb-md-0">
                  <span class="link-icon">
                    <img src="@/assets/img/icons/scan-icon.svg" alt="" />
                  </span>
                  <div class="linked-info">
                    <h6>Email Verification</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-md-end">
                <div class="security-verifi d-flex align-items-center">
                  <a href="javascript:void(0);" class="link-check"
                    ><i class="fa-solid fa-circle-check"></i
                  ></a>
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary btn-connect"
                    data-bs-toggle="modal"
                    data-bs-target="#change-email"
                    >Change</a
                  >
                  <a href="javascript:;" class="btn-acc">Remove</a>
                </div>
              </div>
            </div>
          </div>
          <div class="linked-item">
            <div class="row">
              <div class="col-md-8">
                <div class="linked-acc mb-sm-3 mb-md-0">
                  <span class="link-icon">
                    <img src="@/assets/img/icons/monitor-icon.svg" alt="image" />
                  </span>
                  <div class="linked-info">
                    <h6>Device Management</h6>
                    <p>Last Changed <span>22 Sep 2023, 10:30:55 AM</span></p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-md-end">
                <router-link to="/device-management" class="btn btn-secondary btn-set"
                  >Manage</router-link
                >
              </div>
            </div>
          </div>
          <div class="linked-item">
            <div class="row">
              <div class="col-md-8">
                <div class="linked-acc">
                  <span class="link-icon">
                    <img src="@/assets/img/icons/user-edit.svg" alt="image" />
                  </span>
                  <div class="linked-info">
                    <h6>Account Activity</h6>
                    <p>Last login <span>22 Sep 2023, 10:21:55 AM</span></p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 text-md-end">
                <router-link to="login-activity" class="btn btn-secondary btn-set"
                  >View All</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- /Security Settings -->
      </div>
    </div>
  </div>
  <securitysettingschangemail />
</template>
