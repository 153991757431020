<template>
  <!-- Categories Section -->
  <section class="categories-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading category-heading aos" data-aos="fade-up">
            <h2>Catering Service Categories</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-1.svg" />
                </span>
              </div>
              <h6>Event Party Caterings</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-2.svg" />
                </span>
              </div>
              <h6>Social Function Catering</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-3.svg" />
                </span>
              </div>
              <h6>Event Party Caterings</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-4.svg" />
                </span>
              </div>
              <h6>Kitty Party Caterings</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-5.svg" />
                </span>
              </div>
              <h6>Buffet Event Caterings</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-6.svg" />
                </span>
              </div>
              <h6>International Caterings</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-7.svg" />
                </span>
              </div>
              <h6>Outdoor Event Catering</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-8.svg" />
                </span>
              </div>
              <h6>Birthday Party Catering</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-9.svg" />
                </span>
              </div>
              <h6>Home Event Catering</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-10.svg" />
                </span>
              </div>
              <h6>Engagement Event Catering</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-11.svg" />
                </span>
              </div>
              <h6>Home Event Catering</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12">
          <router-link to="/service-details">
            <div class="categories-main-all">
              <div class="categories-img">
                <span>
                  <img src="@/assets/img/icons/categories-12.svg" />
                </span>
              </div>
              <h6>Indoor Event Catering</h6>
              <span class="category-bottom">
                <i class="feather-chevron-right"></i>
              </span>
            </div>
          </router-link>
        </div>
        <div class="btn-sec btn-catering aos" data-aos="fade-up">
          <router-link to="/search" class="btn btn-primary btn-view"
            >VIEW ALL CATEGORIES<i class="feather-arrow-right"></i
          ></router-link>
        </div>
      </div>
    </div>
  </section>

  <!-- /Categories Section -->

  <!-- Features Services -->
  <section class="features-four-section">
    <div class="container">
      <div class="section-heading section-heading-four">
        <div class="row align-items-center">
          <div class="col-md-6 aos" data-aos="fade-up">
            <h2>Featured Cateres</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
          <div class="col-md-6 text-md-end aos" data-aos="fade-up">
            <router-link to="/service-details" class="btn btn-primary btn-view"
              >VIEW ALL CATERERS<i class="feather-arrow-right"></i
            ></router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel features-four-slider common-four-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexfourfeatured" :key="item.id">
                <div
                  class="service-widget service-widget-space service-two aos"
                  data-aos="fade-up"
                >
                  <div class="service-img service-four-img">
                    <router-link to="/service-details">
                      <img
                        class="img-fluid serv-img"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </router-link>
                    <div class="fav-item">
                      <div class="rate-four">
                        <i class="fa-solid fa-star filled me-1"></i
                        ><span>{{ item.star }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="service-content service-four-content">
                    <h3 class="title">
                      <router-link to="/service-details">{{ item.title }}</router-link>
                    </h3>
                    <p class="service-cater-bottom">
                      <i class="feather-map-pin"></i>{{ item.city }}
                    </p>
                    <p>
                      we are also engage in offering catering services and decoration
                      services where vision...
                    </p>
                    <ul>
                      <li>{{ item.list1 }}</li>
                      <li>{{ item.list2 }}</li>
                      <li>{{ item.list3 }}</li>
                    </ul>
                    <div class="category-feature-bottom">
                      <p>{{ item.rate }}</p>
                      <router-link to="/">Book Now</router-link>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Features Services -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfourfeatured from "@/assets/json/indexfourfeatured.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexfourfeatured: indexfourfeatured,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
