<template>
    <div class="main-wrapper">

        <custombookcalendarnav />

        <div class="page-wrapper">

            <customernotifycontent />

            <customnotifydeleteacc />
        </div>
    </div>
</template>