<template>
  <div class="login-body">
    <div class="main-wrapper">
      <otpheader />

      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-6 mx-auto">
                <div class="login-wrap">
                  <div class="login-header">
                    <h3>Login</h3>
                    <p>We'll send a confirmation code to your email.</p>
                    <h6>
                      Sign in with
                      <router-link to="/login-phone">Phone Number</router-link>
                    </h6>
                  </div>

                  <!-- Login Form -->
                  <form @submit.prevent="submitForm">
                    <div class="log-form">
                      <div class="form-group">
                        <label class="col-form-label">E-mail</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="example@email.com"
                        />
                      </div>
                      <div class="form-group">
                        <label class="col-form-label d-block">Password</label>
                        <div class="pass-group">
                          <input
                            v-if="showPassword"
                            type="text"
                            class="form-control pass-input"
                            v-model="password"
                            placeholder="**********"
                          />
                          <input
                            v-else
                            type="password"
                            class="form-control pass-input"
                            placeholder="**********"
                            v-model="password"
                          />
                          <span
                            class="toggle-password"
                            @click="toggleShow"
                            :class="{
                              'feather-eye': showPassword,
                              'feather-eye-off': !showPassword,
                            }"
                          ></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="char-length">
                            <p>Must be 6 Characters at Least</p>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="text-md-end">
                            <router-link class="forgot-link" to="password-recovery"
                              >Forgot password?</router-link
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label class="custom_check">
                            <input type="checkbox" name="rememberme" class="rememberme" />
                            <span class="checkmark"></span>Remember Me
                          </label>
                        </div>
                        <div class="col-6 text-end">
                          <label class="custom_check">
                            <input type="checkbox" name="loginotp" class="loginotp" />
                            <span class="checkmark"></span>Login with OTP
                          </label>
                        </div>
                      </div>
                    </div>
                    <b-button
                      variant="primary w-100 login-btn"
                      to="/customer-dashboard"
                      type="submit"
                      >Login</b-button
                    >
                    <div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">Or, Login with your email</span>
                    </div>
                    <div class="social-login">
                      <a href="javascript:;" class="btn btn-google w-100"
                        ><img
                          src="@/assets/img/icons/google.svg"
                          class="me-2"
                          alt="img"
                        />Login with Google</a
                      >
                      <a href="javascript:;" class="btn btn-google w-100"
                        ><img
                          src="@/assets/img/icons/fb.svg"
                          class="me-2"
                          alt="img"
                        />Login with Facebook</a
                      >
                    </div>
                    <p class="no-acc">
                      Don't have an account ?
                      <router-link to="/choose-signup">Register</router-link>
                    </p>
                  </form>
                  <!-- /Login Form -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      password: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    submitForm() {
      this.$router.push("/customer-dashboard");
    },
  },
};
</script>
