import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getOrder(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/order', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getInvoicePdfData(_, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/x/get-order-invoice-pdf-data/${id}`).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getOrderDriver(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/order/order-driver', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getOrderPelunasan(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/order/pelunasan', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        checkVehicle(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/order/check', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addOrder(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/order/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateOrder(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('order/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        splitVehicle(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('order/split/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        extendOrder(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('order/extend-order/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        cancelOrder(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('order/cancel/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteOrder(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('order/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        statusOrder(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('order/status-order/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getDraf() {
            return new Promise((resolve, reject) => {
                axios.get('/order/draf').then(resp => {
                resolve(resp.data);
                }).catch(e => {
                reject(e);
                });
            });
        },

        getOrderDriverGrafik(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/order/order-driver-grafik', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },



    },
    getters: {

    }

}
