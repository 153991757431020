<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit mb-0">
          <h5>Reviews Type</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A -> Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-review-type"
                >
                  <i class="fa fa-plus me-2"></i>Add Review Type
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive table-div">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Reviews_Type'">
                      <td>{{ record.Reviews_Type }}</td>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <div class="active-switch">
                        <label class="switch">
                          <input type="checkbox" checked="" />
                          <span class="sliders round"></span>
                        </label>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <div class="table-actions d-flex">
                        <button
                          class="btn delete-table me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-review-type"
                        >
                          <i class="feather feather-edit"></i>
                        </button>
                        <button
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <review-type-model></review-type-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reviews Type",
    dataIndex: "Reviews_Type",
    key: "Reviews_Type",
    sorter: {
      compare: (a, b) => {
        a = a.Reviews_Type.toLowerCase();
        b = b.Reviews_Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Reviews_Type: "High",
    Status: "",
    Action: "",
  },
  {
    id: "2",
    Reviews_Type: "Normal",
    Status: "",
    Action: "",
  },
  {
    id: "3",
    Reviews_Type: "Excellent",
    Status: "",
    Action: "",
  },
  {
    id: "4",
    Reviews_Type: "Good",
    Status: "",
    Action: "",
  },
];
export default {
  data() {
    return {
      Select: ["A -> Z", "Z -> A"],
      columns,
      data,
    };
  },
};
</script>
