<template>
  <!--change email-modal -->
  <div
    class="modal fade"
    id="add-tax"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">System Backup</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body py-0">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>File name</label>
                <input type="text" class="form-control" placeholder="Enter file name" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label>Date</label>
                <input type="text" class="form-control" placeholder="Enter Date" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pt-0">
          <b-button type="button" variant="secondary" data-bs-dismiss="modal"
            >Close</b-button
          >
          <b-button type="button" variant="primary" data-bs-dismiss="modal"
            >Submit</b-button
          >
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete System Backup</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to Delete?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/system-backup");
    },
  },
};
</script>
