<template>
    <div class="main-wrapper">
        <indexsevenheader />

        <div class="page-wrapper">

            <indexsevenbanner />

            <indexsevenpopular />

            <indexsevenservice />

            <indexsevenprovider />

            <indexseventestimonial />

            <indexsevenpricing />

            <indexsevenpartner />

            <indexsevenfooter />

            <scroll />
        </div>
    </div>
</template>