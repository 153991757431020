<template>
  <!-- <div class="login-body"> -->
  <div class="main-wrapper">
    <successheader />

    <div class="page-wrapper">
      <div class="content">
        <div class="container">
          <div class="row">
            <!-- Password Success -->
            <div class="col-md-6 col-lg-6 mx-auto">
              <div class="login-wrap">
                <div class="otp-img">
                  <img src="@/assets/img/icons/tick-circle.svg" alt="" />
                </div>
                <div class="login-header">
                  <div class="log-form">
                    <h3>Success</h3>
                    <p>Your new password has been Successfully saved</p>
                  </div>
                </div>

                <form @submit.prevent="submitForm">
                  <b-button variant="primary w-100 login-btn" type="submit"
                    >Verify & Proceed</b-button
                  >
                </form>
              </div>
            </div>
            <!-- /Password Success -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/login");
    },
  },
};
</script>
