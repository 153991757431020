<template>
    <div class="main-wrapper">
        <layouts></layouts>
        <div class="page-wrapper">
            <breadcrumb :title="title"  :text="text" :text1="text1" />

            <howitsworkcontent />

            <truelyfooter />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "How It Works",
            text: "Home",
            text1: "How It Works",
            
        }
    },
}
</script>