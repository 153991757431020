<template>
  <div class="row">
    <!-- Service List -->
    <div class="col-xl-4 col-md-6" v-for="record in Customerfavourite" :key="record.id">
      <div class="service-widget servicecontent">
        <div class="service-img">
          <router-link to="/service-details">
            <img
              class="img-fluid serv-img"
              alt="Service Image"
              :src="require(`@/assets/img/services/${record.serviceImage}`)"
            />
          </router-link>
          <div class="fav-item">
            <router-link to="/categories"
              ><span class="item-cat">{{ record.category }}</span></router-link
            >
            <a href="javascript:void(0)" class="fav-icon selected">
              <i class="feather-heart"></i>
            </a>
          </div>
          <div class="item-info">
            <router-link to="/providers"
              ><span class="item-img"
                ><img
                  :src="require(`@/assets/img/profiles/${record.Avatar}`)"
                  class="avatar"
                  alt="" /></span
            ></router-link>
          </div>
        </div>
        <div class="service-content">
          <h3 class="title">
            <router-link to="/service-details">{{ record.serviceName }}</router-link>
          </h3>
          <p>
            <i class="feather-map-pin"></i>{{ record.location
            }}<span class="rate"
              ><i class="fas fa-star filled me-1"></i>{{ record.rating }}</span
            >
          </p>
          <div class="serv-info">
            <h6>
              {{ record.price }}<span class="old-price">{{ record.oldPrice }}</span>
            </h6>
            <router-link to="/booking" class="btn btn-book">Book Now</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /Service List -->
  </div>
</template>
<script>
import Customerfavourite from "@/assets/json/customerfavourite.json";
export default {
  data() {
    return {
      Customerfavourite: Customerfavourite,
    };
  },
};
</script>
