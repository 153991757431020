<template>
    <!-- Show User -->
    <div class="modal fade" id="show-branch" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Cabang</h5>
            <button
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Branch Name</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name" disabled/>
                  </div>
                  <div>
                    <label class="form-label">Code</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.code" disabled/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Phone Number</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number" disabled/>
                  </div>
                  <div>
                    <label class="form-label">Address</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.address" disabled/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">City</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.city" disabled/>
                  </div>
                  <div>
                    <label class="form-label">Longtitude</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.longitude" disabled/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Latitude</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.latitude" disabled/>
                  </div>
                  <div>
                    <label class="form-label">Operational Hour</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.operational_hour" disabled/>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">About</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.about" disabled/>
              </div>
              <div class="mb-3">
                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="split-check-title">
                                <h6>Status</h6>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="split-check-box">
                                <ul class="custom-radiosbtn">
                                    <li>
                                        <label class="radiossets">Aktive
                                            <input v-if="formEdit !== undefined" type="radio" name="status" value=1 v-model="formEdit.status" disabled>
                                            <span class="checkmark-radio"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="radiossets">Non Aktive
                                            <input v-if="formEdit !== undefined" type="radio" name="status" value=0 v-model="formEdit.status" disabled>
                                            <span class="checkmark-radio"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Show User -->

    <!-- Add User -->
    <div class="modal fade" id="add-branch" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Cabang</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Branch Name</label>
                    <input type="text" class="form-control" v-model="formCreate.name"/>
                  </div>
                  <div>
                    <label class="form-label">Code</label>
                    <input type="text" class="form-control" v-model="formCreate.code"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Phone Number</label>
                    <input type="text" class="form-control" v-model="formCreate.phone_number"/>
                  </div>
                  <div>
                    <label class="form-label">Address</label>
                    <input type="text" class="form-control" v-model="formCreate.address"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">City</label>
                    <input type="text" class="form-control" v-model="formCreate.city"/>
                  </div>
                  <div>
                    <label class="form-label">Longtitude</label>
                    <input type="text" class="form-control" v-model="formCreate.longitude"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Latitude</label>
                    <input type="text" class="form-control" v-model="formCreate.latitude"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Operational Hour</label>
                    <input type="text" class="form-control" v-model="formCreate.operational_hour"/>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">About</label>
                <input type="text" class="form-control" v-model="formCreate.about"/>
              </div>
              <div class="mb-3">
                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="split-check-title">
                                <h6>Status</h6>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="split-check-box">
                                <ul class="custom-radiosbtn">
                                    <li>
                                        <label class="radiossets">Aktive
                                            <input type="radio" name="status" value=1 v-model="formCreate.status">
                                            <span class="checkmark-radio"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="radiossets">Non Aktive
                                            <input type="radio" name="status" value=0 v-model="formCreate.status">
                                            <span class="checkmark-radio"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add User -->
  
    <!-- Edit User -->
    <div class="modal fade" id="edit-branch" data-bs-backdrop="static" data-bs-keyboard="false" style="--bs-modal-width: 800px;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Cabang</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Branch Name</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name"/>
                  </div>
                  <div>
                    <label class="form-label">Code</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.code"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Phone Number</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number"/>
                  </div>
                  <div>
                    <label class="form-label">Address</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.address"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">City</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.city"/>
                  </div>
                  <div>
                    <label class="form-label">Longtitude</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.longitude"/>
                  </div>
                </div>
              </div>
              <div class="mb-3 form-group">
                <div class="d-flex">
                  <div style="margin-right: 10px;">
                    <label class="form-label">Latitude</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.latitude"/>
                  </div>
                  <div>
                    <label class="form-label">Operational Hour</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.operational_hour"/>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">About</label>
                <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.about"/>
              </div>
              <div class="mb-3">
                <div class="form-group">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="split-check-title">
                                <h6>Status</h6>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="split-check-box">
                                <ul class="custom-radiosbtn">
                                    <li>
                                        <label class="radiossets">Aktive
                                            <input v-if="formEdit !== undefined" type="radio" name="status" value=1 v-model="formEdit.status">
                                            <span class="checkmark-radio"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="radiossets">Non Aktive
                                            <input v-if="formEdit !== undefined" type="radio" name="status" value=0 v-model="formEdit.status">
                                            <span class="checkmark-radio"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit User -->
  
    <div class="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Cabang ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {},
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object,
    },
    emits: ['edit-branch'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
    },
    methods: {
      editSubmit() {
        let value1 = this.formEdit.longitude;
        value1 = value1.replace(/[^0-9.,-]/g, '');
        value1 = value1.replace(',', '.');
        this.formEdit.longitude = value1;
        let value2 = this.formEdit.latitude;
        value2 = value2.replace(/[^0-9.,-]/g, '');
        value2 = value2.replace(',', '.');
        this.formEdit.latitude = value2;
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("branch/updateBranch", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {}
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createSubmit() {
        let value1 = this.formCreate.longitude;
        value1 = value1.replace(/[^0-9.,-]/g, '');
        value1 = value1.replace(',', '.');
        this.formCreate.longitude = value1;
        let value2 = this.formCreate.latitude;
        value2 = value2.replace(/[^0-9.,-]/g, '');
        value2 = value2.replace(',', '.');
        this.formCreate.latitude = value2;
        var loading = this.$loading.show()
        this.$store
          .dispatch("branch/addBranch", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {}
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteData() {
        // Perform deletion using this.deleteItemId
        console.log('Deleting item with ID:', this.deleteItemId);
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("branch/deleteBranch", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
    },
  };
  </script>
  