<template>
    <!-- About -->
			<div class="about-sec">				
				<div class="container">				
					<div class="row align-items-center">				
						<div class="col-lg-6">
							<div class="about-img">
								<div class="about-exp">
									<span>12+ years of experiences</span>
								</div>
								<div class="abt-img">
									<img src="@/assets/img/about-01.jpg" class="img-fluid" alt="img">
								</div>
							</div>
						</div>					
						<div class="col-lg-6">
							<div class="about-content">
								<h6>ABOUT OUR COMPANY</h6>
								<h2>Best Solution For Cleaning Services</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
								<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
								<div class="row">
									<div class="col-md-6">
										<ul>
											<li>At vero et accusamus iusto dignissimos</li>
											<li>At vero et accusamus iusto dignissimos</li>
										</ul>
									</div>
									<div class="col-md-6">
										<ul>
											<li>Nam libero tempore, cum soluta nobis</li>
											<li>Nam libero tempore, cum soluta nobis</li>
										</ul>
									</div>
								</div>
							</div>
						</div>					
					</div>
				</div>
			</div>
	<!-- /About -->

    <!-- Work Section -->
			<section class="work-section work-bg">			
				<div class="container">
					<div class="row">
						<div class="col-md-12 text-center">
							<div class="section-heading">
								<h2>How It Works</h2>
								<p>Aliquam lorem ante, dapibus in, viverra quis</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="work-box">
								<div class="work-icon">
									<span>
										<img src="@/assets/img/icons/work-icon.svg" alt="img">
									</span>
								</div>
								<h5>Choose What To Do</h5>
								<p>Lorem ipsum dolor amet, consectetur adipiscing  tempor labore et dolore magna aliqua.</p>
								<h4>01</h4>
							</div>						
						</div>
						<div class="col-md-4">
							<div class="work-box">
								<div class="work-icon">
									<span>
										<img src="@/assets/img/icons/find-icon.svg" alt="img">
									</span>
								</div>
								<h5>Find What You Want</h5>
								<p>Lorem ipsum dolor amet, consectetur adipiscing  tempor labore et dolore magna aliqua.</p>
								<h4>02</h4>
							</div>						
						</div>
						<div class="col-md-4">
							<div class="work-box">
								<div class="work-icon">
									<span>
										<img src="@/assets/img/icons/place-icon.svg" alt="img">
									</span>
								</div>
								<h5>Amazing Places</h5>
								<p>Lorem ipsum dolor amet, consectetur adipiscing  tempor labore et dolore magna aliqua.</p>
								<h4>03</h4>
							</div>						
						</div>
					</div>
				</div>
			</section>
	<!-- /Work Section -->	

    <!-- Choose Us Section -->
			<div class="chooseus-sec">				
				<div class="container">				
					<div class="row">		
						<div class="col-md-6">
							<div class="choose-content">
								<h2>Why Choose Us</h2>
								<p>At vero eos et accusamus et iusto odio dignissimos ducimus</p>
								<div class="support-card">
									<h4 class="support-title">
										<a class="collapsed" data-bs-toggle="collapse" href="#aboutone" aria-expanded="false">24/7 Supports</a>
									</h4>
									<div id="aboutone" class="card-collapse collapse">
										<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
									</div>
								</div>	
								<div class="support-card">
									<h4 class="support-title">
										<a class="collapsed" data-bs-toggle="collapse" href="#abouttwo" aria-expanded="false">Client’s reviews</a>
									</h4>
									<div id="abouttwo" class="card-collapse collapse">
										<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
									</div>
								</div>	
								<div class="support-card">
									<h4 class="support-title">
										<a class="collapsed" data-bs-toggle="collapse" href="#about3" aria-expanded="false">Professional Team</a>
									</h4>
									<div id="about3" class="card-collapse collapse">
										<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
									</div>
								</div>	
								<div class="support-card">
									<h4 class="support-title">
										<a class="collapsed" data-bs-toggle="collapse" href="#about4" aria-expanded="false">Best Services</a>
									</h4>
									<div id="about4" class="card-collapse collapse">
										<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
									</div>
								</div>	
							</div>
						</div>					
						<div class="col-md-6">
							<div class="chooseus-img">
								<img src="@/assets/img/about-02.png" class="img-fluid" alt="img">
							</div>
						</div>							
					</div>
					<div class="row">		
						<div class="col-md-3">
							<div class="choose-icon">
								<img src="@/assets/img/icons/choose-icon.svg" class="img-fluid" alt="img">
								<div class="choose-info">
									<h5>2583+</h5>
									<p>Satisfied Clients</p>
								</div>
							</div>
						</div>	
						<div class="col-md-3">
							<div class="choose-icon">
								<img src="@/assets/img/icons/choose-icon-01.svg" class="img-fluid" alt="img">
								<div class="choose-info">
									<h5>2383+</h5>
									<p>Expert Team</p>
								</div>
							</div>
						</div>	
						<div class="col-md-3">
							<div class="choose-icon">
								<img src="@/assets/img/icons/choose-icon.png" class="img-fluid" alt="img">
								<div class="choose-info">
									<h5>2129+</h5>
									<p>Project Completed</p>
								</div>
							</div>
						</div>	
						<div class="col-md-3">
							<div class="choose-icon border-0">
								<img src="@/assets/img/icons/choose-icon-03.svg" class="img-fluid" alt="img">
								<div class="choose-info">
									<h5>30+</h5>
									<p>Years of experience</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		<!-- /Choose Us Section -->
</template>