<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Cash On Delivery</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Service'">
                      <a href="javascript:void(0);" class="table-imgname">
                        <img
                          :src="
                            require(`@/assets/admin_img/services/${record.Service_img}`)
                          "
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Service }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'Provider_Name'">
                      <a href="javascript:void(0);" class="table-profileimage">
                        <img
                          :src="
                            require(`@/assets/admin_img/customer/${record.Provider_img}`)
                          "
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Provider_Name }}</span>
                      </a>
                    </template>
                    <template v-else-if="column.key === 'User_Name'">
                      <a href="javascript:void(0);" class="table-profileimage">
                        <img
                          :src="require(`@/assets/admin_img/customer/${record.User_img}`)"
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.User_Name }}</span>
                      </a>
                    </template>

                    <template v-else-if="column.key === 'Status'">
                      <h6 :class="record.Class">{{ record.Status }}</h6>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service",
    dataIndex: "Service",
    key: "Service",
    sorter: {
      compare: (a, b) => {
        a = a.Service.toLowerCase();
        b = b.Service.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Provider Name",
    dataIndex: "Provider_Name",
    key: "Provider_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Provider_Name.toLowerCase();
        b = b.Provider_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User Name",
    dataIndex: "User_Name",
    key: "User_Name",
    sorter: {
      compare: (a, b) => {
        a = a.User_Name.toLowerCase();
        b = b.User_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Service Status",
    dataIndex: "Service_Status",
    sorter: {
      compare: (a, b) => {
        a = a.Service_Status.toLowerCase();
        b = b.Service_Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];

const data = [
  {
    id: "1",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    Provider_img: "user-01.jpg",
    Provider_Name: "John Smith",
    User_img: "user-03.jpg",
    User_Name: "Sharon",
    Amount: "$80",
    Class: "badge-delete",
    Status: "Unpaid",
    Date: "28 Sep 2023",
    Service_Status: "Inprogress",
  },
  {
    id: "2",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    Provider_img: "user-04.jpg",
    Provider_Name: "Johnny",
    User_img: "user-05.jpg",
    User_Name: "Pricilla",
    Amount: "$50",
    Class: "badge-active",
    Status: "Paid",
    Date: "22 Sep 2023",
    Service_Status: "Payment Completed",
  },
  {
    id: "3",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    Provider_img: "user-06.jpg",
    Provider_Name: "Robert",
    User_img: "user-02.jpg",
    User_Name: "Amanda",
    Amount: "$100",
    Class: "badge-active",
    Status: "Paid",
    Date: "20 Sep 2023",
    Service_Status: "Payment Completed",
  },
  {
    id: "4",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    Provider_img: "user-09.jpg",
    Provider_Name: "Sharonda",
    User_img: "user-01.jpg",
    User_Name: "James",
    Amount: "$34",
    Class: "badge-active",
    Status: "Paid",
    Date: "15 Sep 2023",
    Service_Status: "Payment Completed",
  },
  {
    id: "5",
    Service_img: "service-03.jpg",
    Service: "Computer Repair",
    Provider_img: "user-01.jpg",
    Provider_Name: "John Smith",
    User_img: "user-03.jpg",
    User_Name: "Sharon",
    Amount: "$46",
    Class: "badge-active",
    Status: "Paid",
    Date: "23 Sep 2023",
    Service_Status: "Payment Completed",
  },
  {
    id: "6",
    Service_img: "service-02.jpg",
    Service: "Car Repair Services",
    Provider_img: "user-04.jpg",
    Provider_Name: "Johnny",
    User_img: "user-05.jpg",
    User_Name: "Pricilla",
    Amount: "$20",
    Class: "badge-delete",
    Status: "Unpaid",
    Date: "30 Sep 2023",
    Service_Status: "Pending",
  },
  {
    id: "7",
    Service_img: "service-04.jpg",
    Service: "Steam Car Wash",
    Provider_img: "user-06.jpg",
    Provider_Name: "Robert",
    User_img: "user-02.jpg",
    User_Name: "Amanda",
    Amount: "$100",
    Class: "badge-active",
    Status: "Paid",
    Date: "20 Sep 2023",
    Service_Status: "Payment Completed",
  },
  {
    id: "8",
    Service_img: "service-09.jpg",
    Service: "House Cleaning",
    Provider_img: "user-09.jpg",
    Provider_Name: "Sharonda",
    User_img: "user-01.jpg",
    User_Name: "James",
    Amount: "$34",
    Class: "badge-active",
    Status: "Paid",
    Date: "15 Sep 2023",
    Service_Status: "Payment Completed",
  },
];

export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
