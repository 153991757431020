<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Providers</h5>
          <div class="list-btn">
            <ul>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-provider"
                >
                  <i class="fa fa-plus me-2"></i>Add Provider
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Customer_Name'">
                      <td class="table-namesplit">
                        <a href="javascript:void(0);" class="table-profileimage">
                          <img
                            :src="require(`@/assets/admin_img/customer/${record.Image}`)"
                            class="me-2"
                            alt="img"
                          />
                        </a>
                        <a href="javascript:void(0);" class="table-name">
                          <span>{{ record.Customer_Name }}</span>
                          <p>{{ record.Customer_Email }}</p>
                        </a>
                      </td>
                    </template>
                    <template v-else-if="columns.key === 'Last'">
                      <div :class="record.class">
                        <span class="online-path">
                          <h6>{{ record.Last_Activity }}</h6>
                        </span>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <h6 class="badge-active">{{ record.Status }}</h6>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="table-actions d-flex">
                        <button
                          class="btn delete-table me-2"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-provider"
                        >
                          <i class="feather feather-edit"></i>
                        </button>
                        <button
                          class="btn delete-table"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                        >
                          <i class="feather feather-trash-2"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <provider-model></provider-model>
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Customer Name",
    dataIndex: "Customer_Name",
    key: "Customer_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Customer_Name.toLowerCase();
        b = b.Customer_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Mobile",
    dataIndex: "Mobile",
    sorter: {
      compare: (a, b) => {
        a = a.Mobile.toLowerCase();
        b = b.Mobile.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reg Date",
    dataIndex: "Reg_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Reg_Date.toLowerCase();
        b = b.Reg_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Last Activity",
    dataIndex: "Last_Activity",
    key: "Last",
    sorter: {
      compare: (a, b) => {
        a = a.Last_Activity.toLowerCase();
        b = b.Last_Activity.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];

const data = [
  {
    id: "1",
    Image: "user-01.jpg",
    Customer_Name: "Admin",
    Customer_Email: "admin@gmail.com",
    Mobile: "+1 888 888 8888",
    Reg_Date: "30 Sep 2023",
    Last_Activity: "2 mins ago",
    Status: "Active",
    class: "",
  },
  {
    id: "2",
    Image: "user-03.jpg",
    Customer_Name: "John Smith",
    Customer_Email: "johnsmith@gmail.com",
    Mobile: "518-837-9258",
    Reg_Date: "27 Sep 2023",
    Last_Activity: "10 mins ago",
    Status: "Active",
    class: "",
  },
  {
    id: "3",
    Image: "user-09.jpg",
    Customer_Name: "Robert",
    Customer_Email: "robert@gmail.com",
    Mobile: "302-372-7812",
    Reg_Date: "25 Sep 2023",
    Last_Activity: "Online",
    Status: "Active",
    class: "online-set",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
};
</script>
