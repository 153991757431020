<template>
  <!-- Providers Section -->
  <section class="providers-section-seven">
    <div class="container">
      <div class="section-heading section-heading-seven">
        <div class="row">
          <div class="col-md-6 aos" data-aos="fade-up">
            <h2>Top Providers</h2>
            <p>What do you need to find?</p>
          </div>
          <div class="col-md-6 text-md-end aos" data-aos="fade-up">
            <div class="owl-nav mynav-seven-three"></div>
          </div>
        </div>
      </div>
      <div class="row aos" data-aos="fade-up">
        <div class="col-md-12">
          <div class="owl-carousel top-projects-seven">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexsevenprovider" :key="item.id">
                <div class="providerset">
                  <div class="providerset-img providerset-img-seven">
                    <router-link to="/provider-details">
                      <img
                        :src="require(`@/assets/img/provider/${item.image}`)"
                        class="img-fluid"
                        alt="img"
                      />
                    </router-link>
                    <div class="fav-item">
                      <i class="fa fa-check-circle fav-icons" aria-hidden="true"></i>
                      <a
                        href="javascript:void(0)"
                        class="fav-icon"
                        @click="toggleSelected"
                      >
                        <i class="feather-heart"></i>
                      </a>
                    </div>
                  </div>
                  <div class="providerset-content providerset-content-seven">
                    <div class="providerset-price">
                      <div class="providerset-name">
                        <h4>
                          <router-link to="/provider-details">{{
                            item.name
                          }}</router-link>
                        </h4>
                        <span>{{ item.work }}</span>
                      </div>
                      <div class="rate">
                        <i class="fa-solid fa-star filled me-1"></i
                        ><span>{{ item.rating }}</span>
                      </div>
                    </div>
                    <div class="provider-rating-seven">
                      <div class="providerset-prices">
                        <h6>{{ item.price }}<span>/hr</span></h6>
                      </div>
                      <router-link to="/provider-details" class="btn btn-pink"
                        >View Details</router-link
                      >
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Providers Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexsevenprovider from "@/assets/json/indexsevenprovider.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexsevenprovider: indexsevenprovider,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    toggleSelected(event) {
      const element = event.target;
      element.classList.toggle("selected");
    },
  },
};
</script>

<style>
.top-projects-seven .providerset {
  margin-right: 24px;
}
</style>
