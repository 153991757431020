<template>
    <div class="card add-service">
        <div class="row mt-4">
            <div class="col-12">
                <form>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Nama Lengkap</label>
                            <input type="text" class="form-control" v-model="data.full_name"/>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" v-model="data.email"/>
                        </div>
                        </div>
                    </div>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Nik</label>
                            <input type="text" class="form-control" v-model="data.nik"/>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Password</label>
                            <input type="password" class="form-control" v-model="data.password"/>
                        </div>
                        </div>
                    </div>
                    <div class="mb-3 form-group">
                        <div class="d-flex">
                        <div class="col-6" style="margin-right: 10px;">
                            <label class="form-label">Ulangi Password</label>
                            <input type="password" class="form-control" v-model="data.password_confirmation"/>
                        </div>
                        <div class="col-6">
                            <label class="form-label">Jenis User</label>
                            <select class="form-select" v-model="data.type">
                            <option v-for="role in roleData" :key="role.id" :value="role.id">{{ role.name }}</option>
                            </select>
                        </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="form-group">
                            <div class="d-flex">
                                <div class="col-6">
                                    <div class="col-lg-12">
                                        <div class="split-check-title">
                                            <label class="form-label">Jenis Kelamin</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio1" name="gender" value="Laki - Laki" v-model="data.gender">
                                            <label class="form-check-label" for="inlineRadio1">Laki - Laki</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" id="inlineRadio2" name="gender" value="Perempuan" v-model="data.gender">
                                            <label class="form-check-label" for="inlineRadio2">Perempuan</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">Tanggal Mulai Bekerja</label>
                                    <input type="datetime-local" class="form-control"  v-model="data.join_date"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="field-bottom-btns">
                    <!-- <div class="field-btns">
                        <button class="btn btn-primary prev_btn" type="button"
                        @click="previousStep" :disabled="currentStep === 1"><i
                                class="fa-solid fa-arrow-left"></i>Prev</button>
                    </div> -->
                    <div class="field-btns">
                        <button class="btn btn-primary next_btn" type="button"
                        @click="save" :disabled="currentStep === totalSteps">Selanjutnya <i
                                class="fa-solid fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        // mari
        data: {},
        logo: ''
      };
    },
    props: {
        currentStep : Object,
        totalSteps : Object,
        idCompany : Object,
        roleData: {
            type: Array,
            default: () => []
        },
    },
    methods: {
      save() {
        var loading = this.$loading.show()
        this.$store
        .dispatch("user/addUser", this.data )
        .then((data) => {
            if (data.status == 201) {
                this.updateGuide();
                loading.hide()
                this.data = {};
                this.$emit('nextDriver');
            }
        },
        (error) => {
            console.log(error)
            loading.hide();
        })
        .catch((e) => {
            loading.hide()
        })
      },
      updateGuide() {
        this.$store
        .dispatch("guide/updateGuide", { id: this.idCompany, data: { guide_step : "is_user"} })
        .then((data) => {
            if (data.status == 200) {
                // loading.hide()
                // this.$emit('nextDriver');
            }
        },
        (error) => {
            console.log(error)
            // loading.hide();
        })
        .catch((e) => {
            // loading.hide()
        })
      },
      previousStep() {
        this.$emit('previous');
      }
    },
  };
  </script>
  