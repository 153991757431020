<template>
    <!-- Add Wallet -->
		<div class="modal fade custom-modal" id="add-wallet">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header border-bottom-0 justify-content-between">
						<h5 class="modal-title">Add Wallet</h5>
						<button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x"></i></button>
					</div>
					<div class="modal-body pt-0">
						<form action="javascript:;">
							<div class="wallet-add">
								<div class="form-group">
									<label class="col-form-label">Amount</label>
									<input type="text" class="form-control" placeholder="Enter Amount" /> 
								</div>
								<div class="row">
									<div class="col-md-4">
										<div class="bank-selection">
											<input type="radio" value="attach_link" id="rolelink" name="attachment" checked="">
											<label for="rolelink">
												<img src="@/assets/img/paypal.png" alt="">
												<span class="role-check"><i class="fa-solid fa-circle-check"></i></span>
											</label>
										</div>
									</div>
									<div class="col-md-4">
										<div class="bank-selection">
											<input type="radio" value="attach_link" id="rolelink1" name="attachment">
											<label for="rolelink1">
												<img src="@/assets/img/stripe.png" alt="">
												<span class="role-check"><i class="fa-solid fa-circle-check"></i></span>
											</label>
										</div>
									</div>
									<div class="col-md-4">
										<div class="bank-selection">
											<input type="radio" value="attach_link" id="rolelink2" name="attachment">
											<label for="rolelink2">
												<img src="@/assets/img/bank.png" alt="">
												<span class="role-check"><i class="fa-solid fa-circle-check"></i></span>
											</label>
										</div>
									</div>
								</div>
								<div class="modal-submit text-end">
									<a href="javascript:;" class="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</a>
									<b-button type="submit" variant="primary">Submit</b-button>
								</div>								
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- /Add Wallet -->
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>