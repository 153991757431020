<template>
    <div class="provider-body">
        <div class="main-wrapper">
            <dashboardnavbar />

            <div class="page-wrapper">
                <sidebar />

                <div class="content container-fluid">

                    <div class="row">

                        <!-- Appointment Settings -->
                        <div class="col-md-12 mx-auto">
                            <div class="appointment-sec appointment-set-two">
                                <div class="coupon-title">
                                    <h4>Appointment Settings</h4>
                                </div>

                                <div class="appointment-setting-list">
                                    <div class="appoint-wrapper">
                                        <h5>Appointment Time Intervals</h5>
                                        <p>Set the appointment time intervals</p>
                                    </div>
                                    <div class="form-group">
                                        <vue-select :options="Min" id="min" placeholder="30 Min"/>
                                    </div>
                                </div>

                                <div class="appointment-setting-list">
                                    <div class="appoint-wrapper">
                                        <h5>Allow Multiple Booking for Same Time Slot</h5>
                                        <p>Set the appointment time intervals</p>
                                    </div>
                                    <div class="status-toggle grey-tog d-flex align-items-center text-dark fw-500">
                                        <input type="checkbox" id="status" class="check" checked="" />
                                        <label for="status" class="checktoggle">checkbox</label>
                                    </div>
                                </div>

                                <div class="appointment-setting-list">
                                    <div class="appoint-wrapper">
                                        <h5>Appointment Auto Confirm</h5>
                                        <p>Enable/disable appointment auto confirm</p>
                                    </div>
                                    <div class="status-toggle grey-tog d-flex align-items-center text-dark fw-500">
                                        <input type="checkbox" id="status-one" class="check" checked="" />
                                        <label for="status-one" class="checktoggle">checkbox</label>
                                    </div>
                                </div>

                                <div class="appointment-setting-list">
                                    <div class="appoint-wrapper">
                                        <h5>Minimum Advance Booking Time</h5>
                                        <p>Minimum advance booking time (1 hours to 10 days)</p>
                                    </div>
                                    <div class="form-group">
                                        <vue-select :options="Minutes" id="minutes" placeholder="30 Minutes"/>
                                    </div>
                                </div>

                                <div class="appointment-setting-list">
                                    <div class="appoint-wrapper">
                                        <h5>Maximum Advance Booking Time</h5>
                                        <p>Set the maximum advance booking time (1 Month to 5 years)</p>
                                    </div>
                                    <div class="form-group">
                                        <vue-select :options="Months" id="months" placeholder="4 Months"/>
                                    </div>
                                </div>


                                <div class="acc-submit appoint-submit">
                                    <a href="javascript:;" class="btn btn-secondary">Reset</a>
                                    <a href="javascript:;" class="btn btn-primary">Save Changes</a>
                                </div>
                            </div>
                        </div>
                        <!-- /Appointment Settings -->

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            Min: ["30 Min", "40 Min", "50 Min"],
            Minutes: ["30 Minutes", "40 Minutes", "50 Minutes"],
            Months: ["4 Months", "5 Months", "6 Months"]
        }
    },
}
</script>