<template>
    <div class="home-page-four">
        <div class="main-wrapper">

            <indexfourheader />

            <div class="page-wrapper">

                <indexfourbanner />

                <indexfourcatering />

                <indexfourcategories />

                <indexfourwork />
                
                <indexfourservices />

                <indexfourblog />

                <indexfourfooter />

                <scroll />
            </div>
        </div>
    </div>
</template>