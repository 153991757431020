<template>
  <div class="chat-page-body">
    <div class="main-wrapper">
      <custombookcalendarnav />

      <customerchatcontent />

    </div>
  </div>
</template>

