<template>
    <div class="main-wrapper">
        <div class="change-password">
            <div class="change-passwordback">
                <router-link to="/admin/security"><i class="feather feather-arrow-left me-2"></i>Back to Dashboard </router-link>
            </div>
            <div class="change-passwordhead">
                <h3>Change  Password</h3>
                <p>Please enter your current password to change your password</p>
            </div>
            <div class="change-passwordform">
                <div class="form-group">
                    <label>Current Password</label>
                    <div class="pass-group">
                        <b-form-input
                            v-if="showPassword"
                            type="text"
                            class="form-control pass-input"
                            v-model="password"
                            placeholder="password"
                        />
                        <b-form-input v-else type="password"
                            class="form-control pass-input"
                            placeholder="password" v-model="password" />
                        <span
                            class="toggle-password"
                            @click="toggleShow"
                            :class="{
                            'feather-eye': showPassword,
                            'feather-eye-off': !showPassword,
                            }"
                        ></span>
                    </div>
                </div>
                <div class="form-group">
                    <label>New Password</label>
                    <div class="pass-group" id="passwordInput">
                        <b-form-input
                            v-if="showPassword3"
                            type="text"
                            class="form-control pass-input"
                            v-model="password3"
                            placeholder=".............."
                        />
                        <b-form-input v-else type="password"
                            class="form-control pass-input"
                            placeholder=".............." v-model="password3" />
                        <span
                            class="toggle-password"
                            @click="toggleShow2"
                            :class="{
                            'feather-eye': showPassword3,
                            'feather-eye-off': !showPassword3,
                            }"
                        ></span>
                    </div>
                    <div  class="password-strength" id="passwordStrength">
                        <span id="poor"></span>
                        <span id="weak"></span>
                        <span id="strong"></span>
                        <span id="heavy"></span>
                    </div>
                    <div id="passwordInfo"></div>	
                </div>
                <div class="form-group">
                    <label>New Password</label>
                    <div class="pass-group">
                        <b-form-input
                            v-if="showPassword2"
                            type="text"
                            class="form-control pass-input"
                            v-model="password2"
                            placeholder=".............."
                        />
                        <b-form-input v-else type="password"
                            class="form-control pass-input"
                            placeholder=".............." v-model="password2" />
                        <span
                            class="toggle-password"
                            @click="toggleShow1"
                            :class="{
                            'feather-eye': showPassword2,
                            'feather-eye-off': !showPassword2,
                            }"
                        ></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <a href="javascript:void(0);" class="btn btn-primary">Update Password</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'adminchange-password',

    data() {
        return {
            showPassword: false,
		 	password: null,
            showPassword2: false,
		 	password2: null,
            showPassword3: false,
		 	password3: null,
        }
    },
    computed: {
		buttonLabel() {
			return this.showPassword ? "Hide" : "Show";
		},
        buttonLabel1() {
			return this.showPassword2 ? "Hide" : "Show";
		},
        buttonLabel2() {
			return this.showPassword3 ? "Hide" : "Show";
		},
	},
	methods: {
		toggleShow() {
		 	this.showPassword = !this.showPassword;
		},
        toggleShow1() {
		 	this.showPassword2 = !this.showPassword2;
		},
        toggleShow2() {
		 	this.showPassword3 = !this.showPassword3;
		},
	},
    mounted() {

        if($('#passwordInput').length > 0) {
	let passwordInput = document.querySelector('#passwordInput input[type="password"]');
    let passwordStrength= document.getElementById('passwordStrength');
    let poor = document.querySelector('#passwordStrength #poor');
    let weak = document.querySelector('#passwordStrength #weak');
    let strong = document.querySelector('#passwordStrength #strong');
    let heavy = document.querySelector('#passwordStrength #heavy');
    let passwordInfo = document.getElementById('passwordInfo');
  
    let poorRegExp = /[a-z]/;
    let weakRegExp = /(?=.*?[0-9])/;;
    let strongRegExp = /(?=.*?[#?!@$%^&*-])/;
  
	let whitespaceRegExp = /^$|\s+/;
 
	

    passwordInput.oninput= function(){
   
        let passwordValue= passwordInput.value;
        let passwordLength= passwordValue.length;
        let poorPassword= passwordValue.match(poorRegExp);
        let weakPassword= passwordValue.match(weakRegExp);
        let strongPassword= passwordValue.match(strongRegExp);
        let whitespace= passwordValue.match(whitespaceRegExp);
		if(passwordValue != ""){
			passwordStrength.style.display = "block";
			passwordStrength.style.display = "flex";
			passwordInfo.style.display = "block";
			passwordInfo.style.color = "black";
			if(whitespace)
			{
				passwordInfo.textContent = "whitespaces are not allowed";
			}
			else {
				poorPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword);
				weakPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword);
				strongPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword);
				heavyPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword);
			}
			
		}
		else {
			   passwordInfo.style.display = "none";
			   passwordStrength.classList.remove("poor-active");
			   passwordStrength.classList.remove("avg-active");
			   passwordStrength.classList.remove("strong-active");
			   passwordStrength.classList.remove("heavy-active");
			
		   }
		}
		
		function poorPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword){
			if(passwordLength < 8)
			{
			   poor.classList.add("active");
			   passwordStrength.classList.add("poor-active");
			   passwordStrength.classList.remove("avg-active");
			   passwordStrength.classList.remove("strong-active");
			   passwordStrength.classList.remove("heavy-active");
			   passwordInfo.style.display = "block";
			   passwordInfo.style.color = "#FF0000";
			   passwordInfo.innerHTML  = "Weak. Must contain at least 8 characters";
				  
			}
		}

		function weakPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword){
			if(passwordLength >= 8 && (poorPassword || weakPassword || strongPassword))
			{
			   weak.classList.add("active");
			   passwordStrength.classList.remove("poor-active");
			   passwordStrength.classList.add("avg-active");
			   passwordStrength.classList.remove("strong-active");
			   passwordStrength.classList.remove("heavy-active");
			   passwordInfo.style.display = "block";
			   passwordInfo.style.color = "#FFB54A";
			   passwordInfo.innerHTML = "Average. Must contain at least 1 letter or number";
				  
			}else{
			 weak.classList.remove("active");
			 
		   }
		}

		function strongPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword){
		   if(passwordLength>= 8 && poorPassword && (weakPassword || strongPassword))
			{
			 strong.classList.add("active");
			 passwordStrength.classList.remove("avg-active");
			 passwordStrength.classList.remove("poor-active");
			 passwordStrength.classList.add("strong-active");
			 passwordStrength.classList.remove("heavy-active");
			 passwordInfo.innerHTML = "Almost. Must contain special symbol";
			 passwordInfo.style.color = "#1D9CFD";
		   
		   }else{
			 strong.classList.remove("active");
			 
		   }
		}
		
		function heavyPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword){
		  if(passwordLength >= 8 && (poorPassword && weakPassword) && strongPassword)
			{
			 heavy.classList.add("active");
			 passwordStrength.classList.remove("poor-active");
			 passwordStrength.classList.remove("avg-active");
			 passwordStrength.classList.remove("strong-active");
			 passwordStrength.classList.add("heavy-active");
			 passwordInfo.innerHTML = "Awesome! You have a secure password.";
			 passwordInfo.style.color = "#159F46";
		   }else{
			 heavy.classList.remove("active");
			 
		   }
		}
	}	
    },
}
</script>