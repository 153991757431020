<template>
  <!-- Footer -->
  <footer class="footer footer-eight">
    <!-- Footer Top -->
    <div class="footer-top aos" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget">
              <div class="footer-logo">
                <router-link to="/"
                  ><img src="@/assets/img/logo-02.svg" alt="logo"
                /></router-link>
              </div>
              <div class="footer-contact-info">
                <p>
                  <span><i class="feather-phone"></i></span> 321 546 8764
                </p>
                <p>
                  <span><i class="feather-mail"></i></span> truelysell@example.com
                </p>
                <div class="footer-address">
                  <p>
                    <span><i class="feather-map-pin"></i></span> 367 Hillcrest Lane,
                    Irvine, California, United States
                  </p>
                </div>
              </div>
            </div>
            <div class="social-icon">
              <ul>
                <li>
                  <a href="javascript:void(0);" target="_blank"
                    ><i class="fa-brands fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" target="_blank"
                    ><i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" target="_blank"
                    ><i class="fa-brands fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript:void(0);" target="_blank"
                    ><i class="fa-brands fa-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-2 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu footer-menu-eight">
              <h2 class="footer-title">Support</h2>
              <ul>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="about-us">Contact us</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="blog-grid">Online Chat</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="contact-us">Whatsapp</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="provider-signup">Telegram</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="user-signup">Ticketing</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="user-signup">Call Center</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-2 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu footer-menu-eight">
              <h2 class="footer-title">About Us</h2>
              <ul>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="about-us">About us</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="blog-grid">Blog</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="contact-us">Careers</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="provider-signup">Jobs</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="user-signup">In Press</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="user-signup">Gallery</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-2 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu footer-menu-eight">
              <h2 class="footer-title">Top Cities</h2>
              <ul>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="about-us">Contact us</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="blog-grid">Online Chat</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="contact-us">Whatsapp</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="provider-signup">Telegram</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="user-signup">Ticketing</router-link>
                </li>
                <li>
                  <img src="@/assets/img/icons/mini-pink-feet.svg" alt="" />
                  <router-link to="user-signup">Call Center</router-link>
                </li>
              </ul>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu-eight">
              <h2 class="footer-title">Application</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore.
              </p>
              <div class="app-eight-bottom">
                <a href="javascript:;">
                  <img src="@/assets/img/icons/qr-code-eight.svg" alt="" />
                </a>
                <div class="new-app-eight-content">
                  <a href="javascript:;" class="app-eight-content-inner">
                    <img src="@/assets/img/icons/appstore-eight.svg" alt="" />
                  </a>
                  <a href="javascript:;">
                    <img src="@/assets/img/icons/googleplay-eight.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom footer-bottom-eight">
      <div class="container">
        <div class="copyright">
          <div class="row text-center">
            <div class="col-md-12">
              <div class="copyright-text">
                <p>Copyright 2023 © Truelysell. All right reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
