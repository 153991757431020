import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getFines(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/fines', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addFines(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/fines/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateFines(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('fines/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteFines(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('fines/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }



    },
    getters: {

    }

}