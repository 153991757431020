<template>
    <!-- Footer -->
    <footer class="footer footer-four">

        <!-- Footer Top -->
        <div class="footer-top footer-top-four aos" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-widget-four">
                            <div class="footer-logo">
                                <router-link to="/"><img src="@/assets/img/logo.svg" alt="logo"></router-link>
                            </div>
                            <div class="footer-content footer-content-four">
                                <p>Our Experts ae available 24/7</p>
                            </div>
                            <div class="footer-selects">
                                <div class="footer-expert">
                                    <p><i class="feather-smartphone"></i>1800 - 672-4399</p>
                                    <p><i class="feather-mail"></i>mail@truelysell.com</p>
                                </div>
                            </div>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title footer-title-four">Pages</h2>
                            <ul>
                                <li>
                                    <router-link to="/index-4">Home</router-link>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">For business</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Dry wash</a>
                                </li>
                                <li>
                                    <router-link to="/contact-us">Contact</router-link>
                                </li>
                                <li>
                                    <router-link to="/user-signup">Our Blog</router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title footer-title-four">About us</h2>
                            <ul>
                                <li>
                                    <router-link to="/about-us">About</router-link>
                                </li>
                                <li>
                                    <router-link to="/service-grid">Services</router-link>
                                </li>
                                <li>
                                    <router-link to="/how-it-works">How we work</router-link>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">News</a>
                                </li>
                                <li>
                                    <router-link to="/maintenance">Help Guide</router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title footer-title-four">Resources</h2>
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">Download</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Help Center</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Guides</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Partner Network</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Guide</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget">
                            <h2 class="footer-subtitle footer-subtitle-four">Newsletter Signup</h2>
                            <div class="subscribe-form">
                                <input type="email" class="form-control" placeholder="Enter Email Address">
                                <button type="submit" class="btn footer-btn">
                                    <i class="feather-send"></i>
                                </button>
                            </div>
                            <h2 class="footer-title footer-title-four">Follow Us</h2>
                            <div class="social-icon social-icon-four">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);" target="_blank"><i class="fa-brands fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" target="_blank"><i class="fab fa-twitter"></i> </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" target="_blank"><i
                                                class="fa-brands fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);" target="_blank"><i
                                                class="fa-brands fa-linkedin"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top -->

        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <!-- Copyright -->
                <div class="copyright copyright-four">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="copyright-text copyright-text-four">
                                <p class="mb-0">© 2023 Truelysell. All Rights Reserved.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <!-- Copyright Menu -->
                            <div class="copyright-menu">
                                <ul class="policy-menu policy-menu-four">
                                    <li>
                                        <router-link to="/index-4">Home</router-link>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0);">Site Map</a>
                                    </li>
                                    <li>
                                        <router-link to="/privacy-policy">Privacy Policy</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/terms-condition">Cookie Policy</router-link>
                                    </li>
                                </ul>
                            </div>
                            <!-- /Copyright Menu -->
                        </div>
                    </div>
                </div>
                <!-- /Copyright -->
            </div>
        </div>
        <!-- /Footer Bottom -->

    </footer>
    <!-- /Footer -->
</template>