<template>
  <!-- Edit Email Template -->
  <div class="modal fade" id="edit-email-template">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Email Template</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Email Title</label>
              <input
                type="text"
                class="form-control"
                value="Registration Confirmation"
                disabled
              />
            </div>
            <div class="mb-4">
              <label class="form-label"> Template Content</label>
              <summernoteEditor
                v-model="myValue"
                @update:modelValue="summernoteChange($event)"
                @summernoteImageLinkInsert="summernoteImageLinkInsert"
              />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Email Template -->

  <!-- Delete Email Template -->
  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Template ?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to delete this?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Email Template -->
</template>
<script>
import SummernoteEditor from "vue3-summernote-editor";
export default {
  components: {
    SummernoteEditor,
  },
  data() {
    return {
      myValue:
        "Hi {user_name}, Welcome to Truelysell.com, Thanks for signing up. Click the button below to to view site: View Cheers, {sitetitle} Team",
    };
  },
  methods: {
    summernoteChange(newValue) {
      // console.log("summernoteChange", newValue);
    },
    summernoteImageLinkInsert(...args) {
      // console.log("summernoteImageLinkInsert()", args);
    },
  },
};
</script>
