<template>
  <!-- Hero Section -->
  <section class="hero-section-eight">
    <div class="container">
      <div class="home-banner-eight">
        <div class="row align-items-center">
          <div class="col-lg-12 col-md-10 mx-auto">
            <div class="section-search aos" data-aos="fade-up">
              <h1>
                <img src="@/assets/img/icons/dog.svg" alt="" /> This is the only place for
                all your <span>pet care</span>
              </h1>
              <div class="home-eight-bg">
                <img src="@/assets/img/her-section-eight-bg.png" alt="" />
              </div>
              <p>
                The Best Selection For your pet’s needs. A life saving impact from pets in
                communities across the country.
              </p>
              <div class="home-banner-eight-icon">
                <ul>
                  <li>
                    <i class="fa fa-check-circle"></i>
                    <span>Dog Training</span>
                  </li>
                  <li>
                    <i class="fa fa-check-circle"></i>
                    <span>Pet Sitting</span>
                  </li>
                  <li>
                    <i class="fa fa-check-circle"></i>
                    <span>Vet on Call</span>
                  </li>
                  <li>
                    <i class="fa fa-check-circle"></i>
                    <span>Vaccination</span>
                  </li>
                </ul>
              </div>
              <div class="search-box-two search-box-eight">
                <form @submit.prevent="submitForm">
                  <div class="search-input-new line-six">
                    <div class="form-group mb-0">
                      <vue-select :options="Category" placeholder="Service Category" />
                    </div>
                  </div>
                  <div class="search-input-new line-two-six">
                    <div class="form-group mb-0">
                      <i class="feather-map-pin"></i>
                      <input type="text" class="form-control" placeholder="Location" />
                    </div>
                  </div>
                  <div class="search-btn">
                    <button class="btn search_service" type="submit">Search</button>
                  </div>
                </form>
              </div>
              <div class="home-eight-dog">
                <img
                  src="@/assets/img/hero-section-eight.png"
                  alt="image"
                  class="img-fluid"
                />
              </div>
              <div class="home-eight-bg-two">
                <img src="@/assets/img/her-section-eight-bg.png" alt="" />
                <div class="home-eight-dog-feet">
                  <img src="@/assets/img/icons/eight-dog-feet.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Hero Section -->

  <!-- pricing Section -->
  <section class="category-sections-eight">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading section-heading-eight aos" data-aos="fade-up">
            <img src="@/assets/img/icons/dog.svg" alt="" />
            <h2>Pricing Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel category-eight-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexeightplaning" :key="item.id">
                <div class="category-eight-main">
                  <div class="category-eight-img">
                    <img
                      :src="require(`@/assets/img/gallery/${item.image}`)"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div class="category-eight-img-inside">
                    <router-link to="/search">
                      <img src="@/assets/img/icons/dog-feet.svg" alt="" />
                      Read more
                    </router-link>
                  </div>
                  <h6>{{ item.title }}</h6>
                  <span>{{ item.product }}</span>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
      <div class="btn-sec btn-saloons btn-pets aos" data-aos="fade-up">
        <router-link to="/search" class="btn btn-primary btn-view"
          >VIEW ALL CATEGORIES</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexeightplaning from "@/assets/json/indexeightplaning.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      Category: ["Service Category", "Tornoto", "Texas"],
      indexeightplaning: indexeightplaning,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 6,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
  },
};
</script>

<style>
.category-eight-main {
  margin-right: 24px;
}
</style>
