<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Contact Messages</h5>
          <div class="list-btn">
            <ul>
              <li>
                <router-link class="btn-filters active" to="/admin/contact-messages"
                  ><i class="feather feather-list"></i>
                </router-link>
              </li>
              <li>
                <router-link class="btn-filters" to="/admin/localization"
                  ><i class="feather feather-settings"></i>
                </router-link>
              </li>
              <li>
                <div class="filter-sorting">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="filter-sets"
                        ><img
                          src="@/assets/admin_img/icons/filter1.svg"
                          class="me-2"
                          alt="img"
                        />Filter</a
                      >
                    </li>
                    <li>
                      <span
                        ><img
                          src="@/assets/admin_img/icons/sort.svg"
                          class="me-2"
                          alt="img"
                      /></span>
                      <div class="review-sort">
                        <vue-select :options="Select" placeholder="A - Z" />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <div class="table datatable">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <a href="javascript:void(0);" class="table-profileimage">
                        <img
                          :src="require(`@/assets/admin_img/customer/${record.image}`)"
                          class="me-2"
                          alt="img"
                        />
                        <span>{{ record.Name }}</span>
                      </a>
                    </template>

                    <template v-else-if="column.key === 'Action'">
                      <div class="table-actions d-flex">
                        <router-link
                          class="delete-table me-2"
                          to="/admin/contact-messages-view"
                        >
                          <img src="@/assets/admin_img/icons/eye.svg" alt="svg" />
                        </router-link>
                        <a
                          class="delete-table"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#delete-item"
                        >
                          <img src="@/assets/admin_img/icons/delete.svg" alt="svg" />
                        </a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete -->
  <div
    class="modal fade"
    id="delete-item"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Delete Contact Messages</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <b-form @submit.prevent="submitForm">
          <div class="modal-body py-0">
            <div class="del-modal">
              <p>Are you sure want to Delete?</p>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <b-button type="button" variant="secondary" data-bs-dismiss="modal"
              >Cancel</b-button
            >
            <b-button type="submit" variant="primary" data-bs-dismiss="modal"
              >Yes</b-button
            >
          </div>
        </b-form>
      </div>
    </div>
  </div>
  <!-- /Delete -->
</template>

<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "Email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone",
    dataIndex: "Phone",
    sorter: {
      compare: (a, b) => {
        a = a.Phone.toLowerCase();
        b = b.Phone.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Message",
    dataIndex: "Message",
    sorter: {
      compare: (a, b) => {
        a = a.Message.toLowerCase();
        b = b.Message.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "Action",
    sorter: true,
    class: "text-end",
  },
];

const data = [
  {
    id: "1",
    image: "user-01.jpg",
    Name: "John Smith",
    Email: "john@example.com",
    Phone: "+1 347-679-8275",
    Message: "Need this script",
    Date: "28 Sep 2023",
    Action: "",
  },
  {
    id: "2",
    image: "user-04.jpg",
    Name: "Johnny",
    Email: "johnny@example.com",
    Phone: "+1 347-679-8275",
    Message: "Need this script",
    Date: "285 Sep 2023",
    Action: "",
  },
  {
    id: "3",
    image: "user-02.jpg",
    Name: "Robert",
    Email: "robert@example.com",
    Phone: "+1 347-679-8275",
    Message: "Need this script",
    Date: "23 Sep 2023",
    Action: "",
  },
  {
    id: "4",
    image: "user-09.jpg",
    Name: "Sharonda",
    Email: "sharonda@example.com",
    Phone: "+1 347-679-8275",
    Message: "Need this script",
    Date: "03 Sep 2023",
    Action: "",
  },
];
export default {
  data() {
    return {
      Select: ["A - Z", "Z - A"],
      columns,
      data,
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/admin/contact-messages");
    },
  },
};
</script>
