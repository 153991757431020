<template>
    <!-- Footer -->
    <footer class="footer footer-three">
        <!-- Footer Top -->
        <div class="footer-top footer-top-three aos" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <router-link to="/"><img src="@/assets/img/logo-02.svg" alt="logo" /></router-link>
                            </div>
                            <div class="footer-content footer-content-three">
                                <p>
                                    Contrary to popular belief, Lorem Ipsum is not simply
                                    random text. It has roots in a piece of classical Latin
                                    literature from 45 BC, making it over 2000 years old.
                                    Richard...
                                </p>
                            </div>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu footer-menu-three">
                            <h2 class="footer-title footer-title-three">Discover</h2>
                            <ul>
                                <li>
                                    <a href="javascript:;">Chicago</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Los Angeles</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Miami</a>
                                </li>
                                <li>
                                    <a href="javascript:;">New York</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu footer-menu-three">
                            <h2 class="footer-title footer-title-three">Lists by Category</h2>
                            <ul>
                                <li>
                                    <a href="javascript:;">Hair style </a>
                                </li>
                                <li>
                                    <a href="javascript:;">Trimming</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Hair makeup</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Clothes selection</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Beared Trimming</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Box Haircuts</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu footer-menu-three">
                            <h2 class="footer-title footer-title-three">Quick Links</h2>
                            <ul>
                                <li>
                                    <a href="javascript:;">About Us </a>
                                </li>
                                <li>
                                    <a href="javascript:;">Terms & Conditions</a>
                                </li>
                                <li>
                                    <a href="javascript:;">User’s Guide</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Support Center</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Press Info</a>
                                </li>
                                <li>
                                    <a href="javascript:;">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu footer-menu-three">
                            <h2 class="footer-title footer-subtitle-three">
                                Our Total Visitors
                            </h2>
                            <p>4,56,6597</p>
                        </div>
                        <div class="footer-widget footer-menu footer-menu-three">
                            <h2 class="footer-title footer-subtitle-three">
                                Need live support?
                            </h2>
                            <p>info@truelysell.com</p>
                        </div>
                        <div class="footer-widget footer-menu footer-menu-three">
                            <h2 class="footer-title footer-subtitle-three">
                                Toll Free Customer Care
                            </h2>
                            <p>+91 26447 99875</p>
                        </div>
                        <!-- /Footer Widget -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top -->

        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <!-- Copyright -->
                <div class="copyright">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="copyright-text copyright-color">
                                <p class="mb-0">
                                    &copy; 2023 truelysell. All Rights Reserved.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <!-- Copyright Menu -->
                            <div class="copyright-menu">
                                <ul class="footer-menu">
                                    <li>
                                        <router-link to="/index-3">Home</router-link>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">Side Map</a>
                                    </li>
                                    <li>
                                        <router-link to="/privacy-policy">Privacy policy</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/privacy-policy">Cookie Policy</router-link>
                                    </li>
                                </ul>
                            </div>
                            <!-- /Copyright Menu -->
                        </div>
                    </div>
                </div>
                <!-- /Copyright -->
            </div>
        </div>
        <!-- /Footer Bottom -->
    </footer>
    <!-- /Footer -->
</template>