import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
      getRecapDebitCredit(_,{startDate, endDate}) {
        const url = `/recap-debit-credit?from=${startDate}&to=${endDate}`
        return new Promise((resolve, reject) => {
                axios.get(url).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
        });

      }
    },
    getters: {

    }

}
