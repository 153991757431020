<template>
    <div class="main-wrapper">
      <mari-layout></mari-layout>
      <div class="page-wrapper">
        <div class="content">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-md-6 aos" data-aos="fade-up">
                  <h4>List Riwayat Order Driver</h4>
                </div>
              </div>
            </div>
            
            <div class="row mt-4">
              <div class="input-group" style="justify-content: end;">
                <input type="text" v-model="params.keyword" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" style="max-width: 25% !important;" @keyup.enter="search">
                <button class="btn btn-primary" @click="search" type="button" id="button-addon2">Search</button>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <div class="table-resposnive table-div">
                  <div class="table datatable">
                    <a-table :columns="columns" :data-source="data" :pagination="pagination">
                      <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'id'">
                          <div class="table-actions d-flex">
                              <button
                              class="btn delete-table me-2"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#show-driver-grafik"
                              @click="openEditModal(record)"
                            >
                              <i class="feather feather-eye"></i>
                            </button>
                          </div>
                        </template>
                      </template>
                    </a-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <report-driver-model :editData="editData" :deleteItemId="itemIdToDelete" :roleData="dataRole" @dataUpdated="getData" :dataGrafik="grafikData"></report-driver-model>
  </template>
  
  <script>
  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      sorter: true
    },
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      sorter: {
        compare: (a, b) => {
          a = a.full_name.toLowerCase();
          b = b.full_name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "statusDriver",
      key: "statusDriver",
      sorter: {
        compare: (a, b) => {
          a = a.statusDriver.toLowerCase();
          b = b.statusDriver.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
      sorter: {
        compare: (a, b) => {
          a = a.roleName.toLowerCase();
          b = b.roleName.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "id",
      sorter: true,
      class: "text-end",
    },
  ];
  export default {
    data() {
      return {
        columns,
        data: [],
        Select: ["A -> Z", "Z -> A"],
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        editData: {},
        itemIdToDelete: {},
        dataRole: [],
        pagination: {
          current: 1, // Initial current page number
          pageSize: 10, // Number of items per page
          total: 0, // Total number of items
          showSizeChanger: true, // Show size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for items per page
          showTotal: (total) => `Total ${total} items`, // Function to display total items
          onChange: this.handlePageChange, // Function to handle page change
          onShowSizeChange: this.handlePageSizeChange, // Function to handle page size change
        },
        paramsDriver: {
            row: 100,
            keyword: "",
            sortby: "id",
            sorttype: "desc",
            page: 1,
            dateStart: '',
            dateEnd: '',
            jadwal: false,
            driverId: null
        },
        grafikData: null,
      };
    },
    name: "usersetting",
    computed: {

    },
    methods: {
        search() {
          this.params.page = 1;
          this.getData();
        },
        handlePageChange(page) {
          this.pagination.current = page;
          this.params.page = page;
          this.getData();
        },
        handlePageSizeChange(current, size) {
          this.pagination.pageSize = size;
          this.pagination.current = 1;
          this.params.row = size;
          this.params.page = 1;
          this.getData();
        },
        async getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("user/getDriverStaff", this.params)
                .then((resp) => {
                  const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
                    this.data = resp.data.data.map((item, index) => {
                        const roleName = item.role ? item.role.name.toLowerCase() : '-';
                        const statusDriver = item.status_mudi ? item.status_mudi.toLowerCase() : '-';
                        return {
                            ...item,
                            index: startIndex + index + 1,
                            roleName,
                            statusDriver
                        };
                    });
                    this.pagination.current = resp.data.current_page;
                    this.pagination.total = resp.data.total;
                    this.pagination.pageSize = this.params.row;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        async openEditModal(data) {
          // Set the edit data before opening the modal
          const selectedData = await this.getGrafik(data.id);
          console.log(selectedData);
          this.grafikData = { selectedData };
        },
        openDeleteModal(data) {
          // Set the edit data before opening the modal
          this.itemIdToDelete = { data };
        },
        async getRole() {
          var loading = this.$loading.show();
          this.$store
              .dispatch("role/getRole", this.params)
              .then((resp) => {
                  this.dataRole = resp.data.data;
                  loading.hide();
              })
              .catch((e) => {
                  this.$toast.error(e);
                  loading.hide();
              });
        },
        async getGrafik(id) {
        const loading = this.$loading.show();
        try {
          this.paramsDriver.driverId = id;
          const resp = await this.$store.dispatch("order/getOrderDriverGrafik", this.paramsDriver);
          return resp;
        } catch (e) {
          this.$toast.error(e);
          console.error("Error fetching order data:", e);
        } finally {
          loading.hide();
        }
      },
    },
    async mounted() {
        await this.getRole();
        await this.getData();
    },
  };
  </script>
  <style>
  .ant-select-selection-item {
    height: 30px;
    width: 90px;
    margin-top: -6px;
    font-size: 15px;
  }
  </style>