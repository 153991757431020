<template>
  <!-- pricing Section -->
  <section class="service-section pricing-sections pt-0">
    <div class="container">
      <div class="section-heading">
        <div class="row">
          <div class="col-md-12 text-center aos" data-aos="fade-up">
            <h2>Pricing Plans</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
          </div>
        </div>
      </div>
      <div class="row aos" data-aos="fade-up">
        <div class="col-lg-4 col-sm-12">
          <div class="pricing-popular">
            <span class="btn w-100">Popular</span>
          </div>
          <div class="pricing-plans">
            <div class="pricing-planshead">
              <h4>Basic</h4>
              <h5>Lorem ipsum dolor sit amet, consectetur</h5>
              <h6>$50<span>/month</span></h6>
            </div>
            <div class="pricing-planscontent">
              <ul>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Sed perspiciatis unde omnis natus error</span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Lorem dolor consecteturadipiscing elit</span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Duis irure dolor reprehenderit voluptate</span>
                </li>
                <li class="inactive">
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Nemo enim ipsam voluptatem quia </span>
                </li>
                <li class="inactive">
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Sed perspiciatis iste natus error </span>
                </li>
                <li class="inactive">
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Lorem dolor consecteturadipiscing elit </span>
                </li>
              </ul>
              <div class="pricing-btn">
                <router-link to="/search" class="btn btn-primary btn-view"
                  >Get Started<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="pricing-popular active">
            <span class="btn w-100">Popular</span>
          </div>
          <div class="pricing-plans active">
            <div class="pricing-planshead">
              <h4>Standard</h4>
              <h5>Lorem ipsum dolor sit amet, consectetur</h5>
              <h6>$100<span>/month</span></h6>
            </div>
            <div class="pricing-planscontent">
              <ul>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Sed perspiciatis unde omnis natus error</span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Lorem dolor consecteturadipiscing elit</span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Duis irure dolor reprehenderit voluptate</span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Nemo enim ipsam voluptatem quia </span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Sed perspiciatis iste natus error </span>
                </li>
                <li class="inactive">
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Lorem dolor consecteturadipiscing elit </span>
                </li>
              </ul>
              <div class="pricing-btn">
                <router-link to="/search" class="btn btn-primary btn-view"
                  >Get Started<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="pricing-popular">
            <span class="btn w-100">Popular</span>
          </div>
          <div class="pricing-plans">
            <div class="pricing-planshead">
              <h4>Premium</h4>
              <h5>Lorem ipsum dolor sit amet, consectetur</h5>
              <h6>$150<span>/month</span></h6>
            </div>
            <div class="pricing-planscontent">
              <ul>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Sed perspiciatis unde omnis natus error</span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Lorem dolor consecteturadipiscing elit</span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Duis irure dolor reprehenderit voluptate</span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Nemo enim ipsam voluptatem quia </span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Sed perspiciatis iste natus error </span>
                </li>
                <li>
                  <i class="fa fa-check-circle me-2 text-primary"></i>
                  <span>Lorem dolor consecteturadipiscing elit </span>
                </li>
              </ul>
              <div class="pricing-btn">
                <router-link to="/search" class="btn btn-primary btn-view"
                  >Get Started<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /pricing Section -->

  <!-- Client Section -->
  <section class="client-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="section-heading aos" data-aos="fade-up">
            <h2>What our client says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur elit</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel testimonial-slider">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexplaning" :key="item.id">
                <div class="client-widget">
                  <div class="client-img">
                    <a href="javascript:;">
                      <img
                        class="img-fluid"
                        alt="Image"
                        :src="require(`@/assets/img/profiles/${item.image}`)"
                      />
                    </a>
                  </div>
                  <div class="client-content">
                    <div class="rating">
                      <i class="fa-solid fa-star filled me-1"></i>
                      <i class="fa-solid fa-star filled me-1"></i>
                      <i class="fa-solid fa-star filled me-1"></i>
                      <i class="fa-solid fa-star filled me-1"></i>
                      <i class="fa-solid fa-star filled me-1"></i>
                    </div>
                    <p>{{ item.content }}</p>
                    <h5>{{ item.name }}</h5>
                    <h6>{{ item.position }}</h6>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Client Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexplaning from "@/assets/json/indexplanning.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexplaning: indexplaning,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
