import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getCustomerDetail(_, id) {
          return new Promise((resolve, reject) => {
            axios.get(`/customer/show/${id}`).then((resp) => {
              resolve(resp.data)
            }).catch((err) => {
              reject(err)
            })
          })
        },
      
        getCustomer(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/customer', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getCustomerAll() {
            return new Promise((resolve, reject) => {
                axios.get('/customer/getAll').then(resp => {
                resolve(resp.data);
                }).catch(e => {
                reject(e);
                });
            });
        },

        addCustomer(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/customer/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateCustomer(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('customer/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteCustomer(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('customer/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        

        addDoc(_, data){
            return new Promise((resolve,reject) => {
                axios.post('/customer/create-doc', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        deleteDoc(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('customer/delete-doc/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        checkCustomer({ commit }, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/customer/check-name', { params: _params })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
            });
        }



    },
    getters: {

    }

}
