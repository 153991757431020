<template>
    <!-- Show Customer -->
    <div class="modal fade" id="show-customer" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Pelanggan</h5>
            <button
              id="close-show-customer"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
          <b-tabs content-class="mt-3">
            <b-tab title="Detail Pelanggan" active>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <div class="profile-upload-content">
                      <label class="form-label">Photo</label>
                      <div class="profile-upload-btn">
                        <img v-if="(formEdit !== undefined) && (formEdit.photo !== '')" :src="formEdit.photo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                        <img v-else :src="require(`@/assets/admin_img/customer/user-01.jpg`)" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nama Pelanggan</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name" disabled/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nik</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.nik" disabled/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nomer Telepon (WA)</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number" disabled/>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <div class="split-check-title">
                              <label class="form-label">Jenis Kelamin</label>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" type="radio" :checked="formEdit.gender === 'Laki - Laki'" id="inlineRadio1" name="gender" value="Laki - Laki" v-model="formEdit.gender">
                              <label class="form-check-label" for="inlineRadio1">Laki - Laki</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" type="radio" :checked="formEdit.gender === 'Perempuan'" id="inlineRadio2" name="gender" value="Perempuan" v-model="formEdit.gender">
                              <label class="form-check-label" for="inlineRadio2">Perempuan</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Status</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.status === 'AKTIF'" type="radio" id="inlineRadio1" name="status" value="true" v-model="formEdit.status">
                              <label class="form-check-label" for="inlineRadio1">AKTIF</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.status === 'TIDAK AKTIF'" type="radio" id="inlineRadio2" name="status" value="false" v-model="formEdit.status">
                              <label class="form-check-label" for="inlineRadio2">TIDAK AKTIF</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <div class="split-check-title">
                              <label class="form-label">Jenis Pelanggan</label>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input  v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.type === 'REGULER'" type="radio" id="inlineRadio1" name="type" value="REGULER" v-model="formEdit.type">
                              <label class="form-check-label" for="inlineRadio1">REGULER</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input  v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.type === 'COOPORATE'" type="radio" id="inlineRadio2" name="type" value="COOPORATE" v-model="formEdit.type">
                              <label class="form-check-label" for="inlineRadio2">COOPORATE</label>
                          </div><div class="form-check form-check-inline">
                              <input  v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.type === 'RENT TO RENT'" type="radio" id="inlineRadio3" name="type" value="RENT TO RENT" v-model="formEdit.type">
                              <label class="form-check-label" for="inlineRadio3">RENT TO RENT</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input  v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.type === 'INTERNAL'" type="radio" id="inlineRadio4" name="type" value="INTERNAL" v-model="formEdit.type">
                              <label class="form-check-label" for="inlineRadio4">INTERNAL</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Alamat</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.address" disabled/>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Pelanggan Terverifikasi</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.is_member === 1" type="radio" id="inlineRadio1" name="is_member" value="true" v-model="formEdit.is_member">
                              <label class="form-check-label" for="inlineRadio1">Ya</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.is_member === 0" type="radio" id="inlineRadio2" name="is_member" value="false" v-model="formEdit.is_member">
                              <label class="form-check-label" for="inlineRadio2">Tidak</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Pembayaran Termin</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.is_payment_termin === 1" type="radio" id="inlineRadio1" name="is_payment_termin" value="true" v-model="formEdit.is_payment_termin">
                              <label class="form-check-label" for="inlineRadio1">Ya</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.is_payment_termin === 0" type="radio" id="inlineRadio2" name="is_payment_termin" value="false" v-model="formEdit.is_payment_termin">
                              <label class="form-check-label" for="inlineRadio2">Tidak</label>
                          </div>
                      </div>
                  </div>
                  
                  <div class="mb-3">
                    <label class="form-label">Jatuh Tempo</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.due_date" disabled/>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Bisa Banyak Oder</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.multiple_order === 1" type="radio" id="inlineRadio1" name="multiple_order" value="true" v-model="formEdit.multiple_order">
                              <label class="form-check-label" for="inlineRadio1">Ya</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.multiple_order === 0" type="radio" id="inlineRadio2" name="multiple_order" value="false" v-model="formEdit.multiple_order">
                              <label class="form-check-label" for="inlineRadio2">Tidak</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Discount</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.discount" disabled/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Referensi</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.reference" disabled/>
                  </div>
                  <!-- <div class="mb-3">
                    <label class="form-label">Jaminan Identitas</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.card" disabled/>
                  </div>
                  <div class="mb-3">
                    <div class="profile-upload-content">
                      <label class="form-label">Foto Jaminan Identitas</label>
                      <div class="profile-upload-btn">
                        <img v-if="(formEdit !== undefined) && (formEdit.card_photo !== '')" :src="formEdit.card_photo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                        <img v-else :src="require(`@/assets/admin_img/customer/user-01.jpg`)" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </b-tab>
            <b-tab title="Detail Transaksi Pelanggan">
                <!-- Display calculations -->
                <div v-if="formEdit" class="row">
                  <h5>Perilaku Pelanggan</h5>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label for="totalOrders">Jumlah Transaksi</label>
                          <input type="text" id="totalOrders" class="form-control" v-model="totalOrders" readonly>
                      </div>
                      <div class="form-group">
                          <label for="sumTotalPrice">Total Seluruh Transaksi</label>
                          <input type="text" id="sumTotalPrice" class="form-control" v-model="sumTotalPrice" readonly>
                      </div>
                      <div class="form-group">
                          <label for="sumDp">Total DP Transaksi:</label>
                          <input type="text" id="sumDp" class="form-control" v-model="sumDp" readonly>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="form-group">
                          <label for="totalPriceMinusDp">Sisa Pembayaran</label>
                          <input type="text" id="totalPriceMinusDp" class="form-control" v-model="totalPriceMinusDp" readonly>
                      </div>
                      <div class="form-group">
                          <label for="lastTotalPrice">Total Rata - Rata Transaksi</label>
                          <input type="text" id="lastTotalPrice" class="form-control" v-model="lastTotalPrice" readonly>
                      </div>
                  </div>
              </div>
              <div class="row">
                <h5>Riwayat Transaksi</h5>
                  <table class="table" style="margin-bottom: 20px;">
                      <thead>
                      <tr>
                          <th>No Order</th>
                          <th>Total Sewa</th>
                          <th>Total DP</th>
                          <th>Status</th>
                      </tr>
                      </thead>
                      <tbody>
                          <tr v-if="formEdit !== undefined" v-for="(item, index) in formEdit.orders" :key="index">
                              <td class="mb-3">
                                  {{ item.no_order }}
                              </td>
                              <td class="mb-3">
                                {{ item.total_price }}
                              </td>
                              <td class="mb-3">
                                {{ item.dp }}
                              </td>
                              <td class="mb-3">
                                {{ item.status }}
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </div>
            </b-tab>
            <b-tab title="Dokumen Pelanggan">
              <div>
                <button
                  class="btn btn-primary me-2"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-vehicle-doc"
                >
                  <i class="fa fa-image me-2"></i>Tambah Gambar
                </button>
              </div>
              <div v-if="formEdit !== undefined" class="row mt-5">
                <div v-for="(item, index) in formEdit.doc" :key="index" class="col-6">
                  <div class="profile-upload-content d-flex align-items-center">
                    <div class="flex-grow-1">
                      <img v-if="item.file !== ''" :src="item.file" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                      <img v-else :src="require(`@/assets/admin_img/customer/user-01.jpg`)" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                      <span style="margin-left: 20px;">{{ item.remark }}</span>
                    </div>
                    <button @click="deleteDoc(item)" class="btn btn-danger">Hapus</button>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
              <div class="text-end mt-4">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Show Customer -->

    <!-- Add Customer -->
    <div class="modal fade custom-modal" id="add-customer" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Pelanggan test</h5>
            <button
              id="close-create"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit">
              <div class="row mt-4">
                <div class="col-12 col-md-6">
                  <!-- <div class="mb-3">
                    <div class="profile-upload-content">
                        <label class="form-label">Foto Jaminan Identitas</label>
                        
                        <div class="profile-upload-btn">
                            <img v-if="logo" :src="logo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                            <div class="profile-upload-file">
                              <input
                                  type="file"
                                  class="form-control"
                                  ref="uploadFieldAttachment"
                                  id="imgInp"
                                  @change="selectFileAttachment"
                              />
                              <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                            </div>
                        </div>
                        <div class="profile-upload-para">
                            <p>
                            *ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                        File yang diizinkan .png dan .jpg.
                            </p>
                        </div>
                    </div>
                  </div> -->
                  <div class="mb-3">
                    <label class="form-label">Nama Pelanggan</label>
                    <input type="text" class="form-control" v-model="formCreate.name"/>
                    <span v-if="nameCheckStatus === 'checking'">Checking...</span>
                    <span v-if="nameCheckStatus === 'duplicate'" class="text-danger">Nama sudah ada</span>
                    <span v-if="nameCheckStatus === 'available'" class="text-success">Nama bisa ditambahkan</span>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nik</label>
                    <input type="text" class="form-control" v-model="formCreate.nik"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nomer Telepon (WA)</label>
                    <input type="text" class="form-control" v-model="formCreate.phone_number"/>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <div class="split-check-title">
                              <label class="form-label">Jenis Kelamin</label>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio1" name="gender" value="Laki - Laki" v-model="formCreate.gender">
                              <label class="form-check-label" for="inlineRadio1">Laki - Laki</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio2" name="gender" value="Perempuan" v-model="formCreate.gender">
                              <label class="form-check-label" for="inlineRadio2">Perempuan</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Status</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio1" name="status" value="AKTIF" v-model="formCreate.status">
                              <label class="form-check-label" for="inlineRadio1">AKTIF</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio2" name="status" value="TIDAK AKTIF" v-model="formCreate.status">
                              <label class="form-check-label" for="inlineRadio2">TIDAK AKTIF</label>
                          </div>
                      </div>
                  </div>
                  </div>
                  <div class="col-12 col-md-6">
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <div class="split-check-title">
                              <label class="form-label">Jenis Pelanggan</label>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio1" name="type" value="REGULER" v-model="formCreate.type">
                              <label class="form-check-label" for="inlineRadio1">REGULER</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio2" name="type" value="COOPORATE" v-model="formCreate.type">
                              <label class="form-check-label" for="inlineRadio2">COOPORATE</label>
                          </div><div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio3" name="type" value="RENT TO RENT" v-model="formCreate.type">
                              <label class="form-check-label" for="inlineRadio3">RENT TO RENT</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio4" name="type" value="INTERNAL" v-model="formCreate.type">
                              <label class="form-check-label" for="inlineRadio4">INTERNAL</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Pelanggan Terverifikasi</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio1" name="is_member" value="true" v-model="formCreate.is_member">
                              <label class="form-check-label" for="inlineRadio1">Ya</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio2" name="is_member" value="false" v-model="formCreate.is_member">
                              <label class="form-check-label" for="inlineRadio2">Tidak</label>
                          </div>
                      </div>
                  </div>
                  <!-- <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Pembayaran Termin</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio1" name="is_payment_termin" value="true" v-model="formCreate.is_payment_termin">
                              <label class="form-check-label" for="inlineRadio1">Ya</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio2" name="is_payment_termin" value="false" v-model="formCreate.is_payment_termin">
                              <label class="form-check-label" for="inlineRadio2">Tidak</label>
                          </div>
                      </div>
                  </div> -->
                  <!-- <div class="mb-3">
                    <label class="form-label">Jatuh Tempo</label>
                    <input type="text" class="form-control" v-model="formCreate.due_date"/>
                  </div> -->
                  <!-- <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Bisa Banyak Oder</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio1" name="multiple_order" value="true" v-model="formCreate.multiple_order">
                              <label class="form-check-label" for="inlineRadio1">Ya</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" id="inlineRadio2" name="multiple_order" value="false" v-model="formCreate.multiple_order">
                              <label class="form-check-label" for="inlineRadio2">Tidak</label>
                          </div>
                      </div>
                  </div> -->
                  <!-- <div class="mb-3">
                    <label class="form-label">Discount</label>
                    <input type="number" class="form-control" v-model="formCreate.discount"/>
                  </div> -->
                  <div class="mb-3">
                    <label class="form-label">Saran Pelanggan Dari</label>
                    <input type="text" class="form-control" v-model="formCreate.reference"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Alamat</label>
                    <input type="text" class="form-control" v-model="formCreate.address"/>
                  </div>
                  <!-- <div class="mb-3">
                    <label class="form-label">Jaminan Identitas</label>
                    <input type="text" class="form-control" v-model="formCreate.card"/>
                  </div> -->
                  <!-- <div class="mb-3">
                    <div class="profile-upload-content">
                        <label class="form-label">Foto Jaminan Identitas</label>
                        
                        <div class="profile-upload-btn">
                            <img v-if="logo2" :src="logo2" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                            <div class="profile-upload-file">
                              <input
                                  type="file"
                                  class="form-control"
                                  ref="uploadFieldAttachment"
                                  id="imgInp"
                                  @change="selectFileAttachment2"
                              />
                              <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                            </div>
                        </div>
                        <div class="profile-upload-para">
                            <p>
                            *ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                        File yang diizinkan .png dan .jpg.
                            </p>
                        </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Customer -->
  
    <!-- Edit Customer -->
    <div class="modal fade custom-modal" id="edit-customer" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Pelanggan</h5>
            <button
              id="close-edit"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="editSubmit">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                    <div class="profile-upload-content">
                        <label class="form-label">Photo</label>
                        
                        <!-- upload file form logo here -->
                        <div class="profile-upload-btn">
                            <!-- company logo here -->
                            <img v-if="(formEdit !== undefined) && (formEdit.photo !== '')" :src="formEdit.photo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                            <!-- company logo here -->
                            <div class="profile-upload-file">
                              <input
                                  type="file"
                                  class="form-control"
                                  ref="uploadFieldAttachment"
                                  id="imgInp"
                                  @change="selectFileAttachment"
                              />
                              <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                            </div>
                        </div>
                        <!-- upload file form logo here -->
                        <div class="profile-upload-para">
                            <p>
                            *ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                        File yang diizinkan .png dan .jpg.
                            </p>
                        </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nama Pelanggan</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.name"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nik</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.nik"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Nomer Telepon (WA)</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.phone_number"/>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <div class="split-check-title">
                              <label class="form-label">Jenis Kelamin</label>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" type="radio" :checked="formEdit.gender === 'Laki - Laki'" id="inlineRadio1" name="gender" value="Laki - Laki" v-model="formEdit.gender">
                              <label class="form-check-label" for="inlineRadio1">Laki - Laki</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" type="radio" :checked="formEdit.gender === 'Perempuan'" id="inlineRadio2" name="gender" value="Perempuan" v-model="formEdit.gender">
                              <label class="form-check-label" for="inlineRadio2">Perempuan</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Status</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.status === 'AKTIF'" type="radio" id="inlineRadio1" name="status" value="true" v-model="formEdit.status">
                              <label class="form-check-label" for="inlineRadio1">AKTIF</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.status === 'TIDAK AKTIF'" type="radio" id="inlineRadio2" name="status" value="false" v-model="formEdit.status">
                              <label class="form-check-label" for="inlineRadio2">TIDAK AKTIF</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <div class="split-check-title">
                              <label class="form-label">Jenis Pelanggan</label>
                          </div>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input  v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.type === 'REGULER'" type="radio" id="inlineRadio1" name="type" value="REGULER" v-model="formEdit.type">
                              <label class="form-check-label" for="inlineRadio1">REGULER</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input  v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.type === 'COOPORATE'" type="radio" id="inlineRadio2" name="type" value="COOPORATE" v-model="formEdit.type">
                              <label class="form-check-label" for="inlineRadio2">COOPORATE</label>
                          </div><div class="form-check form-check-inline">
                              <input  v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.type === 'RENT TO RENT'" type="radio" id="inlineRadio3" name="type" value="RENT TO RENT" v-model="formEdit.type">
                              <label class="form-check-label" for="inlineRadio3">RENT TO RENT</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input  v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.type === 'INTERNAL'" type="radio" id="inlineRadio4" name="type" value="INTERNAL" v-model="formEdit.type">
                              <label class="form-check-label" for="inlineRadio4">INTERNAL</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Alamat</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.address"/>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Pelanggan Terverifikasi</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.is_member === 1" type="radio" id="inlineRadio1" name="is_member" value="true" v-model="formEdit.is_member">
                              <label class="form-check-label" for="inlineRadio1">Ya</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.is_member === 0" type="radio" id="inlineRadio2" name="is_member" value="false" v-model="formEdit.is_member">
                              <label class="form-check-label" for="inlineRadio2">Tidak</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Pembayaran Termin</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.is_payment_termin === 1" type="radio" id="inlineRadio1" name="is_payment_termin" value="true" v-model="formEdit.is_payment_termin">
                              <label class="form-check-label" for="inlineRadio1">Ya</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.is_payment_termin === 0" type="radio" id="inlineRadio2" name="is_payment_termin" value="false" v-model="formEdit.is_payment_termin">
                              <label class="form-check-label" for="inlineRadio2">Tidak</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Jatuh Tempo</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.due_date"/>
                  </div>
                  <div class="mb-3">
                      <div class="col-lg-12">
                          <label class="form-label">Bisa Banyak Oder</label>
                      </div>
                      <div class="col-lg-12">
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.multiple_order === 1" type="radio" id="inlineRadio1" name="multiple_order" value="true" v-model="formEdit.multiple_order">
                              <label class="form-check-label" for="inlineRadio1">Ya</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input v-if="formEdit !== undefined" class="form-check-input" :checked="formEdit.multiple_order === 0" type="radio" id="inlineRadio2" name="multiple_order" value="false" v-model="formEdit.multiple_order">
                              <label class="form-check-label" for="inlineRadio2">Tidak</label>
                          </div>
                      </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Discount</label>
                    <input v-if="formEdit !== undefined" type="number" class="form-control" v-model="formEdit.discount"/>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Referensi</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.reference"/>
                  </div>
                  <!-- <div class="mb-3">
                    <label class="form-label">Jaminan Identitas</label>
                    <input v-if="formEdit !== undefined" type="text" class="form-control" v-model="formEdit.card"/>
                  </div>
                  <div class="mb-3">
                    <div class="profile-upload-content">
                        <label class="form-label">Foto Jaminan Identitas</label>
                        
                        <div class="profile-upload-btn">
                            <img v-if="(formEdit !== undefined) && (formEdit.card_photo !== '')" :src="formEdit.card_photo" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                            <div class="profile-upload-file">
                              <input
                                  type="file"
                                  class="form-control"
                                  ref="uploadFieldAttachment"
                                  id="imgInp"
                                  @change="selectFileAttachment2"
                              />
                              <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                            </div>
                        </div>
                        <div class="profile-upload-para">
                            <p>
                            *ukuran gambar minimal harus berukuran 320px, dan kurang dari 500kb.
                        File yang diizinkan .png dan .jpg.
                            </p>
                        </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="text-end" style="padding-bottom: 10px;">
                <button
                  type="button"
                  class="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary">Save Changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Customer -->
  
    <div class="modal fade" id="delete" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete Customer ?</h5>
  
            <button
              id="close-delete"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
  
          <div class="modal-body pt-0">
            <p class="text-muted mb-0">Are you sure want to delete this?</p>
  
            <div class="d-flex gap-2 justify-content-end mt-4">
              <button type="button" class="btn btn-primary" @click="deleteData">Delete</button>
  
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Doc Vehicle -->
    <div class="modal fade custom-modal" id="add-vehicle-doc" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tambah Dokumen Gambar</h5>
            <button
              id="close-customer-doc"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <!-- <i class="feather feather-x"></i> -->
            </button>
          </div>
          <div class="modal-body pt-0 mt-4">
            <form @submit.prevent="addDoc">
                <div class="mb-3 form-group">
                  <div class="profile-upload-content">
                    <!-- company logo here -->
                    <img v-if="logo" :src="logo3" alt="logo" class="img-thumbnail mb-3" width="100" height="100">
                    <!-- company logo here -->

                    <!-- upload file form logo here -->
                    <div class="profile-upload-btn">
                        <div class="profile-upload-file">
                          <input
                              type="file"
                              class="form-control"
                              ref="uploadFieldAttachment"
                              id="imgInp"
                              @change="selectFileAttachment3"
                          />
                          <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                        </div>
                    </div>
                    <!-- upload file form logo here -->
                    <div class="profile-upload-para">
                        <p>
                        *image size should be at least 320px big,and less then 500kb.
                        Allowed files .png and .jpg.
                        </p>
                    </div>
                  </div>
                </div>
                <div class="mb-3 form-group">
                  <label class="form-label">Catatan</label>
                  <input type="text" class="form-control"  v-model="formDoc.remark"/>
                </div>
                <div class="text-end" style="padding-bottom: 10px;">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Doc Vehicle -->

  </template>
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  export default {
    data() {
      return {
        formCreate: {},
        formEdit : {},
        logo: '',
        logo2: '',
        logo3: '',
        formDoc: {},
        nameCheckStatus: ''
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object
    },
    emits: ['edit-customer'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
      'formCreate.name': function(newName) {
        if (newName) {
          this.checkName(newName);
        } else {
          this.nameCheckStatus = '';
        }
      }
    },
    computed: {
      totalOrders() {
        return this.formEdit.orders.length;
      },
      sumTotalPrice() {
        return this.formEdit.orders.reduce((sum, item) => sum + parseFloat(item.total_price), 0);
      },
      sumDp() {
        return this.formEdit.orders.reduce((sum, item) => sum + parseFloat(item.dp), 0);
      },
      totalPriceMinusDp() {
        return this.sumTotalPrice - this.sumDp;
      },
      lastTotalPrice() {
        // Pick the last total price
        if (this.formEdit.orders.length > 0) {
          const sumTotalPrice = this.formEdit.orders.reduce((sum, item) => sum + parseFloat(item.total_price), 0);
          // Calculate the average by dividing the sum by the number of orders
          return sumTotalPrice / this.formEdit.orders.length;
        }
        return 0;
      }
    },
    methods: {
      validateFile(file, allowedFileTypes, maxFileSize) {
        if (!allowedFileTypes.includes(file.type)) {
            this.$swal.fire({
                icon: 'warning',
                text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
            });
            return false;
        }

        if (file.size > maxFileSize) {
            this.$swal.fire({
                icon: 'warning',
                text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
            });
            return false;
        }

        return true;
      },
        // method upload file / foto
      selectFileAttachment(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      selectFileAttachment2(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo2 = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      selectFileAttachment3(event) {
        this.file = event.target.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024; // 2 MB

        if (!this.validateFile(this.file, allowedFileTypes, maxFileSize)) {
            return;
        }
        var loading = this.$loading.show();
        uploadFile(this.file)
            .then((resp) => {
                this.logo3 = resp;
                loading.hide();
                this.$swal.fire({
                    icon: 'success',
                    text: 'File berhasil diupload !!',
                });
            })
            .catch((e) => {
                loading.hide();
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Terjadi kesalahan, ' + e,
                })
            });
      },
      editSubmit() {
        this.formEdit.photo = this.formEdit.photo !== '' ? this.formEdit.photo : this.logo;
        this.formEdit.card_photo = this.formEdit.card_photo !== '' ? this.formEdit.card_photo : this.logo2;
        this.formEdit.is_member = this.formEdit.is_member === 'true' ? 1 : 0;
        this.formEdit.is_payment_termin = this.formEdit.is_payment_termin === 'true' ? 1 : 0;
        this.formEdit.multiple_order = this.formEdit.multiple_order === 'true' ? 1 : 0;
        // console.log(this.formEdit);
        const id = this.formEdit.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("customer/updateCustomer", { id: id, data: this.formEdit })
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.formEdit = {};
              this.logo = '';
              this.logo2 = '';
              document.getElementById('close-edit').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      createSubmit() {
        this.formCreate.photo = this.logo;
        this.formCreate.card_photo = this.logo2;
        this.formCreate.due_date = this.formCreate.due_date !== null ? this.formCreate.due_date : "";
        this.formCreate.is_member = this.formCreate.is_member === 'true' ? 1 : 0;
        this.formCreate.is_payment_termin = this.formCreate.is_payment_termin === 'true' ? 1 : 0;
        this.formCreate.multiple_order = this.formCreate.multiple_order === 'true' ? 1 : 0;
        // console.log(this.formCreate);
        var loading = this.$loading.show()
        this.$store
          .dispatch("customer/addCustomer", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {};
              this.logo = '';
              this.logo2 = '';
              document.getElementById('close-create').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteData() {
        // Perform deletion using this.deleteItemId
        // console.log('Deleting item with ID:', this.deleteItemId);
        const id = this.deleteItemId.data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("customer/deleteCustomer", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-delete').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      addDoc() {
        this.formDoc.file = this.logo3;
        this.formDoc.customer_id = Number(this.formEdit.id);
        var loading = this.$loading.show()
        this.$store
          .dispatch("customer/addDoc", this.formDoc )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              this.logo3 = '';
              this.formDoc = {};
              document.getElementById('close-customer-doc').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      deleteDoc(data) {
        const id = data.id;
        var loading = this.$loading.show()
        this.$store
          .dispatch("customer/deleteDoc", id )
          .then((data) => {
            if (data.status == 200) {
              loading.hide()
              document.getElementById('close-show-customer').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
      },
      async checkName(name) {
        this.nameCheckStatus = 'checking';
        try {
          const response = await this.$store.dispatch("customer/checkCustomer", { name: name });
          if (response.exists) { // Adjust according to your backend response
            this.nameCheckStatus = 'duplicate';
          } else {
            this.nameCheckStatus = 'available';
          }
        } catch (error) {
          console.error('Error checking name:', error);
          this.nameCheckStatus = '';
        }
      }
    }
  };
  </script>
  