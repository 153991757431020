<template>
    <div class="main-wrapper">
      <layout></layout>
      <!-- <adminsidebar></adminsidebar> -->
  
        <div class="page-wrapper page-settings">
            <div class="content">
                <!-- <div class="content container-fluid"> -->
                <!-- Page Header -->
                <div class="page-header">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="provider-subtitle">
                            <h6>Jadwal Service</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Page Header -->

                <!-- calendar -->
                <div class="row">
                    <div class="col-md-12">
                    <FullCalendar
                        :options="calendarOptions"
                        :events="events"
                        id="calendar-book"
                    ></FullCalendar>
                    </div>
                </div>
                <!-- /calendar -->
                <!-- </div> -->
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import FullCalendar from "@fullcalendar/vue3";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import timeGridPlugin from "@fullcalendar/timegrid";
  import interactionPlugin from "@fullcalendar/interaction";
  export default {
    components: {
      FullCalendar,
    },
    data() {
      return {
        events: [],
        calendarOptions: {
            plugins: [
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin, // needed for dateClick
            ],
            events: [],
            initialView: "dayGridMonth",
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
            weekends: true,
      },
        params: {
            row: 10,
            keyword: "",
            sortby: "id",
            sorttype: "asc",
            page: 1
        },
        dataVehicle: {}
      };
    },
    methods: {
        getData() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("maintenance/getMaintenance", this.params)
                .then((resp) => {
                    const eventsArray = resp.data.data.map((item) => {
                        const sDate = new Date(item.date);
                        const vehicle = this.dataVehicle.find(vehicle => vehicle.id === item.vehicle_id);
                        const vehicleName = vehicle ? vehicle.name : '';
                        return {
                            title: vehicleName,
                            start: `${sDate.getFullYear()}-${(sDate.getMonth() + 1).toString().padStart(2, '0')}-${sDate.getDate().toString().padStart(2, '0')}`,
                        };
                    });
                    this.events = eventsArray;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
        getVehicle() {
            var loading = this.$loading.show();
            this.$store
                .dispatch("vehicle/getVehicle", this.params)
                .then((resp) => {
                    this.dataVehicle = resp.data.data;
                    loading.hide();
                })
                .catch((e) => {
                    this.$toast.error(e);
                    loading.hide();
                });
        },
    },
    mounted() {
        this.getVehicle();
        this.getData();
    },
    
    watch: {
        // Watch for changes in the events data property and update the calendarOptions accordingly
        events: {
            handler(newEvents) {
            this.calendarOptions.events = newEvents;
            },
            deep: true, // Ensure deep watching to detect changes in nested properties of events
        },
    },
  };
  </script>
  