<template>
    <div class="col-md-3">
        <div class="service-images small-gallery" v-for="(image1, index1) in galleryImages1" :key="index1"
        @click="() => show(index1)">
            <a href="javascript:;" data-fancybox="gallery">
                <img :src="require(`@/assets/img/services/${image1.src}`)" class="img-fluid" alt="img">
                <span class="circle-icon"><i class="feather-plus"></i></span>
            </a>
        </div>
    </div>

    <vue-easy-lightbox :visible="visible" :index1="index1"
        :imgs="galleryImages1.map((image1) => ({ src: require(`@/assets/img/services/${image1.src}`) }))"
        @hide="visible = false" @on-prev="handlePrev" @on-next="handleNext"></vue-easy-lightbox>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            index1: 3,
            galleryImages1: [
                {
                id: 1,
                src: "service-ban-02.jpg"
                },
                {
                id: 2,
                src: "service-ban-03.jpg"
                },
            ],
        }
    },  
    methods: {
        show(index1) {
        this.index1 = index1;
        this.visible = true;
        },
        handlePrev(oldIndex, newIndex) {
        console.log("when prev btn click or user swipe right ----");
        console.log("oldIndex of imgs:", oldIndex);
        console.log("newIndex of imgs:", newIndex);
        },
        handleNext(oldIndex, newIndex) {
        console.log("when next btn click or user swipe left ----");
        console.log("oldIndex of imgs:", oldIndex);
        console.log("newIndex of imgs:", newIndex);
        },
    },
}
</script>