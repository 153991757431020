<template>
  <!-- Add User -->
  <div class="modal fade" id="add-user">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add User</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="profile-upload mb-3">
              <div class="profile-upload-img">
                <img src="@/assets/admin_img/customer/user-01.jpg" alt="img" id="blah" />
              </div>
              <div class="profile-upload-content">
                <div class="profile-upload-btn">
                  <div class="profile-upload-file">
                    <input type="file" id="imgInp1" />
                    <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                  </div>
                  <a href="javascript:void(0);" class="btn btn-remove">Remove</a>
                </div>
                <div class="profile-upload-para">
                  <p>
                    * Recommends a minimum size of 320 x 320 pixels. Allowed files .png
                    and .jpg.
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Username</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Email</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Phone</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Current Password</label>
              <div class="pass-group">
                <input type="password" class="form-control pass-input" />
                <span class="fas toggle-password fa-eye-slash"></span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">New Password</label>
              <div class="pass-group">
                <input type="password" class="form-control pass-inputs" />
                <span class="fas toggle-passwords fa-eye-slash"></span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Role</label>
              <vue-select :options="Role" id="role" placeholder="Admin" />
            </div>
            <div class="form-groupheads d-flex d-flex justify-content-between mb-4">
              <h2>Status</h2>
              <div class="active-switch">
                <label class="switch">
                  <input type="checkbox" checked="" />
                  <span class="sliders round"></span>
                </label>
              </div>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add User -->

  <!-- Edit User -->
  <div class="modal fade" id="edit-user">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit User</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="profile-upload mb-3">
              <div class="profile-upload-img">
                <img src="@/assets/admin_img/customer/user-01.jpg" alt="img" id="blah" />
              </div>
              <div class="profile-upload-content">
                <div class="profile-upload-btn">
                  <div class="profile-upload-file">
                    <input type="file" id="imgInp" />
                    <a href="javascript:void(0);" class="btn btn-upload">Upload</a>
                  </div>
                  <a href="javascript:void(0);" class="btn btn-remove">Remove</a>
                </div>
                <div class="profile-upload-para">
                  <p>
                    * Recommends a minimum size of 320 x 320 pixels. Allowed files .png
                    and .jpg.
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Name</label>
              <input type="text" class="form-control" value="Admin" />
            </div>
            <div class="mb-3">
              <label class="form-label">Username</label>
              <input type="text" class="form-control" value="admin" />
            </div>
            <div class="mb-3">
              <label class="form-label">Email</label>
              <input type="text" class="form-control" value="admin@example.com" />
            </div>
            <div class="mb-3">
              <label class="form-label">Phone</label>
              <input type="text" class="form-control" value="518-837-9258" />
            </div>
            <div class="mb-3">
              <label class="form-label">Current Password</label>
              <div class="pass-group">
                <input type="password" class="form-control pass-input" />
                <span class="fas toggle-password fa-eye-slash"></span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">New Password</label>
              <div class="pass-group">
                <input type="password" class="form-control pass-inputs" />
                <span class="fas toggle-passwords fa-eye-slash"></span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Role</label>
              <vue-select :options="Role" id="roles" placeholder="Admin" />
            </div>
            <div class="form-groupheads d-flex d-flex justify-content-between mb-4">
              <h2>Status</h2>
              <div class="active-switch">
                <label class="switch">
                  <input type="checkbox" checked="" />
                  <span class="sliders round"></span>
                </label>
              </div>
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit User -->

  <!-- Delete User -->
  <div class="modal fade" id="delete">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete User ?</h5>

          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>

        <div class="modal-body pt-0">
          <p class="text-muted mb-0">Are you sure want to delete this?</p>

          <div class="d-flex gap-2 justify-content-end mt-4">
            <button type="button" class="btn btn-primary">Delete</button>

            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete User -->
</template>
<script>
export default {
  data() {
    return {
      Role: ["Admin", "Super Admin"],
    };
  },
};
</script>
