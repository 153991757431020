<template>
  <!-- PHP Mail Config -->
  <div class="modal fade" id="phpmail-config">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">PHP Mail</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Email From Address</label>
              <input type="text" class="form-control" value="demo2" />
            </div>
            <div class="mb-3">
              <label class="form-label">Email Password</label>
              <div class="form-icon">
                <input type="password" class="form-control" value="*************" />
                <span><i class="feather feather-lock"></i></span>
              </div>
            </div>
            <div class="mb-4">
              <label class="form-label">Emails From Name</label>
              <input type="text" class="form-control" value="John Smith" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /PHP Mail Config -->

  <!-- SMTP Config -->
  <div class="modal fade" id="smtp-config">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">SMTP</h5>
          <button
            type="button"
            class="btn-close close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="feather feather-x"></i>
          </button>
        </div>
        <div class="modal-body pt-0">
          <form>
            <div class="mb-3">
              <label class="form-label">Email From Address</label>
              <input type="text" class="form-control" value="demo2" />
            </div>
            <div class="mb-3">
              <label class="form-label">Email Password</label>
              <div class="form-icon">
                <input type="password" class="form-control" value="*************" />
                <span><i class="feather feather-lock"></i></span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Email Host</label>
              <input type="text" class="form-control" value="ssl://smtp.googlemail.com" />
            </div>
            <div class="mb-4">
              <label class="form-label">Email Port</label>
              <input type="text" class="form-control" value="465" />
            </div>
            <div class="text-end">
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /SMTP Config -->
</template>
