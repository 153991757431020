<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper page-settings">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Plugins Manager</h5>
          <div class="list-btn">
            <ul>
              <li>
                <a class="btn btn-primary" href="javascript:;"
                  ><i class="fa fa-plus me-2"></i>Add Plugin</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="tab-sets mb-4">
              <div class="tab-contents-sets">
                <ul>
                  <li>
                    <router-link to="/admin/plugins-manager" class="active"
                      >Installed Plugins</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/admin/available-plugins"
                      >Available Plugins</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-4 col-sm-6 col-12"
            v-for="record in Plugins_Manager"
            :key="record.id"
          >
            <div class="service-widget service-fav">
              <div class="service-img">
                <a href="javascript:;">
                  <img
                    class="img-fluid serv-img"
                    alt="Service Image"
                    :src="require(`@/assets/admin_img/services/${record.Image}`)"
                  />
                </a>
              </div>
              <div class="service-content">
                <div class="serv-info">
                  <div class="serv-users">
                    <h6>
                      {{ record.Method }}<span>{{ record.Version }}</span>
                    </h6>
                    <a
                      class="delete-plugin"
                      href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#delete"
                      ><i class="feather feather-trash-2 me-2"></i>Delete</a
                    >
                  </div>
                  <div class="active-switch">
                    <label class="switch">
                      <input type="checkbox" checked="" />
                      <span class="sliders round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <delete-model :delete_title="delete_title" :delete_text="delete_text" />
</template>

<script>
import Plugins_Manager from "@/assets/json/admin/plugins-manager.json";
export default {
  data() {
    return {
      Plugins_Manager: Plugins_Manager,
      delete_title: "Delete Plugins Manager",
      delete_text: "Are you sure want to Delete?",
    };
  },
};
</script>
