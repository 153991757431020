<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>

    <div class="page-wrapper">
      <div class="content">
        <b-form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-lg-7 col-sm-12 m-auto">
              <div class="content-page-header">
                <h5>Edit Countries</h5>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Country Code</label>
                    <input type="text" class="form-control" value="AS" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Country ID</label>
                    <input type="text" class="form-control" value="684" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Country Name</label>
                    <input type="text" class="form-control" value="American " />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="btn-path">
                    <a href="javascript:void(0);" class="btn btn-cancel me-3">Cancel</a>
                    <b-button type="submit" variant="primary"> Save Country </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      this.$router.push("/admin/countries");
    },
  },
};
</script>
