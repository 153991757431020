<template>
    
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <blogbreadcrumb :title="title"  :text="text" :text1="text1" :text2="text2" />

            <div class="content">
                <div class="container">
                            
                    <div class="row">
                        <blogcontent />

                        <blogsidebar />

                        
                    </div>
                </div>
            </div>
            <truelyfooter />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Our Blog",
            text: "Home",
            text1: "Blog",
            text2: "Blog Details",
            
        }
    },
}
</script>