import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getBranch(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/branch', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addBranch(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/branch/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateBranch(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('branch/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteBranch(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('branch/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }



    },
    getters: {

    }

}