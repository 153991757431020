<template>
    <!-- pricing Section -->
		<section class="price-sections-seven">
			<div class="container">
				<div class="row">
					<div class="col-md-12 text-center">
						<div class="section-heading section-heading-seven aos" data-aos="fade-up">
							<h2>Pricing Plan</h2>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
						</div>
					</div>
				</div>
				<div class="price-toggle price-toggle-seven mb-0">
					<div class="status-toggle toggle-pink d-inline-flex align-items-center">
						Bill Monthly
						<input type="checkbox" id="status_1" class="check">
						<label for="status_1" class="checktoggle">checkbox</label>
						Bill Annualy
					</div>
				</div>
				<div class="row aos" data-aos="fade-up">
					<div class="col-lg-4 col-sm-12">
						<div class="pricing-plans price-new pricing-plans-seven">
							<div class="pricing-plans-img">
								<img src="@/assets/img/icons/pricing-seven-3.svg" alt="">
							</div>
							<div class="pricing-planshead">
								<h5>Basic</h5>
								<h6><span>$</span>10.00</h6>
							</div>
							<div class="pricing-planscontent pricing-planscontent-seven">
								<ul>
									<li>
										<i class="fa fa-check me-2"></i>
										<span>1 Bathroom cleaning</span>
									</li>
									<li>
										<i class="fa fa-check me-2"></i>
										<span>Up to 3 bedrooms cleaning</span>
									</li>
									<li class="inactive">
										<i class="fa fa-check me-2"></i>
										<span>1 Livingroom cleaning</span>
									</li>
									<li class="inactive">
										<i class="fa fa-check me-2"></i>
										<span>Small kitchen (0 - 150 ft2)</span>
									</li>
									<li class="inactive">
										<i class="fa fa-check me-2"></i>
										<span>Up to 2 additional rooms cleaning</span>
									</li>
								</ul>
								<div class="pricing-btn">
									<router-link to="/search" class="btn btn-view">Choose Plan</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-12">
						<div class="pricing-plans price-new pricing-plans-seven active-seven">
							<div class="price-block">
								<span class="popular">MOST POPULAR</span>
							</div>
							<div class="pricing-plans-img">
								<img src="@/assets/img/icons/pricing-seven-2.svg" alt="">
							</div>
							<div class="pricing-planshead">
								<h5>Basic</h5>
								<h6><span>$</span>70.00</h6>
							</div>
							<div class="pricing-planscontent pricing-planscontent-seven">
								<ul>
									<li>
										<i class="fa fa-check me-2"></i>
										<span>1 Bathroom cleaning</span>
									</li>
									<li>
										<i class="fa fa-check me-2"></i>
										<span>Up to 3 bedrooms cleaning</span>
									</li>
									<li>
										<i class="fa fa-check me-2"></i>
										<span>1 Livingroom cleaning</span>
									</li>
									<li>
										<i class="fa fa-check me-2"></i>
										<span>Small kitchen (0 - 150 ft2)</span>
									</li>
									<li>
										<i class="fa fa-check me-2"></i>
										<span>Up to 2 additional rooms cleaning</span>
									</li>
								</ul>
								<div class="pricing-btn">
									<router-link to="/search" class="btn btn-view">Choose Plan</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-12">
						<div class="pricing-plans price-new pricing-plans-seven">
							<div class="pricing-plans-img">
								<img src="@/assets/img/icons/pricing-seven-1.svg" alt="">
							</div>
							<div class="pricing-planshead">
								<h5>Basic</h5>
								<h6><span>$</span>10.00</h6>
							</div>
							<div class="pricing-planscontent pricing-planscontent-seven">
								<ul>
									<li>
										<i class="fa fa-check me-2"></i>
										<span>1 Bathroom cleaning</span>
									</li>
									<li>
										<i class="fa fa-check me-2"></i>
										<span>Up to 3 bedrooms cleaning</span>
									</li>
									<li class="inactive">
										<i class="fa fa-check me-2"></i>
										<span>1 Livingroom cleaning</span>
									</li>
									<li class="inactive">
										<i class="fa fa-check me-2"></i>
										<span>Small kitchen (0 - 150 ft2)</span>
									</li>
									<li class="inactive">
										<i class="fa fa-check me-2"></i>
										<span>Up to 2 additional rooms cleaning</span>
									</li>
								</ul>
								<div class="pricing-btn">
									<router-link to="/search" class="btn btn-view">Choose Plan</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /pricing Section -->

		<!-- App Section -->
		<section class="app-seven-section">
			<div class="container">
				<div class="app-sec app-sec-seven">
					<div class="row align-items-center w-100">
						<div class="col-lg-6 col-12">
							<div class="new-app-seven-middle">
								<div class="heading aos" data-aos="fade-up">
									<h2>Download <span>Our New App</span></h2>
									<p>Aliquam lorem ante, dapibus in, viverra quis, feugiat Phasellus viverra nulla ut
										metus varius laoreet.Aliquam lorem ante, dapibus in, viverra quis, feugiat
										Phasellus viverra,</p>
								</div>
								<div class="new-app-six-bottom aos" data-aos="fade-up">
									<a href="javascript:;" target="_blank">
										<img src="@/assets/img/icons/scan-six.svg" class="img-fluid" alt="img">
										<div class="new-app-six-content">
											<a href="javascript:;"><img src="@/assets/img/icons/googleplay-seven.svg"
													class="img-fluid new-six-img" alt="img"></a>
											<a href="javascript:;"><img src="@/assets/img/icons/appstore-seven.svg" class="img-fluid"
													alt="img"></a>
										</div>
									</a>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-12">
							<div class="appimg-seven aos" data-aos="fade-up">
								<img src="@/assets/img/app-seven.png" class="img-fluid" alt="img">
								<!-- <div class="app-img-sec">
									<img src="@/assets/img/app-seven-bg.png" alt="">
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /App Section -->

		<!-- Testimonials Section -->
		<section class="testimonals-seven-section pt-0">
			<div class="container">
				<div class="section-heading section-heading-seven">
					<div class="row">
						<div class="col-md-6 aos" data-aos="fade-up">
							<h2>Top Providers</h2>
							<p>What do you need to find?</p>
						</div>
						<div class="col-md-6 text-md-end aos" data-aos="fade-up">
							<div class="owl-nav mynav-test"></div>
						</div>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-lg-6 col-12">
						<div class="testimonals-top-seven">
							<img src="@/assets/img/testimonials-seven.png" alt="">
						</div>
					</div>
					<div class="col-lg-6 col-12">
						<div class="owl-carousel testimonals-seven-slider">
                            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
							    <Slide v-for="item in indexseventestimonial" :key="item.id">
                                    <div class="testimonials-main-ryt">
                                        <div class="testimonials-content-seven">
                                            <div class="testimonials-seven-img">
                                                <img :src="require(`@/assets/img/profiles/${item.avatar}`)" alt="">
                                                <div class="testimonials-img-content">
                                                    <h6>James Williams</h6>
                                                    <div class="rating">
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                        <i class="fa-solid fa-star filled me-1"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <img src="@/assets/img/icons/test-quote.svg" alt="" class="img-fluid">
                                        </div>
                                        <p>There are many variations of passages of Lorem Ipsum available,
                                        but the majority have suffered alteration in some form, by injected humour, or randomised words which don</p>
                                    </div>
                                </Slide>
                                    <template #addons>
                                        <Navigation />
                                    </template>
                            </Carousel>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- /Testimonials Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexseventestimonial from '@/assets/json/indexseventestimonial.json'
import "vue3-carousel/dist/carousel.css";
export default {
	data() {
		return {
			indexseventestimonial: indexseventestimonial,
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
				loop: true,
				margin: 24,
			},

			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 700px and up
				767: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 991px and up
				991: {
					itemsToShow: 1,
					snapAlign: "center"
				},
				// 1024 and up
				1024: {
					itemsToShow: 1,
					snapAlign: "start"

				}
			}
		}
	},
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
}
</script>