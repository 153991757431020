<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>Payout</h6>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <a
            href="javascript:;"
            class="btn btn-primary add-set"
            data-bs-toggle="modal"
            data-bs-target="#add-payout"
            ><i class="feather-settings me-2"></i>Set Payout</a
          >
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <div class="row">
      <!-- Payout card -->
      <div class="col-md-4 d-flex">
        <div class="card flex-fill">
          <div class="card-body">
            <div class="balance-crad">
              <div class="balance-head">
                <span class="balance-icon">
                  <img src="@/assets/img/icons/bal-icon.svg" alt="" />
                </span>
                <div class="balance-info">
                  <h6>Available Payout</h6>
                  <h3>
                    $
                    <vue3-autocounter
                      class="counter"
                      ref="counter"
                      :startAmount="0"
                      :delay="3"
                      :endAmount="180"
                      :duration="5"
                      separator=","
                      :autoinit="true"
                    />
                  </h3>
                </div>
              </div>
              <div class="balance-amt">
                <a href="javascript:;" class="btn btn-primary view-transaction"
                  >View Transactions</a
                >
                <a href="javascript:;" class="btn btn-secondary btn-withdraw">Withdraw</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex">
        <div class="card flex-fill">
          <div class="card-body">
            <div class="balance-crad">
              <div class="balance-head">
                <span class="balance-icon">
                  <img src="@/assets/img/icons/bal-icon.svg" alt="" />
                </span>
                <div class="balance-info">
                  <h6>Last Payout</h6>
                  <h3>
                    $
                    <vue3-autocounter
                      class="counter"
                      ref="counter"
                      :startAmount="0"
                      :delay="3"
                      :endAmount="200"
                      :duration="5"
                      separator=","
                      :autoinit="true"
                    />
                  </h3>
                </div>
              </div>
              <div class="balance-amt">
                <a href="javascript:;" class="btn btn-secondary view-transaction"
                  >View Transactions</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex">
        <div class="card flex-fill">
          <div class="card-body">
            <div class="balance-crad">
              <div class="balance-head">
                <span class="balance-icon">
                  <img src="@/assets/img/icons/bal-icon.svg" alt="" />
                </span>
                <div class="balance-info">
                  <h6>Next Payout</h6>
                  <h3>
                    $
                    <vue3-autocounter
                      class="counter"
                      ref="counter"
                      :startAmount="0"
                      :delay="3"
                      :endAmount="200"
                      :duration="5"
                      separator=","
                      :autoinit="true"
                    />
                  </h3>
                </div>
              </div>
              <div class="balance-amt">
                <a href="javascript:;" class="btn btn-secondary view-transaction"
                  >View Transactions</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Payout card -->
    </div>

    <!-- Payout History -->
    <div class="row">
      <div class="col-md-12">
        <h6 class="subhead-title">Payout History</h6>
        <div class="provide-table manage-table">
          <div class="table-responsive">
            <div class="table custom-table datatable" id="data-table">
              <a-table :columns="columns" :data-source="data">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Refunds'">
                    <td>{{ record.Refunds }}</td>
                  </template>
                  <template v-else-if="column.key === 'Status'">
                    <td>
                      <span :class="record.Class">{{ record.Status }}</span>
                    </td>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div id="tablelength"></div>
          </div>
          <div class="col-md-9">
            <div class="table-ingopage">
              <div id="tableinfo"></div>
              <div id="tablepagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Payout History -->
  </div>
</template>

<script>
const columns = [
  {
    title: "Payout Date",
    dataIndex: "Payout_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Payout_Date.toLowerCase();
        b = b.Payout_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Refunds",
    dataIndex: "Refunds",
    key: "Refunds",
    sorter: {
      compare: (a, b) => {
        a = a.Refunds.toLowerCase();
        b = b.Refunds.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Fees",
    dataIndex: "Fees",
    sorter: {
      compare: (a, b) => {
        a = a.Fees.toLowerCase();
        b = b.Fees.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total",
    dataIndex: "Total",
    sorter: {
      compare: (a, b) => {
        a = a.Total.toLowerCase();
        b = b.Total.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payment Processed",
    dataIndex: "Payment_Processed",
    sorter: {
      compare: (a, b) => {
        a = a.Payment_Processed.toLowerCase();
        b = b.Payment_Processed.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    Payout_Date: "Sep 21, 2023",
    Amount: "$100.00",
    Refunds: "-$0.00",
    Fees: "-$10.00",
    Total: "$90.00",
    Class: "badge-success",
    Status: "Paid",
    Payment_Processed: "Sep 11, 2023",
  },
  {
    Payout_Date: "Sep 21, 2023",
    Amount: "$100.00",
    Refunds: "-$0.00",
    Fees: "-$10.00",
    Total: "$90.00",
    Class: "badge-success",
    Status: "Paid",
    Payment_Processed: "Sep 11, 2023",
  },
  {
    Payout_Date: "Sep 21, 2023",
    Amount: "$100.00",
    Refunds: "-$0.00",
    Fees: "-$10.00",
    Total: "$90.00",
    Class: "badge-success",
    Status: "Paid",
    Payment_Processed: "Sep 11, 2023",
  },
  {
    Payout_Date: "Sep 21, 2023",
    Amount: "$100.00",
    Refunds: "-$0.00",
    Fees: "-$10.00",
    Total: "$90.00",
    Class: "badge-success",
    Status: "Paid",
    Payment_Processed: "Sep 11, 2023",
  },
  {
    Payout_Date: "Sep 26, 2023",
    Amount: "$100.00",
    Refunds: "-$10.00",
    Fees: "-$10.00",
    Total: "$90.00",
    Class: "badge badge-warning",
    Status: "Pending",
    Payment_Processed: "",
  },
];
export default {
  data() {
    return {
      columns,
      data,
    };
  },
};
</script>
