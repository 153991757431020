import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
      export(_, {data}) {
        return new Promise((resolve, reject) => {
          axios.post('/scheduler/export', data, {responseType: 'arraybuffer'}).then(resp => {
            resolve(resp)
          }).catch(e => {
            reject(e)
          })
        })
      },
      getData(_, params = {}) {
        return new Promise((resolve, reject) => {
          axios.get(`/scheduler`, {
            params: {
              filter: params.filter,
              vendor_id: params.vendorId,
              class: params.class,
              query: params.query
            }
          }).then(resp => {
            resolve(resp)
          }).catch(e => {
            reject(e)
          })
        })
      },
    },
    getters: {

    }

}
