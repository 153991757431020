<template>
  <!-- Partners Section -->
  <section class="blog-section blog-five-section">
    <div class="container">
      <div class="col-md-12 text-center">
        <div class="section-heading car-wash-heading aos" data-aos="fade-up">
          <div class="car-wash-img-five">
            <img
              src="@/assets/img/icons/car-wash-header-1.svg"
              alt=""
              class="car-wash-header-one"
            />
            <h2>Our Partner</h2>
            <img
              src="@/assets/img/icons/car-wash-header-2.svg"
              alt=""
              class="car-wash-header-two"
            />
          </div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>
      </div>
      <div class="owl-carousel partners-five-slider aos" data-aos="fade-up">
        <Carousel
          :autoplay="3000"
          :wrap-around="true"
          :settings="settings"
          :breakpoints="breakpoints"
        >
          <Slide v-for="item in indexfivepartner" :key="item.id">
            <div class="partner-img">
              <img
                :src="require(`@/assets/img/partner/${item.image}`)"
                class="img-fluid"
                alt="img"
              />
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
  <!-- Partners Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexfivepartner from "@/assets/json/indexfivepartner.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      indexfivepartner: indexfivepartner,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
