<template>
  <div class="main-wrapper">
    <layout></layout>
    <adminsidebar></adminsidebar>
    <div class="page-wrapper page-settings verify-request">
      <div class="content">
        <div class="content-page-header content-page-headersplit">
          <h5>Verification Requests</h5>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-resposnive">
              <table class="table datatable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>User Name</th>
                    <th>Document type</th>
                    <th>Document Name</th>
                    <th>Download</th>
                    <th>Reject Reason</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2</td>
                    <td>
                      <span>Adrrian</span>
                    </td>
                    <td>
                      <span>Passport</span>
                    </td>
                    <td>
                      <a href="javascript:;"><span>Passport.pdf</span></a>
                    </td>
                    <td>
                      <div class="table-download">
                        <i class="fa-solid fa-download"></i>
                      </div>
                    </td>
                    <td>
                      Just For Another Testing <br />
                      Purpose..
                    </td>
                    <td>
                      <a
                        href="javascript:;"
                        class="btn btn-rejected"
                        data-bs-toggle="modal"
                        data-bs-target="#successmodal"
                        >Rejected
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <span>Richard</span>
                    </td>
                    <td>
                      <span>Passport</span>
                    </td>
                    <td>
                      <a href="javascript:;"><span>Passport.pdf</span></a>
                    </td>
                    <td>
                      <div class="table-download">
                        <i class="fa-solid fa-download"></i>
                      </div>
                    </td>
                    <td></td>
                    <td><a href="javascript:;" class="btn btn-verified">Verified </a></td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <span>Andrew</span>
                    </td>
                    <td>
                      <span>Driving Licence</span>
                    </td>
                    <td>
                      <a href="javascript:;"><span> DrivingLicence.pdf</span></a>
                    </td>
                    <td>
                      <div class="table-download">
                        <i class="fa-solid fa-download"></i>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <div class="table-select">
                        <div class="form-group mb-0">
                          <vue-select
                            :options="VerifyStatus"
                            id="verifystatus"
                            placeholder="Select Status"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <span>Richard</span>
                    </td>
                    <td>
                      <span>Passport</span>
                    </td>
                    <td>
                      <a href="javascript:;"><span>Passport.pdf</span></a>
                    </td>
                    <td>
                      <div class="table-download">
                        <i class="fa-solid fa-download"></i>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <div class="table-select">
                        <div class="form-group mb-0">
                          <vue-select
                            :options="VerifyStatus1"
                            id="verifystatus1"
                            placeholder="Select Status"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: {
      compare: (a, b) => {
        a = a.id.toLowerCase();
        b = b.id.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User Name",
    dataIndex: "User_Name",
    key: "User_Name",
    sorter: {
      compare: (a, b) => {
        a = a.User_Name.toLowerCase();
        b = b.User_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Document Type",
    dataIndex: "Document_Type",
    sorter: {
      compare: (a, b) => {
        a = a.Document_Type.toLowerCase();
        b = b.Document_Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Document Name",
    dataIndex: "Document_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Document_Name.toLowerCase();
        b = b.Document_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Download",
    dataIndex: "Download",
    sorter: {
      compare: (a, b) => {
        a = a.Download.toLowerCase();
        b = b.Download.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reject Reason",
    dataIndex: "Reject_Reason",
    sorter: {
      compare: (a, b) => {
        a = a.Reject_Reason.toLowerCase();
        b = b.Reject_Reason.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    id: "1",
    User_Name: "Adrrian",
    Requisition_Date: "Passport",
    Document_Name: "Passport.pdf",
    Reject_Reason: "Just For Another TestingPurpose..",
    Class: "btn badge-active",
    Status: "Rejected",
  },
  {
    id: "2",
    User_Name: "Richard",
    Requisition_Date: "Passport",
    Document_Name: "Passport.pdf",
    Reject_Reason: "",
    Class: "btn badge-active",
    Status: "Verified",
  },
  {
    id: "3",
    User_Name: "Andrew",
    Requisition_Date: "Driving Licence",
    Document_Name: "DrivingLicence.pdf",
    Reject_Reason: "",
    Class: "btn badge-active",
    Status: "",
  },
  {
    id: "4",
    User_Name: "Richard",
    Requisition_Date: "Passport",
    Document_Name: "Passport.pdf",
    Reject_Reason: "",
    Class: "btn badge-active",
    Status: "",
  },
];
export default {
  data() {
    return {
      VerifyStatus: ["Select Status", "Pending", "Inprogress", "Completed", "cancelled"],
      VerifyStatus1: ["Select Status", "Pending", "Inprogress", "Completed", "cancelled"],
      columns,
      data,
    };
  },
};
</script>
