<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>Bookings</h6>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div class="review-sort d-flex align-items-center me-2">
            <p>Sort</p>
            <vue-select :options="abcd" placeholder="A -> Z" />
          </div>
          <div class="grid-listview">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <img src="@/assets/img/icons/filter-icon.svg" alt="" />
                </a>
              </li>
              <li>
                <router-link to="/provider-book-details" class="active">
                  <i class="feather-calendar"></i>
                </router-link>
              </li>
              <li>
                <router-link to="/provider-services">
                  <i class="feather-grid"></i>
                </router-link>
              </li>
              <li>
                <router-link to="/provider-booking">
                  <i class="feather-list"></i>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <!-- calendar -->
    <div class="row">
      <div class="col-md-12">
        <FullCalendar
          :options="calendarOptions"
          :events="calendarOptions.events"
          id="calendar-book"
        ></FullCalendar>
      </div>
    </div>
    <!-- /calendar -->
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      abcd: ["A -> Z", " Most helful"],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "title prev today next",
          right: "dayGridMonth,timeGridWeek,timeGridDay,timeGridList",
        },
        events: [
          {
            title: "12.30am Laptop Service",
            start: "2023-09-02",
          },
          {
            title: "2.00pm Toughened",
            start: "2023-09-04",
          },
          {
            title: "10,00am House Cleaning",
            start: "2023-09-05",
          },
          {
            title: "11,00am Washing Cloths",
            start: "2023-09-10",
          },
          {
            title: "05:00pm Interior",
            start: "2023-09-16",
          },
          {
            title: "01:00pm Building.",
            start: "2023-09-18",
          },
        ],
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
      },
    };
  },
};
</script>
