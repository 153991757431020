<template>
    <!-- App Section -->
	<section class="app-five-section">
		<div class="container">
			<div class="app-sec app-sec-five">
				<div class="row">
					<div class="col-lg-6 col-12">
						<div class="col-md-12">
							<div class="heading aos" data-aos="fade-up">
								<h2>Download Our App</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
									incididunt ut labore et dolore magna aliqua.</p>
								<h6>Scan the QR code to get the app now</h6>

							</div>
						</div>
						<div class="downlaod-btn aos" data-aos="fade-up">
							<div class="scan-img">
								<img src="@/assets/img/scan-img.png" class="img-fluid" alt="img">
							</div>
							<a href="javascript:;" target="_blank">
								<img src="@/assets/img/googleplay-five.png" alt="img">
							</a>
							<a href="javascript:;" target="_blank">
								<img src="@/assets/img/apple-five.png" alt="img">
							</a>
						</div>
					</div>
					<div class="col-lg-6 col-12">
						<div class="appimg-set aos" data-aos="fade-up">
							<img src="@/assets/img/app-sec-five.png" class="img-fluid" alt="img">
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /App Section -->
</template>