<template>

    <!-- show inspection -->
    <div class="modal fade" id="show-inspection" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Inspeksi</h5>
            <button
              id="close-show"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="closeShowInspection"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form>
                <div class="row mt-4 text-center">
                    <div class="col-12">
                        <h5>TANDA TERIMA CHECKLIST KENDARAAN</h5>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 col-md-6">
                        <div class="mb-1">
                            <strong>No POL:</strong> <span class="text-muted">{{ selectedVehicle?.nomor_plat || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Merek:</strong> <span class="text-muted">{{ selectedBrand?.name || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Nama Penyewa:</strong> <span class="text-muted">{{ selectedCustomer?.name || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Warna:</strong> <span class="text-muted">{{ selectedVehicle?.color || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Tahun:</strong> <span class="text-muted">{{ selectedVehicle?.production_year || 'N/A' }}</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="mb-1">
                            <strong>Alamat:</strong> <span class="text-muted">{{ selectedCustomer?.address || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Telepon:</strong> <span class="text-muted">{{ selectedCustomer?.phone_number || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Hari / Tanggal / Jam Keluar:</strong> <span class="text-muted">{{ formattedDateTime || 'N/A' }}</span>
                        </div>
                        <div class="mb-1">
                            <strong>Tujuan Pemakaian:</strong> <span class="text-muted">{{ selectedOrder?.remark || 'N/A' }}</span>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-6 col-md-3 mb-4">
                        <label class="form-label">Photo Tampak Depan</label>
                        <div class="profile-upload-content text-center">
                            <img v-if="formEdit && formEdit.depan" :src="formEdit.depan" 
                                @click="openImageView(formEdit.depan)" 
                                alt="Preview Depan" class="img-thumbnail mb-3" width="100" height="100" />
                        </div>
                    </div>
                    <div class="col-6 col-md-3 mb-4">
                        <label class="form-label">Photo Samping Kiri</label>
                        <div class="profile-upload-content text-center">
                            <img v-if="formEdit && formEdit.samping_kiri" 
                                @click="openImageView(formEdit.samping_kiri)" 
                                :src="formEdit.samping_kiri" alt="Preview Samping Kiri" class="img-thumbnail mb-3" width="100" height="100" />
                        </div>
                    </div>
                    <div class="col-6 col-md-3 mb-4">
                        <label class="form-label">Photo Samping Kanan</label>
                        <div class="profile-upload-content text-center">
                            <img v-if="formEdit && formEdit.samping_kanan" 
                                @click="openImageView(formEdit.samping_kanan)" 
                                :src="formEdit.samping_kanan" alt="Preview Samping Kanan" class="img-thumbnail mb-3" width="100" height="100" />
                        </div>
                    </div>
                    <div class="col-6 col-md-3 mb-4">
                        <label class="form-label">Photo Belakang</label>
                        <div class="profile-upload-content text-center">
                            <img v-if="formEdit && formEdit.belakang" 
                                @click="openImageView(formEdit.belakang)" 
                                :src="formEdit.belakang" alt="Preview Belakang" class="img-thumbnail mb-3" width="100" height="100" />
                        </div>
                    </div>
                    <div class="col-6 col-md-3 mb-4">
                        <label class="form-label">Foto BBM & KM</label>
                        <div class="profile-upload-content text-center">
                            <img v-if="formEdit && formEdit.foto_bbm_km" 
                                @click="openImageView(formEdit.foto_bbm_km)" 
                                :src="formEdit.foto_bbm_km" alt="Preview Depan" class="img-thumbnail mb-3" width="100" height="100" />
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Angka KM</label>
                            <input v-if="formEdit" type="text" class="form-control" v-model="formEdit.km" disabled/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Yang Menyerahkan</label>
                            <input v-if="formEdit" type="text" class="form-control" v-model="formEdit.yang_menyerahkan" disabled/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Yang Menerima</label>
                            <input v-if="formEdit" type="text" class="form-control" v-model="formEdit.yang_menerima" disabled/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Keterangan</label>
                            <input v-if="formEdit" type="text" class="form-control" v-model="formEdit.remark" disabled/>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12">
                        <strong>PERHATIAN TANGGUNG JAWAB PENYEWA/PEMAKAI KENDARAAN</strong>
                        <ol class="small-text">
                            <li>PEMBAYARAN UANG SEWA DI MUKA</li>
                            <li>PERIKSA PERLENGKAPAN DAN LAIN-LAIN</li>
                            <li>PENYEWA TIDAK DIPERBOLEHKAN MEMINDAHTANGANKAN KENDARAAN YANG DISEWA</li>
                            <li>PENYEWA BERTANGGUNG JAWAB MENGGANTI KERUGIAN AKIBAT "KEHILANGAN, KELALAIAN DAN LAIN-LAIN TERMASUK PELANGGARAN HUKUM SELAMA KENDARAAN DALAM MASA PERBAIKAN ATAU DALAM PROSES PENYELESAIAN MASALAH DIHITUNG SEWA TANPA KECUALI"</li>
                            <li>PADA WAKTU PENGEMBALIAN KENDARAAN DALAM KEADAAN BAIK, BERIKUT PERLENGKAPANYA</li>
                            <li>TERLAMBAT KARENA MACET/ PECAH ATAU BOCOR BAN TANGGUNG JAWAB PENYEWA</li>
                            <li>PENYEWA WAJIB KONFIRMASI APABILA TUJUAN PEMAKAIAN DILUAR KESEPAKATAN</li>
                        </ol>
                    </div>
                </div>
                <div class="text-end">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /show inspection -->

    <!-- show inspection2 -->
    <!-- <div class="modal fade" id="show-inspection2" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Detail Inspeksi</h5>
            <button
              id="close-show"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="closeShowInspection"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form>
                <div class="row mt-4">
                    <div class="col-12">
                        <h5 style="text-align: center;">TANDA TERIMA CHECKLIST KENDARAAN</h5>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">No POL: {{ selectedVehicle && selectedVehicle.nomor_plat }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Merek: {{ selectedBrand && selectedBrand.name }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Nama Penyewa: {{ selectedCustomer && selectedCustomer.name }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Warna: {{ selectedVehicle && selectedVehicle.color }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Tahun: {{ selectedVehicle && selectedVehicle.production_year }}</strong> 
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">No KTP: {{ selectedCustomer && selectedCustomer.nik }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">ALAMAT: {{ selectedCustomer && selectedCustomer.address }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">TELEPON: {{ selectedCustomer && selectedCustomer.phone_number }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">HARI / TANGGAL / JAM KELUAR: {{ formattedDateTime }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">TUJUAN PEMAKAIAN : {{ selectedOrder && selectedOrder.remark }}</strong>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="mb-3" style="display: flex;">
                            <div class="col-6">
                                <label class="form-label">File Video</label>
                                <a v-if="formEdit && formEdit.video" class="btn btn-secondary me-2" :href="formEdit.video" download>
                                    Download Video
                                </a>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">YANG MENYERAHKAN</label>
                            <input v-if="formEdit" type="text" class="form-control" v-model="formEdit.yang_menyerahkan"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">YANG MENERIMA</label>
                            <input v-if="formEdit" type="text" class="form-control" v-model="formEdit.yang_menerima"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">ANGKA KM</label>
                            <input v-if="formEdit" type="text" class="form-control" v-model="formEdit.km"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Keterangan</label>
                            <input v-if="formEdit" type="text" class="form-control" v-model="formEdit.remark"/>
                        </div>
                    </div>
                </div>
                <div class="text-end">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /show inspection2 -->

    <!-- Add inspection start -->
    <div class="modal fade" id="add-inspection-start" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Inspeksi Berangkat (Mobil Keluar)</h5>
                    <button 
                        id="close-create1" 
                        type="button" 
                        class="btn-close close-modal" 
                        data-bs-dismiss="modal" 
                        aria-label="Close"
                        ref="closeAddInspection1"
                    >
                        <i class="feather feather-x"></i>
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <form @submit.prevent="createSubmit1">
                        <div class="row mt-4 text-center">
                            <div class="col-12">
                                <h5>TANDA TERIMA CHECKLIST KENDARAAN</h5>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 col-md-6">
                                <div class="mb-1">
                                    <strong>No POL:</strong> <span class="text-muted">{{ selectedVehicle?.nomor_plat || 'N/A' }}</span>
                                </div>
                                <div class="mb-1">
                                    <strong>Merek:</strong> <span class="text-muted">{{ selectedBrand?.name || 'N/A' }}</span>
                                </div>
                                <div class="mb-1">
                                    <strong>Nama Penyewa:</strong> <span class="text-muted">{{ selectedCustomer?.name || 'N/A' }}</span>
                                </div>
                                <div class="mb-1">
                                    <strong>Warna:</strong> <span class="text-muted">{{ selectedVehicle?.color || 'N/A' }}</span>
                                </div>
                                <div class="mb-1">
                                    <strong>Tahun:</strong> <span class="text-muted">{{ selectedVehicle?.production_year || 'N/A' }}</span>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="mb-1">
                                    <strong>Alamat:</strong> <span class="text-muted">{{ selectedCustomer?.address || 'N/A' }}</span>
                                </div>
                                <div class="mb-1">
                                    <strong>Telepon:</strong> <span class="text-muted">{{ selectedCustomer?.phone_number || 'N/A' }}</span>
                                </div>
                                <div class="mb-1">
                                    <strong>Hari / Tanggal / Jam Keluar:</strong> <span class="text-muted">{{ formattedDateTime || 'N/A' }}</span>
                                </div>
                                <div class="mb-1">
                                    <strong>Tujuan Pemakaian:</strong> <span class="text-muted">{{ selectedOrder?.remark || 'N/A' }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-6 col-md-3 mb-4">
                                <label class="form-label">Photo Tampak Depan</label>
                                <div class="profile-upload-content text-center">
                                    <img v-if="formCreate.depan" :src="formCreate.depan" alt="Preview Depan" class="img-thumbnail mb-2" />
                                    <div class="profile-upload-btn">
                                        <input type="file" @change="event => selectFileAttachment(event, 'depan')" class="form-control-file" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-4">
                                <label class="form-label">Photo Samping Kiri</label>
                                <div class="profile-upload-content text-center">
                                    <img v-if="formCreate.samping_kiri" :src="formCreate.samping_kiri" alt="Preview Samping Kiri" class="img-thumbnail mb-2" />
                                    <div class="profile-upload-btn">
                                        <input type="file" @change="event => selectFileAttachment(event, 'samping_kiri')" class="form-control-file" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-4">
                                <label class="form-label">Photo Samping Kanan</label>
                                <div class="profile-upload-content text-center">
                                    <img v-if="formCreate.samping_kanan" :src="formCreate.samping_kanan" alt="Preview Samping Kanan" class="img-thumbnail mb-2" />
                                    <div class="profile-upload-btn">
                                        <input type="file" @change="event => selectFileAttachment(event, 'samping_kanan')" class="form-control-file" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-4">
                                <label class="form-label">Photo Belakang</label>
                                <div class="profile-upload-content text-center">
                                    <img v-if="formCreate.belakang" :src="formCreate.belakang" alt="Preview Belakang" class="img-thumbnail mb-2" />
                                    <div class="profile-upload-btn">
                                        <input type="file" @change="event => selectFileAttachment(event, 'belakang')" class="form-control-file" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 mb-4">
                                <label class="form-label">Foto BBM & KM</label>
                                <div class="profile-upload-content text-center">
                                    <img v-if="formCreate.foto_bbm_km" :src="formCreate.foto_bbm_km" alt="Preview BBM & KM" class="img-thumbnail mb-2" />
                                    <div class="profile-upload-btn">
                                        <input type="file" @change="event => selectFileAttachment(event, 'foto_bbm_km')" class="form-control-file" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Angka KM</label>
                                    <input type="text" class="form-control" v-model="formCreate.km" @input="formCreate.km = formCreate.km.replace(/[^0-9]/g, '')"/>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Yang Menyerahkan</label>
                                    <input type="text" class="form-control" v-model="formCreate.yang_menyerahkan" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Yang Menerima</label>
                                    <input type="text" class="form-control" v-model="formCreate.yang_menerima" />
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Keterangan</label>
                                    <input type="text" class="form-control" v-model="formCreate.remark" />
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <strong>PERHATIAN TANGGUNG JAWAB PENYEWA/PEMAKAI KENDARAAN</strong>
                                <ol class="small-text">
                                    <li>PEMBAYARAN UANG SEWA DI MUKA</li>
                                    <li>PERIKSA PERLENGKAPAN DAN LAIN-LAIN</li>
                                    <li>PENYEWA TIDAK DIPERBOLEHKAN MEMINDAHTANGANKAN KENDARAAN YANG DISEWA</li>
                                    <li>PENYEWA BERTANGGUNG JAWAB MENGGANTI KERUGIAN AKIBAT "KEHILANGAN, KELALAIAN DAN LAIN-LAIN TERMASUK PELANGGARAN HUKUM SELAMA KENDARAAN DALAM MASA PERBAIKAN ATAU DALAM PROSES PENYELESAIAN MASALAH DIHITUNG SEWA TANPA KECUALI"</li>
                                    <li>PADA WAKTU PENGEMBALIAN KENDARAAN DALAM KEADAAN BAIK, BERIKUT PERLENGKAPANYA</li>
                                    <li>TERLAMBAT KARENA MACET/ PECAH ATAU BOCOR BAN TANGGUNG JAWAB PENYEWA</li>
                                    <li>PENYEWA WAJIB KONFIRMASI APABILA TUJUAN PEMAKAIAN DILUAR KESEPAKATAN</li>
                                </ol>
                            </div>
                        </div>
                        <div class="text-end">
                            <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- /Add inspection start -->
  
    <!-- Add inspection finish -->
    <div class="modal fade" id="add-inspection-finish" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Inspeksi Berangkat (Mobil Kembali)</h5>
                <button 
                    id="close-create2" 
                    type="button" 
                    class="btn-close close-modal" 
                    data-bs-dismiss="modal" 
                    aria-label="Close"
                    ref="closeAddInspection2"
                >
                <i class="feather feather-x"></i>
                </button>
            </div>
            <div class="modal-body pt-0">
                <form @submit.prevent="createSubmit2">
                    <div class="row mt-4 text-center">
                        <div class="col-12">
                            <h5>TANDA TERIMA CHECKLIST KENDARAAN</h5>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 col-md-6">
                            <div class="mb-1">
                                <strong>No POL:</strong> <span class="text-muted">{{ selectedVehicle?.nomor_plat || 'N/A' }}</span>
                            </div>
                            <div class="mb-1">
                                <strong>Merek:</strong> <span class="text-muted">{{ selectedBrand?.name || 'N/A' }}</span>
                            </div>
                            <div class="mb-1">
                                <strong>Nama Penyewa:</strong> <span class="text-muted">{{ selectedCustomer?.name || 'N/A' }}</span>
                            </div>
                            <div class="mb-1">
                                <strong>Warna:</strong> <span class="text-muted">{{ selectedVehicle?.color || 'N/A' }}</span>
                            </div>
                            <div class="mb-1">
                                <strong>Tahun:</strong> <span class="text-muted">{{ selectedVehicle?.production_year || 'N/A' }}</span>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="mb-1">
                                <strong>Alamat:</strong> <span class="text-muted">{{ selectedCustomer?.address || 'N/A' }}</span>
                            </div>
                            <div class="mb-1">
                                <strong>Telepon:</strong> <span class="text-muted">{{ selectedCustomer?.phone_number || 'N/A' }}</span>
                            </div>
                            <div class="mb-1">
                                <strong>Hari / Tanggal / Jam Keluar:</strong> <span class="text-muted">{{ formattedDateTime || 'N/A' }}</span>
                            </div>
                            <div class="mb-1">
                                <strong>Tujuan Pemakaian:</strong> <span class="text-muted">{{ selectedOrder?.remark || 'N/A' }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-6 col-md-3 mb-4">
                            <label class="form-label">Photo Tampak Depan</label>
                            <div class="profile-upload-content text-center">
                                <img v-if="formCreate.depan" :src="formCreate.depan" alt="Preview Depan" class="img-thumbnail mb-2" />
                                <div class="profile-upload-btn">
                                    <input type="file" @change="event => selectFileAttachment(event, 'depan')" class="form-control-file" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 mb-4">
                            <label class="form-label">Photo Samping Kiri</label>
                            <div class="profile-upload-content text-center">
                                <img v-if="formCreate.samping_kiri" :src="formCreate.samping_kiri" alt="Preview Samping Kiri" class="img-thumbnail mb-2" />
                                <div class="profile-upload-btn">
                                    <input type="file" @change="event => selectFileAttachment(event, 'samping_kiri')" class="form-control-file" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 mb-4">
                            <label class="form-label">Photo Samping Kanan</label>
                            <div class="profile-upload-content text-center">
                                <img v-if="formCreate.samping_kanan" :src="formCreate.samping_kanan" alt="Preview Samping Kanan" class="img-thumbnail mb-2" />
                                <div class="profile-upload-btn">
                                    <input type="file" @change="event => selectFileAttachment(event, 'samping_kanan')" class="form-control-file" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 mb-4">
                            <label class="form-label">Photo Belakang</label>
                            <div class="profile-upload-content text-center">
                                <img v-if="formCreate.belakang" :src="formCreate.belakang" alt="Preview Belakang" class="img-thumbnail mb-2" />
                                <div class="profile-upload-btn">
                                    <input type="file" @change="event => selectFileAttachment(event, 'belakang')" class="form-control-file" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-3 mb-4">
                            <label class="form-label">Foto BBM & KM</label>
                            <div class="profile-upload-content text-center">
                                <img v-if="formCreate.foto_bbm_km" :src="formCreate.foto_bbm_km" alt="Preview BBM & KM" class="img-thumbnail mb-2" />
                                <div class="profile-upload-btn">
                                    <input type="file" @change="event => selectFileAttachment(event, 'foto_bbm_km')" class="form-control-file" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="mb-3">
                                <label class="form-label">Angka KM</label>
                                <input type="text" class="form-control" v-model="formCreate.km" @input="formCreate.km = formCreate.km.replace(/[^0-9]/g, '')"/>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Yang Menyerahkan</label>
                                <input type="text" class="form-control" v-model="formCreate.yang_menyerahkan" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Yang Menerima</label>
                                <input type="text" class="form-control" v-model="formCreate.yang_menerima" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Keterangan</label>
                                <input type="text" class="form-control" v-model="formCreate.remark" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <strong>PERHATIAN TANGGUNG JAWAB PENYEWA/PEMAKAI KENDARAAN</strong>
                            <ol class="small-text">
                                <li>PEMBAYARAN UANG SEWA DI MUKA</li>
                                <li>PERIKSA PERLENGKAPAN DAN LAIN-LAIN</li>
                                <li>PENYEWA TIDAK DIPERBOLEHKAN MEMINDAHTANGANKAN KENDARAAN YANG DISEWA</li>
                                <li>PENYEWA BERTANGGUNG JAWAB MENGGANTI KERUGIAN AKIBAT "KEHILANGAN, KELALAIAN DAN LAIN-LAIN TERMASUK PELANGGARAN HUKUM SELAMA KENDARAAN DALAM MASA PERBAIKAN ATAU DALAM PROSES PENYELESAIAN MASALAH DIHITUNG SEWA TANPA KECUALI"</li>
                                <li>PADA WAKTU PENGEMBALIAN KENDARAAN DALAM KEADAAN BAIK, BERIKUT PERLENGKAPANYA</li>
                                <li>TERLAMBAT KARENA MACET/ PECAH ATAU BOCOR BAN TANGGUNG JAWAB PENYEWA</li>
                                <li>PENYEWA WAJIB KONFIRMASI APABILA TUJUAN PEMAKAIAN DILUAR KESEPAKATAN</li>
                            </ol>
                        </div>
                    </div>
                <div class="text-end">
                    <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal">
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
                </form>
            </div>
            </div>
        </div>
    </div>
    <!-- /Add inspection finish -->

    <!-- Add inspection start2 -->
    <!-- <div class="modal fade" id="add-inspection-start2" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inspeksi Berangkat (Mobil Keluar)</h5>
            <button
              id="close-create1"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit1">
                <div class="row mt-4">
                    <div class="col-12">
                        <h5 style="text-align: center;">TANDA TERIMA CHECKLIST KENDARAAN</h5>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">No POL: {{ selectedVehicle && selectedVehicle.nomor_plat }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Merek: {{ selectedBrand && selectedBrand.name }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Nama Penyewa: {{ selectedCustomer && selectedCustomer.name }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Warna: {{ selectedVehicle && selectedVehicle.color }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Tahun: {{ selectedVehicle && selectedVehicle.production_year }}</strong> 
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">No KTP: {{ selectedCustomer && selectedCustomer.nik }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">ALAMAT: {{ selectedCustomer && selectedCustomer.address }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">TELEPON: {{ selectedCustomer && selectedCustomer.phone_number }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">HARI / TANGGAL / JAM KELUAR: {{ formattedDateTime }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">TUJUAN PEMAKAIAN : {{ selectedOrder && selectedOrder.remark }}</strong>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <strong>PERHATIAN TANGGUNG JAWAB PENYEWA/PEMAKAI KENDARAAN</strong>
                    <ol>
                        <li>PEMBAYARAN UANG SEWA DI MUKA</li>
                        <li>PERIKSA PERLENGKAPAN DAN LAIN-LAIN</li>
                        <li>PENYEWA TIDAK DIPERBOLEHKAN MEMINDAHTANGANKAN KENDARAAN YANG DISEWA</li>
                        <li>PENYEWA BERTANGGUNG JAWAB MENGGANTI KERUGIAN AKIBAT "KEHILANGAN, KELALAIAN DAN LAIN-LAIN TERMASUK PELANGGARAN HUKUM SELAMA KENDARAAN DALAM MASA PERBAIKAN ATAU DALAM PROSES PENYELESAIAN MASALAH DIHITUNG SEWA TANPA KECUALI"</li>
                        <li>PADA WAKTU PENGEMBALIAN KENDARAAN DALAM KEADAAN BAIK, BERIKUT PERLENGKAPANYA</li>
                        <li>TERLAMBAT KARENA MACET/ PECAH ATAU BOCOR BAN TANGGUNG JAWAB PENYEWA</li>
                        <li>PENYEWA WAJIB KONFIRMASI APABILA TUJUAN PEMAKAIAN DILUAR KESEPAKATAN</li>
                    </ol>
                </div>
                <div class="row mt-4">
                    <div class="col-12 mb-3">
                        <label class="form-label">Upload Video</label>
                        <div class="profile-upload-content">
                            <div class="profile-upload-btn">
                                <div class="profile-upload-file">
                                    <input type="file" @change="event => selectFileAttachment(event, 'video')" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Yang Menyerahkan</label>
                            <input type="text" class="form-control" v-model="formCreate.yang_menyerahkan"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Yang Menerima</label>
                            <input type="text" class="form-control" v-model="formCreate.yang_menerima"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">ANGKA KM</label>
                            <input type="text" class="form-control" v-model="formCreate.km"/>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Keterangan</label>
                            <input type="text" class="form-control" v-model="formCreate.remark"/>
                        </div>
                    </div>
                </div>
                <div class="text-end">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Add inspection start2 -->

    <!-- Add inspection finish2 -->
    <!-- <div class="modal fade" id="add-inspection-finish2" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inspeksi Kembali (Mobil Kembali)</h5>
            <button
              id="close-create2"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <form @submit.prevent="createSubmit2">
                <div class="row mt-4">
                    <div class="col-12">
                        <h5 style="text-align: center;">TANDA TERIMA CHECKLIST KENDARAAN</h5>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">No POL: {{ selectedVehicle && selectedVehicle.nomor_plat }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Merek: {{ selectedBrand && selectedBrand.name }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Nama Penyewa: {{ selectedCustomer && selectedCustomer.name }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Warna: {{ selectedVehicle && selectedVehicle.color }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">Tahun: {{ selectedVehicle && selectedVehicle.production_year }}</strong> 
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 120px;">No KTP: {{ selectedCustomer && selectedCustomer.nik }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">ALAMAT: {{ selectedCustomer && selectedCustomer.address }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">TELEPON: {{ selectedCustomer && selectedCustomer.phone_number }}</strong>
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">HARI / TANGGAL / JAM KELUAR: {{ formattedDateTime }}</strong> 
                        </div>
                        <div class="mb-3" style="display: flex;">
                            <strong class="col-6" style="width: 250px;">TUJUAN PEMAKAIAN : {{ selectedOrder && selectedOrder.remark }}</strong>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <strong>PERHATIAN TANGGUNG JAWAB PENYEWA/PEMAKAI KENDARAAN</strong>
                    <ol>
                        <li>PEMBAYARAN UANG SEWA DI MUKA</li>
                        <li>PERIKSA PERLENGKAPAN DAN LAIN-LAIN</li>
                        <li>PENYEWA TIDAK DIPERBOLEHKAN MEMINDAHTANGANKAN KENDARAAN YANG DISEWA</li>
                        <li>PENYEWA BERTANGGUNG JAWAB MENGGANTI KERUGIAN AKIBAT "KEHILANGAN, KELALAIAN DAN LAIN-LAIN TERMASUK PELANGGARAN HUKUM SELAMA KENDARAAN DALAM MASA PERBAIKAN ATAU DALAM PROSES PENYELESAIAN MASALAH DIHITUNG SEWA TANPA KECUALI"</li>
                        <li>PADA WAKTU PENGEMBALIAN KENDARAAN DALAM KEADAAN BAIK, BERIKUT PERLENGKAPANYA</li>
                        <li>TERLAMBAT KARENA MACET/ PECAH ATAU BOCOR BAN TANGGUNG JAWAB PENYEWA</li>
                        <li>PENYEWA WAJIB KONFIRMASI APABILA TUJUAN PEMAKAIAN DILUAR KESEPAKATAN</li>
                    </ol>
                </div>
                <div class="row mt-4">
                    <div class="col-12 mb-3">
                        <label class="form-label">Upload Video</label>
                        <div class="profile-upload-content">
                            <div class="profile-upload-btn">
                                <div class="profile-upload-file">
                                    <input type="file" @change="event => selectFileAttachment(event, 'video')" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Yang Menyerahkan</label>
                            <input type="text" class="form-control" v-model="formCreate.yang_menyerahkan"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Yang Menerima</label>
                            <input type="text" class="form-control" v-model="formCreate.yang_menerima"/>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">ANGKA KM</label>
                            <input type="text" class="form-control" v-model="formCreate.km"/>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-3">
                            <label class="form-label">Keterangan</label>
                            <input type="text" class="form-control" v-model="formCreate.remark"/>
                        </div>
                    </div>
                </div>
                <div class="text-end">
                    <button
                    type="button"
                    class="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Add inspection finish -->

    <!-- /List inspection vehicle -->
    <div class="modal fade" id="list-inspection" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">List Kendaraan {{ selectedOrder && selectedOrder.no_order}}</h5>
            <button
              id="close-list"
              type="button"
              class="btn-close close-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="closeListInspection"
            >
              <i class="feather feather-x"></i>
            </button>
          </div>
          <div class="modal-body pt-0">
            <p>Inspeksi kendaraan dibuka 5 jam sebelum tanggal order</p>
            <form>
                <div class="row mt-4" v-if="userId">
                    <div class="col-12" v-if="selectedOrder" v-for="(vehicle, indexTop) in selectedOrder.order_vehicle" :key="vehicle.id">
                        <div class="col-12">
                            <h7>Detail Kendaraan ke-{{indexTop+1}}</h7>
                        </div>
                        <div class="col-12 d-flex" v-if="userLogin.role && userLogin.role.name != 'Staff Kendaraan'">
                            <div class="col-lg-4 col-md-6 col-sm-6 col-12" v-if="vehicle" v-for="(vehicleDetail, index) in vehicle.order_vehicle_detail" :key="vehicleDetail.id">
                                <div class="service-slider aos" data-aos="fade-right" v-if="userId.id == vehicleDetail.driver_id">
                                    <div class="service-widget">
                                    <div class="service-content service-content-three">
                                        <h3 class="title">
                                        <a href="javascript:void(0);">{{ vehicleDetail.vehicle.name }} - {{ vehicleDetail.vehicle.nomor_plat }}</a>
                                        </h3>
                                        <ul>
                                        <li>Driver : {{ vehicleDetail.driver && vehicleDetail.driver.full_name }}</li>
                                        </ul>
                                        <div class="main-saloons-profile">
                                        <div class="saloon-profile-left">
                                            <div class="saloon-content">
                                            <div class="saloon-content-top">
                                                <span>Status : {{ vehicleDetail.status }}</span>
                                            </div>										
                                            </div>
                                        </div>
                                        </div>
                                        <div class="saloon-bottom d-flex">
                                            <div>
                                                <a
                                                    v-if="vehicleDetail.inspection.some(inspection => inspection.jenis_inspeksi === 'Berangkat')"
                                                    href="javascript:void(0);"
                                                    class="dropdown-item"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#show-inspection"
                                                    @click="openShowInspection(vehicleDetail.inspection.find(inspection => inspection.jenis_inspeksi === 'Berangkat'), vehicleDetail)"
                                                >
                                                Lihat Inspeksi Berangkat
                                                </a>
                                                <a
                                                    v-else
                                                    href="javascript:void(0);"
                                                    class="dropdown-item"
                                                    type="button"
                                                    :disabled="!allowInspection(vehicleDetail)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add-inspection-start"
                                                    @click="selectedData(vehicleDetail)"
                                                >
                                                Inspeksi Berangkat
                                                </a>
                                            </div>
                                            <div>
                                                <a
                                                    v-if="vehicleDetail.inspection.some(inspection => inspection.jenis_inspeksi === 'Kembali')"
                                                    href="javascript:void(0);"
                                                    class="dropdown-item"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#show-inspection"
                                                    @click="openShowInspection(vehicleDetail.inspection.find(inspection => inspection.jenis_inspeksi === 'Kembali'), vehicleDetail)"
                                                    >
                                                    Lihat Inspeksi Kembali
                                                </a>
                                                <a
                                                    v-else-if="vehicleDetail.inspection.some(inspection => inspection.jenis_inspeksi === 'Berangkat')"
                                                    href="javascript:void(0);"
                                                    class="dropdown-item"
                                                    type="button"
                                                    :disabled="!allowInspection(vehicleDetail)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add-inspection-finish"
                                                    @click="selectedData(vehicleDetail)"
                                                >
                                                Inspeksi Kembali
                                                </a>
                                            </div>
                                            <div>
                                                <a 
                                                    v-if="(vehicleDetail.report.length > 0) && (vehicleDetail.report[0]?.asal_negara === '')"
                                                    v-show="(vehicleDetail.status === 'Kendaraan Sudah Kembali') && (vehicleDetail.report.length > 0)"
                                                    class="dropdown-item"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add-driver-report"
                                                    @click="openReportModalCreate(vehicleDetail)"
                                                >
                                                Report Driver
                                                </a>
                                                <a
                                                    v-else
                                                    v-show="(vehicleDetail.status === 'Kendaraan Sudah Kembali') && (vehicleDetail.report.length > 0)"
                                                    class="dropdown-item"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#show-driver-report"
                                                    @click="openReportModalShow(vehicleDetail)"
                                                >
                                                Show Driver Report
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>				
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex" v-else>
                            <div class="col-lg-4 col-md-6 col-sm-6 col-12" v-if="vehicle" v-for="(vehicleDetail, index) in vehicle.order_vehicle_detail" :key="vehicleDetail.id">
                                <div class="service-slider aos" data-aos="fade-right">
                                    <div class="service-widget">
                                    <div class="service-content service-content-three">
                                        <h3 class="title">
                                        <a href="javascript:void(0);">{{ vehicleDetail.vehicle.name }} - {{ vehicleDetail.vehicle.nomor_plat }}</a>
                                        </h3>
                                        <ul>
                                        <li>Driver : {{ vehicleDetail.driver && vehicleDetail.driver.full_name }}</li>
                                        </ul>
                                        <div class="main-saloons-profile">
                                        <div class="saloon-profile-left">
                                            <div class="saloon-content">
                                            <div class="saloon-content-top">
                                                <span>Status : {{ vehicleDetail.status }}</span>
                                            </div>										
                                            </div>
                                        </div>
                                        </div>
                                        <div class="saloon-bottom d-flex">
                                            <div>
                                                <a
                                                    v-if="vehicleDetail.inspection.some(inspection => inspection.jenis_inspeksi === 'Berangkat')"
                                                    href="javascript:void(0);"
                                                    class="dropdown-item"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#show-inspection"
                                                    @click="openShowInspection(vehicleDetail.inspection.find(inspection => inspection.jenis_inspeksi === 'Berangkat'), vehicleDetail)"
                                                >
                                                Lihat Inspeksi Berangkat
                                                </a>
                                                <a
                                                    v-else
                                                    href="javascript:void(0);"
                                                    class="dropdown-item"
                                                    type="button"
                                                    :disabled="!allowInspection(vehicleDetail)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add-inspection-start"
                                                    @click="selectedData(vehicleDetail)"
                                                >
                                                Inspeksi Berangkat
                                                </a>
                                            </div>
                                            <div>
                                                <a
                                                    v-if="vehicleDetail.inspection.some(inspection => inspection.jenis_inspeksi === 'Kembali')"
                                                    href="javascript:void(0);"
                                                    class="dropdown-item"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#show-inspection"
                                                    @click="openShowInspection(vehicleDetail.inspection.find(inspection => inspection.jenis_inspeksi === 'Kembali'), vehicleDetail)"
                                                    >
                                                    Lihat Inspeksi Kembali
                                                </a>
                                                <a
                                                    v-else-if="vehicleDetail.inspection.some(inspection => inspection.jenis_inspeksi === 'Berangkat')"
                                                    href="javascript:void(0);"
                                                    class="dropdown-item"
                                                    type="button"
                                                    :disabled="!allowInspection(vehicleDetail)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add-inspection-finish"
                                                    @click="selectedData(vehicleDetail)"
                                                >
                                                Inspeksi Kembali
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>				
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4" v-else>
                    <div v-if="selectedOrder" v-for="(vehicle, indexTop) in selectedOrder.order_vehicle" :key="vehicle.id">
                    <h7>Detail Kendaraan ke-{{indexTop+1}}</h7>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Kendaraan</th>
                                    <th>Driver</th>
                                    <th>Inspeksi Berangkat</th>
                                    <th>Inspeksi Kembali</th>
                                    <th>Status Kendaraan</th>
                                    <th v-if="userLogin.role && userLogin.role.name != 'Staff Kendaraan'">Report Driver</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="vehicle" v-for="(vehicleDetail, index) in vehicle.order_vehicle_detail" :key="vehicleDetail.id">
                                    <template v-if="userId">
                                        <template v-if="userId.id == vehicleDetail.driver_id">
                                            <td>
                                                {{ vehicleDetail.vehicle.name }} - {{ vehicleDetail.vehicle.nomor_plat }}
                                            </td>
                                            <td>
                                                {{ vehicleDetail.driver && vehicleDetail.driver.full_name }}
                                            </td>
                                            <td>
                                                <button
                                                    v-if="isDriver == true"
                                                    class="btn delete-table me-2"
                                                    type="button"
                                                    :disabled="!allowInspection(vehicleDetail)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add-inspection-start"
                                                    @click="selectedData(vehicleDetail)"
                                                >
                                                <i class="feather feather-log-out"></i>
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn delete-table me-2"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#show-inspection"
                                                    @click="openShowInspection(vehicleDetail.inspection.find(inspection => inspection.jenis_inspeksi === 'Berangkat'), vehicleDetail)"
                                                >
                                                <i class="feather feather-eye"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    v-if="isDriver == true"
                                                    class="btn delete-table me-2"
                                                    type="button"
                                                    :disabled="!allowInspection(vehicleDetail)"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add-inspection-finish"
                                                    @click="selectedData(vehicleDetail)"
                                                >
                                                <i class="feather feather-log-in"></i>
                                                </button>
                                                <button
                                                    v-else
                                                    class="btn delete-table me-2"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#show-inspection"
                                                    @click="openShowInspection(vehicleDetail.inspection.find(inspection => inspection.jenis_inspeksi === 'Kembali'), vehicleDetail)"
                                                >
                                                <i class="feather feather-eye"></i>
                                                </button>
                                            </td>
                                            <td>{{ vehicleDetail.status }}</td>
                                            <td v-if="userLogin.role && userLogin.role.name != 'Staff Kendaraan'">
                                                <button
                                                    v-if="isDriver == true"
                                                    v-show="(vehicleDetail.status === 'Kendaraan Sudah Kembali') && (vehicleDetail.report.length > 0)"
                                                    class="btn btn-primary me-2"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add-driver-report"
                                                    @click="openReportModalCreate(vehicleDetail)"
                                                >
                                                    Report Driver
                                                </button>
                                                <button
                                                    v-else
                                                    v-show="vehicleDetail.status === 'Kendaraan Sudah Kembali'"
                                                    class="btn btn-primary me-2"
                                                    type="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#show-driver-report"
                                                    @click="openReportModalShow(vehicleDetail)"
                                                >
                                                    Show Driver Report
                                                </button>
                                            </td>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <td>
                                            {{ vehicleDetail.vehicle.name }} - {{ vehicleDetail.vehicle.nomor_plat }}
                                        </td>
                                        <td>
                                            {{ vehicleDetail.driver && vehicleDetail.driver.full_name }}
                                        </td>
                                        <td>
                                            <button
                                                v-if="isDriver == true"
                                                class="btn delete-table me-2"
                                                type="button"
                                                :disabled="!allowInspection(vehicleDetail)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add-inspection-start"
                                                @click="selectedData(vehicleDetail)"
                                            >
                                            <i class="feather feather-log-out"></i>
                                            </button>
                                            <button
                                                v-else
                                                class="btn delete-table me-2"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#show-inspection"
                                                @click="openShowInspection(vehicleDetail.inspection.find(inspection => inspection.jenis_inspeksi === 'Berangkat'), vehicleDetail)"
                                            >
                                            <i class="feather feather-eye"></i>
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                v-if="isDriver == true"
                                                class="btn delete-table me-2"
                                                type="button"
                                                :disabled="!allowInspection(vehicleDetail)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add-inspection-finish"
                                                @click="selectedData(vehicleDetail)"
                                            >
                                            <i class="feather feather-log-in"></i>
                                            </button>
                                            <button
                                                v-else
                                                class="btn delete-table me-2"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#show-inspection"
                                                @click="openShowInspection(vehicleDetail.inspection.find(inspection => inspection.jenis_inspeksi === 'Kembali'), vehicleDetail)"
                                            >
                                            <i class="feather feather-eye"></i>
                                            </button>
                                        </td>
                                        <td>{{ vehicleDetail.status }}</td>
                                        <td>
                                            <button
                                                v-if="isDriver == true"
                                                v-show="(vehicleDetail.status === 'Kendaraan Sudah Kembali') && (userLogin.role.name != 'Staff Kendaraan') && (vehicleDetail.report.length > 0)"
                                                class="btn btn-primary me-2"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add-driver-report"
                                                @click="openReportModalCreate(vehicleDetail)"
                                            >
                                                Report Driver
                                            </button>
                                            <button
                                                v-else
                                                v-show="vehicleDetail.status === 'Kendaraan Sudah Kembali'"
                                                class="btn btn-primary me-2"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#show-driver-report"
                                                @click="openReportModalShow(vehicleDetail)"
                                            >
                                                Show Driver Report
                                            </button>
                                        </td>
                                    </template>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="text-end">
                    <button
                    type="button"
                    class="btn btn-secondary me-2 mt-2"
                    data-bs-dismiss="modal"
                    >
                    Cancel
                    </button>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /List inspection vehicle -->
    <report-driver-model :reportData="reportData" :dataId="dataId" @dataUpdated="getData" :openImage="modalImageSrc2"></report-driver-model>
  </template>
  <script>
  import { uploadFile } from "@/utils/fileUpload";
  import moment from "moment";
  export default {
    data() {
      return {
        files: {
            depan: null,
            samping_kiri: null,
            samping_kanan: null,
            belakang: null,
            foto_bbm_km: null,
            video: null
        },
        formCreate: {
            order_id: null,
            jenis_inspeksi: '',
            depan: null,
            samping_kiri: null,
            samping_kanan: null,
            belakang: null,
            foto_bbm_km: null,
            jam: '',
            video: null
        },
        formEdit : {},
        dataRole: [],
        dataMenu: [],
        selectedOrder: {},
        selectedVehicle: {},
        selectedCustomer: {},
        selectedDriver: {},
        selectedBrand: {},
        selectedVehicleDetail: {},
        logo: '',
        dataId: null,
        reportData: {},
        boundHandleBackButton: null,
        modalImageSrc2: '',
      };
    },
    props: {
      editData: Object,
      deleteItemId: Object,
      userId: Object,
      userLogin: Object,
      menuData: {
        type: Array,
        default: () => []
      },
      roleData: {
        type: Array,
        default: () => []
      },
      orderData: Object,
      productData: {
        type: Array,
        default: () => []
      },
      customerData: {
        type: Array,
        default: () => []
      },
      vehicleData: {
        type: Array,
        default: () => []
      },
      userData: {
        type: Array,
        default: () => []
      },
      brandData: {
        type: Array,
        default: () => []
      },
    },
    emits: ['edit-inspection'],
    watch: {
      editData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.formEdit = newValue.data;
          }
        }
      },
      orderData: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
              this.selectedOrder = newValue.data;
          }
        }
      },
    },
    computed: {
        formattedDateTime() {
            if (this.selectedOrder) {
                const { starting_date, starting_time, finish_date, finish_time } = this.selectedOrder;
                const startDate = new Date(starting_date).toLocaleDateString('en-GB');
                const finishDate = new Date(finish_date).toLocaleDateString('en-GB');
                return `${startDate} pukul: ${starting_time} S/D ${finishDate} pukul: ${finish_time}`;
            }
            return '';
        },
        isDriver() {
            return this.$store.state.user.isDriver;
        },
    },
    methods: {
        allowInspection(vehicleDetail) {
            let time = vehicleDetail.tgl_awal

            const diff = moment(time).diff(moment.now())
            const durationAsHours = moment.duration(diff).asHours()

            return durationAsHours <= 5;
        },
        validateFile(file, allowedFileTypes, maxFileSize) {
            if (!allowedFileTypes.includes(file.type)) {
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Format file tidak didukung. Hanya file dengan tipe: ' + allowedFileTypes.join(', ') + ' yang diizinkan.',
                });
                return false;
            }

            if (file.size > maxFileSize) {
                this.$swal.fire({
                    icon: 'warning',
                    text: 'Ukuran file terlalu besar. Maksimal ukuran file adalah ' + (maxFileSize / (1024 * 1024)) + ' MB.',
                });
                return false;
            }

            return true;
        },
        selectFileAttachment(event, column) {
            const file = event.target.files[0];
            let allowedFileTypes = [];
            let maxFileSize = 10 * 1024 * 1024; // Set max size to 10 MB

            if (column === 'video') {
                allowedFileTypes = ['video/mp4', 'video/avi', 'video/mkv'];
                maxFileSize = 5 * 1024 * 1024; // 5 MB
            } else {
                allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
                maxFileSize = 10 * 1024 * 1024; // 10 MB
            }

            // Validate file type before processing
            if (!this.validateFile(file, allowedFileTypes, maxFileSize)) {
                return;
            }

            // Compress image only if it's an image and size exceeds 2 MB
            if (allowedFileTypes.includes(file.type) && file.size > 2 * 1024 * 1024) {
                this.compressImage(file, 2 * 1024 * 1024).then((compressedFile) => {
                    // Validate the compressed file size
                    if (compressedFile.size > maxFileSize) {
                        this.$swal.fire({
                            icon: 'warning',
                            text: `Ukuran file setelah kompresi masih terlalu besar. Maksimal ukuran file adalah ${(maxFileSize / (1024 * 1024)).toFixed(2)} MB.`,
                        });
                        return;
                    }

                    this.uploadAndHandleFile(compressedFile, column);
                }).catch((error) => {
                    this.$swal.fire({
                        icon: 'error',
                        text: `Gagal mengompresi gambar: ${error.message}`,
                    });
                });
            } else {
                this.uploadAndHandleFile(file, column); // Directly upload files that don't need compression
            }
        },

        compressImage(file, targetSize) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        let width = img.width;
                        let height = img.height;

                        // Start with high-quality compression and decrease until target size is met
                        let quality = 0.9; // Start at 90% quality

                        const compressAndCheck = () => {
                            canvas.width = width;
                            canvas.height = height;
                            ctx.drawImage(img, 0, 0, width, height);

                            canvas.toBlob((blob) => {
                                if (blob.size <= targetSize || quality < 0.1) {
                                    // If the size is within the target size or the quality is too low, resolve
                                    resolve(new File([blob], file.name, { type: file.type }));
                                } else {
                                    // Reduce quality and try again
                                    quality -= 0.1;
                                    compressAndCheck();
                                }
                            }, file.type, quality);
                        };

                        compressAndCheck();
                    };
                };
                reader.onerror = (error) => reject(error);
            });
        },

        uploadAndHandleFile(file, column) {
            this.files[column] = file;

            var loading = this.$loading.show();

            uploadFile(file)
                .then((resp) => {
                    this.formCreate[column] = resp;  // Ensure that `resp` contains a 'url' property
                    loading.hide();
                    this.$swal.fire({
                        icon: 'success',
                        text: 'File berhasil diupload !!',
                    });
                })
                .catch((e) => {
                    loading.hide();
                    this.$swal.fire({
                        icon: 'warning',
                        text: 'Terjadi kesalahan: ' + e.message,
                    });
                });
        },
        //function tidak menggunakan video
        createSubmit1() {
        this.formCreate.order_id = this.selectedOrder ? this.selectedOrder.id : null;
        this.formCreate.vehicle_id = this.selectedVehicle ? this.selectedVehicle.id : null;
        this.formCreate.jenis_inspeksi = 'Berangkat';
        this.formCreate.order_vehicle_detail_id = this.selectedVehicleDetail ? this.selectedVehicleDetail.id : null;
        this.formCreate.photo = this.logo;
        var loading = this.$loading.show()
        this.$store
          .dispatch("inspection/addInspection", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {
                order_id: null,
                jenis_inspeksi: '',
                depan: null,
                samping_kiri: null,
                samping_kanan: null,
                belakang: null,
                jam: '',
                video: null
              };
              this.files = {
                    depan: null,
                    samping_kiri: null,
                    samping_kanan: null,
                    belakang: null,
                },
              document.getElementById('close-create1').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
        },
        //function menggunakan video
        // createSubmit1() {
        // this.formCreate.order_id = this.selectedOrder ? this.selectedOrder.id : null;
        // this.formCreate.vehicle_id = this.selectedVehicle ? this.selectedVehicle.id : null;
        // this.formCreate.jenis_inspeksi = 'Berangkat';
        // this.formCreate.order_vehicle_detail_id = this.selectedVehicleDetail ? this.selectedVehicleDetail.id : null;
        // this.formCreate.photo = this.logo;
        // var loading = this.$loading.show()
        // this.$store
        //   .dispatch("inspection/addInspection", this.formCreate )
        //   .then((data) => {
        //     if (data.status == 201) {
        //       loading.hide()
        //       this.formCreate = {
        //         order_id: null,
        //         jenis_inspeksi: '',
        //         depan: null,
        //         samping_kiri: null,
        //         samping_kanan: null,
        //         belakang: null,
        //         jam: '',
        //         video: null
        //       };
        //       this.files = {
        //             depan: null,
        //             samping_kiri: null,
        //             samping_kanan: null,
        //             belakang: null,
        //             video: null
        //         },
        //       document.getElementById('close-create1').click();
        //       this.$emit('dataUpdated');
        //     }
        //   },
        //   (error) => {
        //     console.log(error)
        //     loading.hide();
        //   })
        //   .catch((e) => {
        //     loading.hide()
        //   })
        // },
        //function tidak menggunakan video
        createSubmit2() {
        this.formCreate.order_id = this.selectedOrder ? this.selectedOrder.id : null;
        this.formCreate.vehicle_id = this.selectedVehicle ? this.selectedVehicle.id : null;
        this.formCreate.jenis_inspeksi = 'Kembali';
        this.formCreate.order_vehicle_detail_id = this.selectedVehicleDetail ? this.selectedVehicleDetail.id : null;
        this.formCreate.photo = this.logo;
        var loading = this.$loading.show()
        this.$store
          .dispatch("inspection/addInspection", this.formCreate )
          .then((data) => {
            if (data.status == 201) {
              loading.hide()
              this.formCreate = {
                order_id: null,
                jenis_inspeksi: '',
                depan: null,
                samping_kiri: null,
                samping_kanan: null,
                belakang: null,
                jam: '',
                video: null
              };
              this.files = {
                    depan: null,
                    samping_kiri: null,
                    samping_kanan: null,
                    belakang: null,
                },
              document.getElementById('close-create2').click();
              this.$emit('dataUpdated');
            }
          },
          (error) => {
            console.log(error)
            loading.hide();
          })
          .catch((e) => {
            loading.hide()
          })
        },
        //function menggunakan video
        // createSubmit2() {
        // this.formCreate.order_id = this.selectedOrder ? this.selectedOrder.id : null;
        // this.formCreate.vehicle_id = this.selectedVehicle ? this.selectedVehicle.id : null;
        // this.formCreate.jenis_inspeksi = 'Kembali';
        // this.formCreate.order_vehicle_detail_id = this.selectedVehicleDetail ? this.selectedVehicleDetail.id : null;
        // this.formCreate.photo = this.logo;
        // var loading = this.$loading.show()
        // this.$store
        //   .dispatch("inspection/addInspection", this.formCreate )
        //   .then((data) => {
        //     if (data.status == 201) {
        //       loading.hide()
        //       this.formCreate = {
        //         order_id: null,
        //         jenis_inspeksi: '',
        //         depan: null,
        //         samping_kiri: null,
        //         samping_kanan: null,
        //         belakang: null,
        //         jam: '',
        //         video: null
        //       };
        //       this.files = {
        //             depan: null,
        //             samping_kiri: null,
        //             samping_kanan: null,
        //             belakang: null,
        //             video: null
        //         },
        //       document.getElementById('close-create2').click();
        //       this.$emit('dataUpdated');
        //     }
        //   },
        //   (error) => {
        //     console.log(error)
        //     loading.hide();
        //   })
        //   .catch((e) => {
        //     loading.hide()
        //   })
        // },
        selectedData (item) {
            this.selectedVehicleDetail = item;
            this.selectedVehicle = item.vehicle;//this.vehicleData.find(vehicle => vehicle.id === this.selectedOrder.vehicle_id);
            this.selectedCustomer = this.selectedOrder.customer;//this.customerData.find(customer => customer.id === this.selectedOrder.customer_id);
            this.selectedDriver = this.userData.find(user => user.id === this.selectedOrder.driver_id);
            this.selectedBrand = this.brandData.find(brand => brand.id === this.selectedVehicle.brand_id);
            this.formCreate.jam = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        },
        getVehicleName(vehicle_id) {
            const vehicle = this.vehicleData.find(v => v.id === vehicle_id);
            return vehicle ? vehicle.name : 'Unknown Vehicle';
        },
        openReportModalShow(data) {
            // Set the edit data before opening the modal
            const newData = data.report[0];
            const orderData = this.selectedOrder;
            const vehicleData = data.vehicle;
            this.reportData = { newData, orderData, vehicleData };
        },
        openReportModalCreate(data) {
            // Set the edit data before opening the modal
            const newData = data;
            const orderData = this.selectedOrder;
            this.dataId = { newData, orderData };
        },
        openShowInspection (data, vehicleDetail) {
            if (data) {
                this.selectedData(vehicleDetail);
                this.formEdit = data;
            } else {
                Swal.fire({
                    icon: 'error',
                    text: 'Inspeksi Belum Di Buat !!',
                }).then(() => {
                    // Close the modal after the alert is shown
                    this.$refs.closeShowInspection.click();
                });
            }
        },
        closeModal() {
            if (this.$refs.closeShowInspection) {
                this.$refs.closeShowInspection.click();
            }
            if (this.$refs.closeAddInspection1) {
                this.$refs.closeAddInspection1.click();
            }
            if (this.$refs.closeAddInspection2) {
                this.$refs.closeAddInspection2.click();
            }
            if (this.$refs.closeListInspection) {
                this.$refs.closeListInspection.click();
            }
        },
        handleBackButton(event) {
            event.preventDefault();
            this.closeModal();
        },
        openImageView(imageSrc) {
            this.modalImageSrc2 = imageSrc;
        },
    },
    mounted() {
        this.boundHandleBackButton = this.handleBackButton.bind(this);
        window.addEventListener('popstate', this.boundHandleBackButton);
        window.history.pushState({ modalOpen: true }, null, window.location.href);
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.boundHandleBackButton);
    }
  };
  </script>
  
