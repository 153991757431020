<template>
    <!-- Reschedule Appointment -->
		<div class="modal fade custom-modal" id="add-reschedule">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content doctor-profile">
					<div class="modal-header border-bottom-0 justify-content-between">
						<h5 class="modal-title">Reschedule Appointment</h5>
						<button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x"></i></button>
					</div>
					<div class="modal-body pt-0">
						<form action="javascript:;">
							<div class="wallet-add">
								<div class="form-group">
									<label class="col-form-label">Appointment Date</label>
									<div class="form-icon">
										<input type="text" class="form-control datetimepicker" placeholder="Select appointment date" />
										<span class="cus-icon"><i class="feather-calendar"></i></span>
									</div>
								</div>
								<div class="form-group">
									<label class="col-form-label">Appointment Time</label>
									<div class="form-icon">
										<input type="text" class="form-control timepicker" placeholder="Select appointment date" />
										<span class="cus-icon"><i class="feather-clock"></i></span>
									</div>
								</div>
								<div class="modal-submit text-end">
									<a href="javascript:;" class="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</a>
									<b-button type="submit" variant="primary">Save</b-button>
								</div>								
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- /Reschedule Appointment -->
</template>