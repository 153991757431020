<template>
    <!-- Hero Section -->
    <section class="hero-section-nine">
        <div class="container">
            <div class="home-banner home-banner-nine">
                <div class="row align-items-center w-100">
                    <div class="col-lg-6">

                        <div class="banner-imgs banner-imgs-nine">
                            <div class="banner-1 shape-1">
                                <img class="img-fluid" alt="banner" src="@/assets/img/banner-img-04.jpg">
                            </div>
                            <div class="banner-2 shape-3">
                                <img class="img-fluid" alt="banner" src="@/assets/img/banner-img-03.jpg">
                            </div>
                            <div class="banners-3 shape-3">
                                <img class="img-fluid" alt="banner" src="@/assets/img/banner-img-02.jpg">
                            </div>
                            <div class="banner-4 shape-2">
                                <img class="img-responsive" alt="banner" src="@/assets/img/banner-img-01.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-10 mx-auto">
                        <div class="section-search section-search-nine aos" data-aos="fade-up">
                            <div class="arrow-ryt-all">
                                <h6>welcome to truely sell Mechanic</h6>
                                <div class="arrow-ryt">
                                    <img src="@/assets/img/icons/arrow-nine.svg" alt="">
                                </div>
                            </div>
                            <h1>Providing A Professional Reliable service</h1>
                            <p>Our professional cleaning service comes up with a complete solution that makes your space
                                sparkle!</p>
                            <a href="javascript:;" class="btn btn-primary appoints-btn">Book Appointment</a>
                            <div class="banner-appointment-nine">
                                <img src="@/assets/img/profiles/avatar-15.jpg" alt="">
                                <div class="banner-appointment-content">
                                    <p>Got a question about our service?</p>
                                    <h5>Call us: 092837644</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Hero Section -->


    <!-- Safety is out first Priority -->
    <section class="safety-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="safety-first-all">
                        <div class="section-heading section-heading-nine safety-heading-nine aos" data-aos="fade-up">
                            <p>welcome to truely sell Mechanic</p>
                            <h2>Safety is out first Priority</h2>
                        </div>
                        <p class="safety-para">There are many variations of passages of Lorem Ipsum available,
                            but the majority have suffered alteration in some form</p>
                        <p class="safety-para-two">There are many variations of passages of Lorem Ipsum available,
                            but the majority have suffered alteration in some form, by injected humour,
                            or randomised words which don't look even slightly believable.
                            If you are going to use a passage of Lorem Ipsum,</p>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="safety-ryt-main">
                                <img src="@/assets/img/blog/blog-13.jpg" alt="" class="img-fluid">
                                <ul>
                                    <li>
                                        <i class="feather-check"></i>
                                        <span>Highly Professional Staff</span>
                                    </li>
                                    <li>
                                        <i class="feather-check"></i>
                                        <span>100% Satisfaction Guarantee</span>
                                    </li>
                                    <li>
                                        <i class="feather-check"></i>
                                        <span>Quality Control System</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="safety-ryt-main safety-ryt-two">
                                <img src="@/assets/img/blog/blog-12.jpg" alt="" class="img-fluid">
                                <ul>
                                    <li>
                                        <i class="feather-check"></i>
                                        <span>Accurate Testing Process</span>
                                    </li>
                                    <li>
                                        <i class="feather-check"></i>
                                        <span>Unrivalled Workmanship</span>
                                    </li>
                                    <li>
                                        <i class="feather-check"></i>
                                        <span>Timely Delivery</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Safety is out first Priority -->
</template>