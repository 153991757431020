<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>My Services</h6>
          </div>
        </div>
        <div class="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div class="review-sort d-flex align-items-center me-2">
            <p>Sort</p>
            <vue-select :options="abcd" placeholder="A -> Z" />
          </div>
          <div class="grid-listview me-2">
            <ul>
              <li>
                <a href="javascript:void(0);">
                  <i class="feather-filter"></i>
                </a>
              </li>
              <li>
                <router-link to="/provider-services" class="active">
                  <i class="feather-grid"></i>
                </router-link>
              </li>
              <li>
                <router-link to="/provider-services-list">
                  <i class="feather-list"></i>
                </router-link>
              </li>
            </ul>
          </div>
          <router-link
            to="create-service"
            class="btn btn-primary add-set"
            @click="redirectReloadCreate"
            ><i class="feather-plus me-2"></i>Add Service</router-link
          >
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <div class="row">
      <div class="col-md-12">
        <div class="tab-list">
          <ul class="nav">
            <li>
              <a
                href="javascript:;"
                class="active"
                data-bs-toggle="tab"
                data-bs-target="#active-service"
                >Actice Services</a
              >
            </li>
            <li>
              <a href="javascript:;" data-bs-toggle="tab" data-bs-target="#inactive-service"
                >Inactive Services</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tab-content pt-0">
      <myserviceactive></myserviceactive>

      <myserviceinactive></myserviceinactive>
    </div>
    <!-- Pagination -->
    <pagination></pagination>
    <!-- /Pagination -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      abcd: ["A -> Z", "Most helful"],
    };
  },
  methods: {
    redirectReloadCreate() {
      this.$router.push({ path: "/create-service" }).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>
