<template>
  <!-- Banner Section -->
  <section class="hero-section-three">
    <div class="container">
      <div class="home-banner home-banner-three aos" data-aos="fade-up">
        <div class="row align-items-center w-100">
          <div class="col-lg-6 col-md-12 mx-auto">
            <div class="section-search section-section-three">
              <h4>Best Saloon Template</h4>
              <h1>Find the Awesome Saloon’s Near you</h1>
              <p>
                Hair and SPA Salons and fully integrated with theme tools that you can use
                for the promotion of your business.
              </p>
              <button class="make-appointment">MAKE AN APPOINTMENT</button>
            </div>
          </div>
          <div class="col-lg-6"></div>
        </div>
      </div>
      <div class="search-box search-box-three aos" data-aos="fade-up">
        <form @submit.prevent="submitForm" class="d-flex search-form-profile">
          <div class="searchbox-list">
            <div class="search-input search-input-three">
              <i class="feather-check-square"></i>
              <div class="form-group">
                <vue-select :options="Type" placeholder="Select Service type" />
              </div>
            </div>
            <div class="search-input search-input-three">
              <i class="feather-map-pin me-2"></i>
              <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Location" />
              </div>
            </div>
            <div class="search-input search-input-three">
              <i class="feather-calendar me-2"></i>
              <div class="form-group mb-0">
                <datepicker
                  :input-format="dateFormat"
                  v-model="startdate"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                  placeholder="Add Date"
                />
              </div>
            </div>
          </div>
          <div class="search-btn search-btn-three">
            <b-button variant="primary" type="submit"
              ><i class="feather-search me-2"></i>SEARCH HERE</b-button
            >
          </div>
        </form>
      </div>
    </div>
  </section>
  <!-- /Banner Section -->

  <!-- Services Section -->
  <section class="services-section">
    <div class="container">
      <div class="services-header aos" data-aos="fade-up">
        <div class="row">
          <div class="col-md-12">
            <div class="section-content">
              <h2>Our Services</h2>
              <div class="our-img-all">
                <img src="@/assets/img/icons/scissor.svg" alt="" />
              </div>
              <p>
                Our Barbershop & Tattoo Salon provides classic services combined with
                innovative techniques.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="owl-carousel services-slider aos" data-aos="fade-up">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in indexthreeservice" :key="item.id">
                <div class="services-all">
                  <div class="services-main-img">
                    <a href="javascript:;">
                      <img
                        class="img-fluid serv-image"
                        alt="Service Image"
                        :src="require(`@/assets/img/services/${item.image}`)"
                      />
                    </a>
                    <div class="service-foot">
                      <img :src="require(`@/assets/img/icons/${item.img}`)" />
                      <h4>{{ item.title }}</h4>
                      <h6>{{ item.shops }}</h6>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="sidecircle-ryt">
      <img src="@/assets/img/side-circle.png" />
    </div>
  </section>
  <!-- /service Section -->
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import indexthreeservice from "@/assets/json/indexthreeservice.json";
import "vue3-carousel/dist/carousel.css";
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      Type: ["Select Service type", "Tornoto", "Texas"],
      dateFormat: "dd-MM-yyyy",
      startdate: currentDate,
      indexthreeservice: indexthreeservice,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
  },
};
</script>

<style>
.services-slider .services-all {
  margin-right: 24px;
}
</style>
