<template>
    <div class="top-bar-nine">
        <div class="container">
            <div class="top-bar-nine-all">
                <div class="top-bar-main">
                    <router-link to="index-9" class="">
                        <img src="@/assets/img/logo-02.png" alt="">
                    </router-link>
                </div>
                <div class="top-bar-ryt">
                    <div class="top-address">
                        <span><i class="feather-phone-call"></i></span>
                        <div>
                            <h6>Get Support</h6>
                            <p>310-437-2766</p>
                        </div>
                    </div>
                    <div class="top-address">
                        <span><img src="@/assets/img/icons/global-nine.svg" alt=""></span>
                        <div class="top-addres-nine">
                            <h6>Language</h6>
                            <p>
                                <img src="@/assets/img/icons/dropdown-nine.svg" class="footer-dropdown" alt="img">
                                <select class="top-select">
                                    <option>English</option>
                                    <option>Mexico</option>
                                    <option>American</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div class="top-btn">
                        <a href="javascript:;" class="btn btn-primary"><i class="feather-user"></i> Login</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
