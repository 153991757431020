import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        createRecapPdf(_, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/x/create-vehicle-recap-pdf/${id}`, { responseType: 'blob' }).then((resp) => {
                resolve(resp.data)
                }).catch((err) => {
                reject(err)
                })
            })
        },
        getVehicleStat(_, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/x/get-vehicle-stat/${id}`).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        changeVehicle(_, param) {
            return new Promise((resolve, reject) => {
                axios.post('/x/change-vehicle', {
                    detail_id: param.detail_id,
                    to_vehicle_id: param.to_vehicle_id
                }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });           
        },

        getVehicle(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('/vehicles', {params: _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getVehicleAll() {
            return new Promise((resolve, reject) => {
                axios.get('/vehicles/getAll').then(resp => {
                resolve(resp.data);
                }).catch(e => {
                reject(e);
                });
            });
        },

        showVehicle(_, id) {
            return new Promise((resolve, reject) => {
              axios.get(`/vehicles/show/${id}`).then(resp => {
                resolve(resp.data);
              }).catch(e => {
                reject(e);
              });
            });
        },

        addVehicle(_,data){
            return new Promise((resolve, reject) => {
                axios.post('/vehicles/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateVehicle(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('vehicles/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteVehicle(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('vehicles/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        addPrice(_, data){
            return new Promise((resolve,reject) => {
                axios.post('/vehicles/create-price', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        updatePrice(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('vehicles/update-price/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deletePrice(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('vehicles/delete-price/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        addDoc(_, data){
            return new Promise((resolve,reject) => {
                axios.post('/vehicles/create-doc', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        deleteDoc(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('vehicles/delete-doc/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },



    },
    getters: {

    }

}