<template>
  <div class="content container-fluid">
    <!-- Page Header -->
    <div class="page-header">
      <div class="row">
        <div class="col-md-4">
          <div class="provider-subtitle">
            <h6>Holidays & Leave</h6>
          </div>
        </div>
        <div class="col-md-8">
          <div class="leave-action text-md-end">
            <a href="javascript:;" class="btn btn-secondary"
              ><i class="feather-file-text"></i> Leave History</a
            >
            <a
              href="javascript:;"
              class="btn btn-secondary"
              data-bs-toggle="modal"
              data-bs-target="#add-holiday"
              ><i class="feather-plus"></i> Add Holiday</a
            >
            <a
              href="javascript:;"
              class="btn btn-primary add-set"
              data-bs-toggle="modal"
              data-bs-target="#add-leave"
              ><i class="feather-plus"></i> Add Leave</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Header -->

    <!-- calendar -->
    <div class="row">
      <div class="col-md-12">
        <FullCalendar
          :options="calendarOptions"
          :events="events"
          id="calendar-book"
        ></FullCalendar>
      </div>
    </div>
    <!-- /calendar -->
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      events: [],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "title prev today next",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        events: [
          {
            title: "Weekly Holiday",
            start: "2023-09-03",
          },
          {
            title: "Weekly Holiday",
            start: "2023-09-06",
          },
          {
            title: "Weekly Holiday",
            start: "2023-09-13",
          },
          {
            title: "Weekly Holiday",
            start: "2023-09-20",
          },
          {
            title: "Weekly Holiday",
            start: "2023-09-27",
          },
          {
            title: "leave",
            start: "2023-09-16",
          },
          {
            title: "leave",
            start: "2023-04-12",
          },
        ],
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
      },
    };
  },
};
</script>
