<template>
  <div class="main-wrapper">
    <div class="login-pages">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-xl-7">
            <div class="login-images">
              <img src="@/assets/admin_img/login-banner.png" alt="img" />
            </div>
          </div>
          <div class="col-lg-6 col-xl-5">
              <Form @submit="handleRegister" :validation-schema="schema" class="login-content">
                <div class="login-contenthead">
                  <h5>Kesulitan mengakses akun Anda ?</h5>
                  <h6>
                    Jangan khawatir! Silakan masukkan alamat yang terkait dengan Anda.
                  </h6>
                </div>
                <div class="login-input">
                  <div class="form-group">
                    <label>E-mail</label>
                    <Field name="email" type="text" placeholder="Email *" class="form-control" />
                    <ErrorMessage name="email" class="error-feedback" />
                  </div>
                </div>
                <div class="login-button">
                  <button class="btn btn-login" type="submit" :disabled="loading"><span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span> Submit</button>
                </div>
              </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
    import * as yup from "yup";
    import { Form, Field, ErrorMessage } from "vee-validate";

    export default {
        name: 'ForgetPassword',
        components: {
          Form,
          Field,
          ErrorMessage,
        },
        data() {
            const schema = yup.object().shape({
            email: yup
                .string()
                .required("Email harus diisi!")
                .email("Harus berupa format email!")
                .max(50, "Tidak boleh lebih dari 50 karakter!"),
            });

            return {
                successful: false,
                loading: false,
                message: "",
                schema,
            };
        },
        methods: {
            handleRegister(user) {
               let loading = this.$loading.show();
                this.$store.dispatch("auth/forgetPassword", user).then(
                    (data) => {
                        loading.hide();
                        if (data.data.status == 200) {
                            this.$swal.fire({icon: 'success', html: data.data.message});

                        }
                    },
                    (error) => {
                      console.log(error)
                      loading.hide();
                    }
                );
            },
        },
    }
</script>
